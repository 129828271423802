import { Button, Input, Modal } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { sizes } from '~/styles';

const ForceRecreateZoomIsvMeeting = ({ virtualEventId }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmText, setConfirmText] = useState('');

  const [saveZoomMeeting] = useMutation(ForceRecreateZoomIsvMeeting.mutation, {
    variables: {
      virtualEventId,
    },
  });

  return (
    <>
      <Button type='danger' onClick={() => setShowConfirmModal(true)}>
        Force Recreate Zoom ISV Meeting?
      </Button>
      <Modal
        visible={showConfirmModal}
        title='Are you certain?'
        onCancel={() => setShowConfirmModal(false)}
        footer={
          <Button
            type='primary'
            disabled={confirmText !== strings.confirm}
            onClick={async () => {
              await saveZoomMeeting();
              setShowConfirmModal(false);
            }}
          >
            Confirm
          </Button>
        }
      >
        {strings.confirmPrompt}
        <Input
          css={{ width: '100%', marginTop: sizes.Spacing.Medium }}
          value={confirmText}
          onChange={e => setConfirmText(e.target.value)}
        />
      </Modal>
    </>
  );
};

const strings = {
  confirmPrompt: `If you really want to override the Zoom Meeting for this event, please enter the phrase 'CONFIRMED' in the field below`,
  confirm: 'CONFIRMED',
};

ForceRecreateZoomIsvMeeting.mutation = gql`
  mutation SaveRecreatedZoomIsvMeeting($virtualEventId: ID!) {
    createZoomMeeting(virtualEventId: $virtualEventId) {
      id
    }
  }
`;

export default ForceRecreateZoomIsvMeeting;
