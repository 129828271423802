import _ from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { DeepPartial } from 'utility-types';

export interface EditableEntity {
  id: string;
}

export type Edits<TEntity extends EditableEntity> = DeepPartial<TEntity>;

export type EditableEntityResult<TEntity extends EditableEntity> = [
  TEntity,
  {
    edits: Edits<TEntity> & EditableEntity;
    edit: (edits: Edits<TEntity>) => void;
    clearEdits: () => void;
  }
];

/**
 * Allows for collecting and applying local edits on top of remote steate.
 */
export function useEditableEntity<TEntity extends EditableEntity>(
  remoteEntity: TEntity,
): EditableEntityResult<TEntity> {
  if (!remoteEntity || !remoteEntity.id) {
    throw new Error(`useEditableEntity requires an entity that is loaded, and that has an id`);
  }
  const { id } = remoteEntity;

  // We track any fields that have been edited…
  const [edits, setEdits] = useState({ id } as Edits<TEntity> & EditableEntity);
  // …and overlay them on top of remote state.
  const entity = useMemo(() => {
    return _.mergeWith({}, remoteEntity, edits, entityMerge);
  }, [remoteEntity, edits]);

  // Let downstream components make partial edits.
  const editsRef = useRef(edits);
  editsRef.current = edits;
  const edit = useMemo(() => {
    return function edit(edit: Edits<TEntity>) {
      const before = editsRef.current;
      const after = _.mergeWith({}, editsRef.current, edit, { id }, entityMerge);
      console.debug('Applying edit:', edit, { before, after });
      setEdits(after);
    };
  }, [setEdits, id]);

  const clearEdits = useMemo(() => {
    return function clearEdits() {
      setEdits({ id } as Edits<TEntity> & EditableEntity);
    };
  }, [setEdits, id]);

  return [entity, { edits, edit, clearEdits }];
}

function entityMerge(target: any, source: any) {
  // Don't merge array properties.
  if (Array.isArray(target) || Array.isArray(source)) {
    return source;
  }
}
