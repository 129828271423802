import Text from '~/Text';
import { rrulestr } from 'rrule';
import moment from 'moment-timezone';
import { Icon } from 'antd';
import { sizes } from '~/styles';
import gql from 'graphql-tag';

import * as GraphQL from '~/graphql';
import { pluralize } from 'humanize-plus';

const ScheduleItemCell = (props: GraphQL.ScheduleItemCell.Fragment) => {
  let timeString = '';
  const rrule = rrulestr(props.rrule);

  const startTimeMoment = moment(props.startTime, 'HHmm');

  if (props.durationMins && startTimeMoment) {
    const endTimeMoment = startTimeMoment.clone().add(props.durationMins, 'minutes');
    const isSameAMPM = startTimeMoment.format('a') === endTimeMoment.format('a');

    const startTime = startTimeMoment.format(`h:mm${isSameAMPM ? '' : 'a'}`);
    const endTime = endTimeMoment.format('h:mma');
    timeString = `${startTime}-${endTime} `;
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        '> *:not(:last-child)': { marginBottom: sizes.Spacing.Small },
      }}
    >
      <div css={{ display: 'flex' }}>
        <Icon
          type='calendar'
          css={{ marginRight: sizes.Spacing.XSmall, position: 'relative', top: 2 }}
        />
        <Text size='Small'>{`${timeString}${rrule.toText()}`}</Text>
      </div>
      {props.capacity != null && (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Icon type='user' css={{ marginRight: sizes.Spacing.XSmall }} />
          <Text size='Small'>{`${props.capacity} total capacity`}</Text>
        </div>
      )}
      {props.futureEvents && props.futureEvents.length > 0 && (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Icon type='info-circle' css={{ marginRight: sizes.Spacing.XSmall }} />
          <Text size='Small'>{`${props.futureEvents.length} upcoming ${pluralize(
            props.futureEvents.length,
            'event',
          )}`}</Text>
        </div>
      )}
    </div>
  );
};

export default ScheduleItemCell;

ScheduleItemCell.fragment = gql`
  fragment ScheduleItemCell on Schedule {
    id
    rrule
    durationMins
    startTime
    capacity
    futureEvents {
      id
    }
  }
`;
