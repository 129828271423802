import _ from 'lodash';
import React from 'react';

import * as GraphQL from '~/graphql';
import { memo } from '~/react';
import MultiSelectFilter from './MultiSelect';

const FilterCapacityType = ({ ...otherProps }) => {
  return (
    <MultiSelectFilter
      name={'capacityTypes'}
      placeholder='All capacity types'
      options={Object.keys(GraphQL.CapacityType).map(name => ({
        label: name,
        value: name,
      }))}
      {...otherProps}
    />
  );
};

export default memo(FilterCapacityType);
