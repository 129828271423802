import _ from 'lodash';
import React from 'react';

import SearchResultsPanel from '~/Search/Results/Panel';
import SearchResultsResult from '~/Search/Results/Result';

interface Props {
  children?: React.ReactNode;
  visible: boolean;
}

const SearchResults = ({ children, visible }: Props) => {
  return _.isArray(children) ? (
    <SearchResultsPanel visible={visible}>
      {_.map(children, (child: any, i) => (
        <SearchResultsResult key={i} index={i} {...child.props} />
      ))}
    </SearchResultsPanel>
  ) : null;
};

export default SearchResults;
