import { Button } from 'antd';
import { DateTime } from 'luxon';
import { formatCents } from '~/formatters';
import { Price, PriceStatus } from '~/graphql';
import { borders, colors, font, sizes } from '~/styles';
import { BorderRadius } from '~/styles/sizes';
import ChargeStatus from './ChargeStatus';

const IDENTITY_FUNCTION = () => {};

const gridStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gap: `${sizes.Spacing.XSmall}px`,
};

type InvoicedChargesRowProps = {
  price: Pick<Price, 'id' | 'priceType' | 'totalCents' | 'status' | 'createdAt'>;
  className?: string;
  pendingDelete?: boolean;
  onRemove?: (id: string) => void;
  onReAdd?: (id: string) => void;
};
export function InvoicedChargeRow({
  price,
  className,
  pendingDelete = false,
  onRemove = IDENTITY_FUNCTION,
  onReAdd = IDENTITY_FUNCTION,
}: InvoicedChargesRowProps) {
  const { id, totalCents, createdAt, status } = price;
  const displayCents = formatCents(totalCents);
  const handleToggle = () => {
    if (pendingDelete) {
      return onReAdd(id ? id : undefined);
    } else {
      return onRemove(id ? id : undefined);
    }
  };
  const isUnpaid = status === PriceStatus.Unpaid;
  const isAbandoned = status === PriceStatus.Abandoned;
  const invoiceDiffDays = Math.floor(
    DateTime.fromISO(createdAt)
      .diffNow()
      .as('days'),
  );
  return (
    <div
      css={{
        ...gridStyle,
        width: '100%',
        alignItems: 'center',
        borderBottom: borders.darkDivider,
        padding: `${sizes.Spacing.Small}px`,
        transition: 'all 0.5s ease-in-out',
        backgroundColor: pendingDelete ? colors.Red.Red500_Faded : colors.GreyLighter,
      }}
      className={className}
    >
      <div>{displayCents}</div>
      <div css={{ textAlign: 'center' }}>Email</div>
      <div css={{ textAlign: 'center' }}>
        {invoiceDiffDays === 0 ? 'Today' : `${-invoiceDiffDays} days ago`}
      </div>
      {isUnpaid && !pendingDelete && (
        <div css={{ textAlign: 'center', justifySelf: 'flex-end' }}>
          <Button
            css={{
              borderRadius: `${BorderRadius.Medium}px`,
              color: colors.GreyDarkest,
              backgroundColor: colors.Warning,
              borderColor: colors.Warning,
              '&:hover': {
                backgroundColor: colors.Red500_Faded,
                borderColor: colors.Red500_Faded,
                color: colors.White,
              },
            }}
            type='primary'
            onClick={handleToggle}
          >
            Abandon
          </Button>
        </div>
      )}
      {isUnpaid && pendingDelete && (
        <div css={{ textAlign: 'center', justifySelf: 'flex-end' }}>
          <Button
            css={{
              borderRadius: `${BorderRadius.Medium}px`,
              color: 'white',
              backgroundColor: colors.Blue500,
            }}
            type='primary'
            onClick={handleToggle}
          >
            Unselect
          </Button>
        </div>
      )}
      {isAbandoned && (
        <ChargeStatus status={status} css={{ textAlign: 'center', justifySelf: 'flex-end' }} />
      )}
    </div>
  );
}

export function InvoicedChargeHeaderRow({ className }: { className?: string }) {
  return (
    <div
      css={{
        ...gridStyle,
        color: colors.White,
        padding: `${sizes.Spacing.XSmall}px ${sizes.Spacing.Small}px`,
        ...font.Size.Body,
        fontWeight: font.FontWeight.Bold,
        backgroundColor: colors.Blue500,
        width: '100%',
        justifyContent: 'space-between',
      }}
      className={className}
    >
      <span>Amount</span>
      <span css={{ textAlign: 'center' }}>Invoice Type</span>
      <span css={{ textAlign: 'center' }}>Invoice Sent</span>
      <span css={{ textAlign: 'end' }} />
    </div>
  );
}
