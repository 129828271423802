import '~/polyfills';

import React from 'react';
import ReactDOM from 'react-dom';

import App from '~/App';
import * as serviceWorker from '~/serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import config from '~/config';

if (config.isProduction) {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.3,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
