import { Input } from 'antd';
import { ChangeEvent, useMemo } from 'react';

import { colors } from '~/styles';

interface Props {
  unit: string;
  value?: number;
  onChange?: (newValue: number | null) => unknown;
}

const InputNumberWithUnit = ({ unit, value, onChange, ...otherProps }: Props) => {
  const handleChange = useMemo(() => {
    return function handleChange(event: ChangeEvent<HTMLInputElement>) {
      const stringValue = event.target.value;
      const numericValue = parseFloat(stringValue);
      onChange(Number.isFinite(numericValue) ? numericValue : null);
    };
  }, [onChange]);

  return (
    <Input
      value={value}
      onChange={handleChange}
      suffix={<div css={{ lineHeight: '100% !important', color: colors.Silver }}>{unit}</div>}
    />
  );
};

export default InputNumberWithUnit;
