import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useRef } from 'react';
import { ArrayParam, DateParam, StringParam, useQueryParams } from 'use-query-params';

import * as GraphQL from '~/graphql';
import { sizes } from '~/styles';
import columns from './columns';
import FillRemaining from '~/FillRemaining';
import PaginatedTable from '~/PaginatedTable';
import rowRenderer from '~/Experiences/mobileRowRenderer';
import moment from 'moment-timezone';
import { useParams, Redirect } from 'react-router-dom';

const Events = ({
  columnWhitelist,
  variablesOverride,
  clientSideFilter,
  ...otherProps
}: {
  variablesOverride?: any;
  columnWhitelist?: string[];
  clientSideFilter?: (rowData: any) => boolean;
}) => {
  const [params] = useQueryParams({
    search: StringParam,
    startDate: DateParam,
    endDate: DateParam,
    capacityTypes: ArrayParam,
    dateFilterType: StringParam,
  });

  const { tabName } = useParams();

  const variables = useRef(getDefaultEventsListVariables());

  if (tabName === 'events' && !params.dateFilterType)
    return <Redirect to='/supply/events?dateFilterType=Future' />;

  const datesFromParams = { startDate: params.startDate, endDate: params.endDate };

  const dateParams = {
    Past: { endDate: moment().startOf('day') },
    Future: { startDate: moment().startOf('day') },
    Custom: datesFromParams,
  }[params.dateFilterType || 'Custom'];

  _.assign(variables.current, {
    filterInput: {
      ...dateParams,
      search: params.search,
      capacityType:
        _.size(Object.keys(GraphQL.CapacityType)) === _.size(params.capacityTypes)
          ? undefined
          : _.first(params.capacityTypes),
    },
    order: [
      {
        key: GraphQL.EventOrderKey.Start,
        direction:
          params.dateFilterType !== 'Past'
            ? GraphQL.OrderDirection.Asc
            : GraphQL.OrderDirection.Desc,
      },
    ],
  });

  return (
    <FillRemaining>
      <PaginatedTable<GraphQL.EventsList.Query, GraphQL.EventsList.Variables>
        {...otherProps as any}
        columns={
          columnWhitelist ? columns.filter(column => columnWhitelist.includes(column.key)) : columns
        }
        headerHeight={sizes.GRID_UNIT * 10}
        query={Events.query}
        dataKey='eventsConnection'
        variables={variablesOverride || variables.current}
        rowHeight={sizes.GRID_UNIT * 10}
        clientSideFilter={clientSideFilter}
        listRowRenderer={rowRenderer}
      />
    </FillRemaining>
  );
};

export const getDefaultEventsListVariables = () => ({
  first: 40,
  after: null,
  filterInput: {
    search: null,
  },
});

Events.query = gql`
  query EventsList(
    $first: Int
    $after: String
    $filterInput: EventFilterInput
    $order: [EventOrderArgs]
  ) {
    eventsConnection(first: $first, after: $after, filterInput: $filterInput, order: $order)
      @connection(key: "eventsConnection", filter: ["filterInput"]) {
      edges {
        node {
          id
          start
          end
          capacityType
          remainingCapacity
          totalCapacity
          location {
            address {
              neighborhood
            }
          }
          schedule {
            id
            rrule
          }
          experience {
            id
            name
            partner {
              id
              name
            }
            priceLevel
            xperienceTypes
            cardOnFile {
              id
            }
            attireType {
              id
              name
            }
            friendlyTowardsDietaryRestrictions {
              id
              name
            }
            notFriendlyTowardsDietaryRestrictions {
              id
              name
            }
          }
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export default Events;
