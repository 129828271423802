import MoneyInput from '~/Input/Money';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import PriceColumn from './PriceColumn';

const PriceInput = ({ value, onChange, title, label, inputDataTestId, isUserFacing }) => (
  <PriceColumn label={title}>
    <LabeledField label={label} isUserFacing={isUserFacing}>
      <MoneyInput value={value} onChange={onChange} data-testid={inputDataTestId} />
    </LabeledField>
  </PriceColumn>
);

export default PriceInput;
