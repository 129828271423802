import _ from 'lodash';
import { useCallback, useLayoutEffect, useRef } from 'react';

const maxScrollTop = (dom: any) => dom.scrollHeight - dom.clientHeight;

const runScroll = (dom: any, offset: any) => {
  dom.scrollTop = offset;
};

const useScrollBottom = (domRef: any, references: any[] = []) => {
  const wasScrolled = useRef(null as any);

  const isScrolled = useCallback(() => {
    if (!domRef.current) return;
    return Math.ceil(domRef.current.scrollTop) >= maxScrollTop(domRef.current);
  }, [domRef.current]);

  useLayoutEffect(() => {
    const onScroll = () => {
      wasScrolled.current = isScrolled();
    };

    _.invoke(domRef.current, 'addEventListener', 'scroll', onScroll);
    return () => {
      _.invoke(domRef.current, 'removeEventListener', 'scroll', onScroll);
    };
  }, [domRef.current]);

  const scroll = useCallback(
    position => {
      if (!domRef.current) return;
      const offset = position === Infinity ? maxScrollTop(domRef.current) : position;
      runScroll(domRef.current, offset);
      _.invoke(domRef.current, 'focus');
    },
    [runScroll, domRef.current],
  );

  useLayoutEffect(() => {
    scroll(Infinity);
  }, [...references, domRef.current]);

  useLayoutEffect(() => {
    wasScrolled.current = isScrolled();
  }, []);
};

export default useScrollBottom;
