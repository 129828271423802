import { InputProps } from 'antd/lib/input';
import gql from 'graphql-tag';
import _ from 'lodash';

import Search from '~/Search';
import * as GraphQL from '~/graphql';
import { getApolloClient } from '~/services/apollo';

interface Props {
  onChange: any;
  value?: string | any;
  size?: InputProps['size'];
  descriptionKey?: keyof GraphQL.SearchUsers.Fragment;
  role?: GraphQL.Role;
}

const SearchUsers = ({ value, onChange, descriptionKey, role, ...otherProps }: Props) => {
  return (
    <Search
      onSelect={onChange}
      onSearch={_.partialRight(SearchUsers.search, descriptionKey, role)}
      defaultValue={value}
      {...otherProps}
    />
  );
};

SearchUsers.search = async (
  query: string,
  descriptionKey?: keyof GraphQL.SearchUsers.Fragment,
  role?: GraphQL.Role,
) => {
  const data = await getApolloClient().mutate<
    GraphQL.SearchUsers.Mutation,
    GraphQL.SearchUsers.Variables
  >({ mutation: SearchUsers.mutation, variables: { query, role } });

  return _.map(data.data!.searchUsers, (user, key) => ({
    ...user,
    key,
    title: user.name!,
    description: descriptionKey ? user[descriptionKey] : undefined,
  }));
};

SearchUsers.fragment = gql`
  fragment SearchUsers on User {
    id
    firstName
    lastName
    name
    phone
    email
    role
    orgs {
      id
      name
    }
  }
`;

SearchUsers.mutation = gql`
  mutation SearchUsers($query: String!, $role: Role) {
    searchUsers(query: $query, role: $role) {
      ...SearchUsers
    }
  }

  ${SearchUsers.fragment}
`;

export default SearchUsers;
