import { Icon } from 'antd';
import React from 'react';
import { formatCents, formatCredits } from '~/formatters';
import { ContractUnitType } from '~/graphql';
import { getContractName } from '~/helpers/contract';
import { colors, sizes } from '~/styles';

const contractTypeDisplayStrings: Record<ContractUnitType, string> = {
  Cents: 'cent based',
  Credits: 'credit based',
  Recurring: 'seat based',
  Event: '',
  Unlimited: '',
};

type Props = {
  contract?: {
    name: string;
    status?;
    type;
    unitCount?: number;
    premiumUnitCount?: number;
  };
};

export default function ContractRow({ contract }: Props) {
  const contractUnitType = contract.type || ContractUnitType.Credits;
  const contractName = getContractName(contract);
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: `${sizes.Spacing.Small}px`,
        backgroundColor: colors.GreyLighter,
      }}
    >
      <div>
        <Icon type='bank' /> {contractName}
      </div>
      {contract && (
        <React.Fragment>
          {contractUnitType === ContractUnitType.Credits && (
            <div>{formatCredits(contract.unitCount)} remaining</div>
          )}
          {contractUnitType === ContractUnitType.Recurring && (
            <div>{contract.unitCount} seats remaining</div>
          )}
          {contractUnitType === ContractUnitType.Cents && (
            <div>{formatCents(contract.unitCount)} remaining</div>
          )}
          {contractUnitType === ContractUnitType.Event ||
            (contractUnitType === ContractUnitType.Unlimited && <div>Unknown balance</div>)}
        </React.Fragment>
      )}
      {contract && contractUnitType === ContractUnitType.Recurring && (
        <div>{contract.premiumUnitCount} premium seats remaining</div>
      )}
    </div>
  );
}
