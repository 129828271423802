import React from 'react';

import { colors, font } from '~/styles';

const Weight = font.FontWeight;
export const Size = {
  XXSmall: 10,
  XSmall: 12,
  Small: 14,
  Base: 16,
  Large: 18,
  XLarge: 20,
  XXLarge: 24,
  Huge: 30,
  XHuge: 36,
  XXHuge: 48,
};

interface Props {
  block?: boolean;
  size?: keyof typeof Size;
  children?: React.ReactNode;
  color?: keyof typeof colors;
  weight?: keyof typeof Weight;
  italic?: boolean;
}

const Text = ({
  children,
  italic,
  weight = 'Regular',
  size = 'Base',
  block = true,
  color = 'Black',
  ...props
}: Props) => {
  return (
    <span
      css={{
        fontSize: Size[size],
        lineHeight: `${Size[size] * 1.2}px`,
        fontWeight: Weight[weight],
        fontFamily: font.FONT_FAMILY,
        display: block ? 'block' : undefined,
        color: colors[color] as any,
        fontStyle: italic ? 'italic' : undefined,
      }}
      {...props}
    >
      {children}
    </span>
  );
};

Text.Weight = Weight;

Text.Size = Size;

export default Text;
