import { VirtualItem } from '@tanstack/react-virtual';
import { colors, sizes } from '~/styles';

import { DataTableColumn, GraphQLFiltersInput } from './interface';

export const CELL_VERTICAL_PADDING = sizes.Spacing.XSmall;
export const CELL_HORIZONTAL_PADDING = sizes.Spacing.Medium;
export const CELL_LINE_HEIGHT = 20;
export const CELL_BORDER_SIZE = 1;

/**
 * Renders an individual row of data.
 */
function DataTableRow<TRow, TSortKeys extends string, TFilters extends GraphQLFiltersInput>({
  columns,
  virtualItem: { measureElement, index },
  data,
}: {
  columns: DataTableColumn<TRow, TSortKeys, TFilters>[];
  virtualItem: VirtualItem<HTMLTableRowElement>;
  data?: TRow;
}) {
  let cells: React.ReactNode[];
  if (data) {
    cells = columns.map(({ cell, width }, columnIndex) => {
      let content: React.ReactNode;
      try {
        content = cell(data);
      } catch (error) {
        content = <ErrorCell error={error} />;
      }

      return (
        <td key={columnIndex} css={{ maxWidth: width }}>
          {content}
        </td>
      );
    });
  } else {
    cells = columns.map((_column, index) => (
      <td key={index}>
        <div css={STYLES.placeholder} />
      </td>
    ));
  }

  return (
    <tr ref={measureElement} css={STYLES.root} aria-rowindex={index}>
      {cells}
    </tr>
  );
}

function ErrorCell({ error }: { error: any }) {
  return (
    <div css={STYLES.error} title={error.stack}>
      {error.message}
    </div>
  );
}

const STYLES = {
  root: {
    borderBottom: `1px solid ${colors.GreyLight}`,
    '&:hover': {
      backgroundColor: `#F7FAFF`, // Blue 100
    },
    '& td': {
      padding: `${sizes.Spacing.XSmall}px ${sizes.Spacing.Medium}px`,
    },
  },
  placeholder: {
    backgroundColor: colors.GreyLight,
    width: '100%',
    height: CELL_LINE_HEIGHT,
  },
  error: {
    backgroundColor: 'red',
    color: 'white',
  },
};

export default DataTableRow;
