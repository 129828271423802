import _ from 'lodash';
import React from 'react';

import { formatPhone, parsePhone } from '~/formatters';
import { getPlaceDetails } from '~/geo';
import SearchPlaces from '~/Search/Places';
import { sizes } from '~/styles';
import Text from '~/Text';

export const strings = {
  title: 'Create experience',
  startBySearching: 'Start by searching for an existing business:',
};

const ImportPlaceData = (props: { onSelectPlace: any }) => {
  const onSelectPlace = async (place: any) => {
    const location = await getPlaceDetails(place.place_id);
    props.onSelectPlace({
      url: location.url,
      phone: location.phone ? formatPhone(parsePhone(location.phone)) : null,
      rating: location.rating,
      name: _.get(place, 'title'),
      priceLevel: location.priceLevel,
      location: _.pick(location, 'placeId', 'coordinates', 'address', 'name'),
    });
  };

  return (
    <div
      css={{
        padding: sizes.Spacing.Medium,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Text size='Base' weight='Medium' css={{ marginBottom: sizes.Spacing.XSmall }}>
        {strings.startBySearching}
      </Text>
      <div css={{ maxWidth: sizes.GRID_UNIT * 60 }}>
        <SearchPlaces onSelect={onSelectPlace} size='large' />
      </div>
    </div>
  );
};

export default ImportPlaceData;
