import { Avatar } from 'evergreen-ui';
import gql from 'graphql-tag';
import React from 'react';

import Ellipsis from '~/Ellipsis';
import { formatPhone, smartFormatRelativeDate } from '~/formatters';
import { memo } from '~/react';
import RowComponent from '~/RowComponent';
import Strong from '~/Strong';
import { colors, font, sizes } from '~/styles';
import * as GraphQL from '~/graphql';

type User = GraphQL.MessagesListRow.Fragment;

interface Props {
  data: User;
  index: number;
  isSelected: boolean;
  onClick: (user: User) => void;
}

const MessagesListRow = ({
  data,
  onClick,
  index,
  isSelected,
  ...otherProps
}: Props) => (
  <RowComponent
    data={data}
    onClick={onClick}
    isSelected={isSelected}
    {...otherProps}
  >
    <Avatar
      name={data.name}
      size={sizes.GRID_UNIT * 12}
      hashValue={data.name ? undefined : data.id}
      css={{ marginRight: sizes.Spacing.Small }}
    />
    <div
      css={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: sizes.Spacing.XXXSmall,
        }}
      >
        <UserIdentifier {...data} />
        <MostRecentMessageSentAt {...data} />
      </div>
      <MostRecentMessage {...data} />
    </div>
  </RowComponent>
);

const MostRecentMessage = ({ mostRecentMessage }: User) =>
  mostRecentMessage ? (
    <div css={{ ...font.Size.SmallBody, color: colors.Slate }}>
      <Ellipsis trimRight maxLine={2} text={mostRecentMessage.content} />
    </div>
  ) : null;

const MostRecentMessageSentAt = ({ mostRecentMessage }: User) =>
  mostRecentMessage ? (
    <div css={{ ...font.Size.SmallBody, color: colors.Slate }}>
      {smartFormatRelativeDate(mostRecentMessage.createdAt)}
    </div>
  ) : null;

const UserIdentifier = ({ name, phone }: User) => (
  <Strong css={{ fontWeight: font.FontWeight.Demi }}>
    {name || formatPhone(phone)}
  </Strong>
);

MessagesListRow.fragment = gql`
  fragment MessagesListRow on User {
    id
    name
    firstName
    lastName
    phone
    mostRecentMessage {
      id
      content
      type
      createdAt
    }
  }
`;

export default memo(MessagesListRow);
