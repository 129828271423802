import { colors } from '~/styles';

const InfoCircle = ({ size = 20, color = colors.White }) => {
  const viewBox = `0 0 ${size} ${size}`;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1872_10720)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.99998 2.49998C5.85784 2.49998 2.49998 5.85784 2.49998 9.99998C2.49998 14.1421 5.85784 17.5 9.99998 17.5C14.1421 17.5 17.5 14.1421 17.5 9.99998C17.5 5.85784 14.1421 2.49998 9.99998 2.49998ZM0.833313 9.99998C0.833313 4.93737 4.93737 0.833313 9.99998 0.833313C15.0626 0.833313 19.1666 4.93737 19.1666 9.99998C19.1666 15.0626 15.0626 19.1666 9.99998 19.1666C4.93737 19.1666 0.833313 15.0626 0.833313 9.99998ZM9.16665 6.66665C9.16665 6.20641 9.53974 5.83331 9.99998 5.83331H10.0083C10.4685 5.83331 10.8416 6.20641 10.8416 6.66665C10.8416 7.12688 10.4685 7.49998 10.0083 7.49998H9.99998C9.53974 7.49998 9.16665 7.12688 9.16665 6.66665ZM9.99998 9.16665C10.4602 9.16665 10.8333 9.53974 10.8333 9.99998V13.3333C10.8333 13.7935 10.4602 14.1666 9.99998 14.1666C9.53974 14.1666 9.16665 13.7935 9.16665 13.3333V9.99998C9.16665 9.53974 9.53974 9.16665 9.99998 9.16665Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_1872_10720'>
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoCircle;
