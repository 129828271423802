import React, { useEffect, useState } from 'react';
import { Button, Icon, message, Modal, Tooltip } from 'antd';
import * as GraphQL from '~/graphql';
import { QuestionInput } from './Question';
import _ from 'lodash';
import ScottHr from '~/ScottHr';
import { colors, sizes } from '~/styles';
import Link from '~/Link';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import FlipMove from 'react-flip-move';
import LoadingOverlay from '~/Loading/Overlay';
import { recursivelyRemoveTypenames } from '~/services/apollo';
import Checkbox from '~/Checkbox';

export interface QuestionnaireProps {
  opsPreTeamEvent?: GraphQL.GenericReferenceInput;
  teamEventReview?: GraphQL.GenericReferenceInput;
  virtualEventReview?: GraphQL.GenericReferenceInput;
  defaultExperiencePreEventQuestionnaire?: GraphQL.GenericReferenceInput;
  defaultFor?: GraphQL.DefaultQuestionnaireFor;
  questionnaireDefaultType?: GraphQL.DefaultQuestionnaireFor;
  questionnaire?: { id: string; questions: GraphQL.QuestionFragment.Fragment[] };
  visible: boolean;
  onClose: any;
  refetch?: string[];
}

const createEmptyQuestion = (len?: number): GraphQL.QuestionInput => ({
  question: '',
  orderIndex: len || 0,
  questionType: GraphQL.QuestionType.Text,
  // Adding defaults because this 100% breaks questionnaires when not set
  scaleMin: 1,
  scaleMax: 5,
});

const asQuestionInput = ({
  question,
  stripId,
}: {
  question: any;
  stripId?: boolean;
}): GraphQL.QuestionInput => {
  const withoutAnswers = _.omit(question, 'answers');
  return recursivelyRemoveTypenames(stripId ? _.omit(withoutAnswers, 'id') : withoutAnswers);
};

const getTitleNoun = (props: QuestionnaireProps) => {
  if (props.opsPreTeamEvent) return strings.opsPreTeamEvent;
  if (props.virtualEventReview) return strings.virtualEventReview;
};

const removeUniqueIdentifier = (questions: object[]) => {
  return _.map(questions, q => _.omit(q, 'id'));
};

export const QuestionnaireDialog = (props: QuestionnaireProps) => {
  const {
    opsPreTeamEvent,
    teamEventReview,
    virtualEventReview,
    defaultExperiencePreEventQuestionnaire,
    defaultFor,
    questionnaireDefaultType,
    questionnaire,
    visible,
    onClose,
    refetch,
  } = props;

  const titleNoun = getTitleNoun(props);
  const titleVerb = questionnaire ? strings.edit : strings.create;
  const title = `${titleVerb} ${titleNoun ? titleNoun + ' ' : ''}${strings.questionnaire}`;

  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState(undefined as any);
  const [useDefaultQuestionnaire, setUseDefaultQuestionnaire] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (_.get(questionnaire, 'questions')) {
      const sorted = questionnaire.questions.sort((a, b) => a.orderIndex - b.orderIndex);
      setQuestions(sorted);
    }
  }, []);

  const { data } = useQuery<
    GraphQL.DefaultQuestionnaire.Query,
    GraphQL.DefaultQuestionnaire.Variables
  >(QuestionnaireDialog.DefaultQuestionnaireQuery, {
    variables: { defaultFor: questionnaireDefaultType },
  });

  useEffect(() => {
    const isDefault =
      questionnaire &&
      _.size(questionnaire.questions) &&
      _.isEqual(
        _.map(questionnaire.questions, 'id'),
        _.map(_.get(data, 'defaultQuestionnaire.questions'), 'id'),
      );

    setUseDefaultQuestionnaire(isDefault);
  }, [data]);

  useEffect(() => {
    setIsDirty(
      !_.isEqual(
        removeUniqueIdentifier(_.get(questionnaire, 'questions')),
        removeUniqueIdentifier(questions),
      ),
    );
  }, [questions, questionnaire]);

  useEffect(() => {
    if (useDefaultQuestionnaire) {
      setQuestions(_.get(data, 'defaultQuestionnaire.questions', []));
    }
  }, [useDefaultQuestionnaire]);

  const [createQuestionnaire] = useMutation<
    GraphQL.QuestionnaireDialogCreateQuestionnaire.Mutation,
    GraphQL.QuestionnaireDialogCreateQuestionnaire.Variables
  >(QuestionnaireDialog.createQuestionnaire, {
    refetchQueries: refetch,
  });

  const [updateQuestionnaire] = useMutation<
    GraphQL.QuestionnaireDialogUpdateQuestionnaire.Mutation,
    GraphQL.QuestionnaireDialogUpdateQuestionnaire.Variables
  >(QuestionnaireDialog.updateQuestionnaire);

  const [updateDefaultQuestionnaire] = useMutation<
    GraphQL.QuestionnaireDialogUpdateDefaultQuestionnaire.Mutation,
    GraphQL.QuestionnaireDialogUpdateDefaultQuestionnaire.Variables
  >(QuestionnaireDialog.updateDefaultQuestionnaire);

  return (
    <Modal
      width='75%'
      title={title}
      destroyOnClose={true}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key='cancel' onClick={onClose}>
          {strings.cancel}
        </Button>,
        <Button
          key='save'
          disabled={!isDirty}
          type='primary'
          onClick={async () => {
            setLoading(true);
            if (_.get(questionnaire, 'id')) {
              // We are updating the default questionnaire for this entity
              if (defaultFor) {
                await updateDefaultQuestionnaire({
                  variables: {
                    id: questionnaire.id,
                    questions: _.map(questions, q => {
                      return asQuestionInput({ question: q });
                    }),
                  },
                });
              } else {
                await updateQuestionnaire({
                  variables: {
                    questionnaireId: questionnaire.id,
                    opsPreTeamEvent,
                    teamEventReview,
                    virtualEventReview,
                    defaultExperiencePreEventQuestionnaire,
                    questions: _.map(questions, q => {
                      return asQuestionInput({ question: q });
                    }),
                  },
                });
              }
              message.success('Questionnaire updated!');
            } else {
              await createQuestionnaire({
                variables: {
                  opsPreTeamEvent,
                  teamEventReview,
                  virtualEventReview,
                  defaultExperiencePreEventQuestionnaire,
                  defaultFor,
                  questions: _.map(questions, q => asQuestionInput({ question: q, stripId: true })),
                },
              });
              message.success('Questionnaire created!');
            }
            setLoading(false);
            onClose();
          }}
        >
          {strings.save}
        </Button>,
      ]}
    >
      <LoadingOverlay visible={loading} />
      <div css={{ marginBottom: sizes.Spacing.Medium }}>
        {questionnaireDefaultType && (
          <Checkbox
            checked={useDefaultQuestionnaire}
            onChange={e => setUseDefaultQuestionnaire(e.target.checked)}
          >
            {strings.useDefault}
          </Checkbox>
        )}
        <Link
          href='#'
          css={{
            fontSize: 18,
            alignItems: 'center',
          }}
          onClick={e => {
            e.preventDefault();
            const newQuestion = createEmptyQuestion(_.size(questions));
            const existingQuestions = questions || [];
            setQuestions([...existingQuestions, newQuestion]);
          }}
        >
          <Icon css={{ color: colors.AntdBlue }} type='plus' />
          <span
            css={{
              marginLeft: sizes.Spacing.Small,
            }}
          >
            {strings.addQuestion}
          </span>
        </Link>
      </div>
      <FlipMove>
        {_.map(questions, (question, i: number) => (
          <div key={`question-${i}`}>
            <QuestionInput
              question={question}
              index={i}
              disabled={useDefaultQuestionnaire}
              questionCount={_.size(questions)}
              setQuestion={newQuestion => {
                const updatedQuestions = [...questions];
                // Removing the id prevents updating the defaultQuestionnaire's question and instead creates
                // a new question
                updatedQuestions[i] = _.omit(newQuestion, 'id');
                setQuestions(updatedQuestions);
              }}
              deleteQuestion={() => {
                const updatedQuestions = [...questions];
                updatedQuestions.splice(i, 1);
                setQuestions(_.size(updatedQuestions) ? updatedQuestions : undefined);
              }}
              reorderQuestion={up => {
                const delta = up ? -1 : 1;

                const updatedQuestions = [...questions];
                const questionToSwap = updatedQuestions[i + delta];
                const thisQuestion = updatedQuestions[i];
                updatedQuestions[i] = {
                  ...questionToSwap,
                  orderIndex: questionToSwap.orderIndex - delta,
                };
                updatedQuestions[i + delta] = {
                  ...thisQuestion,
                  orderIndex: thisQuestion.orderIndex + delta,
                };
                setQuestions(updatedQuestions);
              }}
            />
            {i < questions.length - 1 && <ScottHr />}
          </div>
        ))}
      </FlipMove>
    </Modal>
  );
};

QuestionnaireDialog.fragment = gql`
  fragment QuestionFragment on Question {
    id
    options
    scaleMin
    scaleMax
    orderIndex
    question
    questionType
    required
    requesterOnly
  }
`;

QuestionnaireDialog.createQuestionnaire = gql`
  mutation QuestionnaireDialogCreateQuestionnaire(
    $opsPreTeamEvent: GenericReferenceInput
    $teamEventReview: GenericReferenceInput
    $defaultExperiencePreEventQuestionnaire: GenericReferenceInput
    $defaultFor: DefaultQuestionnaireFor
    $questions: [QuestionInput]
  ) {
    createQuestionnaire(
      questions: $questions
      opsPreTeamEvent: $opsPreTeamEvent
      teamEventReview: $teamEventReview
      defaultExperiencePreEventQuestionnaire: $defaultExperiencePreEventQuestionnaire
      defaultFor: $defaultFor
    ) {
      id
    }
  }
`;

QuestionnaireDialog.updateQuestionnaire = gql`
  mutation QuestionnaireDialogUpdateQuestionnaire(
    $questionnaireId: ID!
    $opsPreTeamEvent: GenericReferenceInput
    $teamEventReview: GenericReferenceInput
    $virtualEventReview: GenericReferenceInput
    $defaultExperiencePreEventQuestionnaire: GenericReferenceInput
    $questions: [QuestionInput]
  ) {
    updateQuestionnaire(
      id: $questionnaireId
      opsPreTeamEvent: $opsPreTeamEvent
      teamEventReview: $teamEventReview
      virtualEventReview: $virtualEventReview
      defaultExperiencePreEventQuestionnaire: $defaultExperiencePreEventQuestionnaire
      questions: $questions
    ) {
      id
    }
  }
`;

QuestionnaireDialog.updateDefaultQuestionnaire = gql`
  mutation QuestionnaireDialogUpdateDefaultQuestionnaire($id: ID!, $questions: [QuestionInput]) {
    updateDefaultQuestionnaire(id: $id, questions: $questions) {
      id
    }
  }
`;

QuestionnaireDialog.DefaultQuestionnaireQuery = gql`
  query DefaultQuestionnaire($defaultFor: DefaultQuestionnaireFor) {
    defaultQuestionnaire(defaultFor: $defaultFor) {
      id
      questions {
        id
        ...QuestionFragment
      }
    }
  }
  ${QuestionnaireDialog.fragment}
`;

const strings = {
  addQuestion: 'Add question',
  useDefault: 'Use Default Questionnaire',
  cancel: 'Cancel',
  create: 'Create',
  edit: 'Edit',
  questionnaire: 'Questionnaire',
  save: 'Save',
  opsPreTeamEvent: 'Team Event',
  virtualEventReview: 'Virtual Event',
};
