import * as Humanize from 'humanize-plus';
import _ from 'lodash';
import React from 'react';
import { Column as RVColumn } from 'react-virtualized';

import { colors, font } from '~/styles';
import { ColumnProps } from './types';

const renderColumn = ({
  key,
  width,
  cellRenderer,
  label,
  noHeader,
}: ColumnProps) => {
  return (
    <RVColumn
      key={key}
      dataKey={key}
      cellDataGetter={({ dataKey, rowData }) => _.get(rowData, dataKey)}
      headerRenderer={({ label }) =>
        noHeader ? (
          ''
        ) : (
          <span
            css={{
              fontWeight: font.FontWeight.Demi,
              color: colors.OffBlack,
              textTransform: 'initial',
            }}
          >
            {label}
          </span>
        )
      }
      flexGrow={1}
      width={width}
      cellRenderer={({ cellData, rowData }) => cellRenderer(cellData, rowData)}
      label={
        label ||
        _.words(key)
          .map(Humanize.titleCase)
          .join(' ')
      }
    />
  );
};

export default renderColumn;
