import React from 'react';

import ActivityFeed from '.';
import FiltersBarToggle from '~/FiltersBar/Toggle';
import Page from '../Page';
import FiltersBar from '~/FiltersBar';
import FilterSupplyEntities from '~/Filter/SupplyEntities';
import FilterActor from '~/Filter/Owners';

const FILTER_SET_KEY = 'ActivityFeedFiltersVisible';

const ActivityFeedPage = () => {
  return (
    <Page title='Activity Feed' rightContent={<FiltersBarToggle filtersetKey={FILTER_SET_KEY} />}>
      <>
        <FiltersBar filtersetKey={FILTER_SET_KEY}>
          <FilterSupplyEntities />
          <FilterActor parameterName='actorId' isSingleSelect={true} title='Actor' />
        </FiltersBar>
        <ActivityFeed />
      </>
    </Page>
  );
};

export default ActivityFeedPage;
