import gql from 'graphql-tag';

import { makeQueryBasedSelect } from './QueryBasedSelect';

const SelectAccessibilityTypes = makeQueryBasedSelect(
  gql`
    query SelectAccessibilityTypes {
      accessibilityTypes {
        id
        name
      }
    }
  `,
  'accessibilityTypes',
);

export default SelectAccessibilityTypes;
