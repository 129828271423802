import React, { useState } from 'react';
import moment from 'moment-timezone';
import { addSpacesToPascalCasedString, formatTimeRange } from '~/formatters';
import Text from '~/Text';

import * as GraphQL from '~/graphql';
import _ from 'lodash';
import Link from '~/Link';
import EntityLink from '../EntityLink';
import EventDialog from '~/EventDialog';

const EventCell = ({ actor, metadata }: GraphQL.AuditLogCell.Fragment) => {
  const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);

  const actorName = actor.firstName;
  const beforeMetadata = _.get(metadata, 'before.event');
  const afterMetadata = _.get(metadata, 'after.event');

  const eventLink = (
    <Link onClick={() => setIsEventDialogOpen(true)} css={{ fontWeight: 400 }}>
      event
    </Link>
  );

  let text;
  if (!beforeMetadata && afterMetadata && afterMetadata.start) {
    text = (
      <Text size='Small'>
        {`${actorName} created an `}
        {eventLink}
        {` for `}
        <EntityLink
          entityType='Xperience'
          entityId={afterMetadata.experience.id}
          experienceAtPartnerText={true}
        />
        {` on ${moment(afterMetadata.start).format('MMM Do')} from ${formatTimeRange(
          afterMetadata.start,
          afterMetadata.end,
        )} with ${afterMetadata.totalCapacity} ${afterMetadata.capacityType} capacity.`}
      </Text>
    );
  } else if (beforeMetadata && !afterMetadata) {
    text = (
      <Text size='Small'>
        {`${actorName} deleted an event for `}
        <EntityLink
          entityType='Xperience'
          entityId={beforeMetadata.experience.id}
          experienceAtPartnerText={true}
        />{' '}
        {` on ${moment(beforeMetadata.start).format('MMM Do')} from ${formatTimeRange(
          beforeMetadata.start,
          beforeMetadata.end,
        )}.`}
      </Text>
    );
  } else {
    if (afterMetadata && afterMetadata.start) {
      text = (
        <Text size='Small'>
          {`${actorName} updated an `}
          {eventLink}
          {` for ${_.get(afterMetadata, 'experience.name', 'n/a')} @ ${_.get(
            afterMetadata,
            'experience.partner.name',
            'n/a',
          )} on ${moment(afterMetadata.start).format('MMM Do')} from ${formatTimeRange(
            afterMetadata.start,
            afterMetadata.end,
          )}.`}
        </Text>
      );
    }
  }

  return (
    <>
      {text}
      {afterMetadata && (
        <EventDialog
          isOpen={isEventDialogOpen}
          close={() => setIsEventDialogOpen(false)}
          experienceId={_.get(afterMetadata, 'experience.id')}
          eventId={afterMetadata.id}
        />
      )}
    </>
  );
};

export default EventCell;
