import React, { useState } from 'react';
import { Button } from 'antd';
import EventDialog from '~/EventDialog';

const AddNewEventButton = ({
  experienceId,
  refetchQueries,
  ...otherProps
}: {
  refetchQueries?: string[];
  experienceId?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const queriesToRefetch = refetchQueries || ['EventsList'];
  return (
    <>
      <Button type='primary' onClick={() => setIsOpen(true)} {...otherProps}>
        Add Event
      </Button>
      <EventDialog
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        refetchQueries={queriesToRefetch}
        experienceId={experienceId}
      />
    </>
  );
};

export default AddNewEventButton;
