import React from 'react';
import { OrganizationUpdateData } from '../Edit';
import { TypeSettingSwitch } from './CustomerType/CustomerTypeTab';

export const BetasTab = ({
  updatedData,
  setUpdatedData,
}: {
  updatedData: OrganizationUpdateData;
  setUpdatedData: (data: OrganizationUpdateData) => void;
}) => {
  return (
    <>
      <TypeSettingSwitch
        label='Pulse Beta is'
        checked={updatedData.isPulseBetaEnabled}
        onChange={() => {
          setUpdatedData({
            ...updatedData,
            isPulseBetaEnabled: !updatedData.isPulseBetaEnabled,
          });
        }}
      />
      <div css={{ marginTop: 10 }}>
        <TypeSettingSwitch
          label='Meetups Beta is'
          checked={updatedData.isMeetupsBetaEnabled}
          onChange={() => {
            setUpdatedData({
              ...updatedData,
              isMeetupsBetaEnabled: !updatedData.isMeetupsBetaEnabled,
            });
          }}
        />
      </div>
    </>
  );
};
