import { Input, Select } from 'antd';
import gql from 'graphql-tag';
import { countries } from 'i18n-iso-countries/langs/en.json';
import React from 'react';
import states from 'states-us';

import * as GraphQL from '~/graphql';
import { Edits } from '~/hooks/useEditableEntity';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import Row from '~/Row';

import Heading from '../Heading';
import LabeledSwitch from '~/Input/LabeledSwitch';
import InputNumberWithUnit from '~/Input/NumberWithUnit';
import MoneyInput from '~/Input/Money';

export interface Props {
  experience: GraphQL.ExperienceProfileShipping.Fragment;
  onChange: (edits: Edits<GraphQL.ExperienceProfileShipping.Fragment>) => void;
}

const ExperienceProfileShipping = ({ experience, onChange }: Props) => {
  return (
    <div css={{ maxWidth: 600 }}>
      <Heading>Messaging</Heading>
      <Row>
        <LabeledField label='Goods Description'>
          <Input.TextArea
            rows={6}
            value={experience.physicalGoodsDescription}
            onChange={event => onChange({ physicalGoodsDescription: event.target.value })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Shipping Customizations'>
          <Input.TextArea
            rows={6}
            value={experience.shippingCustomizations}
            onChange={event => onChange({ shippingCustomizations: event.target.value })}
          />
        </LabeledField>
      </Row>
      <USAShipping
        usaShipping={experience.usaShipping}
        onChange={usaShipping => onChange({ usaShipping })}
      />
      <InternationalShipping
        internationalShipping={experience.internationalShipping}
        onChange={internationalShipping => onChange({ internationalShipping })}
      />
    </div>
  );
};

const USAShipping = ({
  usaShipping,
  onChange,
}: {
  usaShipping: Props['experience']['usaShipping'];
  onChange: (edits: Props['experience']['usaShipping']) => void;
}) => {
  const { __typename, ...shipping } = usaShipping;

  return (
    <>
      <Heading>USA Shipping</Heading>
      <Row>
        <LabeledField label='Available'>
          <LabeledSwitch
            checked={shipping.enabled}
            onChange={enabled => onChange({ ...shipping, enabled })}
            checkedLabel='Yes'
            uncheckedLabel='No'
          />
        </LabeledField>
        <LabeledField label='Lead Time'>
          <InputNumberWithUnit
            value={shipping.estimatedLeadTimeDays}
            onChange={estimatedLeadTimeDays => onChange({ ...shipping, estimatedLeadTimeDays })}
            unit='business days'
          />
        </LabeledField>
        <LabeledField label='Cost Estimate'>
          <MoneyInput
            size='default'
            value={shipping.estimatedCostCents}
            onChange={estimatedCostCents => onChange({ ...shipping, estimatedCostCents })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Non-Shippable Locations'>
          <Select
            mode='multiple'
            value={shipping.excludeStates}
            onChange={excludeStates => onChange({ ...shipping, excludeStates })}
          >
            {states.map(({ name, abbreviation }) => (
              <Select.Option key={abbreviation}>{name}</Select.Option>
            ))}
          </Select>
        </LabeledField>
      </Row>
    </>
  );
};

const InternationalShipping = ({
  internationalShipping,
  onChange,
}: {
  internationalShipping: Props['experience']['internationalShipping'];
  onChange: (edits: Props['experience']['internationalShipping']) => void;
}) => {
  const { __typename, ...shipping } = internationalShipping;

  return (
    <>
      <Heading>International Shipping</Heading>
      <Row>
        <LabeledField label='Available'>
          <LabeledSwitch
            checked={shipping.enabled}
            onChange={enabled => onChange({ ...shipping, enabled })}
            checkedLabel='Yes'
            uncheckedLabel='No'
          />
        </LabeledField>
        <LabeledField label='Lead Time'>
          <InputNumberWithUnit
            value={shipping.estimatedLeadTimeDays}
            onChange={estimatedLeadTimeDays => onChange({ ...shipping, estimatedLeadTimeDays })}
            unit='business days'
          />
        </LabeledField>
        <LabeledField label='Cost Estimate'>
          <MoneyInput
            size='default'
            value={shipping.estimatedCostCents}
            onChange={estimatedCostCents => onChange({ ...shipping, estimatedCostCents })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Non-Shippable Locations'>
          <Select
            mode='multiple'
            value={shipping.excludeCountries}
            onChange={excludeCountries => onChange({ ...shipping, excludeCountries })}
          >
            {Object.entries(countries).map(([abbreviation, name]) => (
              <Select.Option key={abbreviation}>{name}</Select.Option>
            ))}
          </Select>
        </LabeledField>
      </Row>
    </>
  );
};

ExperienceProfileShipping.fragment = gql`
  fragment ExperienceProfileShipping on Xperience {
    id
    physicalGoodsDescription
    shippingCustomizations
    usaShipping {
      enabled
      estimatedLeadTimeDays
      estimatedCostCents
      excludeStates
    }
    internationalShipping {
      enabled
      estimatedLeadTimeDays
      estimatedCostCents
      excludeCountries
    }
  }
`;

export default ExperienceProfileShipping;
