import _ from 'lodash';
import React from 'react';
import { InfiniteLoader, List, Table } from 'react-virtualized';
import FillRemaining from '~/FillRemaining';
import useMobile from '~/hooks/useMobile';
import LoadingOverlay from '~/Loading/Overlay';
import { memo } from '~/react';
import { colors } from '~/styles';
import renderColumn from './columns';
import usePaginatedTableHooks from './hooks';
import { PaginatedTableProps, PaginatedVariables } from './types';

const PaginatedTable = <TQuery, TVariables extends PaginatedVariables>({
  columns,
  listRowRenderer,
  rowHeight,
  listRowHeight,
  headerHeight,
  headerStyle,
  rowStyle,
  ...otherProps
}: PaginatedTableProps<TVariables>) => {
  const {
    containerRef,
    containerSize,
    rowCount,
    rows,
    loadNextPage,
    hasNextPage,
    loading,
  } = usePaginatedTableHooks<TQuery, TVariables>(otherProps);

  const isPhone = useMobile();

  const renderMobileList = ({ registerChild, onRowsRendered }: any) => (
    <List
      width={containerSize.width}
      height={containerSize.height}
      ref={registerChild}
      rowCount={_.size(rows)}
      onRowsRendered={onRowsRendered}
      noContentRenderer={() => <span>Empty row!</span>}
      rowHeight={listRowHeight}
      overscanRowCount={10}
      rowRenderer={_.partial(listRowRenderer, rows)}
      {...otherProps}
    />
  );

  const renderDesktopTable = ({ registerChild, onRowsRendered }: any) => (
    <Table
      width={containerSize.width}
      height={containerSize.height}
      ref={registerChild}
      rowCount={_.size(rows)}
      onRowsRendered={onRowsRendered}
      rowGetter={({ index }) => rows[index]}
      noContentRenderer={() => <span>Empty row!</span>}
      rowStyle={{
        borderBottom: `1px solid ${colors.DarkSnow}`,
        ...rowStyle,
      }}
      rowHeight={rowHeight}
      headerHeight={headerHeight}
      headerStyle={headerStyle}
      overscanRowCount={10}
      {...otherProps}
    >
      {_.map(columns, renderColumn)}
    </Table>
  );

  return (
    <FillRemaining>
      <div
        css={{ width: '100%', height: '100%', position: 'relative' }}
        ref={containerRef}
        {..._.pick(otherProps, 'css')}
      >
        <InfiniteLoader
          threshold={10}
          minimumBatchSize={20}
          rowCount={rowCount}
          loadMoreRows={loadNextPage}
          isRowLoaded={({ index }) => !hasNextPage || index < _.size(rows)}
        >
          {isPhone ? renderMobileList : renderDesktopTable}
        </InfiniteLoader>
        <LoadingOverlay visible={loading} />
      </div>
    </FillRemaining>
  );
};

export default memo(PaginatedTable) as typeof PaginatedTable;
