import { Icon } from 'antd';
import React from 'react';
import gql from 'graphql-tag';

import LabeledSection from '~/LabeledSection';
import Link from '~/Link';
import Text from '~/Text';
import ScottHr from '~/ScottHr';
import { colors, sizes } from '~/styles';
import * as GraphQL from '~/graphql';
import ExperienceStatus from '~/ExperienceStatus';

import AddExperienceButton from '../AddExperience';

const PartnerExperiences = (props: {
  partnerId: string;
  experiences: GraphQL.PartnerExperiences.Fragment[];
  history: any;
}) => {
  return (
    <LabeledSection
      label={'Experiences'}
      viewParts={[
        () => (
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            {props.experiences.map((exp, i) => (
              <React.Fragment key={`experience-item-${i}`}>
                <ExperienceItem
                  pathToExperienceProfile={`/supply/partner/${props.partnerId}/experience/${
                    exp.id
                  }`}
                  experience={exp}
                />
                <ScottHr />
              </React.Fragment>
            ))}
            <AddExperienceButton {...props} />
          </div>
        ),
      ]}
    />
  );
};

export default PartnerExperiences;

const ExperienceItem = (props: {
  pathToExperienceProfile: string;
  experience: GraphQL.PartnerExperiences.Fragment;
}) => {
  return (
    <div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div css={{ flex: 1 }}>
          <Link href={props.pathToExperienceProfile} useReactRouterLink>
            {props.experience.name}
          </Link>
        </div>
        <div>
          {props.experience.isActive ? (
            <div css={{ color: colors.DarkGreen }}>
              Active
              <Icon type='check-circle' css={{ paddingLeft: sizes.Spacing.XXSmall }} />
            </div>
          ) : (
            <div css={{ color: colors.Silver }}>
              Inactive
              <Icon type='pause-circle' css={{ paddingLeft: sizes.Spacing.XXSmall }} />
            </div>
          )}
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          marginTop: sizes.Spacing.XXSmall,
        }}
      >
        <Icon type='clock-circle' css={{ marginRight: sizes.Spacing.XSmall, fontSize: 16 }} />
        <Text size='Small'>{`${
          props.experience.estimatedDurationMins
        } minute estimated duration`}</Text>
      </div>
    </div>
  );
};

PartnerExperiences.fragment = gql`
  fragment PartnerExperiences on Xperience {
    id
    name
    isActive
    estimatedDurationMins
    ...ExperienceStatus
  }
  ${ExperienceStatus.fragment}
`;
