import { Modal, Select } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useState } from 'react';
import { useApolloClient } from 'react-apollo';
import { useFormState } from 'react-use-form-state';

import * as GraphQL from '~/graphql';
import Input from '~/Input';
import PhoneInput from '~/Input/Phone';
import LabeledField from '~/Labeled/Field';
import { sizes } from '~/styles';

interface Props {
  user?: GraphQL.EditUserModal.Fragment;
  onClose: () => void;
  open: boolean;
  defaultRole?: GraphQL.Role;
  afterSave?(user: Partial<GraphQL.User>): void;
}

const EditUserModal = ({ user, onClose, open, defaultRole, afterSave }: Props) => {
  const [loading, setLoading] = useState(false);

  const defaultValues: Partial<GraphQL.EditUserModal.Fragment> = {
    role: defaultRole || GraphQL.Role.Muggle,
  };

  const [formState, { text, email, tel, select, checkbox }] = useFormState<
    GraphQL.EditUserModal.Fragment
  >({
    ...defaultValues,
    ..._.pick(
      user,
      'id',
      'firstName',
      'lastName',
      'phone',
      'email',
      'role',
      'internalSlackId',
      'isServiceAccount',
    ),
  });

  const client = useApolloClient();

  const onPressSave = async () => {
    setLoading(true);
    try {
      const variables = user ? { ...formState.values, id: user.id } : _.cloneDeep(formState.values);

      _.forIn(variables, (val, key) => {
        // Use `null` instead of empty strings (which can be dangerous!)
        if (val === '') variables[key] = null;
      });

      const { data } = await client.mutate({
        mutation: user ? EditUserModal.updateMutation : EditUserModal.createMutation,
        variables,
      });
      if (afterSave) afterSave(user ? data.updateUser : data.createUser);
      setLoading(false);
      onClose();
    } catch (e) {
      setLoading(false);
    }
  };

  // This is broken out so that we can effectively override the `onChange`
  // handler so that we can pass it an event like it expects (as opposed to a
  // value, like antd's Select component gives us)
  const roleSelectProps = select('role');

  return (
    <Modal
      destroyOnClose
      afterClose={() => formState.reset()}
      title={user ? strings.editTitle : strings.addTitle}
      visible={open}
      confirmLoading={loading}
      okText={user ? strings.save : strings.addUser}
      onCancel={onClose}
      onOk={onPressSave}
    >
      <Row>
        <LabeledField label={strings.firstName}>
          <Input {...text('firstName')} />
        </LabeledField>
        <LabeledField label={strings.lastName}>
          <Input {...text('lastName')} />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label={strings.phone}>
          <PhoneInput {...tel('phone')} />
        </LabeledField>
        <LabeledField label={strings.email}>
          <Input {...email('email')} />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label={strings.internalSlackId}>
          <Input {...text('internalSlackId')} />
        </LabeledField>
        <LabeledField label={strings.isServiceAccount}>
          <Input {...checkbox('isServiceAccount')} />
        </LabeledField>
      </Row>
      <LabeledField label={strings.role}>
        <Select
          {...roleSelectProps}
          onChange={(value: string) => {
            roleSelectProps.onChange({
              target: { value, validity: { valid: true } },
            });
          }}
          size='large'
          css={{ width: sizes.GRID_UNIT * 30 }}
        >
          {_.map([GraphQL.Role.Muggle, GraphQL.Role.HouseElf, GraphQL.Role.Wizard], (role, i) => (
            <Select.Option key={i} value={role}>
              {_.words(role)
                .map(_.capitalize)
                .join(' ')}
            </Select.Option>
          ))}
        </Select>
      </LabeledField>
    </Modal>
  );
};

interface RowProps {
  children?: React.ReactNode;
}

const Row = ({ children }: RowProps) => (
  <div
    css={{
      display: 'flex',
      '> *': { flex: 1 },
      '> *:not(:last-child)': { marginRight: sizes.Spacing.XSmall },
    }}
  >
    {children}
  </div>
);

EditUserModal.fragment = gql`
  fragment EditUserModal on User {
    id
    firstName
    lastName
    phone
    email
    role
    internalSlackId
    isServiceAccount
  }
`;

EditUserModal.createMutation = gql`
  mutation CreateUser(
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $role: Role
    $isServiceAccount: Boolean
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      role: $role
      isServiceAccount: $isServiceAccount
    ) {
      ...EditUserModal
    }
  }

  ${EditUserModal.fragment}
`;

EditUserModal.updateMutation = gql`
  mutation UpdateUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $role: Role
    $internalSlackId: String
    $isServiceAccount: Boolean
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      role: $role
      internalSlackId: $internalSlackId
      isServiceAccount: $isServiceAccount
    ) {
      ...EditUserModal
    }
  }

  ${EditUserModal.fragment}
`;

const strings = {
  editTitle: `Edit User`,
  internalSlackId: `Internal Slack ID`,
  isServiceAccount: `Service Account`,
  addTitle: `Add User`,
  save: `Save`,
  addUser: `Add User`,
  firstName: `First name`,
  lastName: `Last name`,
  phone: `Phone`,
  email: `Email`,
  role: `Role`,
};

export default EditUserModal;
