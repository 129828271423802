import { Icon, Input } from 'antd';
import gql from 'graphql-tag';
import React from 'react';

import * as GraphQL from '~/graphql';
import { Edits } from '~/hooks/useEditableEntity';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import Row from '~/Row';
import PriceLevel from '~/Experiences/PriceLevel';
import InputNumberWithUnit from '~/Input/NumberWithUnit';

import Heading from '../../Heading';
import LabeledSwitch from '~/Input/LabeledSwitch';

export interface Props {
  experience: GraphQL.ExperienceProfileSummaryDetails.Fragment;
  onChange: (edits: Edits<GraphQL.ExperienceProfileSummaryDetails.Fragment>) => void;
}

const ExperienceProfileSummaryDetails = ({ experience, onChange }: Props) => {
  return (
    <div>
      <Heading>Summary</Heading>
      <Row>
        <LabeledField label='Is Virtual'>
          <LabeledSwitch
            checked={experience.isVirtual}
            onChange={isVirtual => onChange({ isVirtual })}
            checkedLabel={
              <>
                <Icon type='laptop' /> Yes
              </>
            }
            uncheckedLabel={
              <>
                <Icon type='team' /> No
              </>
            }
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Experience Instructions'>
          <Input.TextArea
            rows={6}
            value={experience.experienceInstructions}
            onChange={event => onChange({ experienceInstructions: event.target.value })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Estimated Duration'>
          <InputNumberWithUnit
            value={experience.estimatedDurationMins}
            onChange={estimatedDurationMins => onChange({ estimatedDurationMins })}
            unit='minutes'
          />
        </LabeledField>
        <LabeledField label='Number of Hosts'>
          <InputNumberWithUnit
            value={experience.numHosts}
            onChange={numHosts => onChange({ numHosts })}
            unit='hosts'
          />
        </LabeledField>
        <LabeledField label='Price'>
          <PriceLevel
            level={experience.priceLevel}
            onChange={priceLevel => onChange({ priceLevel })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Minimum Group Size'>
          <InputNumberWithUnit
            value={experience.numMinGuests}
            onChange={numMinGuests => onChange({ numMinGuests })}
            unit='guests'
          />
        </LabeledField>
        <LabeledField label='Maximum Group Size'>
          <InputNumberWithUnit
            value={experience.numMaxGuests}
            onChange={numMaxGuests => onChange({ numMaxGuests })}
            unit='guests'
          />
        </LabeledField>
      </Row>
      {experience.isVirtual && (
        <Row>
          <LabeledField label='Breakout Rooms?' css={{ flex: 1 }}>
            <LabeledSwitch
              checked={Number.isFinite(experience.breakoutRoomSize)}
              onChange={hasBreakoutRooms =>
                onChange({
                  breakoutRoomSize: hasBreakoutRooms ? experience.breakoutRoomSize || 0 : null,
                })
              }
              checkedLabel='Yes'
              uncheckedLabel='No'
            />
          </LabeledField>
          {Number.isFinite(experience.breakoutRoomSize) && (
            <LabeledField label='Breakout Room Size' css={{ flex: 2 }}>
              <InputNumberWithUnit
                value={experience.breakoutRoomSize}
                onChange={breakoutRoomSize => onChange({ breakoutRoomSize })}
                unit='guests per room'
              />
            </LabeledField>
          )}
        </Row>
      )}
    </div>
  );
};

ExperienceProfileSummaryDetails.fragment = gql`
  fragment ExperienceProfileSummaryDetails on Xperience {
    id
    breakoutRoomSize
    estimatedDurationMins
    experienceInstructions
    isVirtual
    numHosts
    numMaxGuests
    numMinGuests
    priceLevel
  }
`;

export default ExperienceProfileSummaryDetails;
