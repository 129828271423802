import React from 'react';
import _ from 'lodash';
import { ExcludeKeys } from 'tsdef';
import { InputProps } from 'antd/lib/input';
import NumberFormat from 'react-number-format';

import Input from '~/Input';

type Props = {
  defaultValue?: any;
  onChange: (value: number) => void;
  value?: any;
  [key: string]: any;
} & ExcludeKeys<
  InputProps,
  'onChange' | 'value' | 'defaultValue' | 'prefix' | 'type'
>;

const PercentInput = (props: Props) => {
  const { onChange, defaultValue, value, ...safeProps } = props;
  return (
    <NumberFormat
      {...safeProps as any}
      suffix={'%'}
      value={value === '0' ? undefined : value}
      placeholder={'0%'}
      onBlur={() => onChange(_.toNumber(value))}
      customInput={Input}
      onValueChange={({ value }) => {
        onChange(_.toNumber(value));
      }}
    />
  );
};

export default PercentInput;
