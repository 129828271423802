import _ from 'lodash';
import React, { useState } from 'react';
import { sizes } from '~/styles';
import Text from '~/Text';
import moment from 'moment-timezone';
import { TimePicker, Icon } from 'antd';
import { weekdayOrder } from '.';
import Link from '~/Link';

const COLUMN_WIDTH = 104;
const strings = {
  placeholder: '',
};

type OpenAndClose = { open: moment.Moment; close: moment.Moment } | undefined;

type OperatingHoursLayer = {
  Sun: OpenAndClose;
  Mon: OpenAndClose;
  Tues: OpenAndClose;
  Wed: OpenAndClose;
  Thu: OpenAndClose;
  Fri: OpenAndClose;
  Sat: OpenAndClose;
};

type Props = {
  operatingHours: OperatingHoursLayer[];
  setOperatingHours(newOperatingHours: OperatingHoursLayer[]): void;
};

const OperatingHoursEditor = (props: Props) => {
  const [layers, setLayers] = useState(props.operatingHours);
  const setValuesForDayInLayer = (
    layerIndex: number,
    dayOfWeek: string,
    openAndClose: OpenAndClose,
  ) => {
    const newLayers = _.cloneDeep(layers);
    newLayers[layerIndex] = { ...newLayers[layerIndex], [dayOfWeek]: openAndClose };
    setLayers(newLayers);
    props.setOperatingHours(newLayers);
  };
  const numOfLayers = layers.length;
  return (
    <div>
      {_.map(layers, (layer, i) => (
        <>
          <OperatingHoursLayer
            layerIndex={i}
            values={layer}
            setValuesForDayInLayer={(...args) => setValuesForDayInLayer(i, ...args)}
          />
          {i + 1 === numOfLayers ? (
            <Link
              onClick={() =>
                setLayers([
                  ...layers,
                  {
                    Sun: undefined,
                    Mon: undefined,
                    Tues: undefined,
                    Wed: undefined,
                    Thu: undefined,
                    Fri: undefined,
                    Sat: undefined,
                  },
                ])
              }
            >
              <Icon type='plus-circle' />
              &nbsp; Add hours
            </Link>
          ) : (
            <hr
              css={{
                opacity: 0.2,
                marginTop: sizes.Spacing.XLarge,
                marginBottom: sizes.Spacing.Large,
              }}
            />
          )}
        </>
      ))}
    </div>
  );
};

export default OperatingHoursEditor;

const OperatingHoursLayer = (props: {
  layerIndex: number;
  values: OperatingHoursLayer;
  setValuesForDayInLayer(dayOfWeek: string, openAndClose: OpenAndClose): void;
}) => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: sizes.Spacing.Medium,
        '> *:not(:last-child)': { marginRight: sizes.Spacing.XSmall },
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: COLUMN_WIDTH / 2,
          height: 88,
        }}
      >
        <Text
          size='XSmall'
          weight='Demi'
          css={{
            textAlign: 'center',
            textTransform: 'uppercase',
            marginBottom: sizes.Spacing.XXSmall,
            opacity: 0.5,
          }}
        >
          &nbsp;
        </Text>
        <Text
          size='XSmall'
          weight='Demi'
          css={{
            height: '32px',
            lineHeight: '32px',
            textTransform: 'uppercase',
            opacity: 0.5,
            marginBottom: sizes.Spacing.XXSmall,
          }}
        >
          Start
        </Text>
        <Text
          size='XSmall'
          weight='Demi'
          css={{
            height: '32px',
            lineHeight: '32px',
            textTransform: 'uppercase',
            opacity: 0.5,
          }}
        >
          End
        </Text>
      </div>
      {_.map(weekdayOrder, weekdayName => {
        const openAndClose = props.values[weekdayName];
        return (
          <LayerColumn
            layerIndex={props.layerIndex}
            weekdayName={weekdayName}
            {...openAndClose}
            onChangeOpen={newValue =>
              props.setValuesForDayInLayer(weekdayName, {
                ...openAndClose,
                open: newValue,
              })
            }
            onChangeClose={newValue =>
              props.setValuesForDayInLayer(weekdayName, {
                ...openAndClose,
                close: newValue,
              })
            }
          />
        );
      })}
    </div>
  );
};

const LayerColumn = (props: {
  layerIndex: number;
  weekdayName: string;
  open?: moment.Moment;
  close?: moment.Moment;
  onChangeOpen(newOpen: moment.Moment): void;
  onChangeClose(newClose: moment.Moment): void;
}) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column', width: COLUMN_WIDTH }}>
      <Text
        size='XSmall'
        weight='Demi'
        css={{
          textAlign: 'center',
          textTransform: 'uppercase',
          marginBottom: sizes.Spacing.XXSmall,
          opacity: 0.5,
        }}
      >
        {props.weekdayName}
      </Text>
      <div data-testid={`${props.layerIndex}-${props.weekdayName}-open`}>
        <TimePicker
          use12Hours
          minuteStep={5}
          placeholder={strings.placeholder}
          defaultValue={props.open}
          format='h:mm a'
          style={{ width: COLUMN_WIDTH, marginBottom: sizes.Spacing.XXSmall }}
          onChange={time => props.onChangeOpen(time)}
        />
      </div>
      <div data-testid={`${props.layerIndex}-${props.weekdayName}-close`}>
        <TimePicker
          use12Hours
          minuteStep={5}
          placeholder={strings.placeholder}
          defaultValue={props.close}
          format='h:mm a'
          style={{ width: COLUMN_WIDTH }}
          onChange={time => props.onChangeClose(time)}
        />
      </div>
    </div>
  );
};
