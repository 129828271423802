import { useMemo } from 'react';

import { TagInput } from '~/graphql';
import TagsSelect from '~/Search/Tags';

import { DataTableFilter, GraphQLFiltersInput } from '../interface';
import { KeysOfType } from '../utilTypes';

/**
 * A <DataTable> filter that displays a dropdown where you can select multiple
 * tags to filter to.
 */
export function tagFilter<
  TFilters extends GraphQLFiltersInput,
  TFilterKey extends KeysOfType<TFilters, TagInput[]> = KeysOfType<TFilters, TagInput[]>
>(key: TFilterKey, title = 'Includes Tags'): DataTableFilter<TFilters, TFilterKey, TagInput[]> {
  return {
    field: key,
    title,
    component: ({ value, onChange }) => {
      const onNewTags = useMemo(() => {
        return (newTags: string[]) => {
          onChange(newTags.map(name => ({ name })));
        };
      }, [onChange]);

      const tags = useMemo(() => (value || []).map((t: TagInput) => t.name), [value]);

      return <TagsSelect values={tags} onChange={onNewTags} css={{ width: '100%' }} />;
    },
  };
}
