import useComponentSize from '@rehooks/component-size';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { PaginatedTableProps, PaginatedVariables } from './types';

const usePaginatedTableHooks = <TQuery, TVariables extends PaginatedVariables>({
  query,
  variables,
  dataKey,
  setCount = _.noop,
  clientSideFilter = () => true,
  clientSideSorts = [],
}: Pick<
  PaginatedTableProps<TVariables>,
  | 'query'
  | 'variables'
  | 'dataKey'
  | 'setCount'
  | 'clientSideFilter'
  | 'clientSideSorts'
>) => {
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  const { data, fetchMore, refetch, loading } = useQuery<TQuery, TVariables>(query, {
    variables: { ...variables, first: 20, after: null },
  });

  const rowCount = _.get(data, `${dataKey}.count`);

  setCount(rowCount);

  const containerRef = useRef(null);
  const containerSize = useComponentSize(containerRef);
  const [rows, setRows] = useState([] as any);

  useEffect(() => {
    if (loading) return;

    const _rows = _.sortBy(
      _.filter(_.map(_.get(data, `${dataKey}.edges`), 'node'), clientSideFilter),
      clientSideSorts,
    );

    if (_.size(_rows)) setRows(_rows);
  }, [_.get(data, dataKey)]);

  useEffect(() => {
    refetch(variables);
  }, [JSON.stringify(variables)]);

  const hasNextPage = _.get(data, `${dataKey}.pageInfo.hasNextPage`);
  const endCursor = _.get(data, `${dataKey}.pageInfo.endCursor`);

  const loadNextPage = async () => {
    if (isNextPageLoading) return;

    setIsNextPageLoading(true);

    await fetchMore({
      variables: { first: 30, after: endCursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!_.get(fetchMoreResult, `${dataKey}.edges`)) return previousResult;

        return {
          ...(previousResult as any),
          [dataKey]: {
            ..._.get(previousResult, dataKey, {}),
            edges: [
              ..._.get(previousResult, [dataKey, 'edges'], []),
              ..._.get(fetchMoreResult, [dataKey, 'edges'], []),
            ],
            pageInfo: {
              ..._.get(previousResult, [dataKey, 'pageInfo'], {}),
              ..._.get(fetchMoreResult, [dataKey, 'pageInfo'], {}),
            },
            count: _.get(fetchMoreResult, [dataKey, 'count'], 0),
          },
        };
      },
    });

    setIsNextPageLoading(false);
  };

  return {
    rowCount,
    hasNextPage,
    loadNextPage,
    loading,
    containerRef,
    containerSize,
    rows,
  };
};

export default usePaginatedTableHooks;
