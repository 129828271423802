import gql from 'graphql-tag';
import React from 'react';
import { Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import LabeledSection from '~/LabeledSection';
import Link from '~/Link';
import Text from '~/Text';
import { XperienceStatus } from '~/graphql';
import { COLOR_MAP } from '~/ExperienceStatus';
import FullScreenSpinner from '~/FullScreenSpinner';

const StatusSummary = () => {
  const { data, loading } = useQuery(StatusSummary.query);

  const completedReviewedCount = data ? data.completedReviewedStatus.count : 0;
  const completedNeedsReviewCount = data ? data.completedNeedsReviewStatus.count : 0;
  const incompleteCount = data ? data.incompleteStatus.count : 0;

  const total =
    completedNeedsReviewCount + completedNeedsReviewCount + incompleteCount;

  return (
    <LabeledSection
      label={`STATUS${total > 0 ? ' (' + total + ' total)' : ''}`}
      isHorizontal
      pageLinkText='View All'
      pageLinkPath='/supply/experiences'
      viewParts={
        loading
          ? [
              () => (
                <div css={{ position: 'relative', width: '100%', height: 90 }}>
                  <FullScreenSpinner />
                </div>
              ),
            ]
          : [
              () => (
                <StatusCell
                  status={XperienceStatus.CompletedReviewed}
                  title={'Completed, Reviewed'}
                  value={completedReviewedCount}
                />
              ),
              () => (
                <StatusCell
                  status={XperienceStatus.CompletedNeedsReview}
                  title={'Completed, Needs Review'}
                  value={completedNeedsReviewCount}
                />
              ),
              () => (
                <StatusCell
                  status={XperienceStatus.Incomplete}
                  title={'Incomplete'}
                  value={incompleteCount}
                />
              ),
            ]
      }
    />
  );
};

export default StatusSummary;

StatusSummary.query = gql`
  query StatusSummary {
    incompleteStatus: xperiencesConnection(filterInput: { statuses: [Incomplete] })
      @connection(key: "xperiencesConnection", filter: ["filterInput"]) {
      count
    }
    completedNeedsReviewStatus: xperiencesConnection(
      filterInput: { statuses: [CompletedNeedsReview] }
    ) @connection(key: "xperiencesConnection", filter: ["filterInput"]) {
      count
    }
    completedReviewedStatus: xperiencesConnection(
      filterInput: { statuses: [CompletedReviewed] }
    ) @connection(key: "xperiencesConnection", filter: ["filterInput"]) {
      count
    }
  }
`;

const StatusCell = ({
  status,
  title,
  value,
}: {
  status: XperienceStatus;
  title: string;
  value: number;
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 85,
        maxWidth: 100,
      }}
    >
      <Text size='XSmall' color='GreyDark' css={{ marginTop: 'auto' }}>
        {title}
      </Text>
      <Text size='Huge' css={{ color: COLOR_MAP[status] }} weight='Demi'>
        {value}
      </Text>
      <Link
        to={{
          pathname: '/supply/experiences',
          search: `?statuses=${encodeURIComponent(status)}`,
        }}
        useReactRouterLink
      >
        View
      </Link>
    </div>
  );
};
