import { sizes, font, colors } from '~/styles';
import { titleCaseEnum } from '~/formatters';
import moment from 'moment-timezone';
import _ from 'lodash';
import { Popover } from 'antd';
import Link from '~/Link';
import { MysteryType, AdventureStatus } from '~/graphql';
import ScottHr from '~/ScottHr';

const statusLookup = {
  [AdventureStatus.Canceled]: {
    color: colors.Black,
    label: 'Canceled',
  },
  [AdventureStatus.Completed]: {
    color: colors.Black,
    label: 'Completed',
  },
  [AdventureStatus.InProgress]: {
    color: colors.Warning,
    label: 'In Progress',
  },
  [AdventureStatus.Requested]: {
    color: colors.Negative,
    label: 'Not Planned',
  },
  [AdventureStatus.Scheduled]: {
    color: colors.Positive,
    label: 'Planned',
  },
};

const columns = [
  {
    key: 'shortId',
    width: sizes.GRID_UNIT * 8,
    cellRenderer: (_cellData, rowData) => (
      <Link
        onClick={() => {
          const baseUrl = window.location.href
            .split('/')
            .slice(0, 3)
            .join('/');
          window.location.href = `${baseUrl}/staging/${_.get(rowData, 'id')}`;
        }}
      >
        {_.get(rowData, 'shortId')}
      </Link>
    ),
  },
  {
    key: 'status',
    width: sizes.GRID_UNIT * 8,
    cellRenderer: status => {
      const { color, label } = statusLookup[status];
      return <span css={{ color }}>{label}</span>;
    },
  },
  {
    key: 'date',
    width: sizes.GRID_UNIT * 8,
    cellRenderer: (_cellData, rowData) =>
      moment(rowData.startsAt).format('M/D/YYYY'),
  },
  {
    key: 'startsAt',
    width: sizes.GRID_UNIT * 8,
    cellRenderer: startsAt => moment(startsAt).format('h:mma'),
  },
  {
    key: 'owner',
    width: sizes.GRID_UNIT * 8,
    cellRenderer: owner => _.get(owner, 'firstName', '-'),
  },
  {
    key: 'requester',
    width: sizes.GRID_UNIT * 8,
    cellRenderer: (_cellData, rowData) =>
      _.get(_.first(rowData.party), 'firstName', '-'),
  },
  {
    key: 'guests',
    width: sizes.GRID_UNIT * 4,
    cellRenderer: (_cellData, rowData) => _.size(rowData.party),
  },
  {
    key: 'budget',
    width: sizes.GRID_UNIT * 8,
    cellRenderer: (_cellData, rowData) =>
      rowData.budget ? `$${rowData.budget.min}-$${rowData.budget.max}/person` : '-',
  },
  {
    key: 'type',
    width: sizes.GRID_UNIT * 12,
    cellRenderer: type => {
      const lookup = {
        [MysteryType.Activity]: '1-Stop Activity',
        [MysteryType.Classic]: 'Classic',
        [MysteryType.Meal]: '1-Stop Meal',
      };
      return lookup[type];
    },
  },
  {
    key: 'theme',
    width: sizes.GRID_UNIT * 8,
    cellRenderer: (_cellData, rowData) => _.get(rowData, 'template.title', '-'),
  },
  {
    key: 'dietary',
    width: sizes.GRID_UNIT * 12,
    cellRenderer: (_cellData, rowData) => {
      const restrictions = new Set(
        _.flatMap(_.get(rowData, 'party', []), user =>
          _.map(user.dietaryRestrictions, 'name'),
        ),
      );
      return [...restrictions].join(', ') || '-';
    },
  },
  {
    key: 'accessibility',
    width: sizes.GRID_UNIT * 12,
    cellRenderer: (_cellData, rowData) => {
      const restrictions = new Set(
        _.flatMap(_.get(rowData, 'party', []), user =>
          _.map(user.accessibilityRestrictions, 'name'),
        ),
      );
      return [...restrictions].join(', ') || '-';
    },
  },
  {
    key: 'note',
    label: 'Note (User)',
    width: sizes.GRID_UNIT * 8,
    cellRenderer: note =>
      note ? (
        <Popover title='Customer note' content={<p>{note}</p>}>
          <div css={{ color: colors.AntdBlue }}>View note</div>
        </Popover>
      ) : (
        '-'
      ),
  },
  {
    key: 'notes',
    label: 'Note (Internal)',
    width: sizes.GRID_UNIT * 8,
    cellRenderer: (_cellData, rowData) =>
      _.size(rowData.notes) ? (
        <Popover
          title='Internal notes'
          content={
            <div css={{ display: 'flex', flexDirection: 'column' }}>
              {_.get(rowData, 'notes', []).map((note, i) => (
                <div key={`${rowData.shortId}-note-${i}`}>
                  <div>{note.content}</div>
                  <div css={{ ...font.Size.Caption }}>{note.author.name}</div>
                  {i < _.size(rowData.notes) - 1 && <ScottHr />}
                </div>
              ))}
            </div>
          }
        >
          <div css={{ color: colors.AntdBlue }}>View notes</div>
        </Popover>
      ) : (
        '-'
      ),
  },
];

export default columns;
