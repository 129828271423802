import React from 'react';
import { Button, Icon } from 'antd';

import { DataTableQueryResult, DataTableQueryStatus } from './querying';
import { GraphQLFiltersInput } from './interface';

const CONTENT_HEIGHT = 20;
const PADDING = 8;

/**
 * Displays the current status of the query.
 *
 * Also provides UX to allow the user to retry failed pages.
 */
function DataTableFooter<TRow, TSortKeys extends string, TFilters extends GraphQLFiltersInput>({
  result: { status: state, rows, count, error, loadPage },
}: {
  result: DataTableQueryResult<TRow, TSortKeys, TFilters>;
}) {
  let statusIndicator: React.ReactNode;
  if (state === DataTableQueryStatus.LOADING) {
    statusIndicator = <Icon type='loading' />;
  } else if (state === DataTableQueryStatus.ERROR) {
    statusIndicator = <Icon type='error' />;
  } else {
    statusIndicator = <></>;
  }

  let message: React.ReactNode;
  if (state === DataTableQueryStatus.ERROR) {
    message = (
      <div>
        <Button type='danger' onClick={() => loadPage(true)}>
          Retry?
        </Button>
        {error && error.message}
      </div>
    );
  } else if (count === -1) {
    message = `loading`;
  } else if (count === 0) {
    message = `no rows match the current filter`;
  } else {
    message = `${rows.length} of ${count} rows loaded`;
  }

  return (
    <div css={STYLES.root}>
      <div css={STYLES.statusIndicator}>{statusIndicator}</div>
      <div css={STYLES.content}>{message}</div>
    </div>
  );
}

const STYLES = {
  root: {
    display: 'flex',
    backgroundColor: '#dddddd',
    padding: PADDING,
  },
  statusIndicator: {
    lineHeight: 0, // Adjust for the icon getting a baseline.
    marginRight: PADDING,
    minWidth: CONTENT_HEIGHT,
    '& svg': {
      height: CONTENT_HEIGHT,
      width: CONTENT_HEIGHT,
    },
  },
  content: {
    lineHeight: `${CONTENT_HEIGHT}px`,
  },
} as const;

export default DataTableFooter;
