import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';

import * as GraphQL from '~/graphql';

function checkForGoogleMaps() {
  if (!('google' in window)) throw new Error(`The Google Maps library isn't loaded`);
}

const createElement = () => document.createElement('div');

export const calculateDrivingDuration = _.memoize(
  async (
    location1: { placeId: GraphQL.Maybe<string> },
    location2: { placeId: GraphQL.Maybe<string> },
    departureTime?: Moment,
  ) => {
    const directionsService = new google.maps.DirectionsService();
    // Make sure the departure time is in the future because Google will throw
    // if it's in the past.
    departureTime = (() => {
      while (moment(departureTime).isBefore()) {
        // Keep adding a week until the departure time is no longer in the past.
        departureTime = moment(departureTime).add(1, 'week');
      }
      return departureTime;
    })();
    const drivingOptions = departureTime
      ? {
          drivingOptions: {
            departureTime: departureTime.toDate(),
            trafficModel: google.maps.TrafficModel.BEST_GUESS,
          },
        }
      : {};
    return new Promise(resolve => {
      directionsService.route(
        {
          origin: { placeId: location1.placeId } as any,
          destination: { placeId: location2.placeId } as any,
          travelMode: google.maps.TravelMode.DRIVING,
          ...drivingOptions,
        },
        function(response, status) {
          if (status !== google.maps.DirectionsStatus.OK) {
            window.alert('Directions request failed due to ' + status);
            return resolve({});
          }

          let duration = 0;

          _.forEach(response.routes, route => {
            const key = _.has(route.legs[0], 'duration_in_traffic')
              ? 'duration_in_traffic'
              : 'duration';
            duration += _.sum(_.map(route.legs, `${key}.value`));
          });

          (response as any).durationMinutes = _.round(duration / 60);
          resolve(response);
        },
      );
    });
  },
  (loc1, loc2, departureTime) =>
    _.get(loc1, 'placeId') +
    _.get(loc2, 'placeId') +
    _.invoke(departureTime, 'toISOString'),
);

export const getPlaceDetails = (placeId: string): Promise<any> => {
  checkForGoogleMaps();

  const placesService = new google.maps.places.PlacesService(createElement());

  return new Promise((resolve, reject) => {
    placesService.getDetails({ placeId }, (place, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) return;

      resolve({
        placeId: place.place_id,
        name: place.name,
        url: place.website,
        phone: place.formatted_phone_number,
        priceLevel: place.price_level,
        rating: place.rating,
        coordinates: {
          latitude: _.invoke(place, 'geometry.location.lat'),
          longitude: _.invoke(place, 'geometry.location.lng'),
        },
        address: {
          full: _.get(place, 'formatted_address'),
          streetNumber: getComponentByType(
            place.address_components!,
            'street_number',
          ),
          route: getComponentByType(place.address_components!, 'route'),
          subPremise: getComponentByType(place.address_components!, 'subpremise'),
          city: getComponentByType(place.address_components!, 'locality'),
          state: getComponentByType(
            place.address_components!,
            'administrative_area_level_1',
          ),
          postalCode: getComponentByType(place.address_components!, 'postal_code'),
          county: getComponentByType(
            place.address_components!,
            'administrative_area_level_2',
          ),
          country: getComponentByType(place.address_components!, 'country'),
          neighborhood: getComponentByType(
            place.address_components!,
            'neighborhood',
          ),
        },
      });
    });
  });
};

export const geocodeByPlaceIdOrAddress = ({
  placeId,
  address,
}: {
  placeId?: string;
  address?: string;
}): Promise<GeocodedPlace> => {
  if (!placeId && !address) throw new Error('placeId or address must be specified');
  checkForGoogleMaps();

  const geocoder = new google.maps.Geocoder();
  const OK = google.maps.GeocoderStatus.OK;

  if (placeId) getPlaceDetails(placeId);

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId, address }, (results, status) => {
      if (status !== OK) reject(status);
      const result = _.first(results);
      if (!result) return resolve();

      resolve({
        placeId: result.place_id,
        coordinates: {
          latitude: _.invoke(result, 'geometry.location.lat'),
          longitude: _.invoke(result, 'geometry.location.lng'),
        },
        address: {
          full: _.get(result, 'formatted_address'),
          streetNumber: getComponentByType(
            result.address_components,
            'street_number',
          )!,
          route: getComponentByType(result.address_components, 'route')!,
          subPremise: getComponentByType(result.address_components, 'subpremise'),
          city: getComponentByType(result.address_components, 'locality'),
          state: getComponentByType(
            result.address_components,
            'administrative_area_level_1',
          ),
          postalCode: getComponentByType(result.address_components, 'postal_code'),
          county: getComponentByType(
            result.address_components,
            'administrative_area_level_2',
          ),
          country: getComponentByType(result.address_components, 'country')!,
          neighborhood: getComponentByType(
            result.address_components,
            'neighborhood',
          ),
        },
      });
    });
  });
};

function getComponentByType(
  addressComponents: google.maps.GeocoderAddressComponent[],
  type: string,
) {
  const match = _.find(addressComponents, component =>
    _.includes(component.types, type),
  );
  return _.get(match, 'short_name');
}

interface GeocodedPlace {
  placeId: string;
  coordinates: GraphQL.Coordinates;
  address: GraphQL.Address;
}
