/**
 * Return the app configuration for the current environment.
 */

const environment = process.env.NODE_ENV || 'development';
export const isDevelopment = environment === 'development';
export const isProduction = environment === 'production';
export const isTest = environment === 'test';

const externalIp = process.env.REACT_APP_IP_ADDR || 'localhost';

function getEnvOrDie(key: string) {
  const value = process.env[key];
  if (!value || value.trim() === '') {
    throw new Error(`Please provide a value for ${key}`);
  }

  return value;
}

export const config = {
  nodeEnv: environment,
  apiUrl: isProduction ? 'https://api.trymystery.com' : `http://${externalIp}:5000`,
  opsUrl: isProduction ? 'https://ops.trymystery.com' : `http://${externalIp}:2999`,
  teamsUrl: isProduction ? 'https://teams.trymystery.com' : `http://${externalIp}:3000`,
  googleMapsApiKey: getEnvOrDie('REACT_APP_GOOGLE_MAPS_API_KEY'),
  pusherKey: getEnvOrDie('REACT_APP_PUSHER_KEY'),
  statsigKey: getEnvOrDie('REACT_APP_STATSIG_CLIENT_KEY'),
  sentry: {
    dsn: getEnvOrDie('REACT_APP_SENTRY_DSN'),
  },
  isDevelopment,
  isTest,
  isProduction,
  hubspot: {
    baseUrl: 'https://app.hubspot.com',
    dealPath: '/contacts/9438752/deal/',
  },
};

export type Config = typeof config;

export default config;
