import React from 'react';
import StatusSummary from './Status';
import AtRiskSummary from './AtRisk';
import ChangesSummary from './Changes';
import { sizes } from '~/styles';

const SupplySummary = () => {
  return (
    <div css={{ overflow: 'auto' }}>
      <div
        css={{
          display: 'flex',
          padding: sizes.Spacing.Large,
          '> *:not(:last-child)': { marginRight: sizes.Spacing.Large },
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <StatusSummary />
          <AtRiskSummary />
        </div>
        <ChangesSummary css={{ flex: 2 }} />
      </div>
    </div>
  );
};

export default SupplySummary;
