import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import Calendar from '~/components/Calendar';
import FillRemaining from '~/FillRemaining';
import * as GraphQL from '~/graphql';
import Header from '~/Header';
import Loading from '~/Loading';

const strings = {
  metrics: 'Metrics',
};

const Home = () => {
  const { data, loading } = useQuery<GraphQL.Home.Query>(Home.query);

  const renderedComponent =
    loading || !data ? (
      <Loading />
    ) : (
      <iframe
        src={data!.metabaseMetricsURL!}
        frameBorder='0'
        width='100%'
        height='100%'
        allowTransparency
      />
    );

  return (
    <FillRemaining>
      <Header
        title={strings.metrics}
        rightContent={
          loading ? undefined : data.viewer ? `Hello ${data.viewer.firstName}!` : undefined
        }
      />
      {renderedComponent}
    </FillRemaining>
  );
};

Home.query = gql`
  query Home {
    metabaseMetricsURL
    viewer {
      id
      firstName
    }
  }
`;

export default Home;
