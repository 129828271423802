import React from 'react';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import Text from '~/Text';

import { sizes } from '~/styles';
import ScottTitle from '~/ScottTitle';
import ScottHr from '~/ScottHr';
import ScheduleItemCell from '~/ScheduleItemCell';
import * as GraphQL from '~/graphql';
import Link from '~/Link';
import { Popconfirm } from 'antd';

const SchedulesView = (props: {
  schedules: GraphQL.ScheduleItemCell.Fragment[];
  setSchedules?: (newSchedules: GraphQL.ScheduleItemCell.Fragment[]) => void;
  showDeleteButton?: boolean;
}) => {
  if (props.showDeleteButton && !props.setSchedules)
    throw new Error('cant delete a schedule without the setSchedules function');
  const numOfSchedules = props.schedules.length;
  const noSchedules = numOfSchedules === 0;
  return (
    <div>
      {!noSchedules && <ScottTitle type='SubSection'>Schedules</ScottTitle>}
      {props.schedules.map((schedule, i) => (
        <>
          <LabeledField label={`Availability ${i + 1}`} key={`schedule-${i}`}>
            <div
              css={{
                marginTop: sizes.Spacing.XSmall,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <ScheduleItemCell {...schedule} />
              {props.showDeleteButton && (
                <Popconfirm
                  placement='top'
                  title='Are you sure you want to delete this schedule?'
                  okText='Yes'
                  cancelText='No'
                  onConfirm={() =>
                    props.setSchedules(
                      props.schedules.filter(sched => sched.id !== schedule.id),
                    )
                  }
                >
                  <Link css={{ marginLeft: sizes.Spacing.Large }}>Remove</Link>
                </Popconfirm>
              )}
            </div>
          </LabeledField>
          {i + 1 !== numOfSchedules && <ScottHr key={`schedule-hr-${i}`} />}
        </>
      ))}
      {noSchedules && (
        <Text size='Small' color='GreyDark'>
          No schedules added
        </Text>
      )}
    </div>
  );
};

export default SchedulesView;
