import React, { useEffect, useState } from 'react';
import Header from '~/Header';
import LoadingOverlay from '~/Loading/Overlay';
import _ from 'lodash';
import gql from 'graphql-tag';
import * as GraphQL from '~/graphql';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { sizes } from '~/styles';
import { Button, Table } from 'antd';

const Loader = () => {
  const questionnaireId = _.last(window.location.href.split('/'));
  const { data } = useQuery<
    GraphQL.QuestionnaireReview.Query,
    GraphQL.QuestionnaireReview.Variables
  >(QuestionnaireReview.query, { variables: { id: questionnaireId } });

  const { data: questionnaireData } = useQuery<
    GraphQL.GetQuestionnaireForReview.Query,
    GraphQL.GetQuestionnaireForReview.Variables
  >(QuestionnaireReview.getQuestionnaireQuery, { variables: { id: questionnaireId } });

  return data && questionnaireData ? (
    <QuestionnaireReview
      id={questionnaireId}
      questionnaireAnswers={_.get(data, 'questionnaireAnswers')}
      questionnaire={_.get(questionnaireData, 'questionnaire')}
    />
  ) : null;
};

const QuestionnaireReview = ({ id, questionnaireAnswers, questionnaire }) => {
  const [reviewedUsers, setReviewedUsers] = useState(null);

  const [saving, setSaving] = useState(false);
  const [saveReviewedUsers] = useMutation(QuestionnaireReview.mutation, {
    variables: { id, users: reviewedUsers },
  });

  useEffect(() => {
    if (!reviewedUsers) {
      const initialReviewedUsers = _.reduce(
        questionnaireAnswers,
        (acc, { user, reviewed }) => {
          if (reviewed) {
            acc.push(user.id);
          }
          return acc;
        },
        [],
      );

      setReviewedUsers(initialReviewedUsers);
    }
  }, []);

  const questionsById = _.keyBy(_.get(questionnaire, 'questions'), 'id');

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    ..._.map(Object.entries(questionsById), ([id, question]: [string, any]) => ({
      title: question.question,
      dataIndex: id,
      render:
        question.questionType === GraphQL.QuestionType.Image
          ? answer => <img css={{ height: '100px' }} src={answer} />
          : answer => answer,
    })),
  ];

  const data = _.sortBy(
    _.map(questionnaireAnswers, ({ user, answers }) => {
      const row = {
        key: _.get(user, 'id'),
        name: `${_.get(user, 'firstName')} ${_.get(user, 'lastName')}`,
      };
      _.reduce(
        answers,
        (acc, answer) => {
          const questionId = _.get(answer, 'question.id');
          acc[questionId] = _.get(answer, 'answer');
          return acc;
        },
        row,
      );

      return row;
    }),
    'name',
  );

  return (
    <>
      <LoadingOverlay visible={saving} />

      <Header
        title='Questionnaire Review'
        rightContent={
          <Button
            type='primary'
            loading={saving}
            onClick={async () => {
              setSaving(true);
              await saveReviewedUsers();
              setSaving(false);
            }}
          >
            Save Reviewed Users
          </Button>
        }
      />
      <Table
        columns={columns}
        dataSource={data}
        rowSelection={{
          type: 'checkbox',
          onChange: selectedRowKeys => setReviewedUsers(selectedRowKeys),
          selectedRowKeys: reviewedUsers,
        }}
      />
    </>
  );
};

QuestionnaireReview.query = gql`
  query QuestionnaireReview($id: ID!) {
    questionnaireAnswers(id: $id) {
      user {
        id
        firstName
        lastName
      }
      answers {
        id
        question {
          id
          question
          questionType
        }
        answer
      }
      reviewed
    }
  }
`;

QuestionnaireReview.getQuestionnaireQuery = gql`
  query GetQuestionnaireForReview($id: ID!) {
    questionnaire(id: $id) {
      questions {
        id
        question
        questionType
      }
    }
  }
`;

QuestionnaireReview.mutation = gql`
  mutation QuestionnaireReviewMutation($id: ID!, $users: [ID]!) {
    reviewQuestionnaireUsers(id: $id, users: $users) {
      id
    }
  }
`;

export default Loader;
