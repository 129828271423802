import createPersistedState from 'use-persisted-state';

const usePersistedState = <T>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] => {
  return createPersistedState(key)(initialValue);
};

export default usePersistedState;
