import React from 'react';
import Text from '~/Text';
import Page from '../Page';
import { Button, Tabs } from 'antd';
import ExperiencesTable from './Experiences/Table';
import PartnersTable from './Partners/Table';
import EventsTable from './Events';
import SupplySummary from './Summary';
import { sizes, colors } from '~/styles';
import SupplySearch from './Search';
import { useRouteMatch, useParams } from 'react-router';
import _ from 'lodash';
import EventsFilterBar from './Events/FilterBar';
import icons from '~/lib/styles/icons';
import useWindowSize from '~/hooks/useWindowSize';
import TemplatesFilterBar from './Templates/FilterBar';
import TemplatesTable from './Templates';
import AddPartnerButton from '../AddPartner';
import SupplyTags from '../Tags';

const SupplyHome = ({ history }) => {
  const { path } = useRouteMatch();
  const { tabName } = useParams<{ tabName: string }>();

  const { width } = useWindowSize();

  const TAB_MAP = {
    summary: { component: <SupplySummary /> },
    partners: {
      component: <PartnersTable />,
      tabExtras: <AddPartnerButton />,
      tabIcon: icons['Partner'],
    },
    experiences: {
      component: <ExperiencesTable css={{ flex: 1 }} />,
      tabIcon: icons['Xperience'],
    },
    events: {
      component: <EventsTable />,
      filterBar: <EventsFilterBar />,
      tabIcon: icons['Event'],
      forceStackedHeader: true,
    },
    templates: {
      component: <TemplatesTable />,
      filterBar: <TemplatesFilterBar />,
      tabIcon: icons['Template'],
      forceStackedHeader: true,
    },
    tags: {
      component: <SupplyTags />,
      tabIcon: icons['Tags'],
    },
  };

  const activeKey = tabName || 'summary';

  const isStackedHeader =
    (width < 1500 && !!TAB_MAP[activeKey].filterBar) ||
    (TAB_MAP[activeKey] && TAB_MAP[activeKey].forceStackedHeader);

  const { tabExtras } = TAB_MAP[activeKey];

  return (
    <Page rightContent={<SupplySearch />} customContentArea>
      <>
        <div
          css={{
            display: 'flex',
            borderBottom: `1px solid ${colors.GreyLight}`,
            alignItems: 'center',
          }}
        >
          <div
            css={{
              ...(isStackedHeader ? { flexDirection: 'column-reverse' } : { alignItems: 'center' }),
              justifyContent: 'space-between',
              display: 'flex',
              paddingTop: sizes.Spacing.XSmall,
              paddingLeft: sizes.Spacing.XLarge,
              paddingRight: sizes.Spacing.XLarge,
              marginLeft: -16, // antd tab padding
              marginRight: -16, // antd tab padding

              flex: 1,
            }}
          >
            <Tabs
              size='small'
              activeKey={activeKey}
              onChange={newActiveKey => {
                history.push(path.replace(':tabName', newActiveKey));
              }}
              css={{ flex: 1 }}
              tabBarStyle={{
                marginBottom: 0,
                border: 0,
              }}
              tabBarGutter={sizes.Spacing.Small}
            >
              {Object.keys(TAB_MAP).map(tabName => (
                <Tabs.TabPane
                  key={tabName}
                  tab={
                    <Tab
                      isActive={tabName === activeKey}
                      name={_.capitalize(tabName)}
                      icon={TAB_MAP[tabName].tabIcon}
                    />
                  }
                />
              ))}
            </Tabs>
            {TAB_MAP[activeKey].filterBar && !isStackedHeader && (
              <div css={{ marginBottom: 6 }}>{TAB_MAP[activeKey].filterBar}</div>
            )}
          </div>
          {tabExtras && <div css={{ paddingRight: sizes.Spacing.XSmall }}>{tabExtras}</div>}
        </div>
        {isStackedHeader && (
          <div
            css={{
              padding: sizes.Spacing.XSmall,
              display: 'flex',
              justifyContent: 'flex-end',
              borderBottom: `1px solid ${colors.GreyLighter}`,
            }}
          >
            {TAB_MAP[activeKey].filterBar}
          </div>
        )}
        {TAB_MAP[activeKey].component}
      </>
    </Page>
  );
};

export default SupplyHome;

const Tab = (props: { name: string; isActive?: boolean; icon?: React.ReactElement }) => {
  return (
    <Text
      css={{
        position: 'relative',
        bottom: 6,
        display: 'flex',
        fontWeight: props.isActive ? 500 : undefined,
        color: props.isActive ? colors.AntdBlue : colors.GreyDarker,
        height: 23,
        alignItems: 'flex-end',
      }}
    >
      {props.icon ? (
        <div
          css={{
            position: 'relative',
            top: 3,
            marginRight: sizes.Spacing.XSmall,
            fontSize: 18,
          }}
        >
          {props.icon}
        </div>
      ) : (
        undefined
      )}
      {props.name}
    </Text>
  );
};
