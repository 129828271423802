import React from 'react';
import config from '~/config';
import { Typography } from 'antd';
import { OrganizationUpdateData } from '~/Organization/Edit';
import { colors, font, sizes } from '~/styles';
import { TypeSettingHeader, TypeSettingSwitch } from './CustomerTypeTab';
import Link from '~/Link';
import { FiExternalLink } from 'react-icons/fi';

const ViewInsights = ({ updatedData }: { updatedData: OrganizationUpdateData }) => (
  <Link
    href={`${config.teamsUrl}/insights?orgId=${updatedData.id}`}
    target='_blank'
    rel='noopener noreferrer'
    disabled={!updatedData.engagementGraphApproved}
    css={{ display: 'inline-flex', alignItems: 'center' }}
    fontWeight={font.FontWeight.Demi}
  >
    <FiExternalLink css={{ marginRight: sizes.Spacing.XSmall }} />
    View {updatedData.name}'s Insights
  </Link>
);

const InsightsSwitch = ({
  updatedData,
  setUpdatedData,
}: {
  updatedData: OrganizationUpdateData;
  setUpdatedData: (data: OrganizationUpdateData) => void;
}) => (
  <TypeSettingSwitch
    label='Insights is'
    checked={updatedData.engagementGraphApproved}
    onChange={() => {
      setUpdatedData({
        ...updatedData,
        engagementGraphApproved: !updatedData.engagementGraphApproved,
      });
    }}
  />
);

const InsightsNote = ({ title, children }) => (
  <div
    css={{
      background: colors.Highlight,
      padding: sizes.Spacing.Medium,
      marginTop: sizes.Spacing.Medium,
    }}
  >
    <Typography css={{ fontWeight: 'bold' }}>{title}</Typography>
    <Typography css={{ marginTop: sizes.Spacing.XXSmall }}>{children}</Typography>
  </div>
);

const InsightsSection = ({
  updatedData,
  setUpdatedData,
}: {
  updatedData: OrganizationUpdateData;
  setUpdatedData: (data: OrganizationUpdateData) => void;
}) => {
  return (
    <div css={{ marginTop: sizes.Spacing.Small, paddingLeft: sizes.Spacing.Small }}>
      <TypeSettingHeader title='Insights' subtitle='Enable connection score insights' />
      <InsightsNote title='Note'>
        Any user that is part of an Events + Insights organization will be required to connect their
        calendar when setting up their account.
      </InsightsNote>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: sizes.Spacing.Large,
          flexWrap: 'wrap',
          gap: sizes.Spacing.XSmall,
        }}
      >
        <InsightsSwitch updatedData={updatedData} setUpdatedData={setUpdatedData} />
        <ViewInsights updatedData={updatedData} />
      </div>
    </div>
  );
};

export default InsightsSection;
