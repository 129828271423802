import { useQuery } from '@apollo/react-hooks';
import useComponentSize from '@rehooks/component-size';
import { ObservableQuery } from 'apollo-client';
import deepmerge from 'deepmerge';
import { DocumentNode } from 'graphql';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { PaginatedVariables } from '~/PaginatedTable/types';

export const usePaginatedQuery = <
  TQuery extends any,
  TVariables extends PaginatedVariables,
  TNode extends any = any
>(
  query: DocumentNode,
  variables: TVariables,
  dataKey: keyof TQuery & string | string,
  skip?: boolean,
) => {
  const [rows, setRows] = useState<TNode[]>([] as any);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  const { data, fetchMore, refetch, loading } = useQuery<TQuery, TVariables>(query, {
    variables: { ...variables, first: 20, after: null },
    skip,
  });

  const { count, hasNextPage, endCursor } = getPageInfo(_.get(data, dataKey));

  const paginate = usePagination(
    fetchMore,
    dataKey,
    {
      ...variables,
      first: 30,
      after: endCursor,
    },
    { isNextPageLoading, setIsNextPageLoading },
  );

  useEffect(() => {
    refetch(variables);
  }, [variables]);

  useEffect(() => {
    if (loading) return;
    const _rows = _.map(_.get(data, `${dataKey}.edges`), 'node');
    setRows(_rows);
  }, [_.get(data, dataKey)]);

  return {
    // Raw data returned from the query
    data,
    rows,
    count,
    hasNextPage,
    endCursor,
    paginate,
    loading,
    isNextPageLoading,
  };
};

export const useContainerSize = () => {
  const ref = useRef(null);
  const size = useComponentSize(ref);
  return { ref, size };
};

const getPageInfo = (data: any) => ({
  count: _.get(data, `count`),
  hasNextPage: _.get(data, `pageInfo.hasNextPage`),
  endCursor: _.get(data, `pageInfo.endCursor`),
});

const usePagination = <TQuery, TVariables extends PaginatedVariables>(
  fetchMore: ObservableQuery<TQuery, TVariables>['fetchMore'],
  dataKey: string,
  variables: Pick<TVariables, 'first' | 'after'>,
  { isNextPageLoading, setIsNextPageLoading }: any,
) => {
  return async () => {
    if (isNextPageLoading) return;

    setIsNextPageLoading(true);

    await fetchMore({
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) =>
        !_.get(fetchMoreResult, `${dataKey}.edges`)
          ? previousResult
          : deepmerge(previousResult, fetchMoreResult),
    });

    setIsNextPageLoading(false);
  };
};
