import { Button, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { ContractStatus } from '~/graphql';
import ScottHr from '~/ScottHr';
import { sizes } from '~/styles';

import { isNewContract, newContract } from '../ContractModal';
import ContractOverview from '../ContractOveriew';
import { Contract } from '../Edit';

const PlansTab = ({
  contracts,
  setSelectedContract,
  setShowContractModal,
}: {
  contracts: Contract[];
  setSelectedContract: (contract: Contract) => void;
  setShowContractModal: (show: boolean) => void;
}) => {
  // Prevents stub contracts from showing up when adding a new one
  const activeContracts = _.filter(
    contracts,
    contract => !isNewContract(contract) && contract.status === ContractStatus.Active,
  );
  const nonActiveContracts = _.filter(
    contracts,
    contract => !isNewContract(contract) && contract.status !== ContractStatus.Active,
  );

  return (
    <>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginRight: sizes.Spacing.Small,
        }}
      >
        <Typography css={{ fontSize: '30px', fontWeight: 'bold' }}>Plans</Typography>
        <Button
          type='primary'
          onClick={() => {
            setSelectedContract(newContract());
            setShowContractModal(true);
          }}
        >
          Create Plan
        </Button>
      </div>
      <Typography css={{ marginBottom: sizes.Spacing.Medium }}>
        Select a plan to view or edit
      </Typography>
      {_.map(activeContracts, contract => (
        <ContractOverview
          key={contract.id}
          contract={contract}
          setSelectedContract={setSelectedContract}
          setShowContractModal={setShowContractModal}
        />
      ))}
      {!_.isEmpty(nonActiveContracts) && (
        <>
          <ScottHr />
          <Typography css={{ marginBottom: sizes.Spacing.Medium, fontWeight: 'bold' }}>
            Expired and Canceled Plans
          </Typography>
          {_.map(nonActiveContracts, contract => (
            <ContractOverview
              key={contract.id}
              contract={contract}
              setSelectedContract={setSelectedContract}
              setShowContractModal={setShowContractModal}
            />
          ))}
        </>
      )}
    </>
  );
};

export default PlansTab;
