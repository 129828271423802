import { useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import { DocumentNode } from 'graphql';

interface Reference {
  id: string;
}

interface QueryBasedSelectProps {
  value: Reference[] | Reference;
  onChange: (newValues: Reference[] | Reference) => unknown;
}

/**
 * A factory for Selects that are backed by a query to retrieve a list of
 * options.
 */
export function makeQueryBasedSelect(query: DocumentNode, queryKey: string) {
  const QueryBasedSelect = ({ value, onChange, ...props }: QueryBasedSelectProps) => {
    const { data, loading } = useQuery(query);

    let options: JSX.Element[] = [];
    if (data && data[queryKey]) {
      options = data[queryKey].map(({ id, name }) => (
        <Select.Option key={id} label={name}>
          {name}
        </Select.Option>
      ));
    }

    return (
      <Select
        mode={Array.isArray(value) ? 'multiple' : 'default'}
        optionFilterProp='label'
        loading={loading}
        value={Array.isArray(value) ? value.map(({ id }) => id) : (value || {}).id}
        onChange={ids => {
          Array.isArray(ids)
            ? onChange(ids.map(id => ({ id })) as Reference[])
            : onChange({ id: ids } as Reference);
        }}
        {...props}
      >
        {options}
      </Select>
    );
  };

  return QueryBasedSelect;
}
