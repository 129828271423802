import { Button, DatePicker, Input, InputNumber, message, Modal, Radio, Select } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
// Required for antd DatePicker :(
import moment from 'moment';
import React, { useState } from 'react';
import { centsToDollars } from '~/BillingReviewModal/helpers';
import * as GraphQL from '~/graphql';
import LabeledField from '~/Labeled/Field';
import Row from '~/Row';
import ScottHr from '~/ScottHr';
import { recursivelyRemoveTypenames } from '~/services/apollo';
import { colors, sizes } from '~/styles';

import { useMutation } from '@apollo/react-hooks';
import { isNewContract } from './ContractModal';
import AuditLogCell from '~/AuditLogCell';
import { CONTRACT_UNIT_PHRASE_LOOKUP } from '~/helpers/contract';
import CreditInput from '~/Input/Credit';
import { formatCents, formatCredits } from '~/formatters';
import { Label } from '@shopify/polaris';
import SearchUsersMultiSelect from '~/Search/Users/MultiSelect';

const FIELD_WIDTH = 300;

export type EditorContract = Omit<
  GraphQL.Contract,
  'organization' | 'teamEvents' | 'authorizedUsers'
> & { authorizedUsers: GraphQL.AuthorizedUser.Fragment[] };
type Unit = {
  costCents: number;
  initialCount: number;
  count: number;
  pending?: number;
  type: GraphQL.ContractUnitType;
};

const dataIsValid = (contract: EditorContract): boolean => {
  const defaultRequiredFields = [
    contract.startDate,
    contract.renewDate,
    contract.productType,
    contract.status,
    contract.type,
    contract.unitInitialCount,
  ];

  const fieldsRequiredIfSeatsBased = [contract.unitCostCents, contract.premiumUnitCostCents];
  const fieldsRequiredIfEventsBased = [contract.premiumUnitCostCents];

  const fields = defaultRequiredFields;
  if (contract.type === GraphQL.ContractUnitType.Recurring) {
    fields.push(...fieldsRequiredIfSeatsBased);
  } else if (contract.type === GraphQL.ContractUnitType.Event) {
    fields.push(...fieldsRequiredIfEventsBased);
  }

  return _.every(fields.map(f => !!f));
};

const intToDollars = (value: number | string): string => `$${centsToDollars(value)}`;
const dollarsToInt = (string: string): number => parseInt(string.replace(/[^0-9]/g, ''));

const renderFormattedNumber = (num: number, type: GraphQL.ContractUnitType): string | number => {
  if (!num) return '-';

  switch (type) {
    case GraphQL.ContractUnitType.Cents:
      return formatCents(num);
    case GraphQL.ContractUnitType.Credits:
      return formatCredits(num);
    default:
      return num;
  }
};

const UnitEditor = ({
  unit,
  setUnit,
  isCreate,
  isPremium,
}: {
  unit: Unit;
  setUnit: (newUnit: Unit) => void;
  isCreate: boolean;
  isPremium: boolean;
}) => {
  const { costCents, initialCount, count, pending, type } = unit;
  const creditsBased = type === GraphQL.ContractUnitType.Credits;
  const centsBased = type === GraphQL.ContractUnitType.Cents;
  const eventsBased = type === GraphQL.ContractUnitType.Event;
  const disabled = centsBased && isPremium;

  const renderContractRemaining = () => {
    if (isCreate) {
      return <p>{renderFormattedNumber(count, type)}</p>;
    } else if (!creditsBased) {
      return (
        <InputNumber
          disabled={disabled}
          value={count}
          onChange={newCount => setUnit({ ...unit, count: newCount })}
          formatter={number => (centsBased ? formatCents(parseInt(`${number}`)) : `${number}`)}
          parser={string =>
            type === GraphQL.ContractUnitType.Cents
              ? parseInt(string.replace(/[^0-9-]+/g, ''))
              : parseInt(string)
          }
        />
      );
    }

    return (
      <CreditInput
        disabled={disabled}
        value={_.round(count / 100, 2).toFixed(2)}
        onChange={newCount => setUnit({ ...unit, count: newCount * 100 })}
        formatter={number => formatCredits(parseInt(number))}
        parser={string => parseInt(string)}
      />
    );
  };

  return (
    <div
      css={{
        border: `1px solid ${colors.DarkSnow}`,
        borderRadius: '4px',
        padding: '6px',
        marginTop: '6px',
      }}
    >
      <LabeledField
        label={`Additional ${isPremium ? 'credit' : CONTRACT_UNIT_PHRASE_LOOKUP[type]} cost`}
        required={isCreate && (!centsBased && (!eventsBased || isPremium))}
      >
        <InputNumber
          disabled={centsBased || (eventsBased && !isPremium)}
          value={costCents}
          formatter={intToDollars}
          parser={dollarsToInt}
          onChange={newCount => setUnit({ ...unit, costCents: newCount })}
        />
      </LabeledField>
      <LabeledField
        label={`Total ${isPremium ? 'credits' : CONTRACT_UNIT_PHRASE_LOOKUP[type]} in contract`}
        required={isCreate && !isPremium && !disabled}
      >
        {isCreate ? (
          creditsBased ? (
            /* Credits based get a fancy Credits input type */
            <CreditInput
              disabled={disabled}
              value={_.round(initialCount / 100, 2).toFixed(2)}
              formatter={value => formatCredits(parseInt(value))}
              parser={string => parseInt(string)}
              onChange={newInitialCount =>
                setUnit({
                  ...unit,
                  initialCount: newInitialCount * 100,
                  count: newInitialCount * 100,
                })
              }
            />
          ) : (
            /* Non credits based get a standard input field */
            <InputNumber
              disabled={disabled}
              value={initialCount}
              formatter={value => (!centsBased ? `${value}` : intToDollars(value))}
              parser={string => (!centsBased ? parseInt(string) : dollarsToInt(string))}
              onChange={newInitialCount =>
                setUnit({ ...unit, initialCount: newInitialCount, count: newInitialCount })
              }
            />
          )
        ) : (
          <p>{renderFormattedNumber(initialCount, type)}</p>
        )}
      </LabeledField>
      <LabeledField
        label={`${
          isPremium ? 'Credits' : _.startCase(CONTRACT_UNIT_PHRASE_LOOKUP[type])
        } remaining`}
      >
        {renderContractRemaining()}
      </LabeledField>
      {!isCreate && (
        <LabeledField
          label={`${
            isPremium ? 'Credits' : _.startCase(CONTRACT_UNIT_PHRASE_LOOKUP[type])
          } pending use`}
        >
          <p>{renderFormattedNumber(pending, type)}</p>
        </LabeledField>
      )}
    </div>
  );
};

const ContractAddEdit = ({
  orgId,
  contract,
  closeModal,
}: {
  orgId: string;
  contract: EditorContract;
  closeModal: () => void;
}) => {
  const [updatedData, setUpdatedData] = useState<EditorContract>(contract);
  const [showAuditLogs, setShowAuditLogs] = useState(false);

  const {
    productType,
    startDate,
    renewDate,
    status,
    type,
    unitCostCents,
    unitCount,
    unitInitialCount,
    pendingUnitCount,
    pendingPremiumUnitCount,
    premiumUnitCostCents,
    premiumUnitCount,
    premiumUnitInitialCount,
    notes,
  } = updatedData;

  const isCreate = isNewContract(contract);
  const isDirty = JSON.stringify(updatedData) !== JSON.stringify(contract);
  const [createContract, { loading: createLoading }] = useMutation(ContractAddEdit.create, {
    refetchQueries: ['OrganizationEditor', 'OrganizationGridQuery'],
  });
  const [updateContract, { loading: updateLoading }] = useMutation(ContractAddEdit.update, {
    refetchQueries: ['OrganizationEditor'],
  });
  return (
    <div
      css={{
        marginTop: sizes.Spacing.Small,
        marginBottom: sizes.Spacing.Small,
        padding: sizes.Spacing.Small,
        borderRadius: '8px',
        background: colors.Snow,
      }}
    >
      <Modal
        title='Audit Logs'
        visible={showAuditLogs}
        onCancel={() => setShowAuditLogs(false)}
        footer={null}
      >
        {_.map(_.compact(contract.auditLogs), (auditLog: any) => (
          <AuditLogCell key={auditLog.id} {...auditLog} shouldLabelEntity={true} />
        ))}
      </Modal>
      {!_.isEmpty(contract.auditLogs) && (
        <Row>
          <Button
            css={{ marginLeft: '-14px' }}
            type='link'
            onClick={() => {
              setShowAuditLogs(true);
            }}
          >
            Show Audit Logs
          </Button>
        </Row>
      )}
      <Row>
        <LabeledField label='Name' css={{ flex: 1 }}>
          <Input
            value={updatedData.name}
            onChange={event => setUpdatedData({ ...updatedData, name: event.target.value })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Plan starts on' required={true} css={{ flex: 1 }}>
          <DatePicker
            css={{ width: FIELD_WIDTH }}
            size='default'
            placeholder='Select date'
            format='M/D/YYYY'
            value={startDate && moment(startDate)}
            onChange={newDate => setUpdatedData({ ...updatedData, startDate: newDate })}
          />
        </LabeledField>
        <LabeledField required={true} label='Plan renews on' css={{ flex: 1 }}>
          <DatePicker
            css={{ width: FIELD_WIDTH }}
            size='default'
            placeholder='Select date'
            format='M/D/YYYY'
            value={renewDate ? moment(renewDate) : null}
            onChange={newDate => setUpdatedData({ ...updatedData, renewDate: newDate })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField required={true} label='Product Type' css={{ flex: 1 }}>
          <Select
            css={{ width: FIELD_WIDTH }}
            value={productType}
            onChange={newType => setUpdatedData({ ...updatedData, productType: newType })}
          >
            {/* Only for Team Events to start - TW */}
            <Select.Option value={GraphQL.MysteryProduct.TeamEvent}>Team Event</Select.Option>
          </Select>
        </LabeledField>
        <LabeledField required={true} label='Status' css={{ flex: 1 }}>
          <Select
            css={{
              fontWeight: status === GraphQL.ContractStatus.Active ? 'bold' : undefined,
              color: status === GraphQL.ContractStatus.Active ? colors.DarkPurple : colors.Negative,
              width: FIELD_WIDTH,
            }}
            value={status}
            disabled={isCreate}
            onChange={newStatus => setUpdatedData({ ...updatedData, status: newStatus })}
          >
            {Object.keys(GraphQL.ContractStatus).map(type => (
              <Select.Option key={type} value={type}>
                {_.words(type).join(' ')}
              </Select.Option>
            ))}
          </Select>
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Contract Type' required={true}>
          <Radio.Group
            value={type}
            onChange={newType => setUpdatedData({ ...updatedData, type: newType.target.value })}
          >
            <Radio value={GraphQL.ContractUnitType.Recurring}>Recurring (seats-based)</Radio>
            <Radio value={GraphQL.ContractUnitType.Cents}>Piggy Bank (money-based)</Radio>
            <Radio value={GraphQL.ContractUnitType.Event}>Events (events-based)</Radio>
            <Radio value={GraphQL.ContractUnitType.Credits}>Credits (credits-based)</Radio>
          </Radio.Group>
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label={_.startCase(CONTRACT_UNIT_PHRASE_LOOKUP[type])} css={{ flex: 1 }}>
          <UnitEditor
            unit={{
              costCents: unitCostCents,
              initialCount: unitInitialCount,
              count: unitCount,
              pending: pendingUnitCount,
              type,
            }}
            setUnit={newUnit =>
              setUpdatedData({
                ...updatedData,
                unitCostCents: newUnit.costCents,
                unitInitialCount: newUnit.initialCount,
                unitCount: newUnit.count,
              })
            }
            isCreate={isCreate}
            isPremium={false}
          />
        </LabeledField>
        {/* Hide this UnitEditor for Credits, there is no premium units for that type */
        type != GraphQL.ContractUnitType.Credits && (
          <LabeledField label='Supply & upgrade credits' css={{ flex: 1 }}>
            <UnitEditor
              unit={{
                costCents: premiumUnitCostCents,
                initialCount: premiumUnitInitialCount,
                count: premiumUnitCount,
                pending: pendingPremiumUnitCount,
                type,
              }}
              setUnit={newUnit =>
                setUpdatedData({
                  ...updatedData,
                  premiumUnitCostCents: newUnit.costCents,
                  premiumUnitInitialCount: newUnit.initialCount,
                  premiumUnitCount: newUnit.count,
                })
              }
              isCreate={isCreate}
              isPremium={true}
            />
          </LabeledField>
        )}
      </Row>
      <Row>
        <LabeledField label='Authorized Users' css={{ flex: 1 }}>
          <SearchUsersMultiSelect
            css={{ width: '100%' }}
            value={updatedData.authorizedUsers}
            onChange={event => {
              setUpdatedData({
                ...updatedData,
                authorizedUsers: event.target.options.map((o: any) => o.value),
              });
            }}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Notes' css={{ flex: 1 }}>
          <Input.TextArea
            rows={4}
            value={notes}
            onChange={({ target }) => setUpdatedData({ ...updatedData, notes: target.value })}
          />
        </LabeledField>
      </Row>
      <ScottHr />
      <Row css={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button css={{ marginRight: sizes.Spacing.Small }} type='ghost' onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type='primary'
          disabled={!isDirty || !dataIsValid(updatedData)}
          loading={createLoading || updateLoading}
          onClick={async () => {
            const [mutation, verb] = isCreate
              ? [createContract, 'created']
              : [updateContract, 'updated'];
            const variables = recursivelyRemoveTypenames(
              Object.entries({ ...updatedData, organization: { id: orgId } }).reduce(
                (acc, [key, val]) => {
                  if (!_.isNil(val)) {
                    acc[key] = val;
                  }
                  return acc;
                },
                {},
              ),
            );
            variables.authorizedUsers = updatedData.authorizedUsers.map(user => {
              return { id: user.id };
            });
            await mutation({ variables });
            message.success(`Contract ${verb}!`);
            closeModal();
          }}
        >
          Save
        </Button>
      </Row>
    </div>
  );
};

ContractAddEdit.create = gql`
  mutation ContractCreate(
    $notes: String
    $organization: GenericReferenceInput!
    $premiumUnitCostCents: Int
    $premiumUnitInitialCount: Int
    $productType: MysteryProduct!
    $renewDate: DateTime!
    $startDate: DateTime!
    $type: ContractUnitType!
    $unitCostCents: Int
    $unitInitialCount: Int!
  ) {
    createContract(
      notes: $notes
      organization: $organization
      premiumUnitCostCents: $premiumUnitCostCents
      premiumUnitInitialCount: $premiumUnitInitialCount
      productType: $productType
      renewDate: $renewDate
      startDate: $startDate
      type: $type
      unitCostCents: $unitCostCents
      unitInitialCount: $unitInitialCount
    ) {
      id
    }
  }
`;

ContractAddEdit.update = gql`
  mutation ContractUpdate(
    $id: ID!
    $name: String
    $notes: String
    $premiumUnitCount: Int
    $premiumUnitCostCents: Int
    $productType: MysteryProduct!
    $renewDate: DateTime!
    $startDate: DateTime!
    $status: ContractStatus!
    $type: ContractUnitType!
    $unitCount: Int!
    $unitCostCents: Int
    $authorizedUsers: [GenericReferenceInput!]
  ) {
    updateContract(
      id: $id
      name: $name
      notes: $notes
      premiumUnitCount: $premiumUnitCount
      premiumUnitCostCents: $premiumUnitCostCents
      productType: $productType
      renewDate: $renewDate
      startDate: $startDate
      status: $status
      type: $type
      unitCount: $unitCount
      unitCostCents: $unitCostCents
      authorizedUsers: $authorizedUsers
    ) {
      id
    }
  }
`;

ContractAddEdit.fragments = {
  contract: gql`
    fragment AuthorizedUser on User {
      id
      name
      firstName
    }
  `,
};

export default ContractAddEdit;
