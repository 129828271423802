import { Spin, Tag } from 'antd';
import _ from 'lodash';
import { useQuery } from 'react-apollo';
import React from 'react';

import * as GraphQL from '~/graphql';

import TagsActionCell from './ActionCell';
import { tagsList } from './operations';
import { sizes } from '~/styles';

const SupplyTags = () => {
  const { data, loading, refetch } = useQuery<GraphQL.TagsList.Query, GraphQL.TagsList.Variables>(
    tagsList,
  );

  return loading ? <Spin /> : <TagsTable tags={data.tags} refetch={refetch} />;
};

const TagsTable = ({
  tags,
  refetch,
}: {
  tags: GraphQL.TagsList.Query['tags'];
  refetch: () => Promise<unknown>;
}) => {
  if (!tags) {
    return <div>Failed to load tags; please refresh. And file a ticket if this persists.</div>;
  }

  return (
    <div css={{ overflow: 'auto' }}>
      <table
        css={{
          maxWidth: 400,
          borderSpacing: 8,
          borderCollapse: 'separate',
          margin: `0 ${sizes.Spacing.Large}px`,
        }}
      >
        <thead>
          <tr>
            <th>Tag</th>
            <th css={{ whiteSpace: 'nowrap' }}># of Experiences</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {tags
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(tag => (
              <tr key={tag.id}>
                <td css={{ whiteSpace: 'nowrap' }}>
                  <Tag color='purple'>{tag.name}</Tag>
                </td>
                <td>{tag.experiences.length}</td>
                <td>{!tag.isUsedByCode && <TagsActionCell tag={tag} refetch={refetch} />}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SupplyTags;
