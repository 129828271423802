import React, { useState } from 'react';
import { Button, Row, Icon } from 'antd';
import gql from 'graphql-tag';
import Link from '~/Link';
import { QuestionnaireDialog, QuestionnaireProps } from '.';
import { colors } from '~/styles';

type Props = { button?: boolean; title?: string } & Omit<QuestionnaireProps, 'visible' | 'onClose'>;

const EditQuestionnaireButton = (props: Props) => {
  const { button } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const title = props.title || ' Edit Questionnaire';
  return (
    <>
      {button ? (
        <Button type='primary' onClick={() => setIsDialogOpen(true)}>
          {title}
        </Button>
      ) : (
        <Row onClick={() => setIsDialogOpen(true)}>
          <Icon type='edit' css={{ color: colors.AntdBlue, fontSize: 12 }} />
          <Link>{title}</Link>
        </Row>
      )}

      <QuestionnaireDialog
        onClose={() => setIsDialogOpen(false)}
        visible={isDialogOpen}
        {...props}
      />
    </>
  );
};

EditQuestionnaireButton.fragment = gql`
  fragment EditQuestionnaireButton on Question {
    ...QuestionFragment
  }
  ${QuestionnaireDialog.fragment}
`;

export default EditQuestionnaireButton;
