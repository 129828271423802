import { Select } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { ArrayParam, useQueryParam } from 'use-query-params';

import LabeledField from '~/Labeled/Field';
import { memo } from '~/react';
import { sizes } from '~/styles';

interface Props {
  /**
   * The name of the filtered attribute. The name will be used to link up
   * **directly** with a query param.
   */
  name: string;
  title?: string;
  options: { value: string; label: string }[];
  defaultValues?: string[];
  placeholder?: string;
  css?: any;
}

const FilterMultiSelect = ({
  title,
  name,
  options,
  placeholder,
  defaultValues,
  ...props
}: Props) => {
  const [selectedValues, setSelectedValues] = useQueryParam(name, ArrayParam);
  const [open, setOpen] = useState<boolean>(false);

  const onChange = (values: string[]) => {
    if (defaultValues && _.isEmpty(_.xor(values, defaultValues))) {
      setSelectedValues([]);
    } else {
      setSelectedValues(values);
    }
  };

  const { css, ...otherProps } = props;

  const selectComponent = (
    <Select
      allowClear
      filterOption
      open={open}
      mode='multiple'
      maxTagCount={2}
      onChange={onChange}
      placeholder={placeholder || title}
      value={selectedValues || defaultValues}
      optionFilterProp='label'
      onDropdownVisibleChange={() => setOpen(!open)}
      maxTagPlaceholder={omittedValues => `+${_.size(omittedValues)} more`}
      css={[{ minWidth: sizes.GRID_UNIT * 30 }, css]}
      {...otherProps}
    >
      {_.map(options, ({ label, value }, i) => (
        <Select.Option key={i} value={value} label={label}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );

  return title ? (
    <LabeledField label={title}>{selectComponent}</LabeledField>
  ) : (
    selectComponent
  );
};

export default memo(FilterMultiSelect);
