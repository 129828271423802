import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
// @ts-ignore
import SemanticSearch from 'semantic-ui-react/dist/es/modules/Search/Search';

import Input from '~/Input';
import SearchResults from './Results';

interface Props {
  onSelect: any;
  onClear?: any;
  defaultValue?: any;
  keepBlankValue?: boolean;
  onSearch: (query: string) => Promise<any[]>;
}

const Search = ({
  onSearch,
  onSelect,
  onClear,
  defaultValue,
  keepBlankValue,
  ...otherProps
}: Props) => {
  defaultValue = Search.convertDefaultValue(defaultValue);

  const [results, setResults] = useState<Result[]>([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const onSearchChange = useCallback(
    _.debounce(async (_ev: any, { value: input }: any) => {
      if (!input) {
        onClear && onClear();
        return setResults([]);
      }

      setLoading(true);
      await onSearch(input).then(setResults);
      setLoading(false);
    }, 500),
    [onSearch, setResults, setLoading],
  );

  const onResultSelect = (_ev: any, { result }: any) => {
    onSelect(result);
    setValue(keepBlankValue ? '' : result.title);
  };

  useEffect(() => setValue(defaultValue), [defaultValue]);

  return (
    <SemanticSearch
      fluid
      value={value}
      selectFirstResult
      autoCorrect='off'
      loading={loading}
      results={results}
      autoComplete='off'
      input={otherProps}
      as={Search.SearchComplex}
      onResultSelect={onResultSelect}
      onSearchChange={(_ev: any, { value: input }: any) => {
        onSearchChange(_ev, { value: input });
        setValue(input);
      }}
      {...otherProps}
    />
  );
};

interface Result {
  key: string | number;
  title: string;
  description?: string | null;
  orgs: any;
}

Search.SearchComplex = ({ children, className }: any) => (
  <>
    <Input
      size='default'
      css={{ cursor: 'pointer', ':focus': { cursor: 'text' } }}
      autoFocus
      {...children[0].props}
    />
    <SearchResults visible={_.includes(className.split(' '), 'visible')} {...children[1].props} />
  </>
);

Search.convertDefaultValue = (value: string | any) =>
  typeof value === 'object' ? value.title : value;

export default Search;
