import _ from 'lodash';
import { Button, Input, InputNumber, Radio, Select } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';

import * as GraphQL from '~/graphql';
import Row from '~/Row';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import SearchUsersMultiSelect from '~/Search/Users/MultiSelect';
import AddressInput from '~/Input/Address';
import InputPhone from '~/Input/Phone';
import { sizes } from '~/styles';
import ScottHr from '~/ScottHr';
import EditUserModal from '~/Users/EditUserModal';
import { Edits } from '~/hooks/useEditableEntity';

import Heading from '../Heading';
import InputNumberWithUnit from '~/Input/NumberWithUnit';

interface Props {
  partner: GraphQL.PartnerProfileDetails.Fragment;
  onChange: (edits: Edits<GraphQL.PartnerProfileDetails.Fragment>) => void;
}

const PartnerProfileDetails = (props: Props) => {
  const [addingUser, setAddingUser] = useState(false);

  return (
    <Row marginRight={sizes.Spacing.XXXLarge}>
      <div css={{ flex: 1 }}>
        <Heading>Details</Heading>
        <CoreDetails {...props} />
      </div>
      <div css={{ flex: 1 }}>
        <Heading>Location</Heading>
        <LocationDetails {...props} />
        <ScottHr />
        <Heading>
          <Row css={{ alignItems: 'flex-end' }}>
            <div css={{ flex: 1 }}>Point of Contact</div>
            <Button onClick={() => setAddingUser(true)}>Add House Elf</Button>
            <EditUserModal
              open={addingUser}
              defaultRole={GraphQL.Role.HouseElf}
              onClose={() => setAddingUser(false)}
            />
          </Row>
        </Heading>
        <ContactDetails {...props} />
      </div>
    </Row>
  );
};

const CoreDetails = ({ partner, onChange }: Props) => {
  return (
    <>
      <Row>
        <LabeledField label='Status'>
          <Select value={partner.status} onChange={status => onChange({ status })}>
            {Object.values(GraphQL.PartnerStatus).map(status => (
              <Select.Option key={status} value={status}>
                {status}
              </Select.Option>
            ))}
          </Select>
        </LabeledField>
        <LabeledField label='Quality'>
          <Select value={partner.quality} onChange={quality => onChange({ quality })}>
            {Object.values(GraphQL.PartnerQuality).map(quality => (
              <Select.Option key={quality} value={quality}>
                {quality}
              </Select.Option>
            ))}
          </Select>
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Number of Hosts'>
          <InputNumberWithUnit
            value={partner.numHosts}
            onChange={numHosts => onChange({ numHosts })}
            unit='hosts'
          />
        </LabeledField>
        <LabeledField label='Permission to Record'>
          <Radio.Group
            value={String(partner.permissionToRecord)}
            onChange={event => onChange({ permissionToRecord: event.target.value === 'true' })}
          >
            <Radio value='true'>Yes</Radio>
            <Radio value='false'>No</Radio>
          </Radio.Group>
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Supported Video Platforms'>
          <Select
            mode='multiple'
            value={partner.supportedVideoPlatforms.map(p => p.platform)}
            onChange={platforms =>
              onChange({ supportedVideoPlatforms: platforms.map(platform => ({ platform })) })
            }
          >
            {Object.values(GraphQL.VirtualEventType).map(eventType => (
              <Select.Option key={eventType} value={eventType}>
                {eventType}
              </Select.Option>
            ))}
          </Select>
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Notes'>
          <Input.TextArea
            rows={6}
            value={partner.notes}
            onChange={event => onChange({ notes: event.target.value })}
          />
        </LabeledField>
      </Row>
    </>
  );
};

const LocationDetails = ({ partner, onChange }: Props) => {
  return (
    <>
      <Row>
        <LabeledField label='Address'>
          <AddressInput
            css={{ flex: 1 }}
            address={partner.location ? partner.location.address.full : ''}
            onChange={({ location }) => onChange({ location })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Phone'>
          <InputPhone
            value={partner.phone || ''}
            onChange={(event: any) => {
              onChange({ phone: event.target.value });
            }}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Email'>
          <Input
            value={partner.email}
            onChange={event => {
              onChange({ email: event.target.value });
            }}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='URL'>
          <Input
            value={partner.url}
            onChange={event => {
              onChange({ url: event.target.value });
            }}
          />
        </LabeledField>
      </Row>
    </>
  );
};

const ContactDetails = ({ partner, onChange }: Props) => {
  return (
    <>
      <Row>
        <SearchUsersMultiSelect
          css={{ width: '100%' }}
          value={partner.owners}
          onChange={event => {
            onChange({ owners: event.target.options.map((o: any) => o.value) });
          }}
        />
      </Row>
    </>
  );
};

export default PartnerProfileDetails;

PartnerProfileDetails.fragment = gql`
  fragment PartnerProfileDetails on Partner {
    id
    email
    notes
    numHosts
    permissionToRecord
    phone
    quality
    status
    supportedVideoPlatforms {
      platform
    }
    url
    location {
      id
      address {
        full
      }
    }
    owners {
      ...SearchUsersMultiSelect
    }
  }
  ${SearchUsersMultiSelect.fragment}
`;
