import React from 'react';
import { Icon } from 'antd';
import { NavLink } from 'react-router-dom';
import { colors, sizes } from '~/styles';
import _ from 'lodash';

export type Props = {
  partner?: {
    id: string;
    name: string;
  };
  experience?: {
    id: string;
    name: string;
  };
  supplyPageChild?: {
    name: string;
  };
};

const SupplyBreadcrumb = (props: Props) => {
  const isHome = !props.partner && !props.experience && !props.supplyPageChild;
  const links = _.compact([
    <NavLink
      key={'home-breadcrumb-link'}
      disabled={isHome}
      activeStyle={{
        color: isHome ? colors.OffBlack : colors.Blue.DarkBlue,
      }}
      to='/supply'
    >
      <div
        css={{
          display: 'flex',
          height: 21,
        }}
      >
        Supply
      </div>
    </NavLink>,
    props.partner ? (
      <NavLink
        key={'partner-breadcrumb-link'}
        disabled={!props.experience}
        activeStyle={{
          color: props.experience ? colors.Blue.DarkBlue : colors.OffBlack,
        }}
        to={`/supply/partner/${props.partner.id}`}
      >
        {props.partner.name}
      </NavLink>
    ) : (
      undefined
    ),
    props.partner && props.experience ? (
      <NavLink
        key={'experience-breadcrumb-link'}
        disabled={true}
        activeStyle={{ color: colors.OffBlack }}
        to={`/supply/partner/${props.partner.id}/experience/${props.experience.id}`}
      >
        {props.experience.name}
      </NavLink>
    ) : (
      undefined
    ),
    !props.partner && !props.experience && props.supplyPageChild ? (
      <NavLink
        key={'supply-page-child-link'}
        disabled={true}
        activeStyle={{ color: colors.OffBlack }}
        to={'#'}
      >
        {props.supplyPageChild.name}
      </NavLink>
    ) : (
      undefined
    ),
  ]);
  return (
    <div css={{ display: 'flex', alignItems: 'center' }}>
      {links.map((element, i) => (
        <div key={`breadcrumb-${i}`} css={{ display: 'flex', alignItems: 'center' }}>
          {element}
          {i + 1 !== links.length && (
            <Icon
              key={`breadcrumb-connector-${i}`}
              type='right'
              css={{
                marginLeft: sizes.Spacing.XSmall,
                marginRight: sizes.Spacing.XSmall,
                color: colors.GreyDarker,
                fontSize: 10,
                width: 10,
                height: 10,
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default SupplyBreadcrumb;
