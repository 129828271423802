import { message } from 'antd';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { ErrorLink } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { IncomingMessage } from 'http';
import _ from 'lodash';
import React from 'react';

import { config } from '~/config';
import { font, sizes } from '~/styles';

message.config({ duration: 5, maxCount: 4 });

const showError = (error: string) =>
  message.error(
    <div css={{ display: 'inline-block' }}>
      <span
        css={{
          fontWeight: font.FontWeight.Demi,
          paddingRight: sizes.Spacing.XXSmall,
        }}
      >
        GraphQL Error:
      </span>
      <span css={{ fontStyle: 'italic' }}>{error}</span>
    </div>,
  );

export interface ApolloData {
  initialState?: any;
}

export interface AppWithApolloProps {
  apollo: ApolloData;
}

export interface ApolloClientFactory {
  (options?: { data?: ApolloData; req?: IncomingMessage }): ApolloClient<any>;
}

let client: ApolloClient<NormalizedCacheObject>;

export const getApolloClient = (({ req }: any = {}) => {
  if (client) return client;

  let httpLinkOptions = {
    uri: `${config.apiUrl}/graphql`,
    credentials: 'include',
    headers: {} as any,
  };

  if (req && req.headers.cookie) {
    httpLinkOptions.headers['cookie'] = req.headers.cookie;
  }

  const link = ApolloLink.from([
    new ErrorLink(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) graphQLErrors.map(({ message }) => showError(message));
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink(httpLinkOptions),
  ]);

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [],
      },
    },
  });

  const dataIdFromObject = (obj: any) => obj.id;

  const cache = new InMemoryCache({ dataIdFromObject, fragmentMatcher });

  client = new ApolloClient({ link, cache });

  return client;
}) as ApolloClientFactory;

export const getQueryByName = (name: string) => {
  const client = getApolloClient();

  const queryManager = client.queryManager as any;
  const queryId = _.first(queryManager.queryIdsByName[name]) as string;
  return _.get(queryManager.queries.get(queryId), 'observableQuery');
};

export const refetchQueryByName = async (name: string, variables?: any) => {
  const query = getQueryByName(name);
  return query.refetch(variables);
};

export const recursivelyRemoveTypenames = (_data: any) => {
  const data = _.cloneDeep(_data);

  const removeTypenames = (obj: any) => {
    if (typeof obj === 'object') {
      if (_.isArray(obj)) {
        // We're dealing with an array
        _.forEach(obj, removeTypenames);
      } else {
        // We're dealing with a non-array object
        _.forIn(obj, (val, key) => {
          if (key === '__typename') {
            delete obj[key];
          } else {
            removeTypenames(val);
          }
        });
      }
    } else {
      return;
    }
  };

  removeTypenames(data);

  return data;
};
