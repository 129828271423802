import { Drawer } from '@blueprintjs/core';
import { ActionList } from '@shopify/polaris';
import _ from 'lodash';
import React from 'react';
import { FiMenu } from 'react-icons/fi';

import { navPaths } from '~/App/Routes';
import { colors } from '~/styles';

interface Props {
  activePath: string;
  linker: any;
  visible: boolean;
  setVisible: any;
}

const NavigationSheet = ({ activePath, linker, visible, setVisible }: Props) => {
  const width = _.get(window, 'innerWidth') - 120;

  return (
    <>
      <div
        css={{
          padding: 8,
          cursor: 'pointer',
          borderRadius: '50%',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: '.2s ease-in-out all',
          backgroundColor: 'rgba(255, 255, 255, 0.0)',
          ':hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
          ':active': { backgroundColor: 'rgba(255, 255, 255, 0.07)' },
        }}
        onClick={() => setVisible(true)}
      >
        <FiMenu size={24} color={colors.White} />
      </div>
      <Drawer
        lazy
        size={width}
        style={{ zIndex: 1000 }}
        onClose={() => setVisible(false)}
        isOpen={_.isBoolean(visible) ? visible : true}
      >
        <ActionList
          sections={[
            {
              items: _.map(navPaths, ({ path, label }) => {
                return {
                  content: label,
                  active: activePath === path,
                  onAction: linker.linkTo(path),
                };
              }),
            },
          ]}
        />
      </Drawer>
    </>
  );
};

export default NavigationSheet;
