import { DocumentNode } from 'graphql';
import _ from 'lodash';
import React, { useRef } from 'react';
import { InfiniteLoader, List } from 'react-virtualized';

import FillRemaining from '~/FillRemaining';
import useContainerSize from '~/hooks/useContainerSize';
import LoadingOverlay from '~/Loading/Overlay';
import { memo } from '~/react';
import { usePaginatedQuery } from './helpers';

interface Props<
  TQuery extends PaginatedQuery,
  TVariables extends PaginatedVariables
> {
  setCount?: any;
  rowHeight: number;
  query: DocumentNode;
  variables: TVariables;
  rowRenderer: RowRenderer<any>;
  dataKey: Exclude<keyof TQuery, '__typename'> & string;
}

const PaginatedList = <
  TQuery extends PaginatedQuery,
  TVariables extends PaginatedVariables
>({
  query,
  variables,
  dataKey,
  rowHeight,
  setCount,
  rowRenderer,
  ...otherProps
}: Props<TQuery, TVariables>) => {
  const { rows, count, paginate, hasNextPage, loading } = usePaginatedQuery<
    TQuery,
    TVariables
  >(query, variables, dataKey);

  const containerRef = useRef(null as any);
  const size = useContainerSize(containerRef);

  return (
    <FillRemaining {...otherProps} ref={containerRef}>
      <InfiniteLoader
        threshold={10}
        minimumBatchSize={20}
        rowCount={count}
        loadMoreRows={paginate}
        isRowLoaded={({ index }) => !hasNextPage || index < _.size(rows)}
      >
        {({ onRowsRendered, registerChild }) => (
          <List
            width={size.width}
            height={size.height}
            ref={registerChild}
            rowHeight={rowHeight}
            overscanRowCount={10}
            rowCount={_.size(rows)}
            onRowsRendered={onRowsRendered}
            rowRenderer={_.partial(rowRenderer, rows)}
            noContentRenderer={() => <span>Empty row!</span>}
          />
        )}
      </InfiniteLoader>
      <LoadingOverlay visible={loading} />
    </FillRemaining>
  );
};

export type RowRenderer<TNode> = (
  rows: TNode[],
  props: {
    index: number;
    isScrolling: boolean;
    isVisible: boolean;
    parent: any;
    style: any;
  },
) => React.ReactNode;

export interface PaginatedVariables {
  first?: number | null;
  after?: string | null;
}

export type PaginatedQuery = {
  __typename?: 'Query';
};

export default memo(PaginatedList) as typeof PaginatedList;
