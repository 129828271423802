import React from 'react';

import { colors, font } from '~/styles';

export interface TypographyProps {
  children: React.ReactNode;
}

const Strong = createTypographyComponent({
  ...font.Size.Body,
  fontWeight: font.FontWeight.Medium,
});

interface CreateTypographyComponentArgs {
  fontSize: string | number;
  lineHeight?: string | number;
  fontWeight?: number;
  fontFamily?: string;
  color?: string;
  display?: string;
}

function createTypographyComponent<TProps extends TypographyProps = TypographyProps>(
  cssFontProps: CreateTypographyComponentArgs,
) {
  return ({ children, ...otherProps }: TProps) => (
    <span
      css={{ display: 'block', color: colors.OffBlack, ...cssFontProps }}
      {...otherProps}
    >
      {children}
      <div css={{ display: 'inline' }} />
    </span>
  );
}

export default Strong;
