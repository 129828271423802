import { Typography } from 'antd';
import _ from 'lodash';
import { centsToDollars } from '~/BillingReviewModal/helpers';
import { CostUnit, PricingBand } from '~/graphql';
import NumberInput from '~/Input/Number';
import Link from '~/Link';
import RemoveIcon from '~/Remove/Icon';
import ScottHr from '~/ScottHr';
import { colors, sizes } from '~/styles';
import CreditInputColumn from '~/Supply/ExperienceProfile/Pricing/CreditInputColumn';
import PriceInput from '~/Supply/ExperienceProfile/Pricing/PriceInput';
import UnitType from '~/UnitType';
import BandRow from './BandRow';

interface Props {
  id: number;
  displayIndex: number;
  onRemove: (id: number) => void;
  band: PricingBand;
  onBandChange: (id: number, band: PricingBand) => void;
}

const PriceBand = ({ id, displayIndex, onRemove, band, onBandChange }: Props) => {
  const { minUsers, maxUsers, cents, unit, credits } = band;

  return (
    <BandRow>
      <Typography
        variant='body2'
        css={{ color: colors.ExtraDarkSmoke, marginBottom: sizes.Spacing.XXSmall }}
      >{`#${displayIndex + 1}`}</Typography>
      <Link onClick={() => onRemove(id)} css={{ width: 'fill' }}>
        <RemoveIcon />
      </Link>
      <NumberInput
        data-testid={`min-num-of-users-input-${id}`}
        css={{ width: 100, input: { textAlign: 'right' } }}
        value={minUsers}
        onChange={e =>
          onBandChange(id, {
            ...band,
            minUsers: e.target.value,
          })
        }
      />
      <NumberInput
        data-testid={`max-num-of-users-input-${id}`}
        css={{ width: 100, input: { textAlign: 'right' } }}
        value={maxUsers}
        onChange={e =>
          onBandChange(id, {
            ...band,
            maxUsers: e.target.value,
          })
        }
      />
      <ScottHr isVertical marginBottom={0} marginTop={0} />
      <PriceInput
        inputDataTestId='priceBand-price'
        value={centsToDollars(cents)}
        onChange={val =>
          onBandChange(id, {
            ...band,
            cents: _.toNumber(val) * 100,
          })
        }
        title={''}
        label={''}
        isUserFacing={true}
      />
      <UnitType
        forPriceBand
        value={unit || CostUnit.User}
        onChange={e => {
          return onBandChange(id, {
            ...band,
            unit: e,
          });
        }}
      />
      <CreditInputColumn
        inputDataTestId='priceBand-credits'
        css={{ input: { textAlign: 'right' } }}
        value={centsToDollars(credits)}
        onChange={val =>
          onBandChange(id, {
            ...band,
            credits: _.toNumber(val) * 100,
          })
        }
        title={''}
        label={''}
        isUserFacing={true}
      />
    </BandRow>
  );
};

export default PriceBand;
