import { Select } from 'antd';

import { DataTableFilter, GraphQLFiltersInput } from '../interface';
import { ValueOf } from '../utilTypes';

/**
 * A <DataTable> filter that displays a dropdown where you can select multiple
 * values of an enum to filter to.
 */
export function enumMultiFilter<
  TFilters extends GraphQLFiltersInput,
  TFilterKey extends keyof TFilters = keyof TFilters
>(
  key: TFilterKey,
  title: string,
  enumMultiMap: Record<string, ValueOf<TFilters[TFilterKey]>>,
  transformFn: (string) => string = (input: string) => input,
): DataTableFilter<TFilters, TFilterKey> {
  return {
    field: key,
    title,
    component: ({ value, onChange }) => (
      <Select
        css={STYLES.root}
        optionFilterProp='label'
        mode='multiple'
        value={value}
        onChange={(newValue: string[]) => {
          onChange(newValue.length ? (newValue as any) : undefined);
        }}
      >
        {Object.values(enumMultiMap).map(value => (
          <Select.Option key={value} label={transformFn(value)}>
            {transformFn(value)}
          </Select.Option>
        ))}
      </Select>
    ),
  };
}

const STYLES = {
  root: {
    width: '100%',
  },
};
