import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useState } from 'react';
import Banner from '~/Banner';
import * as GraphQL from '~/graphql';
import { getPriceForBand } from '~/helpers';
import { AlertHexagon, CheckCircle } from '~/icons';
import LabeledSwitch from '~/Input/LabeledSwitch';
import Modal from '~/Modal';
import ScottSection from '~/ScottSection';
import ScottTitle from '~/ScottTitle';
import { colors, sizes } from '~/styles';
import CostToMystery from './CostToMystery';
import Discounts from './Discounts';
import PriceBands from './PriceBands';
import SectionTitle from './SectionTitle';
import TieredEventPricing from './TieredEventPricing';

const strings = {
  seatsContractPricing: 'Seats Contract Pricing',
  customerCharge: 'Customer Pays Mystery',
  modalTitle: 'Pricing Info',
  requiresUpgrade: 'Requires upgrade',
  bannerMaxGuest:
    'We’ll use the max-guests value of Band #1 to set this event’s minimum guests requirement',
  bannerMinRequirement: (guests: number) => {
    return `This event has a minimum requirement of ${guests} guests. Customers will be billed accordingly`;
  },
};

const ExperiencePricingEditor = (props: {
  data: GraphQL.ExperienceProfilePricing.Fragment;
  isOpen?: boolean;
  close?(): void;
}) => {
  const cost: Partial<GraphQL.Cost> = _.get(props.data, 'cost') || {};
  const ctcPricingBands = _.get(cost, 'ctcPricingBands') || [];
  const band: GraphQL.PricingBand = _.nth(ctcPricingBands, 0);

  const [unitType, setUnitType] = useState(cost ? cost.ctmUnit : GraphQL.CostUnit.User);
  const [rawMysteryCharge, setRawMysteryCharge] = useState(cost ? cost.ctmCents : 0);
  const [rawCustomerCharge, setRawCustomerCharge] = useState(
    getPriceForBand(band) || _.get(cost, 'ctcCents'),
  );

  const [ctmPerPersonCentsMinimum, setCtmPerPersonCentsMinimum] = useState(
    cost && cost.ctmPerPersonCentsMinimum,
  );

  const [forceShowTieredEventPricing, setForceShowTieredEventPricing] = useState(false);

  const [ctmTieredEventPricing, setCtmTieredEventPricing] = useState(
    cost && cost.ctmTieredEventPricing ? cost.ctmTieredEventPricing : [],
  );

  const [agreedOnDate, setAgreedOnDate] = useState(cost ? moment(cost.agreedOn) : moment());
  const [cancellationPolicy, setCancellationPolicy] = useState(
    cost ? cost.cancellationPolicy : undefined,
  );
  const [requiresUpgrade, setRequiresUpgrade] = useState(
    _.get(props.data, 'requiresUpgrade') || false,
  );

  const [updateExperience, { loading }] = useMutation<
    GraphQL.ExperiencePricingEditor.Mutation,
    GraphQL.ExperiencePricingEditor.Variables
  >(ExperiencePricingEditor.mutation);

  const [ctmDiscounts, setCtmDiscounts] = useState(
    cost && cost.ctmVolumeDiscounts ? discountTransforms.toObj(cost.ctmVolumeDiscounts) : {},
  );
  const [ctcBands, setCtcBands] = useState<{ [key: number]: GraphQL.PricingBand }>(
    ctcPricingBands.length > 0 ? priceTransforms.toObj(ctcPricingBands) : {},
  );
  const setCtcBand = (id: number, newBand: GraphQL.PricingBand) => {
    setCtcBands({
      ...ctcBands,
      [id]: newBand,
    });
  };
  const removeCtcBand = (id: number) => {
    const { [id]: bandToRemove, ...bandsToKeep } = ctcBands;
    setCtcBands(bandsToKeep);
  };
  const addCtcBand = () => {
    const maxID = Math.max(...Object.keys(ctcBands).map(key => parseInt(key)));
    const nextID = maxID > 0 ? maxID + 1 : 1;
    setCtcBands({
      ...ctcBands,
      [nextID]: {
        cents: NaN,
        minUsers: NaN,
        maxUsers: undefined,
        credits: NaN,
        unit: GraphQL.CostUnit.User,
      },
    });
  };

  const hasMinReqGuests = !!ctcBands[0] && ctcBands[0].maxUsers;
  const bannerText = hasMinReqGuests
    ? strings.bannerMinRequirement(ctcBands[0].maxUsers)
    : strings.bannerMaxGuest;
  const bannerIcon = hasMinReqGuests ? <CheckCircle /> : <AlertHexagon />;

  return (
    <Modal
      width={1000}
      visible={props.isOpen}
      title={strings.modalTitle}
      onCancel={() => {
        props.close();
      }}
      okButtonProps={{
        disabled: false,
        loading,
        'data-testid': 'pricing-info-modal-ok-button',
      }}
      confirmLoading={loading}
      onOk={async () => {
        const ctcPricingBands: GraphQL.PricingBand[] = _.filter(
          [...priceTransforms.fromObj(ctcBands)],
          band => {
            // if cents is NaN, unit can't be found, or minUser is NaN skip it
            if (
              _.isNil(_.get(band, 'unit')) ||
              _.isNaN(_.get(band, 'cents') || NaN) ||
              _.isNaN(_.get(band, 'minUsers') || NaN)
            ) {
              return false;
            }
            return true;
          },
        );
        const band = _.nth(ctcPricingBands, 0);
        if (band) {
          setRawCustomerCharge(getPriceForBand(band));
        }
        const newCost = {
          ctcUnit: _.get(band, 'unit') || cost.ctcUnit || GraphQL.CostUnit.User,
          ctcCents: band ? getPriceForBand(band) : rawCustomerCharge,
          ctmCents: rawMysteryCharge,
          ctmUnit: unitType,
          ctcPricingBands,
          ctmVolumeDiscounts: [...discountTransforms.fromObj(ctmDiscounts)],
          ctmTieredEventPricing: _.sortBy(
            _.filter(
              ctmTieredEventPricing,
              row =>
                _.get(row, 'minNumUsers') != null &&
                _.get(row, 'ctmCents') != null &&
                _.get(row, 'minNumUsers') !== 0 &&
                _.get(row, 'ctmCents') !== 0,
            ).map(row => _.pick(row, ['minNumUsers', 'ctmCents'])),
            'minNumUsers',
          ),
          ctmPerPersonCentsMinimum: ctmPerPersonCentsMinimum ? ctmPerPersonCentsMinimum : undefined,
          agreedOn: agreedOnDate.format('MM/DD/YYYY'),
          cancellationPolicy: cancellationPolicy,
        };
        const missingKeys = costMissingKeys(newCost);
        if (missingKeys.length) {
          message.error(`Missing these fields!: ${missingKeys}`);
          return;
        }
        updateExperience({
          variables: {
            id: props.data.id,
            cost: newCost,
            requiresUpgrade,
          },
          refetchQueries: ['ExperienceProfile'],
        })
          .then(_ => {
            message.success('Updated Experience');
            props.close();
          })
          .catch(error => {
            message.error(JSON.stringify(error));
          });
      }}
    >
      <Discounts
        title='Cost to Mystery Discounts'
        discounts={ctmDiscounts}
        setDiscounts={setCtmDiscounts}
        css={{ marginTop: sizes.Spacing.Medium }}
      />
      <PriceBands
        title='Cost to Customer Prices'
        ctcBands={ctcBands}
        onCtcBandChange={setCtcBand}
        agreedOnDate={agreedOnDate}
        onAgreedDateChange={setAgreedOnDate}
        onRemoveCtcBand={removeCtcBand}
        onAddCtcBand={addCtcBand}
      />
      <Banner
        text={bannerText}
        icon={bannerIcon}
        css={{
          display: 'flex',
          margin: `${sizes.Spacing.Small}px 0`,
          padding: `${sizes.Spacing.Small}px`,
          borderRadius: `${sizes.BorderRadius.Medium}px`,
          backgroundColor: `${colors.NavyBlue}`,
          '.ant-alert-message': {
            marginLeft: sizes.Spacing.Small,
            color: colors.White,
            fontWeight: 500,
          },
        }}
      />
      <div css={{ marginTop: sizes.Spacing.Medium }}>
        <SectionTitle title={strings.seatsContractPricing} />
        <ScottSection>
          <ScottTitle type='Field'>{strings.requiresUpgrade}</ScottTitle>
          <div css={{ marginTop: sizes.Spacing.XSmall }}>
            <LabeledSwitch
              checked={requiresUpgrade}
              onChange={enabled => setRequiresUpgrade(enabled)}
              checkedLabel='Yes'
              uncheckedLabel='No'
            />
          </div>
        </ScottSection>
      </div>
      <div css={{ marginTop: sizes.Spacing.Medium }}>
        <CostToMystery
          ctmPerPersonCentsMinimum={ctmPerPersonCentsMinimum}
          forceShowTieredEventPricing={forceShowTieredEventPricing}
          partnerName={props.data.partner.name}
          rawMysteryCharge={rawMysteryCharge}
          onCtmPerPersonCentsMinimumChange={setCtmPerPersonCentsMinimum}
          onForceShowTieredEventPricingChange={setForceShowTieredEventPricing}
          onRawMysteryChargeChange={setRawMysteryCharge}
          onUnitTypeChange={setUnitType}
          unitType={unitType}
        />
      </div>
      {(forceShowTieredEventPricing || _.size(ctmTieredEventPricing) > 0) && (
        <TieredEventPricing data={ctmTieredEventPricing} setData={setCtmTieredEventPricing} />
      )}
    </Modal>
  );
};

export const requiredCostKeys = ['ctcCents', 'ctmCents', 'ctmUnit', 'ctcPricingBands'];
export const costMissingKeys = (cost): Array<string> => {
  const missingKeys = [];
  for (const key of requiredCostKeys) {
    const val = _.get(cost, key);
    if (_.isNil(val)) {
      missingKeys.push(key);
    } else if (key === 'ctcPricingBands' && val.length === 0) {
      missingKeys.push(key);
    }
  }
  return missingKeys;
};

export const discountTransforms = {
  toObj: (discounts: GraphQL.VolumeDiscount[]): object =>
    discounts.reduce((acc, discount, i) => {
      acc[i] = discount;
      return acc;
    }, {}),
  fromObj: (discounts: object): GraphQL.VolumeDiscount[] =>
    Object.values(discounts)
      .sort((a: GraphQL.VolumeDiscount, b: GraphQL.VolumeDiscount) =>
        a.minNumUsers < b.minNumUsers ? -1 : 1,
      )
      .map(discount => ({
        minNumUsers: parseInt(discount.minNumUsers),
        percentDiscount: parseInt(discount.percentDiscount),
      })),
};

export const priceTransforms = {
  toObj: (priceBands: GraphQL.PricingBand[]): { [key: number]: GraphQL.PricingBand } =>
    priceBands.reduce((acc, band, i) => {
      acc[i] = band;
      return acc;
    }, {}),
  fromObj: (priceBands: object): GraphQL.PricingBand[] =>
    Object.values(priceBands)
      .sort((a: GraphQL.PricingBand, b: GraphQL.PricingBand) => (a.minUsers < b.minUsers ? -1 : 1))
      .map(band => ({
        minUsers: parseInt(band.minUsers),
        maxUsers: band.maxUsers ? parseInt(band.maxUsers) : undefined,
        cents: parseInt(band.cents),
        credits: parseInt(band.credits),
        unit: band.unit || GraphQL.CostUnit.User,
      })),
};

export const VerticalDivide = () => <div css={{ opacity: 0.1, border: '1px solid #000000' }} />;

export default ExperiencePricingEditor;

ExperiencePricingEditor.mutation = gql`
  mutation ExperiencePricingEditor($id: ID!, $cost: CostInput!, $requiresUpgrade: Boolean!) {
    updateXperience(id: $id, cost: $cost, requiresUpgrade: $requiresUpgrade) {
      id
      requiresUpgrade
      cost {
        id
        ctcCents
        ctcUnit
        ctmUnit
        ctmCents
        agreedOn
        ctmVolumeDiscounts {
          minNumUsers
          percentDiscount
        }
        ctcPricingBands {
          minUsers
          maxUsers
          cents
          credits
          unit
        }
        ctmTieredEventPricing {
          minNumUsers
          ctmCents
        }
        ctmPerPersonCentsMinimum
        cancellationPolicy
      }
    }
  }
`;
