import React, { useState } from 'react';
import { sizes } from '~/styles';
import Icon from './Icon';

interface Props {
  checked: boolean;
  onChange(event: { target: { checked: boolean } }): void;
  children?: any;
  isNegative?: boolean;
  disabled?: boolean;
  className?: string;
}

const Checkbox = ({
  checked,
  onChange,
  children,
  isNegative,
  disabled,
  className,
  ...otherProps
}: Props) => {
  return (
    <label
      disabled={disabled}
      className={className}
      css={{
        opacity: disabled ? 0.3 : 1.0,
        cursor: disabled ? 'default' : 'pointer',
        marginBottom: sizes.Spacing.XXSmall,
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={() =>
        !disabled &&
        onChange({
          target: {
            checked: !checked,
          },
        })
      }
      {...otherProps}
    >
      <Icon checked={checked} isNegative={isNegative} />
      <span
        css={{
          paddingLeft: sizes.Spacing.XXSmall,
          userSelect: 'none',
        }}
      >
        {children}
      </span>
    </label>
  );
};

export default Checkbox;
