import React from 'react';
import { Icon } from 'antd';
import gql from 'graphql-tag';
import { isNumber } from 'lodash';
import moment from 'moment-timezone';
import DataTable, { filters } from '~/DataTable';
import { titleCaseFromPascalCase } from '~/formatters';
import * as GraphQL from '~/graphql';
import Link from '~/Link';
import { colors } from '~/styles';
import TagsListCell from '~/Supply/Tags/ListCell';

function formatNumber(num: number) {
  return num / 100;
}

function displayCredits(credits: number, maxUsers: number | null, unit: GraphQL.CostUnit) {
  if (isNumber(maxUsers) && unit === GraphQL.CostUnit.Event) {
    return formatNumber(credits * maxUsers);
  }
  return formatNumber(credits);
}

const ExperiencesTable = (props: object) => {
  return (
    <DataTable<GraphQL.ExperiencesTable.Node, GraphQL.ExperiencesTable.Variables>
      {...props}
      query={query}
      queryPath={['xperiencesConnection']}
      defaultSort={{
        Name: GraphQL.OrderDirection.Asc,
      }}
      defaultFilters={{
        isActive: true,
      }}
      columns={[
        {
          header: 'Partner',
          width: 200,
          cell: ({ partner }) => (
            <Link href={`/supply/partner/${partner.id}`} useReactRouterLink>
              {partner.name}
            </Link>
          ),
        },

        {
          header: 'Experience',
          width: 225,
          sortKey: GraphQL.XperiencesOrderKey.Name,
          cell: ({ id, name, partner }) => (
            <Link href={`/supply/partner/${partner.id}/experience/${id}`} useReactRouterLink>
              {name}
            </Link>
          ),
        },

        {
          header: 'Group Size',
          width: 135,
          sortKey: GraphQL.XperiencesOrderKey.NumMaxGuests,
          filter: filters.integerFilter('numGuests', 'Supports Groups of Size'),
          cell: ({ numMinGuests, numMaxGuests }) =>
            `${numMinGuests || '??'} \u2013 ${numMaxGuests || '??'}`,
        },

        {
          header: 'Customer Pays',
          width: 220,
          cell: ({ cost }) => {
            if (!cost) return null;
            const { ctcPricingBands } = cost;
            return (
              <div>
                {ctcPricingBands.map(({ cents, credits, maxUsers, minUsers, unit }, index) => (
                  <div key={index}>
                    ${formatNumber(cents)} or {displayCredits(credits, maxUsers, unit)}cr /{' '}
                    {unit === 'User' ? 'person' : 'event'} ({minUsers}
                    {maxUsers ? `-${maxUsers}` : '+'})
                  </div>
                ))}
              </div>
            );
          },
        },

        {
          header: 'Shipped Goods',
          width: 165,
          sortKey: GraphQL.XperiencesOrderKey.HasPhysicalGoods,
          filter: filters.booleanFilter('hasPhysicalGoods', 'Has Shipped Goods'),
          cell: ({ hasPhysicalGoods }) =>
            hasPhysicalGoods ? (
              <>
                <Icon type='shopping' /> Yes
              </>
            ) : (
              <span css={{ color: colors.Silver }}>No shipped goods</span>
            ),
        },

        {
          header: 'Last Updated',
          width: 150,
          sortKey: GraphQL.XperiencesOrderKey.UpdatedAt,
          cell: ({ updatedAt }) => moment(updatedAt).fromNow(),
        },

        {
          header: 'Active',
          width: 105,
          sortKey: GraphQL.XperiencesOrderKey.IsActive,
          filter: filters.booleanFilter('isActive', 'Is Active'),
          cell: ({ isActive }) => (isActive ? 'Active' : 'Disabled'),
        },

        {
          header: 'Type',
          width: 95,
          filter: filters.booleanFilter('isVirtual', 'Event Type', {
            true: 'virtual',
            false: 'physical',
          }),
          cell: ({ isVirtual }) => (isVirtual ? 'Virtual' : 'Physical'),
        },

        {
          header: 'Tags',
          width: 400,
          filter: filters.tagFilter('tags'),
          cell: ({ tags }) => <TagsListCell data={tags} sortIndex={tag => tag.name} />,
        },
      ]}
    />
  );
};

const query = gql`
  query ExperiencesTable(
    $first: Int
    $after: String
    $order: [XperiencesOrderArgs]
    $filterInput: XperienceFilterInput
  ) {
    xperiencesConnection(first: $first, after: $after, order: $order, filterInput: $filterInput)
      @connection(key: "xperiencesConnection", filter: ["order", "filterInput"]) {
      edges {
        node {
          hasPhysicalGoods
          id
          isActive
          isVirtual
          name
          numMaxGuests
          numMinGuests
          status
          updatedAt
          cost {
            id
            ctcPricingBands {
              cents
              credits
              maxUsers
              minUsers
              unit
            }
          }
          partner {
            id
            name
          }
          tags {
            id
            name
          }
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export default ExperiencesTable;
