import React from 'react';
import { Input } from 'antd';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import TextArea from '~/Input/TextArea';

const NotesSection = (props: {
  notes?: string;
  setNotes(newValue: string): void;
}) => {
  return (
    <LabeledField label='Notes'>
      <TextArea
        data-testid='event-details-notes-section'
        placeholder='Add notes here'
        value={props.notes}
        onChange={e => props.setNotes(e.target.value)}
      />
    </LabeledField>
  );
};

export default NotesSection;
