import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Button,
  Checkbox,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  TimePicker,
  Tooltip,
  message,
} from 'antd';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import RadioGroup from 'antd/lib/radio/group';
import gql from 'graphql-tag';
import _ from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { useCallback, useState } from 'react';
import { FiChevronDown, FiChevronRight, FiTrash2 } from 'react-icons/fi';
import FilterOwners from '~/Filter/Owners';
import TextArea from '~/Input/TextArea';
import Link from '~/Link';
import Loading from '~/Loading';
import { QuestionnaireDialog } from '~/QuestionnaireDialog';
import EditQuestionnaireButton from '~/QuestionnaireDialog/EditQuestionnaireButton';
import Row from '~/Row';
import ScottHr from '~/ScottHr';
import ScottTitle from '~/ScottTitle';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import OrganizationSearch from '~/Search/Organizations';
import SearchUsers from '~/Search/Users';
import UserDropdown from '~/Search/Users/UserDropdown';
import SearchXperiences from '~/Search/Xperiences';
import Text from '~/Text';
import EditUserModal from '~/Users/EditUserModal';
import { config } from '~/config';
import { mergeDateWithTime } from '~/date';
import { formatUserFullName, titleCaseEnum } from '~/formatters';
import * as GraphQL from '~/graphql';
import { colors, sizes } from '~/styles';
import { Spacing } from '~/styles/sizes';
import AddGuests from './AddGuests';
import ForceRecreateZoomIsvMeeting from './ForceRecreateZoomIsvMeeting';

const ROW_BOTTOM_MARGIN = sizes.Spacing.Large;
const FIELD_WIDTH = 320;
const METABASE_AUDIT_LOG_BASE_URL =
  'https://metabase.trymystery.com/question/2718-team-event-audit-logs';

interface ResendPromptProps {
  onClickDoSend: () => void;
  onClickDontSend: () => void;
  visible: boolean;
  onCancel: () => void;
}

const ResendPrompt = ({ onClickDoSend, onClickDontSend, visible, onCancel, title, children }) => (
  <Modal
    visible={visible}
    title={title}
    footer={
      <div>
        <Button type='default' onClick={onClickDontSend}>
          Don't Resend
        </Button>
        <Button type='primary' onClick={onClickDoSend}>
          Resend
        </Button>
      </div>
    }
    onCancel={onCancel}
  >
    {children}
  </Modal>
);

const ResendConfirmationPrompt = (props: ResendPromptProps) => (
  <ResendPrompt {...props} title='Resend Requester Confirmation Email?'>
    {<div>Do you want to resend the event confirmation email to the new requester?</div>}
  </ResendPrompt>
);

const ResendCredentialsPrompt = (
  props: ResendPromptProps & {
    resendOption: GraphQL.ResendOption;
    setResendOption: (option: GraphQL.ResendOption) => void;
  },
) => (
  <ResendPrompt {...props} title='Resend Instructor Credentials Email?'>
    <div css={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div css={{ paddingBottom: sizes.Spacing.Small }}>
        Do you want to resend the Zoom login credentials?
      </div>
      <LabeledField label={'Send to'}>
        <Select
          css={{ width: '100%' }}
          dropdownMatchSelectWidth={true}
          value={props.resendOption}
          onChange={newOption => props.setResendOption(newOption)}
        >
          {Object.values(GraphQL.ResendOption).map(option => (
            <Select.Option key={option} value={option}>
              {titleCaseEnum(option)}
            </Select.Option>
          ))}
        </Select>
      </LabeledField>
    </div>
  </ResendPrompt>
);

const ExpandingSection = ({ label, children, show, setShow }) => (
  <div css={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        color: colors.AntdBlue,
      }}
      onClick={() => setShow(!show)}
    >
      <span css={{ fontSize: 16, fontWeight: 'bold', marginRight: sizes.Spacing.Small }}>
        {label}
      </span>
      {show ? <FiChevronDown /> : <FiChevronRight />}
    </div>
    {show && children}
  </div>
);

const SelfServeInfo = ({ latestBookingSession, show, setShow }) =>
  latestBookingSession ? (
    <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
      <ExpandingSection label='Self Serve Booking Info' show={show} setShow={setShow}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: sizes.Spacing.Small,
            marginBottom: sizes.Spacing.Small,
          }}
        >
          <LabeledField label='submitted guest emails'>
            {_.map(latestBookingSession.guestEmails, email => (
              <span>{email}</span>
            ))}
          </LabeledField>
          <LabeledField label='selected event details'>
            {_.map(latestBookingSession.selectedEventDetails, detail => (
              <span>{detail}</span>
            ))}
          </LabeledField>
        </div>
      </ExpandingSection>
      ;
    </Row>
  ) : null;

const ConfirmedUsers = ({
  teamEventId,
  show,
  setShow,
  requesterEmail,
  setShowRemoveGuestModal,
  setGuestToRemoveEmail,
}) => {
  const { data, loading } = useQuery<
    GraphQL.GetInvitedGuests.Query,
    GraphQL.GetInvitedGuests.Variables
  >(TeamEventEditor.invitedGuestQuery, { variables: { teamEventId } });

  const guests = _.get(data, 'teamEvent.invitedGuests') as GraphQL.GetInvitedGuests.InvitedGuests[];
  const ordered = _.sortBy(guests, ['rsvpStatus', 'email'], ['asc', 'asc']);
  return (
    <ExpandingSection
      label='Guests'
      show={show}
      setShow={setShow}
      children={
        loading ? (
          <Loading />
        ) : (
          <>
            {ordered.map(invitedGuest => (
              <div
                key={`user-${invitedGuest.email}`}
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  borderBottom: `0.5px solid ${colors.DarkSmoke}`,
                  marginTop: sizes.Spacing.XSmall,
                  marginBottom: sizes.Spacing.XSmall,
                  fontWeight: requesterEmail === invitedGuest.email ? 'bold' : 'normal',
                }}
              >
                <span css={{ width: '30%' }}>{_.get(invitedGuest, 'user.name')}</span>
                <span css={{ width: '50%' }}>{invitedGuest.email}</span>
                <span css={{ width: '20%' }}>{titleCaseEnum(invitedGuest.rsvpStatus)}</span>
                <span
                  css={{ width: '5%', cursor: 'pointer', '*:hover': { color: 'red' } }}
                  onClick={() => {
                    setGuestToRemoveEmail(invitedGuest.email);
                    setShowRemoveGuestModal(true);
                  }}
                >
                  <Tooltip title='Remove guest'>
                    <FiTrash2 />
                  </Tooltip>
                </span>
              </div>
            ))}
            {guests.find(user => user.email === requesterEmail) ? (
              <div
                css={{
                  marginTop: sizes.Spacing.XSmall,
                  marginBottom: sizes.Spacing.XSmall,
                  fontWeight: 'bold',
                }}
              >
                Requester is bolded
              </div>
            ) : null}
          </>
        )
      }
    />
  );
};

const AttendingUsers = ({ virtualEvent, show, setShow }) => {
  const emails = [];
  const names = [];
  _.map(virtualEvent.attendees, attendee => {
    if (attendee.indexOf('@') === -1) {
      names.push(attendee);
    } else {
      emails.push(attendee);
    }
  });
  return (
    <ExpandingSection
      label='Attended'
      show={show}
      setShow={setShow}
      children={[...emails, ...names].map(attendee => (
        <div
          css={{
            marginTop: sizes.Spacing.XSmall,
            marginBottom: sizes.Spacing.XSmall,
            borderBottom: `0.5px solid ${colors.DarkSmoke}`,
          }}
          key={`user-${attendee}`}
        >{`${attendee}`}</div>
      ))}
    />
  );
};

const CancelModal = ({ show, contractUsed, teamEventId, onClose }) => {
  const [cancelTeamEvent] = useMutation(TeamEventEditor.cancel, {
    refetchQueries: ['TeamEventGridQuery'],
  });

  return (
    <Modal title='Cancel Team Event' visible={show} onCancel={onClose} footer={null}>
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <div>Cancel this Team Event... for real?</div>
        {contractUsed && (
          <div css={{ marginTop: sizes.Spacing.Medium }}>
            Please note that contract units / credits used for this event must be manually
            reconciled
          </div>
        )}
        <div
          css={{
            marginTop: sizes.Spacing.Large,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={onClose}>Nevermind</Button>
          <Button
            onClick={() => {
              cancelTeamEvent({ variables: { id: teamEventId } }).then(() => {
                message.success('Team Event canceled!');
                onClose();
              });
            }}
            type='primary'
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const RemoveGuestModal = ({ show, guestEmail, teamEventId, onClose }) => {
  const [removeInvitedUser, { loading }] = useMutation<
    GraphQL.RemoveUserFromTeamEvent.Mutation,
    GraphQL.RemoveUserFromTeamEvent.Variables
  >(TeamEventEditor.removeGuestMutation);

  return (
    <Modal title='Remove Invited Guest' visible={show} onCancel={onClose} footer={null}>
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <div>{`Remove ${guestEmail} from the guest list... for real?`}</div>
        <div
          css={{
            marginTop: sizes.Spacing.Large,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={onClose}>Nevermind</Button>
          <Button
            disabled={loading}
            onClick={async () => {
              await removeInvitedUser({ variables: { teamEventId, email: guestEmail } });
              message.success(`${guestEmail} removed`);
              onClose();
            }}
            type='primary'
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const TeamEventEditor = ({
  onClose,
  orderId,
  viewer,
  goalOptions,
  updatedData,
  setUpdatedData,
  reloadTeamEvent,
  data,
}) => {
  if (!orderId) return null;

  const [showAddGuestsModal, setShowAddGuestsModal] = useState<boolean>(false);
  const [updateRequester, setUpdateRequester] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRemoveGuestModal, setShowRemoveGuestModal] = useState(false);
  const [guestToRemoveEmail, setGuestToRemoveEmail] = useState();
  const [showResendConfirmationPrompt, setShowResendConfirmationPrompt] = useState(false);
  const [showResendCredentialsPrompt, setShowResendCredentialsPrompt] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showSelfServe, setShowSelfServe] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [showAttending, setAttending] = useState(false);
  const [showZoomInfo, setShowZoomInfo] = useState(false);
  const [resendConfirmationEmail, setResendConfirmationEmail] = useState(false);
  const [shouldShowEmailPrompt, setShouldShowEmailPrompt] = useState(false);
  const [resendOption, setResendOption] = useState(GraphQL.ResendOption.InstructorOnly);

  // Added due to a weird bug where the AntD drawer gets scrolled to the middle of the page on load
  const scrollToTop = useCallback(n => {
    if (n) n.scrollTo(0, 0);
  }, []);

  const [updateTeamEvent] = useMutation<
    GraphQL.TeamEventEditorUpdate.Mutation,
    GraphQL.TeamEventEditorUpdate.Variables
  >(TeamEventEditor.mutation, {
    refetchQueries: ['TeamEventGridQuery', 'TeamEventEditor'],
  });

  const [updateVirtualEvent] = useMutation<
    GraphQL.TeamEventVirtualEventUpdate.Mutation,
    GraphQL.TeamEventVirtualEventUpdate.Variables
  >(TeamEventEditor.updateVirtualEvent, {
    refetchQueries: ['TeamEventGridQuery', 'TeamEventEditor'],
  });

  const [sendCredentialsEmail] = useMutation(TeamEventEditor.resendCredentialsEmail);

  const {
    id,
    description,
    expectedHeadCount,
    experience,
    finalHeadCount,
    hubspotDealId,
    initialPrice,
    isRequestToBook,
    joinLink,
    latestBookingSession,
    opsPreEventQuestionnaire,
    preEventQuestionnaire,
    organization,
    owner,
    shortId,
    status,
    requestedBy,
    requestedFor,
    reviewQuestionnaire,
    signUpExpirationDate,
    surpriseToAttendees,
    surpriseToAll,
    title,
    virtualEvent,
    participantFamiliarity,
    participationModel,
    purpose,
    goals,
    testEventType,
  } = updatedData;

  const formattedPrice = (_.get(initialPrice, 'totalCents') || 0) / 100;
  const includesVirtualEvent = !!virtualEvent;
  const lineItems = _.get(initialPrice, 'lineItems');
  const unitsUsed = lineItems
    ? _.reduce(
        lineItems,
        (acc, lineItem) => {
          acc += lineItem.contractUnits;
          return acc;
        },
        0,
      )
    : null;
  const upgradesUsed = lineItems
    ? _.reduce(
        lineItems,
        (acc, lineItem) => {
          acc += lineItem.contractPremiumUnits;
          return acc;
        },
        0,
      )
    : null;

  const onSubmit = async (args?: { keepPanelOpen?: boolean }) => {
    if (shouldShowEmailPrompt) {
      setShowResendConfirmationPrompt(true);
      return;
    }

    setSaving(true);
    const instructorChanged =
      _.get(virtualEvent, 'instructor.id') !== _.get(data.teamEvent, 'virtualEvent.instructor.id');
    const updateArgs: GraphQL.TeamEventEditorUpdate.Variables = {
      id: orderId,
      description,
      expectedHeadCount,
      experience: !!_.get(updatedData, 'experience')
        ? _.pick(_.get(updatedData, 'experience'), 'id')
        : undefined,
      finalHeadCount,
      isRequestToBook,
      organization: organization ? _.pick(organization, 'id') : null,
      owner: _.pick(owner, 'id'),
      resendConfirmationEmail,
      // instructorChanged prevents the case of emailing two people when updating both the experience and the
      // instructor in the same "Save Call" - See ENG-4030 - https://linear.app/mystery/issue/ENG-4030/booking-confirmation-email-sent-to-both-ige-hosts
      instructorChanged,
      requestedFor,
      signUpExpirationDate,
      surpriseToAttendees,
      surpriseToAll,
      title,
      participantFamiliarity,
      participationModel,
      purpose,
      goals: _.map(goals, goal => _.pick(goal, 'id')),
      testEventType,
    };

    if (requestedBy) {
      updateArgs.requestedBy = _.pick(requestedBy, 'id');
    }

    try {
      await updateTeamEvent({
        variables: updateArgs,
      });

      if (includesVirtualEvent) {
        const userChangedVirtualEventValues: [string, any][] = _.differenceWith(
          _.toPairs(virtualEvent),
          _.toPairs(data.teamEvent.virtualEvent),
          _.isEqual,
        );

        const variablesToUpdate = _.fromPairs(userChangedVirtualEventValues);

        await updateVirtualEvent({
          variables: {
            virtualEventId: virtualEvent.id,
            type: virtualEvent.type,
            ...variablesToUpdate,
          },
        });
      }

      message.success('Updated Team Event');
      if (!_.get(args, 'keepPanelOpen')) onClose();
    } catch (errors) {
      message.error(JSON.stringify(errors));
    }
    setSaving(false);
  };

  const isDirty = JSON.stringify(updatedData) !== JSON.stringify(_.get(data, 'teamEvent'));

  const resendLoginDisabled = (): number => {
    let disabled = 0;
    !moment(requestedFor)
      .subtract(90, 'minutes')
      .isSameOrBefore(moment()) && (disabled = disabled ^ 1);
    isDirty && (disabled = disabled ^ 2);
    return disabled;
  };

  const resendLoginTooltips = {
    1: 'Disabled until 90 minutes before the event',
    2: 'Save your changes first',
    3: 'Disabled until 90 minutes before the event. And you have unsaved changes',
  };

  return (
    <React.Fragment>
      <div
        ref={scrollToTop}
        css={{
          flex: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: sizes.Spacing.Medium,
        }}
      >
        <ResendConfirmationPrompt
          visible={showResendConfirmationPrompt}
          onClickDoSend={() => {
            setShouldShowEmailPrompt(false);
            setShowResendConfirmationPrompt(false);
            setResendConfirmationEmail(true);
            onSubmit();
          }}
          onClickDontSend={() => {
            setShouldShowEmailPrompt(false);
            setShowResendConfirmationPrompt(false);
            setResendConfirmationEmail(false);
            onSubmit();
          }}
          onCancel={() => setShowResendConfirmationPrompt(false)}
        />
        <ResendCredentialsPrompt
          visible={showResendCredentialsPrompt}
          onClickDoSend={async () => {
            setShowResendCredentialsPrompt(false);
            await sendCredentialsEmail({
              variables: {
                zoomMeetingId: _.get(virtualEvent, 'zoomMeeting.id'),
                type: resendOption,
              },
            });

            const sentTo =
              resendOption === GraphQL.ResendOption.InstructorOnly
                ? `${_.get(virtualEvent, 'instructor.email')}`
                : resendOption === GraphQL.ResendOption.InstructorAndMe
                ? `${_.get(virtualEvent, 'instructor.email')} and BCC'd to ${_.get(
                    viewer,
                    'email',
                  )}`
                : _.get(viewer, 'email');
            message.success(`Credentials sent to ${sentTo}`);
          }}
          onClickDontSend={() => setShowResendCredentialsPrompt(false)}
          onCancel={() => setShowResendCredentialsPrompt(false)}
          resendOption={resendOption}
          setResendOption={setResendOption}
        />
        <EditUserModal
          onClose={() => setUpdateRequester(false)}
          open={updateRequester}
          user={requestedBy}
        />
        <CancelModal
          onClose={() => {
            setShowCancelModal(false);
            onClose();
          }}
          teamEventId={id}
          show={showCancelModal}
          contractUsed={!!unitsUsed || !!upgradesUsed}
        />
        <RemoveGuestModal
          show={showRemoveGuestModal}
          onClose={() => setShowRemoveGuestModal(false)}
          guestEmail={guestToRemoveEmail}
          teamEventId={id}
        />
        <ScottTitle type='SubSection'>Team Event Summary</ScottTitle>
        <Row css={{ marginBottom: Spacing.None }}>
          <Row onClick={() => setUpdateRequester(true)} alignItems='center'>
            <Icon type='edit' css={{ color: colors.AntdBlue, fontSize: 12 }} />
            <Link>Edit requester info</Link>
          </Row>
          <EditQuestionnaireButton
            title={`Edit Review Questionnaire`}
            questionnaireDefaultType={GraphQL.DefaultQuestionnaireFor.TeamEvent}
            questionnaire={reviewQuestionnaire}
            teamEventReview={{ id }}
          />
          <Row
            css={{
              opacity: preEventQuestionnaire ? 1 : 0.5,
              cursor: preEventQuestionnaire ? 'pointer' : 'default !important',
            }}
            onClick={() => {
              if (preEventQuestionnaire)
                window.location.href = `/questionnaire/${preEventQuestionnaire.id}`;
            }}
            alignItems='center'
          >
            <Icon type='question' css={{ color: colors.AntdBlue, fontSize: 12 }} />
            <Link>View pre-event answers</Link>
          </Row>
          <Row
            css={{
              opacity: opsPreEventQuestionnaire ? 1 : 0.5,
              cursor: opsPreEventQuestionnaire ? 'pointer' : 'default !important',
            }}
            onClick={() => {
              if (opsPreEventQuestionnaire)
                window.location.href = `/questionnaire/${opsPreEventQuestionnaire.id}`;
            }}
            alignItems='center'
          >
            <Icon type='question' css={{ color: colors.AntdBlue, fontSize: 12 }} />
            <Link>Review questionnaire</Link>
          </Row>
          <Row alignItems='center'>
            <Link
              href={`${METABASE_AUDIT_LOG_BASE_URL}?eventid=${id}`}
              target='_blank'
              css={{ display: 'flex' }}
            >
              <Icon
                type='pull-request'
                css={{ color: colors.AntdBlue, fontSize: 12, height: '100%', alignSelf: 'center' }}
              />
              <span>View Event Changes</span>
            </Link>
          </Row>
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <EditQuestionnaireButton
            title={`Edit Pre-Event Questionnaire`}
            questionnaire={opsPreEventQuestionnaire}
            opsPreTeamEvent={{ id }}
            refetch={['TeamEventEditor']}
          />
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField label='join link'>{joinLink}</LabeledField>
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField label='team event short id'>{shortId}</LabeledField>
          <LabeledField label='title'>{title}</LabeledField>
          <LabeledField label='experience'>{_.get(experience, 'name')}</LabeledField>
          <LabeledField label='Instructor'>{_.get(virtualEvent, 'instructor.name')}</LabeledField>
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField label='Date/Time'>
            {DateTime.fromJSDate(new Date(requestedFor)).toFormat('LL/dd/yy hh:mm')}
          </LabeledField>
          <LabeledField label='Anticipated Head Count'>{expectedHeadCount}</LabeledField>
          <LabeledField label='Hubspot Deal ID'>
            {hubspotDealId ? (
              <a href={`${config.hubspot.baseUrl}${config.hubspot.dealPath}${hubspotDealId}`}>
                {hubspotDealId}
              </a>
            ) : (
              '-'
            )}
          </LabeledField>
          <LabeledField label='Collected Payment Info'>{`$${formattedPrice}`}</LabeledField>
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField label='team event id'>{id}</LabeledField>
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField
            label='title'
            css={{ height: '60px' }}
            errorText={_.size(title) >= 50 && '50 char. limit reached'}
          >
            <Input
              value={title}
              maxLength={50}
              onChange={({ target }) =>
                setUpdatedData({
                  ...updatedData,
                  title: target.value,
                })
              }
            />
          </LabeledField>
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField label='Organization'>
            <div>
              <OrganizationSearch
                onSelect={async (org: { id: string; internalName: string }) => {
                  setUpdatedData({
                    ...updatedData,
                    organization: org,
                  });
                }}
                defaultValue={_.get(organization, 'internalName')}
                orgId={_.get(organization, 'id')}
              />
            </div>
          </LabeledField>
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField label='Experience'>
            <div>
              <SearchXperiences
                virtualOnly
                css={{ height: 32, fontSize: '14px !important' }}
                value={_.get(updatedData, 'experience.name')}
                onChange={experience => setUpdatedData({ ...updatedData, experience })}
              />
            </div>
          </LabeledField>
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField label='Supply Partner Notes'>
            <Input
              value={description}
              onChange={({ target }) =>
                setUpdatedData({
                  ...updatedData,
                  description: target.value,
                })
              }
            />
          </LabeledField>
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField label='Test event type'>
            <Select
              value={testEventType}
              defaultValue={null}
              css={{ width: '100%' }}
              onChange={value =>
                setUpdatedData({
                  ...updatedData,
                  testEventType: value,
                })
              }
            >
              <option value={null}>-----</option>
              {Object.values(GraphQL.TestEventType).map(eventType => (
                <option value={eventType} key={eventType}>
                  {eventType}
                </option>
              ))}
            </Select>
          </LabeledField>
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField label='event day'>
            <DatePicker
              size='default'
              placeholder='Select date'
              format='M/D/YYYY'
              value={moment(requestedFor)}
              onChange={async newDate => {
                setUpdatedData({
                  ...updatedData,
                  requestedFor: mergeDateWithTime(newDate, requestedFor),
                });
              }}
              css={{ width: FIELD_WIDTH }}
            />
          </LabeledField>
          <LabeledField label='meeting time'>
            <TimePicker
              size='default'
              placeholder='Select time'
              use12Hours
              minuteStep={5}
              format='h:mm a'
              value={moment(requestedFor)}
              onChange={newTime => {
                setUpdatedData({
                  ...updatedData,
                  requestedFor: mergeDateWithTime(requestedFor, newTime),
                });
              }}
              css={{ width: FIELD_WIDTH }}
            />
          </LabeledField>
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField label='Expected Headcount'>
            <Input
              type='number'
              value={expectedHeadCount}
              onChange={({ target }) =>
                setUpdatedData({
                  ...updatedData,
                  expectedHeadCount: Number(target.value),
                })
              }
            />
          </LabeledField>
          <LabeledField label='Final Headcount'>
            <Input
              disabled={
                ![
                  GraphQL.TeamEventStatus.ReadyToReview,
                  GraphQL.TeamEventStatus.InvoiceSent,
                  GraphQL.TeamEventStatus.Complete,
                ].includes(status)
              }
              type='number'
              value={finalHeadCount}
              onChange={({ target }) =>
                setUpdatedData({
                  ...updatedData,
                  finalHeadCount: Number(target.value),
                })
              }
            />
          </LabeledField>
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField label='Connection Goals'>
            <CheckboxGroup
              css={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              value={_.map(goals, 'id')}
              onChange={newGoalIds =>
                setUpdatedData({
                  ...updatedData,
                  goals: _.map(newGoalIds, newGoalId => ({ id: newGoalId })),
                })
              }
              options={_.map(goalOptions, option => ({
                value: option.id,
                label: titleCaseEnum(option.goal),
              }))}
            />
          </LabeledField>
        </Row>
        <SelfServeInfo
          latestBookingSession={latestBookingSession}
          show={showSelfServe}
          setShow={setShowSelfServe}
        />
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <ConfirmedUsers
            teamEventId={id}
            show={showUsers}
            setShow={setShowUsers}
            requesterEmail={_.get(requestedBy, 'email')}
            setGuestToRemoveEmail={setGuestToRemoveEmail}
            setShowRemoveGuestModal={setShowRemoveGuestModal}
          />
        </Row>
        {virtualEvent ? (
          <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
            <AttendingUsers
              virtualEvent={virtualEvent}
              show={showAttending}
              setShow={setAttending}
            />
          </Row>
        ) : null}
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField label='Join Link'>
            <Text size='Small'>{joinLink}</Text>
          </LabeledField>
          <LabeledField label='Sign Up Expiration'>
            <DatePicker
              size='default'
              placeholder='Select date'
              format='M/D/YYYY'
              value={moment(signUpExpirationDate)}
              onChange={newDate => {
                setUpdatedData({
                  ...updatedData,
                  signUpExpirationDate: newDate,
                });
              }}
              css={{ width: FIELD_WIDTH }}
            />
          </LabeledField>
        </Row>
        <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
          <LabeledField label='Requester'>
            <div>
              <SearchUsers
                css={{ height: 32, maxWidth: FIELD_WIDTH, fontSize: 10 }}
                onChange={user => {
                  if (requestedBy && status !== GraphQL.TeamEventStatus.Requested) {
                    setShouldShowEmailPrompt(true);
                  }
                  setUpdatedData({
                    ...updatedData,
                    requestedBy: user,
                  });
                }}
                value={
                  requestedBy
                    ? formatUserFullName({
                        firstName: _.get(requestedBy, 'firstName', '-'),
                        lastName: _.get(requestedBy, 'lastName', '-'),
                      })
                    : ''
                }
              />
            </div>
          </LabeledField>
          <LabeledField label='owner'>
            <UserDropdown
              placeholder='No owner'
              user={_.get(owner, 'id')}
              setUser={newOwner =>
                setUpdatedData({
                  ...updatedData,
                  owner: { id: newOwner },
                })
              }
              query={FilterOwners.query}
            />
          </LabeledField>
        </Row>
        <ScottHr />
        <ScottTitle type='SubSection'>SSF Event Details</ScottTitle>
        <Row>
          <LabeledField label='Participant Location' css={{ flex: 2 }}>
            <RadioGroup
              value={participationModel}
              onChange={e => setUpdatedData({ ...updatedData, participationModel: e.target.value })}
            >
              <Radio.Button value={GraphQL.ParticipationModel.InPerson}>In Person</Radio.Button>
              <Radio.Button value={GraphQL.ParticipationModel.Hybrid}>Hybrid</Radio.Button>
              <Radio.Button value={GraphQL.ParticipationModel.Virtual}>Virtual</Radio.Button>
            </RadioGroup>
          </LabeledField>
          <LabeledField label='Participant Familiarity' css={{ flex: 3 }}>
            <RadioGroup
              value={participantFamiliarity}
              onChange={e =>
                setUpdatedData({ ...updatedData, participantFamiliarity: e.target.value })
              }
            >
              <Radio.Button value={1}>Strangers</Radio.Button>
              <Radio.Button value={2}>A little</Radio.Button>
              <Radio.Button value={3}>Average</Radio.Button>
              <Radio.Button value={4}>Good</Radio.Button>
              <Radio.Button value={5}>BFFs</Radio.Button>
            </RadioGroup>
          </LabeledField>
        </Row>
        <Row>
          <LabeledField label='Purpose of Event'>
            <TextArea
              value={purpose}
              onChange={({ target }) =>
                setUpdatedData({
                  ...updatedData,
                  purpose: target.value,
                })
              }
            />
          </LabeledField>
        </Row>
        {includesVirtualEvent && (
          <React.Fragment>
            <ScottHr />
            <ScottTitle type='SubSection'>Meeting Options</ScottTitle>
            <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }} justifyContent='flex-start'>
              <Row alignItems='center'>
                <Icon type='usergroup-add' css={{ color: colors.AntdBlue, fontSize: 12 }} />
                <Link onClick={() => setShowAddGuestsModal(true)}>Add Guests</Link>
                <AddGuests
                  isOpen={showAddGuestsModal}
                  close={() => {
                    reloadTeamEvent();
                    setShowAddGuestsModal(false);
                  }}
                  teamEvent={updatedData}
                />
              </Row>
              <EditQuestionnaireButton
                title={`Edit Review Questionnaire`}
                questionnaireDefaultType={GraphQL.DefaultQuestionnaireFor.VirtualEvent}
                questionnaire={_.get(virtualEvent, 'reviewQuestionnaire')}
                virtualEventReview={{ id: virtualEvent.id }}
              />
            </Row>
            <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
              <LabeledField label='virtual event id'>{virtualEvent.id}</LabeledField>
              <LabeledField css={{ paddingLeft: '5px' }} label='meeting provider'>
                <Radio.Group
                  css={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                  onChange={({ target }) =>
                    setUpdatedData({
                      ...updatedData,
                      virtualEvent: {
                        ...virtualEvent,
                        type: target.value,
                      },
                    })
                  }
                  value={_.get(virtualEvent, 'type')}
                >
                  <Radio key={`meeting-provider`} value={GraphQL.VirtualEventType.ZoomIsv}>
                    {titleCaseEnum(GraphQL.VirtualEventType.ZoomIsv)}
                  </Radio>
                </Radio.Group>
              </LabeledField>
            </Row>
            <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
              <Checkbox
                css={{ flex: 0.5 }}
                onChange={() =>
                  setUpdatedData({
                    ...updatedData,
                    surpriseToAttendees: !surpriseToAttendees,
                  })
                }
                defaultChecked={surpriseToAttendees}
              >
                Surprise to Attendees
              </Checkbox>
              <Checkbox
                css={{ flex: 0.5 }}
                onChange={() =>
                  setUpdatedData({
                    ...updatedData,
                    surpriseToAll: !surpriseToAll,
                  })
                }
                defaultChecked={surpriseToAll}
              >
                Surprise to All
              </Checkbox>
              <Checkbox
                css={{ flex: 0.5 }}
                onChange={() =>
                  setUpdatedData({
                    ...updatedData,
                    isRequestToBook: !isRequestToBook,
                  })
                }
                defaultChecked={isRequestToBook}
              >
                Is Request To Book
              </Checkbox>
            </Row>
            <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
              <Checkbox
                css={{ flex: 0.5 }}
                onChange={() =>
                  setUpdatedData({
                    ...updatedData,
                    virtualEvent: {
                      ...virtualEvent,
                      requireInstructor: !_.get(virtualEvent, 'requireInstructor', false),
                    },
                  })
                }
                defaultChecked={!!_.get(virtualEvent, 'requireInstructor', false)}
              >
                Meeting Requires Instructor?
              </Checkbox>
              <Checkbox
                css={{ flex: 0.5 }}
                onChange={() =>
                  setUpdatedData({
                    ...updatedData,
                    virtualEvent: {
                      ...virtualEvent,
                      joinBeforeHost: !_.get(virtualEvent, 'joinBeforeHost', false),
                    },
                  })
                }
                defaultChecked={!!_.get(virtualEvent, 'joinBeforeHost', false)}
              >
                Allow Join Before Host?
              </Checkbox>
            </Row>
            <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
              <LabeledField css={{ flex: 0.5 }} label='instructor'>
                <UserDropdown
                  disabled={!_.get(virtualEvent, 'requireInstructor', false)}
                  placeholder='No instructor'
                  user={_.get(virtualEvent, 'instructor.id')}
                  setUser={newInstructor =>
                    setUpdatedData({
                      ...updatedData,
                      virtualEvent: {
                        ...virtualEvent,
                        instructor: {
                          id: newInstructor,
                        },
                      },
                    })
                  }
                  fullName={true}
                  query={TeamEventEditor.instructorQuery}
                />
              </LabeledField>
            </Row>
            <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
              <LabeledField css={{ flex: 0.5 }} label='duration mins'>
                <InputNumber
                  css={{ width: '97%' }}
                  placeholder='No duration...'
                  value={_.get(virtualEvent, 'durationMins')}
                  onChange={newDuration =>
                    setUpdatedData({
                      ...updatedData,
                      virtualEvent: {
                        ...virtualEvent,
                        durationMins: newDuration,
                      },
                    })
                  }
                />
              </LabeledField>
              <LabeledField css={{ flex: 0.5 }} label='join event lead time mins'>
                <InputNumber
                  css={{ width: '97%' }}
                  placeholder='No duration...'
                  value={_.get(virtualEvent, 'joinEventButtonEnabledLeadTimeMins')}
                  disabled={!_.get(virtualEvent, 'joinBeforeHost', false)}
                  onChange={newLeadTime =>
                    setUpdatedData({
                      ...updatedData,
                      virtualEvent: {
                        ...virtualEvent,
                        joinEventButtonEnabledLeadTimeMins: newLeadTime,
                      },
                    })
                  }
                />
              </LabeledField>
            </Row>
            <Row css={{ marginBottom: ROW_BOTTOM_MARGIN }}>
              <Tooltip
                overlay={resendLoginDisabled() ? resendLoginTooltips[resendLoginDisabled()] : false}
              >
                <div>
                  <Link
                    disabled={resendLoginDisabled()}
                    onClick={() => setShowResendCredentialsPrompt(true)}
                  >
                    Resend Login Credentials
                  </Link>
                </div>
              </Tooltip>
            </Row>
            <ScottHr />
          </React.Fragment>
        )}

        {includesVirtualEvent &&
          (virtualEvent.type === GraphQL.VirtualEventType.Zoom ||
            virtualEvent.type === GraphQL.VirtualEventType.ZoomIsv) && (
            <ExpandingSection label='Zoom Details' show={showZoomInfo} setShow={setShowZoomInfo}>
              <Row css={{ marginTop: sizes.Spacing.Small }}>
                <LabeledField label='zoom host email'>
                  <Text>{_.get(virtualEvent, 'zoomMeeting.host.email', '-')}</Text>
                </LabeledField>
              </Row>
              <Row css={{ marginTop: sizes.Spacing.Small }}>
                <LabeledField label='zoom host joined at'>
                  <Text>
                    {_.get(virtualEvent, 'zoomMeeting.hostJoinedAt')
                      ? DateTime.fromISO(
                          _.get(virtualEvent, 'zoomMeeting.hostJoinedAt'),
                        ).toLocaleString(DateTime.DATETIME_MED)
                      : '-'}
                  </Text>
                </LabeledField>
              </Row>
              <Row>
                <LabeledField label='zoom start url (host only)'>
                  <Link href={_.get(virtualEvent, 'zoomMeeting.startUrl', '-')} target='_blank'>
                    {_.get(virtualEvent, 'zoomMeeting.startUrl', '-')}
                  </Link>
                </LabeledField>
              </Row>
              <Row>
                <LabeledField label='zoom join url (participants)'>
                  <Text>{_.get(virtualEvent, 'zoomMeeting.joinUrl', '-')}</Text>
                </LabeledField>
              </Row>
              <ForceRecreateZoomIsvMeeting virtualEventId={_.get(virtualEvent, 'id')} />
            </ExpandingSection>
          )}
      </div>
      <div>
        <ScottHr marginTop={0} marginBottom={0} />
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            padding: sizes.Spacing.Medium,
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            type='danger'
            onClick={() => {
              setShowCancelModal(true);
            }}
          >
            Cancel Event
          </Button>
          <Button
            loading={saving}
            icon='save'
            type='primary'
            size='small'
            disabled={!isDirty}
            onClick={() => onSubmit({ keepPanelOpen: true })}
          />
          <div>
            <Button
              type='default'
              onClick={() => {
                onClose();
                setUpdatedData(undefined);
              }}
              css={{ marginRight: sizes.Spacing.Small }}
            >
              Close
            </Button>
            <Button loading={saving} type='primary' disabled={!isDirty} onClick={() => onSubmit()}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

TeamEventEditor.updateVirtualEvent = gql`
  mutation TeamEventVirtualEventUpdate(
    $virtualEventId: ID!
    $instructor: GenericReferenceInput
    $durationMins: Int
    $requireInstructor: Boolean
    $joinBeforeHost: Boolean
    $joinEventButtonEnabledLeadTimeMins: Int
    $type: VirtualEventType
  ) {
    updateVirtualEvent(
      id: $virtualEventId
      instructor: $instructor
      durationMins: $durationMins
      requireInstructor: $requireInstructor
      joinBeforeHost: $joinBeforeHost
      joinEventButtonEnabledLeadTimeMins: $joinEventButtonEnabledLeadTimeMins
      type: $type
    ) {
      id
    }
  }
`;

TeamEventEditor.mutation = gql`
  mutation TeamEventEditorUpdate(
    $id: ID!
    $title: String
    $description: String
    $expectedHeadCount: Int
    $organization: GenericReferenceInput
    $requestedFor: DateTime
    $signUpExpirationDate: DateTime
    $requestedBy: GenericReferenceInput
    $owner: NullableReferenceInput!
    $experience: GenericReferenceInput
    $resendConfirmationEmail: Boolean
    $surpriseToAttendees: Boolean
    $surpriseToAll: Boolean
    $isRequestToBook: Boolean
    $finalHeadCount: Int
    $freeEvent: Boolean
    $manualBilling: Boolean
    $participantFamiliarity: Int
    $participationModel: ParticipationModel
    $purpose: String
    $goals: [GenericReferenceInput!]
    $testEventType: TestEventType
    $instructorChanged: Boolean
  ) {
    updateTeamEvent(
      id: $id
      title: $title
      description: $description
      expectedHeadCount: $expectedHeadCount
      organization: $organization
      requestedFor: $requestedFor
      signUpExpirationDate: $signUpExpirationDate
      requestedBy: $requestedBy
      experience: $experience
      resendConfirmationEmail: $resendConfirmationEmail
      surpriseToAttendees: $surpriseToAttendees
      surpriseToAll: $surpriseToAll
      isRequestToBook: $isRequestToBook
      finalHeadCount: $finalHeadCount
      freeEvent: $freeEvent
      manualBilling: $manualBilling
      participantFamiliarity: $participantFamiliarity
      participationModel: $participationModel
      purpose: $purpose
      goals: $goals
      testEventType: $testEventType
      instructorChanged: $instructorChanged
    ) {
      id
      experience {
        id
        name
      }
    }
    updateTeamEventOwner(id: $id, owner: $owner) {
      id
    }
  }
`;

TeamEventEditor.resendCredentialsEmail = gql`
  mutation TeamEventEditorResendLoginEmail($zoomMeetingId: ID!, $type: ResendOption) {
    resendZoomLoginEmail(id: $zoomMeetingId, type: $type) {
      id
    }
  }
`;

TeamEventEditor.cancel = gql`
  mutation TeamEventEditorCancel($id: ID!) {
    cancelTeamEvent(id: $id) {
      id
    }
  }
`;

TeamEventEditor.instructorQuery = gql`
  query VirtualEventInstructors {
    users(role: HouseElf) {
      id
      name
      firstName
      lastName
    }
  }
`;

TeamEventEditor.invitedGuestQuery = gql`
  query GetInvitedGuests($teamEventId: ID!) {
    teamEvent(id: $teamEventId) {
      id
      invitedGuests {
        id
        user {
          id
          name
        }
        email
        rsvpStatus
      }
    }
  }
`;

TeamEventEditor.removeGuestMutation = gql`
  mutation RemoveUserFromTeamEvent($teamEventId: ID!, $email: String!) {
    removeUserFromTeamEventGuestList(id: $teamEventId, email: $email) {
      id
      invitedGuests {
        id
      }
    }
  }
`;

TeamEventEditor.query = gql`
  query TeamEventEditor($id: ID!) {
    teamEvent(id: $id) {
      id
      title
      shortId
      description
      expectedHeadCount
      finalHeadCount
      joinLink
      surpriseToAttendees
      surpriseToAll
      freeEvent
      manualBilling
      isRequestToBook
      status
      testEventType
      invitedGuests {
        email
        rsvpStatus
        user {
          firstName
          lastName
        }
      }
      initialPrice {
        id
        paidAt
        totalCents
        lineItems {
          id
          category
          contractUnits
          contractPremiumUnits
        }
      }
      finalizedPrice {
        id
        stripeChargeId
      }
      hubspotDealId

      experience {
        id
        name
      }
      virtualEvent {
        id
        type
        attendees
        durationMins
        zoomMeeting {
          id
          host {
            id
            email
          }
          joinUrl
          startUrl
          hostJoinedAt
        }
        requireInstructor
        instructor {
          id
          name
          firstName
          lastName
          phone
          email
          role
        }
        joinBeforeHost
        joinEventButtonEnabledLeadTimeMins
        reviewQuestionnaire {
          id
          questions {
            ...QuestionFragment
          }
        }
      }
      requestedFor
      signUpExpirationDate
      creator {
        id
        firstName
        lastName
      }
      organization {
        id
        name
        internalName
      }
      owner {
        id
        name
        firstName
        lastName
      }
      party {
        id
        firstName
        lastName
        email
      }
      requestedBy {
        id
        name
        firstName
        lastName
        phone
        email
        role
      }
      reviewQuestionnaire {
        id
        questions {
          ...QuestionFragment
        }
      }
      opsPreEventQuestionnaire {
        id
        questions {
          ...QuestionFragment
          answers {
            id
          }
        }
      }
      preEventQuestionnaire {
        id
      }
      latestBookingSession {
        id
        guestEmails
        selectedEventDetails
      }
      participantFamiliarity
      participationModel
      purpose
      goals {
        id
      }
    }
  }
  ${QuestionnaireDialog.fragment}
`;

export default TeamEventEditor;
