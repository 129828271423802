import _ from 'lodash';
import * as GraphQL from '~/graphql';
import PriceInput from './PriceInput';

const mysteryCharge = (partnerName: string) => `Mystery Pays ${partnerName}`;
const pricePer = (unit: GraphQL.CostUnit, unitName: string = 'Event') =>
  `Price / ${unit === GraphQL.CostUnit.User ? 'Person' : unitName}`;

const MysteryCharge = ({ value, unit, onChange, partnerName }) => (
  <PriceInput
    inputDataTestId='mystery-charge-input'
    value={value}
    onChange={val => onChange(_.toNumber(val) * 100)}
    title={mysteryCharge(partnerName)}
    label={pricePer(unit)}
    isUserFacing={false}
  />
);

export default MysteryCharge;
