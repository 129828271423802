import { Tab as EvergreenTab, Tablist } from 'evergreen-ui';
import _ from 'lodash';
import React, { Suspense, useState } from 'react';

import useMobile from '~/hooks/useMobile';
import Loading from '~/Loading';
import { memo } from '~/react';
import { borders, sizes } from '~/styles';
import FillRemaining from '../FillRemaining';

interface Props {
  defaultTab?: string;
  tabs: Tab[];
}

const Tabs = ({ defaultTab, tabs, ...otherProps }: Props) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab || _.first(tabs)!.label);

  const isPhone = useMobile();

  const ActiveTab = _.find(tabs, { label: selectedTab })!;
  const tabProps = _.isFunction(ActiveTab.props)
    ? ActiveTab.props()
    : ActiveTab.props;

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }} {...otherProps}>
      <div css={{ padding: sizes.Spacing.XSmall, borderBottom: borders.divider }}>
        <Tablist css={isPhone ? { width: '100%', display: 'flex' } : {}}>
          {tabs.map(({ label }) => (
            <EvergreenTab
              key={label}
              isSelected={selectedTab === label}
              onSelect={() => setSelectedTab(label)}
            >
              {label}
            </EvergreenTab>
          ))}
        </Tablist>
      </div>
      <FillRemaining>
        <Suspense fallback={<Loading />}>
          <ActiveTab.component {...tabProps} />
        </Suspense>
      </FillRemaining>
    </div>
  );
};

export interface Tab {
  label: string | React.ReactNode;
  component: any | React.FunctionComponent | React.ComponentClass<any>;
  props: any;
}

export default memo(Tabs);
