export default [
  {
    name: 'People',
    emojis: [
      { no: 6, code: '1F604', char: '😄', name: 'grinning face with smiling eyes' },
      { no: 5, code: '1F603', char: '😃', name: 'grinning face with big eyes' },
      { no: 1, code: '1F600', char: '😀', name: 'grinning face' },
      { no: 10, code: '1F60A', char: '😊', name: 'smiling face with smiling eyes' },
      { no: 9, code: '1F609', char: '😉', name: 'winking face' },
      { no: 13, code: '1F60D', char: '😍', name: 'smiling face with heart-eyes' },
      { no: 14, code: '1F618', char: '😘', name: 'face blowing a kiss' },
      { no: 17, code: '1F61A', char: '😚', name: 'kissing face with closed eyes' },
      { no: 15, code: '1F617', char: '😗', name: 'kissing face' },
      { no: 16, code: '1F619', char: '😙', name: 'kissing face with smiling eyes' },
      { no: 39, code: '1F61C', char: '😜', name: 'winking face with tongue' },
      { no: 40, code: '1F61D', char: '😝', name: 'squinting face with tongue' },
      { no: 38, code: '1F61B', char: '😛', name: 'face with tongue' },
      { no: 65, code: '1F633', char: '😳', name: 'flushed face' },
      { no: 2, code: '1F601', char: '😁', name: 'beaming face with smiling eyes' },
      { no: 44, code: '1F614', char: '😔', name: 'pensive face' },
      { no: 37, code: '1F60C', char: '😌', name: 'relieved face' },
      { no: 42, code: '1F612', char: '😒', name: 'unamused face' },
      { no: 52, code: '1F61E', char: '😞', name: 'disappointed face' },
      { no: 29, code: '1F623', char: '😣', name: 'persevering face' },
      { no: 55, code: '1F622', char: '😢', name: 'crying face' },
      { no: 3, code: '1F602', char: '😂', name: 'face with tears of joy' },
      { no: 56, code: '1F62D', char: '😭', name: 'loudly crying face' },
      { no: 34, code: '1F62A', char: '😪', name: 'sleepy face' },
      { no: 30, code: '1F625', char: '😥', name: 'sad but relieved face' },
      { no: 63, code: '1F630', char: '😰', name: 'anxious face with sweat' },
      { no: 7, code: '1F605', char: '😅', name: 'grinning face with sweat' },
      { no: 43, code: '1F613', char: '😓', name: 'downcast face with sweat' },
      { no: 60, code: '1F629', char: '😩', name: 'weary face' },
      { no: 35, code: '1F62B', char: '😫', name: 'tired face' },
      { no: 59, code: '1F628', char: '😨', name: 'fearful face' },
      { no: 64, code: '1F631', char: '😱', name: 'face screaming in fear' },
      { no: 69, code: '1F620', char: '😠', name: 'angry face' },
      { no: 68, code: '1F621', char: '😡', name: 'pouting face' },
      { no: 54, code: '1F624', char: '😤', name: 'face with steam from nose' },
      { no: 51, code: '1F616', char: '😖', name: 'confounded face' },
      { no: 8, code: '1F606', char: '😆', name: 'grinning squinting face' },
      { no: 11, code: '1F60B', char: '😋', name: 'face savoring food' },
      { no: 71, code: '1F637', char: '😷', name: 'face with medical mask' },
      { no: 12, code: '1F60E', char: '😎', name: 'smiling face with sunglasses' },
      { no: 36, code: '1F634', char: '😴', name: 'sleeping face' },
      { no: 67, code: '1F635', char: '😵', name: 'dizzy face' },
      { no: 48, code: '1F632', char: '😲', name: 'astonished face' },
      { no: 53, code: '1F61F', char: '😟', name: 'worried face' },
      { no: 57, code: '1F626', char: '😦', name: 'frowning face with open mouth' },
      { no: 58, code: '1F627', char: '😧', name: 'anguished face' },
      { no: 86, code: '1F47F', char: '👿', name: 'angry face with horns' },
      { no: 31, code: '1F62E', char: '😮', name: 'face with open mouth' },
      { no: 62, code: '1F62C', char: '😬', name: 'grimacing face' },
      { no: 24, code: '1F610', char: '😐', name: 'neutral face' },
      { no: 45, code: '1F615', char: '😕', name: 'confused face' },
      { no: 33, code: '1F62F', char: '😯', name: 'hushed face' },
      { no: 28, code: '1F60F', char: '😏', name: 'smirking face' },
      { no: 25, code: '1F611', char: '😑', name: 'expressionless face' },
      { no: 462, code: '1F472', char: '👲', name: 'man with Chinese cap' },
      { no: 444, code: '1F473', char: '👳', name: 'person wearing turban' },
      { no: 360, code: '1F46E', char: '👮', name: 'police officer' },
      { no: 414, code: '1F477', char: '👷', name: 'construction worker' },
      { no: 396, code: '1F482', char: '💂', name: 'guard' },
      { no: 108, code: '1F476', char: '👶', name: 'baby' },
      { no: 120, code: '1F466', char: '👦', name: 'boy' },
      { no: 126, code: '1F467', char: '👧', name: 'girl' },
      { no: 138, code: '1F468', char: '👨', name: 'man' },
      { no: 144, code: '1F469', char: '👩', name: 'woman' },
      { no: 156, code: '1F474', char: '👴', name: 'old man' },
      { no: 162, code: '1F475', char: '👵', name: 'old woman' },
      { no: 480, code: '1F471', char: '👱', name: 'blond-haired person' },
      { no: 522, code: '1F47C', char: '👼', name: 'baby angel' },
      { no: 438, code: '1F478', char: '👸', name: 'princess' },
      { no: 96, code: '1F63A', char: '😺', name: 'grinning cat face' },
      {
        no: 97,
        code: '1F638',
        char: '😸',
        name: 'grinning cat face with smiling eyes',
      },
      {
        no: 99,
        code: '1F63B',
        char: '😻',
        name: 'smiling cat face with heart-eyes',
      },
      { no: 101, code: '1F63D', char: '😽', name: 'kissing cat face' },
      { no: 100, code: '1F63C', char: '😼', name: 'cat face with wry smile' },
      { no: 102, code: '1F640', char: '🙀', name: 'weary cat face' },
      { no: 103, code: '1F63F', char: '😿', name: 'crying cat face' },
      { no: 98, code: '1F639', char: '😹', name: 'cat face with tears of joy' },
      { no: 104, code: '1F63E', char: '😾', name: 'pouting cat face' },
      { no: 87, code: '1F479', char: '👹', name: 'ogre' },
      { no: 88, code: '1F47A', char: '👺', name: 'goblin' },
      { no: 105, code: '1F648', char: '🙈', name: 'see-no-evil monkey' },
      { no: 106, code: '1F649', char: '🙉', name: 'hear-no-evil monkey' },
      { no: 107, code: '1F64A', char: '🙊', name: 'speak-no-evil monkey' },
      { no: 89, code: '1F480', char: '💀', name: 'skull' },
      { no: 92, code: '1F47D', char: '👽', name: 'alien' },
      { no: 95, code: '1F4A9', char: '💩', name: 'pile of poo' },
      { no: 1927, code: '1F525', char: '🔥', name: 'fire' },
      { no: 1934, code: '2728', char: '✨', name: 'sparkles' },
      { no: 1902, code: '1F31F', char: '🌟', name: 'glowing star' },
      { no: 1469, code: '1F4AB', char: '💫', name: 'dizzy' },
      { no: 1466, code: '1F4A5', char: '💥', name: 'collision' },
      { no: 1464, code: '1F4A2', char: '💢', name: 'anger symbol' },
      { no: 1467, code: '1F4A6', char: '💦', name: 'sweat droplets' },
      { no: 1928, code: '1F4A7', char: '💧', name: 'droplet' },
      { no: 1463, code: '1F4A4', char: '💤', name: 'zzz' },
      { no: 1468, code: '1F4A8', char: '💨', name: 'dashing away' },
      { no: 1425, code: '1F442', char: '👂', name: 'ear' },
      { no: 1438, code: '1F440', char: '👀', name: 'eyes' },
      { no: 1431, code: '1F443', char: '👃', name: 'nose' },
      { no: 1442, code: '1F445', char: '👅', name: 'tongue' },
      { no: 1443, code: '1F444', char: '👄', name: 'mouth' },
      { no: 1328, code: '1F44D', char: '👍', name: 'thumbs up' },
      { no: 1334, code: '1F44E', char: '👎', name: 'thumbs down' },
      { no: 1322, code: '1F44C', char: '👌', name: 'OK hand' },
      { no: 1346, code: '1F44A', char: '👊', name: 'oncoming fist' },
      { no: 1340, code: '270A', char: '✊', name: 'raised fist' },
      { no: 1370, code: '1F44B', char: '👋', name: 'waving hand' },
      { no: 1316, code: '270B', char: '✋', name: 'raised hand' },
      { no: 1394, code: '1F450', char: '👐', name: 'open hands' },
      { no: 1262, code: '1F446', char: '👆', name: 'backhand index pointing up' },
      { no: 1274, code: '1F447', char: '👇', name: 'backhand index pointing down' },
      { no: 1250, code: '1F449', char: '👉', name: 'backhand index pointing right' },
      { no: 1244, code: '1F448', char: '👈', name: 'backhand index pointing left' },
      { no: 1400, code: '1F64C', char: '🙌', name: 'raising hands' },
      { no: 1412, code: '1F64F', char: '🙏', name: 'folded hands' },
      { no: 1388, code: '1F44F', char: '👏', name: 'clapping hands' },
      { no: 1238, code: '1F4AA', char: '💪', name: 'flexed biceps' },
      { no: 834, code: '1F6B6', char: '🚶', name: 'person walking' },
      { no: 852, code: '1F3C3', char: '🏃', name: 'person running' },
      { no: 870, code: '1F483', char: '💃', name: 'woman dancing' },
      { no: 1195, code: '1F46B', char: '👫', name: 'man and woman holding hands' },
      { no: 1206, code: '1F46A', char: '👪', name: 'family' },
      { no: 1198, code: '1F48F', char: '💏', name: 'kiss' },
      { no: 1202, code: '1F491', char: '💑', name: 'couple with heart' },
      { no: 882, code: '1F46F', char: '👯', name: 'people with bunny ears' },
      { no: 690, code: '1F646', char: '🙆', name: 'person gesturing OK' },
      { no: 672, code: '1F645', char: '🙅', name: 'person gesturing NO' },
      { no: 708, code: '1F481', char: '💁', name: 'person tipping hand' },
      { no: 726, code: '1F64B', char: '🙋', name: 'person raising hand' },
      { no: 798, code: '1F486', char: '💆', name: 'person getting massage' },
      { no: 816, code: '1F487', char: '💇', name: 'person getting haircut' },
      { no: 1419, code: '1F485', char: '💅', name: 'nail polish' },
      { no: 504, code: '1F470', char: '👰', name: 'bride with veil' },
      { no: 654, code: '1F64E', char: '🙎', name: 'person pouting' },
      { no: 636, code: '1F64D', char: '🙍', name: 'person frowning' },
      { no: 744, code: '1F647', char: '🙇', name: 'person bowing' },
      { no: 1500, code: '1F3A9', char: '🎩', name: 'top hat' },
      { no: 1498, code: '1F451', char: '👑', name: 'crown' },
      { no: 1499, code: '1F452', char: '👒', name: 'woman’s hat' },
      { no: 1494, code: '1F45F', char: '👟', name: 'running shoe' },
      { no: 1493, code: '1F45E', char: '👞', name: 'man’s shoe' },
      { no: 1496, code: '1F461', char: '👡', name: 'woman’s sandal' },
      { no: 1495, code: '1F460', char: '👠', name: 'high-heeled shoe' },
      { no: 1497, code: '1F462', char: '👢', name: 'woman’s boot' },
      { no: 1478, code: '1F455', char: '👕', name: 't-shirt' },
      { no: 1477, code: '1F454', char: '👔', name: 'necktie' },
      { no: 1487, code: '1F45A', char: '👚', name: 'woman’s clothes' },
      { no: 1484, code: '1F457', char: '👗', name: 'dress' },
      { no: 1976, code: '1F3BD', char: '🎽', name: 'running shirt' },
      { no: 1479, code: '1F456', char: '👖', name: 'jeans' },
      { no: 1485, code: '1F458', char: '👘', name: 'kimono' },
      { no: 1486, code: '1F459', char: '👙', name: 'bikini' },
      { no: 2097, code: '1F4BC', char: '💼', name: 'briefcase' },
      { no: 1489, code: '1F45C', char: '👜', name: 'handbag' },
      { no: 1490, code: '1F45D', char: '👝', name: 'clutch bag' },
      { no: 1488, code: '1F45B', char: '👛', name: 'purse' },
      { no: 1475, code: '1F453', char: '👓', name: 'glasses' },
      { no: 1944, code: '1F380', char: '🎀', name: 'ribbon' },
      { no: 1918, code: '1F302', char: '🌂', name: 'closed umbrella' },
      { no: 1505, code: '1F484', char: '💄', name: 'lipstick' },
      { no: 1454, code: '1F49B', char: '💛', name: 'yellow heart' },
      { no: 1452, code: '1F499', char: '💙', name: 'blue heart' },
      { no: 1456, code: '1F49C', char: '💜', name: 'purple heart' },
      { no: 1453, code: '1F49A', char: '💚', name: 'green heart' },
      { no: 1448, code: '1F494', char: '💔', name: 'broken heart' },
      { no: 1451, code: '1F497', char: '💗', name: 'growing heart' },
      { no: 1447, code: '1F493', char: '💓', name: 'beating heart' },
      { no: 1449, code: '1F495', char: '💕', name: 'two hearts' },
      { no: 1450, code: '1F496', char: '💖', name: 'sparkling heart' },
      { no: 1459, code: '1F49E', char: '💞', name: 'revolving hearts' },
      { no: 1445, code: '1F498', char: '💘', name: 'heart with arrow' },
      { no: 1462, code: '1F48C', char: '💌', name: 'love letter' },
      { no: 1444, code: '1F48B', char: '💋', name: 'kiss mark' },
      { no: 1506, code: '1F48D', char: '💍', name: 'ring' },
      { no: 1507, code: '1F48E', char: '💎', name: 'gem stone' },
      { no: 958, code: '1F464', char: '👤', name: 'bust in silhouette' },
      { no: 1470, code: '1F4AC', char: '💬', name: 'speech balloon' },
      { no: 1437, code: '1F463', char: '👣', name: 'footprints' },
    ],
  },
  {
    name: 'Nature',
    emojis: [
      { no: 1511, code: '1F436', char: '🐶', name: 'dog face' },
      { no: 1514, code: '1F43A', char: '🐺', name: 'wolf face' },
      { no: 1516, code: '1F431', char: '🐱', name: 'cat face' },
      { no: 1543, code: '1F42D', char: '🐭', name: 'mouse face' },
      { no: 1546, code: '1F439', char: '🐹', name: 'hamster face' },
      { no: 1547, code: '1F430', char: '🐰', name: 'rabbit face' },
      { no: 1568, code: '1F438', char: '🐸', name: 'frog face' },
      { no: 1519, code: '1F42F', char: '🐯', name: 'tiger face' },
      { no: 1553, code: '1F428', char: '🐨', name: 'koala' },
      { no: 1552, code: '1F43B', char: '🐻', name: 'bear face' },
      { no: 1531, code: '1F437', char: '🐷', name: 'pig face' },
      { no: 1534, code: '1F43D', char: '🐽', name: 'pig nose' },
      { no: 1527, code: '1F42E', char: '🐮', name: 'cow face' },
      { no: 1533, code: '1F417', char: '🐗', name: 'boar' },
      { no: 1508, code: '1F435', char: '🐵', name: 'monkey face' },
      { no: 1509, code: '1F412', char: '🐒', name: 'monkey' },
      { no: 1522, code: '1F434', char: '🐴', name: 'horse face' },
      { no: 1536, code: '1F411', char: '🐑', name: 'ewe' },
      { no: 1541, code: '1F418', char: '🐘', name: 'elephant' },
      { no: 1554, code: '1F43C', char: '🐼', name: 'panda face' },
      { no: 1563, code: '1F427', char: '🐧', name: 'penguin' },
      { no: 1562, code: '1F426', char: '🐦', name: 'bird' },
      { no: 1560, code: '1F424', char: '🐤', name: 'baby chick' },
      { no: 1561, code: '1F425', char: '🐥', name: 'front-facing baby chick' },
      { no: 1559, code: '1F423', char: '🐣', name: 'hatching chick' },
      { no: 1557, code: '1F414', char: '🐔', name: 'chicken' },
      { no: 1572, code: '1F40D', char: '🐍', name: 'snake' },
      { no: 1570, code: '1F422', char: '🐢', name: 'turtle' },
      { no: 1591, code: '1F41B', char: '🐛', name: 'bug' },
      { no: 1593, code: '1F41D', char: '🐝', name: 'honeybee' },
      { no: 1592, code: '1F41C', char: '🐜', name: 'ant' },
      { no: 1594, code: '1F41E', char: '🐞', name: 'lady beetle' },
      { no: 1589, code: '1F40C', char: '🐌', name: 'snail' },
      { no: 1584, code: '1F419', char: '🐙', name: 'octopus' },
      { no: 1585, code: '1F41A', char: '🐚', name: 'spiral shell' },
      { no: 1581, code: '1F420', char: '🐠', name: 'tropical fish' },
      { no: 1580, code: '1F41F', char: '🐟', name: 'fish' },
      { no: 1579, code: '1F42C', char: '🐬', name: 'dolphin' },
      { no: 1577, code: '1F433', char: '🐳', name: 'spouting whale' },
      { no: 1523, code: '1F40E', char: '🐎', name: 'horse' },
      { no: 1573, code: '1F432', char: '🐲', name: 'dragon face' },
      { no: 1582, code: '1F421', char: '🐡', name: 'blowfish' },
      { no: 1539, code: '1F42B', char: '🐫', name: 'two-hump camel' },
      { no: 1513, code: '1F429', char: '🐩', name: 'poodle' },
      { no: 1555, code: '1F43E', char: '🐾', name: 'paw prints' },
      { no: 1599, code: '1F490', char: '💐', name: 'bouquet' },
      { no: 1600, code: '1F338', char: '🌸', name: 'cherry blossom' },
      { no: 1608, code: '1F337', char: '🌷', name: 'tulip' },
      { no: 1617, code: '1F340', char: '🍀', name: 'four leaf clover' },
      { no: 1603, code: '1F339', char: '🌹', name: 'rose' },
      { no: 1606, code: '1F33B', char: '🌻', name: 'sunflower' },
      { no: 1605, code: '1F33A', char: '🌺', name: 'hibiscus' },
      { no: 1618, code: '1F341', char: '🍁', name: 'maple leaf' },
      { no: 1620, code: '1F343', char: '🍃', name: 'leaf fluttering in wind' },
      { no: 1619, code: '1F342', char: '🍂', name: 'fallen leaf' },
      { no: 1615, code: '1F33F', char: '🌿', name: 'herb' },
      { no: 1614, code: '1F33E', char: '🌾', name: 'sheaf of rice' },
      { no: 1645, code: '1F344', char: '🍄', name: 'mushroom' },
      { no: 1613, code: '1F335', char: '🌵', name: 'cactus' },
      { no: 1612, code: '1F334', char: '🌴', name: 'palm tree' },
      { no: 1647, code: '1F330', char: '🌰', name: 'chestnut' },
      { no: 1609, code: '1F331', char: '🌱', name: 'seedling' },
      { no: 1607, code: '1F33C', char: '🌼', name: 'blossom' },
      { no: 1885, code: '1F311', char: '🌑', name: 'new moon' },
      { no: 1887, code: '1F313', char: '🌓', name: 'first quarter moon' },
      { no: 1888, code: '1F314', char: '🌔', name: 'waxing gibbous moon' },
      { no: 1889, code: '1F315', char: '🌕', name: 'full moon' },
      { no: 1895, code: '1F31B', char: '🌛', name: 'first quarter moon face' },
      { no: 1893, code: '1F319', char: '🌙', name: 'crescent moon' },
      { no: 1725, code: '1F30F', char: '🌏', name: 'globe showing Asia-Australia' },
      { no: 1731, code: '1F30B', char: '🌋', name: 'volcano' },
      { no: 1777, code: '1F30C', char: '🌌', name: 'milky way' },
      { no: 1903, code: '1F320', char: '🌠', name: 'shooting star' },
      { no: 1905, code: '26C5', char: '⛅', name: 'sun behind cloud' },
      { no: 1925, code: '26C4', char: '⛄', name: 'snowman without snow' },
      { no: 1916, code: '1F300', char: '🌀', name: 'cyclone' },
      { no: 1769, code: '1F301', char: '🌁', name: 'foggy' },
      { no: 1917, code: '1F308', char: '🌈', name: 'rainbow' },
      { no: 1929, code: '1F30A', char: '🌊', name: 'water wave' },
    ],
  },
  {
    name: 'Objects',
    emojis: [
      { no: 1939, code: '1F38D', char: '🎍', name: 'pine decoration' },
      { no: 1458, code: '1F49D', char: '💝', name: 'heart with ribbon' },
      { no: 1940, code: '1F38E', char: '🎎', name: 'Japanese dolls' },
      { no: 1492, code: '1F392', char: '🎒', name: 'school backpack' },
      { no: 1501, code: '1F393', char: '🎓', name: 'graduation cap' },
      { no: 1941, code: '1F38F', char: '🎏', name: 'carp streamer' },
      { no: 1932, code: '1F386', char: '🎆', name: 'fireworks' },
      { no: 1933, code: '1F387', char: '🎇', name: 'sparkler' },
      { no: 1942, code: '1F390', char: '🎐', name: 'wind chime' },
      { no: 1943, code: '1F391', char: '🎑', name: 'moon viewing ceremony' },
      { no: 1930, code: '1F383', char: '🎃', name: 'jack-o-lantern' },
      { no: 91, code: '1F47B', char: '👻', name: 'ghost' },
      { no: 528, code: '1F385', char: '🎅', name: 'Santa Claus' },
      { no: 1931, code: '1F384', char: '🎄', name: 'Christmas tree' },
      { no: 1945, code: '1F381', char: '🎁', name: 'wrapped gift' },
      { no: 1938, code: '1F38B', char: '🎋', name: 'tanabata tree' },
      { no: 1936, code: '1F389', char: '🎉', name: 'party popper' },
      { no: 1937, code: '1F38A', char: '🎊', name: 'confetti ball' },
      { no: 1935, code: '1F388', char: '🎈', name: 'balloon' },
      { no: 2359, code: '1F38C', char: '🎌', name: 'crossed flags' },
      { no: 2150, code: '1F52E', char: '🔮', name: 'crystal ball' },
      { no: 2032, code: '1F3A5', char: '🎥', name: 'movie camera' },
      { no: 2037, code: '1F4F7', char: '📷', name: 'camera' },
      { no: 2039, code: '1F4F9', char: '📹', name: 'video camera' },
      { no: 2040, code: '1F4FC', char: '📼', name: 'videocassette' },
      { no: 2030, code: '1F4BF', char: '💿', name: 'optical disk' },
      { no: 2031, code: '1F4C0', char: '📀', name: 'dvd' },
      { no: 2028, code: '1F4BD', char: '💽', name: 'computer disk' },
      { no: 2029, code: '1F4BE', char: '💾', name: 'floppy disk' },
      { no: 2022, code: '1F4BB', char: '💻', name: 'laptop computer' },
      { no: 2014, code: '1F4F1', char: '📱', name: 'mobile phone' },
      { no: 2017, code: '1F4DE', char: '📞', name: 'telephone receiver' },
      { no: 2018, code: '1F4DF', char: '📟', name: 'pager' },
      { no: 2019, code: '1F4E0', char: '📠', name: 'fax machine' },
      { no: 2045, code: '1F4E1', char: '📡', name: 'satellite antenna' },
      { no: 2036, code: '1F4FA', char: '📺', name: 'television' },
      { no: 2007, code: '1F4FB', char: '📻', name: 'radio' },
      { no: 1993, code: '1F50A', char: '🔊', name: 'speaker high volume' },
      { no: 1997, code: '1F514', char: '🔔', name: 'bell' },
      { no: 1994, code: '1F4E2', char: '📢', name: 'loudspeaker' },
      { no: 1995, code: '1F4E3', char: '📣', name: 'megaphone' },
      { no: 1855, code: '23F3', char: '⏳', name: 'hourglass not done' },
      { no: 1854, code: '231B', char: '⌛', name: 'hourglass done' },
      { no: 1857, code: '23F0', char: '⏰', name: 'alarm clock' },
      { no: 1856, code: '231A', char: '⌚', name: 'watch' },
      { no: 2121, code: '1F513', char: '🔓', name: 'unlocked' },
      { no: 2120, code: '1F512', char: '🔒', name: 'locked' },
      { no: 2122, code: '1F50F', char: '🔏', name: 'locked with pen' },
      { no: 2123, code: '1F510', char: '🔐', name: 'locked with key' },
      { no: 2124, code: '1F511', char: '🔑', name: 'key' },
      { no: 2042, code: '1F50E', char: '🔎', name: 'magnifying glass tilted right' },
      { no: 2047, code: '1F4A1', char: '💡', name: 'light bulb' },
      { no: 2048, code: '1F526', char: '🔦', name: 'flashlight' },
      { no: 2021, code: '1F50C', char: '🔌', name: 'electric plug' },
      { no: 2020, code: '1F50B', char: '🔋', name: 'battery' },
      { no: 2041, code: '1F50D', char: '🔍', name: 'magnifying glass tilted left' },
      { no: 939, code: '1F6C0', char: '🛀', name: 'person taking bath' },
      { no: 1851, code: '1F6BD', char: '🚽', name: 'toilet' },
      { no: 2135, code: '1F527', char: '🔧', name: 'wrench' },
      { no: 2136, code: '1F529', char: '🔩', name: 'nut and bolt' },
      { no: 2126, code: '1F528', char: '🔨', name: 'hammer' },
      { no: 1848, code: '1F6AA', char: '🚪', name: 'door' },
      { no: 2145, code: '1F6AC', char: '🚬', name: 'cigarette' },
      { no: 1465, code: '1F4A3', char: '💣', name: 'bomb' },
      { no: 2132, code: '1F52B', char: '🔫', name: 'pistol' },
      { no: 1721, code: '1F52A', char: '🔪', name: 'kitchen knife' },
      { no: 2144, code: '1F48A', char: '💊', name: 'pill' },
      { no: 2143, code: '1F489', char: '💉', name: 'syringe' },
      { no: 2067, code: '1F4B0', char: '💰', name: 'money bag' },
      { no: 2068, code: '1F4B4', char: '💴', name: 'yen banknote' },
      { no: 2069, code: '1F4B5', char: '💵', name: 'dollar banknote' },
      { no: 2073, code: '1F4B3', char: '💳', name: 'credit card' },
      { no: 2072, code: '1F4B8', char: '💸', name: 'money with wings' },
      { no: 2015, code: '1F4F2', char: '📲', name: 'mobile phone with arrow' },
      { no: 2078, code: '1F4E7', char: '📧', name: 'e-mail' },
      { no: 2082, code: '1F4E5', char: '📥', name: 'inbox tray' },
      { no: 2081, code: '1F4E4', char: '📤', name: 'outbox tray' },
      { no: 2080, code: '1F4E9', char: '📩', name: 'envelope with arrow' },
      { no: 2079, code: '1F4E8', char: '📨', name: 'incoming envelope' },
      {
        no: 2084,
        code: '1F4EB',
        char: '📫',
        name: 'closed mailbox with raised flag',
      },
      {
        no: 2085,
        code: '1F4EA',
        char: '📪',
        name: 'closed mailbox with lowered flag',
      },
      { no: 2088, code: '1F4EE', char: '📮', name: 'postbox' },
      { no: 2083, code: '1F4E6', char: '📦', name: 'package' },
      { no: 2096, code: '1F4DD', char: '📝', name: 'memo' },
      { no: 2061, code: '1F4C4', char: '📄', name: 'page facing up' },
      { no: 2059, code: '1F4C3', char: '📃', name: 'page with curl' },
      { no: 2064, code: '1F4D1', char: '📑', name: 'bookmark tabs' },
      { no: 2108, code: '1F4CA', char: '📊', name: 'bar chart' },
      { no: 2106, code: '1F4C8', char: '📈', name: 'chart increasing' },
      { no: 2107, code: '1F4C9', char: '📉', name: 'chart decreasing' },
      { no: 2060, code: '1F4DC', char: '📜', name: 'scroll' },
      { no: 2109, code: '1F4CB', char: '📋', name: 'clipboard' },
      { no: 2101, code: '1F4C5', char: '📅', name: 'calendar' },
      { no: 2102, code: '1F4C6', char: '📆', name: 'tear-off calendar' },
      { no: 2105, code: '1F4C7', char: '📇', name: 'card index' },
      { no: 2098, code: '1F4C1', char: '📁', name: 'file folder' },
      { no: 2099, code: '1F4C2', char: '📂', name: 'open file folder' },
      { no: 2110, code: '1F4CC', char: '📌', name: 'pushpin' },
      { no: 2112, code: '1F4CE', char: '📎', name: 'paperclip' },
      { no: 2114, code: '1F4CF', char: '📏', name: 'straight ruler' },
      { no: 2115, code: '1F4D0', char: '📐', name: 'triangular ruler' },
      { no: 2051, code: '1F4D5', char: '📕', name: 'closed book' },
      { no: 2053, code: '1F4D7', char: '📗', name: 'green book' },
      { no: 2054, code: '1F4D8', char: '📘', name: 'blue book' },
      { no: 2055, code: '1F4D9', char: '📙', name: 'orange book' },
      { no: 2057, code: '1F4D3', char: '📓', name: 'notebook' },
      {
        no: 2050,
        code: '1F4D4',
        char: '📔',
        name: 'notebook with decorative cover',
      },
      { no: 2058, code: '1F4D2', char: '📒', name: 'ledger' },
      { no: 2056, code: '1F4DA', char: '📚', name: 'books' },
      { no: 2052, code: '1F4D6', char: '📖', name: 'open book' },
      { no: 2065, code: '1F516', char: '🔖', name: 'bookmark' },
      { no: 2254, code: '1F4DB', char: '📛', name: 'name badge' },
      { no: 2062, code: '1F4F0', char: '📰', name: 'newspaper' },
      { no: 1785, code: '1F3A8', char: '🎨', name: 'artist palette' },
      { no: 2035, code: '1F3AC', char: '🎬', name: 'clapper board' },
      { no: 2005, code: '1F3A4', char: '🎤', name: 'microphone' },
      { no: 2006, code: '1F3A7', char: '🎧', name: 'headphone' },
      { no: 1999, code: '1F3BC', char: '🎼', name: 'musical score' },
      { no: 2000, code: '1F3B5', char: '🎵', name: 'musical note' },
      { no: 2001, code: '1F3B6', char: '🎶', name: 'musical notes' },
      { no: 2010, code: '1F3B9', char: '🎹', name: 'musical keyboard' },
      { no: 2012, code: '1F3BB', char: '🎻', name: 'violin' },
      { no: 2011, code: '1F3BA', char: '🎺', name: 'trumpet' },
      { no: 2008, code: '1F3B7', char: '🎷', name: 'saxophone' },
      { no: 2009, code: '1F3B8', char: '🎸', name: 'guitar' },
      { no: 93, code: '1F47E', char: '👾', name: 'alien monster' },
      { no: 1980, code: '1F3AE', char: '🎮', name: 'video game' },
      { no: 1987, code: '1F0CF', char: '🃏', name: 'joker' },
      { no: 1989, code: '1F3B4', char: '🎴', name: 'flower playing cards' },
      { no: 1988, code: '1F004', char: '🀄', name: 'mahjong red dragon' },
      { no: 1982, code: '1F3B2', char: '🎲', name: 'game die' },
      { no: 1972, code: '1F3AF', char: '🎯', name: 'direct hit' },
      { no: 1959, code: '1F3C8', char: '🏈', name: 'american football' },
      { no: 1957, code: '1F3C0', char: '🏀', name: 'basketball' },
      { no: 1955, code: '26BD', char: '⚽', name: 'soccer ball' },
      { no: 1956, code: '26BE', char: '⚾', name: 'baseball' },
      { no: 1961, code: '1F3BE', char: '🎾', name: 'tennis' },
      { no: 1962, code: '1F3B1', char: '🎱', name: 'pool 8 ball' },
      { no: 1963, code: '1F3B3', char: '🎳', name: 'bowling' },
      { no: 1973, code: '26F3', char: '⛳', name: 'flag in hole' },
      { no: 2357, code: '1F3C1', char: '🏁', name: 'chequered flag' },
      { no: 1950, code: '1F3C6', char: '🏆', name: 'trophy' },
      { no: 1977, code: '1F3BF', char: '🎿', name: 'skis' },
      { no: 968, code: '1F3C2', char: '🏂', name: 'snowboarder' },
      { no: 1028, code: '1F3CA', char: '🏊', name: 'person swimming' },
      { no: 992, code: '1F3C4', char: '🏄', name: 'person surfing' },
      { no: 1975, code: '1F3A3', char: '🎣', name: 'fishing pole' },
      { no: 1706, code: '1F375', char: '🍵', name: 'teacup without handle' },
      { no: 1707, code: '1F376', char: '🍶', name: 'sake' },
      { no: 1712, code: '1F37A', char: '🍺', name: 'beer mug' },
      { no: 1713, code: '1F37B', char: '🍻', name: 'clinking beer mugs' },
      { no: 1710, code: '1F378', char: '🍸', name: 'cocktail glass' },
      { no: 1711, code: '1F379', char: '🍹', name: 'tropical drink' },
      { no: 1709, code: '1F377', char: '🍷', name: 'wine glass' },
      { no: 1719, code: '1F374', char: '🍴', name: 'fork and knife' },
      { no: 1660, code: '1F355', char: '🍕', name: 'pizza' },
      { no: 1658, code: '1F354', char: '🍔', name: 'hamburger' },
      { no: 1659, code: '1F35F', char: '🍟', name: 'french fries' },
      { no: 1655, code: '1F357', char: '🍗', name: 'poultry leg' },
      { no: 1654, code: '1F356', char: '🍖', name: 'meat on bone' },
      { no: 1680, code: '1F35D', char: '🍝', name: 'spaghetti' },
      { no: 1678, code: '1F35B', char: '🍛', name: 'curry rice' },
      { no: 1684, code: '1F364', char: '🍤', name: 'fried shrimp' },
      { no: 1674, code: '1F371', char: '🍱', name: 'bento box' },
      { no: 1683, code: '1F363', char: '🍣', name: 'sushi' },
      { no: 1685, code: '1F365', char: '🍥', name: 'fish cake with swirl' },
      { no: 1676, code: '1F359', char: '🍙', name: 'rice ball' },
      { no: 1675, code: '1F358', char: '🍘', name: 'rice cracker' },
      { no: 1677, code: '1F35A', char: '🍚', name: 'cooked rice' },
      { no: 1679, code: '1F35C', char: '🍜', name: 'steaming bowl' },
      { no: 1669, code: '1F372', char: '🍲', name: 'pot of food' },
      { no: 1682, code: '1F362', char: '🍢', name: 'oden' },
      { no: 1686, code: '1F361', char: '🍡', name: 'dango' },
      { no: 1667, code: '1F373', char: '🍳', name: 'cooking' },
      { no: 1648, code: '1F35E', char: '🍞', name: 'bread' },
      { no: 1693, code: '1F369', char: '🍩', name: 'doughnut' },
      { no: 1701, code: '1F36E', char: '🍮', name: 'custard' },
      { no: 1690, code: '1F366', char: '🍦', name: 'soft ice cream' },
      { no: 1692, code: '1F368', char: '🍨', name: 'ice cream' },
      { no: 1691, code: '1F367', char: '🍧', name: 'shaved ice' },
      { no: 1695, code: '1F382', char: '🎂', name: 'birthday cake' },
      { no: 1696, code: '1F370', char: '🍰', name: 'shortcake' },
      { no: 1694, code: '1F36A', char: '🍪', name: 'cookie' },
      { no: 1698, code: '1F36B', char: '🍫', name: 'chocolate bar' },
      { no: 1699, code: '1F36C', char: '🍬', name: 'candy' },
      { no: 1700, code: '1F36D', char: '🍭', name: 'lollipop' },
      { no: 1702, code: '1F36F', char: '🍯', name: 'honey pot' },
      { no: 1628, code: '1F34E', char: '🍎', name: 'red apple' },
      { no: 1629, code: '1F34F', char: '🍏', name: 'green apple' },
      { no: 1624, code: '1F34A', char: '🍊', name: 'tangerine' },
      { no: 1632, code: '1F352', char: '🍒', name: 'cherries' },
      { no: 1621, code: '1F347', char: '🍇', name: 'grapes' },
      { no: 1623, code: '1F349', char: '🍉', name: 'watermelon' },
      { no: 1633, code: '1F353', char: '🍓', name: 'strawberry' },
      { no: 1631, code: '1F351', char: '🍑', name: 'peach' },
      { no: 1622, code: '1F348', char: '🍈', name: 'melon' },
      { no: 1626, code: '1F34C', char: '🍌', name: 'banana' },
      { no: 1627, code: '1F34D', char: '🍍', name: 'pineapple' },
      { no: 1681, code: '1F360', char: '🍠', name: 'roasted sweet potato' },
      { no: 1638, code: '1F346', char: '🍆', name: 'eggplant' },
      { no: 1635, code: '1F345', char: '🍅', name: 'tomato' },
      { no: 1641, code: '1F33D', char: '🌽', name: 'ear of corn' },
    ],
  },
  {
    name: 'Places',
    emojis: [
      { no: 1744, code: '1F3E0', char: '🏠', name: 'house' },
      { no: 1745, code: '1F3E1', char: '🏡', name: 'house with garden' },
      { no: 1754, code: '1F3EB', char: '🏫', name: 'school' },
      { no: 1746, code: '1F3E2', char: '🏢', name: 'office building' },
      { no: 1747, code: '1F3E3', char: '🏣', name: 'Japanese post office' },
      { no: 1749, code: '1F3E5', char: '🏥', name: 'hospital' },
      { no: 1750, code: '1F3E6', char: '🏦', name: 'bank' },
      { no: 1753, code: '1F3EA', char: '🏪', name: 'convenience store' },
      { no: 1752, code: '1F3E9', char: '🏩', name: 'love hotel' },
      { no: 1751, code: '1F3E8', char: '🏨', name: 'hotel' },
      { no: 1759, code: '1F492', char: '💒', name: 'wedding' },
      { no: 1762, code: '26EA', char: '⛪', name: 'church' },
      { no: 1755, code: '1F3EC', char: '🏬', name: 'department store' },
      { no: 1774, code: '1F307', char: '🌇', name: 'sunset' },
      { no: 1773, code: '1F306', char: '🌆', name: 'cityscape at dusk' },
      { no: 1757, code: '1F3EF', char: '🏯', name: 'Japanese castle' },
      { no: 1758, code: '1F3F0', char: '🏰', name: 'castle' },
      { no: 1768, code: '26FA', char: '⛺', name: 'tent' },
      { no: 1756, code: '1F3ED', char: '🏭', name: 'factory' },
      { no: 1760, code: '1F5FC', char: '🗼', name: 'Tokyo tower' },
      { no: 1728, code: '1F5FE', char: '🗾', name: 'map of Japan' },
      { no: 1732, code: '1F5FB', char: '🗻', name: 'mount fuji' },
      { no: 1771, code: '1F304', char: '🌄', name: 'sunrise over mountains' },
      { no: 1772, code: '1F305', char: '🌅', name: 'sunrise' },
      { no: 1770, code: '1F303', char: '🌃', name: 'night with stars' },
      { no: 1761, code: '1F5FD', char: '🗽', name: 'Statue of Liberty' },
      { no: 1775, code: '1F309', char: '🌉', name: 'bridge at night' },
      { no: 1778, code: '1F3A0', char: '🎠', name: 'carousel horse' },
      { no: 1779, code: '1F3A1', char: '🎡', name: 'ferris wheel' },
      { no: 1767, code: '26F2', char: '⛲', name: 'fountain' },
      { no: 1780, code: '1F3A2', char: '🎢', name: 'roller coaster' },
      { no: 1834, code: '1F6A2', char: '🚢', name: 'ship' },
      { no: 1828, code: '26F5', char: '⛵', name: 'sailboat' },
      { no: 1830, code: '1F6A4', char: '🚤', name: 'speedboat' },
      { no: 1845, code: '1F680', char: '🚀', name: 'rocket' },
      { no: 1839, code: '1F4BA', char: '💺', name: 'seat' },
      { no: 1794, code: '1F689', char: '🚉', name: 'station' },
      { no: 1789, code: '1F684', char: '🚄', name: 'high-speed train' },
      { no: 1790, code: '1F685', char: '🚅', name: 'bullet train' },
      { no: 1792, code: '1F687', char: '🚇', name: 'metro' },
      { no: 1788, code: '1F683', char: '🚃', name: 'railway car' },
      { no: 1799, code: '1F68C', char: '🚌', name: 'bus' },
      { no: 1811, code: '1F699', char: '🚙', name: 'sport utility vehicle' },
      { no: 1809, code: '1F697', char: '🚗', name: 'automobile' },
      { no: 1807, code: '1F695', char: '🚕', name: 'taxi' },
      { no: 1812, code: '1F69A', char: '🚚', name: 'delivery truck' },
      { no: 1822, code: '1F6A8', char: '🚨', name: 'police car light' },
      { no: 1805, code: '1F693', char: '🚓', name: 'police car' },
      { no: 1804, code: '1F692', char: '🚒', name: 'fire engine' },
      { no: 1803, code: '1F691', char: '🚑', name: 'ambulance' },
      { no: 1815, code: '1F6B2', char: '🚲', name: 'bicycle' },
      { no: 1781, code: '1F488', char: '💈', name: 'barber pole' },
      { no: 1818, code: '1F68F', char: '🚏', name: 'bus stop' },
      { no: 1948, code: '1F3AB', char: '🎫', name: 'ticket' },
      { no: 1823, code: '1F6A5', char: '🚥', name: 'horizontal traffic light' },
      { no: 1825, code: '1F6A7', char: '🚧', name: 'construction' },
      { no: 2255, code: '1F530', char: '🔰', name: 'Japanese symbol for beginner' },
      { no: 1821, code: '26FD', char: '⛽', name: 'fuel pump' },
      { no: 2049, code: '1F3EE', char: '🏮', name: 'red paper lantern' },
      { no: 1786, code: '1F3B0', char: '🎰', name: 'slot machine' },
      { no: 2148, code: '1F5FF', char: '🗿', name: 'moai' },
      { no: 1782, code: '1F3AA', char: '🎪', name: 'circus tent' },
      { no: 1783, code: '1F3AD', char: '🎭', name: 'performing arts' },
      { no: 2111, code: '1F4CD', char: '📍', name: 'round pushpin' },
      { no: 2358, code: '1F6A9', char: '🚩', name: 'triangular flag' },
    ],
  },
  {
    name: 'Symbols',
    emojis: [
      { no: 2294, code: '1F51F', char: '🔟', name: 'keycap 10' },
      { no: 2298, code: '1F522', char: '🔢', name: 'input numbers' },
      { no: 2299, code: '1F523', char: '🔣', name: 'input symbols' },
      { no: 2296, code: '1F520', char: '🔠', name: 'input latin uppercase' },
      { no: 2297, code: '1F521', char: '🔡', name: 'input latin lowercase' },
      { no: 2300, code: '1F524', char: '🔤', name: 'input latin letters' },
      { no: 2234, code: '1F53C', char: '🔼', name: 'up button' },
      { no: 2236, code: '1F53D', char: '🔽', name: 'down button' },
      { no: 2232, code: '23EA', char: '⏪', name: 'fast reverse button' },
      { no: 2228, code: '23E9', char: '⏩', name: 'fast-forward button' },
      { no: 2235, code: '23EB', char: '⏫', name: 'fast up button' },
      { no: 2237, code: '23EC', char: '⏬', name: 'fast down button' },
      { no: 2313, code: '1F197', char: '🆗', name: 'OK button' },
      { no: 2310, code: '1F195', char: '🆕', name: 'NEW button' },
      { no: 2316, code: '1F199', char: '🆙', name: 'UP! button' },
      { no: 2305, code: '1F192', char: '🆒', name: 'COOL button' },
      { no: 2306, code: '1F193', char: '🆓', name: 'FREE button' },
      { no: 2311, code: '1F196', char: '🆖', name: 'NG button' },
      { no: 2245, code: '1F4F6', char: '📶', name: 'antenna bars' },
      { no: 2242, code: '1F3A6', char: '🎦', name: 'cinema' },
      { no: 2318, code: '1F201', char: '🈁', name: 'Japanese “here” button' },
      { no: 2322, code: '1F22F', char: '🈯', name: 'Japanese “reserved” button' },
      { no: 2330, code: '1F233', char: '🈳', name: 'Japanese “vacancy” button' },
      { no: 2334, code: '1F235', char: '🈵', name: 'Japanese “no vacancy” button' },
      {
        no: 2329,
        code: '1F234',
        char: '🈴',
        name: 'Japanese “passing grade” button',
      },
      { no: 2326, code: '1F232', char: '🈲', name: 'Japanese “prohibited” button' },
      { no: 2323, code: '1F250', char: '🉐', name: 'Japanese “bargain” button' },
      { no: 2324, code: '1F239', char: '🈹', name: 'Japanese “discount” button' },
      {
        no: 2333,
        code: '1F23A',
        char: '🈺',
        name: 'Japanese “open for business” button',
      },
      {
        no: 2321,
        code: '1F236',
        char: '🈶',
        name: 'Japanese “not free of charge” button',
      },
      {
        no: 2325,
        code: '1F21A',
        char: '🈚',
        name: 'Japanese “free of charge” button',
      },
      { no: 2158, code: '1F6BB', char: '🚻', name: 'restroom' },
      { no: 2156, code: '1F6B9', char: '🚹', name: 'men’s room' },
      { no: 2157, code: '1F6BA', char: '🚺', name: 'women’s room' },
      { no: 2159, code: '1F6BC', char: '🚼', name: 'baby symbol' },
      { no: 2160, code: '1F6BE', char: '🚾', name: 'water closet' },
      { no: 2170, code: '1F6AD', char: '🚭', name: 'no smoking' },
      { no: 2328, code: '1F238', char: '🈸', name: 'Japanese “application” button' },
      { no: 2327, code: '1F251', char: '🉑', name: 'Japanese “acceptable” button' },
      { no: 2304, code: '1F191', char: '🆑', name: 'CL button' },
      { no: 2315, code: '1F198', char: '🆘', name: 'SOS button' },
      { no: 2308, code: '1F194', char: '🆔', name: 'ID button' },
      { no: 2168, code: '1F6AB', char: '🚫', name: 'prohibited' },
      { no: 2175, code: '1F51E', char: '🔞', name: 'no one under eighteen' },
      { no: 2167, code: '26D4', char: '⛔', name: 'no entry' },
      { no: 2262, code: '274E', char: '❎', name: 'cross mark button' },
      { no: 2257, code: '2705', char: '✅', name: 'white heavy check mark' },
      { no: 1460, code: '1F49F', char: '💟', name: 'heart decoration' },
      { no: 2317, code: '1F19A', char: '🆚', name: 'VS button' },
      { no: 2246, code: '1F4F3', char: '📳', name: 'vibration mode' },
      { no: 2247, code: '1F4F4', char: '📴', name: 'mobile phone off' },
      { no: 2302, code: '1F18E', char: '🆎', name: 'AB button (blood type)' },
      { no: 2349, code: '1F4A0', char: '💠', name: 'diamond with a dot' },
      { no: 2223, code: '26CE', char: '⛎', name: 'Ophiuchus' },
      { no: 2210, code: '1F52F', char: '🔯', name: 'dotted six-pointed star' },
      { no: 2152, code: '1F3E7', char: '🏧', name: 'ATM sign' },
      { no: 2074, code: '1F4B9', char: '💹', name: 'chart increasing with yen' },
      { no: 2076, code: '1F4B2', char: '💲', name: 'heavy dollar sign' },
      { no: 2075, code: '1F4B1', char: '💱', name: 'currency exchange' },
      { no: 2261, code: '274C', char: '❌', name: 'cross mark' },
      { no: 2277, code: '2757', char: '❗', name: 'exclamation mark' },
      { no: 2274, code: '2753', char: '❓', name: 'question mark' },
      { no: 2276, code: '2755', char: '❕', name: 'white exclamation mark' },
      { no: 2275, code: '2754', char: '❔', name: 'white question mark' },
      { no: 2256, code: '2B55', char: '⭕', name: 'heavy large circle' },
      { no: 2198, code: '1F51D', char: '🔝', name: 'TOP arrow' },
      { no: 2195, code: '1F51A', char: '🔚', name: 'END arrow' },
      { no: 2194, code: '1F519', char: '🔙', name: 'BACK arrow' },
      { no: 2196, code: '1F51B', char: '🔛', name: 'ON! arrow' },
      { no: 2197, code: '1F51C', char: '🔜', name: 'SOON arrow' },
      { no: 2192, code: '1F503', char: '🔃', name: 'clockwise vertical arrows' },
      { no: 1861, code: '1F55B', char: '🕛', name: 'twelve o’clock' },
      { no: 1863, code: '1F550', char: '🕐', name: 'one o’clock' },
      { no: 1865, code: '1F551', char: '🕑', name: 'two o’clock' },
      { no: 1867, code: '1F552', char: '🕒', name: 'three o’clock' },
      { no: 1869, code: '1F553', char: '🕓', name: 'four o’clock' },
      { no: 1871, code: '1F554', char: '🕔', name: 'five o’clock' },
      { no: 1873, code: '1F555', char: '🕕', name: 'six o’clock' },
      { no: 1875, code: '1F556', char: '🕖', name: 'seven o’clock' },
      { no: 1877, code: '1F557', char: '🕗', name: 'eight o’clock' },
      { no: 1879, code: '1F558', char: '🕘', name: 'nine o’clock' },
      { no: 1881, code: '1F559', char: '🕙', name: 'ten o’clock' },
      { no: 1883, code: '1F55A', char: '🕚', name: 'eleven o’clock' },
      { no: 2263, code: '2795', char: '➕', name: 'heavy plus sign' },
      { no: 2264, code: '2796', char: '➖', name: 'heavy minus sign' },
      { no: 2265, code: '2797', char: '➗', name: 'heavy division sign' },
      { no: 1601, code: '1F4AE', char: '💮', name: 'white flower' },
      { no: 2295, code: '1F4AF', char: '💯', name: 'hundred points' },
      { no: 2350, code: '1F518', char: '🔘', name: 'radio button' },
      { no: 2141, code: '1F517', char: '🔗', name: 'link' },
      { no: 2266, code: '27B0', char: '➰', name: 'curly loop' },
      { no: 2253, code: '1F531', char: '🔱', name: 'trident emblem' },
      { no: 2347, code: '1F53A', char: '🔺', name: 'red triangle pointed up' },
      { no: 2351, code: '1F532', char: '🔲', name: 'black square button' },
      { no: 2352, code: '1F533', char: '🔳', name: 'white square button' },
      { no: 2355, code: '1F534', char: '🔴', name: 'red circle' },
      { no: 2356, code: '1F535', char: '🔵', name: 'blue circle' },
      { no: 2348, code: '1F53B', char: '🔻', name: 'red triangle pointed down' },
      { no: 2342, code: '2B1C', char: '⬜', name: 'white large square' },
      { no: 2341, code: '2B1B', char: '⬛', name: 'black large square' },
      { no: 2343, code: '1F536', char: '🔶', name: 'large orange diamond' },
      { no: 2344, code: '1F537', char: '🔷', name: 'large blue diamond' },
      { no: 2345, code: '1F538', char: '🔸', name: 'small orange diamond' },
      { no: 2346, code: '1F539', char: '🔹', name: 'small blue diamond' },
    ],
  },
];
