import React, { useState } from 'react';
import Modal from '~/Modal';
import { InputNumber, message } from 'antd';
import LabeledWithScottTitle from '~/ScottTitle/LabeledWithScottTitle';
import _ from 'lodash';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import * as GraphQL from '~/graphql';

const AddNewCreditCardDialog = (props: { isOpen: boolean; close(): void }) => {
  const [last4, setLast4] = useState(undefined);
  const [expMonth, setExpMonth] = useState(undefined);
  const [expYear, setExpYear] = useState(undefined);

  const [addCreditCard, { loading }] = useMutation<
    GraphQL.AddNewCreditCardDialog.Mutation,
    GraphQL.AddNewCreditCardDialog.Variables
  >(AddNewCreditCardDialog.mutation, {
    refetchQueries: ['CreditCardOnFileSelector'],
  });

  return (
    <Modal
      width={440}
      style={{
        position: 'absolute',
        bottom: 0,
        top: 260,
        left: 0,
        right: 0,
        margin: 'auto',
      }}
      visible={props.isOpen}
      close={props.close}
      title='Add New Card'
      okButtonProps={{ disabled: !last4 || !expMonth || !expYear, loading }}
      onOk={() =>
        addCreditCard({
          variables: { info: { last4: formatLast4(last4), expMonth, expYear } },
        })
          .then(() => {
            message.success(`Added card ●●●● ●●●● ●●●● ${formatLast4(last4)}`);
            props.close();
          })
          .catch(error => {
            message.error(JSON.stringify(error));
            console.error(error);
          })
      }
      onCancel={props.close}
    >
      <div css={{ display: 'flex' }}>
        <LabeledWithScottTitle
          label='Last 4 of Card Number'
          required={!last4 || last4.toString().length !== 4}
        >
          <InputNumber
            placeholder='4321'
            min={1000}
            max={9999}
            value={last4}
            onChange={value => setLast4(value)}
          />
        </LabeledWithScottTitle>
        <LabeledWithScottTitle label='Exp. Month' required={!expMonth}>
          <InputNumber
            placeholder='12'
            min={1}
            max={12}
            value={expMonth}
            onChange={value => setExpMonth(value)}
          />
        </LabeledWithScottTitle>
        <LabeledWithScottTitle label='Exp. Year' required={!expYear}>
          <InputNumber
            placeholder='2025'
            min={2020}
            max={2040}
            value={expYear}
            onChange={value => setExpYear(value)}
          />
        </LabeledWithScottTitle>
      </div>
    </Modal>
  );
};

export default AddNewCreditCardDialog;

const formatLast4 = (last4: number): string => _.padStart(last4.toString(), 4, '0');

AddNewCreditCardDialog.mutation = gql`
  mutation AddNewCreditCardDialog($info: CreditCardInput!) {
    addCreditCard(info: $info) {
      id
    }
  }
`;
