import React from 'react';
import {
  FiBriefcase,
  FiDollarSign,
  FiHelpCircle,
  FiHome,
  FiMessageCircle,
  FiUsers,
  FiDownloadCloud,
  FiShoppingBag,
  FiPackage,
  FiVideo,
} from 'react-icons/fi';
import { FaCalendarTimes, FaBoxes, FaBuilding } from 'react-icons/fa';
import { Route, Switch } from 'react-router';

import Home from '~/Home';
import Messages from '~/Messages';
import { memo } from '~/react';
import { config } from '~/config';
import Users from '~/Users';
import BlackoutDates from '~/BlackoutDates';
import Supply from '~/Supply';

import StagingGrid from '~/Staging/Grid';
import TeamEvent from '~/TeamEvent';
import Questionnaire from '~/Questionnaire';
import Organization from '~/Organization';

export const navPaths = [
  { path: '/', label: 'Metrics', iconComponent: FiHome },
  {
    path: '/blackout-dates',
    label: 'Blackout Dates',
    iconComponent: FaCalendarTimes,
  },
  // { path: '/staging', label: 'Staging', iconComponent: FiLayers },
  { path: '/supply', label: 'Supply', iconComponent: FiShoppingBag },
  { path: '/sms', label: 'Messages', iconComponent: FiMessageCircle },
  { path: '/users', label: 'Users', iconComponent: FiUsers },
  {
    path:
      '/team-event?teamEventStatus=Requested&teamEventStatus=Scheduled&teamEventStatus=InProgress',
    label: 'Team Event',
    iconComponent: FiVideo,
  },
  { path: '/organizations', label: 'Organizations', iconComponent: FaBuilding },
];

const AppRoutes = () => (
  <Switch>
    <Route
      path='/logout'
      render={() => window.location.replace(`${config.apiUrl}/logout`) as any}
    />
    <Route path='/' exact component={Home} />
    <Route path='/blackout-dates' component={BlackoutDates} />
    <Route path='/organizations' component={Organization} />
    <Route path='/staging' component={StagingGrid} />
    <Route path='/supply' component={Supply} />
    <Route path='/sms' component={Messages} />
    <Route path='/users' component={Users} />
    <Route path='/team-event' component={TeamEvent} />
    <Route path='/questionnaire/:id' component={Questionnaire} />
  </Switch>
);

export default memo(AppRoutes);
