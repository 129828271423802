import { Icon, Input, Radio } from 'antd';
import RadioGroup from 'antd/lib/radio/group';
import { uniq } from 'lodash';
import { useState } from 'react';
import MoneyInput from '~/Input/Money';
import { ContractCharge } from '~/TeamEvent/EventCosts';
import { formatCents, formatCredits } from '~/formatters';
import { ContractUnitType, Price, PriceLineItem, PriceStatus, PriceType } from '~/graphql';
import { borders, colors, font, sizes } from '~/styles';
import ChargeStatus from './ChargeStatus';

const IDENTITY_FUNCTION = () => {};

const gridStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gap: `${sizes.Spacing.XSmall}px`,
};

const unitTypeLookup: Record<ContractUnitType, string> = {
  Cents: 'cents',
  Credits: 'credits',
  Event: 'seats',
  Recurring: 'seats',
  Unlimited: '',
};

type ExistingContractChargeRowProps = {
  price: Pick<Price, 'id' | 'priceType' | 'systemGenerated' | 'status'>;
  lineItems: (Pick<PriceLineItem, 'contractUnits' | 'contractPremiumUnits'> & {
    contract: {
      type: ContractUnitType;
    };
  })[];
  className?: string;
  pendingDelete?: boolean;
  onRemove?: (id: string) => void;
  onReAdd?: (id: string) => void;
};
export function ExistingContractChargeRow({
  price,
  lineItems,
  className,
  pendingDelete = false,
  onRemove = IDENTITY_FUNCTION,
  onReAdd = IDENTITY_FUNCTION,
}: ExistingContractChargeRowProps) {
  const { id, priceType, systemGenerated, status } = price;
  const totalContractUnits = lineItems.reduce(
    (sum, lineItem) => sum + lineItem.contractUnits || 0,
    0,
  );
  const totalContractPremiumUnits = lineItems.reduce(
    (sum, lineItems) => sum + lineItems.contractPremiumUnits || 0,
    0,
  );
  const unitTypes = uniq(
    lineItems
      .filter(lineItem => lineItem.contract)
      .map(lineItem => lineItem.contract)
      .map(contract => contract.type),
  );
  let unitType = unitTypes.length > 1 ? 'Mixed' : unitTypeLookup[unitTypes[0]];
  unitType = unitType === 'seats' && totalContractPremiumUnits ? 'Premium Seats' : unitType;
  const adjustedStatus = status === PriceStatus.Abandoned ? PriceStatus.Paid : status;
  const iconStyle = pendingDelete
    ? { color: colors.Red.Red500, ':hover': { color: colors.Blue.Blue500 } }
    : { color: colors.Blue.Blue500, ':hover': { color: colors.Red.Red500 } };
  const handleToggle = () => {
    if (pendingDelete) {
      return onReAdd(id ? id : undefined);
    } else {
      return onRemove(id ? id : undefined);
    }
  };
  return (
    <div
      css={{
        ...gridStyle,
        width: '100%',
        alignItems: 'center',
        borderBottom: borders.darkDivider,
        padding: `${sizes.Spacing.Small}px`,
        transition: 'all 0.5s ease-in-out',
        backgroundColor: pendingDelete ? colors.Red.Red500_Faded : colors.GreyLighter,
      }}
      className={className}
    >
      <div css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {systemGenerated && <Icon type='lock' css={{ color: colors.GreyLight }} />}
        {!systemGenerated && !pendingDelete && (
          <Icon type='minus-circle' css={{ ...iconStyle }} onClick={handleToggle} />
        )}
        {!systemGenerated && pendingDelete && (
          <Icon type='plus-circle' css={{ ...iconStyle }} onClick={handleToggle} />
        )}
        <div>
          {unitType === 'credits' && <span>{formatCredits(totalContractUnits)}</span>}
          {unitType === 'seats' && <span>{totalContractPremiumUnits || totalContractUnits}</span>}
          {unitType === 'cents' && <span>{formatCents(totalContractUnits)}</span>}
        </div>
      </div>
      <div css={{ textAlign: 'center', textTransform: 'capitalize' }}>{unitType}</div>
      <div css={{ textAlign: 'center' }}>{priceType}</div>
      <ChargeStatus status={adjustedStatus} css={{ textAlign: 'center', justifySelf: 'end' }} />
    </div>
  );
}

type NewContractChargeRowProps = {
  className?: string;
  contractUnitType: ContractUnitType;
  charge: ContractCharge;
  onChange: (unitCount: number, premiumUnitCount: number) => void;
  onRemove: (id?: string) => void;
};
export function NewContractChargeRow({
  className,
  contractUnitType,
  charge,
  onRemove,
  onChange,
}: NewContractChargeRowProps) {
  const [isPremiumUnits, setPremiumUnits] = useState<boolean>(false);
  const { unitCount, premiumUnitCount } = charge;
  const unitTypeString = unitTypeLookup[contractUnitType];
  const handleRemove = () => onRemove(undefined);
  const handleGenericChange = e => {
    if (isSeats && isPremiumUnits) {
      return onChange(0, Math.floor(e.target.value));
    }
    if (isSeats) {
      return onChange(Math.floor(e.target.value), 0);
    }
    return onChange(Math.floor(e.target.value * 100), 0);
  };
  const handleCentsChange = (value: number) => {
    onChange(value * 100, 0);
  };
  const handlePremiumToggle = e => {
    setPremiumUnits(e.target.value);
  };
  const isSeats = contractUnitType === ContractUnitType.Recurring;
  const isCents = contractUnitType === ContractUnitType.Cents;
  const currentValue =
    isSeats && isPremiumUnits ? premiumUnitCount : isSeats ? unitCount : unitCount / 100.0;
  return (
    <div
      css={{
        ...gridStyle,
        width: '100%',
        alignItems: 'center',
        borderBottom: borders.darkDivider,
        padding: `${sizes.Spacing.Small}px`,
        transition: 'all 0.5s ease-in-out',
        backgroundColor: colors.GreyLighter,
      }}
      className={className}
    >
      <div css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Icon
          type='minus-circle'
          onClick={handleRemove}
          css={{
            color: colors.Blue.Blue500,
            ':hover': { color: colors.Red.Red500 },
          }}
        />
        <div>
          <span>
            {!isCents && (
              <Input type='number' value={currentValue} onChange={handleGenericChange} />
            )}
            {isCents && <MoneyInput value={currentValue} onChange={handleCentsChange} />}
          </span>
        </div>
      </div>
      {!isSeats && (
        <div css={{ textAlign: 'center', textTransform: 'capitalize' }}>{unitTypeString}</div>
      )}
      {isSeats && (
        <div css={{ textAlign: 'start', textTransform: 'capitalize', marginLeft: '20px' }}>
          <RadioGroup value={isPremiumUnits} onChange={handlePremiumToggle}>
            <Radio value={false}>Seats</Radio>
            <Radio value={true}>Premium Seats</Radio>
          </RadioGroup>
        </div>
      )}
      <div css={{ textAlign: 'center' }}>{PriceType.ExtraCharge}</div>
      <ChargeStatus css={{ textAlign: 'center', justifySelf: 'end' }} />
    </div>
  );
}

export function ContractChargeHeaderRow({ className }: { className?: string }) {
  return (
    <div
      css={{
        ...gridStyle,
        color: colors.White,
        padding: `${sizes.Spacing.XSmall}px ${sizes.Spacing.Small}px`,
        ...font.Size.Body,
        fontWeight: font.FontWeight.Bold,
        backgroundColor: colors.Blue500,
        width: '100%',
        justifyContent: 'space-between',
      }}
      className={className}
    >
      <span>Units</span>
      <span css={{ textAlign: 'center' }}>Unit Type</span>
      <span css={{ textAlign: 'center' }}>Price Type</span>
      <span css={{ textAlign: 'end' }}>Status</span>
    </div>
  );
}
