import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

export type Breakpoint = 'phone' | 'tablet' | 'desktop';
export type BreakpointOption = { breakpoint: Breakpoint; width: number };

const breakpoints: BreakpointOption[] = [
  { breakpoint: 'phone', width: 0 },
  { breakpoint: 'tablet', width: 600 },
  { breakpoint: 'desktop', width: 900 },
];

const getCurrentBreakpoint = () => {
  if (!('window' in global)) return 'desktop';
  return _(breakpoints)
    .filter(({ width }) => window.innerWidth >= width)
    .maxBy('width')!.breakpoint;
};

const ResponsiveContext = React.createContext(getCurrentBreakpoint());

export const BreakpointProvider = ({ children }: { children: React.ReactNode }) => {
  const [breakpoint, setBreakpoint] = useState(getCurrentBreakpoint());

  useEffect(() => {
    const useUpdateBreakpoint = () => setBreakpoint(getCurrentBreakpoint());
    window.addEventListener('resize', useUpdateBreakpoint);
    return () => window.removeEventListener('resize', useUpdateBreakpoint);
  }, []);

  return (
    <ResponsiveContext.Provider value={breakpoint}>
      {children}
    </ResponsiveContext.Provider>
  );
};

const useBreakpoint = () => {
  const breakpoint = useContext(ResponsiveContext);
  return {
    breakpoint,
    isPhone: breakpoint === 'phone',
    isTablet: breakpoint === 'tablet',
    isDesktop: breakpoint === 'desktop',
  };
};

export default useBreakpoint;
