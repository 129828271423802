import _ from 'lodash';
import React, { useMemo } from 'react';

import { DataTableProps, GraphQLConnectionVariables } from './interface';
import * as filtersModule from './filters';
import { FiltersFor, SortKeysFor } from './utilTypes';
import { useDataTableQuery } from './querying';
import DataTableFooter from './Footer';
import DataTableBody from './Body';
import DataTableHeader from './Header';
import {
  CELL_VERTICAL_PADDING,
  CELL_HORIZONTAL_PADDING,
  CELL_LINE_HEIGHT,
  CELL_BORDER_SIZE,
} from './Row';

import { sizes } from '~/styles';

export const filters = filtersModule;

const DEFAULT_ROW_HEIGHT = CELL_VERTICAL_PADDING * 2 + CELL_LINE_HEIGHT + CELL_BORDER_SIZE;
const DEFAULT_PAGE_SIZE = 50;

/**
 * Renders a table for a given GraphQL query.
 *
 * Note that the query must conform to the GraphQL connection standard.
 *
 * Takes care of the following for you:
 *
 *   * Automatic pagination while scrolling (virtual scrolling).
 *   * Error handling.
 *   * Optional sorting and filtering per column, with the UX visibily attached
 *     to each column.
 *   * Support for custom filter UX.
 *
 */
function DataTable<TRow, TVariables extends GraphQLConnectionVariables>({
  query,
  queryPath,
  columns,
  pageSize,
  rowHeight,
  rowsOfOverscan,
  defaultSort = {},
  defaultFilters = {},
  ...passthroughProps
}: DataTableProps<TRow, SortKeysFor<TVariables>, FiltersFor<TVariables>>) {
  pageSize = pageSize || DEFAULT_PAGE_SIZE;
  rowHeight = rowHeight || DEFAULT_ROW_HEIGHT;
  rowsOfOverscan = rowsOfOverscan || pageSize / 2;

  const scrollRef = React.useRef<HTMLDivElement>();
  const tableCss = useMemo(() => STYLES.table(rowHeight), [rowHeight]);

  const sortPrecedence = useMemo(() => {
    return columns
      .map(({ sortKey }) => sortKey)
      .filter(k => !!k)
      .reverse();
  }, [columns]);
  const result = useDataTableQuery<TRow, SortKeysFor<TVariables>, FiltersFor<TVariables>>(
    query,
    queryPath,
    pageSize,
    sortPrecedence,
    defaultSort,
    defaultFilters,
  );

  return (
    <div {...passthroughProps} css={STYLES.root} aria-rowcount={result.count}>
      <div css={STYLES.scroll} ref={scrollRef}>
        <table css={tableCss}>
          <DataTableHeader columns={columns} result={result} scrollRef={scrollRef} />
          <DataTableBody
            columns={columns}
            result={result}
            scrollRef={scrollRef}
            rowHeight={rowHeight}
            rowsOfOverscan={rowsOfOverscan}
          />
        </table>
      </div>
      <DataTableFooter result={result} />
    </div>
  );
}

const STYLES = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  scroll: {
    overflow: 'auto',
    flex: 1,
  },
  table: (height: number) =>
    ({
      borderSpacing: 0,
      '& tr': {
        height,
      },
      '& th, & td': {
        padding: `${CELL_VERTICAL_PADDING}px ${CELL_HORIZONTAL_PADDING}px`,
        lineHeight: `${CELL_LINE_HEIGHT}px`,
        '&:first-child, &:last-child': {
          paddingLeft: sizes.Spacing.XLarge,
        },
      },
      '& td': {
        overflow: 'hidden',
      },
    } as const),
} as const;

export default DataTable;
