export type Maybe<T> = T | null;

export interface MysteriesOrderArgs {
  key: MysteriesOrderKey;

  direction: OrderDirection;
}

export interface TeamEventOrderArgs {
  key: TeamEventOrderKey;

  direction: OrderDirection;
}

export interface DeliveryOrderOrderArgs {
  key: DeliveryOrderOrderKey;

  direction: OrderDirection;
}

export interface MessagesOrderArgs {
  key: MessagesOrderKey;

  direction: OrderDirection;
}

export interface LocationInput {
  id?: Maybe<string>;

  placeId?: Maybe<string>;

  name?: Maybe<string>;

  address?: Maybe<AddressInput>;

  coordinates?: Maybe<CoordinatesInput>;

  url?: Maybe<string>;

  phone?: Maybe<string>;

  priceLevel?: Maybe<number>;

  rating?: Maybe<number>;
}

export interface AddressInput {
  full?: Maybe<string>;

  streetNumber?: Maybe<string>;

  route?: Maybe<string>;

  subPremise?: Maybe<string>;

  city?: Maybe<string>;

  state?: Maybe<string>;

  postalCode?: Maybe<string>;

  county?: Maybe<string>;

  country?: Maybe<string>;

  neighborhood?: Maybe<string>;
}

export interface CoordinatesInput {
  latitude?: Maybe<number>;

  longitude?: Maybe<number>;
}

export interface EventOrderArgs {
  key: EventOrderKey;

  direction: OrderDirection;
}

export interface EventFilterInput {
  search?: Maybe<string>;

  startDate?: Maybe<DateTime>;

  endDate?: Maybe<DateTime>;

  minRemainingCapacity?: Maybe<number>;

  maxRemainingCapacity?: Maybe<number>;

  capacityType?: Maybe<CapacityType>;
}

export interface ExperiencesOrderArgs {
  key: ExperiencesOrderKey;

  direction: OrderDirection;
}

export interface DateWindowInput {
  startTime?: Maybe<DateTime>;

  endTime?: Maybe<DateTime>;
}

export interface MysteryTemplateOrderArgs {
  key: MysteryTemplateOrderKey;

  direction: OrderDirection;
}

export interface TemplateFilterInput {
  categories?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  connectionFocusAreas?: Maybe<(Maybe<ConnectionFocusArea>)[]>;

  title?: Maybe<string>;

  code?: Maybe<string>;
}

export interface GenericReferenceInput {
  id: string;
}

export interface OrganizationOrderArgs {
  key: OrganizationOrderKey;

  direction: OrderDirection;
}

export interface PartnerOrderArgs {
  key: PartnerOrderKey;

  direction: OrderDirection;
}

export interface PartnerFilterInput {
  statuses?: Maybe<(Maybe<PartnerStatus>)[]>;

  qualities?: Maybe<(Maybe<PartnerQuality>)[]>;

  tiers?: Maybe<(Maybe<PartnerTier>)[]>;

  search?: Maybe<string>;
}

export interface Rules {
  OpenHours?: Maybe<OpenHoursRuleInput>;

  PriceRange?: Maybe<PriceRangeRuleInput>;

  OpenEvent?: Maybe<OpenEventRuleInput>;

  HasPhysicalGoods?: Maybe<HasPhysicalGoodsInput>;

  IncludeByTag?: Maybe<TagsRuleInput>;
}

export interface OpenHoursRuleInput {
  startTime: DateTime;

  timezone: string;
}

export interface PriceRangeRuleInput {
  priceLevel: number;

  includeLowerPriceLevels?: Maybe<boolean>;
}

export interface OpenEventRuleInput {
  timeWindows: (Maybe<TimeWindow>)[];

  timezone: string;

  numberOfAttendees?: Maybe<number>;
}

export interface TimeWindow {
  startTime: DateTime;

  endTime: DateTime;
}

export interface HasPhysicalGoodsInput {
  showPhysicalGoods: boolean;
}

export interface TagsRuleInput {
  tags?: Maybe<string[]>;
}

export interface PriceCentsInput {
  min?: Maybe<number>;

  max?: Maybe<number>;
}

export interface TagOrderArgs {
  key: TagOrderKey;

  direction: OrderDirection;
}

export interface TaskFlowScopeInput {
  type: TaskFlowScopeType;

  token: string;
}

export interface Window {
  startTime?: Maybe<DateTime>;

  endTime?: Maybe<DateTime>;
}

export interface UsersOrderArgs {
  key: UsersOrderKey;

  direction: OrderDirection;
}

export interface OrganizationArgs {
  organizationId: string;

  showArchived: boolean;
}

export interface TeamArgs {
  teams?: Maybe<(Maybe<string>)[]>;

  includeManager?: Maybe<boolean>;
}

export interface VirtualEventOrderArgs {
  key: VirtualEventsOrderKey;

  direction: OrderDirection;
}

export interface AudienceMemberInput {
  user?: Maybe<GenericReferenceInput>;

  team?: Maybe<GenericReferenceInput>;

  userTag?: Maybe<GenericReferenceInput>;

  organization?: Maybe<GenericReferenceInput>;
}

export interface XperiencesOrderArgs {
  key: XperiencesOrderKey;

  direction: OrderDirection;
}

export interface RuleOptions {
  beyondRange?: Maybe<number>;

  withinRange?: Maybe<number>;

  pickupLocation?: Maybe<CoordinatesInput>;

  experienceTypes?: Maybe<(Maybe<XperienceType>)[]>;

  startTime?: Maybe<DateTime>;
}

export interface XperienceFilterInput {
  hasPhysicalGoods?: Maybe<boolean>;

  isActive?: Maybe<boolean>;

  isVirtual?: Maybe<boolean>;

  numGuests?: Maybe<number>;

  statuses?: Maybe<(Maybe<XperienceStatus>)[]>;

  tags?: Maybe<(Maybe<TagInput>)[]>;

  categories?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  connectionFocusAreas?: Maybe<(Maybe<ConnectionFocusArea>)[]>;
}

export interface TagInput {
  name?: Maybe<string>;
}

export interface PriceLineItemInput {
  id?: Maybe<string>;

  ride?: Maybe<GenericReferenceInput>;

  experience?: Maybe<GenericReferenceInput>;

  xperience?: Maybe<GenericReferenceInput>;

  multiplier?: Maybe<number>;

  centsOverride?: Maybe<number>;

  description?: Maybe<string>;

  category: LineItemCategory;
}

export interface DisassociatePricesInput {
  priceId: string;

  entityType: PriceEntity;

  entityId: string;
}

export interface CreateContractChargeInput {
  contractId: string;

  teamEventId: string;

  unitCount: number;

  premiumUnitCount: number;
}

export interface CreateBookingSessionInput {
  sessionId: string;

  dateWindows?: Maybe<(Maybe<DateWindowInput>)[]>;

  partySize?: Maybe<number>;

  budgetCents?: Maybe<number>;

  guestEmails?: Maybe<(Maybe<string>)[]>;

  optionsPresented?: Maybe<(Maybe<BookingOptionsPresentedInput>)[]>;

  selectedEventDetails?: Maybe<(Maybe<string>)[]>;

  productType: MysteryProduct;

  teamEvent?: Maybe<GenericReferenceInput>;

  requester?: Maybe<GenericReferenceInput>;
}

export interface BookingOptionsPresentedInput {
  template: string;

  experiences: (Maybe<GenericReferenceInput>)[];
}

export interface UpdateBookingSessionInput {
  dateWindows?: Maybe<(Maybe<DateWindowInput>)[]>;

  partySize?: Maybe<number>;

  budgetCents?: Maybe<number>;

  optionsPresented?: Maybe<(Maybe<string>)[]>;

  selectedEventDetails?: Maybe<(Maybe<string>)[]>;

  teamEvent?: Maybe<GenericReferenceInput>;

  requester?: Maybe<GenericReferenceInput>;
}

export interface BudgetOptionInput {
  type?: Maybe<MysteryType>;

  minCents?: Maybe<number>;

  maxCents?: Maybe<number>;

  labelLong?: Maybe<string>;

  labelShort?: Maybe<string>;

  planningFeeCents?: Maybe<number>;
}

export interface ChatMessageInput {
  content: string;

  roomId: string;
}

export interface CreditCardInput {
  last4: string;

  expMonth: number;

  expYear: number;
}

export interface DeliveryOrderRequesterInput {
  id?: Maybe<string>;

  email?: Maybe<string>;

  firstName?: Maybe<string>;

  lastName?: Maybe<string>;

  phone?: Maybe<string>;

  dob?: Maybe<DateTime>;

  zipCode?: Maybe<string>;
}

export interface DeliveryOrderRecipientInput {
  id?: Maybe<string>;

  firstName?: Maybe<string>;

  lastName?: Maybe<string>;

  phone?: Maybe<string>;
}

export interface MarketingAttributions {
  utm_campaign?: Maybe<string>;

  utm_content?: Maybe<string>;

  utm_term?: Maybe<string>;

  utm_source?: Maybe<string>;
}

export interface ExtraInfoInput {
  name: string;

  description?: Maybe<string>;

  costCentsEach: number;

  quantity: number;

  type: DeliveryOrderExtraItemType;
}

export interface XperienceInput {
  id: string;
}

export interface CostInput {
  ctcCents: number;

  ctcUnit: CostUnit;

  ctcVolumeDiscounts?: Maybe<(Maybe<VolumeDiscountInput>)[]>;

  ctcPricingBands: PricingBandInput[];

  ctmCents: number;

  ctmUnit: CostUnit;

  ctmVolumeDiscounts?: Maybe<(Maybe<VolumeDiscountInput>)[]>;

  ctmTieredEventPricing?: Maybe<(Maybe<TieredEventPricingInput>)[]>;

  ctmPerPersonCentsMinimum?: Maybe<number>;

  cancellationPolicy?: Maybe<string>;

  agreedOn?: Maybe<Date>;
}

export interface VolumeDiscountInput {
  minNumUsers: number;

  percentDiscount: number;
}

export interface PricingBandInput {
  minUsers: number;

  maxUsers?: Maybe<number>;

  cents: number;

  credits?: Maybe<number>;

  unit: CostUnit;
}

export interface TieredEventPricingInput {
  minNumUsers: number;

  ctmCents: number;
}

export interface ReferenceCategoryInput {
  id: string;

  name: string;

  type: ExperienceType;
}

export interface InventoryItemInput {
  name?: Maybe<string>;

  description?: Maybe<string>;

  isActive?: Maybe<boolean>;

  category?: Maybe<InventoryItemCategory>;

  costToMysteryCents?: Maybe<number>;
}

export interface BoxRecipeInput {
  isActive?: Maybe<boolean>;

  name?: Maybe<string>;

  description?: Maybe<string>;

  localDeliveryMarketRestriction?: Maybe<LocalDeliveryMarket>;

  ingredients?: Maybe<BoxRecipeLineItemInput[]>;

  boxTypes?: Maybe<GenericReferenceInput[]>;
}

export interface BoxRecipeLineItemInput {
  id?: Maybe<string>;

  item: GenericReferenceInput;

  quantity: number;
}

export interface BoxTypeInput {
  isB2C?: Maybe<boolean>;

  isActive?: Maybe<boolean>;

  name?: Maybe<string>;

  description?: Maybe<string>;

  costToCustomerCents?: Maybe<number>;

  boxRecipes?: Maybe<GenericReferenceInput[]>;

  photoUrl?: Maybe<string>;
}

export interface MoralePulseSettings {
  cadence?: Maybe<MoralePulseCadence>;

  enabled?: Maybe<boolean>;

  startDate?: Maybe<Date>;

  activeQuestions?: Maybe<(Maybe<string>)[]>;

  targetAudience?: Maybe<(Maybe<string>)[]>;
}

export interface MoralePulseSurveySettings {
  audience?: Maybe<(Maybe<string>)[]>;

  endDate?: Maybe<Date>;

  status?: Maybe<MoralePulseSurveyStatus>;
}

export interface MoralePulseQuestionReponse {
  questionId: string;

  value?: Maybe<number>;

  comment?: Maybe<string>;
}

export interface MagicLinkAnswer {
  questionId: string;

  value: number;

  comment?: Maybe<string>;
}

export interface MysteryTemplateInput {
  code?: Maybe<string>;

  title?: Maybe<string>;

  description?: Maybe<string>;

  quote?: Maybe<string>;

  date?: Maybe<DateTime>;

  theme?: Maybe<GenericReferenceInput>;

  logoUrl?: Maybe<string>;

  photoUrl?: Maybe<string>;

  color?: Maybe<string>;

  orderIndex?: Maybe<number>;

  type?: Maybe<MysteryType>;

  cost?: Maybe<CostInput>;

  categories?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  rangeIdealGuests?: Maybe<(Maybe<number>)[]>;

  estimatedDurationMins?: Maybe<number>;

  includesBreakoutRooms?: Maybe<boolean>;

  connectionFocusAreas?: Maybe<(Maybe<ConnectionFocusArea>)[]>;
}

export interface ReferenceUserInput {
  id: string;
}

export interface MemberInput {
  email?: Maybe<string>;

  userId?: Maybe<string>;

  roleId?: Maybe<string>;
}

export interface UserFromCsv {
  firstName: string;

  lastName: string;

  email: string;

  managerEmail?: Maybe<string>;

  title?: Maybe<string>;

  department?: Maybe<string>;

  startDate?: Maybe<DateTime>;
}

export interface OrgSimilarityInput {
  orgId: string;

  biggestSimilarity?: Maybe<string>;

  biggestDifference?: Maybe<string>;
}

export interface PartnerVideoPlatformInput {
  platform?: Maybe<VirtualEventType>;
}

export interface CreateOpsAdjustmentForTeamEvent {
  owner: PayoutOwnerInput;

  reasonToken: string;

  amount: AmountInput;
}

export interface PayoutOwnerInput {
  ownerType: PayoutOwnerType;

  ownerToken: string;
}

export interface AmountInput {
  unitType: UnitType;

  unitToken: string;

  unitCount: number;
}

export interface PlannedMessageInput {
  id?: Maybe<string>;

  orderIndex: number;

  content: string;

  stop?: Maybe<GenericReferenceInput>;

  hook?: Maybe<PlannedMessageHookType>;

  hookDurationMs?: Maybe<number>;

  recipient?: Maybe<GenericReferenceInput>;
}

export interface QuestionInput {
  id?: Maybe<string>;

  question?: Maybe<string>;

  questionType?: Maybe<QuestionType>;

  options?: Maybe<(Maybe<string>)[]>;

  scaleMin?: Maybe<number>;

  scaleMax?: Maybe<number>;

  orderIndex?: Maybe<number>;

  required?: Maybe<boolean>;

  requesterOnly?: Maybe<boolean>;

  contractOnly?: Maybe<boolean>;

  transactionalOnly?: Maybe<boolean>;
}

export interface AnswerInput {
  id?: Maybe<string>;

  question?: Maybe<GenericReferenceInput>;

  questionnaire?: Maybe<GenericReferenceInput>;

  answer?: Maybe<string>;
}

export interface UserOrganizationRole {
  userId: string;

  organizationRoleId: string;
}

export interface BudgetInput {
  min: number;

  max?: Maybe<number>;
}

export interface ScheduledAdventureGuestInput {
  name: string;

  phone?: Maybe<string>;
}

export interface SearchInput {
  query: string;

  entities: string[];

  fieldsToSearch: string[];

  limit?: Maybe<number>;
}

export interface TaskFlowInput {
  flow: TaskFlowType;

  subFlow: TaskFlowSubType;

  scope: TaskFlowScopeInput;

  owner: TaskFlowOwnerInput;

  assignee: TaskFlowAssigneeInput;

  status?: Maybe<TaskFlowStatus>;

  priority?: Maybe<TaskFlowPriority>;
}

export interface TaskFlowOwnerInput {
  type: TaskFlowOwnerType;

  token: string;
}

export interface TaskFlowAssigneeInput {
  type: TaskFlowAssigneeType;

  token: string;
}

export interface TaskFlowUpdate {
  assignee?: Maybe<TaskFlowAssigneeInput>;

  status?: Maybe<TaskFlowStatus>;

  subFlow?: Maybe<TaskFlowSubType>;

  priority?: Maybe<TaskFlowPriority>;
}

export interface ChangeManagerInput {
  teamId: string;

  managerId: string;
}

export interface BoxTypeCapacityInput {
  boxTypeId: string;

  capacityLimit?: Maybe<number>;
}

export interface InviteeInput {
  firstName?: Maybe<string>;

  lastName?: Maybe<string>;

  email?: Maybe<string>;

  isRequester?: Maybe<boolean>;
}

export interface NullableReferenceInput {
  id?: Maybe<string>;
}

export interface FoodPreferenceInput {
  type: Cuisine;

  value?: Maybe<number>;
}

export interface RestrictionInput {
  type: RestrictionType;

  value: string;
}

export interface UserUpdateInput {
  zipCode?: Maybe<string>;

  email?: Maybe<string>;

  name?: Maybe<string>;

  firstName?: Maybe<string>;

  lastName?: Maybe<string>;

  marketingConsent?: Maybe<boolean>;

  phone?: Maybe<string>;

  dob?: Maybe<Date>;

  role?: Maybe<Role>;

  preferenceAnswers?: Maybe<GenericReferenceInput[]>;

  dietaryRestrictions?: Maybe<GenericReferenceInput[]>;

  accessibilityRestrictions?: Maybe<GenericReferenceInput[]>;

  foodPreferences?: Maybe<FoodPreferenceInput[]>;

  dietaryRestrictionsLastAsked?: Maybe<Date>;

  restrictions?: Maybe<RestrictionInput[]>;

  internalSlackId?: Maybe<string>;

  companyRole?: Maybe<string>;

  companyNameForSales?: Maybe<string>;

  orgs?: Maybe<GenericReferenceInput[]>;

  organizationRoleId?: Maybe<string>;

  wasPromptedForMarketingConsent?: Maybe<boolean>;

  isServiceAccount?: Maybe<boolean>;

  team?: Maybe<string>;

  startDate?: Maybe<DateTime>;
  /** deprecated - use optOutOfWatercoolerAt */
  optOutOfWatercooler?: Maybe<boolean>;

  optOutOfWatercoolerAt?: Maybe<DateTime>;

  preferredWatercoolerDays?: Maybe<Weekday[]>;

  preferredWatercoolerTime?: Maybe<TimeOfDay[]>;

  timezone?: Maybe<string>;

  dataIntegrationAdmin?: Maybe<boolean>;

  optOutOfDigestAt?: Maybe<DateTime>;
}

export interface FinchReassocationInput {
  targetEmail: string;

  toReassociate: string;

  finchAssociationId: string;
}

export interface XperienceUsaShippingInput {
  enabled: boolean;

  estimatedLeadTimeDays?: Maybe<number>;

  estimatedCostCents?: Maybe<number>;

  excludeStates: string[];
}

export interface XperienceInternationalShippingInput {
  enabled: boolean;

  estimatedLeadTimeDays?: Maybe<number>;

  estimatedCostCents?: Maybe<number>;

  excludeCountries: string[];
}

export interface ScheduleInput {
  id?: Maybe<string>;

  rrule?: Maybe<string>;

  startTime?: Maybe<string>;

  durationMins?: Maybe<number>;

  capacity?: Maybe<number>;
}

export interface OperatingHoursInput {
  open: OperatingHoursTimeInput;

  close?: Maybe<OperatingHoursTimeInput>;
}

export interface OperatingHoursTimeInput {
  day: number;

  hours: number;

  minutes: number;

  time: string;
}

export interface OpenTableMetaData {
  crossStreet?: Maybe<string>;

  neighborhood?: Maybe<string>;

  hoursOfOperation?: Maybe<string>;

  cuisines?: Maybe<string>;

  diningStyle?: Maybe<string>;

  dressCode?: Maybe<string>;

  parkingDetails?: Maybe<string>;

  paymentOptions?: Maybe<string>;

  executiveChef?: Maybe<string>;

  additional?: Maybe<string>;

  topTags?: Maybe<(Maybe<string>)[]>;

  description?: Maybe<string>;

  foodType?: Maybe<string>;

  noise?: Maybe<string>;
}

export interface ActivityPreferenceInput {
  type: ActivityType;
}

export interface AdventureGuestInput {
  id?: Maybe<string>;

  name?: Maybe<string>;

  phone?: Maybe<string>;

  alertsPreference?: Maybe<MysteryAlertsPreference>;
}

export interface AdventureStopExperienceInput {
  id: string;
}

export interface AdventureStopInput {
  id?: Maybe<string>;

  adventure?: Maybe<GenericReferenceInput>;

  details?: Maybe<string>;

  isFlexRide?: Maybe<boolean>;

  skipRide?: Maybe<boolean>;

  pickupTime: DateTime;

  startTime: DateTime;

  endTime: DateTime;

  orderIndex: number;

  experienceType: AdventureStopExperienceType;

  experience?: Maybe<AdventureStopExperienceInput>;

  event?: Maybe<GenericReferenceInput>;

  checkInDetails?: Maybe<string>;

  checkOutDetails?: Maybe<string>;

  paymentDetails?: Maybe<string>;

  budgetCents?: Maybe<number>;
}

export interface AdventureUserInput {
  id: string;

  alertsPreference?: Maybe<MysteryAlertsPreference>;
}

export interface AuditLogsOrderArgs {
  key: AuditLogsOrderKey;

  direction: OrderDirection;
}

export interface ExperienceWithEventIdInput {
  experience: GenericReferenceInput;

  eventId?: Maybe<string>;
}

export interface PayoutEntityReferenceInput {
  entityType: PayoutEntityType;

  entityId: string;
}

export interface PreferenceQuestionAnswerInput {
  label: string;

  value: number;
}

export interface PreferenceQuestionInput {
  type?: Maybe<PreferenceType>;

  question: string;

  answers?: Maybe<PreferenceQuestionAnswerInput[]>;
}

export interface PriceCompInput {
  reason: string;

  amountCents?: Maybe<number>;

  fullComp: boolean;
}

export interface ReferenceSubCategoryInput {
  id: string;

  name: string;

  category: ReferenceCategoryInput;
}

export interface ScrapedEventsOrderArgs {
  key: ScrapedEventsOrderKey;

  direction: OrderDirection;
}

export interface StopFeedbackInput {
  stop: GenericReferenceInput;

  user: GenericReferenceInput;

  adventure: GenericReferenceInput;

  rating?: Maybe<number>;

  hadBeenPreviously?: Maybe<boolean>;

  comments?: Maybe<string>;
}

export interface UpdateZoomMeetingInput {
  id: string;

  hostJoinedAt?: Maybe<DateTime>;
}

export interface VirtualEventUserInput {
  id: string;
}

export enum RegionName {
  Sea = 'SEA',
  Aus = 'AUS',
  Atl = 'ATL',
  Den = 'DEN',
  Phx = 'PHX',
  Sfo = 'SFO',
  Slc = 'SLC',
  Nyc = 'NYC',
  Tst = 'TST',
}

export enum Role {
  Wizard = 'Wizard',
  Muggle = 'Muggle',
  HouseElf = 'HouseElf',
}

export enum MysteriesOrderKey {
  Id = 'Id',
  StartsAt = 'StartsAt',
  Status = 'Status',
}

export enum OrderDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum AdventureStatus {
  PendingInstantBook = 'PendingInstantBook',
  Requested = 'Requested',
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Canceled = 'Canceled',
  Completed = 'Completed',
}

export enum ScheduledAdventureStatus {
  Unfulfilled = 'Unfulfilled',
  Fulfilled = 'Fulfilled',
  Canceled = 'Canceled',
}

export enum DiscountType {
  Percentage = 'Percentage',
  Flat = 'Flat',
  NoPlanningFee = 'NoPlanningFee',
}

export enum ExperienceType {
  Restaurant = 'Restaurant',
  Bar = 'Bar',
  Activity = 'Activity',
  Eatery = 'Eatery',
}

export enum AdmissionType {
  FirstComeFirstServed = 'FirstComeFirstServed',
  Reservation = 'Reservation',
  Ticket = 'Ticket',
}

export enum Attire {
  Active = 'Active',
  BusinessCasual = 'BusinessCasual',
  Casual = 'Casual',
  ColdWeather = 'ColdWeather',
  Formal = 'Formal',
  SemiFormal = 'SemiFormal',
}

export enum InventoryItemCategory {
  MainActivity = 'MainActivity',
  SmallActivity = 'SmallActivity',
  Goods = 'Goods',
  Treat = 'Treat',
  Other = 'Other',
}

export enum DeliveryOrderMethod {
  Delivery = 'Delivery',
  Pickup = 'Pickup',
}

export enum DeliveryProvider {
  FedEx = 'FedEx',
  Postmates = 'Postmates',
}

export enum DeliveryStatus {
  Pending = 'Pending',
  Pickup = 'Pickup',
  PickupComplete = 'PickupComplete',
  Dropoff = 'Dropoff',
  Delivered = 'Delivered',
  Canceled = 'Canceled',
  Returned = 'Returned',
  Ongoing = 'Ongoing',
  Completed = 'Completed',
}

export enum DeliveryInternalStatus {
  NotStarted = 'NotStarted',
  Assembled = 'Assembled',
  SentToFulfillment = 'SentToFulfillment',
  Canceled = 'Canceled',
}

export enum Cuisine {
  Asian = 'Asian',
  American = 'American',
  Barbecue = 'Barbecue',
  Caribbean = 'Caribbean',
  Chinese = 'Chinese',
  Dessert = 'Dessert',
  French = 'French',
  Greek = 'Greek',
  Indian = 'Indian',
  Italian = 'Italian',
  Japanese = 'Japanese',
  Korean = 'Korean',
  Mediterranean = 'Mediterranean',
  Mexican = 'Mexican',
  Seafood = 'Seafood',
  Thai = 'Thai',
  Vietnamese = 'Vietnamese',
}

export enum PlannedMessageHookType {
  BeforeArrival = 'BeforeArrival',
  AfterArrival = 'AfterArrival',
  BeforeStart = 'BeforeStart',
  AfterStart = 'AfterStart',
  DeliverySentToFulfillment = 'DeliverySentToFulfillment',
  DeliveryOrderReminder = 'DeliveryOrderReminder',
  DeliveryPickedUp = 'DeliveryPickedUp',
  DeliveryMenuInfo = 'DeliveryMenuInfo',
  Delivered = 'Delivered',
  Immediate = 'Immediate',
}

export enum RideStatus {
  Scheduled = 'Scheduled',
  Pending = 'Pending',
  Dispatched = 'Dispatched',
  Accepted = 'Accepted',
  Arrived = 'Arrived',
  PickedUp = 'PickedUp',
  DroppedOff = 'DroppedOff',
  Canceled = 'Canceled',
}

export enum RideType {
  Drive = 'Drive',
  Lyft = 'Lyft',
  LyftPlus = 'LyftPlus',
  Walk = 'Walk',
}

export enum PriceStatus {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
  Comped = 'Comped',
  Abandoned = 'Abandoned',
}

export enum OverrideBudgetBlockReason {
  PlanningError = 'PlanningError',
  CustomerExceededPlannedBudget = 'CustomerExceededPlannedBudget',
  LyftCostsMoreThanExpected = 'LyftCostsMoreThanExpected',
  SelfServeBooking = 'SelfServeBooking',
}

export enum PriceEntity {
  TeamEvent = 'TeamEvent',
}

export enum PriceType {
  Initial = 'Initial',
  Finalized = 'Finalized',
  ExtraCharge = 'ExtraCharge',
  TestCharge = 'TestCharge',
}

export enum MysteryProduct {
  NightIn = 'NightIn',
  NightOut = 'NightOut',
  TeamEvent = 'TeamEvent',
  Watercooler = 'Watercooler',
  Hybrid = 'Hybrid',
}

export enum ContractStatus {
  Active = 'Active',
  Expired = 'Expired',
  Canceled = 'Canceled',
}

export enum ContractUnitType {
  Cents = 'Cents',
  Credits = 'Credits',
  Event = 'Event',
  Recurring = 'Recurring',
  Unlimited = 'Unlimited',
}

export enum TeamEventStatus {
  Requested = 'Requested',
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Canceled = 'Canceled',
  ReadyToReview = 'ReadyToReview',
  InvoiceSent = 'InvoiceSent',
  Complete = 'Complete',
  Expired = 'Expired',
}

export enum VirtualEventType {
  Chime = 'Chime',
  Zoom = 'Zoom',
  Teams = 'Teams',
  Meet = 'Meet',
  ZoomIsv = 'ZoomIsv',
}

export enum QuestionType {
  Text = 'Text',
  DropDown = 'DropDown',
  Image = 'Image',
  MultipleChoice = 'MultipleChoice',
  Checkboxes = 'Checkboxes',
  Scale = 'Scale',
  Date = 'Date',
  Time = 'Time',
  LimitedCharText = 'LimitedCharText',
  PeoplePicker = 'PeoplePicker',
  PeopleRater = 'PeopleRater',
  EventReview = 'EventReview',
}

export enum DefaultQuestionnaireFor {
  Mystery = 'Mystery',
  TeamEvent = 'TeamEvent',
  AccountManager = 'AccountManager',
  BoxRecipe = 'BoxRecipe',
  VirtualEvent = 'VirtualEvent',
  Watercooler = 'Watercooler',
}

export enum InvitedGuestRsvpStatus {
  Invited = 'Invited',
  Accepted = 'Accepted',
  Declined = 'Declined',
  NeedsMoreInfo = 'NeedsMoreInfo',
}

export enum TeamEventIssueCategory {
  SupplyPartner = 'SupplyPartner',
  Deliverables = 'Deliverables',
  LiveOps = 'LiveOps',
  OpsTeam = 'OpsTeam',
  SupplyTeam = 'SupplyTeam',
  SalesTeam = 'SalesTeam',
  PdeTech = 'PdeTech',
  MysteryMatched = 'MysteryMatched',
  Billing = 'Billing',
  Other = 'Other',
}

export enum DeliveryOrderExtraItemType {
  Meal = 'Meal',
  BottleOfWineRed = 'BottleOfWineRed',
  BottleOfWineWhite = 'BottleOfWineWhite',
}

export enum ParticipationModel {
  Hybrid = 'Hybrid',
  InPerson = 'InPerson',
  Virtual = 'Virtual',
}

export enum EventGoal {
  FullOrg = 'FullOrg',
  WithinTeam = 'WithinTeam',
  BetweenTeam = 'BetweenTeam',
  Leadership = 'Leadership',
  NewHire = 'NewHire',
}

export enum PayoutOwnerType {
  User = 'User',
  Company = 'Company',
  Partner = 'Partner',
}

export enum UnitType {
  CurrencyCents = 'CurrencyCents',
  ContractSeats = 'ContractSeats',
  CreditCentis = 'CreditCentis',
  CreditMillis = 'CreditMillis',
}

export enum PayoutReasonType {
  TeamEventPayout = 'TeamEventPayout',
  TeamEventRevenue = 'TeamEventRevenue',
  Tax = 'Tax',
  OpsAdjustment = 'OpsAdjustment',
}

export enum TestEventType {
  NoPayments = 'NoPayments',
  OnlyCharges = 'OnlyCharges',
  ChargesAndPayout = 'ChargesAndPayout',
}

export enum TeamEventOrderKey {
  Id = 'Id',
  Type = 'Type',
  RequestedFor = 'RequestedFor',
}

export enum HrisSyncState {
  Pulling = 'Pulling',
  Validation = 'Validation',
  Error = 'Error',
  Done = 'Done',
}

export enum CustomerType {
  Transactional = 'Transactional',
  EventsOnly = 'EventsOnly',
  EventsAndInsights = 'EventsAndInsights',
}

export enum EntityType {
  DirectTeam = 'DirectTeam',
  TransitiveTeam = 'TransitiveTeam',
  Individual = 'Individual',
  NewHire = 'NewHire',
}

export enum ScoreType {
  WithinTeam = 'WithinTeam',
  SenseOfBelonging = 'SenseOfBelonging',
  Leadership = 'Leadership',
  CrossTeam = 'CrossTeam',
}

export enum ServiceAccountProvider {
  Google = 'Google',
}

export enum MoralePulseCadence {
  Weekly = 'WEEKLY',
  Semimonthly = 'SEMIMONTHLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
}

export enum MoralePulseQuestionCategory {
  Connection = 'Connection',
  Trust = 'Trust',
  Leadership = 'Leadership',
  JobSatisfaction = 'JobSatisfaction',
  Morale = 'Morale',
  Communication = 'Communication',
  WellBeing = 'WellBeing',
  EmployeeGrowth = 'EmployeeGrowth',
  Pay = 'Pay',
  Benefits = 'Benefits',
  CompanyDirection = 'CompanyDirection',
  DiversityAndInclusion = 'DiversityAndInclusion',
}

export enum MoralePulseQuestionType {
  Scale = 'Scale',
  Nps = 'NPS',
  Friendship = 'Friendship',
}

export enum MoralePulseSurveyStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Closed = 'Closed',
}

export enum OrganizationOnboardingStatus {
  Complete = 'Complete',
  RequiresAdmin = 'RequiresAdmin',
  RequiresTeams = 'RequiresTeams',
  RequiresCalendars = 'RequiresCalendars',
}

export enum EventName {
  MysteryStatusChanged = 'MysteryStatusChanged',
  MysteryDetailsChangedByCustomer = 'MysteryDetailsChangedByCustomer',
  StopsChanged = 'StopsChanged',
  BillingStatusChanged = 'BillingStatusChanged',
  BillingPriceOverride = 'BillingPriceOverride',
  ExperienceChanged = 'ExperienceChanged',
  QueryDataChanged = 'QueryDataChanged',
  EntityApproval = 'EntityApproval',
}

export enum LineItemCategory {
  SurpriseToAll = 'SurpriseToAll',
  Lyft = 'Lyft',
  Experience = 'Experience',
  Contract = 'Contract',
  GiftCard = 'GiftCard',
  Promo = 'Promo',
  PlanningFee = 'PlanningFee',
  ServiceFee = 'ServiceFee',
  Comp = 'Comp',
  SubscriptionDiscount = 'SubscriptionDiscount',
  PostEvent = 'PostEvent',
  Tax = 'Tax',
  AbandonedCollection = 'AbandonedCollection',
  Other = 'Other',
  Credit = 'Credit',
}

export enum StopStatus {
  Arrived = 'Arrived',
  Completed = 'Completed',
}

export enum ProgressType {
  RideDispatched = 'RideDispatched',
  RideAtPickup = 'RideAtPickup',
  PickedUp = 'PickedUp',
  RideAtDropoff = 'RideAtDropoff',
  Canceled = 'Canceled',
}

export enum MysteryFrequency {
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Annually = 'Annually',
  SpecialOccasions = 'SpecialOccasions',
}

export enum StopReservationStatus {
  NoReservation = 'NoReservation',
  ReservationMade = 'ReservationMade',
  NoReservationRequired = 'NoReservationRequired',
}

export enum LocalDeliveryMarket {
  Seattle = 'Seattle',
  Denver = 'Denver',
  Austin = 'Austin',
}

export enum XperienceType {
  Restaurant = 'Restaurant',
  Bar = 'Bar',
  Activity = 'Activity',
  Dessert = 'Dessert',
}

export enum OnlineReservationProvider {
  OpenTable = 'OpenTable',
  Resy = 'Resy',
}

export enum ConnectionFocusArea {
  Leadership = 'Leadership',
  CrossTeam = 'CrossTeam',
  SenseOfBelonging = 'SenseOfBelonging',
  IntraTeam = 'IntraTeam',
}

export enum AttireTypeOptions {
  Athletic = 'Athletic',
  Athleisure = 'Athleisure',
  Casual = 'Casual',
  Nice = 'Nice',
  Formal = 'Formal',
}

export enum SubAttireTypeCategory {
  Planning = 'Planning',
  Clothing = 'Clothing',
  Shoes = 'Shoes',
  Other = 'Other',
  UnacceptableAttire = 'UnacceptableAttire',
}

export enum MysteryType {
  Classic = 'Classic',
  Meal = 'Meal',
  Activity = 'Activity',
  TeamEvent = 'TeamEvent',
}

export enum CostUnit {
  User = 'User',
  Mystery = 'Mystery',
  Event = 'Event',
}

export enum UnitToken {
  Usd = 'USD',
  Seats = 'Seats',
  PremiumSeats = 'PremiumSeats',
  Credits = 'Credits',
}

export enum PartnerStatus {
  Lead = 'Lead',
  Qualified = 'Qualified',
  Vetting = 'Vetting',
  Active = 'Active',
  Deactivated = 'Deactivated',
  Rejected = 'Rejected',
}

export enum PartnerTier {
  First = 'First',
  Second = 'Second',
  Third = 'Third',
  Fourth = 'Fourth',
  Fifth = 'Fifth',
}

export enum PartnerQuality {
  Unknown = 'Unknown',
  Standard = 'Standard',
  Preferred = 'Preferred',
  Elite = 'Elite',
}

export enum XperienceAvailabilityType {
  OperatingHours = 'OperatingHours',
  Schedule = 'Schedule',
}

export enum XperienceOwnerRole {
  Owner = 'Owner',
  GeneralManager = 'GeneralManager',
  BarManager = 'BarManager',
  Manager = 'Manager',
  OperationsLead = 'OperationsLead',
}

export enum XperienceStatus {
  Incomplete = 'Incomplete',
  CompletedNeedsReview = 'CompletedNeedsReview',
  CompletedReviewed = 'CompletedReviewed',
}

export enum CapacityType {
  Reservation = 'Reservation',
  PrePurchased = 'PrePurchased',
}

export enum LegalDocumentType {
  TermsOfService = 'TermsOfService',
  PrivacyPolicy = 'PrivacyPolicy',
  CancelPolicy = 'CancelPolicy',
}

export enum MessageType {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
}

export enum MessageExternalSource {
  ChatKit = 'ChatKit',
  Twilio = 'Twilio',
  StreamChat = 'StreamChat',
}

export enum PushNotificationType {
  NewChatMessage = 'NewChatMessage',
  MysteryReminderOneHour = 'MysteryReminderOneHour',
  RideReminderFifteenMinutes = 'RideReminderFifteenMinutes',
  ActiveRideStarting = 'ActiveRideStarting',
  ActiveRideEnding = 'ActiveRideEnding',
  RideDispatched = 'RideDispatched',
  RideArriving = 'RideArriving',
  ArrivingAtStop = 'ArrivingAtStop',
  RideHeartbeat = 'RideHeartbeat',
  AdHoc = 'AdHoc',
  PushCheck = 'PushCheck',
  FeedbackRequest = 'FeedbackRequest',
}

export enum LocationPermissionType {
  Always = 'Always',
  WhileUsing = 'WhileUsing',
  Never = 'Never',
}

export enum PreferenceType {
  Competition = 'Competition',
  MakeFood = 'MakeFood',
  Health = 'Health',
  GeneralInterests = 'GeneralInterests',
  Cooperation = 'Cooperation',
  CityFamiliarity = 'CityFamiliarity',
  Day = 'Day',
  Depend = 'Depend',
  Entertainment = 'Entertainment',
  EntertainmentType = 'EntertainmentType',
  EntertainmentMusicType = 'EntertainmentMusicType',
  Feedback = 'Feedback',
  Frequency = 'Frequency',
  Adrenaline = 'Adrenaline',
  Alcohol = 'Alcohol',
  AlcoholType = 'AlcoholType',
  Company = 'Company',
  Crafty = 'Crafty',
  Humor = 'Humor',
  AdventurousCuisine = 'AdventurousCuisine',
  Desserts = 'Desserts',
  CuisineRestrictions = 'CuisineRestrictions',
  PhysicalRestrictions = 'PhysicalRestrictions',
  Formal = 'Formal',
  Leadership = 'Leadership',
  Learning = 'Learning',
  Length = 'Length',
  Noise = 'Noise',
  Outdoors = 'Outdoors',
  Participation = 'Participation',
  Party = 'Party',
  Physical = 'Physical',
  Puzzle = 'Puzzle',
  Time = 'Time',
  Risks = 'Risks',
}

export enum PreferenceCategory {
  Activity = 'Activity',
  Interest = 'Interest',
  Cuisine = 'Cuisine',
  SpecialRequest = 'SpecialRequest',
}

export enum RestrictionType {
  Cuisine = 'Cuisine',
  Food = 'Food',
  Physical = 'Physical',
}

export enum DeliveryOrderOrderKey {
  Id = 'Id',
  DropoffDeadline = 'DropoffDeadline',
  InternalStatus = 'InternalStatus',
  RequestedFor = 'RequestedFor',
  MethodOfDelivery = 'MethodOfDelivery',
}

export enum SignUpSource {
  App = 'App',
  Web = 'Web',
}

export enum MysteryAlertsPreference {
  Always = 'Always',
  OnlyAfterStart = 'OnlyAfterStart',
  OneHourBefore = 'OneHourBefore',
  OneDayBefore = 'OneDayBefore',
  Never = 'Never',
}

export enum OrganizationRoleName {
  Admin = 'Admin',
  Coordinator = 'Coordinator',
  Member = 'Member',
}

export enum NylasConnectionState {
  Active = 'Active',
  Inactive = 'Inactive',
  Unknown = 'Unknown',
}

export enum AccountStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Invited = 'Invited',
  NeedsAction = 'NeedsAction',
  Pending = 'Pending',
}

export enum Weekday {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
}

export enum TimeOfDay {
  Morning = 'Morning',
  Afternoon = 'Afternoon',
}

export enum MessagesOrderKey {
  Id = 'Id',
  CreatedAt = 'CreatedAt',
}

export enum AdventureSource {
  Web = 'Web',
  IOs = 'iOS',
  Android = 'Android',
}

export enum ActivityType {
  Active = 'Active',
  Entertainment = 'Entertainment',
  Classes = 'Classes',
  Surprise = 'Surprise',
}

export enum Relationship {
  Sibling = 'Sibling',
  Parent = 'Parent',
  Child = 'Child',
  Descendent = 'Descendent',
  Ancestor = 'Ancestor',
  Interteam = 'Interteam',
}

export enum EventOrderKey {
  Id = 'Id',
  Start = 'Start',
  RemainingCapacity = 'RemainingCapacity',
  CapacityType = 'CapacityType',
}

export enum ExperiencesOrderKey {
  Id = 'Id',
}

export enum MoralePulseResponseSentiment {
  VeryPositive = 'VERY_POSITIVE',
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
  VeryNegative = 'VERY_NEGATIVE',
  Neutral = 'NEUTRAL',
}

export enum MoralePulseSurveyReportingIntervalType {
  Month = 'MONTH',
  Quarter = 'QUARTER',
}

export enum MysteryTemplateOrderKey {
  Id = 'Id',
  CreatedAt = 'CreatedAt',
}

export enum OrganizationOrderKey {
  Id = 'Id',
  Name = 'Name',
  InternalName = 'InternalName',
  CreatedAt = 'CreatedAt',
  UpdatedAt = 'UpdatedAt',
}

export enum PartnerOrderKey {
  Id = 'Id',
  Name = 'Name',
  Status = 'Status',
  Quality = 'Quality',
  Tier = 'Tier',
  UpdatedAt = 'UpdatedAt',
  CreatedAt = 'CreatedAt',
}

export enum EventScraperSource {
  Stranger = 'Stranger',
  Eventbrite = 'Eventbrite',
}

export enum TagOrderKey {
  Id = 'Id',
}

export enum TaskFlowType {
  OrgCalendarIntegration = 'OrgCalendarIntegration',
  OrgStructure = 'OrgStructure',
  OrgSlackIntegration = 'OrgSlackIntegration',
  PersonalPreferences = 'PersonalPreferences',
  PersonalCalendarIntegration = 'PersonalCalendarIntegration',
  AcceptTermsOfService = 'AcceptTermsOfService',
}

export enum TaskFlowSubType {
  None = 'None',
  GlobalGoogle = 'GlobalGoogle',
  GlobalGoogleDelegate = 'GlobalGoogleDelegate',
  IndividualGoogle = 'IndividualGoogle',
  IndividualMicrosoft = 'IndividualMicrosoft',
  HrisIntegration = 'HrisIntegration',
  CsvUpload = 'CSVUpload',
}

export enum TaskFlowScopeType {
  User = 'User',
  Organization = 'Organization',
}

export enum TaskFlowOwnerType {
  User = 'User',
}

export enum TaskFlowAssigneeType {
  User = 'User',
  External = 'External',
  Glue = 'Glue',
}

export enum TaskFlowStatus {
  NotStarted = 'NotStarted',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Errored = 'Errored',
  UserCalendarConnect = 'UserCalendarConnect',
  CalendarSyncing = 'CalendarSyncing',
  DelegateCalendarConnect = 'DelegateCalendarConnect',
  WaitingForDelegate = 'WaitingForDelegate',
}

export enum TaskFlowPriority {
  Critical = 'Critical',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export enum UsersOrderKey {
  Id = 'Id',
  MostRecentMessage = 'MostRecentMessage',
  FirstName = 'FirstName',
  LastName = 'LastName',
}

export enum VirtualEventsOrderKey {
  Id = 'Id',
  Type = 'Type',
}

export enum WatercoolerStatus {
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
}

export enum WatercoolerGoal {
  CrossTeam = 'CrossTeam',
  WithinTeam = 'WithinTeam',
  Leadership = 'Leadership',
  General = 'General',
  Random = 'Random',
}

export enum XperiencesOrderKey {
  Id = 'Id',
  CreatedAt = 'CreatedAt',
  HasPhysicalGoods = 'HasPhysicalGoods',
  IsActive = 'IsActive',
  IsVirtual = 'IsVirtual',
  Name = 'Name',
  NumMaxGuests = 'NumMaxGuests',
  Status = 'Status',
  UpdatedAt = 'UpdatedAt',
}

export enum RuleName {
  ActivityPreferences = 'ActivityPreferences',
  AvoidPreviousStops = 'AvoidPreviousStops',
  BetweenRange = 'BetweenRange',
  CardOnFile = 'CardOnFile',
  ExcludeByCuisine = 'ExcludeByCuisine',
  ExcludeByDiet = 'ExcludeByDiet',
  ExcludeByImpairment = 'ExcludeByImpairment',
  ExperienceTypes = 'ExperienceTypes',
  HasPhysicalGoods = 'HasPhysicalGoods',
  IncludeByDiet = 'IncludeByDiet',
  IncludeByTag = 'IncludeByTag',
  OpenHours = 'OpenHours',
  OpenEvent = 'OpenEvent',
  OverTwentyOne = 'OverTwentyOne',
  PriceRange = 'PriceRange',
}

export enum ResendOption {
  InstructorOnly = 'InstructorOnly',
  InstructorAndMe = 'InstructorAndMe',
  MeOnly = 'MeOnly',
}

export enum AdventureStopExperienceType {
  Event = 'Event',
  Experience = 'Experience',
}

export enum AuditLogsOrderKey {
  Id = 'Id',
}

export enum CancellationReason {
  Busy = 'Busy',
  Error = 'Error',
  Expensive = 'Expensive',
  DontWantTo = 'DontWantTo',
  GuestsCancel = 'GuestsCancel',
}

export enum ScoreTrend {
  Falling = 'Falling',
  Neutral = 'Neutral',
  Rising = 'Rising',
  Unknown = 'Unknown',
}

export enum EmailCategory {
  Apology = 'Apology',
  BookingConfirmation = 'BookingConfirmation',
  GiftCardConfirmation = 'GiftCardConfirmation',
  MagicLink = 'MagicLink',
  NightInConfirmation = 'NightInConfirmation',
  OrganizationInvite = 'OrganizationInvite',
  Otp = 'OTP',
  PostSignUp = 'PostSignUp',
  Receipt = 'Receipt',
  FeedbackRequest = 'FeedbackRequest',
  FollowupRequest = 'FollowupRequest',
  TeamEventBookingConfirmation = 'TeamEventBookingConfirmation',
  TeamEventInvitation = 'TeamEventInvitation',
  TeamEventInvoice = 'TeamEventInvoice',
  TeamEventReminder = 'TeamEventReminder',
  TeamEventRsvpConfirmation = 'TeamEventRsvpConfirmation',
  TeamEventRsvpReminder = 'TeamEventRsvpReminder',
  TeamEventRsvpFinalReminder = 'TeamEventRsvpFinalReminder',
  RequestToBook = 'RequestToBook',
  VirtualEventHostInfo = 'VirtualEventHostInfo',
  VirtualEventHostReminder = 'VirtualEventHostReminder',
  VirtualEventInstructorInfo = 'VirtualEventInstructorInfo',
  VirtualEventInstructorSelected = 'VirtualEventInstructorSelected',
  ZoomHostLimitReached = 'ZoomHostLimitReached',
  ZoomMeetingCreationFailure = 'ZoomMeetingCreationFailure',
  SelfServeOpsNotification = 'SelfServeOpsNotification',
  HostHasntJoinedToOps = 'HostHasntJoinedToOps',
  TeamEventRsvpDeclineFailure = 'TeamEventRsvpDeclineFailure',
  TeamEventRsvpAcceptFailure = 'TeamEventRsvpAcceptFailure',
  TeamEventAddressReminder = 'TeamEventAddressReminder',
  TeamEventSurveyReminder = 'TeamEventSurveyReminder',
  TeamEventAddressFinalReminder = 'TeamEventAddressFinalReminder',
  TeamEventSurveyFinalReminder = 'TeamEventSurveyFinalReminder',
  NewUserAccountReminder = 'NewUserAccountReminder',
  NewUserAccountFinalReminder = 'NewUserAccountFinalReminder',
  WatercoolersEnabledNotification = 'WatercoolersEnabledNotification',
  WatercoolersFeedbackRequest = 'WatercoolersFeedbackRequest',
  WatercoolersMissedConnectionNotification = 'WatercoolersMissedConnectionNotification',
  PulseResponseRequest = 'PulseResponseRequest',
  PulseResultsNotification = 'PulseResultsNotification',
  Digest = 'Digest',
}

export enum EmailStatus {
  New = 'New',
  Sent = 'Sent',
  Failed = 'Failed',
}

export enum EventType {
  NewMessage = 'NewMessage',
  PlannedMessageSent = 'PlannedMessageSent',
  PlannedMessagesChanged = 'PlannedMessagesChanged',
  RideStatusChanged = 'RideStatusChanged',
  MysteryChanged = 'MysteryChanged',
  ZoomMeetingChanged = 'ZoomMeetingChanged',
}

export enum MarketingEvent {
  SignUp = 'SignUp',
  Schedule = 'Schedule',
  NightInSchedule = 'NightInSchedule',
}

export enum NpsClassification {
  Promoter = 'PROMOTER',
  Detractor = 'DETRACTOR',
  Passive = 'PASSIVE',
}

export enum NylasProvider {
  Gmail = 'gmail',
  Eas = 'eas',
  Ews = 'ews',
  Graph = 'graph',
  Hotmail = 'hotmail',
  Exchange = 'exchange',
  Office365 = 'office365',
  Outlook = 'outlook',
  Yahoo = 'yahoo',
  Icloud = 'icloud',
  Generic = 'generic',
  Imap = 'IMAP',
  Custom = 'custom',
  Nylas = 'nylas',
}

export enum NylasSyncState {
  Downloading = 'downloading',
  Running = 'running',
  Partial = 'partial',
  Exception = 'exception',
  Stopped = 'stopped',
  Initializing = 'initializing',
  Invalid = 'invalid',
}

export enum OpsAdjustmentToken {
  Reschedule = 'Reschedule',
  Cancellation = 'Cancellation',
  PriceDiscrepancy = 'PriceDiscrepancy',
  EventCustomization = 'EventCustomization',
  PartnerOther = 'PartnerOther',
  SupplyIssues = 'SupplyIssues',
  Other = 'Other',
}

export enum OrganizationManagementType {
  Hris = 'HRIS',
  Manual = 'Manual',
}

export enum PayoutEntityType {
  TeamEvent = 'TeamEvent',
}

export enum RelationshipOptions {
  NotSure = 'NotSure',
  JustMet = 'JustMet',
  Neutral = 'Neutral',
  WorkFriends = 'WorkFriends',
  Bffs = 'Bffs',
}

export enum PreferenceQuestionType {
  NightOut = 'NightOut',
  TeamEvent = 'TeamEvent',
  Archived = 'Archived',
}

export enum RRuleFrequency {
  Yearly = 'YEARLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Minutely = 'MINUTELY',
  Secondly = 'SECONDLY',
}

export enum ScrapedEventsOrderKey {
  Id = 'Id',
  StartsAt = 'StartsAt',
  Price = 'Price',
}

export enum TeamEventBookingSource {
  Instant = 'Instant',
  Rtb = 'Rtb',
  Manual = 'Manual',
}

export enum TeamEventMetricType {
  ExperienceRating = 'ExperienceRating',
  ConnectednessRating = 'ConnectednessRating',
}

export enum TeamEventRegistrationStatus {
  Registered = 'Registered',
  Invited = 'Invited',
}

export enum TemplateVariables {
  ExperienceName = 'experience_name',
  InstructorFirstName = 'instructor_first_name',
  EventName = 'event_name',
  Participants = 'participants',
}

export enum UserTagType {
  Automatic = 'Automatic',
  Manual = 'Manual',
}

export enum WatercoolerMissedConnectionReason {
  OptimizerLeftOut = 'OptimizerLeftOut',
  TooBusy = 'TooBusy',
}

export enum WineType {
  Red = 'Red',
  White = 'White',
}

export enum XperienceMarket {
  Seattle = 'Seattle',
  Austin = 'Austin',
  Denver = 'Denver',
  SanFrancisco = 'SanFrancisco',
  NewYork = 'NewYork',
  Virtual = 'Virtual',
}

export type DateTime = any;

export type Date = any;

export type Jsonb = any;

export type Markdown = any;

// ====================================================
// Documents
// ====================================================

export namespace AuditLogExperienceLink {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    xperience: Maybe<Xperience>;
  };

  export type Xperience = {
    __typename?: 'Xperience';

    id: string;

    name: string;

    partner: Partner;
  };

  export type Partner = {
    __typename?: 'Partner';

    id: string;

    name: string;
  };
}

export namespace AuditLogPartnerLink {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    partner: Maybe<Partner>;
  };

  export type Partner = {
    __typename?: 'Partner';

    id: string;

    name: string;
  };
}

export namespace ConversationGroup {
  export type Variables = {
    first?: Maybe<number>;
    after?: Maybe<string>;
    order: (Maybe<MessagesOrderArgs>)[];
    mysteryId: string;
  };

  export type Query = {
    __typename?: 'Query';

    adventure: Maybe<Adventure>;
  };

  export type Adventure = {
    __typename?: 'Adventure';

    id: string;

    hasUnreadMessages: boolean;

    party: Maybe<Party[]>;

    messagesConnection: Maybe<MessagesConnection>;
  };

  export type Party = ConversationUser.Fragment;

  export type MessagesConnection = {
    __typename?: 'MessageConnection';

    edges: (Maybe<Edges>)[];

    count: number;

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'MessageEdge';

    node: Node;
  };

  export type Node = Conversation.Fragment;

  export type PageInfo = {
    __typename?: 'PageInfo';

    endCursor: Maybe<string>;

    hasNextPage: boolean;
  };
}

export namespace MarkConversationRead {
  export type Variables = {
    mysteryId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    markMysteryMessagesAsRead: Maybe<MarkMysteryMessagesAsRead>;
  };

  export type MarkMysteryMessagesAsRead = {
    __typename?: 'Query';

    adventure: Maybe<Adventure>;
  };

  export type Adventure = {
    __typename?: 'Adventure';

    id: string;

    hasUnreadMessages: boolean;
  };
}

export namespace ConversationMessageInputUser {
  export type Variables = {
    phone: string;
  };

  export type Query = {
    __typename?: 'Query';

    user: Maybe<User>;
  };

  export type User = {
    __typename?: 'User';

    id: string;

    forceSms: Maybe<boolean>;
  };
}

export namespace ConversationMessageInputForceSms {
  export type Variables = {
    userId: string;
    forceSms: boolean;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    setForceSms: Maybe<SetForceSms>;
  };

  export type SetForceSms = {
    __typename?: 'User';

    id: string;

    forceSms: Maybe<boolean>;
  };
}

export namespace ConversationMessageInput {
  export type Variables = {
    content: string;
    toPhone: string;
    mysteryId?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    sendMessage: SendMessage;
  };

  export type SendMessage = ConversationMessageInput.Fragment;
}

export namespace GroupConversationMessageInput {
  export type Variables = {
    content: string;
    toPhones: string[];
    mysteryId?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    sendGroupMessage: SendGroupMessage;
  };

  export type SendGroupMessage = ConversationMessageInput.Fragment;
}

export namespace ConversationSingleUser {
  export type Variables = {
    first?: Maybe<number>;
    after?: Maybe<string>;
    order: (Maybe<MessagesOrderArgs>)[];
    userIds?: Maybe<(Maybe<string>)[]>;
  };

  export type Query = {
    __typename?: 'Query';

    users: Maybe<Users[]>;

    messagesConnection: Maybe<MessagesConnection>;
  };

  export type Users = ConversationUser.Fragment;

  export type MessagesConnection = {
    __typename?: 'MessageConnection';

    edges: (Maybe<Edges>)[];

    count: number;

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'MessageEdge';

    node: Node;
  };

  export type Node = Conversation.Fragment;

  export type PageInfo = {
    __typename?: 'PageInfo';

    endCursor: Maybe<string>;

    hasNextPage: boolean;
  };
}

export namespace AddNewCreditCardDialog {
  export type Variables = {
    info: CreditCardInput;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addCreditCard: Maybe<AddCreditCard>;
  };

  export type AddCreditCard = {
    __typename?: 'CreditCard';

    id: string;
  };
}

export namespace CreditCardOnFileSelector {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    creditCards: Maybe<(Maybe<CreditCards>)[]>;
  };

  export type CreditCards = CreditCardCell.Fragment;
}

export namespace EventDialogExperienceData {
  export type Variables = {
    experienceId: string;
  };

  export type Query = {
    __typename?: 'Query';

    xperience: Maybe<Xperience>;
  };

  export type Xperience = EventDialogExperience.Fragment;
}

export namespace EventDialogEventData {
  export type Variables = {
    eventId: string;
  };

  export type Query = {
    __typename?: 'Query';

    event: Maybe<Event>;
  };

  export type Event = EventDialogEvent.Fragment;
}

export namespace EventDialogCreateEvent {
  export type Variables = {
    experience: XperienceInput;
    schedule?: Maybe<GenericReferenceInput>;
    capacityType: CapacityType;
    totalCapacity: number;
    location: LocationInput;
    start: DateTime;
    end: DateTime;
    notes?: Maybe<string>;
    cost?: Maybe<CostInput>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createEvent: Maybe<CreateEvent>;
  };

  export type CreateEvent = EventDialogEvent.Fragment;
}

export namespace EventDialogUpdateEvent {
  export type Variables = {
    id: string;
    schedule?: Maybe<GenericReferenceInput>;
    capacityType?: Maybe<CapacityType>;
    totalCapacity?: Maybe<number>;
    location?: Maybe<LocationInput>;
    start?: Maybe<DateTime>;
    end?: Maybe<DateTime>;
    notes?: Maybe<string>;
    cost?: Maybe<CostInput>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateEvent: Maybe<UpdateEvent>;
  };

  export type UpdateEvent = EventDialogEvent.Fragment;
}

export namespace EventDialogDeleteMutation {
  export type Variables = {
    id: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteEvent: Maybe<Event>;
  };
}

export namespace RestaurantListQuery {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    deliveryMeals: DeliveryMeals[];
  };

  export type DeliveryMeals = {
    __typename?: 'DeliveryMeal';

    id: string;

    name: string;

    active: boolean;
  };
}

export namespace DeliveryMarkets {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getDeliveryMarkets: GetDeliveryMarkets[];
  };

  export type GetDeliveryMarkets = {
    __typename?: 'DeliveryMarket';

    id: string;

    name: string;
  };
}

export namespace FilterOwners {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    users: Maybe<Users[]>;
  };

  export type Users = FilterOwners.Fragment;
}

export namespace AdventureRegions {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getServiceRegions: GetServiceRegions[];
  };

  export type GetServiceRegions = {
    __typename?: 'ServiceRegion';

    id: string;

    name: RegionName;
  };
}

export namespace FilterScrapedEventCategories {
  export type Variables = {
    date: DateTime;
  };

  export type Query = {
    __typename?: 'Query';

    getAllScrapedEventCategoriesForDate: Maybe<string[]>;
  };
}

export namespace FilterScrapedEventNeighborhoods {
  export type Variables = {
    date: DateTime;
  };

  export type Query = {
    __typename?: 'Query';

    getAllScrapedEventNeighborhoodsForDate: Maybe<string[]>;
  };
}

export namespace TeamEvents {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    teamEvents: Maybe<(Maybe<TeamEvents>)[]>;
  };

  export type TeamEvents = {
    __typename?: 'TeamEvent';

    id: string;

    title: Maybe<string>;

    requestedFor: Maybe<DateTime>;

    deliveryOrders: Maybe<(Maybe<DeliveryOrders>)[]>;
  };

  export type DeliveryOrders = {
    __typename?: 'DeliveryOrder';

    id: string;
  };
}

export namespace SelectAccessibilityTypes {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    accessibilityTypes: (Maybe<AccessibilityTypes>)[];
  };

  export type AccessibilityTypes = {
    __typename?: 'AccessibilityType';

    id: string;

    name: string;
  };
}

export namespace SelectDietaryRestrictions {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    dietaryRestrictions: Maybe<(Maybe<DietaryRestrictions>)[]>;
  };

  export type DietaryRestrictions = {
    __typename?: 'DietaryRestriction';

    id: string;

    name: string;
  };
}

export namespace SelectInterests {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    interests: Interests[];
  };

  export type Interests = {
    __typename?: 'Interest';

    id: string;

    name: string;
  };
}

export namespace SelectTemplateTheme {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    mysteryThemes: Maybe<(Maybe<MysteryThemes>)[]>;
  };

  export type MysteryThemes = {
    __typename?: 'MysteryTheme';

    id: string;

    name: string;

    orderIndex: number;

    logoUrl: Maybe<string>;
  };
}

export namespace SelectXperienceCategories {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    xperienceCategories: XperienceCategories[];
  };

  export type XperienceCategories = {
    __typename?: 'XperienceCategory';

    id: string;

    name: string;
  };
}

export namespace QuestionnaireDialogCreateQuestionnaire {
  export type Variables = {
    opsPreTeamEvent?: Maybe<GenericReferenceInput>;
    teamEventReview?: Maybe<GenericReferenceInput>;
    defaultExperiencePreEventQuestionnaire?: Maybe<GenericReferenceInput>;
    boxRecipeReview?: Maybe<GenericReferenceInput>;
    defaultFor?: Maybe<DefaultQuestionnaireFor>;
    questions?: Maybe<(Maybe<QuestionInput>)[]>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createQuestionnaire: CreateQuestionnaire;
  };

  export type CreateQuestionnaire = {
    __typename?: 'Questionnaire';

    id: string;
  };
}

export namespace QuestionnaireDialogUpdateQuestionnaire {
  export type Variables = {
    questionnaireId: string;
    opsPreTeamEvent?: Maybe<GenericReferenceInput>;
    teamEventReview?: Maybe<GenericReferenceInput>;
    virtualEventReview?: Maybe<GenericReferenceInput>;
    defaultExperiencePreEventQuestionnaire?: Maybe<GenericReferenceInput>;
    boxRecipeReview?: Maybe<GenericReferenceInput>;
    questions?: Maybe<(Maybe<QuestionInput>)[]>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateQuestionnaire: UpdateQuestionnaire;
  };

  export type UpdateQuestionnaire = {
    __typename?: 'Questionnaire';

    id: string;
  };
}

export namespace QuestionnaireDialogUpdateDefaultQuestionnaire {
  export type Variables = {
    id: string;
    questions?: Maybe<(Maybe<QuestionInput>)[]>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateDefaultQuestionnaire: UpdateDefaultQuestionnaire;
  };

  export type UpdateDefaultQuestionnaire = {
    __typename?: 'DefaultQuestionnaire';

    id: string;
  };
}

export namespace DefaultQuestionnaire {
  export type Variables = {
    defaultFor?: Maybe<DefaultQuestionnaireFor>;
  };

  export type Query = {
    __typename?: 'Query';

    defaultQuestionnaire: Maybe<DefaultQuestionnaire>;
  };

  export type DefaultQuestionnaire = {
    __typename?: 'DefaultQuestionnaire';

    id: string;

    questions: Maybe<(Maybe<Questions>)[]>;
  };

  export type Questions = {
    __typename?: 'Question';

    id: string;
  } & QuestionFragment.Fragment;
}

export namespace ScheduleDialog {
  export type Variables = {
    experienceId: string;
    availabilityType: XperienceAvailabilityType;
    schedules: (Maybe<ScheduleInput>)[];
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateXperience: Maybe<UpdateXperience>;
  };

  export type UpdateXperience = {
    __typename?: 'Xperience';

    id: string;

    availabilityType: XperienceAvailabilityType;

    schedules: Maybe<(Maybe<Schedules>)[]>;
  };

  export type Schedules = ScheduleDialog.Fragment;
}

export namespace SearchBoxRecipes {
  export type Variables = {
    query: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    searchBoxRecipes: (Maybe<SearchBoxRecipes>)[];
  };

  export type SearchBoxRecipes = SearchBoxRecipes.Fragment;
}

export namespace SearchBoxTypes {
  export type Variables = {
    query: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    searchBoxTypes: (Maybe<SearchBoxTypes>)[];
  };

  export type SearchBoxTypes = SearchBoxTypes.Fragment;
}

export namespace SearchCategories {
  export type Variables = {
    query: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    searchExperienceCategories: Maybe<(Maybe<SearchExperienceCategories>)[]>;
  };

  export type SearchExperienceCategories = SearchCategories.Fragment;
}

export namespace SearchCategoriesMultiSelect {
  export type Variables = {
    query: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    searchExperienceCategories: Maybe<(Maybe<SearchExperienceCategories>)[]>;
  };

  export type SearchExperienceCategories = SearchCategoriesMultiSelect.Fragment;
}

export namespace SearchCombinedCategoriesMultiSelect {
  export type Variables = {
    query: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    searchExperienceSubCategories: Maybe<(Maybe<SearchExperienceSubCategories>)[]>;
  };

  export type SearchExperienceSubCategories = SearchCombinedCategoriesMultiSelect.Fragment;
}

export namespace SearchEvents {
  export type Variables = {
    query: string;
    startDate: DateTime;
    endDate: DateTime;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    searchEvents: Maybe<SearchEvents[]>;
  };

  export type SearchEvents = SearchEvents.Fragment;
}

export namespace SearchInventoryItems {
  export type Variables = {
    query: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    searchInventoryItems: (Maybe<SearchInventoryItems>)[];
  };

  export type SearchInventoryItems = SearchInventoryItems.Fragment;
}

export namespace OmniSearch {
  export type Variables = {
    query: string;
    entities: string[];
    fieldsToSearch: string[];
    limit?: Maybe<number>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    search: Maybe<(Maybe<Search>)[]>;
  };

  export type Search = {
    __typename?: 'SearchResult';

    id: string;

    name: string;

    description: Maybe<string>;

    entityType: string;

    parentId: Maybe<string>;
  };
}

export namespace OrganizationSearch {
  export type Variables = {
    query: string;
  };

  export type Query = {
    __typename?: 'Query';

    organizations: Maybe<Organizations[]>;
  };

  export type Organizations = {
    __typename?: 'Organization';

    id: string;

    name: string;

    internalName: Maybe<string>;
  };
}

export namespace SearchTagsMultiSelect {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    tags: Maybe<(Maybe<Tags>)[]>;
  };

  export type Tags = SearchTagsMultiSelect.Fragment;
}

export namespace SearchMysteryTemplates {
  export type Variables = {
    query: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    searchMysteryTemplates: Maybe<SearchMysteryTemplates[]>;
  };

  export type SearchMysteryTemplates = SearchMysteryTemplates.Fragment;
}

export namespace SearchUsers {
  export type Variables = {
    query: string;
    role?: Maybe<Role>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    searchUsers: Maybe<SearchUsers[]>;
  };

  export type SearchUsers = SearchUsers.Fragment;
}

export namespace SearchUsersMultiSelect {
  export type Variables = {
    query: string;
    role?: Maybe<Role>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    searchUsers: Maybe<SearchUsers[]>;
  };

  export type SearchUsers = SearchUsersMultiSelect.Fragment;
}

export namespace SearchXperiences {
  export type Variables = {
    query: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    searchXperiences: Maybe<(Maybe<SearchXperiences>)[]>;
  };

  export type SearchXperiences = SearchXperiences.Fragment;
}

export namespace HandleMysteryChanged {
  export type Variables = {
    mysteryId: string;
  };

  export type Query = {
    __typename?: 'Query';

    adventure: Maybe<Adventure>;
  };

  export type Adventure = HandleMysteryChanged.Fragment;
}

export namespace HandleMysteryMessage {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    message: Maybe<Message>;
  };

  export type Message = HandleMysteryMessage.Fragment;
}

export namespace UnreadMessagesStatusCheck {
  export type Variables = {
    first?: Maybe<number>;
    ids?: Maybe<(Maybe<string>)[]>;
  };

  export type Query = {
    __typename?: 'Query';

    mysteriesConnection: Maybe<MysteriesConnection>;
  };

  export type MysteriesConnection = {
    __typename?: 'MysteryConnection';

    edges: (Maybe<Edges>)[];
  };

  export type Edges = {
    __typename?: 'MysteryEdge';

    node: Node;
  };

  export type Node = {
    __typename?: 'Adventure';

    id: string;

    hasUnreadMessages: boolean;
  };
}

export namespace AppGate {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    viewer: Maybe<Viewer>;
  };

  export type Viewer = AppGate.Fragment;
}

export namespace AddBlackoutDate {
  export type Variables = {
    blackedOutDate: DateTime;
    mysteryType: MysteryType;
    reason?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addBlackoutDate: Maybe<AddBlackoutDate>;
  };

  export type AddBlackoutDate = {
    __typename?: 'BlackoutDate';

    id: string;

    blackedOutDate: DateTime;

    mysteryType: Maybe<MysteryType>;

    reason: Maybe<string>;
  };
}

export namespace BlackoutDates {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    blackoutDates: (Maybe<BlackoutDates>)[];
  };

  export type BlackoutDates = {
    __typename?: 'BlackoutDate';

    id: string;

    blackedOutDate: DateTime;

    mysteryType: Maybe<MysteryType>;

    reason: Maybe<string>;
  };
}

export namespace DeleteBlackoutDate {
  export type Variables = {
    id: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteBlackoutDate: Maybe<BlackoutDate>;
  };
}

export namespace NightInQuery {
  export type Variables = {
    first?: Maybe<number>;
    after?: Maybe<string>;
    search?: Maybe<string>;
    mealStatus?: Maybe<(Maybe<DeliveryStatus>)[]>;
    internalStatus?: Maybe<(Maybe<DeliveryInternalStatus>)[]>;
    methodOfDelivery?: Maybe<(Maybe<DeliveryOrderMethod>)[]>;
    mealProvider?: Maybe<(Maybe<string>)[]>;
    dateRange?: Maybe<(Maybe<DateTime>)[]>;
    market?: Maybe<string>;
    order?: Maybe<(Maybe<DeliveryOrderOrderArgs>)[]>;
    teamEvent?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    deliveryOrderConnection: Maybe<DeliveryOrderConnection>;
  };

  export type DeliveryOrderConnection = {
    __typename?: 'DeliveryOrderConnection';

    count: number;

    edges: (Maybe<Edges>)[];

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'DeliveryEdge';

    node: Node;
  };

  export type Node = NightIn.Fragment;

  export type PageInfo = {
    __typename?: 'PageInfo';

    hasNextPage: boolean;

    endCursor: Maybe<string>;
  };
}

export namespace Home {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    metabaseMetricsURL: Maybe<string>;

    viewer: Maybe<Viewer>;
  };

  export type Viewer = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;
  };
}

export namespace AddNewBoxType {
  export type Variables = {
    name: string;
    description?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createBoxType: Maybe<CreateBoxType>;
  };

  export type CreateBoxType = {
    __typename?: 'BoxType';

    id: string;

    name: string;

    description: Maybe<string>;
  };
}

export namespace AddNewInventoryItem {
  export type Variables = {
    name: string;
    description?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createInventoryItem: Maybe<CreateInventoryItem>;
  };

  export type CreateInventoryItem = {
    __typename?: 'InventoryItem';

    id: string;

    name: string;

    description: Maybe<string>;
  };
}

export namespace AddNewRecipe {
  export type Variables = {
    name: string;
    description?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createBoxRecipe: Maybe<CreateBoxRecipe>;
  };

  export type CreateBoxRecipe = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;

    description: Maybe<string>;
  };
}

export namespace EditBoxTypeMutation {
  export type Variables = {
    id: string;
    input: BoxTypeInput;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateBoxType: Maybe<UpdateBoxType>;
  };

  export type UpdateBoxType = BoxTypeProfile.Fragment;
}

export namespace EditBoxType {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    boxType: Maybe<BoxType>;
  };

  export type BoxType = BoxTypeProfile.Fragment;
}

export namespace UpdateBoxType {
  export type Variables = {
    id: string;
    isActive?: Maybe<boolean>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateBoxType: Maybe<UpdateBoxType>;
  };

  export type UpdateBoxType = {
    __typename?: 'BoxType';

    id: string;

    isActive: boolean;
  } & BoxTypeProfile.Fragment;
}

export namespace BoxTypeProfile {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    boxType: Maybe<BoxType>;
  };

  export type BoxType = BoxTypeProfile.Fragment;
}

export namespace BoxTypesQuery {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    boxTypes: BoxTypes[];
  };

  export type BoxTypes = BoxTypes.Fragment;
}

export namespace InventoryQuery {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    inventoryItems: InventoryItems[];
  };

  export type InventoryItems = Inventory.Fragment;
}

export namespace ItemProfile {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    inventoryItem: Maybe<InventoryItem>;
  };

  export type InventoryItem = ItemProfile.Fragment;
}

export namespace MobileQuantityChangeLog {
  export type Variables = {
    inventoryItemId: string;
  };

  export type Query = {
    __typename?: 'Query';

    inventoryItem: Maybe<InventoryItem>;
  };

  export type InventoryItem = {
    __typename?: 'InventoryItem';

    id: string;

    quantityChangeLogs: Maybe<QuantityChangeLogs[]>;
  };

  export type QuantityChangeLogs = {
    __typename?: 'InventoryItemQuantityChangeLog';

    id: string;

    previousQuantity: number;

    newQuantity: number;

    deltaQuantity: number;

    itemType: ItemType;

    actor: Actor;

    timestamp: DateTime;
  };

  export type ItemType = {
    __typename?: 'InventoryItem';

    id: string;

    name: string;
  };

  export type Actor = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;
  };
}

export namespace InventoryItemNameAndDescription {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    inventoryItem: Maybe<InventoryItem>;
  };

  export type InventoryItem = {
    __typename?: 'InventoryItem';

    id: string;

    isActive: boolean;

    name: string;

    description: Maybe<string>;

    category: Maybe<InventoryItemCategory>;

    costToMysteryCents: Maybe<number>;

    recipeToBuildThisItem: Maybe<RecipeToBuildThisItem>;
  };

  export type RecipeToBuildThisItem = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;
  };
}

export namespace UpdateInventoryItemNameAndDescription {
  export type Variables = {
    id: string;
    name?: Maybe<string>;
    description?: Maybe<string>;
    isActive?: Maybe<boolean>;
    category?: Maybe<InventoryItemCategory>;
    costToMysteryCents?: Maybe<number>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateInventoryItem: Maybe<UpdateInventoryItem>;
  };

  export type UpdateInventoryItem = {
    __typename?: 'InventoryItem';

    id: string;

    name: string;

    description: Maybe<string>;

    isActive: boolean;

    category: Maybe<InventoryItemCategory>;

    costToMysteryCents: Maybe<number>;
  };
}

export namespace UpdateInventoryItemQuantity {
  export type Variables = {
    id: string;
    deltaQuantity: number;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateInventoryItemQuantity: UpdateInventoryItemQuantity;
  };

  export type UpdateInventoryItemQuantity = {
    __typename?: 'InventoryItemQuantityChangeLog';

    id: string;

    deltaQuantity: number;

    previousQuantity: number;

    newQuantity: number;

    itemType: ItemType;
  };

  export type ItemType = {
    __typename?: 'InventoryItem';

    id: string;

    name: string;
  };
}

export namespace RecipesQuery {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    boxRecipes: BoxRecipes[];
  };

  export type BoxRecipes = Recipes.Fragment;
}

export namespace UpdateBoxRecipe {
  export type Variables = {
    id: string;
    isActive?: Maybe<boolean>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateBoxRecipe: Maybe<UpdateBoxRecipe>;
  };

  export type UpdateBoxRecipe = {
    __typename?: 'BoxRecipe';

    id: string;

    isActive: boolean;
  } & RecipeProfile.Fragment;
}

export namespace RecipeProfile {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    boxRecipe: Maybe<BoxRecipe>;
  };

  export type BoxRecipe = RecipeProfile.Fragment;
}

export namespace RecipeIngredientsSection {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    boxRecipe: Maybe<BoxRecipe>;
  };

  export type BoxRecipe = {
    __typename?: 'BoxRecipe';

    id: string;

    ingredients: Ingredients[];

    orders: (Maybe<Orders>)[];
  };

  export type Ingredients = {
    __typename?: 'BoxRecipeLineItem';

    id: string;

    createdAt: DateTime;
  };

  export type Orders = {
    __typename?: 'DeliveryOrder';

    id: string;
  };
}

export namespace RecipeIngredientsSectionRow {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    boxRecipeLineItem: Maybe<BoxRecipeLineItem>;
  };

  export type BoxRecipeLineItem = IngredientsSectionRow.Fragment;
}

export namespace RecipeIngredientsSectionRowUpdate {
  export type Variables = {
    recipeId: string;
    ingredientLineItem: BoxRecipeLineItemInput;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateBoxRecipe: Maybe<UpdateBoxRecipe>;
  };

  export type UpdateBoxRecipe = {
    __typename?: 'BoxRecipe';

    id: string;

    ingredients: Ingredients[];
  };

  export type Ingredients = IngredientsSectionRow.Fragment;
}

export namespace BoxRecipeNameAndDescription {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    boxRecipe: Maybe<BoxRecipe>;

    boxTypes: _BoxTypes[];
  };

  export type BoxRecipe = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;

    description: Maybe<string>;

    localDeliveryMarketRestriction: Maybe<LocalDeliveryMarket>;

    boxTypes: (Maybe<BoxTypes>)[];
  };

  export type BoxTypes = {
    __typename?: 'BoxType';

    id: string;

    name: string;
  };

  export type _BoxTypes = {
    __typename?: 'BoxType';

    id: string;

    name: string;
  };
}

export namespace UpdateBoxRecipeNameAndDescription {
  export type Variables = {
    id: string;
    name?: Maybe<string>;
    description?: Maybe<string>;
    boxTypes?: Maybe<GenericReferenceInput[]>;
    localDeliveryMarketRestriction?: Maybe<LocalDeliveryMarket>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateBoxRecipe: Maybe<UpdateBoxRecipe>;
  };

  export type UpdateBoxRecipe = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;

    description: Maybe<string>;

    localDeliveryMarketRestriction: Maybe<LocalDeliveryMarket>;

    boxTypes: (Maybe<BoxTypes>)[];
  };

  export type BoxTypes = {
    __typename?: 'BoxType';

    id: string;

    name: string;

    costToCustomerCents: Maybe<number>;
  };
}

export namespace RecipeQuantitySection {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    boxRecipe: Maybe<BoxRecipe>;
  };

  export type BoxRecipe = {
    __typename?: 'BoxRecipe';

    id: string;

    outputItem: OutputItem;

    hasEnoughInventoryToMake: Maybe<number>;

    orders: (Maybe<Orders>)[];
  };

  export type OutputItem = {
    __typename?: 'InventoryItem';

    id: string;

    quantityInStock: number;
  };

  export type Orders = {
    __typename?: 'DeliveryOrder';

    id: string;
  };
}

export namespace HandleNewMessage {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    message: Maybe<Message>;
  };

  export type Message = HandleNewMessage.Fragment;
}

export namespace MessagesList {
  export type Variables = {
    first?: Maybe<number>;
    after?: Maybe<string>;
    search?: Maybe<string>;
    order: UsersOrderArgs[];
  };

  export type Query = {
    __typename?: 'Query';

    usersConnection: Maybe<UsersConnection>;
  };

  export type UsersConnection = {
    __typename?: 'UserConnection';

    edges: (Maybe<Edges>)[];

    count: number;

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'UserEdge';

    node: Node;
  };

  export type Node = MessagesListRow.Fragment;

  export type PageInfo = {
    __typename?: 'PageInfo';

    endCursor: Maybe<string>;

    hasNextPage: boolean;
  };
}

export namespace AddOrgUsersFromCsv {
  export type Variables = {
    orgId: string;
    users: UserFromCsv[];
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addUsersFromCsv: Maybe<AddUsersFromCsv>;
  };

  export type AddUsersFromCsv = {
    __typename?: 'Organization';

    id: string;
  };
}

export namespace ContractCreate {
  export type Variables = {
    notes?: Maybe<string>;
    organization: GenericReferenceInput;
    premiumUnitCostCents?: Maybe<number>;
    premiumUnitInitialCount?: Maybe<number>;
    productType: MysteryProduct;
    renewDate: DateTime;
    startDate: DateTime;
    type: ContractUnitType;
    unitCostCents?: Maybe<number>;
    unitInitialCount: number;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createContract: CreateContract;
  };

  export type CreateContract = {
    __typename?: 'Contract';

    id: string;
  };
}

export namespace ContractUpdate {
  export type Variables = {
    id: string;
    name?: Maybe<string>;
    notes?: Maybe<string>;
    premiumUnitCount?: Maybe<number>;
    premiumUnitCostCents?: Maybe<number>;
    productType: MysteryProduct;
    renewDate: DateTime;
    startDate: DateTime;
    status: ContractStatus;
    type: ContractUnitType;
    unitCount: number;
    unitCostCents?: Maybe<number>;
    authorizedUsers?: Maybe<GenericReferenceInput[]>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateContract: UpdateContract;
  };

  export type UpdateContract = {
    __typename?: 'Contract';

    id: string;
  };
}

export namespace ContractArchive {
  export type Variables = {
    contractId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    archiveContract: ArchiveContract;
  };

  export type ArchiveContract = {
    __typename?: 'Contract';

    id: string;
  };
}

export namespace CreateNewOrganization {
  export type Variables = {
    name: string;
    members?: Maybe<ReferenceUserInput[]>;
    crmUrl?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createOrganization: CreateOrganization;
  };

  export type CreateOrganization = {
    __typename?: 'Organization';

    id: string;
  };
}

export namespace OrganizationEditor {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    organization: Maybe<Organization>;
  };

  export type Organization = {
    __typename?: 'Organization';

    id: string;

    name: string;

    internalName: Maybe<string>;

    domain: Maybe<string>;

    industry: Maybe<string>;

    description: Maybe<string>;

    engagementGraphApproved: Maybe<boolean>;

    createdAt: DateTime;

    crmUrl: Maybe<string>;

    isHrisConnected: Maybe<boolean>;

    manualIntegrationLastUpdated: Maybe<DateTime>;

    customerType: Maybe<CustomerType>;

    userSignUpPath: Maybe<string>;

    logoUrl: Maybe<string>;

    inferMembership: boolean;

    accountManager: Maybe<AccountManager>;

    salesPointOfContact: Maybe<SalesPointOfContact>;

    customerSuccessManager: Maybe<CustomerSuccessManager>;

    members: Maybe<Members[]>;

    upcomingEvents: Maybe<UpcomingEvents[]>;

    contracts: Maybe<Contracts[]>;

    isPulseBetaEnabled: Maybe<boolean>;

    isMeetupsBetaEnabled: Maybe<boolean>;
  };

  export type AccountManager = {
    __typename?: 'User';

    id: string;
  };

  export type SalesPointOfContact = {
    __typename?: 'User';

    id: string;
  };

  export type CustomerSuccessManager = {
    __typename?: 'User';

    id: string;
  };

  export type Members = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    email: Maybe<string>;

    phone: Maybe<string>;

    isServiceAccount: boolean;

    organizationRole: Maybe<OrganizationRole>;
  };

  export type OrganizationRole = {
    __typename?: 'OrganizationRole';

    id: string;

    name: OrganizationRoleName;
  };

  export type UpcomingEvents = {
    __typename?: 'TeamEvent';

    id: string;

    shortId: string;
  };

  export type Contracts = {
    __typename?: 'Contract';

    id: string;

    name: Maybe<string>;

    status: ContractStatus;

    startDate: DateTime;

    renewDate: Maybe<DateTime>;

    unitCostCents: Maybe<number>;

    unitCount: number;

    unitInitialCount: number;

    type: ContractUnitType;

    pendingUnitCount: Maybe<number>;

    pendingPremiumUnitCount: Maybe<number>;

    premiumUnitCostCents: Maybe<number>;

    premiumUnitCount: Maybe<number>;

    premiumUnitInitialCount: Maybe<number>;

    notes: Maybe<string>;

    productType: MysteryProduct;

    createdAt: DateTime;

    updatedAt: DateTime;

    auditLogs: Maybe<(Maybe<AuditLogs>)[]>;

    authorizedUsers: (Maybe<AuthorizedUsers>)[];
  };

  export type AuditLogs = AuditLogCell.Fragment;

  export type AuthorizedUsers = AuthorizedUser.Fragment;
}

export namespace OrganizationEdit {
  export type Variables = {
    id: string;
    name: string;
    internalName?: Maybe<string>;
    domain?: Maybe<string>;
    crmUrl?: Maybe<string>;
    accountManager?: Maybe<ReferenceUserInput>;
    salesPointOfContact?: Maybe<ReferenceUserInput>;
    customerSuccessManager?: Maybe<ReferenceUserInput>;
    industry?: Maybe<string>;
    description?: Maybe<string>;
    engagementGraphApproved?: Maybe<boolean>;
    customerType?: Maybe<CustomerType>;
    logoUrl?: Maybe<string>;
    userSignUpPath?: Maybe<string>;
    inferMembership?: Maybe<boolean>;
    isPulseBetaEnabled?: Maybe<boolean>;
    isMeetupsBetaEnabled?: Maybe<boolean>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateOrganization: UpdateOrganization;
  };

  export type UpdateOrganization = {
    __typename?: 'Organization';

    id: string;
  };
}

export namespace OrganizationGridQuery {
  export type Variables = {
    first?: Maybe<number>;
    after?: Maybe<string>;
    order?: Maybe<(Maybe<OrganizationOrderArgs>)[]>;
    search?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    organizationsConnection: Maybe<OrganizationsConnection>;
  };

  export type OrganizationsConnection = {
    __typename?: 'OrganizationConnection';

    count: number;

    edges: (Maybe<Edges>)[];

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'OrganizationEdge';

    node: Node;
  };

  export type Node = OrganizationGrid.Fragment;

  export type PageInfo = {
    __typename?: 'PageInfo';

    hasNextPage: boolean;

    endCursor: Maybe<string>;
  };
}

export namespace UpdateMembers {
  export type Variables = {
    orgId: string;
    addedMembers?: Maybe<ReferenceUserInput[]>;
    removedMembers?: Maybe<ReferenceUserInput[]>;
    userRoleInputs: UserOrganizationRole[];
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addOrganizationMembers: AddOrganizationMembers;

    removeOrganizationMembers: RemoveOrganizationMembers;

    updateUsersOrganizationRoles: UpdateUsersOrganizationRoles[];
  };

  export type AddOrganizationMembers = {
    __typename?: 'Organization';

    id: string;
  };

  export type RemoveOrganizationMembers = {
    __typename?: 'Organization';

    id: string;
  };

  export type UpdateUsersOrganizationRoles = {
    __typename?: 'User';

    id: string;
  };
}

export namespace Roles {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    roles: Roles[];
  };

  export type Roles = {
    __typename?: 'OrganizationRole';

    id: string;

    name: OrganizationRoleName;
  };
}

export namespace OrganizationMerge {
  export type Variables = {
    currentOrgId: string;
    targetOrgId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    mergeOrganizations: MergeOrganizations;
  };

  export type MergeOrganizations = {
    __typename?: 'Organization';

    id: string;
  };
}

export namespace QuestionnaireReview {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    questionnaireAnswers: Maybe<(Maybe<QuestionnaireAnswers>)[]>;
  };

  export type QuestionnaireAnswers = {
    __typename?: 'UserAnswers';

    user: Maybe<User>;

    answers: Maybe<(Maybe<Answers>)[]>;

    reviewed: Maybe<boolean>;
  };

  export type User = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    lastName: Maybe<string>;
  };

  export type Answers = {
    __typename?: 'Answer';

    id: string;

    question: Maybe<Question>;

    answer: Maybe<string>;
  };

  export type Question = {
    __typename?: 'Question';

    id: string;

    question: Maybe<string>;

    questionType: Maybe<QuestionType>;
  };
}

export namespace GetQuestionnaireForReview {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    questionnaire: Maybe<Questionnaire>;
  };

  export type Questionnaire = {
    __typename?: 'Questionnaire';

    questions: Maybe<(Maybe<Questions>)[]>;
  };

  export type Questions = {
    __typename?: 'Question';

    id: string;

    question: Maybe<string>;

    questionType: Maybe<QuestionType>;
  };
}

export namespace QuestionnaireReviewMutation {
  export type Variables = {
    id: string;
    users: (Maybe<string>)[];
  };

  export type Mutation = {
    __typename?: 'Mutation';

    reviewQuestionnaireUsers: ReviewQuestionnaireUsers;
  };

  export type ReviewQuestionnaireUsers = {
    __typename?: 'Questionnaire';

    id: string;
  };
}

export namespace StagingGridQuery {
  export type Variables = {
    first?: Maybe<number>;
    after?: Maybe<string>;
    order?: Maybe<(Maybe<MysteriesOrderArgs>)[]>;
    ids?: Maybe<(Maybe<string>)[]>;
    ownerIds?: Maybe<(Maybe<string>)[]>;
    status?: Maybe<(Maybe<AdventureStatus>)[]>;
    search?: Maybe<string>;
    dateRange?: Maybe<(Maybe<DateTime>)[]>;
    region?: Maybe<(Maybe<string>)[]>;
  };

  export type Query = {
    __typename?: 'Query';

    mysteriesConnection: Maybe<MysteriesConnection>;
  };

  export type MysteriesConnection = {
    __typename?: 'MysteryConnection';

    edges: (Maybe<Edges>)[];

    count: number;

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'MysteryEdge';

    node: Node;
  };

  export type Node = StagingGrid.Fragment;

  export type PageInfo = {
    __typename?: 'PageInfo';

    endCursor: Maybe<string>;

    hasNextPage: boolean;
  };
}

export namespace StagingStatusSummary {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    notPlannedStatus: Maybe<NotPlannedStatus>;

    plannedStatus: Maybe<PlannedStatus>;

    inProgressStatus: Maybe<InProgressStatus>;
  };

  export type NotPlannedStatus = {
    __typename?: 'MysteryConnection';

    count: number;
  };

  export type PlannedStatus = {
    __typename?: 'MysteryConnection';

    count: number;
  };

  export type InProgressStatus = {
    __typename?: 'MysteryConnection';

    count: number;
  };
}

export namespace Staging {
  export type Variables = {
    shortId?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    adventure: Maybe<Adventure>;
  };

  export type Adventure = Staging.Fragment;
}

export namespace ActivityFeed {
  export type Variables = {
    first?: Maybe<number>;
    after?: Maybe<string>;
    eventNames?: Maybe<(Maybe<EventName>)[]>;
    entityTypes?: Maybe<(Maybe<string>)[]>;
    actorId?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    auditLogsConnection: Maybe<AuditLogsConnection>;
  };

  export type AuditLogsConnection = {
    __typename?: 'AuditLogConnection';

    edges: (Maybe<Edges>)[];

    count: number;

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'AuditLogEdge';

    node: Node;
  };

  export type Node = {
    __typename?: 'AuditLog';

    id: string;
  } & AuditLogCell.Fragment;

  export type PageInfo = {
    __typename?: 'PageInfo';

    endCursor: Maybe<string>;

    hasNextPage: boolean;
  };
}

export namespace AddExperienceButton {
  export type Variables = {
    name: string;
    partner: GenericReferenceInput;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createXperience: Maybe<CreateXperience>;
  };

  export type CreateXperience = {
    __typename?: 'Xperience';

    id: string;
  };
}

export namespace AddPartner {
  export type Variables = {
    name: string;
    email: string;
    quality?: Maybe<PartnerQuality>;
    status?: Maybe<PartnerStatus>;
    numHosts?: Maybe<number>;
    permissionToRecord?: Maybe<boolean>;
    supportedVideoPlatforms?: Maybe<(Maybe<PartnerVideoPlatformInput>)[]>;
    location?: Maybe<LocationInput>;
    phone?: Maybe<string>;
    url?: Maybe<string>;
    notes?: Maybe<Markdown>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createPartner: CreatePartner;
  };

  export type CreatePartner = {
    __typename?: 'Partner';

    id: string;

    name: string;
  };
}

export namespace AvailabilityEditor {
  export type Variables = {
    experienceId: string;
    availabilityType: XperienceAvailabilityType;
    schedules?: Maybe<(Maybe<ScheduleInput>)[]>;
    operatingHours?: Maybe<(Maybe<OperatingHoursInput>)[]>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateXperience: Maybe<UpdateXperience>;
  };

  export type UpdateXperience = {
    __typename?: 'Xperience';

    id: string;

    availabilityType: XperienceAvailabilityType;

    schedules: Maybe<(Maybe<Schedules>)[]>;

    operatingHours: Maybe<(Maybe<OperatingHours>)[]>;
  };

  export type Schedules = {
    __typename?: 'Schedule';

    id: string;

    rrule: string;

    startTime: string;

    durationMins: number;

    capacity: Maybe<number>;
  };

  export type OperatingHours = {
    __typename?: 'OperatingHours';

    id: string;

    open: Open;

    close: Maybe<Close>;
  };

  export type Open = {
    __typename?: 'OperatingHoursTime';

    day: number;

    hours: number;

    minutes: number;

    time: string;
  };

  export type Close = {
    __typename?: 'OperatingHoursTime';

    day: number;

    hours: number;

    minutes: number;

    time: string;
  };
}

export namespace ExperienceProfile {
  export type Variables = {
    experienceId: string;
  };

  export type Query = {
    __typename?: 'Query';

    xperience: Maybe<Xperience>;
  };

  export type Xperience = ExperienceProfile.Fragment;
}

export namespace ExperienceProfileUpdate {
  export type Variables = {
    id: string;
    breakoutRoomSize?: Maybe<number>;
    cardOnFile?: Maybe<GenericReferenceInput>;
    categories?: Maybe<(Maybe<GenericReferenceInput>)[]>;
    checkInMessage?: Maybe<string>;
    checkOutMessage?: Maybe<string>;
    connectionFocusAreas?: Maybe<(Maybe<ConnectionFocusArea>)[]>;
    dayBeforeInstructions?: Maybe<Markdown>;
    description?: Maybe<string>;
    durationOptions?: Maybe<string>;
    estimatedDurationMins?: Maybe<number>;
    experienceInstructions?: Maybe<string>;
    experienceStructure?: Maybe<string>;
    friendlyTowardsAccessibilityTypes?: Maybe<(Maybe<GenericReferenceInput>)[]>;
    friendlyTowardsDietaryRestrictions?: Maybe<(Maybe<GenericReferenceInput>)[]>;
    hybridGroupInstructions?: Maybe<Markdown>;
    internationalShipping?: Maybe<XperienceInternationalShippingInput>;
    isActive?: Maybe<boolean>;
    isVirtual?: Maybe<boolean>;
    leadTimeNeededDays?: Maybe<number>;
    name?: Maybe<string>;
    notFriendlyTowardsAccessibilityTypes?: Maybe<(Maybe<GenericReferenceInput>)[]>;
    notFriendlyTowardsDietaryRestrictions?: Maybe<(Maybe<GenericReferenceInput>)[]>;
    numHosts?: Maybe<number>;
    numIdealGuests?: Maybe<number>;
    numMaxGuests?: Maybe<number>;
    numMinGuests?: Maybe<number>;
    owner?: Maybe<ReferenceUserInput>;
    ownerRoles?: Maybe<(Maybe<XperienceOwnerRole>)[]>;
    paymentMessage?: Maybe<string>;
    phone?: Maybe<string>;
    photoUrl?: Maybe<string>;
    physicalGoodsDescription?: Maybe<Markdown>;
    postEventInformation?: Maybe<Markdown>;
    priceLevel?: Maybe<number>;
    relatedInterests?: Maybe<(Maybe<GenericReferenceInput>)[]>;
    shippingCustomizations?: Maybe<Markdown>;
    supplyKitInstructions?: Maybe<string>;
    tags?: Maybe<(Maybe<TagInput>)[]>;
    templates?: Maybe<(Maybe<GenericReferenceInput>)[]>;
    url?: Maybe<string>;
    usaShipping?: Maybe<XperienceUsaShippingInput>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateXperience: Maybe<UpdateXperience>;
  };

  export type UpdateXperience = ExperienceProfile.Fragment;
}

export namespace ExperienceProfileDelete {
  export type Variables = {
    id: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteXperience: Maybe<Xperience>;
  };
}

export namespace ExperiencePricingEditor {
  export type Variables = {
    id: string;
    cost: CostInput;
    requiresUpgrade: boolean;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateXperience: Maybe<UpdateXperience>;
  };

  export type UpdateXperience = {
    __typename?: 'Xperience';

    id: string;

    requiresUpgrade: boolean;

    cost: Maybe<Cost>;
  };

  export type Cost = {
    __typename?: 'Cost';

    id: string;

    ctcCents: number;

    ctcUnit: CostUnit;

    ctmUnit: CostUnit;

    ctmCents: number;

    agreedOn: Maybe<Date>;

    ctmVolumeDiscounts: Maybe<(Maybe<CtmVolumeDiscounts>)[]>;

    ctcPricingBands: CtcPricingBands[];

    ctmTieredEventPricing: Maybe<(Maybe<CtmTieredEventPricing>)[]>;

    ctmPerPersonCentsMinimum: Maybe<number>;

    cancellationPolicy: Maybe<string>;
  };

  export type CtmVolumeDiscounts = {
    __typename?: 'VolumeDiscount';

    minNumUsers: number;

    percentDiscount: number;
  };

  export type CtcPricingBands = {
    __typename?: 'PricingBand';

    minUsers: number;

    maxUsers: Maybe<number>;

    cents: number;

    credits: Maybe<number>;

    unit: CostUnit;
  };

  export type CtmTieredEventPricing = {
    __typename?: 'TieredEventPricing';

    minNumUsers: number;

    ctmCents: number;
  };
}

export namespace DeleteEvent {
  export type Variables = {
    eventId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteEvent: Maybe<Event>;
  };
}

export namespace EventsList {
  export type Variables = {
    first?: Maybe<number>;
    after?: Maybe<string>;
    filterInput?: Maybe<EventFilterInput>;
    order?: Maybe<(Maybe<EventOrderArgs>)[]>;
  };

  export type Query = {
    __typename?: 'Query';

    eventsConnection: Maybe<EventsConnection>;
  };

  export type EventsConnection = {
    __typename?: 'EventConnection';

    edges: (Maybe<Edges>)[];

    count: number;

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'EventEdge';

    node: Node;
  };

  export type Node = {
    __typename?: 'Event';

    id: string;

    start: DateTime;

    end: DateTime;

    capacityType: CapacityType;

    remainingCapacity: number;

    totalCapacity: number;

    location: Location;

    schedule: Maybe<Schedule>;

    experience: Experience;
  };

  export type Location = {
    __typename?: 'Location';

    address: Address;
  };

  export type Address = {
    __typename?: 'Address';

    neighborhood: Maybe<string>;
  };

  export type Schedule = {
    __typename?: 'Schedule';

    id: string;

    rrule: string;
  };

  export type Experience = {
    __typename?: 'Xperience';

    id: string;

    name: string;

    partner: Partner;

    priceLevel: Maybe<number>;

    xperienceTypes: (Maybe<XperienceType>)[];

    cardOnFile: Maybe<CardOnFile>;

    attireType: Maybe<AttireType>;

    friendlyTowardsDietaryRestrictions: Maybe<(Maybe<FriendlyTowardsDietaryRestrictions>)[]>;

    notFriendlyTowardsDietaryRestrictions: Maybe<(Maybe<NotFriendlyTowardsDietaryRestrictions>)[]>;
  };

  export type Partner = {
    __typename?: 'Partner';

    id: string;

    name: string;
  };

  export type CardOnFile = {
    __typename?: 'CreditCard';

    id: string;
  };

  export type AttireType = {
    __typename?: 'AttireType';

    id: string;

    name: AttireTypeOptions;
  };

  export type FriendlyTowardsDietaryRestrictions = {
    __typename?: 'DietaryRestriction';

    id: string;

    name: string;
  };

  export type NotFriendlyTowardsDietaryRestrictions = {
    __typename?: 'DietaryRestriction';

    id: string;

    name: string;
  };

  export type PageInfo = {
    __typename?: 'PageInfo';

    endCursor: Maybe<string>;

    hasNextPage: boolean;
  };
}

export namespace ExperiencesTable {
  export type Variables = {
    first?: Maybe<number>;
    after?: Maybe<string>;
    order?: Maybe<(Maybe<XperiencesOrderArgs>)[]>;
    filterInput?: Maybe<XperienceFilterInput>;
  };

  export type Query = {
    __typename?: 'Query';

    xperiencesConnection: Maybe<XperiencesConnection>;
  };

  export type XperiencesConnection = {
    __typename?: 'XperienceConnection';

    edges: (Maybe<Edges>)[];

    count: number;

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'XperienceEdge';

    node: Node;
  };

  export type Node = {
    __typename?: 'Xperience';

    hasPhysicalGoods: boolean;

    id: string;

    isActive: boolean;

    isVirtual: boolean;

    name: string;

    numMaxGuests: Maybe<number>;

    numMinGuests: Maybe<number>;

    status: XperienceStatus;

    updatedAt: Maybe<DateTime>;

    cost: Maybe<Cost>;

    partner: Partner;

    tags: (Maybe<Tags>)[];
  };

  export type Cost = {
    __typename?: 'Cost';

    id: string;

    ctcPricingBands: CtcPricingBands[];
  };

  export type CtcPricingBands = {
    __typename?: 'PricingBand';

    cents: number;

    credits: Maybe<number>;

    maxUsers: Maybe<number>;

    minUsers: number;

    unit: CostUnit;
  };

  export type Partner = {
    __typename?: 'Partner';

    id: string;

    name: string;
  };

  export type Tags = {
    __typename?: 'Tag';

    id: string;

    name: string;
  };

  export type PageInfo = {
    __typename?: 'PageInfo';

    endCursor: Maybe<string>;

    hasNextPage: boolean;
  };
}

export namespace PartnersTable {
  export type Variables = {
    first?: Maybe<number>;
    after?: Maybe<string>;
    order?: Maybe<(Maybe<PartnerOrderArgs>)[]>;
    filterInput?: Maybe<PartnerFilterInput>;
  };

  export type Query = {
    __typename?: 'Query';

    partnersConnection: Maybe<PartnersConnection>;
  };

  export type PartnersConnection = {
    __typename?: 'PartnerConnection';

    edges: (Maybe<Edges>)[];

    count: number;

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'PartnerEdge';

    node: Node;
  };

  export type Node = {
    __typename?: 'Partner';

    id: string;

    name: string;

    status: PartnerStatus;

    quality: Maybe<PartnerQuality>;

    updatedAt: DateTime;
  };

  export type PageInfo = {
    __typename?: 'PageInfo';

    endCursor: Maybe<string>;

    hasNextPage: boolean;
  };
}

export namespace ChangesSummary {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    auditLogsConnection: Maybe<AuditLogsConnection>;
  };

  export type AuditLogsConnection = {
    __typename?: 'AuditLogConnection';

    edges: (Maybe<Edges>)[];

    count: number;

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'AuditLogEdge';

    node: Node;
  };

  export type Node = {
    __typename?: 'AuditLog';

    id: string;
  } & AuditLogCell.Fragment;

  export type PageInfo = {
    __typename?: 'PageInfo';

    endCursor: Maybe<string>;

    hasNextPage: boolean;
  };
}

export namespace StatusSummary {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    incompleteStatus: Maybe<IncompleteStatus>;

    completedNeedsReviewStatus: Maybe<CompletedNeedsReviewStatus>;

    completedReviewedStatus: Maybe<CompletedReviewedStatus>;
  };

  export type IncompleteStatus = {
    __typename?: 'XperienceConnection';

    count: number;
  };

  export type CompletedNeedsReviewStatus = {
    __typename?: 'XperienceConnection';

    count: number;
  };

  export type CompletedReviewedStatus = {
    __typename?: 'XperienceConnection';

    count: number;
  };
}

export namespace MysteryTemplatesList {
  export type Variables = {
    first?: Maybe<number>;
    after?: Maybe<string>;
    order?: Maybe<(Maybe<MysteryTemplateOrderArgs>)[]>;
    filterInput?: Maybe<TemplateFilterInput>;
  };

  export type Query = {
    __typename?: 'Query';

    mysteryTemplatesConnection: Maybe<MysteryTemplatesConnection>;
  };

  export type MysteryTemplatesConnection = {
    __typename?: 'MysteryTemplateConnection';

    edges: (Maybe<Edges>)[];

    count: number;

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'MysteryTemplateEdge';

    node: Node;
  };

  export type Node = {
    __typename?: 'MysteryTemplate';

    id: string;

    type: MysteryType;

    title: string;

    description: Maybe<string>;

    photoUrl: Maybe<string>;

    code: string;

    categories: Maybe<(Maybe<Categories>)[]>;

    connectionFocusAreas: Maybe<(Maybe<ConnectionFocusArea>)[]>;

    rangeIdealGuests: Maybe<(Maybe<number>)[]>;

    estimatedDurationMins: Maybe<number>;

    includesBreakoutRooms: Maybe<boolean>;

    cost: Maybe<Cost>;

    theme: Maybe<Theme>;
  };

  export type Categories = {
    __typename?: 'XperienceCategory';

    id: string;

    name: string;
  };

  export type Cost = {
    __typename?: 'Cost';

    id: string;

    ctcCents: number;

    ctcUnit: CostUnit;

    ctmCents: number;

    ctmUnit: CostUnit;

    ctcPricingBands: CtcPricingBands[];

    ctmTieredEventPricing: Maybe<(Maybe<CtmTieredEventPricing>)[]>;

    ctmVolumeDiscounts: Maybe<(Maybe<CtmVolumeDiscounts>)[]>;

    ctmPerPersonCentsMinimum: Maybe<number>;

    agreedOn: Maybe<Date>;

    cancellationPolicy: Maybe<string>;
  };

  export type CtcPricingBands = {
    __typename?: 'PricingBand';

    unit: CostUnit;

    cents: number;

    credits: Maybe<number>;

    minUsers: number;

    maxUsers: Maybe<number>;
  };

  export type CtmTieredEventPricing = {
    __typename?: 'TieredEventPricing';

    minNumUsers: number;

    ctmCents: number;
  };

  export type CtmVolumeDiscounts = {
    __typename?: 'VolumeDiscount';

    minNumUsers: number;

    percentDiscount: number;
  };

  export type Theme = {
    __typename?: 'MysteryTheme';

    id: string;

    title: string;
  };

  export type PageInfo = {
    __typename?: 'PageInfo';

    endCursor: Maybe<string>;

    hasNextPage: boolean;
  };
}

export namespace NewTemplate {
  export type Variables = {
    code: string;
    title: string;
    description: string;
    theme?: Maybe<GenericReferenceInput>;
    photoUrl?: Maybe<string>;
    orderIndex?: Maybe<number>;
    type?: Maybe<MysteryType>;
    cost?: Maybe<CostInput>;
    categories?: Maybe<(Maybe<GenericReferenceInput>)[]>;
    rangeIdealGuests?: Maybe<(Maybe<number>)[]>;
    estimatedDurationMins?: Maybe<number>;
    includesBreakoutRooms?: Maybe<boolean>;
    connectionFocusAreas?: Maybe<(Maybe<ConnectionFocusArea>)[]>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createMysteryTemplate: Maybe<CreateMysteryTemplate>;
  };

  export type CreateMysteryTemplate = {
    __typename?: 'MysteryTemplate';

    id: string;
  };
}

export namespace EditTemplate {
  export type Variables = {
    templateId: string;
    template: MysteryTemplateInput;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    editMysteryTemplate: Maybe<EditMysteryTemplate>;
  };

  export type EditMysteryTemplate = {
    __typename?: 'MysteryTemplate';

    id: string;
  };
}

export namespace PartnerProfile {
  export type Variables = {
    partnerId: string;
  };

  export type Query = {
    __typename?: 'Query';

    partner: Maybe<Partner>;
  };

  export type Partner = PartnerProfile.Fragment;
}

export namespace PartnerProfileUpdate {
  export type Variables = {
    id: string;
    description?: Maybe<string>;
    email?: Maybe<string>;
    location?: Maybe<LocationInput>;
    name?: Maybe<string>;
    notes?: Maybe<Markdown>;
    numHosts?: Maybe<number>;
    permissionToRecord?: Maybe<boolean>;
    phone?: Maybe<string>;
    quality?: Maybe<PartnerQuality>;
    status?: Maybe<PartnerStatus>;
    supportedVideoPlatforms?: Maybe<(Maybe<PartnerVideoPlatformInput>)[]>;
    url?: Maybe<string>;
    owners?: Maybe<(Maybe<ReferenceUserInput>)[]>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updatePartner: UpdatePartner;
  };

  export type UpdatePartner = PartnerProfile.Fragment;
}

export namespace PartnerProfileDelete {
  export type Variables = {
    id: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deletePartner: Maybe<Partner>;
  };
}

export namespace TagsList {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    tags: Maybe<(Maybe<Tags>)[]>;
  };

  export type Tags = {
    __typename?: 'Tag';

    id: string;

    name: string;

    isUsedByCode: boolean;

    experiences: Maybe<(Maybe<Experiences>)[]>;
  };

  export type Experiences = {
    __typename?: 'Xperience';

    id: string;
  };
}

export namespace DeleteTag {
  export type Variables = {
    tagId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteTag: Maybe<Query>;
  };
}

export namespace MergeTag {
  export type Variables = {
    id: string;
    into: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    mergeTag: Maybe<Query>;
  };
}

export namespace AddGuestsUpdateRequester {
  export type Variables = {
    id: string;
    requestedBy?: Maybe<GenericReferenceInput>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateTeamEvent: TeamEvent;
  };
}

export namespace AddGuestsToTeamEvent {
  export type Variables = {
    id: string;
    userId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    addUserToTeamEvent: AddUserToTeamEvent;
  };

  export type AddUserToTeamEvent = {
    __typename?: 'TeamEvent';

    party: Maybe<(Maybe<Party>)[]>;
  };

  export type Party = {
    __typename?: 'User';

    id: string;

    email: Maybe<string>;
  };
}

export namespace AddGuestsSearchBoxTypes {
  export type Variables = {
    query: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    searchBoxTypes: (Maybe<SearchBoxTypes>)[];
  };

  export type SearchBoxTypes = {
    __typename?: 'BoxType';

    id: string;

    name: string;

    boxRecipes: (Maybe<BoxRecipes>)[];
  };

  export type BoxRecipes = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;
  };
}

export namespace BulkCreateAndAddUsers {
  export type Variables = {
    id: string;
    invitees?: Maybe<(Maybe<InviteeInput>)[]>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    bulkCreateUserAndAddToGuestList: Maybe<(Maybe<BulkCreateUserAndAddToGuestList>)[]>;
  };

  export type BulkCreateUserAndAddToGuestList = {
    __typename?: 'User';

    id: string;

    email: Maybe<string>;
  };
}

export namespace BulkInviteGuests {
  export type Variables = {
    id: string;
    emails: (Maybe<string>)[];
  };

  export type Mutation = {
    __typename?: 'Mutation';

    bulkAddUsersToTeamEventGuestList: BulkAddUsersToTeamEventGuestList;
  };

  export type BulkAddUsersToTeamEventGuestList = {
    __typename?: 'TeamEvent';

    id: string;

    invitedGuests: (Maybe<InvitedGuests>)[];
  };

  export type InvitedGuests = {
    __typename?: 'InvitedGuest';

    id: string;

    email: string;
  };
}

export namespace AddGuestsSearchUsers {
  export type Variables = {
    query: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    searchUsers: Maybe<SearchUsers[]>;
  };

  export type SearchUsers = AddGuests.Fragment;
}

export namespace AddGuestsCreateUser {
  export type Variables = {
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    email?: Maybe<string>;
    phone?: Maybe<string>;
    role?: Maybe<Role>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createUser: Maybe<CreateUser>;
  };

  export type CreateUser = AddGuests.Fragment;
}

export namespace AddGuestsCreateTeamEventDeliveryOrder {
  export type Variables = {
    id: string;
    userId: string;
    location: LocationInput;
    extras?: Maybe<(Maybe<ExtraInfoInput>)[]>;
    selectedBoxType?: Maybe<GenericReferenceInput>;
    foodRestrictions?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createTeamEventDeliveryOrder: CreateTeamEventDeliveryOrder;
  };

  export type CreateTeamEventDeliveryOrder = {
    __typename?: 'TeamEvent';

    id: string;

    deliveryOrders: Maybe<(Maybe<DeliveryOrders>)[]>;
  };

  export type DeliveryOrders = {
    __typename?: 'DeliveryOrder';

    id: string;

    boxType: Maybe<BoxType>;

    boxRecipe: Maybe<BoxRecipe>;
  };

  export type BoxType = {
    __typename?: 'BoxType';

    name: string;
  };

  export type BoxRecipe = {
    __typename?: 'BoxRecipe';

    name: string;
  };
}

export namespace TeamEventVirtualEventUpdate {
  export type Variables = {
    virtualEventId: string;
    instructor?: Maybe<GenericReferenceInput>;
    durationMins?: Maybe<number>;
    requireInstructor?: Maybe<boolean>;
    joinBeforeHost?: Maybe<boolean>;
    joinEventButtonEnabledLeadTimeMins?: Maybe<number>;
    type?: Maybe<VirtualEventType>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateVirtualEvent: UpdateVirtualEvent;
  };

  export type UpdateVirtualEvent = {
    __typename?: 'VirtualEvent';

    id: string;
  };
}

export namespace TeamEventEditorUpdate {
  export type Variables = {
    id: string;
    title?: Maybe<string>;
    description?: Maybe<string>;
    expectedHeadCount?: Maybe<number>;
    organization?: Maybe<GenericReferenceInput>;
    requestedFor?: Maybe<DateTime>;
    signUpExpirationDate?: Maybe<DateTime>;
    requestedBy?: Maybe<GenericReferenceInput>;
    boxRecipes?: Maybe<(Maybe<GenericReferenceInput>)[]>;
    owner: NullableReferenceInput;
    availableBoxTypes?: Maybe<(Maybe<GenericReferenceInput>)[]>;
    boxTypeCapacityLimits?: Maybe<(Maybe<BoxTypeCapacityInput>)[]>;
    experience?: Maybe<GenericReferenceInput>;
    resendConfirmationEmail?: Maybe<boolean>;
    surpriseToAttendees?: Maybe<boolean>;
    surpriseToAll?: Maybe<boolean>;
    isRequestToBook?: Maybe<boolean>;
    finalHeadCount?: Maybe<number>;
    freeEvent?: Maybe<boolean>;
    manualBilling?: Maybe<boolean>;
    participantFamiliarity?: Maybe<number>;
    participationModel?: Maybe<ParticipationModel>;
    purpose?: Maybe<string>;
    goals?: Maybe<GenericReferenceInput[]>;
    testEventType?: Maybe<TestEventType>;
    instructorChanged?: Maybe<boolean>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateTeamEvent: UpdateTeamEvent;

    updateTeamEventOwner: UpdateTeamEventOwner;
  };

  export type UpdateTeamEvent = {
    __typename?: 'TeamEvent';

    id: string;

    experience: Maybe<Experience>;
  };

  export type Experience = {
    __typename?: 'Xperience';

    id: string;

    name: string;
  };

  export type UpdateTeamEventOwner = {
    __typename?: 'TeamEvent';

    id: string;

    availableBoxTypesWithCapacityQuantities: Maybe<
      (Maybe<AvailableBoxTypesWithCapacityQuantities>)[]
    >;
  };

  export type AvailableBoxTypesWithCapacityQuantities = {
    __typename?: 'BoxTypeWithAvailableCapacity';

    boxType: BoxType;

    totalOrdered: number;

    capacityLimit: Maybe<number>;
  };

  export type BoxType = {
    __typename?: 'BoxType';

    id: string;

    name: string;
  };
}

export namespace TeamEventEditorResendLoginEmail {
  export type Variables = {
    zoomMeetingId: string;
    type?: Maybe<ResendOption>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    resendZoomLoginEmail: ResendZoomLoginEmail;
  };

  export type ResendZoomLoginEmail = {
    __typename?: 'ZoomMeeting';

    id: string;
  };
}

export namespace TeamEventEditorCancel {
  export type Variables = {
    id: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    cancelTeamEvent: Maybe<CancelTeamEvent>;
  };

  export type CancelTeamEvent = {
    __typename?: 'TeamEvent';

    id: string;
  };
}

export namespace VirtualEventInstructors {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    users: Maybe<Users[]>;
  };

  export type Users = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    firstName: Maybe<string>;

    lastName: Maybe<string>;
  };
}

export namespace GetInvitedGuests {
  export type Variables = {
    teamEventId: string;
  };

  export type Query = {
    __typename?: 'Query';

    teamEvent: Maybe<TeamEvent>;
  };

  export type TeamEvent = {
    __typename?: 'TeamEvent';

    id: string;

    invitedGuests: (Maybe<InvitedGuests>)[];
  };

  export type InvitedGuests = {
    __typename?: 'InvitedGuest';

    id: string;

    user: Maybe<User>;

    email: string;

    rsvpStatus: Maybe<InvitedGuestRsvpStatus>;
  };

  export type User = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;
  };
}

export namespace RemoveUserFromTeamEvent {
  export type Variables = {
    teamEventId: string;
    email: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    removeUserFromTeamEventGuestList: RemoveUserFromTeamEventGuestList;
  };

  export type RemoveUserFromTeamEventGuestList = {
    __typename?: 'TeamEvent';

    id: string;

    invitedGuests: (Maybe<InvitedGuests>)[];
  };

  export type InvitedGuests = {
    __typename?: 'InvitedGuest';

    id: string;
  };
}

export namespace TeamEventEditor {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    teamEvent: Maybe<TeamEvent>;
  };

  export type TeamEvent = {
    __typename?: 'TeamEvent';

    id: string;

    title: Maybe<string>;

    shortId: string;

    description: Maybe<string>;

    expectedHeadCount: Maybe<number>;

    finalHeadCount: Maybe<number>;

    joinLink: Maybe<string>;

    includeDelivery: Maybe<boolean>;

    surpriseToAttendees: Maybe<boolean>;

    surpriseToAll: Maybe<boolean>;

    freeEvent: Maybe<boolean>;

    manualBilling: Maybe<boolean>;

    isRequestToBook: Maybe<boolean>;

    status: Maybe<TeamEventStatus>;

    testEventType: Maybe<TestEventType>;

    invitedGuests: (Maybe<InvitedGuests>)[];

    initialPrice: Maybe<InitialPrice>;

    finalizedPrice: Maybe<FinalizedPrice>;

    hubspotDealId: Maybe<string>;

    deliveryOrders: Maybe<(Maybe<DeliveryOrders>)[]>;

    availableBoxTypes: Maybe<(Maybe<AvailableBoxTypes>)[]>;

    availableBoxTypesWithCapacityQuantities: Maybe<
      (Maybe<AvailableBoxTypesWithCapacityQuantities>)[]
    >;

    experience: Maybe<Experience>;

    virtualEvent: Maybe<VirtualEvent>;

    requestedFor: Maybe<DateTime>;

    signUpExpirationDate: Maybe<DateTime>;

    creator: Maybe<Creator>;

    organization: Maybe<Organization>;

    owner: Maybe<Owner>;

    party: Maybe<(Maybe<Party>)[]>;

    requestedBy: Maybe<RequestedBy>;

    boxRecipes: Maybe<(Maybe<_BoxRecipes>)[]>;

    reviewQuestionnaire: _ReviewQuestionnaire;

    opsPreEventQuestionnaire: Maybe<OpsPreEventQuestionnaire>;

    preEventQuestionnaire: Maybe<PreEventQuestionnaire>;

    latestBookingSession: Maybe<LatestBookingSession>;

    participantFamiliarity: Maybe<number>;

    participationModel: Maybe<ParticipationModel>;

    purpose: Maybe<string>;

    goals: Maybe<(Maybe<Goals>)[]>;
  };

  export type InvitedGuests = {
    __typename?: 'InvitedGuest';

    email: string;

    rsvpStatus: Maybe<InvitedGuestRsvpStatus>;

    user: Maybe<User>;
  };

  export type User = {
    __typename?: 'User';

    firstName: Maybe<string>;

    lastName: Maybe<string>;
  };

  export type InitialPrice = {
    __typename?: 'Price';

    id: string;

    paidAt: Maybe<DateTime>;

    totalCents: Maybe<number>;

    lineItems: Maybe<(Maybe<LineItems>)[]>;
  };

  export type LineItems = {
    __typename?: 'PriceLineItem';

    id: string;

    category: LineItemCategory;

    contractUnits: Maybe<number>;

    contractPremiumUnits: Maybe<number>;
  };

  export type FinalizedPrice = {
    __typename?: 'Price';

    id: string;

    stripeChargeId: Maybe<string>;
  };

  export type DeliveryOrders = {
    __typename?: 'DeliveryOrder';

    id: string;
  };

  export type AvailableBoxTypes = {
    __typename?: 'BoxType';

    id: string;

    name: string;

    boxRecipes: (Maybe<BoxRecipes>)[];
  };

  export type BoxRecipes = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;
  };

  export type AvailableBoxTypesWithCapacityQuantities = {
    __typename?: 'BoxTypeWithAvailableCapacity';

    boxType: BoxType;

    totalOrdered: number;

    capacityLimit: Maybe<number>;
  };

  export type BoxType = {
    __typename?: 'BoxType';

    id: string;

    name: string;
  };

  export type Experience = {
    __typename?: 'Xperience';

    id: string;

    name: string;
  };

  export type VirtualEvent = {
    __typename?: 'VirtualEvent';

    id: string;

    type: Maybe<VirtualEventType>;

    attendees: Maybe<string[]>;

    durationMins: Maybe<number>;

    zoomMeeting: Maybe<ZoomMeeting>;

    requireInstructor: Maybe<boolean>;

    instructor: Maybe<Instructor>;

    joinBeforeHost: Maybe<boolean>;

    joinEventButtonEnabledLeadTimeMins: Maybe<number>;

    reviewQuestionnaire: Maybe<ReviewQuestionnaire>;
  };

  export type ZoomMeeting = {
    __typename?: 'ZoomMeeting';

    id: string;

    host: Maybe<Host>;

    joinUrl: Maybe<string>;

    startUrl: Maybe<string>;

    hostJoinedAt: Maybe<DateTime>;
  };

  export type Host = {
    __typename?: 'ZoomHost';

    id: string;

    email: Maybe<string>;
  };

  export type Instructor = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    phone: Maybe<string>;

    email: Maybe<string>;

    role: Role;
  };

  export type ReviewQuestionnaire = {
    __typename?: 'Questionnaire';

    id: string;

    questions: Maybe<(Maybe<Questions>)[]>;
  };

  export type Questions = QuestionFragment.Fragment;

  export type Creator = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    lastName: Maybe<string>;
  };

  export type Organization = {
    __typename?: 'Organization';

    id: string;

    name: string;

    internalName: Maybe<string>;
  };

  export type Owner = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    firstName: Maybe<string>;

    lastName: Maybe<string>;
  };

  export type Party = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    email: Maybe<string>;
  };

  export type RequestedBy = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    phone: Maybe<string>;

    email: Maybe<string>;

    role: Role;
  };

  export type _BoxRecipes = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;
  };

  export type _ReviewQuestionnaire = {
    __typename?: 'Questionnaire';

    id: string;

    questions: Maybe<(Maybe<_Questions>)[]>;
  };

  export type _Questions = QuestionFragment.Fragment;

  export type OpsPreEventQuestionnaire = {
    __typename?: 'Questionnaire';

    id: string;

    questions: Maybe<(Maybe<__Questions>)[]>;
  };

  export type __Questions = {
    __typename?: 'Question';

    answers: Maybe<(Maybe<Answers>)[]>;
  } & QuestionFragment.Fragment;

  export type Answers = {
    __typename?: 'Answer';

    id: string;
  };

  export type PreEventQuestionnaire = {
    __typename?: 'Questionnaire';

    id: string;
  };

  export type LatestBookingSession = {
    __typename?: 'BookingSession';

    id: string;

    guestEmails: Maybe<(Maybe<string>)[]>;

    selectedEventDetails: Maybe<(Maybe<string>)[]>;
  };

  export type Goals = {
    __typename?: 'TeamEventGoal';

    id: string;
  };
}

export namespace CostToMysteryBillingMutation {
  export type Variables = {
    id: string;
    freeEvent?: Maybe<boolean>;
    manualBilling?: Maybe<boolean>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateTeamEvent: UpdateTeamEvent;
  };

  export type UpdateTeamEvent = {
    __typename?: 'TeamEvent';

    id: string;

    freeEvent: Maybe<boolean>;

    manualBilling: Maybe<boolean>;
  };
}

export namespace CostToMysteryAddAdjustment {
  export type Variables = {
    teamEventId: string;
    ownerType: PayoutOwnerType;
    ownerToken: string;
    reasonToken: string;
    unitType: UnitType;
    unitToken: string;
    unitCount: number;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createOpsAdjustmentForTeamEvent: Maybe<CreateOpsAdjustmentForTeamEvent>;
  };

  export type CreateOpsAdjustmentForTeamEvent = {
    __typename?: 'Payout';

    id: string;
  };
}

export namespace CostToMysteryRemoveAdjustment {
  export type Variables = {
    payoutIds: string[];
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteOpsAdjustmentsForTeamEvent: Maybe<(Maybe<DeleteOpsAdjustmentsForTeamEvent>)[]>;
  };

  export type DeleteOpsAdjustmentsForTeamEvent = {
    __typename?: 'Payout';

    id: string;
  };
}

export namespace CostToMysteryAddContractCharge {
  export type Variables = {
    contractChargeInputs: CreateContractChargeInput[];
  };

  export type Mutation = {
    __typename?: 'Mutation';

    generateContractChargesForTeamEvent: GenerateContractChargesForTeamEvent[];
  };

  export type GenerateContractChargesForTeamEvent = {
    __typename?: 'Price';

    id: string;
  };
}

export namespace CostToMysteryDisassociatePrices {
  export type Variables = {
    priceInputs: DisassociatePricesInput[];
  };

  export type Mutation = {
    __typename?: 'Mutation';

    disassociatePrices: DisassociatePrices[];
  };

  export type DisassociatePrices = {
    __typename?: 'Price';

    id: string;
  };
}

export namespace CostToMysteryAbandonPrices {
  export type Variables = {
    priceIds: string[];
  };

  export type Mutation = {
    __typename?: 'Mutation';

    abandonPrices: AbandonPrices[];
  };

  export type AbandonPrices = {
    __typename?: 'Price';

    id: string;
  };
}

export namespace CostToMysteryPriceFromStripeCharge {
  export type Variables = {
    teamEventId: string;
    stripeChargeId?: Maybe<string>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    generatePriceFromStripeCharge: GeneratePriceFromStripeCharge;
  };

  export type GeneratePriceFromStripeCharge = {
    __typename?: 'Price';

    id: string;
  };
}

export namespace CostToMysteryTab {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    teamEvent: Maybe<TeamEvent>;
  };

  export type TeamEvent = {
    __typename?: 'TeamEvent';

    id: string;

    finalHeadCount: Maybe<number>;

    expectedHeadCount: Maybe<number>;

    manualBilling: Maybe<boolean>;

    freeEvent: Maybe<boolean>;

    status: Maybe<TeamEventStatus>;

    experience: Maybe<Experience>;

    costs: Maybe<Costs>;

    prices: Maybe<(Maybe<Prices>)[]>;

    payouts: Payouts[];

    contract: Maybe<_Contract>;

    organization: Maybe<Organization>;
  };

  export type Experience = {
    __typename?: 'Xperience';

    id: string;

    partner: Partner;
  };

  export type Partner = {
    __typename?: 'Partner';

    id: string;
  };

  export type Costs = {
    __typename?: 'EventCosts';

    costToCustomerCents: number;

    costToMysteryCents: number;

    netGainCents: number;
  };

  export type Prices = {
    __typename?: 'Price';

    id: string;

    paidAt: Maybe<DateTime>;

    totalCents: Maybe<number>;

    stripeChargeId: Maybe<string>;

    priceType: Maybe<PriceType>;

    systemGenerated: Maybe<boolean>;

    status: PriceStatus;

    createdAt: DateTime;

    lineItems: Maybe<(Maybe<LineItems>)[]>;
  };

  export type LineItems = {
    __typename?: 'PriceLineItem';

    id: string;

    category: LineItemCategory;

    contractUnits: Maybe<number>;

    contractPremiumUnits: Maybe<number>;

    contract: Maybe<Contract>;
  };

  export type Contract = {
    __typename?: 'Contract';

    unitCostCents: Maybe<number>;

    premiumUnitCostCents: Maybe<number>;

    type: ContractUnitType;
  };

  export type Payouts = {
    __typename?: 'Payout';

    id: string;

    systemGenerated: boolean;

    estimate: boolean;

    createdAt: Date;

    amount: Amount;

    createdBy: Maybe<CreatedBy>;

    reason: Reason;

    owner: Owner;
  };

  export type Amount = {
    __typename?: 'Amount';

    unitType: UnitType;

    unitToken: string;

    unitCount: number;
  };

  export type CreatedBy = {
    __typename?: 'User';

    id: string;
  };

  export type Reason = {
    __typename?: 'PayoutReason';

    reasonType: PayoutReasonType;

    reasonToken: string;
  };

  export type Owner = {
    __typename?: 'PayoutOwner';

    ownerType: PayoutOwnerType;

    ownerToken: string;
  };

  export type _Contract = {
    __typename?: 'Contract';

    id: string;

    name: Maybe<string>;

    status: ContractStatus;

    type: ContractUnitType;

    premiumUnitCount: Maybe<number>;

    unitCount: number;
  };

  export type Organization = {
    __typename?: 'Organization';

    id: string;

    activeContract: Maybe<ActiveContract>;
  };

  export type ActiveContract = {
    __typename?: 'Contract';

    id: string;

    type: ContractUnitType;

    premiumUnitCount: Maybe<number>;

    unitCount: number;
  };
}

export namespace TeamEventIssueCreateIssue {
  export type Variables = {
    categories: TeamEventIssueCategory[];
    note?: Maybe<string>;
    teamEventId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createTeamEventIssue: CreateTeamEventIssue;
  };

  export type CreateTeamEventIssue = {
    __typename?: 'TeamEventIssue';

    id: string;

    categories: TeamEventIssueCategory[];

    note: Maybe<string>;
  };
}

export namespace TeamEventIssueUpdateIssue {
  export type Variables = {
    categories: TeamEventIssueCategory[];
    note?: Maybe<string>;
    id: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateTeamEventIssue: UpdateTeamEventIssue;
  };

  export type UpdateTeamEventIssue = {
    __typename?: 'TeamEventIssue';

    id: string;

    categories: TeamEventIssueCategory[];

    note: Maybe<string>;
  };
}

export namespace IssueOnEventTab {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    teamEvent: Maybe<TeamEvent>;
  };

  export type TeamEvent = {
    __typename?: 'TeamEvent';

    id: string;

    teamEventIssues: Maybe<(Maybe<TeamEventIssues>)[]>;
  };

  export type TeamEventIssues = {
    __typename?: 'TeamEventIssue';

    id: string;

    categories: TeamEventIssueCategory[];

    note: Maybe<string>;
  };
}

export namespace SaveRecreatedZoomIsvMeeting {
  export type Variables = {
    virtualEventId: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createZoomMeeting: CreateZoomMeeting;
  };

  export type CreateZoomMeeting = {
    __typename?: 'ZoomMeeting';

    id: string;
  };
}

export namespace TeamEventBoxTypes {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    activeBoxTypes: ActiveBoxTypes[];

    viewer: Maybe<Viewer>;
  };

  export type ActiveBoxTypes = {
    __typename?: 'BoxType';

    id: string;

    name: string;

    boxRecipes: (Maybe<BoxRecipes>)[];
  };

  export type BoxRecipes = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;
  };

  export type Viewer = {
    __typename?: 'User';

    id: string;

    email: Maybe<string>;
  };
}

export namespace TeamEventGridQuery {
  export type Variables = {
    first?: Maybe<number>;
    after?: Maybe<string>;
    order?: Maybe<(Maybe<TeamEventOrderArgs>)[]>;
    ids?: Maybe<(Maybe<string>)[]>;
    ownerIds?: Maybe<(Maybe<string>)[]>;
    search?: Maybe<string>;
    dateRange?: Maybe<(Maybe<DateTime>)[]>;
    teamEventStatus?: Maybe<(Maybe<TeamEventStatus>)[]>;
    organizationId?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    teamEventsConnection: Maybe<TeamEventsConnection>;
  };

  export type TeamEventsConnection = {
    __typename?: 'TeamEventConnection';

    count: number;

    edges: (Maybe<Edges>)[];

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'TeamEventEdge';

    node: Node;
  };

  export type Node = TeamEventGrid.Fragment;

  export type PageInfo = {
    __typename?: 'PageInfo';

    hasNextPage: boolean;

    endCursor: Maybe<string>;
  };
}

export namespace TeamEventCreateEvent {
  export type Variables = {
    requestedFor: DateTime;
    availableBoxTypes?: Maybe<(Maybe<GenericReferenceInput>)[]>;
    includeDelivery: boolean;
    includeVirtualEvent: boolean;
    requireInstructor?: Maybe<boolean>;
    testEventType?: Maybe<TestEventType>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createTeamEvent: CreateTeamEvent;
  };

  export type CreateTeamEvent = {
    __typename?: 'TeamEvent';

    id: string;
  };
}

export namespace TeamEventGoals {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    teamEventGoals: Maybe<TeamEventGoals[]>;
  };

  export type TeamEventGoals = {
    __typename?: 'TeamEventGoal';

    id: string;

    goal: EventGoal;
  };
}

export namespace HandleZoomMeetingUpdateQuery {
  export type Variables = {
    id: string;
  };

  export type Query = {
    __typename?: 'Query';

    zoomMeeting: Maybe<ZoomMeeting>;
  };

  export type ZoomMeeting = {
    __typename?: 'ZoomMeeting';

    id: string;

    hostJoinedAt: Maybe<DateTime>;
  };
}

export namespace UsersDetails {
  export type Variables = {
    userId: string;
  };

  export type Query = {
    __typename?: 'Query';

    user: Maybe<User>;
  };

  export type User = UsersDetails.Fragment & UsersTabsDetails.Fragment;
}

export namespace CreateUser {
  export type Variables = {
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    email?: Maybe<string>;
    phone?: Maybe<string>;
    role?: Maybe<Role>;
    isServiceAccount?: Maybe<boolean>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createUser: Maybe<CreateUser>;
  };

  export type CreateUser = EditUserModal.Fragment;
}

export namespace UpdateUser {
  export type Variables = {
    id: string;
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    email?: Maybe<string>;
    phone?: Maybe<string>;
    role?: Maybe<Role>;
    internalSlackId?: Maybe<string>;
    isServiceAccount?: Maybe<boolean>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateUser: Maybe<UpdateUser>;
  };

  export type UpdateUser = EditUserModal.Fragment;
}

export namespace UsersList {
  export type Variables = {
    first?: Maybe<number>;
    after?: Maybe<string>;
    order?: Maybe<(Maybe<UsersOrderArgs>)[]>;
    search?: Maybe<string>;
    showServiceAccounts?: Maybe<boolean>;
  };

  export type Query = {
    __typename?: 'Query';

    usersConnection: Maybe<UsersConnection>;
  };

  export type UsersConnection = {
    __typename?: 'UserConnection';

    edges: (Maybe<Edges>)[];

    count: number;

    pageInfo: PageInfo;
  };

  export type Edges = {
    __typename?: 'UserEdge';

    node: Node;
  };

  export type Node = UsersListRow.Fragment;

  export type PageInfo = {
    __typename?: 'PageInfo';

    endCursor: Maybe<string>;

    hasNextPage: boolean;
  };
}

export namespace DeleteUser {
  export type Variables = {
    id: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteUser: Maybe<boolean>;
  };
}

export namespace AuditLogCell {
  export type Fragment = {
    __typename?: 'AuditLog';

    id: string;

    metadata: Maybe<Metadata>;

    occurredAt: DateTime;

    actor: Maybe<Actor>;

    entityType: Maybe<string>;

    entityId: Maybe<string>;

    eventName: EventName;
  };

  export type Metadata = {
    __typename?: 'AuditLogMetadata';

    before: Maybe<Jsonb>;

    after: Maybe<Jsonb>;
  };

  export type Actor = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;
  };
}

export namespace ConversationUser {
  export type Fragment = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    phone: Maybe<string>;
  };
}

export namespace Conversation {
  export type Fragment = ConversationMessage.Fragment;
}

export namespace ConversationMessageAttachments {
  export type Fragment = {
    __typename?: 'Message';

    attachments: Maybe<string[]>;
  };
}

export namespace ConversationMessageAuthorAndTime {
  export type Fragment = {
    __typename?: 'Message';

    type: MessageType;

    createdAt: DateTime;

    user: User;

    opsUser: Maybe<OpsUser>;
  };

  export type User = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;
  };

  export type OpsUser = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;
  };
}

export namespace ConversationMessage {
  export type Fragment = {
    __typename?: 'Message';

    id: string;

    type: MessageType;

    readAt: Maybe<DateTime>;

    adventure: Maybe<Adventure>;

    user: User;

    opsUser: Maybe<OpsUser>;
  } & (ConversationMessageWithNewlines.Fragment & ConversationMessageAuthorAndTime.Fragment);

  export type Adventure = {
    __typename?: 'Adventure';

    id: string;

    hasUnreadMessages: boolean;
  };

  export type User = {
    __typename?: 'User';

    id: string;

    phone: Maybe<string>;
  };

  export type OpsUser = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;
  };
}

export namespace ConversationMessageInput {
  export type Fragment = {
    __typename?: 'Message';

    id: string;

    content: string;

    type: MessageType;

    user: User;

    createdAt: DateTime;

    readAt: Maybe<DateTime>;
  };

  export type User = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    mostRecentMessage: Maybe<MostRecentMessage>;
  };

  export type MostRecentMessage = {
    __typename?: 'Message';

    id: string;

    createdAt: DateTime;

    content: string;
  };
}

export namespace ConversationMessageWithNewlines {
  export type Fragment = {
    __typename?: 'Message';

    id: string;

    type: MessageType;

    content: string;
  } & ConversationMessageAttachments.Fragment;
}

export namespace CreditCardCell {
  export type Fragment = {
    __typename?: 'CreditCard';

    id: string;

    last4: string;

    expMonth: number;

    expYear: number;
  };
}

export namespace EventDialogExperience {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    name: string;

    availabilityType: XperienceAvailabilityType;

    schedules: Maybe<(Maybe<Schedules>)[]>;

    location: Maybe<Location>;
  };

  export type Schedules = ScheduleItemCell.Fragment;

  export type Location = {
    __typename?: 'Location';

    id: string;
  };
}

export namespace EventDialogEvent {
  export type Fragment = {
    __typename?: 'Event';

    id: string;

    start: DateTime;

    end: DateTime;

    capacityType: CapacityType;

    totalCapacity: number;

    notes: Maybe<string>;

    schedule: Maybe<Schedule>;

    experience: Experience;

    adventureStops: Maybe<(Maybe<AdventureStops>)[]>;
  };

  export type Schedule = ScheduleItemCell.Fragment;

  export type Experience = EventDialogExperience.Fragment;

  export type AdventureStops = {
    __typename?: 'AdventureStop';

    id: string;

    adventure: Adventure;
  };

  export type Adventure = {
    __typename?: 'Adventure';

    id: string;

    shortId: string;

    party: Maybe<Party[]>;
  };

  export type Party = {
    __typename?: 'User';

    id: string;
  };
}

export namespace ExperienceStatus {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    status: XperienceStatus;

    latestChangesHaveBeenApproved: boolean;

    latestApproval: Maybe<LatestApproval>;

    missingRequiredFields: Maybe<(Maybe<string>)[]>;
  };

  export type LatestApproval = {
    __typename?: 'Approval';

    id: string;

    occurredAt: DateTime;

    actor: Actor;
  };

  export type Actor = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;
  };
}

export namespace FilterOwners {
  export type Fragment = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;
  };
}

export namespace FilterScrapedEventCategories {
  export type Fragment = {
    __typename?: 'Adventure';

    id: string;
  };
}

export namespace FilterScrapedEventNeighborhoods {
  export type Fragment = {
    __typename?: 'Adventure';

    id: string;
  };
}

export namespace EditQuestionnaireButton {
  export type Fragment = QuestionFragment.Fragment;
}

export namespace QuestionFragment {
  export type Fragment = {
    __typename?: 'Question';

    id: string;

    options: Maybe<(Maybe<string>)[]>;

    scaleMin: Maybe<number>;

    scaleMax: Maybe<number>;

    orderIndex: Maybe<number>;

    question: Maybe<string>;

    questionType: Maybe<QuestionType>;

    required: boolean;

    requesterOnly: Maybe<boolean>;
  };
}

export namespace ScheduleDialog {
  export type Fragment = {
    __typename?: 'Schedule';

    id: string;

    rrule: string;

    startTime: string;

    durationMins: number;

    capacity: Maybe<number>;
  };
}

export namespace ScheduleItemCell {
  export type Fragment = {
    __typename?: 'Schedule';

    id: string;

    rrule: string;

    durationMins: number;

    startTime: string;

    capacity: Maybe<number>;

    futureEvents: Maybe<(Maybe<FutureEvents>)[]>;
  };

  export type FutureEvents = {
    __typename?: 'Event';

    id: string;
  };
}

export namespace SearchBoxRecipes {
  export type Fragment = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;

    description: Maybe<string>;
  };
}

export namespace SearchBoxTypes {
  export type Fragment = {
    __typename?: 'BoxType';

    id: string;

    name: string;

    description: Maybe<string>;
  };
}

export namespace SearchCategories {
  export type Fragment = {
    __typename?: 'ExperienceCategory';

    id: string;

    name: string;

    type: ExperienceType;
  };
}

export namespace SearchCategoriesMultiSelect {
  export type Fragment = {
    __typename?: 'ExperienceCategory';

    id: string;

    name: string;

    type: ExperienceType;
  };
}

export namespace SearchCombinedCategoriesMultiSelect {
  export type Fragment = {
    __typename?: 'ExperienceSubCategory';

    id: string;

    name: string;

    category: Category;
  };

  export type Category = {
    __typename?: 'ExperienceCategory';

    id: string;

    name: string;

    type: ExperienceType;
  };
}

export namespace SearchEvents {
  export type Fragment = {
    __typename?: 'Event';

    id: string;

    start: DateTime;

    end: DateTime;

    totalCapacity: number;

    remainingCapacity: number;

    capacityType: CapacityType;

    location: Location;

    experience: Experience;
  };

  export type Location = {
    __typename?: 'Location';

    id: string;

    placeId: Maybe<string>;

    address: Address;
  };

  export type Address = {
    __typename?: 'Address';

    full: string;

    neighborhood: Maybe<string>;
  };

  export type Experience = {
    __typename?: 'Xperience';

    id: string;

    name: string;

    checkInMessage: Maybe<string>;

    checkOutMessage: Maybe<string>;

    paymentMessage: Maybe<string>;

    partner: Partner;
  };

  export type Partner = {
    __typename?: 'Partner';

    id: string;

    name: string;
  };
}

export namespace SearchInventoryItems {
  export type Fragment = {
    __typename?: 'InventoryItem';

    id: string;

    name: string;

    description: Maybe<string>;
  };
}

export namespace SearchTagsMultiSelect {
  export type Fragment = {
    __typename?: 'Tag';

    id: string;

    name: string;
  };
}

export namespace SearchMysteryTemplates {
  export type Fragment = {
    __typename?: 'MysteryTemplate';

    id: string;

    title: string;
  };
}

export namespace SearchUsers {
  export type Fragment = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    name: Maybe<string>;

    phone: Maybe<string>;

    email: Maybe<string>;

    role: Role;

    orgs: (Maybe<Orgs>)[];
  };

  export type Orgs = {
    __typename?: 'Organization';

    id: string;

    name: string;
  };
}

export namespace SearchUsersMultiSelect {
  export type Fragment = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    name: Maybe<string>;
  };
}

export namespace SearchXperiences {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    isVirtual: boolean;

    name: string;

    description: Maybe<string>;
  };
}

export namespace HandleMysteryChangedLocation {
  export type Fragment = {
    __typename?: 'Location';

    id: string;

    placeId: Maybe<string>;

    coordinates: Coordinates;

    address: Address;
  };

  export type Coordinates = {
    __typename?: 'Coordinates';

    latitude: number;

    longitude: number;
  };

  export type Address = {
    __typename?: 'Address';

    full: string;

    neighborhood: Maybe<string>;
  };
}

export namespace HandleMysteryChanged {
  export type Fragment = {
    __typename?: 'Adventure';

    id: string;

    status: AdventureStatus;

    shortId: string;

    timezone: string;

    requestedFor: Maybe<DateTime>;

    hasUnreadMessages: boolean;

    isCanceled: boolean;

    startsAt: DateTime;

    pickupLocation: PickupLocation;

    party: Maybe<Party[]>;

    stops: Maybe<Stops[]>;
  };

  export type PickupLocation = HandleMysteryChangedLocation.Fragment;

  export type Party = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    name: Maybe<string>;

    phone: Maybe<string>;
  };

  export type Stops = {
    __typename?: 'AdventureStop';

    id: string;

    startTime: DateTime;

    pickupTime: DateTime;

    endTime: DateTime;

    status: Maybe<StopStatus>;

    isFlexRide: Maybe<boolean>;

    skipRide: Maybe<boolean>;

    checkInDetails: Maybe<string>;

    checkOutDetails: Maybe<string>;

    paymentDetails: Maybe<string>;

    progress: Maybe<(Maybe<Progress>)[]>;

    experience: Maybe<Experience>;

    ride: Maybe<Ride>;
  };

  export type Progress = {
    __typename?: 'AdventureProgress';

    id: string;

    type: ProgressType;

    occurredAt: DateTime;
  };

  export type Experience = {
    __typename?: 'Experience';

    id: string;

    name: string;

    location: Location;
  };

  export type Location = HandleMysteryChangedLocation.Fragment;

  export type Ride = {
    __typename?: 'Ride';

    id: string;

    status: Maybe<RideStatus>;

    flexRideCode: Maybe<string>;

    flexRideRequestedAt: Maybe<DateTime>;

    routePolyline: Maybe<string>;

    lastHeartbeat: Maybe<LastHeartbeat>;

    originEta: Maybe<DateTime>;

    origin: Origin;

    destinationEta: Maybe<DateTime>;

    destination: Destination;
  };

  export type LastHeartbeat = {
    __typename?: 'RideHeartbeat';

    id: string;

    bearing: Maybe<number>;
  };

  export type Origin = HandleMysteryChangedLocation.Fragment;

  export type Destination = HandleMysteryChangedLocation.Fragment;
}

export namespace HandleMysteryMessage {
  export type Fragment = {
    __typename?: 'Message';

    adventure: Maybe<Adventure>;
  } & ConversationMessage.Fragment;

  export type Adventure = {
    __typename?: 'Adventure';

    id: string;

    hasUnreadMessages: boolean;
  };
}

export namespace AppGate {
  export type Fragment = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    name: Maybe<string>;

    email: Maybe<string>;

    phone: Maybe<string>;

    role: Role;
  };
}

export namespace NightIn {
  export type Fragment = {
    __typename?: 'DeliveryOrder';

    id: string;

    shortId: Maybe<string>;

    requester: Requester;

    requestedFor: DateTime;

    dropoffDeadline: Maybe<DateTime>;

    dropoffLocation: Maybe<DropoffLocation>;

    costCents: number;

    recipient: Maybe<Recipient>;

    boxType: Maybe<BoxType>;

    boxRecipe: Maybe<BoxRecipe>;

    mealManifest: Maybe<MealManifest>;

    internalStatus: Maybe<DeliveryInternalStatus>;

    createdAt: DateTime;

    note: Maybe<string>;

    internalNote: Maybe<string>;

    teamEvent: Maybe<TeamEvent>;
  };

  export type Requester = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    name: Maybe<string>;

    deliveryOrders: DeliveryOrders;
  };

  export type DeliveryOrders = {
    __typename?: 'DeliveryOrderConnection';

    edges: (Maybe<Edges>)[];
  };

  export type Edges = {
    __typename?: 'DeliveryEdge';

    node: Node;
  };

  export type Node = {
    __typename?: 'DeliveryOrder';

    id: string;
  };

  export type DropoffLocation = {
    __typename?: 'Location';

    id: string;

    address: Address;
  };

  export type Address = {
    __typename?: 'Address';

    full: string;
  };

  export type Recipient = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    firstName: Maybe<string>;

    lastName: Maybe<string>;
  };

  export type BoxType = {
    __typename?: 'BoxType';

    id: string;

    name: string;

    boxRecipes: (Maybe<BoxRecipes>)[];
  };

  export type BoxRecipes = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;
  };

  export type BoxRecipe = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;
  };

  export type MealManifest = {
    __typename?: 'DeliveryMealManifest';

    id: string;

    status: DeliveryStatus;

    numMeals: number;

    meal: Maybe<Meal>;

    foodRestrictions: Maybe<string>;

    pickupReadyAt: DateTime;

    methodOfDelivery: DeliveryOrderMethod;

    postmatesDeliveryId: Maybe<string>;

    numBottlesRedWine: number;

    numBottlesWhiteWine: number;
  };

  export type Meal = {
    __typename?: 'DeliveryMeal';

    id: string;

    name: string;
  };

  export type TeamEvent = {
    __typename?: 'TeamEvent';

    id: string;

    title: Maybe<string>;
  };
}

export namespace BoxTypeProfile {
  export type Fragment = {
    __typename?: 'BoxType';

    id: string;

    isB2C: boolean;

    isActive: boolean;

    name: string;

    description: Maybe<string>;

    costToCustomerCents: Maybe<number>;

    boxRecipes: (Maybe<BoxRecipes>)[];

    orders: (Maybe<Orders>)[];

    photoUrl: Maybe<string>;
  };

  export type BoxRecipes = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;

    ingredientsCostCents: number;

    localDeliveryMarketRestriction: Maybe<LocalDeliveryMarket>;
  };

  export type Orders = {
    __typename?: 'DeliveryOrder';

    id: string;
  };
}

export namespace BoxTypes {
  export type Fragment = {
    __typename?: 'BoxType';

    id: string;

    isActive: boolean;

    isB2C: boolean;

    name: string;

    createdAt: DateTime;

    costToCustomerCents: Maybe<number>;
  };
}

export namespace Inventory {
  export type Fragment = {
    __typename?: 'InventoryItem';

    id: string;

    createdAt: DateTime;
  };
}

export namespace ItemProfile {
  export type Fragment = {
    __typename?: 'InventoryItem';

    id: string;

    name: string;

    description: Maybe<string>;

    quantityInStock: number;
  };
}

export namespace Recipes {
  export type Fragment = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;

    createdAt: DateTime;
  };
}

export namespace RecipeProfile {
  export type Fragment = {
    __typename?: 'BoxRecipe';

    id: string;

    isActive: boolean;

    name: string;

    description: Maybe<string>;

    hasEnoughInventoryToMake: Maybe<number>;

    reviewQuestionnaire: ReviewQuestionnaire;

    orders: (Maybe<Orders>)[];

    boxTypes: (Maybe<BoxTypes>)[];

    ingredients: Ingredients[];

    outputItem: OutputItem;
  };

  export type ReviewQuestionnaire = {
    __typename?: 'Questionnaire';

    id: string;

    questions: Maybe<(Maybe<Questions>)[]>;
  };

  export type Questions = QuestionFragment.Fragment;

  export type Orders = {
    __typename?: 'DeliveryOrder';

    id: string;
  };

  export type BoxTypes = {
    __typename?: 'BoxType';

    id: string;

    costToCustomerCents: Maybe<number>;
  };

  export type Ingredients = {
    __typename?: 'BoxRecipeLineItem';

    id: string;

    item: Item;

    quantity: number;
  };

  export type Item = {
    __typename?: 'InventoryItem';

    id: string;

    costToMysteryCents: Maybe<number>;
  };

  export type OutputItem = {
    __typename?: 'InventoryItem';

    id: string;

    name: string;

    quantityInStock: number;
  };
}

export namespace IngredientsSectionRow {
  export type Fragment = {
    __typename?: 'BoxRecipeLineItem';

    id: string;

    item: Item;

    quantity: number;
  };

  export type Item = {
    __typename?: 'InventoryItem';

    id: string;

    name: string;

    description: Maybe<string>;

    costToMysteryCents: Maybe<number>;

    quantityInStock: number;
  };
}

export namespace HandleNewMessage {
  export type Fragment = {
    __typename?: 'Message';

    user: User;
  } & ConversationMessage.Fragment;

  export type User = ConversationUser.Fragment;
}

export namespace MessagesListRow {
  export type Fragment = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    phone: Maybe<string>;

    mostRecentMessage: Maybe<MostRecentMessage>;
  };

  export type MostRecentMessage = {
    __typename?: 'Message';

    id: string;

    content: string;

    type: MessageType;

    createdAt: DateTime;
  };
}

export namespace AuthorizedUser {
  export type Fragment = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    firstName: Maybe<string>;
  };
}

export namespace OrganizationGrid {
  export type Fragment = {
    __typename?: 'Organization';

    id: string;

    name: string;

    internalName: Maybe<string>;

    createdAt: DateTime;

    updatedAt: DateTime;

    customerType: Maybe<CustomerType>;

    members: Maybe<Members[]>;

    contracts: Maybe<Contracts[]>;
  };

  export type Members = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    organizationRole: Maybe<OrganizationRole>;
  };

  export type OrganizationRole = {
    __typename?: 'OrganizationRole';

    id: string;

    name: OrganizationRoleName;
  };

  export type Contracts = {
    __typename?: 'Contract';

    id: string;

    status: ContractStatus;

    type: ContractUnitType;
  };
}

export namespace StagingGrid {
  export type Fragment = {
    __typename?: 'Adventure';

    id: string;

    shortId: string;

    hasUnreadMessages: boolean;

    owner: Maybe<Owner>;

    startsAt: DateTime;

    status: AdventureStatus;

    budget: Maybe<Budget>;

    note: Maybe<string>;

    type: Maybe<MysteryType>;

    template: Maybe<Template>;

    party: Maybe<Party[]>;
  };

  export type Owner = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;
  };

  export type Budget = {
    __typename?: 'Budget';

    min: number;

    max: Maybe<number>;
  };

  export type Template = {
    __typename?: 'MysteryTemplate';

    id: string;

    title: string;
  };

  export type Party = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    dietaryRestrictions: Maybe<(Maybe<DietaryRestrictions>)[]>;

    accessibilityRestrictions: Maybe<(Maybe<AccessibilityRestrictions>)[]>;
  };

  export type DietaryRestrictions = {
    __typename?: 'DietaryRestriction';

    id: string;

    name: string;
  };

  export type AccessibilityRestrictions = {
    __typename?: 'AccessibilityType';

    id: string;

    name: string;
  };
}

export namespace Staging {
  export type Fragment = {
    __typename?: 'Adventure';

    id: string;

    shortId: string;
  };
}

export namespace ExperienceProfileAuditLogs {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    auditLogs: Maybe<(Maybe<AuditLogs>)[]>;

    events: Maybe<(Maybe<Events>)[]>;
  };

  export type AuditLogs = AuditLogCell.Fragment;

  export type Events = {
    __typename?: 'Event';

    auditLogs: (Maybe<_AuditLogs>)[];
  };

  export type _AuditLogs = AuditLogCell.Fragment;
}

export namespace Availability {
  export type Fragment = ExperienceAvailabilityView.Fragment;
}

export namespace ExperienceAvailabilityView {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    availabilityType: XperienceAvailabilityType;

    schedules: Maybe<(Maybe<Schedules>)[]>;

    operatingHours: Maybe<(Maybe<OperatingHours>)[]>;
  };

  export type Schedules = ScheduleItemCell.Fragment;

  export type OperatingHours = {
    __typename?: 'OperatingHours';

    id: string;

    open: Open;

    close: Maybe<Close>;
  };

  export type Open = {
    __typename?: 'OperatingHoursTime';

    day: number;

    hours: number;

    minutes: number;

    time: string;
  };

  export type Close = {
    __typename?: 'OperatingHoursTime';

    day: number;

    hours: number;

    minutes: number;

    time: string;
  };
}

export namespace ExperienceProfileAvailabilityDetails {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    numMaxGuests: Maybe<number>;
  } & ExperienceAvailabilityView.Fragment;
}

export namespace ExperienceProfileContactDetails {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    owner: Maybe<Owner>;

    ownerRoles: Maybe<(Maybe<XperienceOwnerRole>)[]>;
  };

  export type Owner = SearchUsers.Fragment;
}

export namespace ExperienceProfileCoreDetails {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    description: Maybe<string>;

    isActive: boolean;

    isVirtual: boolean;

    phone: Maybe<string>;

    photoUrl: Maybe<string>;

    url: Maybe<string>;

    xperienceTypes: (Maybe<XperienceType>)[];

    location: Maybe<Location>;

    tags: (Maybe<Tags>)[];

    templates: (Maybe<Templates>)[];
  };

  export type Location = {
    __typename?: 'Location';

    id: string;

    address: Address;
  };

  export type Address = {
    __typename?: 'Address';

    full: string;
  };

  export type Tags = {
    __typename?: 'Tag';

    id: string;

    name: string;
  };

  export type Templates = {
    __typename?: 'MysteryTemplate';

    id: string;

    title: string;
  };
}

export namespace ExperienceProfileDetails {
  export type Fragment = ExperienceProfileAvailabilityDetails.Fragment &
    ExperienceProfileContactDetails.Fragment &
    ExperienceProfileCoreDetails.Fragment &
    ExperienceProfileInstructionsDetails.Fragment &
    ExperienceProfilePhysicalDetails.Fragment &
    ExperienceProfilePreferenceDetails.Fragment &
    ExperienceProfileSummaryDetails.Fragment;
}

export namespace ExperienceProfileInstructionsDetails {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    dayBeforeInstructions: Maybe<Markdown>;

    durationOptions: Maybe<string>;

    experienceStructure: Maybe<string>;

    hybridGroupInstructions: Maybe<Markdown>;

    leadTimeNeededDays: Maybe<number>;

    supplyKitInstructions: Maybe<string>;

    postEventInformation: Maybe<Markdown>;
  };
}

export namespace ExperienceProfilePhysicalDetails {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    checkInMessage: Maybe<string>;

    checkOutMessage: Maybe<string>;

    paymentMessage: Maybe<string>;

    cardOnFile: Maybe<CardOnFile>;
  };

  export type CardOnFile = {
    __typename?: 'CreditCard';

    id: string;

    last4: string;

    expMonth: number;

    expYear: number;
  };
}

export namespace ExperienceProfilePreferenceDetails {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    relatedInterests: RelatedInterests[];

    friendlyTowardsDietaryRestrictions: Maybe<(Maybe<FriendlyTowardsDietaryRestrictions>)[]>;

    notFriendlyTowardsDietaryRestrictions: Maybe<(Maybe<NotFriendlyTowardsDietaryRestrictions>)[]>;

    friendlyTowardsAccessibilityTypes: Maybe<(Maybe<FriendlyTowardsAccessibilityTypes>)[]>;

    notFriendlyTowardsAccessibilityTypes: Maybe<(Maybe<NotFriendlyTowardsAccessibilityTypes>)[]>;
  };

  export type RelatedInterests = {
    __typename?: 'Interest';

    id: string;

    name: string;
  };

  export type FriendlyTowardsDietaryRestrictions = {
    __typename?: 'DietaryRestriction';

    id: string;

    name: string;
  };

  export type NotFriendlyTowardsDietaryRestrictions = {
    __typename?: 'DietaryRestriction';

    id: string;

    name: string;
  };

  export type FriendlyTowardsAccessibilityTypes = {
    __typename?: 'AccessibilityType';

    id: string;

    name: string;
  };

  export type NotFriendlyTowardsAccessibilityTypes = {
    __typename?: 'AccessibilityType';

    id: string;

    name: string;
  };
}

export namespace ExperienceProfileSummaryDetails {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    breakoutRoomSize: Maybe<number>;

    estimatedDurationMins: number;

    experienceInstructions: Maybe<string>;

    isVirtual: boolean;

    numHosts: Maybe<number>;

    numMaxGuests: Maybe<number>;

    numMinGuests: Maybe<number>;

    priceLevel: Maybe<number>;
  };
}

export namespace ExperienceProfile {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    name: string;

    description: Maybe<string>;

    defaultExperiencePreEventQuestionnaire: Maybe<DefaultExperiencePreEventQuestionnaire>;

    partner: Partner;
  } & (ExperienceProfileDetails.Fragment &
    ExperienceProfileShipping.Fragment &
    ExperienceProfilePricing.Fragment &
    ExperienceProfileAuditLogs.Fragment);

  export type DefaultExperiencePreEventQuestionnaire = {
    __typename?: 'Questionnaire';

    id: string;

    questions: Maybe<(Maybe<Questions>)[]>;
  };

  export type Questions = EditQuestionnaireButton.Fragment;

  export type Partner = {
    __typename?: 'Partner';

    id: string;

    name: string;
  };
}

export namespace ExperienceProfilePricing {
  export type Fragment = ExperiencePricingView.Fragment;
}

export namespace ExperiencePricingView {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    requiresUpgrade: boolean;

    cost: Maybe<Cost>;

    partner: Partner;
  };

  export type Cost = {
    __typename?: 'Cost';

    id: string;

    agreedOn: Maybe<Date>;

    ctcCents: number;

    ctcUnit: CostUnit;

    ctcPricingBands: CtcPricingBands[];

    ctmCents: number;

    ctmUnit: CostUnit;

    ctmVolumeDiscounts: Maybe<(Maybe<CtmVolumeDiscounts>)[]>;

    ctmTieredEventPricing: Maybe<(Maybe<CtmTieredEventPricing>)[]>;

    ctmPerPersonCentsMinimum: Maybe<number>;

    cancellationPolicy: Maybe<string>;
  };

  export type CtcPricingBands = {
    __typename?: 'PricingBand';

    minUsers: number;

    maxUsers: Maybe<number>;

    cents: number;

    credits: Maybe<number>;

    unit: CostUnit;
  };

  export type CtmVolumeDiscounts = {
    __typename?: 'VolumeDiscount';

    minNumUsers: number;

    percentDiscount: number;
  };

  export type CtmTieredEventPricing = {
    __typename?: 'TieredEventPricing';

    minNumUsers: number;

    ctmCents: number;
  };

  export type Partner = {
    __typename?: 'Partner';

    id: string;

    name: string;
  };
}

export namespace ExperienceProfileShipping {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    physicalGoodsDescription: Maybe<Markdown>;

    shippingCustomizations: Maybe<Markdown>;

    usaShipping: UsaShipping;

    internationalShipping: InternationalShipping;
  };

  export type UsaShipping = {
    __typename?: 'XperienceUSAShipping';

    enabled: boolean;

    estimatedLeadTimeDays: Maybe<number>;

    estimatedCostCents: Maybe<number>;

    excludeStates: string[];
  };

  export type InternationalShipping = {
    __typename?: 'XperienceInternationalShipping';

    enabled: boolean;

    estimatedLeadTimeDays: Maybe<number>;

    estimatedCostCents: Maybe<number>;

    excludeCountries: string[];
  };
}

export namespace PartnerAuditLogs {
  export type Fragment = {
    __typename?: 'Partner';

    id: string;

    auditLogs: (Maybe<AuditLogs>)[];

    experiences: (Maybe<Experiences>)[];
  };

  export type AuditLogs = AuditLogCell.Fragment;

  export type Experiences = {
    __typename?: 'Xperience';

    auditLogs: Maybe<(Maybe<_AuditLogs>)[]>;

    events: Maybe<(Maybe<Events>)[]>;
  };

  export type _AuditLogs = AuditLogCell.Fragment;

  export type Events = {
    __typename?: 'Event';

    auditLogs: (Maybe<__AuditLogs>)[];
  };

  export type __AuditLogs = AuditLogCell.Fragment;
}

export namespace PartnerProfileDetails {
  export type Fragment = {
    __typename?: 'Partner';

    id: string;

    email: Maybe<string>;

    notes: Maybe<Markdown>;

    numHosts: Maybe<number>;

    permissionToRecord: boolean;

    phone: Maybe<string>;

    quality: Maybe<PartnerQuality>;

    status: PartnerStatus;

    supportedVideoPlatforms: (Maybe<SupportedVideoPlatforms>)[];

    url: Maybe<string>;

    location: Maybe<Location>;

    owners: (Maybe<Owners>)[];
  };

  export type SupportedVideoPlatforms = {
    __typename?: 'PartnerVideoPlatform';

    platform: Maybe<VirtualEventType>;
  };

  export type Location = {
    __typename?: 'Location';

    id: string;

    address: Address;
  };

  export type Address = {
    __typename?: 'Address';

    full: string;
  };

  export type Owners = SearchUsersMultiSelect.Fragment;
}

export namespace PartnerExperiences {
  export type Fragment = {
    __typename?: 'Xperience';

    id: string;

    name: string;

    isActive: boolean;

    estimatedDurationMins: number;
  } & ExperienceStatus.Fragment;
}

export namespace PartnerProfile {
  export type Fragment = {
    __typename?: 'Partner';

    id: string;

    name: string;

    description: Maybe<string>;

    experiences: (Maybe<Experiences>)[];

    createdAt: DateTime;

    updatedAt: DateTime;
  } & (PartnerProfileDetails.Fragment & PartnerAuditLogs.Fragment);

  export type Experiences = PartnerExperiences.Fragment;
}

export namespace AddGuests {
  export type Fragment = {
    __typename?: 'User';

    id: string;

    email: Maybe<string>;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    phone: Maybe<string>;

    dob: Maybe<Date>;

    zipCode: Maybe<string>;
  };
}

export namespace TeamEventGrid {
  export type Fragment = {
    __typename?: 'TeamEvent';

    id: string;

    experience: Maybe<Experience>;

    status: Maybe<TeamEventStatus>;

    includeDelivery: Maybe<boolean>;

    requestedFor: Maybe<DateTime>;

    title: Maybe<string>;

    shortId: string;

    availableBoxTypes: Maybe<(Maybe<AvailableBoxTypes>)[]>;

    creator: Maybe<Creator>;

    owner: Maybe<Owner>;

    boxRecipes: Maybe<(Maybe<BoxRecipes>)[]>;

    invitationsAccepted: Maybe<number>;

    invitationsSent: Maybe<number>;

    requestedBy: Maybe<RequestedBy>;

    organization: Maybe<Organization>;

    virtualEvent: Maybe<VirtualEvent>;
  };

  export type Experience = {
    __typename?: 'Xperience';

    id: string;

    name: string;
  };

  export type AvailableBoxTypes = {
    __typename?: 'BoxType';

    id: string;

    name: string;
  };

  export type Creator = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    lastName: Maybe<string>;
  };

  export type Owner = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    lastName: Maybe<string>;
  };

  export type BoxRecipes = {
    __typename?: 'BoxRecipe';

    id: string;

    name: string;
  };

  export type RequestedBy = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    firstName: Maybe<string>;

    lastName: Maybe<string>;
  };

  export type Organization = {
    __typename?: 'Organization';

    id: string;

    name: string;

    internalName: Maybe<string>;
  };

  export type VirtualEvent = {
    __typename?: 'VirtualEvent';

    id: string;

    type: Maybe<VirtualEventType>;

    zoomMeeting: Maybe<ZoomMeeting>;
  };

  export type ZoomMeeting = {
    __typename?: 'ZoomMeeting';

    id: string;

    hostJoinedAt: Maybe<DateTime>;
  };
}

export namespace UsersDetails {
  export type Fragment = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    phone: Maybe<string>;

    email: Maybe<string>;
  };
}

export namespace EditUserModal {
  export type Fragment = {
    __typename?: 'User';

    id: string;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    phone: Maybe<string>;

    email: Maybe<string>;

    role: Role;

    internalSlackId: Maybe<string>;

    isServiceAccount: boolean;
  };
}

export namespace UsersListRow {
  export type Fragment = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    phone: Maybe<string>;

    email: Maybe<string>;
  };
}

export namespace UsersTabsDetails {
  export type Fragment = {
    __typename?: 'User';

    id: string;

    name: Maybe<string>;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    phone: Maybe<string>;

    email: Maybe<string>;

    role: Role;

    internalSlackId: Maybe<string>;

    isServiceAccount: boolean;
  };
}

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Interfaces
// ====================================================

export interface EngagementResult {
  scoreType: ScoreType;

  day: DateTime;
}

export interface EngagementScore {
  scoreType: ScoreType;

  value: number;

  trend?: Maybe<ScoreTrend>;

  oneMonthDelta?: Maybe<number>;

  day: DateTime;
}

// ====================================================
// Types
// ====================================================

export interface Query {
  accessibilityTypes: (Maybe<AccessibilityType>)[];

  adventure?: Maybe<Adventure>;

  mysteriesConnection?: Maybe<MysteryConnection>;

  foodPreferenceOptions?: Maybe<(Maybe<FoodPreferenceTypeWithImage>)[]>;

  activityPreferenceOptions?: Maybe<(Maybe<ActivityPreferenceTypeWithImage>)[]>;

  approvals: (Maybe<Approval>)[];

  attireTypes?: Maybe<(Maybe<AttireType>)[]>;

  subAttireTypes?: Maybe<(Maybe<SubAttireType>)[]>;

  auditLogs: (Maybe<AuditLog>)[];

  auditLogsConnection?: Maybe<AuditLogConnection>;

  billableAdventures?: Maybe<(Maybe<Adventure>)[]>;

  estimatePriceForTeamEvent?: Maybe<EstimatedCost>;

  blackoutDates: (Maybe<BlackoutDate>)[];

  bookingSessions: (Maybe<BookingSession>)[];

  bookingSessionOptions: BookingSessionOptions;

  budgetOptions: (Maybe<BudgetOption>)[];

  checkGroupCalendarAvailability: AvailabilityInformation;

  timesAvailableForDate?: Maybe<(Maybe<CapacityTimeWindow>)[]>;

  upcomingCapacity?: Maybe<(Maybe<Capacity>)[]>;

  currentLeadTimeHours: number;

  currentBusinessLeadTimeHours: number;

  chimeMeeting?: Maybe<ChimeMeeting>;

  chimeMeetingAttendees?: Maybe<(Maybe<Attendee>)[]>;

  chimeMeetingByVirtualEvent?: Maybe<ChimeMeeting>;

  chimeMeetings?: Maybe<(Maybe<ChimeMeeting>)[]>;

  contract?: Maybe<Contract>;

  organizationContracts: Contract[];

  creditCards?: Maybe<(Maybe<CreditCard>)[]>;

  getDeliveryMarkets: DeliveryMarket[];

  deliveryMeals: DeliveryMeal[];

  getDeliveryMarket: DeliveryMarket;

  getDeliveryOrder: DeliveryOrder;

  findDeliveryOrderForUserAndTeamEvent?: Maybe<DeliveryOrder>;

  deliveryOrderConnection?: Maybe<DeliveryOrderConnection>;

  getDeliveryMarketForLocation?: Maybe<DeliveryMarket>;

  availableExtras?: Maybe<(Maybe<ExtraInfo>)[]>;

  dietaryRestrictions?: Maybe<(Maybe<DietaryRestriction>)[]>;

  latestEngagementEdges?: Maybe<ScalarEngagementEdge[]>;

  organizationEngagementScore?: Maybe<OrganizationScore>;

  event?: Maybe<Event>;

  events?: Maybe<(Maybe<Event>)[]>;

  eventsConnection?: Maybe<EventConnection>;

  experience?: Maybe<Experience>;

  experiences?: Maybe<(Maybe<Experience>)[]>;

  experiencesConnection?: Maybe<ExperienceConnection>;

  mostRecentMystery?: Maybe<Adventure>;

  interests: Interest[];

  inventoryItem?: Maybe<InventoryItem>;

  inventoryItems: InventoryItem[];

  inventoryItemQuantityChangeLogs: InventoryItemQuantityChangeLog[];

  boxRecipe?: Maybe<BoxRecipe>;

  boxRecipes: BoxRecipe[];

  boxType?: Maybe<BoxType>;

  boxTypes: BoxType[];

  activeBoxTypes: BoxType[];

  availableBoxTypes: (Maybe<BoxType>)[];

  boxRecipeLineItem?: Maybe<BoxRecipeLineItem>;

  legalDocument?: Maybe<LegalDocument>;

  attributionQueryKeys?: Maybe<(Maybe<string>)[]>;

  message?: Maybe<Message>;

  messagesByIds?: Maybe<(Maybe<Message>)[]>;

  messages?: Maybe<(Maybe<Message>)[]>;

  messagesConnection?: Maybe<MessageConnection>;

  metabaseMetricsURL?: Maybe<string>;

  summarizeSurveyAnswers: MoralePulseAnswerSummary[];

  summarizeSurveyParticipation?: Maybe<MoralePulseParticipationSummary>;

  moralePulseSurvey?: Maybe<MoralePulseSurvey>;

  moralePulseSurveys: MoralePulseSurvey[];

  moralePulseActiveSurveys: MoralePulseSurvey[];

  moralePulseReportingTimeframes: MoralePulseSurveyReportingInterval[];
  /** Get a summary of Morale Pulse survey answers across all surveys in the organization, limited to a particular audience or timeframe. */
  moralePulseSurveyAnswerSummary: (Maybe<MoralePulseAnswerSummary>)[];
  /** Summarizes participation statistics for Morale Pulse surveys in the organization, limited to a particular audience or timeframe. */
  moralePulseSurveyParticipationSummary: MoralePulseParticipationSummary;
  /** Summarizes response data to a particular survey question across all surveys in the organization, limited to a particular audience or timeframe. */
  moralePulseQuestionSummary?: Maybe<MoralePulseQuestionSummary>;

  moralePulseQuestionSummaries: MoralePulseQuestionSummary[];

  magicLinkPulseOrganization?: Maybe<MagicLinkPulseOrganization>;

  magicLinkPulseSurvey?: Maybe<MagicLinkPulseSurveyWrapper>;

  mysteryTemplate?: Maybe<MysteryTemplate>;

  mysteryTemplatesConnection?: Maybe<MysteryTemplateConnection>;

  mysteryThemes?: Maybe<(Maybe<MysteryTheme>)[]>;

  organization?: Maybe<Organization>;

  organizations?: Maybe<Organization[]>;

  organizationsConnection?: Maybe<OrganizationConnection>;

  getPendingOrgMembers?: Maybe<(Maybe<User>)[]>;

  getOrgByLinkPath?: Maybe<Organization>;

  getOrganizationMembers?: Maybe<User[]>;

  partner?: Maybe<Partner>;

  partners?: Maybe<(Maybe<Partner>)[]>;

  partnersConnection?: Maybe<PartnerConnection>;

  payout?: Maybe<Payout>;

  payoutsForTeamEvent: Payout[];

  plannedMessages?: Maybe<(Maybe<PlannedMessage>)[]>;

  preferenceAnswer: PreferenceAnswer[];

  preferenceQuestions: PreferenceQuestion[];

  unansweredPreferenceQuestions: (Maybe<PreferenceQuestion>)[];

  promoCode?: Maybe<Promo>;

  question?: Maybe<Question>;

  questionnaire?: Maybe<Questionnaire>;

  questionnaireAnswers?: Maybe<(Maybe<UserAnswers>)[]>;

  defaultQuestionnaire?: Maybe<DefaultQuestionnaire>;

  reviewQuestionnaires?: Maybe<(Maybe<Questionnaire>)[]>;

  completedFeedback?: Maybe<(Maybe<FeedbackComplete>)[]>;

  roles: OrganizationRole[];

  findMatchingExperiencesForTeamEvents?: Maybe<TeamEventMatches>;

  scheduledAdventure?: Maybe<ScheduledAdventure>;

  scheduledAdventures?: Maybe<(Maybe<ScheduledAdventure>)[]>;

  scrapedEventsConnection?: Maybe<ScrapedEventConnection>;

  checkIfEventsHaveBeenScrapedForDate: boolean;

  getAllScrapedEventCategoriesForDate?: Maybe<string[]>;

  getAllScrapedEventNeighborhoodsForDate?: Maybe<string[]>;

  serviceRegion?: Maybe<ServiceRegion>;

  getServiceRegions: ServiceRegion[];

  tag?: Maybe<Tag>;

  tags?: Maybe<(Maybe<Tag>)[]>;

  tagsConnection?: Maybe<TagConnection>;

  getTagMapping?: Maybe<TagMapping>;

  taskFlow?: Maybe<TaskFlow>;

  taskFlowsForUser: TaskFlow[];

  taskFlowsForOrganization: TaskFlow[];

  inferTaskFlow: TaskFlow;

  listRequiredOnboardingTasksFlows: TaskFlow[];

  teamInMyOrg?: Maybe<Team>;

  allTeamsInMyOrg?: Maybe<Team[]>;

  searchTeams?: Maybe<(Maybe<Team>)[]>;

  teamEvent?: Maybe<TeamEvent>;

  teamEvents?: Maybe<(Maybe<TeamEvent>)[]>;

  teamEventsConnection?: Maybe<TeamEventConnection>;

  userGetExistingEventsForTemplate?: Maybe<(Maybe<DateRange>)[]>;

  getTeamEventIssues?: Maybe<(Maybe<TeamEventIssue>)[]>;

  teamEventGoals?: Maybe<TeamEventGoal[]>;

  viewer?: Maybe<User>;

  user?: Maybe<User>;

  users?: Maybe<User[]>;

  usersConnection?: Maybe<UserConnection>;

  orgUsersConnection?: Maybe<UserConnection>;

  getUsersWithTags?: Maybe<(Maybe<User>)[]>;

  searchUserTags?: Maybe<(Maybe<UserTag>)[]>;

  virtualEvent?: Maybe<VirtualEvent>;

  virtualEvents?: Maybe<(Maybe<VirtualEvent>)[]>;

  virtualEventsConnection?: Maybe<VirtualEventConnection>;

  virtualExperience?: Maybe<VirtualExperience>;

  virtualExperiences?: Maybe<(Maybe<VirtualExperience>)[]>;

  watercooler?: Maybe<Watercooler>;

  watercoolers?: Maybe<(Maybe<Watercooler>)[]>;

  watercoolersForAudience?: Maybe<(Maybe<Watercooler>)[]>;

  watercoolerInitiative?: Maybe<WatercoolerInitiative>;

  watercoolerInitiativeByOrg?: Maybe<WatercoolerInitiative>;

  watercoolerInitiatives?: Maybe<(Maybe<WatercoolerInitiative>)[]>;

  averageConnectionScoresForAudience?: Maybe<TeamScore>;

  averageFeedbackRatingForWatercooler?: Maybe<number>;

  userInWatercoolerInitiativeAudience?: Maybe<boolean>;

  xperience?: Maybe<Xperience>;

  xperiences?: Maybe<(Maybe<Xperience>)[]>;

  xperienceCategories: XperienceCategory[];

  xperiencesConnection?: Maybe<XperienceConnection>;

  findEventsForXperiences?: Maybe<(Maybe<TemplateWithEvents>)[]>;

  zoomMeeting?: Maybe<ZoomMeeting>;

  zoomMeetings?: Maybe<(Maybe<ZoomMeeting>)[]>;

  zoomHost?: Maybe<ZoomHost>;

  zoomHosts?: Maybe<(Maybe<ZoomHost>)[]>;

  zoomHostPassword?: Maybe<string>;
}

export interface AccessibilityType {
  id: string;

  name: string;

  createdAt?: Maybe<DateTime>;
}

export interface Adventure {
  id: string;

  region: ServiceRegion;

  shortId: string;

  owner?: Maybe<User>;

  startsAt: DateTime;

  status: AdventureStatus;

  isCanceled: boolean;

  pickupLocation: Location;

  timezone: string;

  party?: Maybe<User[]>;

  plannedMessages?: Maybe<(Maybe<PlannedMessage>)[]>;

  messages?: Maybe<(Maybe<Message>)[]>;

  messagesConnection?: Maybe<MessageConnection>;

  latestProgressEvent?: Maybe<AdventureProgress>;

  progress?: Maybe<(Maybe<AdventureProgress>)[]>;

  price?: Maybe<Price>;

  createdAt: DateTime;

  hasUnreadMessages: boolean;

  chatRoomId?: Maybe<string>;

  stopByOrderIndex: AdventureStop;

  stops?: Maybe<AdventureStop[]>;

  stopCount: number;

  nextUnarrivedStop?: Maybe<AdventureStop>;

  note?: Maybe<string>;

  canShareNote?: Maybe<boolean>;

  budget?: Maybe<Budget>;

  mysteryBudget?: Maybe<BudgetOption>;

  foodBudget?: Maybe<number>;

  requester?: Maybe<User>;

  requestedFor?: Maybe<DateTime>;

  requestedForWindowDurationMs?: Maybe<number>;

  promo?: Maybe<Promo>;

  feedbacks?: Maybe<(Maybe<AdventureFeedback>)[]>;

  feedbackLinksSentAt?: Maybe<DateTime>;

  type?: Maybe<MysteryType>;

  source?: Maybe<AdventureSource>;

  foodPreferences?: Maybe<(Maybe<FoodPreference>)[]>;

  activityPreferences?: Maybe<(Maybe<ActivityPreference>)[]>;

  template?: Maybe<MysteryTemplate>;
}

export interface ServiceRegion {
  id: string;

  name: RegionName;

  nextSeqVal: number;

  mysteries?: Maybe<Adventure[]>;
}

export interface User {
  id: string;

  phone?: Maybe<string>;

  role: Role;

  zipCode?: Maybe<string>;

  firstName?: Maybe<string>;

  lastName?: Maybe<string>;

  name?: Maybe<string>;

  email?: Maybe<string>;

  dob?: Maybe<Date>;

  chatRoomId?: Maybe<string>;

  forceSms?: Maybe<boolean>;

  companyRole?: Maybe<string>;

  companyNameForSales?: Maybe<string>;

  stripeId?: Maybe<string>;

  cardOnFile?: Maybe<CardOnFile>;

  hasMysteryUnlimited?: Maybe<boolean>;

  ownedAdventures?: Maybe<(Maybe<Adventure>)[]>;

  requestedAdventures?: Maybe<(Maybe<Adventure>)[]>;

  mysteries: MysteryConnection;

  nextScheduledMystery?: Maybe<Adventure>;

  inProgressMystery?: Maybe<Adventure>;

  completedMysteriesCount: number;

  scheduledAdventures?: Maybe<(Maybe<ScheduledAdventure>)[]>;

  legalAgreements?: Maybe<(Maybe<LegalAgreement>)[]>;

  hasAgreedToTOS?: Maybe<boolean>;

  hasAgreedToLatestTOS?: Maybe<boolean>;

  heartbeats?: Maybe<(Maybe<Heartbeat>)[]>;

  messages?: Maybe<(Maybe<Message>)[]>;

  mostRecentMessage?: Maybe<Message>;

  pushNotifications: (Maybe<PushNotification>)[];

  oneSignalUserId?: Maybe<string>;

  notificationsEnabled: boolean;

  locationEnabled: LocationPermissionType;

  marketingConsent: boolean;

  preferenceAnswers?: Maybe<(Maybe<PreferenceAnswer>)[]>;

  unansweredPreferenceQuestions: (Maybe<PreferenceQuestion>)[];

  dietaryRestrictions?: Maybe<(Maybe<DietaryRestriction>)[]>;

  dietaryRestrictionsLastAsked?: Maybe<Date>;

  accessibilityRestrictions?: Maybe<(Maybe<AccessibilityType>)[]>;

  foodPreferences?: Maybe<(Maybe<FoodPreference>)[]>;

  restrictions?: Maybe<(Maybe<Restriction>)[]>;

  adventureFeedback?: Maybe<(Maybe<AdventureFeedback>)[]>;

  adventureStopFeedback?: Maybe<(Maybe<AdventureStopFeedback>)[]>;

  questionnaireAnswers?: Maybe<(Maybe<Answer>)[]>;

  askFeedbackEvent?: Maybe<TeamEvent>;

  deliveryOrders: DeliveryOrderConnection;

  teamEvents: TeamEventConnection;

  requestedTeamEvents?: Maybe<(Maybe<TeamEvent>)[]>;

  lastTeamEvent?: Maybe<TeamEvent>;

  signUpSource?: Maybe<SignUpSource>;

  createdAt: DateTime;

  updatedAt: DateTime;

  alertsPreference?: Maybe<MysteryAlertsPreference>;

  internalSlackId?: Maybe<string>;

  organizationRole?: Maybe<OrganizationRole>;

  defaultOrganization?: Maybe<Organization>;

  orgs: (Maybe<Organization>)[];

  tags?: Maybe<(Maybe<UserTag>)[]>;

  title?: Maybe<string>;

  startDate?: Maybe<DateTime>;

  isNylasConnected?: Maybe<boolean>;

  nylasConnectionState?: Maybe<NylasConnectionState>;

  nylasAssociation?: Maybe<NylasAssociation>;

  finchAssociation?: Maybe<string>;

  promptedForMarketingConsent?: Maybe<DateTime>;

  accountStatus?: Maybe<AccountStatus>;

  manager?: Maybe<User>;

  managerOf?: Maybe<(Maybe<Team>)[]>;

  teams: (Maybe<Team>)[];

  customerType: CustomerType;

  isServiceAccount: boolean;

  isArchived: boolean;

  optOutOfWatercooler?: Maybe<boolean>;

  optOutOfWatercoolerAt?: Maybe<DateTime>;

  preferredWatercoolerDays?: Maybe<(Maybe<Weekday>)[]>;

  preferredWatercoolerTime?: Maybe<(Maybe<TimeOfDay>)[]>;

  timezone?: Maybe<string>;

  dataIntegrationAdmin?: Maybe<boolean>;

  optOutOfDigestAt?: Maybe<DateTime>;

  contracts: (Maybe<Contract>)[];
}

export interface CardOnFile {
  id: string;

  last4: string;

  expMonth: number;

  expYear: number;

  brand: string;
}

export interface MysteryConnection {
  pageInfo: PageInfo;

  edges: (Maybe<MysteryEdge>)[];

  count: number;
}

export interface PageInfo {
  hasNextPage: boolean;

  hasPreviousPage: boolean;

  startCursor?: Maybe<string>;

  endCursor?: Maybe<string>;
}

export interface MysteryEdge {
  node: Adventure;

  cursor: string;
}

export interface ScheduledAdventure {
  id: string;

  status: ScheduledAdventureStatus;

  adventure?: Maybe<Adventure>;

  budget: Budget;

  promo?: Maybe<Promo>;

  creator: User;

  party: (Maybe<User>)[];

  pickupLocation: Location;

  note?: Maybe<string>;

  canShareNote?: Maybe<boolean>;

  requestedFor: DateTime;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface Budget {
  min: number;

  max?: Maybe<number>;
}

export interface Promo {
  id: string;

  code: string;

  description: string;

  discountType: DiscountType;

  discountAmount?: Maybe<number>;

  validUntil?: Maybe<DateTime>;

  createdAt: DateTime;
}

export interface Location {
  id: string;

  placeId?: Maybe<string>;

  name?: Maybe<string>;

  address: Address;

  coordinates: Coordinates;

  experience?: Maybe<Experience>;

  createdAt: DateTime;

  timezone?: Maybe<string>;
}

export interface Address {
  full: string;

  streetNumber?: Maybe<string>;

  route?: Maybe<string>;

  subPremise?: Maybe<string>;

  city?: Maybe<string>;

  state?: Maybe<string>;

  postalCode?: Maybe<string>;

  county?: Maybe<string>;

  country?: Maybe<string>;

  neighborhood?: Maybe<string>;
}

export interface Coordinates {
  latitude: number;

  longitude: number;
}

export interface Experience {
  id: string;

  queryRoot: Query;

  name: string;

  phone?: Maybe<string>;

  types: (Maybe<ExperienceType>)[];

  admissionType?: Maybe<AdmissionType>;

  acceptsReservations?: Maybe<boolean>;

  acceptsOnlineReservations?: Maybe<boolean>;

  attire: (Maybe<Attire>)[];

  priceLevel?: Maybe<number>;

  priceCents?: Maybe<number>;

  previousBilling?: Maybe<BillingStatistics>;

  rating?: Maybe<number>;

  ticketPurchaseUrl?: Maybe<string>;

  url?: Maybe<string>;

  photoUrl?: Maybe<string>;

  description?: Maybe<string>;

  smsDescription?: Maybe<string>;

  createdAt: DateTime;

  owner?: Maybe<User>;

  creator?: Maybe<User>;

  events?: Maybe<(Maybe<Event>)[]>;

  lineItem?: Maybe<PriceLineItem>;

  hours?: Maybe<(Maybe<OperatingHours>)[]>;

  categories: (Maybe<ExperienceCategory>)[];

  subCategories: (Maybe<ExperienceSubCategory>)[];

  location: Location;
}

export interface BillingStatistics {
  avgCents: number;

  stddevCents: number;

  previousMysteries: number;
}

export interface Event {
  id: string;

  experience: Xperience;

  schedule?: Maybe<Schedule>;

  capacityType: CapacityType;

  remainingCapacity: number;

  totalCapacity: number;

  notes?: Maybe<string>;

  cost?: Maybe<Cost>;

  location: Location;

  start: DateTime;

  end: DateTime;

  creator?: Maybe<User>;

  generated?: Maybe<boolean>;

  adventureStops?: Maybe<(Maybe<AdventureStop>)[]>;

  createdAt: DateTime;

  updatedAt: DateTime;

  auditLogs: (Maybe<AuditLog>)[];
}

export interface Xperience {
  id: string;

  isActive: boolean;

  isVirtual: boolean;

  name: string;

  description?: Maybe<string>;

  url?: Maybe<string>;

  photoUrl?: Maybe<string>;

  phone?: Maybe<string>;

  defaultBoxType?: Maybe<BoxType>;

  xperienceTypes: (Maybe<XperienceType>)[];

  estimatedDurationMins: number;

  acceptsReservations: boolean;

  reservationsUrl?: Maybe<string>;

  onlineReservationProvider?: Maybe<OnlineReservationProvider>;

  checkInMessage?: Maybe<string>;

  checkOutMessage?: Maybe<string>;

  paymentMessage?: Maybe<string>;

  cardOnFile?: Maybe<CreditCard>;

  priceLevel?: Maybe<number>;

  experienceInstructions?: Maybe<string>;

  hybridGroupInstructions?: Maybe<Markdown>;

  dayBeforeInstructions?: Maybe<Markdown>;

  experienceStructure?: Maybe<string>;

  durationOptions?: Maybe<string>;

  leadTimeNeededDays?: Maybe<number>;

  usaShipping: XperienceUsaShipping;

  internationalShipping: XperienceInternationalShipping;

  physicalGoodsDescription?: Maybe<Markdown>;

  shippingCustomizations?: Maybe<Markdown>;

  numHosts?: Maybe<number>;

  numMinGuests?: Maybe<number>;

  numMaxGuests?: Maybe<number>;

  numIdealGuests?: Maybe<number>;

  breakoutRoomSize?: Maybe<number>;

  supplyKitInstructions?: Maybe<string>;

  postEventInformation?: Maybe<Markdown>;

  connectionFocusAreas?: Maybe<(Maybe<ConnectionFocusArea>)[]>;

  attireType?: Maybe<AttireType>;

  subAttireTypes?: Maybe<(Maybe<SubAttireType>)[]>;

  friendlyTowardsDietaryRestrictions?: Maybe<(Maybe<DietaryRestriction>)[]>;

  notFriendlyTowardsDietaryRestrictions?: Maybe<(Maybe<DietaryRestriction>)[]>;

  friendlyTowardsAccessibilityTypes?: Maybe<(Maybe<AccessibilityType>)[]>;

  notFriendlyTowardsAccessibilityTypes?: Maybe<(Maybe<AccessibilityType>)[]>;

  categories: XperienceCategory[];

  relatedInterests: Interest[];

  tags: (Maybe<Tag>)[];

  templates: (Maybe<MysteryTemplate>)[];

  partner: Partner;

  location?: Maybe<Location>;

  events?: Maybe<(Maybe<Event>)[]>;

  futureEvents?: Maybe<(Maybe<Event>)[]>;

  cost?: Maybe<Cost>;

  availabilityType: XperienceAvailabilityType;

  schedules?: Maybe<(Maybe<Schedule>)[]>;

  operatingHours?: Maybe<(Maybe<OperatingHours>)[]>;

  owner?: Maybe<User>;

  ownerRoles?: Maybe<(Maybe<XperienceOwnerRole>)[]>;

  auditLogs?: Maybe<(Maybe<AuditLog>)[]>;

  missingRequiredFields?: Maybe<(Maybe<string>)[]>;

  latestApproval?: Maybe<Approval>;

  latestChangesHaveBeenApproved: boolean;

  totalEventCount: number;

  upcomingEventCount: number;

  status: XperienceStatus;

  hasPhysicalGoods: boolean;

  requiresUpgrade: boolean;

  defaultExperiencePreEventQuestionnaire?: Maybe<Questionnaire>;

  connectednessScore?: Maybe<number>;

  updatedAt?: Maybe<DateTime>;

  createdAt?: Maybe<DateTime>;

  deletedAt?: Maybe<DateTime>;
}

export interface BoxType {
  id: string;

  isB2C: boolean;

  isActive: boolean;

  name: string;

  description?: Maybe<string>;

  costToCustomerCents?: Maybe<number>;

  boxRecipes: (Maybe<BoxRecipe>)[];

  orders: (Maybe<DeliveryOrder>)[];

  photoUrl?: Maybe<string>;

  creator: User;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface BoxRecipe {
  id: string;

  isActive: boolean;

  name: string;

  description?: Maybe<string>;

  ingredients: BoxRecipeLineItem[];

  ingredientsCostCents: number;

  creator: User;

  orders: (Maybe<DeliveryOrder>)[];

  virtualEvents: (Maybe<VirtualEvent>)[];

  outputItem: InventoryItem;

  hasEnoughInventoryToMake?: Maybe<number>;

  localDeliveryMarketRestriction?: Maybe<LocalDeliveryMarket>;

  reviewQuestionnaire: Questionnaire;

  boxTypes: (Maybe<BoxType>)[];

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface BoxRecipeLineItem {
  id: string;

  item: InventoryItem;

  quantity: number;

  createdAt: DateTime;
}

export interface InventoryItem {
  id: string;

  isActive: boolean;

  name: string;

  description?: Maybe<string>;

  category?: Maybe<InventoryItemCategory>;

  costToMysteryCents?: Maybe<number>;

  quantityInStock: number;

  quantityChangeLogs?: Maybe<InventoryItemQuantityChangeLog[]>;

  recipeToBuildThisItem?: Maybe<BoxRecipe>;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface InventoryItemQuantityChangeLog {
  id: string;

  actor: User;

  timestamp: DateTime;

  itemType: InventoryItem;

  deltaQuantity: number;

  previousQuantity: number;

  newQuantity: number;
}

export interface DeliveryOrder {
  id: string;

  shortId?: Maybe<string>;

  methodOfDelivery?: Maybe<DeliveryOrderMethod>;

  deliveryProvider?: Maybe<DeliveryProvider>;

  status: DeliveryStatus;

  internalStatus?: Maybe<DeliveryInternalStatus>;

  note?: Maybe<string>;

  internalNote?: Maybe<string>;

  foodRestrictions?: Maybe<string>;

  requester: User;

  numMeals?: Maybe<number>;

  totalNumMeals?: Maybe<number>;

  includeWine?: Maybe<boolean>;

  costCents: number;

  donationCents?: Maybe<number>;

  chargeId?: Maybe<string>;

  postmatesDeliveryId?: Maybe<string>;

  selectedCuisines?: Maybe<(Maybe<string>)[]>;

  pickupReadyAt?: Maybe<DateTime>;

  requestedFor: DateTime;

  dropoffLocation?: Maybe<Location>;

  dropoffDeadline?: Maybe<DateTime>;

  boxType?: Maybe<BoxType>;

  boxRecipe?: Maybe<BoxRecipe>;

  meal?: Maybe<DeliveryMeal>;

  mealManifest?: Maybe<DeliveryMealManifest>;

  market?: Maybe<DeliveryMarket>;

  recipient?: Maybe<User>;

  plannedMessages?: Maybe<(Maybe<PlannedMessage>)[]>;

  createdAt: DateTime;

  noteToOps?: Maybe<string>;

  extras?: Maybe<(Maybe<ExtraInfo>)[]>;

  teamEvent?: Maybe<TeamEvent>;
}

export interface DeliveryMeal {
  id: string;

  active: boolean;

  name: string;

  cuisine: Cuisine;

  phone: string;

  location: Location;
}

export interface DeliveryMealManifest {
  id: string;

  status: DeliveryStatus;

  postmatesDeliveryId?: Maybe<string>;

  methodOfDelivery: DeliveryOrderMethod;

  pickupReadyAt: DateTime;

  selectedCuisines: (Maybe<string>)[];

  meal?: Maybe<DeliveryMeal>;

  numMeals: number;

  mealCostCents: number;

  numBottlesRedWine: number;

  numBottlesWhiteWine: number;

  wineCostCents?: Maybe<number>;

  deliveryOrder: DeliveryOrder;

  foodRestrictions?: Maybe<string>;
}

export interface DeliveryMarket {
  id: string;

  name: string;

  availableDeliveryDays: number[];

  taxRate: number;

  deliveryProvider: DeliveryProvider;
}

export interface PlannedMessage {
  id: string;

  orderIndex: number;

  content: string;

  adventure?: Maybe<Adventure>;

  sentAt?: Maybe<DateTime>;

  hook?: Maybe<PlannedMessageHookType>;

  hookDurationMs?: Maybe<number>;

  stop?: Maybe<AdventureStop>;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface AdventureStop {
  id: string;

  arrived: boolean;

  ride?: Maybe<Ride>;

  nextRide?: Maybe<Ride>;

  revealed: boolean;

  status?: Maybe<StopStatus>;

  isFlexRide?: Maybe<boolean>;

  skipRide?: Maybe<boolean>;

  details?: Maybe<string>;

  orderIndex: number;

  pickupTime: DateTime;

  startTime: DateTime;

  endTime: DateTime;

  adventure: Adventure;

  progress?: Maybe<(Maybe<AdventureProgress>)[]>;

  experience?: Maybe<Experience>;

  event?: Maybe<Event>;

  feedback?: Maybe<AdventureStopFeedback>;

  plannedMessages?: Maybe<PlannedMessage[]>;

  createdAt: DateTime;

  checkInDetails?: Maybe<string>;

  checkOutDetails?: Maybe<string>;

  paymentDetails?: Maybe<string>;

  budgetCents?: Maybe<number>;

  shouldCreateGeofence: boolean;

  reservationStatus?: Maybe<StopReservationStatus>;
}

export interface Ride {
  id: string;

  costCents?: Maybe<number>;

  status?: Maybe<RideStatus>;

  externalScheduledRideId?: Maybe<string>;

  rideType?: Maybe<RideType>;

  flexRideCode?: Maybe<string>;

  flexRideRequestedAt?: Maybe<DateTime>;

  sentFlexRideLinkAt?: Maybe<DateTime>;

  externalRideId?: Maybe<string>;

  routePolyline?: Maybe<string>;

  heartbeats?: Maybe<(Maybe<RideHeartbeat>)[]>;

  lastHeartbeat?: Maybe<RideHeartbeat>;

  stop?: Maybe<AdventureStop>;

  mystery: Adventure;

  origin: Location;

  originEta?: Maybe<DateTime>;

  destination: Location;

  destinationEta?: Maybe<DateTime>;

  pickupTime: DateTime;

  pollAgainAt?: Maybe<DateTime>;

  lineItem?: Maybe<PriceLineItem>;

  createdAt?: Maybe<DateTime>;

  updatedAt: DateTime;
}

export interface RideHeartbeat {
  id: string;

  coordinates: Coordinates;

  bearing?: Maybe<number>;

  recordedAt: DateTime;
}

export interface PriceLineItem {
  id: string;

  price: Price;

  ride?: Maybe<Ride>;

  experience?: Maybe<Experience>;

  xperience?: Maybe<Xperience>;

  giftCard?: Maybe<GiftCard>;

  contract?: Maybe<Contract>;

  cents?: Maybe<number>;

  centsOverride?: Maybe<number>;

  contractUnits?: Maybe<number>;

  contractPremiumUnits?: Maybe<number>;

  totalCentsBeforeComp?: Maybe<number>;

  totalCentsAfterComp?: Maybe<number>;

  percent?: Maybe<number>;

  multiplier: number;

  description?: Maybe<string>;

  category: LineItemCategory;

  comp?: Maybe<PriceComp>;

  createdAt: DateTime;
}

export interface Price {
  id: string;

  adventure: Adventure;

  status: PriceStatus;

  totalCents?: Maybe<number>;

  lineItems?: Maybe<(Maybe<PriceLineItem>)[]>;

  overrideBudgetBlock: boolean;

  overrideBudgetBlockReason?: Maybe<OverrideBudgetBlockReason>;

  overrodeBy?: Maybe<User>;

  stripeChargeId?: Maybe<string>;

  receiptId?: Maybe<string>;

  paidAt?: Maybe<DateTime>;

  comp?: Maybe<PriceComp>;

  createdAt: DateTime;

  entityType?: Maybe<PriceEntity>;

  entityId?: Maybe<string>;

  priceType?: Maybe<PriceType>;

  systemGenerated?: Maybe<boolean>;
}

export interface PriceComp {
  id: string;

  reason: string;

  amountCents?: Maybe<number>;

  fullComp: boolean;

  price?: Maybe<Price>;

  priceLineItem?: Maybe<PriceLineItem>;

  creator: User;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface GiftCard {
  id: string;

  redemptionCode: string;

  stripeChargeId: string;

  buyer: User;

  redeemer?: Maybe<User>;

  initialAmountCents: number;

  remainingAmountCents: number;

  lineItems?: Maybe<(Maybe<PriceLineItem>)[]>;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface Contract {
  id: string;

  name?: Maybe<string>;

  notes?: Maybe<string>;

  premiumUnitCostCents?: Maybe<number>;

  premiumUnitCount?: Maybe<number>;

  premiumUnitInitialCount?: Maybe<number>;

  productType: MysteryProduct;

  renewDate?: Maybe<DateTime>;

  startDate: DateTime;

  status: ContractStatus;

  type: ContractUnitType;

  unitCostCents?: Maybe<number>;

  unitCount: number;

  unitInitialCount: number;

  organization: Organization;

  auditLogs?: Maybe<(Maybe<AuditLog>)[]>;

  authorizedUsers: (Maybe<User>)[];

  teamEvents: (Maybe<TeamEvent>)[];

  pendingUnitCount?: Maybe<number>;

  pendingPremiumUnitCount?: Maybe<number>;

  createdAt: DateTime;

  updatedAt: DateTime;

  deletedAt?: Maybe<DateTime>;
}

export interface Organization {
  id: string;

  name: string;

  internalName?: Maybe<string>;

  avgEventRating: number;

  ratingCount: number;

  createdAt: DateTime;

  updatedAt: DateTime;

  archivedMembers?: Maybe<User[]>;

  members?: Maybe<User[]>;

  membersWithServiceAccounts?: Maybe<User[]>;

  contracts?: Maybe<Contract[]>;

  activeContract?: Maybe<Contract>;

  upcomingEvents?: Maybe<TeamEvent[]>;

  accountManager?: Maybe<User>;

  salesPointOfContact?: Maybe<User>;

  customerSuccessManager?: Maybe<User>;

  crmUrl?: Maybe<string>;

  allTeamEvents?: Maybe<(Maybe<TeamEvent>)[]>;

  teamEvents: TeamEventConnection;

  userTags?: Maybe<(Maybe<UserTag>)[]>;

  isHrisConnected?: Maybe<boolean>;

  hrisIntegrations?: Maybe<(Maybe<HrisIntegration>)[]>;

  manualIntegrationLastUpdated?: Maybe<DateTime>;

  domain?: Maybe<string>;

  location?: Maybe<Location>;

  industry?: Maybe<string>;

  description?: Maybe<string>;

  targetConnections?: Maybe<ScalarTargetConnection[]>;

  engagementGraphApproved?: Maybe<boolean>;

  customerType?: Maybe<CustomerType>;

  rootTeam?: Maybe<Team>;

  nylasServiceAccounts?: Maybe<(Maybe<NylasServiceAccount>)[]>;

  isInsightsEnabled?: Maybe<boolean>;

  teams?: Maybe<(Maybe<Team>)[]>;

  userSignUpPath?: Maybe<string>;

  logoUrl?: Maybe<string>;

  biggestSimilarity?: Maybe<string>;

  biggestDifference?: Maybe<string>;

  inferMembership: boolean;

  trendingScores?: Maybe<IndividualScoreTrend[]>;

  moralePulses?: Maybe<MoralePulse[]>;

  hasMoralePulseBeenSetUp: boolean;

  waitingForFirstMoralePulse: boolean;

  orgOnboardingCompleted: OnboardingCompletion;

  activeMoralePulseQuestions?: Maybe<MoralePulseQuestion[]>;

  isPulseBetaEnabled?: Maybe<boolean>;

  isMeetupsBetaEnabled?: Maybe<boolean>;

  watercoolerInitiative?: Maybe<WatercoolerInitiative>;

  hasTeamEvents: boolean;
}

export interface TeamEvent {
  id: string;

  shortId: string;

  title?: Maybe<string>;

  status?: Maybe<TeamEventStatus>;

  description?: Maybe<string>;

  canceled: boolean;

  joinLink?: Maybe<string>;

  createdAt: DateTime;

  updatedAt: DateTime;

  requestedFor?: Maybe<DateTime>;

  creator?: Maybe<User>;

  owner?: Maybe<User>;

  requestedBy?: Maybe<User>;

  party?: Maybe<(Maybe<User>)[]>;

  partySize?: Maybe<number>;

  virtualEvent?: Maybe<VirtualEvent>;

  boxRecipes?: Maybe<(Maybe<BoxRecipe>)[]>;

  includeDelivery?: Maybe<boolean>;

  invitedGuests: (Maybe<InvitedGuest>)[];

  rsvpSummary?: Maybe<RsvpSummary>;

  deliveryOrders?: Maybe<(Maybe<DeliveryOrder>)[]>;

  deliveryOrderForUser?: Maybe<DeliveryOrder>;

  teamEventIssues?: Maybe<(Maybe<TeamEventIssue>)[]>;

  teamEventMetricSummary?: Maybe<TeamEventMetricSummary>;

  connectionsStrengthened?: Maybe<number>;

  opsPreEventQuestionnaire?: Maybe<Questionnaire>;

  reviewQuestionnaire: Questionnaire;

  preEventQuestionnaire?: Maybe<Questionnaire>;

  preEventQuestionnaires?: Maybe<(Maybe<Questionnaire>)[]>;

  answerForUser?: Maybe<string>;

  questionnairesWithAnswersForHostToReview?: Maybe<(Maybe<Questionnaire>)[]>;

  availableBoxTypes?: Maybe<(Maybe<BoxType>)[]>;

  availableBoxTypesWithCapacityQuantities?: Maybe<(Maybe<BoxTypeWithAvailableCapacity>)[]>;

  availableBoxTypesInStock?: Maybe<(Maybe<BoxType>)[]>;

  availableExtras?: Maybe<(Maybe<ExtraInfo>)[]>;

  signUpExpirationDate?: Maybe<DateTime>;

  experience?: Maybe<Xperience>;

  expectedHeadCount?: Maybe<number>;

  finalHeadCount?: Maybe<number>;

  latestBookingSession?: Maybe<BookingSession>;

  initialPrice?: Maybe<Price>;

  finalizedPrice?: Maybe<Price>;

  feedbackLinksSentAt?: Maybe<DateTime>;

  hubspotDealId?: Maybe<string>;

  surpriseToAttendees?: Maybe<boolean>;

  surpriseToAll?: Maybe<boolean>;

  eventRating: number;

  ratingCount: number;

  isRequestToBook?: Maybe<boolean>;

  organization?: Maybe<Organization>;

  freeEvent?: Maybe<boolean>;

  manualBilling?: Maybe<boolean>;

  invitationsAccepted?: Maybe<number>;

  invitationsSent?: Maybe<number>;

  registeredWithoutInvitationCount?: Maybe<number>;

  participantFamiliarity?: Maybe<number>;

  participationModel?: Maybe<ParticipationModel>;

  purpose?: Maybe<string>;

  nylasEventId?: Maybe<string>;

  goals?: Maybe<(Maybe<TeamEventGoal>)[]>;

  userCompletedFeedback: boolean;

  userDidNotAttend: boolean;

  connectionRating?: Maybe<number>;

  prices?: Maybe<(Maybe<Price>)[]>;

  initialPrices?: Maybe<(Maybe<Price>)[]>;

  finalizedPrices?: Maybe<(Maybe<Price>)[]>;

  costs?: Maybe<EventCosts>;

  payouts: Payout[];

  feedbackReminders?: Maybe<FeedbackReminder[]>;

  testEventType?: Maybe<TestEventType>;

  contract?: Maybe<Contract>;
}

export interface VirtualEvent {
  id: string;

  type?: Maybe<VirtualEventType>;

  durationMins?: Maybe<number>;

  joinEventButtonEnabledLeadTimeMins?: Maybe<number>;

  instructor?: Maybe<User>;

  requireInstructor?: Maybe<boolean>;

  joinBeforeHost?: Maybe<boolean>;

  teamEvent?: Maybe<TeamEvent>;

  chimeMeetings?: Maybe<(Maybe<ChimeMeeting>)[]>;

  zoomMeeting?: Maybe<ZoomMeeting>;

  reviewQuestionnaire?: Maybe<Questionnaire>;

  parentChimeMeeting?: Maybe<ChimeMeeting>;

  attendees?: Maybe<string[]>;

  instructorRating: number;

  ratingCount: number;

  hostTextMessageSent: boolean;
}

export interface ChimeMeeting {
  id?: Maybe<string>;

  inProgress?: Maybe<boolean>;

  meetingId: string;

  region: string;

  responseJson: string;

  virtualEvent?: Maybe<VirtualEvent>;

  parentMeeting?: Maybe<ChimeMeeting>;

  childMeetings?: Maybe<(Maybe<ChimeMeeting>)[]>;

  userIds?: Maybe<(Maybe<string>)[]>;

  createdAt?: Maybe<DateTime>;

  updatedAt?: Maybe<DateTime>;
}

export interface ZoomMeeting {
  id: string;

  meetingId?: Maybe<string>;

  joinUrl?: Maybe<string>;

  startUrl?: Maybe<string>;

  host?: Maybe<ZoomHost>;

  virtualEvent?: Maybe<VirtualEvent>;

  hostJoinedAt?: Maybe<DateTime>;
}

export interface ZoomHost {
  id: string;

  email?: Maybe<string>;

  zoomId?: Maybe<string>;

  zoomMeetings?: Maybe<(Maybe<ZoomMeeting>)[]>;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface Questionnaire {
  id: string;

  questions?: Maybe<(Maybe<Question>)[]>;

  completedUserIds?: Maybe<(Maybe<string>)[]>;

  teamEvent?: Maybe<TeamEvent>;

  defaultFor?: Maybe<DefaultQuestionnaireFor>;
}

export interface Question {
  id: string;

  questionnaire?: Maybe<Questionnaire>;

  answers?: Maybe<(Maybe<Answer>)[]>;

  options?: Maybe<(Maybe<string>)[]>;

  question?: Maybe<string>;

  questionType?: Maybe<QuestionType>;

  subIndex?: Maybe<number>;

  orderIndex?: Maybe<number>;

  scaleMin?: Maybe<number>;

  scaleMax?: Maybe<number>;

  required: boolean;

  requesterOnly?: Maybe<boolean>;

  contractOnly: boolean;

  transactionalOnly: boolean;

  createdAt: DateTime;

  updatedAt: DateTime;

  answerForUser?: Maybe<Answer>;

  subtitle?: Maybe<string>;
}

export interface Answer {
  id: string;

  answer?: Maybe<string>;

  user?: Maybe<User>;

  question?: Maybe<Question>;

  questionnaire?: Maybe<Questionnaire>;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface InvitedGuest {
  id: string;

  user?: Maybe<User>;

  email: string;

  acceptedAt?: Maybe<DateTime>;

  rsvpStatus?: Maybe<InvitedGuestRsvpStatus>;
}

export interface RsvpSummary {
  total?: Maybe<number>;

  accepted?: Maybe<number>;

  declined?: Maybe<number>;
}

export interface TeamEventIssue {
  id: string;

  note?: Maybe<string>;

  categories: TeamEventIssueCategory[];

  teamEvent?: Maybe<TeamEvent>;
}

export interface TeamEventMetricSummary {
  responses?: Maybe<number>;

  rating?: Maybe<TeamEventRating>;

  connectedness?: Maybe<TeamEventConnectedness>;
}

export interface TeamEventRating {
  overallRating?: Maybe<number>;
}

export interface TeamEventConnectedness {
  low?: Maybe<number>;

  neutral?: Maybe<number>;

  strong?: Maybe<number>;
}

export interface BoxTypeWithAvailableCapacity {
  boxType: BoxType;

  capacityLimit?: Maybe<number>;

  totalOrdered: number;
}

export interface ExtraInfo {
  name: string;

  description?: Maybe<string>;

  costCentsEach: number;

  quantity?: Maybe<number>;

  type: DeliveryOrderExtraItemType;
}

export interface BookingSession {
  id: string;

  dateWindows?: Maybe<(Maybe<DateWindow>)[]>;

  selectedEventDetails?: Maybe<(Maybe<string>)[]>;

  partySize?: Maybe<number>;

  budgetCents?: Maybe<number>;

  optionsPresented?: Maybe<(Maybe<BookingOptionsPresented>)[]>;

  productType: MysteryProduct;

  teamEvent?: Maybe<TeamEvent>;

  guestEmails?: Maybe<(Maybe<string>)[]>;

  requester: User;
}

export interface DateWindow {
  startTime?: Maybe<DateTime>;

  endTime?: Maybe<DateTime>;
}

export interface BookingOptionsPresented {
  template: string;

  experiences: (Maybe<Xperience>)[];
}

export interface TeamEventGoal {
  id: string;

  goal: EventGoal;
}

export interface EventCosts {
  costToCustomerCents: number;

  costToMysteryCents: number;

  netGainCents: number;
}

export interface Payout {
  id: string;

  owner: PayoutOwner;

  entity: PayoutEntity;

  amount: Amount;

  reason: PayoutReason;

  dueBy?: Maybe<Date>;

  completedAt?: Maybe<Date>;

  createdAt: Date;

  updatedAt: Date;

  createdBy?: Maybe<User>;

  systemGenerated: boolean;

  estimate: boolean;
}

export interface PayoutOwner {
  ownerType: PayoutOwnerType;

  ownerToken: string;
}

export interface Amount {
  unitType: UnitType;

  unitToken: string;

  unitCount: number;
}

export interface PayoutReason {
  reasonType: PayoutReasonType;

  reasonToken: string;
}

export interface FeedbackReminder {
  user: User;

  completedFeedback: boolean;

  feedbackCompletedAt?: Maybe<DateTime>;

  initialEmailSentAt?: Maybe<DateTime>;

  firstReminderSentAt?: Maybe<DateTime>;

  finalReminderSentAt?: Maybe<DateTime>;
}

export interface TeamEventConnection {
  pageInfo: PageInfo;

  edges: (Maybe<TeamEventEdge>)[];

  count: number;
}

export interface TeamEventEdge {
  node: TeamEvent;

  cursor: string;
}

export interface UserTag {
  id: string;

  name?: Maybe<string>;

  organization?: Maybe<Organization>;

  users?: Maybe<(Maybe<User>)[]>;

  type?: Maybe<string>;
}

export interface HrisIntegration {
  id: string;

  providerName: string;

  lastUpdated?: Maybe<DateTime>;

  state?: Maybe<HrisSyncState>;
}

export interface ScalarTargetConnection {
  userAId: string;

  userBId: string;

  version: number;

  withinTeamImpact: number;

  betweenTeamImpact: number;

  leadershipImpact: number;

  senseOfBelongingImpact: number;

  connectionPotential: number;

  interestSimilarity: number;

  score: number;
}

export interface Team {
  scores: EngagementResult[];

  subScores?: Maybe<EngagementResult[]>;

  id: string;

  name: string;

  members: User[];

  manager?: Maybe<User>;

  childTeams: Team[];

  earliestKnownScoreTime?: Maybe<DateTime>;
}

export interface NylasServiceAccount {
  id: string;

  provider: ServiceAccountProvider;

  googleServiceAccountJson?: Maybe<GoogleServiceAccountJson>;

  organization?: Maybe<Organization>;

  createdAt: DateTime;
}

export interface GoogleServiceAccountJson {
  type: string;

  project_id: string;

  private_key_id: string;

  private_key: string;

  client_email: string;

  client_id: string;

  auth_uri: string;

  token_uri: string;

  auth_provider_x509_cert_url: string;

  client_x509_cert_url: string;
}

export interface IndividualScoreTrend {
  entityId: string;

  deltas: ScoreDelta[];
}

export interface ScoreDelta {
  oneMonthDelta: number;

  scoreType: ScoreType;
}

export interface MoralePulse {
  id: string;

  organization?: Maybe<Organization>;

  cadence?: Maybe<MoralePulseCadence>;

  startDate?: Maybe<Date>;

  enabled?: Maybe<boolean>;

  updatedAt?: Maybe<Date>;

  updatedBy?: Maybe<User>;

  activeQuestions?: Maybe<(Maybe<MoralePulseQuestion>)[]>;

  targetAudience?: Maybe<(Maybe<User>)[]>;

  activeSurvey?: Maybe<MoralePulseSurvey>;
}

export interface MoralePulseQuestion {
  id: string;

  copy: string;

  category?: Maybe<MoralePulseQuestionCategory>;

  options: (Maybe<string>)[];

  version?: Maybe<string>;

  required?: Maybe<boolean>;

  maxValue?: Maybe<number>;

  minValue?: Maybe<number>;

  type?: Maybe<MoralePulseQuestionType>;
}

export interface MoralePulseSurvey {
  id: string;

  organization?: Maybe<Organization>;

  questions?: Maybe<(Maybe<MoralePulseQuestion>)[]>;

  audience?: Maybe<(Maybe<User>)[]>;

  startDate?: Maybe<Date>;

  endDate?: Maybe<Date>;

  status?: Maybe<MoralePulseSurveyStatus>;

  completions?: Maybe<(Maybe<MoralePulseSurveyCompletion>)[]>;

  moralePulse?: Maybe<MoralePulse>;

  hasCompleted?: Maybe<boolean>;

  inAudience?: Maybe<boolean>;
}

export interface MoralePulseSurveyCompletion {
  user?: Maybe<User>;

  survey?: Maybe<MoralePulseSurvey>;

  createdAt?: Maybe<Date>;
}

export interface OnboardingCompletion {
  completedAt?: Maybe<DateTime>;

  status: OrganizationOnboardingStatus;
}

export interface WatercoolerInitiative {
  id: string;

  organization: Organization;

  audience: (Maybe<AudienceMember>)[];

  disabled: boolean;

  disabledAt?: Maybe<DateTime>;

  lastRun?: Maybe<DateTime>;

  runAfter?: Maybe<DateTime>;

  cadenceWeeks?: Maybe<number>;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface AudienceMember {
  id: string;

  user?: Maybe<User>;

  team?: Maybe<Team>;

  userTag?: Maybe<UserTag>;

  organization?: Maybe<Organization>;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface AuditLog {
  id: string;

  actor?: Maybe<User>;

  eventName: EventName;

  metadata?: Maybe<AuditLogMetadata>;

  entityType?: Maybe<string>;

  entityId?: Maybe<string>;

  occurredAt: DateTime;
}

export interface AuditLogMetadata {
  before?: Maybe<Jsonb>;

  after?: Maybe<Jsonb>;
}

export interface AdventureProgress {
  id: string;

  type: ProgressType;

  stop?: Maybe<AdventureStop>;

  adventure: Adventure;

  occurredAt: DateTime;

  createdAt: DateTime;
}

export interface AdventureStopFeedback {
  id: string;

  adventure: Adventure;

  stop: AdventureStop;

  user: User;

  adventureFeedback?: Maybe<AdventureFeedback>;

  createdAt: DateTime;

  updatedAt: DateTime;

  rating?: Maybe<number>;

  hadBeenPreviously?: Maybe<boolean>;

  comments?: Maybe<string>;
}

export interface AdventureFeedback {
  id: string;

  adventure: Adventure;

  user: User;

  stopsFeedback?: Maybe<(Maybe<AdventureStopFeedback>)[]>;

  createdAt: DateTime;

  updatedAt: DateTime;

  rating?: Maybe<number>;

  comments?: Maybe<string>;

  wouldRetransact?: Maybe<boolean>;

  plannedFrequency?: Maybe<MysteryFrequency>;

  nps?: Maybe<number>;
}

export interface CreditCard {
  id: string;

  last4: string;

  expMonth: number;

  expYear: number;

  createdBy: User;
}

export interface XperienceUsaShipping {
  enabled: boolean;

  estimatedLeadTimeDays?: Maybe<number>;

  estimatedCostCents?: Maybe<number>;

  excludeStates: string[];
}

export interface XperienceInternationalShipping {
  enabled: boolean;

  estimatedLeadTimeDays?: Maybe<number>;

  estimatedCostCents?: Maybe<number>;

  excludeCountries: string[];
}

export interface AttireType {
  id: string;

  name: AttireTypeOptions;

  defaultSubTypes?: Maybe<(Maybe<SubAttireType>)[]>;
}

export interface SubAttireType {
  id: string;

  name: string;

  category: SubAttireTypeCategory;

  xperiences?: Maybe<(Maybe<Xperience>)[]>;
}

export interface DietaryRestriction {
  id: string;

  name: string;

  orderIndex: number;

  createdAt?: Maybe<DateTime>;

  deletedAt?: Maybe<DateTime>;
}

export interface XperienceCategory {
  id: string;

  name: string;
}

export interface Interest {
  id: string;

  name: string;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface Tag {
  id: string;

  name: string;

  isUsedByCode: boolean;

  creator: User;

  createdAt: DateTime;

  experiences?: Maybe<(Maybe<Xperience>)[]>;
}

export interface MysteryTemplate {
  id: string;

  code: string;

  title: string;

  date?: Maybe<DateTime>;

  orderIndex: number;

  description?: Maybe<string>;

  color?: Maybe<string>;

  photoUrl?: Maybe<string>;

  quote?: Maybe<string>;

  logoUrl?: Maybe<string>;

  type: MysteryType;

  updatedAt: DateTime;

  createdAt: DateTime;

  rangeIdealGuests?: Maybe<(Maybe<number>)[]>;

  estimatedDurationMins?: Maybe<number>;

  includesBreakoutRooms?: Maybe<boolean>;

  connectionFocusAreas?: Maybe<(Maybe<ConnectionFocusArea>)[]>;

  experiences: (Maybe<Xperience>)[];

  cost?: Maybe<Cost>;

  categories?: Maybe<(Maybe<XperienceCategory>)[]>;

  theme?: Maybe<MysteryTheme>;

  minimumCost?: Maybe<MysteryTemplateMinimumCost>;
}

export interface Cost {
  id: string;

  ctcCents: number;

  ctcUnit: CostUnit;

  ctcVolumeDiscounts?: Maybe<(Maybe<VolumeDiscount>)[]>;

  ctcPricingBands: PricingBand[];

  ctmCents: number;

  ctmUnit: CostUnit;

  ctmVolumeDiscounts?: Maybe<(Maybe<VolumeDiscount>)[]>;

  ctmTieredEventPricing?: Maybe<(Maybe<TieredEventPricing>)[]>;

  ctmPerPersonCentsMinimum?: Maybe<number>;

  cancellationPolicy?: Maybe<string>;

  agreedOn?: Maybe<Date>;
}

export interface VolumeDiscount {
  minNumUsers: number;

  percentDiscount: number;
}

export interface PricingBand {
  minUsers: number;

  maxUsers?: Maybe<number>;

  cents: number;

  credits?: Maybe<number>;

  unit: CostUnit;
}

export interface TieredEventPricing {
  minNumUsers: number;

  ctmCents: number;
}

export interface MysteryTheme {
  id: string;

  title: string;

  orderIndex: number;

  logoUrl?: Maybe<string>;

  templates?: Maybe<(Maybe<MysteryTemplate>)[]>;

  updatedAt: DateTime;

  createdAt: DateTime;
}

export interface MysteryTemplateMinimumCost {
  unitType: UnitType;

  unitToken: UnitToken;

  amount: number;
}

export interface Partner {
  id: string;

  name: string;

  description?: Maybe<string>;

  status: PartnerStatus;

  tier?: Maybe<PartnerTier>;

  quality?: Maybe<PartnerQuality>;

  location?: Maybe<Location>;

  url?: Maybe<string>;

  phone?: Maybe<string>;

  email?: Maybe<string>;

  cardOnFile?: Maybe<CreditCard>;

  numHosts?: Maybe<number>;

  permissionToRecord: boolean;

  supportedVideoPlatforms: (Maybe<PartnerVideoPlatform>)[];

  notes?: Maybe<Markdown>;

  creator: User;

  owners: (Maybe<User>)[];

  experiences: (Maybe<Xperience>)[];

  auditLogs: (Maybe<AuditLog>)[];

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface PartnerVideoPlatform {
  platform?: Maybe<VirtualEventType>;
}

export interface Schedule {
  id: string;

  rrule: string;

  startTime: string;

  durationMins: number;

  capacity?: Maybe<number>;

  xperience: Xperience;

  events?: Maybe<(Maybe<Event>)[]>;

  futureEvents?: Maybe<(Maybe<Event>)[]>;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface OperatingHours {
  id: string;

  experience: Experience;

  open: OperatingHoursTime;

  close?: Maybe<OperatingHoursTime>;

  createdAt: DateTime;
}

export interface OperatingHoursTime {
  day: number;

  hours: number;

  minutes: number;

  time: string;
}

export interface Approval {
  id: string;

  actor: User;

  entityType?: Maybe<string>;

  entityId?: Maybe<string>;

  occurredAt: DateTime;
}

export interface ExperienceCategory {
  id: string;

  name: string;

  type: ExperienceType;

  restrictions: ExperienceRestriction[];

  createdAt: DateTime;
}

export interface ExperienceRestriction {
  id: string;

  name: string;

  categories?: Maybe<(Maybe<ExperienceCategory>)[]>;

  subCategories?: Maybe<(Maybe<ExperienceSubCategory>)[]>;

  createdAt: DateTime;
}

export interface ExperienceSubCategory {
  id: string;

  name: string;

  category: ExperienceCategory;

  restrictions: ExperienceRestriction[];

  createdAt: DateTime;
}

export interface LegalAgreement {
  id: string;

  document: LegalDocument;

  user: User;

  acceptedAt: DateTime;
}

export interface LegalDocument {
  id: string;

  type: LegalDocumentType;

  version: number;

  content: string;

  createdAt: DateTime;
}

export interface Heartbeat {
  id: string;

  location: Point;

  accuracy: number;

  altitude?: Maybe<number>;

  altitudeAccuracy?: Maybe<number>;

  heading?: Maybe<number>;

  speed?: Maybe<number>;

  user: User;

  adventure: Adventure;

  occurredAt: DateTime;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface Point {
  type: string;

  coordinates: number[];
}

export interface Message {
  id: string;

  content: string;

  user: User;

  opsUser?: Maybe<User>;

  adventure?: Maybe<Adventure>;

  type: MessageType;

  readAt?: Maybe<DateTime>;

  attachments?: Maybe<string[]>;

  externalSource?: Maybe<MessageExternalSource>;

  createdAt: DateTime;
}

export interface PushNotification {
  id: string;

  notificationId: string;

  type: PushNotificationType;

  data: Jsonb;

  metadata: Jsonb;

  user: User;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface PreferenceAnswer {
  id: string;

  label: string;

  orderIndex: number;

  selectAll: boolean;

  deselectAll: boolean;

  value?: Maybe<number>;

  question: PreferenceQuestion;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface PreferenceQuestion {
  id: string;

  type?: Maybe<PreferenceType>;

  question: string;

  subTitle?: Maybe<string>;

  answers?: Maybe<PreferenceQuestionAnswer[]>;

  preferenceAnswers?: Maybe<PreferenceAnswer[]>;

  category: PreferenceCategory;

  multiSelect: boolean;

  emoji?: Maybe<string>;

  orderIndex: number;

  createdAt: DateTime;

  updatedAt: DateTime;

  questionType?: Maybe<string>;
}

export interface PreferenceQuestionAnswer {
  label: string;

  value: number;
}

export interface FoodPreference {
  type: Cuisine;

  value?: Maybe<number>;
}

export interface Restriction {
  type: RestrictionType;

  value: string;
}

export interface DeliveryOrderConnection {
  pageInfo: PageInfo;

  edges: (Maybe<DeliveryEdge>)[];

  count: number;
}

export interface DeliveryEdge {
  node: DeliveryOrder;

  cursor: string;
}

export interface OrganizationRole {
  id: string;

  name: OrganizationRoleName;
}

export interface NylasAssociation {
  id: string;

  accessToken: string;

  nylasId: string;

  provider: string;

  syncState: string;

  email: string;

  primaryCalendarId?: Maybe<string>;
}

export interface MessageConnection {
  pageInfo: PageInfo;

  edges: (Maybe<MessageEdge>)[];

  count: number;
}

export interface MessageEdge {
  node: Message;

  cursor: string;
}

export interface BudgetOption {
  id: string;

  type: MysteryType;

  minCents?: Maybe<number>;

  maxCents?: Maybe<number>;

  priceLevel?: Maybe<number>;

  labelLong?: Maybe<string>;

  labelShort?: Maybe<string>;

  planningFeeCents: number;
}

export interface ActivityPreference {
  type: ActivityType;
}

export interface FoodPreferenceTypeWithImage {
  type: Cuisine;

  image: string;
}

export interface ActivityPreferenceTypeWithImage {
  type: ActivityType;

  image: string;
}

export interface AuditLogConnection {
  pageInfo: PageInfo;

  edges: (Maybe<AuditLogEdge>)[];

  count: number;
}

export interface AuditLogEdge {
  node: AuditLog;

  cursor: string;
}

export interface EstimatedCost {
  contract?: Maybe<Contract>;

  contractUnitsUsed?: Maybe<number>;

  contractPremiumUnitsUsed?: Maybe<number>;

  contractOverageCents?: Maybe<number>;

  estimatedTotalCents?: Maybe<number>;
}

export interface BlackoutDate {
  id: string;

  blackedOutDate: DateTime;

  mysteryType?: Maybe<MysteryType>;

  reason?: Maybe<string>;

  creator?: Maybe<User>;
}

export interface BookingSessionOptions {
  eventSize: PartySize[];

  budget: BudgetOption[];
}

export interface PartySize {
  label: string;

  value: number;
}

export interface AvailabilityInformation {
  timeSlots: (Maybe<TimeSlot>)[];

  emailsNotOnProvider: (Maybe<string>)[];

  hasWorkingHours?: Maybe<boolean>;
}

export interface TimeSlot {
  emails: (Maybe<string>)[];

  status: string;

  startTime: DateTime;

  endTime: DateTime;
}

export interface CapacityTimeWindow {
  start: DateTime;

  windowDuration: number;

  timezone: string;
}

export interface Capacity {
  date: DateTime;

  availability?: Maybe<number>;

  timesAvailable?: Maybe<(Maybe<CapacityTimeWindow>)[]>;
}

export interface Attendee {
  attendeeId: string;

  userId: string;
}

export interface ScalarEngagementEdge {
  id: string;

  fromUserId: string;

  toUserId: string;

  rowNumber: number;

  version: string;

  connection: number;

  relationship: Relationship;
}

export interface OrganizationScore {
  id: string;

  organization: Organization;

  rowNumber: number;

  version: string;

  withinTeamConnection: number;

  betweenTeamConnection: number;

  leadershipConnection: number;

  senseOfBelonging: number;
}

export interface EventConnection {
  pageInfo: PageInfo;

  edges: (Maybe<EventEdge>)[];

  count: number;
}

export interface EventEdge {
  node: Event;

  cursor: string;
}

export interface ExperienceConnection {
  pageInfo: PageInfo;

  edges: (Maybe<ExperienceEdge>)[];

  count: number;
}

export interface ExperienceEdge {
  node: Experience;

  cursor: string;
}

export interface MoralePulseAnswerSummary {
  id: string;

  question: MoralePulseQuestion;

  lowestPossibleValue: number;

  highestPossibleValue: number;

  averageResponseValue: number;

  prevAverageResponseValue?: Maybe<number>;

  questionTrend?: Maybe<number>;

  buckets?: Maybe<MoralePulseAnswerSummaryBucket[]>;
}

export interface MoralePulseAnswerSummaryBucket {
  percentValue: number;

  label: string;

  description?: Maybe<string>;

  sentiment: MoralePulseResponseSentiment;

  questionTrend?: Maybe<number>;
}

export interface MoralePulseParticipationSummary {
  surveyIds: string[];

  audienceCount: number;

  completedCount: number;

  responseRate: number;
}

export interface MoralePulseSurveyReportingInterval {
  interval: MoralePulseSurveyReportingIntervalType;

  timeframeData: MoralePulseSurveyReportingTimeframe[];
}

export interface MoralePulseSurveyReportingTimeframe {
  key: string;

  displayString: string;

  surveyIds: string[];

  type: MoralePulseSurveyReportingIntervalType;

  startDate: Date;

  endDate: Date;
}

/** An entry point to get richer data about a particular question and its responses */
export interface MoralePulseQuestionSummary {
  id: string;

  question: MoralePulseQuestion;
  /** A high-level overview of responses and trend for a particular audience or timeframe. */
  answerSummary?: Maybe<MoralePulseAnswerSummary>;

  timeseriesValuesConnection: MoralePulseAnswerSummaryTimeseriesValuesConnection;
  /** Lists comments made on answers to this question over a particular timeframe. */
  answers: MoralePulseAnswer[];
}

export interface MoralePulseAnswerSummaryTimeseriesValuesConnection {
  timeseriesValues: MoralePulseAnswerSummaryTimeseriesValue[];
}

export interface MoralePulseAnswerSummaryTimeseriesValue {
  question?: Maybe<MoralePulseQuestion>;

  startDate: Date;

  endDate: Date;

  scoreAverage: number;

  trend?: Maybe<number>;

  prevSurveyEndDate?: Maybe<Date>;

  prevSurveyScoreAverage?: Maybe<number>;

  respondentsCount: number;

  buckets?: Maybe<MoralePulseAnswerSummaryBucket[]>;
}

export interface MoralePulseAnswer {
  id: string;

  question?: Maybe<MoralePulseQuestion>;

  survey?: Maybe<MoralePulseSurvey>;

  value?: Maybe<number>;

  valueDescription?: Maybe<string>;

  comment?: Maybe<string>;

  answeredAt: Date;

  sentiment: MoralePulseResponseSentiment;
}

export interface MagicLinkPulseOrganization {
  name?: Maybe<string>;

  logoUrl?: Maybe<string>;
}

export interface MagicLinkPulseSurveyWrapper {
  id?: Maybe<string>;

  email?: Maybe<string>;

  success: boolean;

  survey?: Maybe<MagicLinkPulseSurvey>;
}

export interface MagicLinkPulseSurvey {
  id: string;

  questions: MagicLinkPulseQuestion[];
}

export interface MagicLinkPulseQuestion {
  id: string;

  copy: string;

  options: string[];

  type: MoralePulseQuestionType;
}

export interface MysteryTemplateConnection {
  pageInfo: PageInfo;

  edges: (Maybe<MysteryTemplateEdge>)[];

  count: number;
}

export interface MysteryTemplateEdge {
  node: MysteryTemplate;

  cursor: string;
}

export interface OrganizationConnection {
  pageInfo: PageInfo;

  edges: (Maybe<OrganizationEdge>)[];

  count: number;
}

export interface OrganizationEdge {
  node: Organization;

  cursor: string;
}

export interface PartnerConnection {
  pageInfo: PageInfo;

  edges: (Maybe<PartnerEdge>)[];

  count: number;
}

export interface PartnerEdge {
  node: Partner;

  cursor: string;
}

export interface UserAnswers {
  user?: Maybe<User>;

  answers?: Maybe<(Maybe<Answer>)[]>;

  reviewed?: Maybe<boolean>;
}

export interface DefaultQuestionnaire {
  id: string;

  questions?: Maybe<(Maybe<Question>)[]>;

  defaultFor?: Maybe<DefaultQuestionnaireFor>;
}

export interface FeedbackComplete {
  teamEventId?: Maybe<string>;

  feedbackComplete?: Maybe<boolean>;
}

export interface TeamEventMatches {
  experiences?: Maybe<(Maybe<Xperience>)[]>;

  looseFitIndex?: Maybe<number>;
}

export interface ScrapedEventConnection {
  pageInfo: PageInfo;

  edges: (Maybe<ScrapedEventEdge>)[];

  count: number;
}

export interface ScrapedEventEdge {
  node: ScrapedEvent;

  cursor: string;
}

export interface ScrapedEvent {
  id: string;

  source: EventScraperSource;

  eventDate: DateTime;

  title: string;

  url?: Maybe<string>;

  categories?: Maybe<string[]>;

  venueName?: Maybe<string>;

  address?: Maybe<string>;

  neighborhood?: Maybe<string>;

  location?: Maybe<Point>;

  phone?: Maybe<string>;

  description?: Maybe<string>;

  priceText?: Maybe<string>;

  priceCents?: Maybe<number>;

  dateText?: Maybe<string>;

  start?: Maybe<DateTime>;

  end?: Maybe<DateTime>;

  createdAt: DateTime;

  updatedAt: DateTime;

  scrapedAt: DateTime;
}

export interface TagConnection {
  pageInfo: PageInfo;

  edges: (Maybe<TagEdge>)[];

  count: number;
}

export interface TagEdge {
  node: Tag;

  cursor: string;
}

export interface TagMapping {
  interactive?: Maybe<(Maybe<string>)[]>;

  entertaining?: Maybe<(Maybe<string>)[]>;

  bonding?: Maybe<(Maybe<string>)[]>;

  celebratory?: Maybe<(Maybe<string>)[]>;

  thankful?: Maybe<(Maybe<string>)[]>;

  meet?: Maybe<(Maybe<string>)[]>;

  relaxing?: Maybe<(Maybe<string>)[]>;
}

export interface TaskFlow {
  id: string;

  flow: TaskFlowType;

  subFlow: TaskFlowSubType;

  scope: TaskFlowScope;

  owner: TaskFlowOwner;

  assignee: TaskFlowAssignee;

  status: TaskFlowStatus;

  priority: TaskFlowPriority;

  createdAt: Date;

  updatedAt: Date;
}

export interface TaskFlowScope {
  type: TaskFlowScopeType;

  token: string;
}

export interface TaskFlowOwner {
  type: TaskFlowOwnerType;

  token: string;
}

export interface TaskFlowAssignee {
  type: TaskFlowAssigneeType;

  token: string;
}

export interface DateRange {
  startTime?: Maybe<DateTime>;

  endTime?: Maybe<DateTime>;
}

export interface UserConnection {
  pageInfo: PageInfo;

  edges: (Maybe<UserEdge>)[];

  count: number;
}

export interface UserEdge {
  node: User;

  cursor: string;
}

export interface VirtualEventConnection {
  pageInfo: PageInfo;

  edges: (Maybe<VirtualEventEdge>)[];

  count: number;
}

export interface VirtualEventEdge {
  node: VirtualEvent;

  cursor: string;
}

export interface VirtualExperience {
  id: string;

  name: string;

  description?: Maybe<string>;

  url?: Maybe<string>;

  photoUrl?: Maybe<string>;

  phone?: Maybe<string>;

  estimatedDurationMins: number;

  tags: (Maybe<Tag>)[];

  partner: Partner;

  events?: Maybe<(Maybe<VirtualEvent>)[]>;

  futureEvents?: Maybe<(Maybe<VirtualEvent>)[]>;

  cost?: Maybe<Cost>;

  owner?: Maybe<User>;

  updatedAt?: Maybe<DateTime>;

  createdAt?: Maybe<DateTime>;
}

export interface Watercooler {
  id: string;

  scheduledFor: DateTime;

  status: WatercoolerStatus;

  goal: WatercoolerGoal;

  sharedInterests: (Maybe<string>)[];

  participants: (Maybe<User>)[];

  reviewquestionnaire: Questionnaire;

  virtualEvent: VirtualEvent;

  organization: Organization;

  createdAt: DateTime;

  updatedAt: DateTime;
}

export interface TeamScore {
  withinTeamConnection: number;

  betweenTeamConnection: number;

  leadershipConnection: number;

  senseOfBelonging: number;
}

export interface XperienceConnection {
  pageInfo: PageInfo;

  edges: (Maybe<XperienceEdge>)[];

  count: number;
}

export interface XperienceEdge {
  node: Xperience;

  cursor: string;
}

export interface TemplateWithEvents {
  template: MysteryTemplate;

  events: EventsWithExperiences[];
}

export interface EventsWithExperiences {
  event: DummyEvent;

  experiences: Xperience[];
}

export interface DummyEvent {
  id?: Maybe<string>;

  start: DateTime;

  end: DateTime;
}

export interface Mutation {
  searchAuditLogs?: Maybe<AuditLog[]>;

  generatePriceForAdventure?: Maybe<Query>;

  generatePriceForTeamEvent?: Maybe<Price>;

  regeneratePriceForMystery?: Maybe<Query>;

  regeneratePriceForTeamEvent?: Maybe<Price>;

  updatePrice?: Maybe<Query>;

  overrideBudgetBlock?: Maybe<Query>;

  chargeCustomerForAdventure?: Maybe<Query>;

  chargeCustomerForTeamEvent?: Maybe<Query>;

  generatePriceFromStripeCharge: Price;

  disassociatePrices: Price[];

  generateContractChargesForTeamEvent: Price[];

  abandonPrices: Price[];

  addBlackoutDate?: Maybe<BlackoutDate>;

  deleteBlackoutDate?: Maybe<BlackoutDate>;

  createBookingSession?: Maybe<BookingSession>;

  updateBookingSession?: Maybe<BookingSession>;

  updateBudgetOption?: Maybe<BudgetOption>;

  sendChatMessage?: Maybe<boolean>;

  addChimeAttendee: ChimeAttendeeResponse;

  autoCreateBreakoutRooms: (Maybe<BreakoutRoom>)[];

  createChimeMeeting: ChimeMeetingResponse;

  joinBreakoutRooms: ChimeMeeting;

  kickAllUsers?: Maybe<boolean>;

  manuallyAssignBreakoutRooms: (Maybe<BreakoutRoom>)[];

  createContract: Contract;

  updateContract: Contract;

  archiveContract: Contract;

  addCreditCard?: Maybe<CreditCard>;

  createDeliveryOrder: DeliveryOrder;

  updateDeliveryOrder: DeliveryOrder;

  resendDeliveryOrderReceiptEmail: DeliveryOrder;

  dev?: Maybe<DevMutations>;

  createEvent?: Maybe<Event>;

  updateEvent?: Maybe<Event>;

  deleteEvent?: Maybe<Event>;

  searchEvents?: Maybe<Event[]>;

  createExperienceCategory?: Maybe<ExperienceCategory>;

  deleteExperienceCategory?: Maybe<ExperienceCategory>;

  searchExperienceCategories?: Maybe<(Maybe<ExperienceCategory>)[]>;

  createExperienceSubCategory?: Maybe<ExperienceSubCategory>;

  deleteExperienceSubCategory?: Maybe<ExperienceSubCategory>;

  searchExperienceSubCategories?: Maybe<(Maybe<ExperienceSubCategory>)[]>;

  createGiftCard: GiftCard;

  redeemGiftCard: Query;

  createInventoryItem?: Maybe<InventoryItem>;

  updateInventoryItem?: Maybe<InventoryItem>;

  deleteInventoryItem?: Maybe<boolean>;

  searchInventoryItems: (Maybe<InventoryItem>)[];

  updateInventoryItemQuantity: InventoryItemQuantityChangeLog;

  createBoxRecipe?: Maybe<BoxRecipe>;

  updateBoxRecipe?: Maybe<BoxRecipe>;

  deleteBoxRecipe?: Maybe<boolean>;

  searchBoxRecipes: (Maybe<BoxRecipe>)[];

  createBoxType?: Maybe<BoxType>;

  updateBoxType?: Maybe<BoxType>;

  deleteBoxType?: Maybe<boolean>;

  searchBoxTypes: (Maybe<BoxType>)[];

  updateRsvpStatus: InvitedGuest;

  acceptLegalAgreement?: Maybe<Query>;

  createLegalDocument?: Maybe<Query>;

  acceptLatestLegalAgreements?: Maybe<User>;

  sendMessage: Message;

  sendGroupMessage: Message;

  markMessagesAsRead?: Maybe<Query>;

  markMysteryMessagesAsRead?: Maybe<Query>;

  createMoralePulse?: Maybe<MoralePulse>;

  updateMoralePulse?: Maybe<MoralePulse>;

  disableMoralePulse?: Maybe<boolean>;

  updateMoralePulseSurvey?: Maybe<MoralePulseSurvey>;

  saveMoralePulseSurveyResponses?: Maybe<boolean>;

  respondToMagicLinkPulseSurvey?: Maybe<boolean>;

  createMysteryTemplate?: Maybe<MysteryTemplate>;

  editMysteryTemplate?: Maybe<MysteryTemplate>;

  searchMysteryTemplates?: Maybe<MysteryTemplate[]>;

  createMysteryTheme?: Maybe<MysteryTheme>;

  createOrganization: Organization;

  updateOrganizationMembers: Organization;

  addOrganizationMembers: Organization;

  removeOrganizationMembers: Organization;

  archiveOrganizationMembers: Organization;

  restoreArchivedOrganizationMembers: Organization;

  updateOrganization: Organization;

  mergeOrganizations: Organization;

  refreshHris?: Maybe<Organization>;

  refreshSingleHris?: Maybe<Organization>;

  updateDomain: Organization;

  adminUpdateDomain: Organization;

  sendAccountSetupReminders: Organization;

  addUsersFromCsv?: Maybe<Organization>;

  addMemberToOrganization?: Maybe<Organization>;

  updateOrganizationSimilarities: Organization[];

  togglePulseBeta?: Maybe<boolean>;

  createPartner: Partner;

  updatePartner: Partner;

  deletePartner?: Maybe<Partner>;

  searchPartners?: Maybe<(Maybe<Partner>)[]>;

  createOpsAdjustmentForTeamEvent?: Maybe<Payout>;

  deleteOpsAdjustmentsForTeamEvent?: Maybe<(Maybe<Payout>)[]>;

  updatePlannedMessages?: Maybe<Query>;

  sendPlannedMessage?: Maybe<Query>;

  compMystery?: Maybe<Adventure>;

  compTeamEvent?: Maybe<TeamEvent>;

  compLineItem?: Maybe<Price>;

  createPromo?: Maybe<Query>;

  createQuestionnaire: Questionnaire;

  updateQuestionnaire: Questionnaire;

  updateDefaultQuestionnaire: DefaultQuestionnaire;

  answerQuestion?: Maybe<Answer>;

  completeQuestionnaire: (Maybe<Question>)[];

  completeQuestionnaireMetrics?: Maybe<(Maybe<string>)[]>;

  reviewQuestionnaireUsers: Questionnaire;

  updateRating?: Maybe<Query>;

  updateUsersOrganizationRoles: User[];

  createScheduledAdventure?: Maybe<ScheduledAdventure>;

  updateScheduledAdventure?: Maybe<Query>;

  cancelScheduledAdventure?: Maybe<Query>;

  search?: Maybe<(Maybe<SearchResult>)[]>;

  createShortLink?: Maybe<string>;

  createMysteryUnlimitedSubscription: User;

  cancelMysteryUnlimitedSubscription: User;

  createTag?: Maybe<Tag>;

  updateTag?: Maybe<Tag>;

  mergeTag?: Maybe<Query>;

  deleteTag?: Maybe<Query>;

  searchTags?: Maybe<Tag[]>;

  createTaskFlow: TaskFlow;

  updateTaskFlow: TaskFlow;

  advanceTaskFlow: TaskFlow;

  rewindTaskFlow: TaskFlow;

  generateOnboardingTasks: TaskFlow[];

  editTeamName?: Maybe<boolean>;

  changeManager?: Maybe<boolean>;

  changeManagers?: Maybe<boolean>;

  createTeam: Team;

  toggleUserDidNotAttend?: Maybe<boolean>;

  cancelTeamEvent?: Maybe<TeamEvent>;

  createTeamEvent: TeamEvent;

  userCreateTeamEvent: TeamEvent;

  updateTeamEvent: TeamEvent;

  addUserToTeamEvent: TeamEvent;

  addUserToTeamEventGuestList: TeamEvent;

  bulkCreateUserAndAddToGuestList?: Maybe<(Maybe<User>)[]>;

  bulkAddUsersToTeamEventGuestList: TeamEvent;

  removeUserFromTeamEvent: TeamEvent;

  removeUserFromTeamEventGuestList: TeamEvent;

  createTeamEventDeliveryOrder: TeamEvent;

  updateTeamEventOwner: TeamEvent;

  userGetExistingEventsForTemplate?: Maybe<(Maybe<DateRange>)[]>;

  createTeamEventIssue: TeamEventIssue;

  updateTeamEventIssue: TeamEventIssue;

  logOut?: Maybe<boolean>;

  createUser?: Maybe<User>;

  updateUser?: Maybe<User>;

  updateViewer?: Maybe<User>;

  reassociateFinchEmployees?: Maybe<boolean>;

  setForceSms?: Maybe<User>;

  deleteUser?: Maybe<boolean>;

  searchUsers?: Maybe<User[]>;

  addOrUpdateSourceForUser?: Maybe<User>;

  ignoreFinchAssociation?: Maybe<boolean>;
  /** Submit an answer to a user preference question */
  answerPreferenceQuestion?: Maybe<User>;
  /** Updates the viewer's dietary restrictions */
  updateDietaryRestrictions?: Maybe<User>;

  createUserTags?: Maybe<(Maybe<UserTag>)[]>;

  updateUserTags?: Maybe<User>;

  addTagsToUsers?: Maybe<(Maybe<User>)[]>;

  removeTagsFromUsers?: Maybe<(Maybe<User>)[]>;

  addRemoveTagsFromUsers?: Maybe<(Maybe<User>)[]>;

  deleteUserTags?: Maybe<boolean>;

  renameUserTag?: Maybe<UserTag>;

  updateVirtualEvent: VirtualEvent;

  addUserToVirtualEvent: VirtualEvent;

  addAttendeeToVirtualEvent: VirtualEvent;

  removeAttendeeFromVirtualEvent: VirtualEvent;

  createVirtualExperience?: Maybe<VirtualExperience>;

  updateVirtualExperience?: Maybe<VirtualExperience>;

  createWatercooler?: Maybe<Watercooler>;

  createWatercoolerInitiative?: Maybe<WatercoolerInitiative>;

  updateWatercoolerInitiative?: Maybe<WatercoolerInitiative>;

  deleteWatercoolerInitiative?: Maybe<WatercoolerInitiative>;

  createXperience?: Maybe<Xperience>;

  updateXperience?: Maybe<Xperience>;

  deleteXperience?: Maybe<Xperience>;

  searchXperiences?: Maybe<(Maybe<Xperience>)[]>;

  resendZoomLoginEmail: ZoomMeeting;

  forceCreateZoomMeeting: ZoomMeeting;

  createZoomMeeting: ZoomMeeting;
}

export interface ChimeAttendeeResponse {
  attendeeId: string;

  joinToken: string;

  responseJson: string;
}

export interface BreakoutRoom {
  chimeMeeting: ChimeMeeting;

  users: (Maybe<User>)[];
}

export interface ChimeMeetingResponse {
  id: string;

  meetingId: string;

  responseJson: string;
}

export interface DevMutations {
  wipeViewerUserData?: Maybe<User>;

  deleteXperience?: Maybe<Xperience>;
}

export interface SearchResult {
  id: string;

  name: string;

  description?: Maybe<string>;

  entityType: string;

  parentId?: Maybe<string>;
}

export interface AvailableCuisinesResponse {
  cuisine: Cuisine;

  cheapestDeliveredCostCents?: Maybe<number>;
}

export interface AvailableMeal {
  distanceMeters: number;

  meal: DeliveryMeal;

  deliveryOptions?: Maybe<(Maybe<DeliveryOption>)[]>;
}

export interface DeliveryOption {
  name: string;

  costCents: number;

  description?: Maybe<string>;
}

export interface ClosestDeliveryMealResponse {
  meal: DeliveryMeal;

  distanceMeters: number;
}

export interface DirectTeamEngagementScore extends EngagementResult, EngagementScore {
  scoreType: ScoreType;

  value: number;

  trend?: Maybe<ScoreTrend>;

  oneMonthDelta?: Maybe<number>;

  day: DateTime;

  team: Team;
}

export interface Email {
  id: string;

  category: EmailCategory;

  toUser?: Maybe<User>;

  subject?: Maybe<string>;

  contentHtml?: Maybe<string>;

  contentText?: Maybe<string>;

  createdAt: DateTime;

  updatedAt: DateTime;

  emailStatus?: Maybe<EmailStatus>;
}

export interface ExperienceWithEventId {
  experience: Xperience;

  eventId?: Maybe<string>;
}

export interface FlexRide {
  id: string;

  hasRequested?: Maybe<boolean>;
}

export interface HeartbeatPolyline {
  userId: string;

  polyline: string;
}

export interface IndividualEngagementScore extends EngagementResult, EngagementScore {
  scoreType: ScoreType;

  value: number;

  trend?: Maybe<ScoreTrend>;

  oneMonthDelta?: Maybe<number>;

  day: DateTime;

  teamName?: Maybe<string>;

  version?: Maybe<string>;
}

export interface PayoutEntityReference {
  entityType: PayoutEntityType;

  entityId: string;
}

export interface PersonPickerAnswer {
  id?: Maybe<string>;

  name?: Maybe<string>;
}

export interface PersonRaterAnswer {
  userId?: Maybe<string>;

  value?: Maybe<RelationshipOptions>;
}

export interface PersonRaterOption {
  userId?: Maybe<string>;

  label?: Maybe<string>;
}

export interface Preference {
  type: PreferenceType;

  value?: Maybe<number>;
}

export interface ReorderPostmatesReturn {
  pickupReadyAt: Date;

  postmatesDeliveryId: string;
}

export interface ReservationTime {
  attributes: (Maybe<string>)[];

  covers?: Maybe<number>;

  dateTime?: Maybe<DateTime>;

  offers?: Maybe<(Maybe<string>)[]>;

  points?: Maybe<number>;

  premium?: Maybe<boolean>;

  premiumAccessPriceAmount?: Maybe<number>;

  ticketed?: Maybe<boolean>;

  timeString?: Maybe<string>;

  url?: Maybe<string>;
}

export interface RideDriver {
  id: string;

  name?: Maybe<string>;

  rating?: Maybe<string>;

  phone?: Maybe<string>;

  phoneExpiresAt?: Maybe<DateTime>;

  imageUrl?: Maybe<string>;

  vehicleColor?: Maybe<string>;

  vehicleMake?: Maybe<string>;

  vehicleModel?: Maybe<string>;

  vehicleLicensePlate?: Maybe<string>;

  vehicleImageUrl?: Maybe<string>;
}

export interface RRule {
  freq: RRuleFrequency;

  dtstart?: Maybe<DateTime>;

  interval?: Maybe<number>;

  wkst?: Maybe<number>;

  count?: Maybe<number>;

  until?: Maybe<DateTime>;

  tzid?: Maybe<string>;

  bysetpos?: Maybe<(Maybe<number>)[]>;

  bymonth?: Maybe<(Maybe<number>)[]>;

  bymonthday?: Maybe<(Maybe<number>)[]>;

  bynmonthday?: Maybe<(Maybe<number>)[]>;

  byyearday?: Maybe<(Maybe<number>)[]>;

  byweekno?: Maybe<(Maybe<number>)[]>;

  byweekday?: Maybe<(Maybe<number>)[]>;

  bynweekday?: Maybe<(Maybe<number>)[][]>;

  byhour?: Maybe<(Maybe<number>)[]>;

  byminute?: Maybe<(Maybe<number>)[]>;

  bysecond?: Maybe<(Maybe<number>)[]>;

  byeaster?: Maybe<number>;
}

export interface ScrubbedEngagementScore extends EngagementResult {
  scoreType: ScoreType;

  day: DateTime;
}

export interface TransitiveTeamEngagementScore extends EngagementResult, EngagementScore {
  scoreType: ScoreType;

  value: number;

  trend?: Maybe<ScoreTrend>;

  oneMonthDelta?: Maybe<number>;

  day: DateTime;

  team: Team;
}

export interface TeamEventMetric {
  id: string;

  value?: Maybe<number>;

  type: TeamEventMetricType;

  createdAt: DateTime;
}

export interface TemplateWithExperiences {
  template: MysteryTemplate;

  experiences: (Maybe<XperienceWithEvents>)[];
}

export interface XperienceWithEvents {
  experience: Xperience;

  events: (Maybe<Event>)[];
}

// ====================================================
// Arguments
// ====================================================

export interface AdventureQueryArgs {
  id?: Maybe<string>;

  shortId?: Maybe<string>;
}
export interface MysteriesConnectionQueryArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  order?: Maybe<(Maybe<MysteriesOrderArgs>)[]>;

  ids?: Maybe<(Maybe<string>)[]>;

  ownerIds?: Maybe<(Maybe<string>)[]>;

  search?: Maybe<string>;

  showUnassigned?: Maybe<boolean>;

  userId?: Maybe<string>;

  status?: Maybe<(Maybe<AdventureStatus>)[]>;

  billingOnly?: Maybe<boolean>;

  dateRange?: Maybe<(Maybe<DateTime>)[]>;

  region?: Maybe<(Maybe<string>)[]>;
}
export interface AuditLogsConnectionQueryArgs {
  after?: Maybe<string>;

  first?: Maybe<number>;

  entityTypes?: Maybe<(Maybe<string>)[]>;

  eventNames?: Maybe<(Maybe<EventName>)[]>;

  actorId?: Maybe<string>;

  startDate?: Maybe<DateTime>;

  endDate?: Maybe<DateTime>;
}
export interface BillableAdventuresQueryArgs {
  includePaid?: Maybe<boolean>;

  includeUnpaid?: Maybe<boolean>;

  includeNeedsPrice?: Maybe<boolean>;
}
export interface EstimatePriceForTeamEventQueryArgs {
  teamEventId: string;
}
export interface BudgetOptionsQueryArgs {
  type?: Maybe<MysteryType>;
}
export interface CheckGroupCalendarAvailabilityQueryArgs {
  emails?: Maybe<(Maybe<string>)[]>;
}
export interface TimesAvailableForDateQueryArgs {
  date: DateTime;

  type: MysteryType;
}
export interface UpcomingCapacityQueryArgs {
  type?: Maybe<MysteryType>;

  skipAvailability?: Maybe<boolean>;
}
export interface CurrentBusinessLeadTimeHoursQueryArgs {
  type?: Maybe<MysteryType>;
}
export interface ChimeMeetingQueryArgs {
  id: string;
}
export interface ChimeMeetingAttendeesQueryArgs {
  id: string;
}
export interface ChimeMeetingByVirtualEventQueryArgs {
  virtualEventId: string;
}
export interface ContractQueryArgs {
  id: string;
}
export interface OrganizationContractsQueryArgs {
  organizationId: string;
}
export interface GetDeliveryMarketsQueryArgs {
  deliveryProvider?: Maybe<DeliveryProvider>;
}
export interface GetDeliveryMarketQueryArgs {
  id: string;
}
export interface GetDeliveryOrderQueryArgs {
  id: string;
}
export interface FindDeliveryOrderForUserAndTeamEventQueryArgs {
  userId: string;

  teamEventId: string;
}
export interface DeliveryOrderConnectionQueryArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  order?: Maybe<(Maybe<DeliveryOrderOrderArgs>)[]>;

  search?: Maybe<string>;

  ids?: Maybe<(Maybe<string>)[]>;

  userId?: Maybe<string>;

  mealStatus?: Maybe<(Maybe<DeliveryStatus>)[]>;

  internalStatus?: Maybe<(Maybe<DeliveryInternalStatus>)[]>;

  methodOfDelivery?: Maybe<(Maybe<DeliveryOrderMethod>)[]>;

  dateRange?: Maybe<(Maybe<DateTime>)[]>;

  market?: Maybe<string>;

  teamEvent?: Maybe<string>;

  mealProvider?: Maybe<(Maybe<string>)[]>;
}
export interface GetDeliveryMarketForLocationQueryArgs {
  location?: Maybe<LocationInput>;
}
export interface LatestEngagementEdgesQueryArgs {
  organizationId: string;

  version?: Maybe<string>;
}
export interface OrganizationEngagementScoreQueryArgs {
  organizationId: string;
}
export interface EventQueryArgs {
  id: string;
}
export interface EventsConnectionQueryArgs {
  after?: Maybe<string>;

  first?: Maybe<number>;

  order?: Maybe<(Maybe<EventOrderArgs>)[]>;

  filterInput?: Maybe<EventFilterInput>;
}
export interface ExperienceQueryArgs {
  id: string;
}
export interface ExperiencesConnectionQueryArgs {
  after?: Maybe<string>;

  first?: Maybe<number>;

  order?: Maybe<(Maybe<ExperiencesOrderArgs>)[]>;

  search?: Maybe<string>;
}
export interface InventoryItemQueryArgs {
  id: string;
}
export interface BoxRecipeQueryArgs {
  id: string;
}
export interface BoxTypeQueryArgs {
  id: string;
}
export interface AvailableBoxTypesQueryArgs {
  locationInput?: Maybe<LocationInput>;
}
export interface BoxRecipeLineItemQueryArgs {
  id: string;
}
export interface LegalDocumentQueryArgs {
  type: LegalDocumentType;

  version?: Maybe<number>;
}
export interface MessageQueryArgs {
  id: string;
}
export interface MessagesByIdsQueryArgs {
  ids: (Maybe<string>)[];
}
export interface MessagesQueryArgs {
  userIds?: Maybe<(Maybe<string>)[]>;

  first?: Maybe<number>;
}
export interface MessagesConnectionQueryArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  order?: Maybe<(Maybe<MessagesOrderArgs>)[]>;

  ids?: Maybe<(Maybe<string>)[]>;

  userIds?: Maybe<(Maybe<string>)[]>;
}
export interface SummarizeSurveyAnswersQueryArgs {
  surveyIds: string[];

  audienceIds: string[];

  duration?: Maybe<DateWindowInput>;
}
export interface SummarizeSurveyParticipationQueryArgs {
  surveyIds: string[];

  audienceIds: string[];
}
export interface MoralePulseSurveyQueryArgs {
  id: string;
}
export interface MoralePulseSurveysQueryArgs {
  surveyIds: string[];
}
export interface MoralePulseActiveSurveysQueryArgs {
  organizationId: string;
}
export interface MoralePulseReportingTimeframesQueryArgs {
  organizationId?: Maybe<string>;

  localTimezone?: Maybe<string>;
}
export interface MoralePulseSurveyAnswerSummaryQueryArgs {
  audienceIds: string[];

  timeframe?: Maybe<DateWindowInput>;

  organizationId?: Maybe<string>;
}
export interface MoralePulseSurveyParticipationSummaryQueryArgs {
  audienceIds: string[];

  timeframe?: Maybe<DateWindowInput>;

  organizationId?: Maybe<string>;
}
export interface MoralePulseQuestionSummaryQueryArgs {
  questionId: string;

  organizationId?: Maybe<string>;
}
export interface MoralePulseQuestionSummariesQueryArgs {
  questionIds: string[];

  organizationId?: Maybe<string>;
}
export interface MagicLinkPulseOrganizationQueryArgs {
  magicLinkId: string;
}
export interface MagicLinkPulseSurveyQueryArgs {
  magicLinkId: string;

  email: string;
}
export interface MysteryTemplateQueryArgs {
  id?: Maybe<string>;

  code?: Maybe<string>;
}
export interface MysteryTemplatesConnectionQueryArgs {
  after?: Maybe<string>;

  first?: Maybe<number>;

  order?: Maybe<(Maybe<MysteryTemplateOrderArgs>)[]>;

  search?: Maybe<string>;

  filterInput?: Maybe<TemplateFilterInput>;
}
export interface OrganizationQueryArgs {
  id: string;
}
export interface OrganizationsQueryArgs {
  name?: Maybe<string>;

  internalName?: Maybe<string>;
}
export interface OrganizationsConnectionQueryArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  order?: Maybe<(Maybe<OrganizationOrderArgs>)[]>;

  search?: Maybe<string>;
}
export interface GetPendingOrgMembersQueryArgs {
  id: string;
}
export interface GetOrgByLinkPathQueryArgs {
  path: string;
}
export interface GetOrganizationMembersQueryArgs {
  userIds?: Maybe<string[]>;
}
export interface PartnerQueryArgs {
  id: string;
}
export interface PartnersConnectionQueryArgs {
  after?: Maybe<string>;

  first?: Maybe<number>;

  order?: Maybe<(Maybe<PartnerOrderArgs>)[]>;

  filterInput?: Maybe<PartnerFilterInput>;
}
export interface PayoutQueryArgs {
  id: string;
}
export interface PayoutsForTeamEventQueryArgs {
  teamEventId: string;
}
export interface PlannedMessagesQueryArgs {
  productId: string;

  isDelivery?: Maybe<boolean>;
}
export interface PromoCodeQueryArgs {
  code: string;
}
export interface QuestionQueryArgs {
  id: string;
}
export interface QuestionnaireQueryArgs {
  id: string;
}
export interface QuestionnaireAnswersQueryArgs {
  id: string;
}
export interface DefaultQuestionnaireQueryArgs {
  defaultFor?: Maybe<DefaultQuestionnaireFor>;
}
export interface ReviewQuestionnairesQueryArgs {
  product?: Maybe<MysteryProduct>;

  productId?: Maybe<string>;
}
export interface CompletedFeedbackQueryArgs {
  teamEventIds?: Maybe<(Maybe<string>)[]>;
}
export interface FindMatchingExperiencesForTeamEventsQueryArgs {
  rules: Rules;

  strict?: Maybe<boolean>;
}
export interface ScheduledAdventureQueryArgs {
  id: string;
}
export interface ScrapedEventsConnectionQueryArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  source?: Maybe<EventScraperSource>;

  search?: Maybe<string>;

  date?: Maybe<DateTime>;

  priceCents?: Maybe<PriceCentsInput>;

  categories?: Maybe<string[]>;

  neighborhoods?: Maybe<string[]>;
}
export interface CheckIfEventsHaveBeenScrapedForDateQueryArgs {
  date: DateTime;
}
export interface GetAllScrapedEventCategoriesForDateQueryArgs {
  date: DateTime;
}
export interface GetAllScrapedEventNeighborhoodsForDateQueryArgs {
  date: DateTime;
}
export interface ServiceRegionQueryArgs {
  lat: number;

  lng: number;
}
export interface TagQueryArgs {
  id: string;
}
export interface TagsConnectionQueryArgs {
  after?: Maybe<string>;

  first?: Maybe<number>;

  order?: Maybe<(Maybe<TagOrderArgs>)[]>;

  search?: Maybe<string>;
}
export interface TaskFlowQueryArgs {
  id: string;
}
export interface TaskFlowsForUserQueryArgs {
  userId: string;
}
export interface TaskFlowsForOrganizationQueryArgs {
  organizationId: string;
}
export interface InferTaskFlowQueryArgs {
  scope: TaskFlowScopeInput;

  flow: TaskFlowType;

  subFlow?: Maybe<TaskFlowSubType>;
}
export interface ListRequiredOnboardingTasksFlowsQueryArgs {
  userId: string;
}
export interface TeamInMyOrgQueryArgs {
  id?: Maybe<string>;
}
export interface SearchTeamsQueryArgs {
  orgId: string;

  name?: Maybe<string>;
}
export interface TeamEventQueryArgs {
  id: string;
}
export interface TeamEventsConnectionQueryArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  order?: Maybe<(Maybe<TeamEventOrderArgs>)[]>;

  ids?: Maybe<(Maybe<string>)[]>;

  ownerIds?: Maybe<(Maybe<string>)[]>;

  search?: Maybe<string>;

  teamEventStatus?: Maybe<(Maybe<TeamEventStatus>)[]>;

  dateRange?: Maybe<(Maybe<DateTime>)[]>;

  participantId?: Maybe<string>;

  organizationId?: Maybe<string>;

  requestedBy?: Maybe<string>;

  goals?: Maybe<(Maybe<EventGoal>)[]>;
}
export interface UserGetExistingEventsForTemplateQueryArgs {
  templateID?: Maybe<string>;

  windows?: Maybe<(Maybe<Window>)[]>;
}
export interface GetTeamEventIssuesQueryArgs {
  teamEventId: string;
}
export interface UserQueryArgs {
  id?: Maybe<string>;

  phone?: Maybe<string>;
}
export interface UsersQueryArgs {
  ids?: Maybe<(Maybe<string>)[]>;

  isAssociatedWithAdventure?: Maybe<boolean>;

  role?: Maybe<Role>;
}
export interface UsersConnectionQueryArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  order?: Maybe<(Maybe<UsersOrderArgs>)[]>;

  search?: Maybe<string>;

  organizationArgs?: Maybe<OrganizationArgs>;

  userTags?: Maybe<(Maybe<string>)[]>;

  showServiceAccounts?: Maybe<boolean>;
}
export interface OrgUsersConnectionQueryArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  order?: Maybe<(Maybe<UsersOrderArgs>)[]>;

  search?: Maybe<string>;

  organizationArgs?: Maybe<OrganizationArgs>;

  userTags?: Maybe<(Maybe<string>)[]>;

  teamArgs?: Maybe<TeamArgs>;

  teams?: Maybe<(Maybe<string>)[]>;

  accountStatuses?: Maybe<(Maybe<AccountStatus>)[]>;

  orgRoles?: Maybe<(Maybe<string>)[]>;

  showServiceAccounts?: Maybe<boolean>;
}
export interface GetUsersWithTagsQueryArgs {
  tagIds: (Maybe<string>)[];
}
export interface SearchUserTagsQueryArgs {
  orgId: string;

  name?: Maybe<string>;
}
export interface VirtualEventQueryArgs {
  id: string;
}
export interface VirtualEventsConnectionQueryArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  order?: Maybe<(Maybe<VirtualEventOrderArgs>)[]>;

  ids?: Maybe<(Maybe<string>)[]>;

  ownerIds?: Maybe<(Maybe<string>)[]>;

  dateRange?: Maybe<(Maybe<DateTime>)[]>;
}
export interface VirtualExperienceQueryArgs {
  id: string;
}
export interface WatercoolerQueryArgs {
  id: string;
}
export interface WatercoolersForAudienceQueryArgs {
  orgId: string;

  audience?: Maybe<AudienceMemberInput[]>;

  startDate?: Maybe<DateTime>;

  endDate?: Maybe<DateTime>;
}
export interface WatercoolerInitiativeQueryArgs {
  id: string;
}
export interface WatercoolerInitiativeByOrgQueryArgs {
  orgId: string;
}
export interface AverageConnectionScoresForAudienceQueryArgs {
  orgId: string;

  audience: AudienceMemberInput[];

  startDate?: Maybe<DateTime>;

  endDate?: Maybe<DateTime>;
}
export interface AverageFeedbackRatingForWatercoolerQueryArgs {
  orgId: string;

  audience?: Maybe<AudienceMemberInput[]>;

  startDate?: Maybe<DateTime>;

  endDate?: Maybe<DateTime>;
}
export interface UserInWatercoolerInitiativeAudienceQueryArgs {
  userId: string;

  orgId: string;
}
export interface XperienceQueryArgs {
  id: string;
}
export interface XperiencesConnectionQueryArgs {
  after?: Maybe<string>;

  first?: Maybe<number>;

  order?: Maybe<(Maybe<XperiencesOrderArgs>)[]>;

  search?: Maybe<string>;

  adventureID?: Maybe<string>;

  rules?: Maybe<(Maybe<RuleName>)[]>;

  ruleOptions?: Maybe<RuleOptions>;

  filterInput?: Maybe<XperienceFilterInput>;
}
export interface FindEventsForXperiencesQueryArgs {
  experiences?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  startTime?: Maybe<DateTime>;

  endTime?: Maybe<DateTime>;
}
export interface ZoomMeetingQueryArgs {
  id: string;
}
export interface ZoomHostQueryArgs {
  id: string;
}
export interface ZoomHostPasswordQueryArgs {
  zoomHostId: string;
}
export interface MessagesConnectionAdventureArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  order?: Maybe<(Maybe<MessagesOrderArgs>)[]>;
}
export interface StopByOrderIndexAdventureArgs {
  orderIndex: number;
}
export interface MysteriesUserArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  order?: Maybe<(Maybe<MysteriesOrderArgs>)[]>;

  status?: Maybe<(Maybe<AdventureStatus>)[]>;
}
export interface DeliveryOrdersUserArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  order?: Maybe<(Maybe<DeliveryOrderOrderArgs>)[]>;

  status?: Maybe<(Maybe<DeliveryStatus>)[]>;
}
export interface TeamEventsUserArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  order?: Maybe<(Maybe<TeamEventOrderArgs>)[]>;
}
export interface TeamEventsOrganizationArgs {
  first?: Maybe<number>;

  after?: Maybe<string>;

  order?: Maybe<(Maybe<TeamEventOrderArgs>)[]>;
}
export interface TrendingScoresOrganizationArgs {
  individualCount?: Maybe<number>;
}
export interface DeliveryOrderForUserTeamEventArgs {
  id: string;
}
export interface AnswerForUserTeamEventArgs {
  id: string;
}
export interface AnswerForUserQuestionArgs {
  id: string;
}
export interface ScoresTeamArgs {
  dateCutoff?: Maybe<DateTime>;

  entityType?: Maybe<EntityType>;

  version?: Maybe<string>;
}
export interface SubScoresTeamArgs {
  dateCutoff?: Maybe<DateTime>;

  scoreType?: Maybe<ScoreType>;

  entityType?: Maybe<EntityType>;

  version?: Maybe<string>;
}
export interface HasCompletedMoralePulseSurveyArgs {
  userId: string;
}
export interface InAudienceMoralePulseSurveyArgs {
  userId: string;
}
export interface AnswerSummaryMoralePulseQuestionSummaryArgs {
  timeframe?: Maybe<DateWindowInput>;

  audienceIds: string[];
}
export interface TimeseriesValuesConnectionMoralePulseQuestionSummaryArgs {
  timeframe?: Maybe<DateWindowInput>;

  audienceIds: string[];
}
export interface AnswersMoralePulseQuestionSummaryArgs {
  timeframe?: Maybe<DateWindowInput>;

  onlyWithComments?: Maybe<boolean>;
}
export interface SearchAuditLogsMutationArgs {
  entityType?: Maybe<string>;

  entityId?: Maybe<string>;

  startDate?: Maybe<DateTime>;

  endDate?: Maybe<DateTime>;

  actorId?: Maybe<string>;
}
export interface GeneratePriceForAdventureMutationArgs {
  adventureId: string;
}
export interface GeneratePriceForTeamEventMutationArgs {
  teamEventId: string;
}
export interface RegeneratePriceForMysteryMutationArgs {
  id?: Maybe<string>;

  shortId?: Maybe<string>;
}
export interface RegeneratePriceForTeamEventMutationArgs {
  id?: Maybe<string>;
}
export interface UpdatePriceMutationArgs {
  priceId: string;

  lineItems?: Maybe<(Maybe<PriceLineItemInput>)[]>;
}
export interface OverrideBudgetBlockMutationArgs {
  shortId: string;

  override: boolean;

  reason?: Maybe<OverrideBudgetBlockReason>;
}
export interface ChargeCustomerForAdventureMutationArgs {
  adventureId: string;

  userId: string;

  shouldEmailReceipt?: Maybe<boolean>;
}
export interface ChargeCustomerForTeamEventMutationArgs {
  teamEventId: string;

  userId: string;

  shouldEmailReceipt?: Maybe<boolean>;
}
export interface GeneratePriceFromStripeChargeMutationArgs {
  teamEventId: string;

  stripeChargeId?: Maybe<string>;

  priceType?: Maybe<PriceType>;
}
export interface DisassociatePricesMutationArgs {
  priceInputs: DisassociatePricesInput[];
}
export interface GenerateContractChargesForTeamEventMutationArgs {
  contractChargeInputs: CreateContractChargeInput[];
}
export interface AbandonPricesMutationArgs {
  priceIds: string[];
}
export interface AddBlackoutDateMutationArgs {
  blackedOutDate: DateTime;

  mysteryType?: Maybe<MysteryType>;

  reason?: Maybe<string>;
}
export interface DeleteBlackoutDateMutationArgs {
  id?: Maybe<string>;
}
export interface CreateBookingSessionMutationArgs {
  bookingSession?: Maybe<CreateBookingSessionInput>;
}
export interface UpdateBookingSessionMutationArgs {
  id: string;

  bookingSession?: Maybe<UpdateBookingSessionInput>;
}
export interface UpdateBudgetOptionMutationArgs {
  budgetId: string;

  budgetOption: BudgetOptionInput;
}
export interface SendChatMessageMutationArgs {
  message: ChatMessageInput;
}
export interface AddChimeAttendeeMutationArgs {
  chimeMeetingId: string;
}
export interface AutoCreateBreakoutRoomsMutationArgs {
  parentChimeMeetingId: string;

  users: (Maybe<GenericReferenceInput>)[];

  numberOfRooms?: Maybe<number>;
}
export interface CreateChimeMeetingMutationArgs {
  mediaRegion: string;

  virtualEventId: string;
}
export interface JoinBreakoutRoomsMutationArgs {
  parentChimeMeetingId: string;
}
export interface KickAllUsersMutationArgs {
  chimeMeetingId: string;
}
export interface ManuallyAssignBreakoutRoomsMutationArgs {
  parentChimeMeetingId: string;

  userGroups?: Maybe<(Maybe<GenericReferenceInput>)[][]>;
}
export interface CreateContractMutationArgs {
  name?: Maybe<string>;

  notes?: Maybe<string>;

  organization: GenericReferenceInput;

  premiumUnitCostCents?: Maybe<number>;

  premiumUnitInitialCount?: Maybe<number>;

  productType: MysteryProduct;

  renewDate: DateTime;

  startDate: DateTime;

  type: ContractUnitType;

  unitCostCents?: Maybe<number>;

  unitInitialCount: number;
}
export interface UpdateContractMutationArgs {
  id: string;

  name?: Maybe<string>;

  notes?: Maybe<string>;

  premiumUnitCount?: Maybe<number>;

  premiumUnitCostCents?: Maybe<number>;

  productType: MysteryProduct;

  renewDate: DateTime;

  startDate: DateTime;

  status: ContractStatus;

  type: ContractUnitType;

  unitCount: number;

  unitCostCents?: Maybe<number>;

  authorizedUsers?: Maybe<GenericReferenceInput[]>;
}
export interface ArchiveContractMutationArgs {
  id: string;
}
export interface AddCreditCardMutationArgs {
  info: CreditCardInput;
}
export interface CreateDeliveryOrderMutationArgs {
  requester: DeliveryOrderRequesterInput;

  stripeToken?: Maybe<string>;

  numMeals?: Maybe<number>;

  expectedCostCents?: Maybe<number>;

  donationCents?: Maybe<number>;

  selectedBoxPriceCents?: Maybe<number>;

  selectedBoxType?: Maybe<GenericReferenceInput>;

  selectedDeliveryPriceCents?: Maybe<number>;

  selectedCuisines?: Maybe<(Maybe<string>)[]>;

  dropoffDate?: Maybe<DateTime>;

  dropoffLocation: LocationInput;

  deliveryProvider?: Maybe<DeliveryProvider>;

  note?: Maybe<string>;

  deliveryMarketId?: Maybe<string>;

  recipient?: Maybe<DeliveryOrderRecipientInput>;

  marketingAttributions?: Maybe<MarketingAttributions>;

  noteToOps?: Maybe<string>;

  mealManifest?: Maybe<GenericReferenceInput>;

  meal?: Maybe<GenericReferenceInput>;

  extras?: Maybe<(Maybe<ExtraInfoInput>)[]>;

  justTheBox?: Maybe<boolean>;

  teamEvent?: Maybe<GenericReferenceInput>;
}
export interface UpdateDeliveryOrderMutationArgs {
  id: string;

  requester?: Maybe<DeliveryOrderRequesterInput>;

  boxRecipe?: Maybe<GenericReferenceInput>;

  requestedFor: DateTime;

  recipient?: Maybe<DeliveryOrderRecipientInput>;

  dropoffLocation: LocationInput;

  internalStatus: DeliveryInternalStatus;

  internalNote?: Maybe<string>;
}
export interface ResendDeliveryOrderReceiptEmailMutationArgs {
  id: string;
}
export interface CreateEventMutationArgs {
  experience: XperienceInput;

  schedule?: Maybe<GenericReferenceInput>;

  capacityType: CapacityType;

  totalCapacity: number;

  notes?: Maybe<string>;

  cost?: Maybe<CostInput>;

  location?: Maybe<LocationInput>;

  start: DateTime;

  end: DateTime;

  generated?: Maybe<boolean>;
}
export interface UpdateEventMutationArgs {
  id: string;

  experience?: Maybe<XperienceInput>;

  schedule?: Maybe<GenericReferenceInput>;

  capacityType?: Maybe<CapacityType>;

  remainingCapacity?: Maybe<number>;

  totalCapacity?: Maybe<number>;

  notes?: Maybe<string>;

  cost?: Maybe<CostInput>;

  location?: Maybe<LocationInput>;

  start?: Maybe<DateTime>;

  end?: Maybe<DateTime>;
}
export interface DeleteEventMutationArgs {
  id: string;
}
export interface SearchEventsMutationArgs {
  query?: Maybe<string>;

  startDate: DateTime;

  endDate: DateTime;
}
export interface CreateExperienceCategoryMutationArgs {
  name: string;

  type: ExperienceType;
}
export interface DeleteExperienceCategoryMutationArgs {
  id: string;
}
export interface SearchExperienceCategoriesMutationArgs {
  query: string;
}
export interface CreateExperienceSubCategoryMutationArgs {
  name: string;

  category: ReferenceCategoryInput;
}
export interface DeleteExperienceSubCategoryMutationArgs {
  id: string;
}
export interface SearchExperienceSubCategoriesMutationArgs {
  query: string;
}
export interface CreateGiftCardMutationArgs {
  amountCents: number;

  userId?: Maybe<string>;

  confirmationEmailAddress?: Maybe<string>;

  stripeToken?: Maybe<string>;
}
export interface RedeemGiftCardMutationArgs {
  redemptionCode: string;
}
export interface CreateInventoryItemMutationArgs {
  input: InventoryItemInput;
}
export interface UpdateInventoryItemMutationArgs {
  id: string;

  input: InventoryItemInput;
}
export interface DeleteInventoryItemMutationArgs {
  id: string;
}
export interface SearchInventoryItemsMutationArgs {
  query: string;
}
export interface UpdateInventoryItemQuantityMutationArgs {
  id: string;

  deltaQuantity: number;
}
export interface CreateBoxRecipeMutationArgs {
  input: BoxRecipeInput;
}
export interface UpdateBoxRecipeMutationArgs {
  id: string;

  input: BoxRecipeInput;
}
export interface DeleteBoxRecipeMutationArgs {
  id: string;
}
export interface SearchBoxRecipesMutationArgs {
  query: string;
}
export interface CreateBoxTypeMutationArgs {
  input: BoxTypeInput;
}
export interface UpdateBoxTypeMutationArgs {
  id: string;

  input: BoxTypeInput;
}
export interface DeleteBoxTypeMutationArgs {
  id: string;
}
export interface SearchBoxTypesMutationArgs {
  query: string;
}
export interface UpdateRsvpStatusMutationArgs {
  email: string;

  teamEventId: string;

  rsvpStatus: InvitedGuestRsvpStatus;
}
export interface AcceptLegalAgreementMutationArgs {
  userId: string;

  documentId: string;
}
export interface CreateLegalDocumentMutationArgs {
  type: LegalDocumentType;

  content: string;
}
export interface SendMessageMutationArgs {
  content: string;

  toPhone: string;

  adventureId?: Maybe<string>;
}
export interface SendGroupMessageMutationArgs {
  content: string;

  toPhones: string[];

  adventureId?: Maybe<string>;
}
export interface MarkMessagesAsReadMutationArgs {
  ids: (Maybe<string>)[];
}
export interface MarkMysteryMessagesAsReadMutationArgs {
  mysteryId: string;
}
export interface CreateMoralePulseMutationArgs {
  settings: MoralePulseSettings;
}
export interface UpdateMoralePulseMutationArgs {
  pulseId?: Maybe<string>;

  settings: MoralePulseSettings;
}
export interface DisableMoralePulseMutationArgs {
  pulseId?: Maybe<string>;
}
export interface UpdateMoralePulseSurveyMutationArgs {
  surveyId: string;

  settings: MoralePulseSurveySettings;
}
export interface SaveMoralePulseSurveyResponsesMutationArgs {
  surveyId: string;

  responses?: Maybe<(Maybe<MoralePulseQuestionReponse>)[]>;
}
export interface RespondToMagicLinkPulseSurveyMutationArgs {
  magicLinkId: string;

  email: string;

  answers: MagicLinkAnswer[];
}
export interface CreateMysteryTemplateMutationArgs {
  code: string;

  title: string;

  description?: Maybe<string>;

  date?: Maybe<DateTime>;

  theme?: Maybe<GenericReferenceInput>;

  logoUrl?: Maybe<string>;

  photoUrl?: Maybe<string>;

  quote?: Maybe<string>;

  color?: Maybe<string>;

  orderIndex?: Maybe<number>;

  type?: Maybe<MysteryType>;

  cost?: Maybe<CostInput>;

  categories?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  rangeIdealGuests?: Maybe<(Maybe<number>)[]>;

  estimatedDurationMins?: Maybe<number>;

  includesBreakoutRooms?: Maybe<boolean>;

  connectionFocusAreas?: Maybe<(Maybe<ConnectionFocusArea>)[]>;
}
export interface EditMysteryTemplateMutationArgs {
  templateId: string;

  template: MysteryTemplateInput;
}
export interface SearchMysteryTemplatesMutationArgs {
  query?: Maybe<string>;
}
export interface CreateMysteryThemeMutationArgs {
  title: string;

  orderIndex: number;

  templates?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  logoUrl?: Maybe<string>;
}
export interface CreateOrganizationMutationArgs {
  name: string;

  members?: Maybe<ReferenceUserInput[]>;

  crmUrl?: Maybe<string>;
}
export interface UpdateOrganizationMembersMutationArgs {
  id: string;

  members: MemberInput[];

  doNotSendEmails?: Maybe<boolean>;

  preserveServiceAccounts?: Maybe<boolean>;
}
export interface AddOrganizationMembersMutationArgs {
  id: string;

  addMembers?: Maybe<ReferenceUserInput[]>;
}
export interface RemoveOrganizationMembersMutationArgs {
  id: string;

  removeMembers?: Maybe<ReferenceUserInput[]>;
}
export interface ArchiveOrganizationMembersMutationArgs {
  id: string;

  usersToArchive: string[];
}
export interface RestoreArchivedOrganizationMembersMutationArgs {
  id: string;

  usersToRestore: string[];
}
export interface UpdateOrganizationMutationArgs {
  id: string;

  crmUrl?: Maybe<string>;

  name: string;

  internalName?: Maybe<string>;

  domain?: Maybe<string>;

  accountManager?: Maybe<ReferenceUserInput>;

  salesPointOfContact?: Maybe<ReferenceUserInput>;

  customerSuccessManager?: Maybe<ReferenceUserInput>;

  location?: Maybe<LocationInput>;

  industry?: Maybe<string>;

  description?: Maybe<string>;

  engagementGraphApproved?: Maybe<boolean>;

  customerType?: Maybe<CustomerType>;

  userSignUpPath?: Maybe<string>;

  logoUrl?: Maybe<string>;

  inferMembership?: Maybe<boolean>;

  isPulseBetaEnabled?: Maybe<boolean>;

  isMeetupsBetaEnabled?: Maybe<boolean>;
}
export interface MergeOrganizationsMutationArgs {
  toMerge: string;

  toMergeInto: string;
}
export interface RefreshHrisMutationArgs {
  id: string;
}
export interface RefreshSingleHrisMutationArgs {
  integrationId: string;
}
export interface UpdateDomainMutationArgs {
  orgId: string;

  domain: string;
}
export interface AdminUpdateDomainMutationArgs {
  orgId: string;

  domain: string;
}
export interface SendAccountSetupRemindersMutationArgs {
  orgId: string;

  emails: string[];
}
export interface AddUsersFromCsvMutationArgs {
  users: UserFromCsv[];

  orgId: string;
}
export interface AddMemberToOrganizationMutationArgs {
  orgId: string;

  userId: string;
}
export interface UpdateOrganizationSimilaritiesMutationArgs {
  similarityInputs: OrgSimilarityInput[];
}
export interface TogglePulseBetaMutationArgs {
  orgId: string;

  enabled: boolean;
}
export interface CreatePartnerMutationArgs {
  name: string;

  cardOnFile?: Maybe<GenericReferenceInput>;

  description?: Maybe<string>;

  email?: Maybe<string>;

  location?: Maybe<LocationInput>;

  notes?: Maybe<Markdown>;

  numHosts?: Maybe<number>;

  owners?: Maybe<(Maybe<ReferenceUserInput>)[]>;

  permissionToRecord?: Maybe<boolean>;

  phone?: Maybe<string>;

  quality?: Maybe<PartnerQuality>;

  status?: Maybe<PartnerStatus>;

  supportedVideoPlatforms?: Maybe<(Maybe<PartnerVideoPlatformInput>)[]>;

  tier?: Maybe<PartnerTier>;

  url?: Maybe<string>;
}
export interface UpdatePartnerMutationArgs {
  id: string;

  cardOnFile?: Maybe<GenericReferenceInput>;

  description?: Maybe<string>;

  email?: Maybe<string>;

  location?: Maybe<LocationInput>;

  name?: Maybe<string>;

  notes?: Maybe<Markdown>;

  numHosts?: Maybe<number>;

  owners?: Maybe<(Maybe<ReferenceUserInput>)[]>;

  permissionToRecord?: Maybe<boolean>;

  phone?: Maybe<string>;

  quality?: Maybe<PartnerQuality>;

  status?: Maybe<PartnerStatus>;

  supportedVideoPlatforms?: Maybe<(Maybe<PartnerVideoPlatformInput>)[]>;

  tier?: Maybe<PartnerTier>;

  url?: Maybe<string>;
}
export interface DeletePartnerMutationArgs {
  id: string;
}
export interface SearchPartnersMutationArgs {
  query: string;
}
export interface CreateOpsAdjustmentForTeamEventMutationArgs {
  teamEventId: string;

  adjustment: CreateOpsAdjustmentForTeamEvent;
}
export interface DeleteOpsAdjustmentsForTeamEventMutationArgs {
  payoutIds: string[];
}
export interface UpdatePlannedMessagesMutationArgs {
  productId: string;

  plannedMessages: (Maybe<PlannedMessageInput>)[];

  isDelivery: boolean;
}
export interface SendPlannedMessageMutationArgs {
  messageId: string;
}
export interface CompMysteryMutationArgs {
  mysteryId: string;

  reason: string;

  amountCents?: Maybe<number>;
}
export interface CompTeamEventMutationArgs {
  teamEventId: string;

  reason: string;

  initialBilling?: Maybe<boolean>;

  amountCents?: Maybe<number>;
}
export interface CompLineItemMutationArgs {
  lineItemId: string;

  reason: string;

  amountCents?: Maybe<number>;
}
export interface CreatePromoMutationArgs {
  code: string;

  description: string;

  discountType: DiscountType;

  discountAmount: number;

  validUntil?: Maybe<DateTime>;
}
export interface CreateQuestionnaireMutationArgs {
  questions?: Maybe<(Maybe<QuestionInput>)[]>;

  opsPreTeamEvent?: Maybe<GenericReferenceInput>;

  teamEventReview?: Maybe<GenericReferenceInput>;

  boxRecipeReview?: Maybe<GenericReferenceInput>;

  mysteryReview?: Maybe<GenericReferenceInput>;

  virtualEventReview?: Maybe<GenericReferenceInput>;

  watercoolerReview?: Maybe<GenericReferenceInput>;

  defaultExperiencePreEventQuestionnaire?: Maybe<GenericReferenceInput>;

  defaultFor?: Maybe<DefaultQuestionnaireFor>;
}
export interface UpdateQuestionnaireMutationArgs {
  id: string;

  questions?: Maybe<(Maybe<QuestionInput>)[]>;

  opsPreTeamEvent?: Maybe<GenericReferenceInput>;

  teamEventReview?: Maybe<GenericReferenceInput>;

  virtualEventReview?: Maybe<GenericReferenceInput>;

  boxRecipeReview?: Maybe<GenericReferenceInput>;

  defaultExperiencePreEventQuestionnaire?: Maybe<GenericReferenceInput>;
}
export interface UpdateDefaultQuestionnaireMutationArgs {
  id: string;

  questions?: Maybe<(Maybe<QuestionInput>)[]>;
}
export interface AnswerQuestionMutationArgs {
  answer?: Maybe<AnswerInput>;
}
export interface CompleteQuestionnaireMutationArgs {
  answers?: Maybe<(Maybe<AnswerInput>)[]>;
}
export interface CompleteQuestionnaireMetricsMutationArgs {
  answers?: Maybe<(Maybe<AnswerInput>)[]>;
}
export interface ReviewQuestionnaireUsersMutationArgs {
  id: string;

  users: (Maybe<string>)[];
}
export interface UpdateRatingMutationArgs {
  eventId: string;

  instructorRating: number;

  eventRating: number;
}
export interface UpdateUsersOrganizationRolesMutationArgs {
  userRoleInputs: UserOrganizationRole[];
}
export interface CreateScheduledAdventureMutationArgs {
  pickupLocation: LocationInput;

  requestedFor: DateTime;

  budget: BudgetInput;

  creator: ReferenceUserInput;

  guests: (Maybe<ScheduledAdventureGuestInput>)[];

  note?: Maybe<string>;

  promo?: Maybe<GenericReferenceInput>;

  canShareNote?: Maybe<boolean>;
}
export interface UpdateScheduledAdventureMutationArgs {
  id: string;

  pickupLocation?: Maybe<LocationInput>;

  requestedFor?: Maybe<DateTime>;

  budget?: Maybe<BudgetInput>;

  promo?: Maybe<GenericReferenceInput>;

  guests?: Maybe<(Maybe<ScheduledAdventureGuestInput>)[]>;

  note?: Maybe<string>;

  canShareNote?: Maybe<boolean>;
}
export interface CancelScheduledAdventureMutationArgs {
  id: string;
}
export interface SearchMutationArgs {
  params: SearchInput;
}
export interface CreateShortLinkMutationArgs {
  url: string;

  customSuffix?: Maybe<string>;
}
export interface CreateMysteryUnlimitedSubscriptionMutationArgs {
  userId?: Maybe<string>;

  postFeedbackShortId?: Maybe<string>;
}
export interface CancelMysteryUnlimitedSubscriptionMutationArgs {
  userId?: Maybe<string>;
}
export interface CreateTagMutationArgs {
  name: string;
}
export interface UpdateTagMutationArgs {
  id: string;

  name?: Maybe<string>;
}
export interface MergeTagMutationArgs {
  id: string;

  into: string;
}
export interface DeleteTagMutationArgs {
  id: string;
}
export interface SearchTagsMutationArgs {
  query?: Maybe<string>;
}
export interface CreateTaskFlowMutationArgs {
  flowInput?: Maybe<TaskFlowInput>;
}
export interface UpdateTaskFlowMutationArgs {
  id: string;

  updates?: Maybe<TaskFlowUpdate>;
}
export interface AdvanceTaskFlowMutationArgs {
  id: string;

  assignee?: Maybe<TaskFlowAssigneeInput>;
}
export interface RewindTaskFlowMutationArgs {
  id: string;

  assignee?: Maybe<TaskFlowAssigneeInput>;
}
export interface GenerateOnboardingTasksMutationArgs {
  userId: string;
}
export interface EditTeamNameMutationArgs {
  id: string;

  name: string;
}
export interface ChangeManagerMutationArgs {
  teamId: string;

  managerId: string;
}
export interface ChangeManagersMutationArgs {
  inputs: ChangeManagerInput[];
}
export interface CreateTeamMutationArgs {
  managerId: string;

  orgId: string;

  teamName?: Maybe<string>;
}
export interface ToggleUserDidNotAttendMutationArgs {
  teamEventId: string;
}
export interface CancelTeamEventMutationArgs {
  id: string;
}
export interface CreateTeamEventMutationArgs {
  shortId?: Maybe<string>;

  requestedFor: DateTime;

  availableBoxTypes?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  includeDelivery: boolean;

  includeVirtualEvent: boolean;

  virtualEventType?: Maybe<VirtualEventType>;

  requireInstructor?: Maybe<boolean>;

  creator?: Maybe<GenericReferenceInput>;

  organization?: Maybe<GenericReferenceInput>;

  testEventType?: Maybe<TestEventType>;

  contract?: Maybe<GenericReferenceInput>;
}
export interface UserCreateTeamEventMutationArgs {
  shortId?: Maybe<string>;

  requestedFor: DateTime;

  expectedHeadCount?: Maybe<number>;

  experience?: Maybe<GenericReferenceInput>;

  title?: Maybe<string>;

  cost?: Maybe<number>;

  surpriseToAttendees?: Maybe<boolean>;

  surpriseToAll?: Maybe<boolean>;

  isRequestToBook?: Maybe<boolean>;

  participantFamiliarity?: Maybe<number>;

  participationModel?: Maybe<ParticipationModel>;

  purpose?: Maybe<string>;

  goals?: Maybe<GenericReferenceInput[]>;

  testEventType?: Maybe<TestEventType>;

  contract?: Maybe<GenericReferenceInput>;
}
export interface UpdateTeamEventMutationArgs {
  id: string;

  shortId?: Maybe<string>;

  title?: Maybe<string>;

  description?: Maybe<string>;

  organization?: Maybe<GenericReferenceInput>;

  expectedHeadCount?: Maybe<number>;

  finalHeadCount?: Maybe<number>;

  resendConfirmationEmail?: Maybe<boolean>;

  requestedFor?: Maybe<DateTime>;

  requestedBy?: Maybe<GenericReferenceInput>;

  boxRecipes?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  availableBoxTypes?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  boxTypeCapacityLimits?: Maybe<(Maybe<BoxTypeCapacityInput>)[]>;

  signUpExpirationDate?: Maybe<DateTime>;

  experience?: Maybe<GenericReferenceInput>;

  surpriseToAttendees?: Maybe<boolean>;

  surpriseToAll?: Maybe<boolean>;

  isRequestToBook?: Maybe<boolean>;

  freeEvent?: Maybe<boolean>;

  manualBilling?: Maybe<boolean>;

  purpose?: Maybe<string>;

  participantFamiliarity?: Maybe<number>;

  participationModel?: Maybe<ParticipationModel>;

  goals?: Maybe<GenericReferenceInput[]>;

  testEventType?: Maybe<TestEventType>;

  instructorChanged?: Maybe<boolean>;

  contract?: Maybe<GenericReferenceInput>;
}
export interface AddUserToTeamEventMutationArgs {
  id: string;

  userId?: Maybe<string>;

  skipRegistrationConfirmationEmail?: Maybe<boolean>;
}
export interface AddUserToTeamEventGuestListMutationArgs {
  id: string;

  email?: Maybe<string>;
}
export interface BulkCreateUserAndAddToGuestListMutationArgs {
  id: string;

  invitees?: Maybe<(Maybe<InviteeInput>)[]>;
}
export interface BulkAddUsersToTeamEventGuestListMutationArgs {
  id: string;

  emails: (Maybe<string>)[];
}
export interface RemoveUserFromTeamEventMutationArgs {
  id: string;

  userId: string;
}
export interface RemoveUserFromTeamEventGuestListMutationArgs {
  id: string;

  email: string;
}
export interface CreateTeamEventDeliveryOrderMutationArgs {
  id: string;

  userId: string;

  location: LocationInput;

  extras?: Maybe<(Maybe<ExtraInfoInput>)[]>;

  selectedBoxType?: Maybe<GenericReferenceInput>;

  foodRestrictions?: Maybe<string>;
}
export interface UpdateTeamEventOwnerMutationArgs {
  id: string;

  owner: NullableReferenceInput;
}
export interface UserGetExistingEventsForTemplateMutationArgs {
  templateID?: Maybe<string>;

  windows?: Maybe<(Maybe<Window>)[]>;
}
export interface CreateTeamEventIssueMutationArgs {
  categories: TeamEventIssueCategory[];

  note?: Maybe<string>;

  teamEventId: string;
}
export interface UpdateTeamEventIssueMutationArgs {
  categories: TeamEventIssueCategory[];

  note?: Maybe<string>;

  id: string;
}
export interface CreateUserMutationArgs {
  email?: Maybe<string>;

  firstName?: Maybe<string>;

  lastName?: Maybe<string>;

  phone?: Maybe<string>;

  role?: Maybe<Role>;

  isServiceAccount?: Maybe<boolean>;

  dataIntegrationAdmin?: Maybe<boolean>;

  orgRole?: Maybe<OrganizationRoleName>;
}
export interface UpdateUserMutationArgs {
  id: string;

  zipCode?: Maybe<string>;

  email?: Maybe<string>;

  name?: Maybe<string>;

  firstName?: Maybe<string>;

  lastName?: Maybe<string>;

  marketingConsent?: Maybe<boolean>;

  phone?: Maybe<string>;

  dob?: Maybe<Date>;

  role?: Maybe<Role>;

  preferenceAnswers?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  dietaryRestrictions?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  accessibilityRestrictions?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  foodPreferences?: Maybe<(Maybe<FoodPreferenceInput>)[]>;

  dietaryRestrictionsLastAsked?: Maybe<Date>;

  restrictions?: Maybe<(Maybe<RestrictionInput>)[]>;

  internalSlackId?: Maybe<string>;

  companyRole?: Maybe<string>;

  companyNameForSales?: Maybe<string>;

  orgs?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  organizationRoleId?: Maybe<string>;

  wasPromptedForMarketingConsent?: Maybe<boolean>;

  isServiceAccount?: Maybe<boolean>;

  team?: Maybe<string>;

  startDate?: Maybe<DateTime>;

  optOutOfWatercooler?: Maybe<boolean>;

  optOutOfWatercoolerAt?: Maybe<DateTime>;

  preferredWatercoolerDays?: Maybe<(Maybe<Weekday>)[]>;

  preferredWatercoolerTime?: Maybe<(Maybe<TimeOfDay>)[]>;

  timezone?: Maybe<string>;

  optOutOfDigestAt?: Maybe<DateTime>;
  /** New: pass an object input instead of individual params */
  input?: Maybe<UserUpdateInput>;
}
export interface UpdateViewerMutationArgs {
  input: UserUpdateInput;
}
export interface ReassociateFinchEmployeesMutationArgs {
  reassociations: (Maybe<FinchReassocationInput>)[];
}
export interface SetForceSmsMutationArgs {
  id: string;

  forceSms: boolean;
}
export interface DeleteUserMutationArgs {
  id: string;
}
export interface SearchUsersMutationArgs {
  query: string;

  role?: Maybe<Role>;
}
export interface AddOrUpdateSourceForUserMutationArgs {
  userId: string;

  source: string;
}
export interface IgnoreFinchAssociationMutationArgs {
  ids: (Maybe<string>)[];
}
export interface AnswerPreferenceQuestionMutationArgs {
  questionId: string;

  answerIds: string[];
}
export interface UpdateDietaryRestrictionsMutationArgs {
  dietaryRestrictions: (Maybe<GenericReferenceInput>)[];
}
export interface CreateUserTagsMutationArgs {
  names: (Maybe<string>)[];

  organizationId: string;
}
export interface UpdateUserTagsMutationArgs {
  userId: string;

  tagIds: (Maybe<string>)[];
}
export interface AddTagsToUsersMutationArgs {
  userIds: (Maybe<string>)[];

  tagIds: (Maybe<string>)[];
}
export interface RemoveTagsFromUsersMutationArgs {
  userIds: (Maybe<string>)[];

  tagIds: (Maybe<string>)[];
}
export interface AddRemoveTagsFromUsersMutationArgs {
  userIds: (Maybe<string>)[];

  addTagIds?: Maybe<(Maybe<string>)[]>;

  removeTagIds?: Maybe<(Maybe<string>)[]>;
}
export interface DeleteUserTagsMutationArgs {
  tagIds: (Maybe<string>)[];
}
export interface RenameUserTagMutationArgs {
  tagId: string;

  name?: Maybe<string>;
}
export interface UpdateVirtualEventMutationArgs {
  id: string;

  durationMins?: Maybe<number>;

  joinEventButtonEnabledLeadTimeMins?: Maybe<number>;

  requireInstructor?: Maybe<boolean>;

  joinBeforeHost?: Maybe<boolean>;

  instructor?: Maybe<GenericReferenceInput>;

  startTime?: Maybe<Date>;

  type?: Maybe<VirtualEventType>;
}
export interface AddUserToVirtualEventMutationArgs {
  virtualEventId: string;
}
export interface AddAttendeeToVirtualEventMutationArgs {
  virtualEventId: string;

  userId: string;
}
export interface RemoveAttendeeFromVirtualEventMutationArgs {
  virtualEventId: string;

  attendee: string;
}
export interface CreateVirtualExperienceMutationArgs {
  partner: GenericReferenceInput;

  name: string;

  description?: Maybe<string>;
}
export interface UpdateVirtualExperienceMutationArgs {
  id: string;

  name?: Maybe<string>;

  description?: Maybe<string>;

  url?: Maybe<string>;

  photoUrl?: Maybe<string>;

  phone?: Maybe<string>;

  estimatedDurationMins?: Maybe<number>;

  tags?: Maybe<(Maybe<TagInput>)[]>;

  partner?: Maybe<GenericReferenceInput>;

  cost?: Maybe<CostInput>;

  owner?: Maybe<ReferenceUserInput>;
}
export interface CreateWatercoolerMutationArgs {
  scheduledFor: DateTime;

  status: WatercoolerStatus;

  goal: WatercoolerGoal;

  sharedInterests?: Maybe<(Maybe<string>)[]>;

  title: string;

  participants: GenericReferenceInput[];

  organization: GenericReferenceInput;

  initiative: GenericReferenceInput;
}
export interface CreateWatercoolerInitiativeMutationArgs {
  organization: GenericReferenceInput;

  audience?: Maybe<(Maybe<AudienceMemberInput>)[]>;

  cadenceWeeks?: Maybe<number>;
}
export interface UpdateWatercoolerInitiativeMutationArgs {
  id: string;

  audience?: Maybe<(Maybe<AudienceMemberInput>)[]>;

  disabled?: Maybe<boolean>;

  cadenceWeeks?: Maybe<number>;

  disabledAt?: Maybe<DateTime>;
}
export interface DeleteWatercoolerInitiativeMutationArgs {
  id: string;
}
export interface CreateXperienceMutationArgs {
  partner: GenericReferenceInput;

  isVirtual?: Maybe<boolean>;

  name: string;

  description?: Maybe<string>;

  owner?: Maybe<ReferenceUserInput>;
}
export interface UpdateXperienceMutationArgs {
  id: string;

  isVirtual?: Maybe<boolean>;

  isActive?: Maybe<boolean>;

  defaultBoxType?: Maybe<GenericReferenceInput>;

  name?: Maybe<string>;

  description?: Maybe<string>;

  url?: Maybe<string>;

  photoUrl?: Maybe<string>;

  phone?: Maybe<string>;

  xperienceTypes?: Maybe<(Maybe<XperienceType>)[]>;

  estimatedDurationMins?: Maybe<number>;

  acceptsReservations?: Maybe<boolean>;

  acceptsOnlineReservations?: Maybe<boolean>;

  reservationsUrl?: Maybe<string>;

  checkInMessage?: Maybe<string>;

  checkOutMessage?: Maybe<string>;

  paymentMessage?: Maybe<string>;

  cardOnFile?: Maybe<GenericReferenceInput>;

  priceLevel?: Maybe<number>;

  experienceInstructions?: Maybe<string>;

  hybridGroupInstructions?: Maybe<Markdown>;

  dayBeforeInstructions?: Maybe<Markdown>;

  experienceStructure?: Maybe<string>;

  durationOptions?: Maybe<string>;

  leadTimeNeededDays?: Maybe<number>;

  numHosts?: Maybe<number>;

  numMinGuests?: Maybe<number>;

  numMaxGuests?: Maybe<number>;

  numIdealGuests?: Maybe<number>;

  breakoutRoomSize?: Maybe<number>;

  supplyKitInstructions?: Maybe<string>;

  postEventInformation?: Maybe<Markdown>;

  usaShipping?: Maybe<XperienceUsaShippingInput>;

  internationalShipping?: Maybe<XperienceInternationalShippingInput>;

  physicalGoodsDescription?: Maybe<Markdown>;

  shippingCustomizations?: Maybe<Markdown>;

  connectionFocusAreas?: Maybe<(Maybe<ConnectionFocusArea>)[]>;

  attireType?: Maybe<GenericReferenceInput>;

  subAttireTypes?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  friendlyTowardsDietaryRestrictions?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  notFriendlyTowardsDietaryRestrictions?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  friendlyTowardsAccessibilityTypes?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  notFriendlyTowardsAccessibilityTypes?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  categories?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  relatedInterests?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  tags?: Maybe<(Maybe<TagInput>)[]>;

  templates?: Maybe<(Maybe<GenericReferenceInput>)[]>;

  partner?: Maybe<GenericReferenceInput>;

  location?: Maybe<LocationInput>;

  cost?: Maybe<CostInput>;

  availabilityType?: Maybe<XperienceAvailabilityType>;

  schedules?: Maybe<(Maybe<ScheduleInput>)[]>;

  operatingHours?: Maybe<(Maybe<OperatingHoursInput>)[]>;

  owner?: Maybe<ReferenceUserInput>;

  ownerRoles?: Maybe<(Maybe<XperienceOwnerRole>)[]>;

  openTableMetaData?: Maybe<OpenTableMetaData>;

  reservationProviderId?: Maybe<string>;

  onlineReservationProvider?: Maybe<OnlineReservationProvider>;

  hasPhysicalGoods?: Maybe<boolean>;

  requiresUpgrade?: Maybe<boolean>;
}
export interface DeleteXperienceMutationArgs {
  id: string;
}
export interface SearchXperiencesMutationArgs {
  query: string;
}
export interface ResendZoomLoginEmailMutationArgs {
  id: string;

  type?: Maybe<ResendOption>;
}
export interface ForceCreateZoomMeetingMutationArgs {
  virtualEventId: string;

  meetingId: string;

  joinUrl: string;

  startUrl: string;
}
export interface CreateZoomMeetingMutationArgs {
  virtualEventId: string;
}
export interface WipeViewerUserDataDevMutationsArgs {
  preferencesOnly?: Maybe<boolean>;
}
export interface DeleteXperienceDevMutationsArgs {
  xperienceId: string;
}

// ====================================================
// Unions
// ====================================================

export type PayoutEntity = TeamEvent;

export type TeamEngagementScore = DirectTeamEngagementScore | TransitiveTeamEngagementScore;
