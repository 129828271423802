import { useState, useRef } from 'react';
import _ from 'lodash';
import Modal from '~/Modal';
import gql from 'graphql-tag';
import { CSVReader } from 'react-papaparse';
import { useApolloClient } from 'react-apollo';
import { colors } from '~/styles';
import Text from '~/Text';
import { Button, message, Table } from 'antd';
import { ParseResult } from 'papaparse';
import { formatPhone } from '~/lib/formatters';
import { geocodeByPlaceIdOrAddress } from '~/geo';
import * as GraphQL from '~/graphql';
import { validateEmail } from '~/validators';

interface NewGuest {
  id?: string;
  isRequester?: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  role?: GraphQL.Role;
  acceptedRsvp?: string;
}

type Props = {
  isOpen: boolean;
  close: () => void;
  teamEvent: GraphQL.TeamEventEditor.TeamEvent;
};

const bool = (csvText: string): boolean => !!csvText && csvText.toLowerCase() === 'true';
const csvIncludesRsvpStatus = (guests: NewGuest[]): boolean =>
  guests.some(g => !_.isNil(g.acceptedRsvp));

const AddGuests = ({ isOpen, close, teamEvent }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [guestsToAdd, setGuestsToAdd] = useState<NewGuest[]>([]);
  const readerRef = useRef(null);
  const client = useApolloClient();

  const clearState = () => {
    setGuestsToAdd([]);
    setLoading(false);
    setLoadingMessage('');
  };

  const rsvpStatusInCsv = csvIncludesRsvpStatus(guestsToAdd);

  const validateData = (header: Array<string>, data: NewGuest[]) => {
    if (data.length === 0) {
      message.error(`File must have at least one user`);
      return false;
    }
    const requiredColumns = ['firstName', 'lastName', 'email'];
    // make sure header of csv file contains required columns
    const missingColumns = _.difference(requiredColumns, header);
    if (missingColumns.length != 0) {
      message.error(`File is missing required columns: ${JSON.stringify(missingColumns)}`);
      return false;
    }
    // make sure there are no duplicate phone numbers or emails
    const emails = new Set<string>();
    const phones = new Set<string>();
    for (let i = 0; i < data.length; i++) {
      const guest = data[i];
      const guestEmail = guest.email.trim().toLowerCase();
      const guestName = `${guest.firstName} ${guest.lastName}`;
      const lineNum = i + 2;
      if (!validateEmail(guestEmail)) {
        message.error(
          `File contains an invalid email address: "${guestEmail}" for guest "${guestName}" on line ${lineNum}`,
        );
        return false;
      }
      if (emails.has(guestEmail)) {
        message.error(
          `File must not contain duplicate emails: "${guestEmail}" for guest "${guestName}" on line ${lineNum}`,
        );
        return false;
      }
      if (guest.phone && phones.has(guest.phone)) {
        message.error(
          `File must not contain duplicate phone numbers: "${
            guest.phone
          }" for guest "${guestName}" on line ${lineNum}`,
        );
        return false;
      }
      emails.add(guestEmail);
      if (guest.phone) {
        phones.add(guest.phone);
      }
    }

    return true;
  };

  const handleInvitedGuests = async (invitedGuests: NewGuest[]) => {
    const lowerCaseEmails = invitedGuests.map(guest => guest.email.trim().toLowerCase());
    try {
      const { data } = await client.mutate({
        mutation: AddGuests.bulkInviteGuests,
        variables: {
          id: teamEvent.id,
          emails: lowerCaseEmails,
        },
      });

      const invitedGuestEmails = new Set<string>(
        _.map(_.get(data, 'bulkAddUsersToTeamEventGuestList.invitedGuests'), 'email'),
      );

      const addedGuests = lowerCaseEmails.filter(email => invitedGuestEmails.has(email));
      const failedGuests = lowerCaseEmails.filter(email => !invitedGuestEmails.has(email));
      if (addedGuests.length > 0) {
        message.success(
          `Successfully invited these emails to this event: ${JSON.stringify(
            Array.from(addedGuests),
          )}`,
        );
      }
      if (failedGuests.length > 0) {
        message.error(`Failed to invite these users to the event: ${JSON.stringify(failedGuests)}`);
      }
    } catch (err) {
      console.log(`[Graphql error]: ${err.message || JSON.stringify(err)}`);
      message.error(
        `Unable to invite these guests to event: ${JSON.stringify(_.map(invitedGuests, 'email'))}`,
      );
    }
  };

  const handleAcceptedUsers = async (acceptedInvites: NewGuest[]) => {
    const { data } = await client.mutate({
      mutation: AddGuests.bulkCreateAndAddUsers,
      variables: {
        id: teamEvent.id,
        invitees: acceptedInvites.map(guest => {
          return {
            email: guest.email.trim().toLowerCase(),
            firstName: guest.firstName,
            isRequester: bool(guest.isRequester),
            lastName: guest.lastName,
          } as GraphQL.InviteeInput;
        }),
      },
    });
    const addedUsers = new Set<string>(
      data.bulkCreateUserAndAddToGuestList.map(invitee => invitee.email),
    );

    const bulkCreatedByEmail = data.bulkCreateUserAndAddToGuestList.reduce(
      (acc: Record<string, NewGuest>, invitee: NewGuest) => {
        if (invitee.email) {
          acc[invitee.email.toLowerCase()] = invitee;
        }
        return acc;
      },
      {},
    ) as Record<string, NewGuest>;
    const createdUsersWithIds = acceptedInvites.map(guest => {
      if (guest.email) {
        Object.assign(guest, bulkCreatedByEmail[guest.email.toLowerCase()]);
      }
      return guest;
    });

    if (addedUsers.size > 0) {
      message.success(
        `Successfully added these user(s) to this event: ${JSON.stringify(Array.from(addedUsers))}`,
      );
    }
    const failedUsers = new Set<string>(
      acceptedInvites
        .filter(guest => !addedUsers.has(guest.email))
        .map(failedUser => failedUser.email),
    );
    if (failedUsers.size > 0) {
      message.error(
        `Failed to add these user(s) to this event: ${JSON.stringify(Array.from(failedUsers))}`,
      );
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    setLoadingMessage(`Adding ${guestsToAdd.length} users to event...`);

    const acceptedRsvps: NewGuest[] = [];
    const invitedGuests: NewGuest[] = [];
    if (rsvpStatusInCsv) {
      for (const guestToAdd of guestsToAdd)
        if (bool(guestToAdd.acceptedRsvp)) {
          acceptedRsvps.push(guestToAdd);
        } else {
          invitedGuests.push(guestToAdd);
        }
    } else {
      acceptedRsvps.push(...guestsToAdd);
    }

    try {
      // Dear reader, resist the urge to put these calls in a Promise.all. A unique check on the
      // API side means these depend on one finishing before the other starting.
      invitedGuests.length && (await handleInvitedGuests(invitedGuests));
      acceptedRsvps.length && (await handleAcceptedUsers(acceptedRsvps));
    } finally {
      close();
      clearState();
    }
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={() => {
        if (loading) {
          message.warn(`Still loading`);
        } else {
          close();
          clearState();
        }
      }}
      width='70%'
      title={`${guestsToAdd.length === 0 ? 'Add' : 'Confirm'} New Guests`}
      footer={
        guestsToAdd.length === 0
          ? []
          : [
              <Button key='back' disabled={loading} onClick={() => clearState()}>
                Back
              </Button>,
              <Button
                key='confirm'
                type='primary'
                loading={loading}
                onClick={() => {
                  handleConfirm();
                }}
              >
                Confirm
              </Button>,
            ]
      }
    >
      <div css={{ overflowX: 'auto' }}>
        {guestsToAdd.length === 0 ? (
          <CSVReader
            ref={readerRef}
            config={{
              skipEmptyLines: 'greedy',
              header: true,
            }}
            onDrop={async rawData => {
              if (rawData.length === 0) {
                return;
              }
              const data = _.map<ParseResult<any>[], NewGuest>(rawData, _.iteratee('data'));
              const header = _.first(rawData).meta.fields;
              if (validateData(header, data)) {
                setGuestsToAdd(data);
              } else {
                readerRef.current.removeFile();
                clearState();
              }
            }}
            onError={e => {
              message.error(e.error);
              close();
              clearState();
            }}
            style={{
              dropArea: {
                borderColor: colors.AntdBlue,
              },
            }}
          >
            <span>Drop CSV file here or click to upload.</span>
          </CSVReader>
        ) : loadingMessage !== '' ? (
          <Text size='Large'>{loadingMessage}</Text>
        ) : (
          <Table
            bordered
            size='small'
            pagination={false}
            dataSource={guestsToAdd}
            rowKey={guest => guest.email}
            columns={_.compact([
              {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
              },
              {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
              },
              {
                title: 'Phone Number',
                dataIndex: 'phone',
                key: 'phone',
                render: (text: string) => formatPhone(text),
              },
              {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
              },
              rsvpStatusInCsv
                ? {
                    title: 'RSVP Status',
                    dataIndex: 'acceptedRsvp',
                    key: 'acceptedRsvp',
                    render: (_, rowData) => (bool(rowData.acceptedRsvp) ? 'Accepted' : 'Invited'),
                  }
                : null,
            ])}
          />
        )}
      </div>
    </Modal>
  );
};

AddGuests.fragment = gql`
  fragment AddGuests on User {
    id
    email
    firstName
    lastName
    phone
    dob
    zipCode
  }
`;

AddGuests.updateRequester = gql`
  mutation AddGuestsUpdateRequester($id: ID!, $requestedBy: GenericReferenceInput) {
    updateTeamEvent(id: $id, requestedBy: $requestedBy) {
      __typename
    }
  }
`;

AddGuests.addUserToTeamEvent = gql`
  mutation AddGuestsToTeamEvent($id: ID!, $userId: ID!) {
    addUserToTeamEvent(id: $id, userId: $userId) {
      party {
        id
        email
      }
    }
  }
`;

// This sets rsvpStatus = Accepted sends RSVP confirmation emails
AddGuests.bulkCreateAndAddUsers = gql`
  mutation BulkCreateAndAddUsers($id: ID!, $invitees: [InviteeInput]) {
    bulkCreateUserAndAddToGuestList(id: $id, invitees: $invitees) {
      id
      email
    }
  }
`;

// This sets rsvpStatus = Invited sends invite emails
AddGuests.bulkInviteGuests = gql`
  mutation BulkInviteGuests($id: ID!, $emails: [String]!) {
    bulkAddUsersToTeamEventGuestList(id: $id, emails: $emails) {
      id
      invitedGuests {
        id
        email
      }
    }
  }
`;

AddGuests.searchUsers = gql`
  mutation AddGuestsSearchUsers($query: String!) {
    searchUsers(query: $query) {
      ...AddGuests
    }
  }
  ${AddGuests.fragment}
`;

AddGuests.createUser = gql`
  mutation AddGuestsCreateUser(
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $role: Role
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      role: $role
    ) {
      ...AddGuests
    }
  }
  ${AddGuests.fragment}
`;

export default AddGuests;
