import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router';
import { Button, Modal, Input as AntdInput, message, Select, InputNumber, Radio } from 'antd';
import _ from 'lodash';

import ImportPlaceData from '~/Experiences/Editor/ImportPlaceData';
import Input from '~/Input';
import LabeledField from '~/Labeled/Field';
import Link from '~/Link';
import * as GraphQL from '~/graphql';
import Phone from '~/Input/Phone';
import AddressInput from '~/Input/Address';

import Heading from '../Heading';
import Row from '~/Row';

const strings = {
  messages: {
    success: ({ name }) => `Successfully added partner '${name}'`,
  },
  addPartner: 'Add Partner',
  modalTitle: 'Add a partner',
  orEnterManually: 'or enter information manually',
  invalidAddress: 'Invalid address',
  form: {
    name: 'Name',
    email: 'Email',
    quality: 'Quality',
    status: 'Status',
    numHosts: 'Number of hosts',
    permissionToRecord: 'Permission to record',
    supportedVideoPlatforms: 'Supported video platforms',
    location: 'Address',
    phone: 'Phone',
    url: 'URL',
    notes: 'Notes',
    placeholders: {
      name: 'Example: Comedy Central Online',
      email: 'laugh@comedycentral.com',
      location: '123 Main Street, Seattle, WA, 98101, USA',
      phone: '(555) 123-4567',
      url: 'comedycentral.com',
      notes: 'Add notes here',
    },
  },
};

const OPTIONAL_FIELDS = ['description', 'email'];

export type Values = GraphQL.AddPartner.Variables;

type Pages = 'auto' | 'manual';

const defaults: Partial<GraphQL.AddPartner.Variables> = {
  quality: GraphQL.PartnerQuality.Unknown,
  status: GraphQL.PartnerStatus.Vetting,
};

const AddPartnerButton = () => {
  const history = useHistory();

  const [currentPage, setPage] = useState<Pages>('auto');
  const [partnerData, setPartnerData] = useState(defaults);

  const [isValidLocation, setIsValidLocation] = useState(true);
  const isValid = isValidLocation && !!partnerData.name && !!partnerData.email;

  const [createPartnerMutationInProgress, setCreatePartnerMutationInProgress] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => {
    setCreatePartnerMutationInProgress(false);
    setIsDialogOpen(false);
    setPage('auto');
    setPartnerData(defaults);
  };

  const [createPartner] = useMutation<GraphQL.AddPartner.Mutation, GraphQL.AddPartner.Variables>(
    AddPartnerButton.mutation,
  );

  const renderPage = () => {
    switch (currentPage) {
      case 'auto':
        return (
          <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ImportPlaceData
              onSelectPlace={({ name, url, phone, location }) => {
                setPartnerData({
                  name,
                  url,
                  phone,
                  location,
                  description: undefined,
                  email: undefined,
                });
                setPage('manual');
              }}
            />
            <Link onClick={() => setPage('manual')}>{strings.orEnterManually}</Link>
          </div>
        );
      case 'manual':
        return (
          <div>
            <Heading>Required</Heading>
            <LabeledField label={strings.form.name}>
              <Input
                data-testid='partner-name-input'
                placeholder={strings.form.placeholders.name}
                value={partnerData.name}
                onChange={event => setPartnerData({ ...partnerData, name: event.target.value })}
              />
            </LabeledField>
            <LabeledField label={strings.form.email}>
              <Input
                data-testid='partner-email-input'
                placeholder={strings.form.placeholders.email}
                value={partnerData.email}
                onChange={event => setPartnerData({ ...partnerData, email: event.target.value })}
              />
            </LabeledField>
            <LabeledField label={strings.form.quality}>
              <Select
                size='large'
                onChange={quality => setPartnerData({ ...partnerData, quality })}
                value={partnerData.quality}
              >
                {_.map(GraphQL.PartnerQuality, quality => (
                  <Select.Option key={quality} value={quality} title={quality}>
                    {quality}
                  </Select.Option>
                ))}
              </Select>
            </LabeledField>
            <LabeledField label={strings.form.status}>
              <Select
                size='large'
                onChange={status => setPartnerData({ ...partnerData, status })}
                value={partnerData.status}
              >
                {_.map(GraphQL.PartnerStatus, status => (
                  <Select.Option key={status} value={status} title={status}>
                    {status}
                  </Select.Option>
                ))}
              </Select>
            </LabeledField>
            <Heading>Optional</Heading>
            <Row>
              <LabeledField label={strings.form.numHosts}>
                <InputNumber
                  css={{ width: '100%' }}
                  value={partnerData.numHosts}
                  onChange={numHosts => setPartnerData({ ...partnerData, numHosts })}
                />
              </LabeledField>
              <LabeledField label={strings.form.permissionToRecord}>
                <Radio.Group
                  value={String(partnerData.permissionToRecord)}
                  onChange={event =>
                    setPartnerData({
                      ...partnerData,
                      permissionToRecord: event.target.value === 'true',
                    })
                  }
                >
                  <Radio value='true'>Yes</Radio>
                  <Radio value='false'>No</Radio>
                </Radio.Group>
              </LabeledField>
            </Row>
            <LabeledField label='Supported Video Platforms'>
              <Select
                mode='multiple'
                value={partnerData.supportedVideoPlatforms}
                onChange={supportedVideoPlatforms =>
                  setPartnerData({ ...partnerData, supportedVideoPlatforms })
                }
              >
                {Object.values(GraphQL.VirtualEventType).map(eventType => (
                  <Select.Option key={eventType} value={eventType}>
                    {eventType}
                  </Select.Option>
                ))}
              </Select>
            </LabeledField>
            <LabeledField label={strings.form.location}>
              <AddressInput
                data-testid='partner-location-input'
                placeholder={strings.form.placeholders.location}
                address={partnerData.location ? partnerData.location.address.full : ''}
                onChange={({ location }) => setPartnerData({ ...partnerData, location })}
                setLocationValidity={setIsValidLocation}
              />
            </LabeledField>
            <LabeledField label={strings.form.phone}>
              <Phone
                data-testid='partner-phone-input'
                placeholder={strings.form.placeholders.phone}
                value={partnerData.phone}
                onChange={event => {
                  const { value } = event.target;
                  setPartnerData({ ...partnerData, phone: value });
                }}
              />
            </LabeledField>
            <LabeledField label={strings.form.url}>
              <Input
                data-testid='partner-url-input'
                placeholder={strings.form.placeholders.url}
                value={partnerData.url}
                onChange={event => setPartnerData({ ...partnerData, url: event.target.value })}
              />
            </LabeledField>
            <LabeledField label={strings.form.notes}>
              <AntdInput.TextArea
                data-testid='partner-notes-input'
                css={{ fontSize: '16px' }}
                placeholder={strings.form.placeholders.notes}
                value={partnerData.notes}
                rows={6}
                onChange={event => setPartnerData({ ...partnerData, notes: event.target.value })}
              />
            </LabeledField>
          </div>
        );
      default:
        throw new Error(`Unknown page: "${currentPage}"`);
    }
  };

  return (
    <>
      <Button type='primary' onClick={() => setIsDialogOpen(true)}>
        {strings.addPartner}
      </Button>
      <Modal
        visible={isDialogOpen}
        maskClosable={false}
        title={strings.modalTitle}
        footer={{ auto: null, manual: undefined }[currentPage]}
        okButtonProps={{
          disabled: !isValid,
          loading: createPartnerMutationInProgress,
        }}
        bodyStyle={{ overflow: 'auto', maxHeight: '65vh' }}
        onOk={async () => {
          try {
            setCreatePartnerMutationInProgress(true);
            const response = await createPartner({ variables: partnerData as Values });
            if (response.errors) {
              message.error('Oh no, an error occurred');
              console.error(JSON.stringify(response.errors));
            } else {
              const partner = response.data.createPartner;
              message.success(strings.messages.success(partner));
              history.push(`/supply/partner/${partner.id}`);
            }
            setCreatePartnerMutationInProgress(false);
          } catch (error) {
            console.error(JSON.stringify(error));
            setCreatePartnerMutationInProgress(false);
          }
        }}
        onCancel={closeDialog}
      >
        {renderPage()}
      </Modal>
    </>
  );
};

export default AddPartnerButton;

AddPartnerButton.mutation = gql`
  mutation AddPartner(
    $name: String!
    $email: String!
    $quality: PartnerQuality
    $status: PartnerStatus
    $numHosts: Int
    $permissionToRecord: Boolean
    $supportedVideoPlatforms: [PartnerVideoPlatformInput]
    $location: LocationInput
    $phone: String
    $url: String
    $notes: Markdown
  ) {
    createPartner(
      name: $name
      email: $email
      quality: $quality
      status: $status
      numHosts: $numHosts
      permissionToRecord: $permissionToRecord
      supportedVideoPlatforms: $supportedVideoPlatforms
      location: $location
      phone: $phone
      url: $url
      notes: $notes
    ) {
      id
      name
    }
  }
`;
