import { Switch } from 'antd';
import { colors, sizes } from '~/styles';

interface Props {
  checked?: boolean;
  onChange?: (checked: boolean) => unknown;
  checkedLabel: React.ReactNode;
  uncheckedLabel: React.ReactNode;
}

const LabeledSwitch = ({ checked, onChange, checkedLabel, uncheckedLabel }: Props) => {
  return (
    <label
      css={{
        display: 'flex',
        alignItems: 'center',
        color: checked ? colors.AntdBlue : colors.Silver,
        '& > *': { marginRight: sizes.Spacing.XXSmall },
      }}
    >
      <Switch checked={checked} onChange={onChange} />
      <span>{checked ? checkedLabel : uncheckedLabel}</span>
    </label>
  );
};

export default LabeledSwitch;
