import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Statsig } from 'statsig-react';
import useRouter from 'use-react-router';

import { navPaths } from '~/App/Routes';
import useMobile from '~/hooks/useMobile';
import useMobileStyle from '~/hooks/useMobileStyle';
import Monogram from '~/Logo/Monogram';
import { memo } from '~/react';
import { colors, shadow, sizes } from '~/styles';
import NavigationIcon from './Icon';
import SheetNav from './Sheet';

interface Props {
  routes?: any;
}

class Linker {
  constructor(private readonly router: ReturnType<typeof useRouter>) {}

  public linkTo = (path: string) => () => this.router.history.push(path);
}

// A showcase of Statsig gates and experiments.
function useBodyFilters() {
  const filters = [];
  if (Statsig.checkGate('dork_mode')) {
    filters.push('invert(1)');
  }

  const experiment = Statsig.getExperiment('console_colors');
  const rotation = experiment.get('hue_rotation', 0);
  if (rotation !== 0) {
    filters.push(`hue-rotate(${rotation}deg)`);
  }

  return filters.length ? filters.join(' ') : undefined;
}

const AppNavigation = ({ routes, ...otherProps }: Props) => {
  const router = useRouter();
  const useHorizontalNav = useMobile();
  const filter = useBodyFilters();

  const activePath = _.get(router, 'location.pathname');
  const linker = new Linker(router);

  useEffect(() => {
    const { path, params } = extractSymbolicPath(activePath);
    Statsig.logEvent('ops-web:navigate', path, params);
  }, [activePath]);

  const VerticalNavigation = () => (
    <div css={{ width: '100vw' }}>
      <div
        css={{
          top: 0,
          left: 0,
          width: 64,
          bottom: 0,
          minHeight: 600,
          position: 'fixed',
          backgroundColor: '#16181B',
          boxShadow: shadow.three,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Monogram
          css={{
            marginBottom: sizes.Spacing.XLarge,
            marginTop: sizes.Spacing.Medium,
            color: colors.ExtraDarkSnow,
            fontSize: 34,
          }}
        />
        {_.map(navPaths, ({ iconComponent, path, label }, i) => (
          <NavigationIcon
            key={i}
            component={iconComponent}
            onClick={linker.linkTo(path)}
            active={path === '/' ? activePath === '/' : activePath.startsWith(path)}
            label={label}
          />
        ))}
      </div>
      <div
        css={{
          maxWidth: 'calc(100vw - 64px)',
          overflowX: 'hidden',
          backgroundColor: colors.White,
          filter,
        }}
      >
        {routes}
      </div>
    </div>
  );

  const HorizontalNavigation = () => {
    const [visible, setVisible] = useState(false);

    return (
      <div>
        <div
          css={{
            top: 0,
            left: 0,
            right: 0,
            // This is so that the SideSheet is **beneath** our navbar when
            // being used for application views and **above** our navbar when
            // rendering the navigation sheet.
            zIndex: visible ? 10 : 1000,
            height: AppNavigation.HEADER_HEIGHT,
            position: 'fixed',
            backgroundColor: '#16181B',
            boxShadow: shadow.three,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: sizes.Spacing.Small,
            paddingRight: sizes.Spacing.Small,
            transform: 'translate3d(0,0,0)',
          }}
        >
          <Monogram
            css={{
              color: colors.ExtraDarkSnow,
              fontSize: 32,
            }}
          />
          <SheetNav
            activePath={activePath}
            linker={linker}
            visible={visible}
            setVisible={setVisible}
          />
        </div>
        <div
          css={{
            maxHeight: `calc(100vh - ${AppNavigation.HEADER_HEIGHT}px)`,
            overflowY: 'auto',
            backgroundColor: colors.White,
            filter,
          }}
        >
          {routes}
        </div>
      </div>
    );
  };

  const Component = useHorizontalNav ? HorizontalNavigation : VerticalNavigation;

  return (
    <div
      css={{
        width: '100vw',
        position: 'relative',
        ...useMobileStyle(
          { paddingTop: AppNavigation.HEADER_HEIGHT },
          { minHeight: '100vh', paddingLeft: 64 },
        ),
      }}
    >
      <Component />
    </div>
  );
};

AppNavigation.HEADER_HEIGHT = sizes.GRID_UNIT * 12;

const IS_UUID = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

// Annoyingly, react-router won't give us the matched path unless we are
// within the context of the <Route> that defines it.
//
// So we have to parse out IDs and params heuristically…
function extractSymbolicPath(pathname: string) {
  const parts = pathname.split('/');

  let unnamedParamCount = 0;
  const params = {};
  const path = parts
    .map((part, index) => {
      if (!IS_UUID.test(part)) return part;

      const parentPart = parts[index - 1];
      const paramName =
        parentPart && !IS_UUID.test(parentPart)
          ? // If we have something like /foo/<UUID>, we treat it as /foo/:fooId
            `${parentPart}Id`
          : // Or, if there is no (non-UUID) parent, we treat it as /:id#.
            `id${++unnamedParamCount}`;

      params[paramName] = part;
      return `:${paramName}`;
    })
    .join('/');

  return { path, params };
}

export default memo(AppNavigation);
