import React from 'react';

import { colors, scrollbar, sizes } from '~/styles';

interface Props {
  visible: boolean;
  children: React.ReactNode;
}

const SearchResultsPanel = ({ children, visible }: Props) => (
  <div css={{ backgroundColor: colors.White, position: 'relative' }}>
    <div
      css={{
        opacity: 1,
        height: 'auto',
        maxHeight: sizes.GRID_UNIT * 60,
        overflowY: 'auto',
        borderRadius: 6,
        position: 'absolute',
        zIndex: 1,
        width: 'fit-content',
        backgroundColor: colors.White,
        transform: 'translateY(1.5%)',
        minWidth: sizes.GRID_UNIT * 40,
        minHeight: sizes.GRID_UNIT * 4,
        display: visible ? undefined : 'none',
        boxShadow: `0 0 0 1px rgba(6,44,82,.1), 0 2px 16px rgba(33,43,54,.08)`,
        ...scrollbar.invisible,
      }}
    >
      <div
        css={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'flex-start',
          backgroundColor: colors.White,
        }}
      >
        {children}
      </div>
    </div>
  </div>
);

export default SearchResultsPanel;
