import { Button, Input, InputNumber } from 'antd';
import { useState } from 'react';
import gql from 'graphql-tag';

import * as GraphQL from '~/graphql';
import { Edits } from '~/hooks/useEditableEntity';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import Row from '~/Row';
import { colors } from '~/styles';

import Heading from '../../Heading';
import ExperienceAvailabilityView from '../Availability/View';
import AvailabilityEditor from '../Availability/Editor';
import InputNumberWithUnit from '~/Input/NumberWithUnit';

export interface Props {
  experience: GraphQL.ExperienceProfileAvailabilityDetails.Fragment;
  onChange: (edits: Edits<GraphQL.ExperienceProfileAvailabilityDetails.Fragment>) => void;
}

const ExperienceProfileAvailabilityDetails = ({ experience, onChange }: Props) => {
  const [editingAvailability, setEditingAvailability] = useState(false);

  return (
    <div>
      <Heading>Availability</Heading>
      <Row>
        <LabeledField label='Availability'>
          <ExperienceAvailabilityView {...experience} />
          <AvailabilityEditor
            data={experience}
            isOpen={editingAvailability}
            close={() => setEditingAvailability(false)}
          />
        </LabeledField>
        <Button onClick={() => setEditingAvailability(true)}>Manage</Button>
      </Row>
    </div>
  );
};

ExperienceProfileAvailabilityDetails.fragment = gql`
  fragment ExperienceProfileAvailabilityDetails on Xperience {
    id
    numMaxGuests
    ...ExperienceAvailabilityView
  }
  ${ExperienceAvailabilityView.fragment}
`;

export default ExperienceProfileAvailabilityDetails;
