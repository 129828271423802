import gql from 'graphql-tag';

export const tagsList = gql`
  query TagsList {
    tags {
      id
      name
      isUsedByCode
      experiences {
        id
      }
    }
  }
`;

export const deleteMutation = gql`
  mutation DeleteTag($tagId: ID!) {
    deleteTag(id: $tagId) {
      __typename
    }
  }
`;

export const mergeMutation = gql`
  mutation MergeTag($id: ID!, $into: ID!) {
    mergeTag(id: $id, into: $into) {
      __typename
    }
  }
`;
