// Defines JSX `css` prop.
import '@emotion/core';

// Import Semantic UI's styles.
import 'semantic-ui-css/semantic.min.css';

// Import Ant Design's styles.
import 'antd/dist/antd.css';

// Import react-virtualized's styles
// Ref: https://github.com/bvaughn/react-virtualized
import 'react-virtualized/styles.css';

// Imports Polaris' styles.
import '@shopify/polaris/styles.css';

// Defines `window.fetch`.
import 'isomorphic-unfetch';

// Defines `Object.fromEntries`.
import 'object.fromentries/auto';

// Blueprint styles
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';

import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';

// Monkeypatch moment to add support for formatting durations
momentDurationFormatSetup(moment);

// Set the default timezone on moment to be P(S|D)T
moment.tz.setDefault('America/Los_Angeles');
