import { colors } from '~/styles';

const CheckCircle = ({ size = 20, color = colors.White }) => {
  const viewBox = `0 0 ${size} ${size}`;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_1622_11203)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.0525 3.14921C11.5752 2.49097 9.92468 2.32789 8.34712 2.68432C6.76957 3.04075 5.3495 3.89758 4.29869 5.12702C3.24789 6.35646 2.62265 7.89264 2.51624 9.50645C2.40982 11.1203 2.82793 12.7252 3.7082 14.082C4.58847 15.4388 5.88374 16.4747 7.40083 17.0351C8.91793 17.5956 10.5756 17.6507 12.1265 17.1921C13.6774 16.7336 15.0386 15.7859 16.007 14.4906C16.9754 13.1952 17.4991 11.6215 17.5 10.0042V9.23802C17.5 8.77778 17.8731 8.40469 18.3333 8.40469C18.7936 8.40469 19.1667 8.77778 19.1667 9.23802V10.0047C19.1655 11.9814 18.5254 13.9053 17.3419 15.4885C16.1583 17.0717 14.4947 18.2299 12.5991 18.7904C10.7035 19.3509 8.67747 19.2836 6.82324 18.5985C4.96902 17.9135 3.38591 16.6474 2.31003 14.9891C1.23414 13.3309 0.723122 11.3692 0.853183 9.39679C0.983245 7.42435 1.74742 5.5468 3.03174 4.04415C4.31605 2.5415 6.0517 1.49427 7.97982 1.05863C9.90794 0.622999 11.9252 0.822308 13.7308 1.62683C14.1512 1.81415 14.3402 2.3068 14.1528 2.72719C13.9655 3.14758 13.4729 3.33653 13.0525 3.14921ZM18.9223 2.7437C19.2479 3.06898 19.2481 3.59661 18.9229 3.92221L10.5895 12.2639C10.4333 12.4203 10.2213 12.5082 10.0002 12.5083C9.7791 12.5083 9.56706 12.4205 9.41073 12.2642L6.91073 9.76417C6.58529 9.43874 6.58529 8.9111 6.91073 8.58566C7.23616 8.26023 7.7638 8.26023 8.08924 8.58566L9.99969 10.4961L17.7438 2.74429C18.069 2.41869 18.5967 2.41843 18.9223 2.7437Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_1622_11203'>
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckCircle;
