import React from 'react';
import gql from 'graphql-tag';
import _ from 'lodash';
import { useApolloClient } from '@apollo/react-hooks';

import Search from '~/Search';
import * as GraphQL from '~/graphql';

interface Props {
  onChange: any;
  value?: string;
  virtualOnly?: boolean;
}

const SearchXperiences = ({
  value,
  onChange,
  virtualOnly,
  ...otherProps
}: Props) => {
  const client = useApolloClient();

  const onSearch = async (query: string) => {
    const { data } = await client.mutate<
      GraphQL.SearchXperiences.Mutation,
      GraphQL.SearchXperiences.Variables
    >({
      mutation: SearchXperiences.mutation,
      variables: {
        query,
      },
    });

    const results = data!.searchXperiences;

    return _.map(
      _.filter(results, result => !virtualOnly || !!result.isVirtual),
      (item, key) => ({
        ...item,
        key,
        title: `${item.name}`,
        description: item.description,
      }),
    );
  };

  return (
    <Search
      autoFocus={false}
      autofocus={false}
      onSelect={onChange}
      defaultValue={value}
      onSearch={onSearch}
      {...otherProps}
    />
  );
};

SearchXperiences.fragment = gql`
  fragment SearchXperiences on Xperience {
    id
    isVirtual
    name
    description
  }
`;

SearchXperiences.mutation = gql`
  mutation SearchXperiences($query: String!) {
    searchXperiences(query: $query) {
      ...SearchXperiences
    }
  }

  ${SearchXperiences.fragment}
`;

export default SearchXperiences;
