import React from 'react';
import { Button, Checkbox, Typography } from 'antd';
import _ from 'lodash';
import { OrganizationEditor } from '~/graphql';
import ScottHr from '~/ScottHr';
import { colors, sizes } from '~/styles';
import { BulkUpload } from '../BulkUpload';
import { FontWeight } from '~/styles/font';

const MembersTab = ({
  members,
  inferMembership,
  setInferMembership,
  setShowMemberModal,
}: {
  members: OrganizationEditor.Members[];
  inferMembership: boolean;
  setInferMembership: (infer: boolean) => void;
  setShowMemberModal: (show: boolean) => void;
}) => (
  <>
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid grey',
        marginBottom: '10px',
        paddingBottom: '10px',
      }}
    >
      <Typography
        css={{ fontSize: '30px', fontWeight: 'bold' }}
        title='Users will automatically be added as members when invited to an event'
      >
        Infer Membership
      </Typography>
      <div>
        <Checkbox
          css={{ marginRight: 10 }}
          checked={inferMembership}
          onChange={() => setInferMembership(!inferMembership)}
        >
          Automatically Add Members
        </Checkbox>
      </div>
    </div>
    <div css={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Typography css={{ fontSize: '30px', fontWeight: 'bold' }}>Members</Typography>
      <div>
        <Button css={{ marginRight: 10 }} type='primary' onClick={() => setShowMemberModal(true)}>
          Add/Edit Members
        </Button>
      </div>
    </div>
    <div css={{ display: 'flex', flexDirection: 'column', marginTop: sizes.Spacing.Medium }}>
      {_.orderBy(members, ['lastName', 'firstName'], ['asc', 'asc']).map((employee, i) => (
        <div key={employee.id}>
          <div
            css={{
              display: 'flex',
              marginBottom: '20px',
              flexDirection: 'row',
            }}
          >
            <Typography css={{ fontSize: '20px', flex: 1, position: 'relative' }}>
              {employee.firstName} {employee.lastName}
              {employee.isServiceAccount && (
                <Typography
                  css={{
                    fontSize: '10px',
                    flex: 1,
                    fontWeight: FontWeight.Bold,
                    color: colors.AntdBlue,
                    position: 'absolute',
                  }}
                >
                  Service Account
                </Typography>
              )}
            </Typography>
            <Typography css={{ fontSize: '20px', flex: 1 }}>
              {employee.email || employee.phone}
            </Typography>
            <Typography css={{ fontSize: '20px', flex: 1 }}>
              {_.get(employee, 'organizationRole.name') || '-'}
            </Typography>
          </div>
          {i < members.length - 1 && (
            <ScottHr marginBottom={sizes.Spacing.Small} marginTop={sizes.Spacing.Small} />
          )}
        </div>
      ))}
    </div>
  </>
);

export default MembersTab;
