import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import OmniSearch from '~/Search/Omni';

const SupplySearch = () => {
  const [selectedItem, setSelectedItem] = useState(undefined);

  if (selectedItem)
    return (
      <Redirect
        to={(() => {
          const { entityType, id, parentId } = selectedItem;
          switch (entityType) {
            case 'Partner':
              return `/supply/partner/${id}`;
            case 'Xperience':
              return `/supply/partner/${parentId}/experience/${id}`;
            default:
              throw new Error(`unknown entity: ${entityType}`);
          }
        })()}
      />
    );

  return (
    <div css={{ margin: '-16px 0' }}>
      <OmniSearch
        entities={['Partner', 'Xperience']}
        placeholder={'Jump to Experience or Partner'}
        css={{ width: 500 }}
        onChange={setSelectedItem}
      />
    </div>
  );
};

export default SupplySearch;
