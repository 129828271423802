import React, { useRef } from 'react';
import * as GraphQL from '~/graphql';
import gql from 'graphql-tag';
import FiltersBar from '~/FiltersBar';
import FiltersBarToggle from '~/FiltersBar/Toggle';
import FilterDate from '~/Filter/Date';
import FilterOwners from '~/Filter/Owners';
import FilterRegion from '~/Filter/Region';
import FillRemaining from '~/FillRemaining';
import FilterStatus from '~/Filter/Status';
import Header from '~/Header';
import Status from './Status';
import PaginatedTable from '~/PaginatedTable';
import columns from './columns';
import { sizes } from '~/styles';
import { useQueryParams, StringParam, ArrayParam } from 'use-query-params';
import _ from 'lodash';

import LabeledField from '~/Labeled/Field';
import SearchInput from '~/Search/Input';

const getDefaultStagingGridVariables = () => ({
  first: 40,
  after: null,
  search: null,
});

const StagingGrid = () => {
  const [params] = useQueryParams({
    search: StringParam,
    status: ArrayParam,
    ownerIds: ArrayParam,
    dateRange: ArrayParam,
    region: ArrayParam,
  });

  const variables = useRef(getDefaultStagingGridVariables());

  _.assign(variables.current, {
    ...params,
    status: params.status || [
      GraphQL.AdventureStatus.InProgress,
      GraphQL.AdventureStatus.Requested,
      GraphQL.AdventureStatus.Scheduled,
    ],
    order: [
      {
        key: GraphQL.MysteriesOrderKey.StartsAt,
        direction: GraphQL.OrderDirection.Asc,
      },
    ],
  });

  return (
    <FillRemaining css={{ overflowY: 'auto' }}>
      <Header
        title={strings.stagingBoard}
        rightContent={<FiltersBarToggle filtersetKey='stagingFilterVisible' />}
      />
      <FiltersBar filtersetKey='stagingFilterVisible'>
        <FilterStatus options={GraphQL.AdventureStatus} />
        <FilterOwners parameterName='ownerIds' />
        <LabeledField label='Search'>
          <SearchInput size='default' />
        </LabeledField>
        <FilterRegion />
        <div css={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
          <LabeledField css={{ alignSelf: 'flex-end' }} label='Date Range'>
            <FilterDate range queryParamName='dateRange' />
          </LabeledField>
        </div>
      </FiltersBar>
      <Status />
      <PaginatedTable<GraphQL.StagingGridQuery.Query, GraphQL.StagingGridQuery.Variables>
        variables={variables.current}
        columns={columns}
        dataKey='mysteriesConnection'
        query={StagingGrid.query}
        rowHeight={sizes.GRID_UNIT * 8}
        headerHeight={sizes.GRID_UNIT * 8}
        listRowHeight={sizes.GRID_UNIT * 17}
        listRowRenderer={(rows, props) => <div />}
      />
    </FillRemaining>
  );
};

StagingGrid.fragment = gql`
  fragment StagingGrid on Adventure {
    id
    shortId
    hasUnreadMessages
    owner {
      id
      firstName
    }
    startsAt
    status
    budget {
      min
      max
    }
    note
    type
    template {
      id
      title
    }
    party {
      id
      firstName
      dietaryRestrictions {
        id
        name
      }
      accessibilityRestrictions {
        id
        name
      }
    }
  }
`;

StagingGrid.query = gql`
  query StagingGridQuery(
    $first: Int
    $after: String
    $order: [MysteriesOrderArgs]
    $ids: [ID]
    $ownerIds: [ID]
    $status: [AdventureStatus]
    $search: String
    $dateRange: [DateTime]
    $region: [ID]
  ) {
    mysteriesConnection(
      first: $first
      after: $after
      order: $order
      ids: $ids
      ownerIds: $ownerIds
      status: $status
      search: $search
      dateRange: $dateRange
      region: $region
    )
      @connection(
        key: "mysteriesConnection"
        filter: ["ids", "ownerIds", "status", "search", "region"]
      ) {
      edges {
        node {
          ...StagingGrid
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  ${StagingGrid.fragment}
`;

const strings = {
  stagingBoard: 'Staging Board',
};

export default StagingGrid;
