import Button from '@atlaskit/button';
import React, { useState } from 'react';

import EditUserModal from '~/Users/EditUserModal';

const AddUserButton = props => {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <>
      <Button appearance='primary' onClick={() => setModalVisible(true)} {...props}>
        + Add User
      </Button>
      <EditUserModal open={modalVisible} onClose={() => setModalVisible(false)} />
    </>
  );
};

export default AddUserButton;
