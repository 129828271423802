import React from 'react';
import { colors, sizes } from '~/styles';
import { Divider } from 'antd';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';

const StatusCell = ({
  color,
  label,
  count,
}: {
  color: string;
  label: string;
  count: number;
}) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      border: `0.5px solid ${
        label === strings.total ? colors.GreyDarkest : colors.DarkSmoke
      }`,
      borderRadius: sizes.BorderRadius.Medium,
      paddingTop: sizes.Spacing.XXSmall,
      paddingBottom: sizes.Spacing.XXXSmall,
      paddingLeft: sizes.Spacing.XXSmall,
      paddingRight: sizes.Spacing.XXSmall,
      '> *:not(:last-child)': { marginRight: sizes.Spacing.XSmall },
    }}
  >
    <div css={{ fontFamily: 'Avenir', fontSize: '25px', fontWeight: 800, color }}>
      {count}
    </div>
    <div css={{ fontSize: '12px' }}>{label}</div>
  </div>
);

const Status = () => {
  const { data, loading } = useQuery(Status.query);

  const statusKeys = [
    strings.total,
    strings.planned,
    strings.inProgress,
    strings.notPlanned,
  ];

  const statusColors = {
    [strings.inProgress]: colors.Warning,
    [strings.notPlanned]: colors.Negative,
    [strings.planned]: colors.Positive,
    [strings.total]: colors.Black,
  };

  const getStatusMetric = ((data: any) => (metric: string) => {
    if (!data) return '-';

    if (metric === strings.total)
      return _.sum(Object.keys(data).map(key => data[key].count));

    const keyLookup = {
      [strings.inProgress]: 'inProgressStatus',
      [strings.notPlanned]: 'notPlannedStatus',
      [strings.planned]: 'plannedStatus',
    };

    return data[keyLookup[metric]].count;
  })(data);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: sizes.Spacing.Medium,
        marginLeft: sizes.Spacing.Large,
        marginBottom: sizes.Spacing.Medium,
      }}
    >
      {statusKeys.map((key, i) => {
        const props = {
          color: statusColors[key],
          label: key,
          count: getStatusMetric(key),
        };

        return (
          <div
            css={{ display: 'flex', alignItems: 'center' }}
            key={`staging-status-${key}`}
          >
            <StatusCell {...props} />
            {i < 3 && <Divider style={{ height: '20px' }} type='vertical' />}
          </div>
        );
      })}
    </div>
  );
};

const strings = {
  inProgress: 'In progress',
  notPlanned: 'Not planned',
  planned: 'Planned',
  total: 'Total',
};

Status.query = gql`
  query StagingStatusSummary {
    notPlannedStatus: mysteriesConnection(status: Requested)
      @connection(key: "mysteriesConnection", filter: ["status"]) {
      count
    }
    plannedStatus: mysteriesConnection(status: Scheduled)
      @connection(key: "mysteriesConnection", filter: ["status"]) {
      count
    }
    inProgressStatus: mysteriesConnection(status: InProgress)
      @connection(key: "mysteriesConnection", filter: ["status"]) {
      count
    }
  }
`;

export default Status;
