import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useRef, useState } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import FilterDate from '~/Filter/Date';
import FilterOwners from '~/Filter/Owners';
import FilterTeamEventStatus from '~/Filter/TeamEventStatus';
import FiltersBar from '~/FiltersBar';
import FiltersBarToggle from '~/FiltersBar/Toggle';
import Header from '~/Header';
import LabeledField from '~/Labeled/Field';
import PaginatedTable from '~/PaginatedTable';
import { QuestionnaireDialog } from '~/QuestionnaireDialog';
import EditQuestionnaireButton from '~/QuestionnaireDialog/EditQuestionnaireButton';
import SearchInput from '~/Search/Input';
import { titleCaseEnum } from '~/formatters';
import * as GraphQL from '~/graphql';
import usePusher from '~/hooks/usePusher';
import { getApolloClient } from '~/services/apollo';
import { colors, sizes } from '~/styles';
import CreateTeamEventModal from './Create';
import DrawerWithTabs from './EditDrawer';
import MobileRow from './MobileRow';

const rowRenderer = (
  rows: GraphQL.TeamEventGridQuery.Node[],
  { index, isScrolling, isVisible, parent, ...otherProps }: any,
) => <MobileRow rowData={rows[index]} {...otherProps} />;

const handleZoomMeetingChanged = async (zoomMeetingId: string) => {
  const client = getApolloClient();

  await client.query({
    query: TeamEvent.zoomMeeting,
    variables: { id: zoomMeetingId },
    fetchPolicy: 'network-only',
  });
};

const TeamEvent = () => {
  const [selectedTeamEventId, setSelectedTeamEventId] = useState(undefined);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const pusherSubscriber = usePusher();

  pusherSubscriber.bind(GraphQL.EventType.ZoomMeetingChanged, handleZoomMeetingChanged);

  const { data: defaultQuestionnaireData, loading } = useQuery<
    GraphQL.DefaultQuestionnaire.Query,
    GraphQL.DefaultQuestionnaire.Variables
  >(QuestionnaireDialog.DefaultQuestionnaireQuery, {
    variables: { defaultFor: GraphQL.DefaultQuestionnaireFor.TeamEvent },
  });
  const { data: teamEventGoalData } = useQuery<GraphQL.TeamEventGoals.Query>(
    TeamEvent.teamEventGoals,
  );

  const [params] = useQueryParams({
    search: StringParam,
    type: ArrayParam,
    dateRange: ArrayParam,
    ownerIds: ArrayParam,
    teamEventStatus: ArrayParam,
    organizationId: StringParam,
  });

  const variables = useRef(getDefaultTeamEventListVariables());

  _.assign(variables.current, {
    ...params,
  });

  const [createTeamEvent] = useMutation(TeamEvent.create, {
    refetchQueries: ['TeamEventGridQuery'],
  });

  const { data } = useQuery(TeamEvent.viewer);

  return (
    <div css={{ height: '100vh', overflow: 'hidden' }}>
      <Header
        title='Team Events'
        rightContent={
          <div css={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              type='primary'
              css={{ marginRight: sizes.Spacing.Small }}
              onClick={() => setShowCreateModal(true)}
            >
              Create New Team Event
            </Button>
            {defaultQuestionnaireData && (
              <div css={{ marginRight: sizes.Spacing.Small }}>
                <EditQuestionnaireButton
                  title={`Edit Default Review Questionnaire`}
                  questionnaire={defaultQuestionnaireData.defaultQuestionnaire}
                  defaultFor={GraphQL.DefaultQuestionnaireFor.TeamEvent}
                  button
                />
              </div>
            )}
            <FiltersBarToggle filtersetKey='teamEventsFilterVisible' />
          </div>
        }
      />
      <FiltersBar filtersetKey='teamEventsFilterVisible'>
        <LabeledField label='Search'>
          <SearchInput size='default' />
        </LabeledField>
        <FilterTeamEventStatus defaultValues={[]} options={GraphQL.TeamEventStatus} />
        <LabeledField label='Date Range'>
          <FilterDate range queryParamName='dateRange' />
        </LabeledField>
        <FilterOwners parameterName='ownerIds' />
      </FiltersBar>
      <CreateTeamEventModal
        visible={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        createTeamEvent={createTeamEvent}
        setSelectedTeamEventId={setSelectedTeamEventId}
      />
      <PaginatedTable<GraphQL.TeamEventGridQuery.Query, GraphQL.TeamEventGridQuery.Variables>
        columns={[
          {
            key: 'shortId',
            width: sizes.GRID_UNIT * 5,
            cellRenderer: shortId => shortId || '-',
          },
          {
            key: 'title',
            width: sizes.GRID_UNIT * 10,
            cellRenderer: title => title || '-',
          },
          {
            key: 'experience',
            label: 'Experience',
            width: sizes.GRID_UNIT * 15,
            cellRenderer: experience => (experience ? experience.name : '-'),
          },
          {
            key: 'status',
            width: sizes.GRID_UNIT * 6,
            cellRenderer: status => titleCaseEnum(status),
          },
          {
            key: 'hostJoinedAt',
            label: 'Host Joined',
            width: sizes.GRID_UNIT * 1.5,
            cellRenderer: (_cellData, rowData) =>
              _.get(rowData, 'virtualEvent.zoomMeeting.hostJoinedAt') ? (
                <span css={{ color: colors.Brand.DarkGreen, alignSelf: 'center' }}>
                  {moment(_.get(rowData, 'virtualEvent.zoomMeeting.hostJoinedAt')).format('h:mm a')}
                </span>
              ) : (
                <FiX css={{ color: colors.Negative, alignSelf: 'center' }} />
              ),
          },
          {
            key: 'requestedBy',
            label: strings.requestedBy,
            width: sizes.GRID_UNIT * 10,
            cellRenderer: requestedBy => (requestedBy ? requestedBy.name : '-'),
          },
          {
            key: 'requestedFor',
            label: strings.requestedFor,
            width: sizes.GRID_UNIT * 6,
            cellRenderer: requestedFor => moment(requestedFor).format('M/D/YYYY h:mma'),
          },
          {
            key: 'creator',
            width: sizes.GRID_UNIT * 3,
            cellRenderer: creator => (creator ? creator.firstName : '-'),
          },
          {
            key: 'owner',
            width: sizes.GRID_UNIT * 3,
            cellRenderer: owner => (owner ? owner.firstName : '-'),
          },
          {
            key: 'guests',
            label: 'RSVPs',
            width: sizes.GRID_UNIT * 2,
            cellRenderer: (_cellData, rowData) =>
              `${rowData.invitationsAccepted || 0} / ${rowData.invitationsSent || 0}`,
          },
        ]}
        variables={variables.current}
        query={TeamEvent.query}
        listRowRenderer={(rows, props) => rowRenderer(rows, { ...props, setSelectedTeamEventId })}
        dataKey='teamEventsConnection'
        rowHeight={sizes.GRID_UNIT * 8}
        onRowClick={({ rowData }) => setSelectedTeamEventId(rowData.id)}
        headerHeight={sizes.GRID_UNIT * 8}
        listRowHeight={sizes.GRID_UNIT * 17}
      />
      <DrawerWithTabs
        onClose={() => setSelectedTeamEventId(undefined)}
        orderId={selectedTeamEventId}
        goalOptions={_.get(teamEventGoalData, 'teamEventGoals')}
        viewer={_.get(data, 'viewer')}
      />
    </div>
  );
};

export const getDefaultTeamEventListVariables = () => ({
  first: 40,
  after: null,
  search: null,
  teamEventStatus: [
    GraphQL.TeamEventStatus.Requested,
    GraphQL.TeamEventStatus.Scheduled,
    GraphQL.TeamEventStatus.InProgress,
  ],
  dateRange: [],
  order: [
    {
      key: GraphQL.TeamEventOrderKey.RequestedFor,
      direction: GraphQL.OrderDirection.Asc,
    },
  ],
  organizationId: null,
});

TeamEvent.fragment = gql`
  fragment TeamEventGrid on TeamEvent {
    id
    experience {
      id
      name
    }
    status
    requestedFor
    title
    shortId
    creator {
      id
      firstName
      lastName
    }
    owner {
      id
      firstName
      lastName
    }

    invitationsAccepted
    invitationsSent
    requestedBy {
      id
      name
      firstName
      lastName
    }
    organization {
      id
      name
      internalName
    }
    virtualEvent {
      id
      type
      zoomMeeting {
        id
        hostJoinedAt
      }
    }
  }
`;

TeamEvent.viewer = gql`
  query TeamEventViewer {
    viewer {
      id
      email
    }
  }
`;

TeamEvent.query = gql`
  query TeamEventGridQuery(
    $first: Int
    $after: String
    $order: [TeamEventOrderArgs]
    $ids: [ID]
    $ownerIds: [ID]
    $search: String
    $dateRange: [DateTime]
    $teamEventStatus: [TeamEventStatus]
    $organizationId: ID
  ) {
    teamEventsConnection(
      first: $first
      after: $after
      order: $order
      ids: $ids
      ownerIds: $ownerIds
      search: $search
      dateRange: $dateRange
      teamEventStatus: $teamEventStatus
      organizationId: $organizationId
    ) @connection(key: "teamEventsConnection", filter: ["search", "ownerIds"]) {
      count
      edges {
        node {
          ...TeamEventGrid
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${TeamEvent.fragment}
`;

TeamEvent.create = gql`
  mutation TeamEventCreateEvent(
    $requestedFor: DateTime!
    $includeVirtualEvent: Boolean!
    $requireInstructor: Boolean
    $testEventType: TestEventType
  ) {
    createTeamEvent(
      requestedFor: $requestedFor
      includeVirtualEvent: $includeVirtualEvent
      requireInstructor: $requireInstructor
      testEventType: $testEventType
    ) {
      id
    }
  }
`;

TeamEvent.teamEventGoals = gql`
  query TeamEventGoals {
    teamEventGoals {
      id
      goal
    }
  }
`;

TeamEvent.zoomMeeting = gql`
  query handleZoomMeetingUpdateQuery($id: ID!) {
    zoomMeeting(id: $id) {
      id
      hostJoinedAt
    }
  }
`;

const strings = {
  hostEmail: 'Host Email',
  requestedBy: 'Requested By',
  requestedFor: 'Date',
  virtualEventType: 'Virtual Event Type',
};

export default TeamEvent;
