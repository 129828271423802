import { GenericReferenceInput } from '~/graphql';
import SelectXperienceCategories from '~/Input/SelectXperienceCategories';

import { DataTableFilter, GraphQLFiltersInput } from '../interface';
import { KeysOfType } from '../utilTypes';

/**
 * A <DataTable> filter that displays a dropdown where you can select multiple
 * categories to filter to.
 */
export function categoryFilter<
  TFilters extends GraphQLFiltersInput,
  TFilterKey extends KeysOfType<TFilters, GenericReferenceInput[]> = KeysOfType<
    TFilters,
    GenericReferenceInput[]
  >
>(
  key: TFilterKey,
  title = 'Has categories',
): DataTableFilter<TFilters, TFilterKey, GenericReferenceInput[]> {
  return {
    field: key,
    title,
    component: ({ value = [], onChange }) => {
      return (
        <SelectXperienceCategories
          value={value}
          onChange={(categories: GenericReferenceInput[]) => onChange(categories)}
          css={{ width: '100%' }}
        />
      );
    },
  };
}
