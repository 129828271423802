import React from 'react';
import { sizes } from '~/styles';

const opacity = 0.1;

const ScottHr = ({
  marginTop = sizes.Spacing.Medium,
  marginBottom = sizes.Spacing.Medium,
  isVertical,
  ...otherProps
}: {
  marginTop?: number;
  marginBottom?: number;
  isVertical?: boolean;
}) =>
  isVertical ? (
    <div
      css={{
        opacity,
        background: 'black',
        width: 1,
        minHeight: '100%',
        marginLeft: marginTop,
        marginRight: marginBottom,
      }}
      {...otherProps}
    />
  ) : (
    <hr
      css={{
        width: '100%',
        opacity,
        marginTop,
        marginBottom,
      }}
      {...otherProps}
    />
  );

export default ScottHr;
