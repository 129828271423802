import React from 'react';
import Row from '~/Row';
import Text from '~/Text';
import gql from 'graphql-tag';
import * as GraphQL from '~/graphql';
import { sizes } from '~/styles';
import { Icon } from 'antd';
import OperatingHours from '~/OperatingHours';
import SchedulesView from './Schedules/View';
import ScottTitle from '~/ScottTitle';
import ScheduleItemCell from '~/ScheduleItemCell';

const strings = {
  doesntExistPlaceholder: '-',
  operatingHours: 'Operating Hours',
  noOperatingHours: 'No operating hours added',
};

const ExperienceAvailabilityView = (
  props: GraphQL.ExperienceAvailabilityView.Fragment,
) => {
  const { availabilityType, schedules, operatingHours } = props;
  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      {(() => {
        switch (availabilityType) {
          case GraphQL.XperienceAvailabilityType.OperatingHours:
            return (
              <div>
                {operatingHours.length > 0 ? (
                  <>
                    <Row
                      justifyContent='flex-start'
                      marginBottom={sizes.Spacing.XXSmall}
                    >
                      <Icon
                        type='calendar'
                        style={{ fontSize: '12px', fontWeight: 700 }}
                      />
                      <ScottTitle
                        type='SubSection'
                        css={{ marginBottom: 0, opacity: 1 }}
                      >
                        {strings.operatingHours}
                      </ScottTitle>
                    </Row>
                    <div css={{ paddingLeft: '24px' }}>
                      <OperatingHours
                        isEditMode={false}
                        operatingHours={operatingHours}
                      />
                    </div>
                  </>
                ) : (
                  <Text size='Small' color='GreyDark'>
                    {strings.noOperatingHours}
                  </Text>
                )}
              </div>
            );
          case GraphQL.XperienceAvailabilityType.Schedule:
            return <SchedulesView schedules={schedules} />;
          default:
            throw new Error(`unknown availabilityType: ${availabilityType}`);
        }
      })()}
    </div>
  );
};

export default ExperienceAvailabilityView;

ExperienceAvailabilityView.fragment = gql`
  fragment ExperienceAvailabilityView on Xperience {
    id
    availabilityType
    schedules {
      ...ScheduleItemCell
    }
    operatingHours {
      id
      open {
        day
        hours
        minutes
        time
      }
      close {
        day
        hours
        minutes
        time
      }
    }
  }
  ${ScheduleItemCell.fragment}
`;
