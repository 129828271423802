import React, { useState } from 'react';
import Text from '~/Text';
import { Icon } from 'antd';
import Link from '~/Link';
import { sizes } from '~/styles';
import Editor from './Editor';

export type Value = {
  title: string;
  description?: string;
};

export type Props = {
  value: Value;
  onChange(value: Value): void;
  maxWidth?: number;
  rightContent?: React.ReactElement;
  hideUserFacingLabel?: boolean;
  noDescription?: boolean;
};

const EditableTitle = (props: Props) => {
  const { value, maxWidth = 980 } = props;
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: `${maxWidth}px`,
          marginBottom: sizes.Spacing.Small,
        }}
      >
        <Text data-testid='editable-title-title' size='Huge' weight='Bold'>
          {value.title}
        </Text>
        <Link
          data-testid='title-description-edit-button'
          css={{ marginLeft: sizes.Spacing.XSmall }}
          onClick={() => setIsEditing(!isEditing)}
        >
          <Icon type={'form'} style={{ fontSize: '18px' }} />
        </Link>
        {props.rightContent}
      </div>
      {!props.noDescription && (
        <div css={{ marginBottom: sizes.Spacing.XLarge, maxWidth: `${maxWidth}px` }}>
          {value.description ? (
            <Text
              data-testid='editable-title-description'
              color='Slate'
              weight='Regular'
              size='Base'
            >
              {value.description.split('\n').map(line => (
                <>
                  {line}
                  <br />
                </>
              ))}
            </Text>
          ) : (
            <Text color='GreyDark' size='Small'>
              No description has been added
            </Text>
          )}
        </div>
      )}
      <Editor {...props} isOpen={isEditing} close={() => setIsEditing(false)} />
    </div>
  );
};

export default EditableTitle;
