import gql from 'graphql-tag';
import _ from 'lodash';
import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useQuery } from 'react-query';

import * as GraphQL from '~/graphql';

import { Select } from 'antd';

interface Props {
  name?: string; // for title
  onChange: (data: any) => any;
  values?: string[]; // backwards-compatibility
  value?: string | string[];
  single?: boolean;
  useIds?: boolean;
}

const TagsSelect = ({ name, values, value, onChange, single, useIds, ...otherProps }: Props) => {
  const client = useApolloClient();

  const { data } = useQuery('tags', async () =>
    client
      .query({ query: TagsSelect.query })
      .then(response =>
        _.map(response.data.tags, ({ id, name }) => ({ id, name })).sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      ),
  );

  return (
    <Select
      mode={single ? 'default' : 'tags'}
      placeholder={single ? strings.singlePlaceholder : strings.multiPlaceholder}
      optionFilterProp='label'
      value={values || value}
      onChange={newValues => onChange(newValues)}
      {...otherProps}
    >
      {_.map(data, tag => (
        <Select.Option key={useIds ? tag.id : tag.name} label={tag.name}>
          {tag.name}
        </Select.Option>
      ))}
    </Select>
  );
};

const strings = {
  singlePlaceholder: 'No tag selected',
  multiPlaceholder: 'No tags selected',
};

TagsSelect.fragment = gql`
  fragment SearchTagsMultiSelect on Tag {
    id
    name
  }
`;

TagsSelect.query = gql`
  query SearchTagsMultiSelect {
    tags {
      ...SearchTagsMultiSelect
    }
  }
  ${TagsSelect.fragment}
`;

export default TagsSelect;
