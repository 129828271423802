import gql from 'graphql-tag';
import _ from 'lodash';

import { getApolloClient } from './apollo';

export const refreshUnreadMessageStatus = _.throttle(async adventureId => {
  await getApolloClient().query({
    query: gql`
      query UnreadMessagesStatusCheck($first: Int, $ids: [ID]) {
        mysteriesConnection(first: $first, ids: $ids)
          @connection(key: "mysteriesConnection", filter: ["ids"]) {
          edges {
            node {
              id
              hasUnreadMessages
            }
          }
        }
      }
    `,
    variables: { ids: [adventureId], first: 1 },
    fetchPolicy: 'network-only',
  });
}, 1500);
