import React, { useState } from 'react';
import moment from 'moment';
import { sizes } from '~/styles';
import Text from '~/Text';
import ScottTitle from '~/ScottTitle';
import { Button } from 'antd';
import { Spacing } from '~/styles/sizes';

const strings = {
  selectedDate: 'Selected Date',
  noDateSelected: 'Please select a date to blackout',
  addBlackoutDate: 'Add Blackout Date',
};

const BlackoutDateCell = (props: {
  selectedDate?: moment.Moment;
  addBlackoutDate: (date: any) => void;
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const { selectedDate } = props;

  if (!selectedDate && buttonLoading) setButtonLoading(false);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        padding: Spacing.Large,
        width: 400,
      }}
    >
      <ScottTitle>{strings.selectedDate}</ScottTitle>
      <Text size='Large' css={{ marginBottom: sizes.Spacing.Small }}>
        {selectedDate ? selectedDate.format('dddd, MMM Do') : strings.noDateSelected}
      </Text>
      <Button
        css={{ width: 150 }}
        type='primary'
        disabled={!selectedDate}
        loading={buttonLoading}
        onClick={async () => {
          setButtonLoading(true);
          await props.addBlackoutDate(selectedDate.format('MM/DD/YYYY'));
        }}
      >
        {strings.addBlackoutDate}
      </Button>
    </div>
  );
};

export default BlackoutDateCell;
