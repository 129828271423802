import gql from 'graphql-tag';
import _ from 'lodash';
import { useApolloClient } from '@apollo/react-hooks';

import Search from '~/Search';
import * as GraphQL from '~/graphql';
import icons from '~/lib/styles/icons';

interface Props {
  onChange: any;
  value?: string;
  entities: string[];
  limit?: number;
}

const OmniSearch = ({ value, onChange, entities, limit, ...otherProps }: Props) => {
  const client = useApolloClient();

  const showIcon = entities.length > 1;

  const onSearch = async (query: string) => {
    const { data } = await client.mutate<
      GraphQL.OmniSearch.Mutation,
      GraphQL.OmniSearch.Variables
    >({
      mutation: OmniSearch.mutation,
      variables: {
        query,
        entities,
        limit,
        fieldsToSearch: ['name'],
      },
    });

    const results = data!.search;

    return _.map(results, result => ({
      key: result.id,
      id: result.id,
      title: result.name,
      description: result.description,
      entityType: result.entityType,
      parentId: result.parentId,
      icon: showIcon ? icons[result.entityType] : undefined,
    }));
  };

  return (
    <Search
      autofocus={false}
      onSelect={onChange}
      defaultValue={value}
      onSearch={onSearch}
      {...otherProps}
    />
  );
};

OmniSearch.mutation = gql`
  mutation OmniSearch(
    $query: String!
    $entities: [String!]!
    $fieldsToSearch: [String!]!
    $limit: Int
  ) {
    search(
      params: {
        query: $query
        entities: $entities
        fieldsToSearch: $fieldsToSearch
        limit: $limit
      }
    ) {
      id
      name
      description
      entityType
      parentId
    }
  }
`;

export default OmniSearch;
