import React from 'react';

export const NegativeCheckedIcon = () => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 9 9'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.35 0.9C1.10147 0.9 0.9 1.10147 0.9 1.35V7.65C0.9 7.89853 1.10147 8.1 1.35 8.1H7.65C7.89853 8.1 8.1 7.89853 8.1 7.65V1.35C8.1 1.10147 7.89853 0.9 7.65 0.9H1.35ZM0 1.35C0 0.604416 0.604416 0 1.35 0H7.65C8.39558 0 9 0.604416 9 1.35V7.65C9 8.39558 8.39558 9 7.65 9H1.35C0.604416 9 0 8.39558 0 7.65V1.35Z'
      fill='#D40035'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.50818 2.49263C6.71971 2.70417 6.71971 3.04713 6.50818 3.25867L3.25818 6.50867C3.04664 6.7202 2.70368 6.7202 2.49215 6.50867C2.28061 6.29713 2.28061 5.95417 2.49215 5.74264L5.74215 2.49263C5.95368 2.2811 6.29664 2.2811 6.50818 2.49263Z'
      fill='#D40035'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.49215 2.49263C2.70368 2.2811 3.04664 2.2811 3.25818 2.49263L6.50818 5.74264C6.71971 5.95417 6.71971 6.29713 6.50818 6.50867C6.29664 6.7202 5.95368 6.7202 5.74215 6.50867L2.49215 3.25867C2.28061 3.04713 2.28061 2.70417 2.49215 2.49263Z'
      fill='#D40035'
    />
  </svg>
);

export const CheckedIcon = () => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 11 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.4073 1.50924C10.5863 1.68823 10.5863 1.97843 10.4073 2.15742L5.82393 6.74076C5.64494 6.91975 5.35474 6.91975 5.17575 6.74076L3.80075 5.36576C3.62176 5.18677 3.62176 4.89657 3.80075 4.71758C3.97974 4.53859 4.26994 4.53859 4.44893 4.71758L5.49984 5.76849L9.75908 1.50924C9.93807 1.33025 10.2283 1.33025 10.4073 1.50924Z'
      fill='#4A96CB'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.2915 1.83268C2.16995 1.83268 2.05337 1.88097 1.96741 1.96693C1.88146 2.05288 1.83317 2.16946 1.83317 2.29102V8.70768C1.83317 8.82924 1.88146 8.94582 1.96741 9.03177C2.05337 9.11773 2.16995 9.16602 2.2915 9.16602H8.70817C8.82973 9.16602 8.94631 9.11773 9.03226 9.03177C9.11822 8.94582 9.1665 8.82924 9.1665 8.70768V5.49935C9.1665 5.24622 9.37171 5.04102 9.62484 5.04102C9.87797 5.04102 10.0832 5.24622 10.0832 5.49935V8.70768C10.0832 9.07236 9.9383 9.42209 9.68044 9.67995C9.42258 9.93782 9.07284 10.0827 8.70817 10.0827H2.2915C1.92683 10.0827 1.57709 9.93782 1.31923 9.67995C1.06137 9.42209 0.916504 9.07236 0.916504 8.70768V2.29102C0.916504 1.92634 1.06137 1.57661 1.31923 1.31874C1.57709 1.06088 1.92683 0.916016 2.2915 0.916016H7.33317C7.5863 0.916016 7.7915 1.12122 7.7915 1.37435C7.7915 1.62748 7.5863 1.83268 7.33317 1.83268H2.2915Z'
      fill='#4A96CB'
    />
  </svg>
);

export const UnCheckedIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height='12'
    width='12'
    viewBox={`0 0 9 9`}
    fill='none'
    preserveAspectRatio='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.35 0.9C1.10147 0.9 0.9 1.10147 0.9 1.35V7.65C0.9 7.89853 1.10147 8.1 1.35 8.1H7.65C7.89853 8.1 8.1 7.89853 8.1 7.65V1.35C8.1 1.10147 7.89853 0.9 7.65 0.9H1.35ZM0 1.35C0 0.604416 0.604416 0 1.35 0H7.65C8.39558 0 9 0.604416 9 1.35V7.65C9 8.39558 8.39558 9 7.65 9H1.35C0.604416 9 0 8.39558 0 7.65V1.35Z'
      fill='#AAB4BE'
    />
  </svg>
);
