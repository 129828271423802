import React from 'react';
import { sizes } from '~/styles';
import ScottHr from '~/ScottHr';
import AddNewEventButton from './AddNewEventButton';
import FilterDate from '~/Filter/Date';
import FilterCapacityType from '~/Filter/CapacityType';
import SearchInput from '~/Search/Input';

const EventsFilterBar = () => {
  const [params] = useQueryParams({
    dateFilterType: StringParam,
  });

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'stretch',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'stretch',
          '> *:not(:last-child)': {
            marginRight: sizes.Spacing.XSmall,
          },
        }}
      >
        <SearchInput
          autoFocus={false}
          size='default'
          css={{ width: 250 }}
          placeholder='Filter by partner/experience name'
        />
        <FilterCapacityType />
        <DateFilterTypeSelect />
        {params.dateFilterType && params.dateFilterType === 'Custom' && (
          <>
            <FilterDate queryParamName='startDate' placeholder='Start date' />
            <FilterDate queryParamName='endDate' placeholder='End date' />
          </>
        )}
      </div>
      <ScottHr isVertical />
      <AddNewEventButton />
    </div>
  );
};

export default EventsFilterBar;

import _ from 'lodash';

import * as GraphQL from '~/graphql';
import { memo } from '~/react';
import MultiSelectFilter from '~/Filter/MultiSelect';
import { useQueryParams, StringParam } from 'use-query-params';

const DateFilterTypeSelect = ({ ...otherProps }) => {
  return (
    <MultiSelectFilter
      name={'dateFilterType'}
      placeholder='Filter by date'
      options={['Past', 'Future', 'Custom'].map(name => ({
        label: name,
        value: name,
      }))}
      {...otherProps}
      mode='single'
    />
  );
};
