import _ from 'lodash';
import { Checkbox, Input } from 'antd';
import gql from 'graphql-tag';

import * as GraphQL from '~/graphql';
import { Edits } from '~/hooks/useEditableEntity';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import Row from '~/Row';
import InputNumberWithUnit from '~/Input/NumberWithUnit';

import Heading from '../../Heading';
import { addSpacesToPascalCasedString } from '~/formatters';
import CreditCardOnFileSelector from '~/CreditCardOnFileSelector';

export interface Props {
  experience: GraphQL.ExperienceProfilePhysicalDetails.Fragment;
  onChange: (edits: Edits<GraphQL.ExperienceProfilePhysicalDetails.Fragment>) => void;
}

const ExperienceProfilePhysicalDetails = ({ experience, onChange }: Props) => {
  return (
    <div>
      <Heading>Mystery Stop</Heading>
      <Row>
        <LabeledField label='When You Arrive'>
          <Input.TextArea
            rows={6}
            value={experience.checkInMessage}
            onChange={event => onChange({ checkInMessage: event.target.value })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label={`When You're Ready to Leave`}>
          <Input.TextArea
            rows={6}
            value={experience.checkOutMessage}
            onChange={event => onChange({ checkOutMessage: event.target.value })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='How to Pay'>
          <Input.TextArea
            rows={6}
            value={experience.paymentMessage}
            onChange={event => onChange({ paymentMessage: event.target.value })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Card on File'>
          <CreditCardOnFileSelector
            currentSelection={experience.cardOnFile}
            setCardOnFile={cardOnFile => onChange({ cardOnFile })}
          />
        </LabeledField>
      </Row>
    </div>
  );
};

ExperienceProfilePhysicalDetails.fragment = gql`
  fragment ExperienceProfilePhysicalDetails on Xperience {
    id
    checkInMessage
    checkOutMessage
    paymentMessage
    cardOnFile {
      id
      last4
      expMonth
      expYear
    }
  }
`;

export default ExperienceProfilePhysicalDetails;
