import { useQuery } from '@apollo/react-hooks';
import { Result, Tabs } from 'antd';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import EditableTitle from '~/EditableTitle';
import EditControls from '~/EditControls';

import FullScreenSpinner from '~/FullScreenSpinner';
import * as GraphQL from '~/graphql';
import { Edits, useEditableEntity } from '~/hooks/useEditableEntity';
import EditQuestionnaireButton from '~/QuestionnaireDialog/EditQuestionnaireButton';

import Page from '../Page';
import ExperienceProfileAuditLogs from './AuditLogs';
import ExperienceProfileDetails from './Details';
import ExperienceProfilePricing from './Pricing';
import ExperienceProfileShipping from './Shipping';

const ExperienceProfile = () => {
  const { experienceId } = useParams<{ experienceId: string }>();

  const { loading, data, error } = useQuery<
    GraphQL.ExperienceProfile.Query,
    GraphQL.ExperienceProfile.Variables
  >(ExperienceProfile.query, {
    variables: { experienceId },
    fetchPolicy: 'network-only',
  });

  if (loading) return <FullScreenSpinner />;

  if (error) {
    return (
      <Page>
        <Result
          status={500}
          title='Error loading Experience'
          subTitle='We ran into an error when loading this experience.'
        >
          <div>Details for developers:</div>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Result>
      </Page>
    );
  }

  if (!data || !data.xperience || !data.xperience.id) {
    return (
      <Page>
        <Result
          status={404}
          title='Experience not found'
          subTitle="Sorry, the experience you're looking for does not exist."
        />
      </Page>
    );
  }

  return <ExperienceProfileContent xperience={data.xperience} />;
};

const ExperienceProfileContent = (props: { xperience: GraphQL.ExperienceProfile.Fragment }) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { tabName } = useParams<{ tabName: string }>();
  const [experience, { edits, edit, clearEdits }] = useEditableEntity(props.xperience);

  const beforeUpdate = useMemo(() => {
    return function beforeUpdate(edits: Edits<GraphQL.ExperienceProfile.Fragment>) {
      if (edits.templates) {
        // We get { id, title } back from the select widget…
        edits.templates = edits.templates.map(({ id }) => ({ id }));
      }
      return edits;
    };
  }, []);

  return (
    <Page breadcrumbProps={{ partner: experience.partner, experience }}>
      <>
        <div css={{ display: 'flex' }}>
          <div css={{ flex: 1 }}>
            <EditableTitle
              value={{
                title: experience.name,
              }}
              onChange={({ title }) => {
                edit({ name: title });
              }}
              noDescription
            />
          </div>
          <EditControls
            updateMutation={ExperienceProfile.updateMutation}
            deleteMutation={ExperienceProfile.deleteMutation}
            edits={edits}
            clearEdits={clearEdits}
            afterDeleteLocation={`/supply/partner/${experience.partner.id}/experiences`}
            beforeUpdate={beforeUpdate}
            extraControls={
              <EditQuestionnaireButton
                button
                questionnaire={experience.defaultExperiencePreEventQuestionnaire}
                defaultExperiencePreEventQuestionnaire={{ id: experience.id }}
              />
            }
          />
        </div>
        <Tabs
          size='large'
          activeKey={tabName || 'details'}
          css={{ flex: 1, margin: `0 -16px`, '& .ant-tabs-tabpane': { padding: `0 16px` } }}
          onChange={newActiveKey => {
            history.push(
              path
                .replace(':partnerId', experience.partner.id)
                .replace(':experienceId', experience.id)
                .replace(':tabName', newActiveKey),
            );
          }}
        >
          <Tabs.TabPane tab='Experience Details' key='details'>
            <ExperienceProfileDetails experience={experience} onChange={edit} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Shipped Goods' key='shipping'>
            <ExperienceProfileShipping experience={experience} onChange={edit} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Pricing' key='pricing'>
            <ExperienceProfilePricing {...experience} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Change Log' key='audit'>
            <ExperienceProfileAuditLogs {...experience} />
          </Tabs.TabPane>
        </Tabs>
      </>
    </Page>
  );
};

ExperienceProfile.fragment = gql`
  fragment ExperienceProfile on Xperience {
    id
    name
    description
    defaultExperiencePreEventQuestionnaire {
      id
      questions {
        ...EditQuestionnaireButton
      }
    }
    partner {
      id
      name
    }
    ...ExperienceProfileDetails
    ...ExperienceProfileShipping
    ...ExperienceProfilePricing
    ...ExperienceProfileAuditLogs
  }
  ${ExperienceProfileDetails.fragment}
  ${ExperienceProfileShipping.fragment}
  ${ExperienceProfilePricing.fragment}
  ${ExperienceProfileAuditLogs.fragment}
  ${EditQuestionnaireButton.fragment}
`;

ExperienceProfile.query = gql`
  query ExperienceProfile($experienceId: ID!) {
    xperience(id: $experienceId) {
      ...ExperienceProfile
    }
  }
  ${ExperienceProfile.fragment}
`;

ExperienceProfile.updateMutation = gql`
  mutation ExperienceProfileUpdate(
    $id: ID!
    $breakoutRoomSize: Int
    $cardOnFile: GenericReferenceInput
    $categories: [GenericReferenceInput]
    $checkInMessage: String
    $checkOutMessage: String
    $connectionFocusAreas: [ConnectionFocusArea]
    $dayBeforeInstructions: Markdown
    $description: String
    $durationOptions: String
    $estimatedDurationMins: Int
    $experienceInstructions: String
    $experienceStructure: String
    $friendlyTowardsAccessibilityTypes: [GenericReferenceInput]
    $friendlyTowardsDietaryRestrictions: [GenericReferenceInput]
    $hybridGroupInstructions: Markdown
    $internationalShipping: XperienceInternationalShippingInput
    $isActive: Boolean
    $isVirtual: Boolean
    $leadTimeNeededDays: Int
    $name: String
    $notFriendlyTowardsAccessibilityTypes: [GenericReferenceInput]
    $notFriendlyTowardsDietaryRestrictions: [GenericReferenceInput]
    $numHosts: Int
    $numIdealGuests: Int
    $numMaxGuests: Int
    $numMinGuests: Int
    $owner: ReferenceUserInput
    $ownerRoles: [XperienceOwnerRole]
    $paymentMessage: String
    $phone: String
    $photoUrl: String
    $physicalGoodsDescription: Markdown
    $postEventInformation: Markdown
    $priceLevel: Int
    $relatedInterests: [GenericReferenceInput]
    $shippingCustomizations: Markdown
    $supplyKitInstructions: String
    $tags: [TagInput]
    $templates: [GenericReferenceInput]
    $url: String
    $usaShipping: XperienceUSAShippingInput
  ) {
    updateXperience(
      id: $id
      breakoutRoomSize: $breakoutRoomSize
      cardOnFile: $cardOnFile
      categories: $categories
      checkInMessage: $checkInMessage
      checkOutMessage: $checkOutMessage
      connectionFocusAreas: $connectionFocusAreas
      dayBeforeInstructions: $dayBeforeInstructions
      description: $description
      durationOptions: $durationOptions
      estimatedDurationMins: $estimatedDurationMins
      experienceInstructions: $experienceInstructions
      experienceStructure: $experienceStructure
      friendlyTowardsAccessibilityTypes: $friendlyTowardsAccessibilityTypes
      friendlyTowardsDietaryRestrictions: $friendlyTowardsDietaryRestrictions
      hybridGroupInstructions: $hybridGroupInstructions
      internationalShipping: $internationalShipping
      isActive: $isActive
      isVirtual: $isVirtual
      leadTimeNeededDays: $leadTimeNeededDays
      name: $name
      notFriendlyTowardsAccessibilityTypes: $notFriendlyTowardsAccessibilityTypes
      notFriendlyTowardsDietaryRestrictions: $notFriendlyTowardsDietaryRestrictions
      numHosts: $numHosts
      numIdealGuests: $numIdealGuests
      numMaxGuests: $numMaxGuests
      numMinGuests: $numMinGuests
      owner: $owner
      ownerRoles: $ownerRoles
      paymentMessage: $paymentMessage
      phone: $phone
      photoUrl: $photoUrl
      physicalGoodsDescription: $physicalGoodsDescription
      postEventInformation: $postEventInformation
      priceLevel: $priceLevel
      relatedInterests: $relatedInterests
      shippingCustomizations: $shippingCustomizations
      supplyKitInstructions: $supplyKitInstructions
      tags: $tags
      templates: $templates
      url: $url
      usaShipping: $usaShipping
    ) {
      ...ExperienceProfile
    }
  }
  ${ExperienceProfile.fragment}
`;

ExperienceProfile.deleteMutation = gql`
  mutation ExperienceProfileDelete($id: ID!) {
    deleteXperience(id: $id) {
      __typename
    }
  }
`;

export default ExperienceProfile;
