import { message, Icon, Popconfirm } from 'antd';
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';

import gql from 'graphql-tag';
import * as GraphQL from '~/graphql';
import { sizes, colors } from '~/styles';
import Link from '~/Link';
import EventDialog from '~/EventDialog';

interface Props {
  eventId: string;
}

const EventsActionCell = ({ eventId }: Props) => {
  const [isDialogMounted, setIsDialogMounted] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (isEditing) setIsDialogMounted(true);
    else setTimeout(() => setIsDialogMounted(false), 1000);
  }, [isEditing]);

  const closeDialog = () => {
    setIsEditing(false);
  };

  const [deleteEvent] = useMutation<
    GraphQL.DeleteEvent.Mutation,
    GraphQL.DeleteEvent.Variables
  >(EventsActionCell.deleteMutation, {
    variables: { eventId },
    refetchQueries: ['EventsList'],
  });

  const onClickDelete = async () => {
    message.loading(`Deleting experience`);
    try {
      await deleteEvent();
      message.destroy();
      message.success(`Event successfully deleted`);
    } catch (e) {
      message.destroy();
      message.error(`Problem encountered: ${e}`);
    }
  };

  return (
    <>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: 16,
          maxWidth: sizes.GRID_UNIT * 10,
        }}
      >
        <Link data-testid='edit-event-link' onClick={() => setIsEditing(true)}>
          <Icon type='edit' />
        </Link>
        <Popconfirm
          placement='left'
          title='Are you sure you want to delete this event？'
          okText='Yes'
          cancelText='No'
          onConfirm={onClickDelete}
        >
          <Link data-testid='delete-event-link' color={colors.Negative}>
            <Icon type='delete' />
          </Link>
        </Popconfirm>
      </div>
      {isDialogMounted && (
        <EventDialog
          isOpen={isEditing}
          close={closeDialog}
          eventId={eventId}
          refetchQueries={['EventsList']}
        />
      )}
    </>
  );
};

EventsActionCell.deleteMutation = gql`
  mutation DeleteEvent($eventId: ID!) {
    deleteEvent(id: $eventId) {
      __typename
    }
  }
`;

export default EventsActionCell;
