import React from 'react';
import { createMask } from 'string-mask-jedi';

import useMask from '~/hooks/useMask';
import { memo } from '~/react';
import Input from './';

interface Props {
  value: string;
  onChange: any;
  placeholder?: string;
  size?: 'small' | 'default' | 'large';
}

const phoneMask = createMask('(ddd) ddd-dddd', { d: /\d/ });

const InputPhone = ({
  size = 'large',
  placeholder = strings.placeholder,
  value: propsValue,
  onChange: propsOnChange,
  ...otherProps
}: Props) => {
  const { value, onChange, ref } = useMask(phoneMask, propsOnChange, propsValue);

  return (
    <Input
      ref={ref}
      size={size}
      value={value}
      inputComponent={Input}
      placeholder={placeholder}
      onChange={onChange}
      {...otherProps}
    />
  );
};

const strings = {
  placeholder: '(206) 867-5309',
};

export default memo(InputPhone);
