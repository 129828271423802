import React from 'react';
import ScottTitle from '~/ScottTitle';
import Row from '~/Row';
import Text from '~/Text';
import _ from 'lodash';
import moment from 'moment';

import * as GraphQL from '~/graphql';
import { sizes } from '~/styles';
import { rrulestr } from 'rrule';
import { DatePicker, TimePicker } from 'antd';

const SelectDateSection = (props: {
  experienceId?: string;
  schedule?: GraphQL.ScheduleItemCell.Fragment;
  date?: moment.Moment;
  startTime?: moment.Moment;
  endTime?: moment.Moment;
  setDate(newDate: moment.Moment): void;
  setStartTime(newStartTime: moment.Moment): void;
  setEndTime(newEndTime: moment.Moment): void;
}) => {
  const availableDates = props.schedule
    ? rrulestr(props.schedule.rrule)
        .between(
          moment()
            .utc()
            .startOf('day')
            .toDate(),
          moment()
            .utc()
            .startOf('day')
            .add(1, 'year')
            .toDate(),
        )
        .map(date =>
          moment({
            year: date.getUTCFullYear(),
            month: date.getUTCMonth(),
            date: date.getUTCDate(),
          }),
        )
    : undefined;

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <ScottTitle type='SubSection'>Select Dates</ScottTitle>
      <Row alignItems='center'>
        <div css={{ flex: 1 }}>
          <DatePicker
            allowClear={false}
            size='large'
            data-testid='event-details-date-selector'
            format='MM-DD-YYYY'
            disabledDate={current => {
              if (!props.schedule || !current || !availableDates)
                return current && current < moment().startOf('day');

              return !_.some(
                _.map(availableDates, date => date.isSame(current, 'day')),
              );
            }}
            value={props.date}
            onChange={newDate => props.setDate(newDate)}
          />
        </div>
        {props.schedule ? (
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Text
              size='Small'
              color='GreyDarker'
              css={{ marginBottom: sizes.Spacing.XXSmall }}
            >
              {`${moment(props.schedule.startTime, 'HH:mm').format(
                'h:mma',
              )} to ${moment(props.schedule.startTime, 'HH:mm')
                .add(props.schedule.durationMins, 'minutes')
                .format('h:mma')}`}
            </Text>
            <Text size='XSmall' color='GreyDark'>{`${
              props.schedule.durationMins
            } minutes`}</Text>
          </div>
        ) : (
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: sizes.Spacing.Medium,
              flex: 1,
            }}
          >
            <div data-testid='select-date-start-time-input'>
              <TimePicker
                size='large'
                use12Hours
                minuteStep={5}
                placeholder={'Start'}
                value={props.startTime}
                format='h:mm a'
                onChange={time => props.setStartTime(time)}
                css={{ width: sizes.GRID_UNIT * 20 }}
              />
            </div>
            <Text
              size='Small'
              color='GreyDark'
              css={{
                marginLeft: sizes.Spacing.XSmall,
                marginRight: sizes.Spacing.XSmall,
              }}
            >
              to
            </Text>
            <div data-testid='select-date-end-time-input'>
              <TimePicker
                size='large'
                data-testid='select-date-end-time-input'
                use12Hours
                minuteStep={5}
                placeholder={'End'}
                value={props.endTime}
                format='h:mm a'
                onChange={time => props.setEndTime(time)}
                css={{ width: sizes.GRID_UNIT * 20 }}
              />
            </div>
          </div>
        )}
      </Row>
    </div>
  );
};

export default SelectDateSection;
