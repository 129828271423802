import { sizes } from '~/styles';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const CostToCustomerRow = ({ children, className }: Props): JSX.Element => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '> *:not(:last-child)': { marginRight: sizes.Spacing.Medium },
    }}
    className={className}
  >
    {children}
  </div>
);

export default CostToCustomerRow;
