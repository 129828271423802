import React, { useState } from 'react';
import { sizes, colors } from '~/styles';
import ScottTitle from '~/ScottTitle';
import Text from '~/Text';
import Link from '~/Link';
import { Icon } from 'antd';
import ScottHr from '~/ScottHr';
import ScottSection from '~/ScottSection';

import _ from 'lodash';

type Props<T> = {
  /** label of the section on the top left */
  label: string;
  /** this is the body of the section that will show the read version of data,
   * each element will be delimited by a hr
   */
  viewParts: React.FunctionComponent<T>[];
  /** this is the modal used to edit the data */
  EditModal?: React.FunctionComponent<{
    data: T;
    isOpen: boolean;
    close(): void;
  }>;
  /**
   * the parent of this component will determine if anything should be rendered
   * the presence of this string means render this text in the body instead of view
   */
  emptyMessage?: string;
  /**
   * used to determine what state we are in and will prevent rendering the edit dialogs if
   * we don't have a real object yet
   */
  data?: T;
  /**
   * if this section doesn't have an EditModal,
   * you can give it a path here and it will make
   * an icon link that will link to that page
   */
  pageLinkPath?: string;
  pageLinkText?: string;

  /**
   * this makes the section flex its view parts horizontally instead of vertically
   */
  isHorizontal?: boolean;

  noBodyPadding?: boolean;
};

const LabeledSection = <T extends {}>(props: Props<T>) => {
  const {
    label,
    viewParts,
    EditModal,
    emptyMessage,
    data,
    pageLinkPath,
    pageLinkText,
    isHorizontal,
    noBodyPadding,
    ...otherProps
  } = props;
  if ((pageLinkPath || pageLinkText) && EditModal)
    throw new Error("Can't have an edit modal and a link currently.");

  const numberOfViewParts = viewParts.length;

  const hasThingToEdit = data ? Object.keys(data).length !== 0 : false;
  const shouldRenderEditModal = EditModal && hasThingToEdit;

  const [isEdit, setIsEdit] = useState(false);

  return (
    <div css={{ marginBottom: sizes.Spacing.Large }} {...otherProps}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: sizes.GRID_UNIT * 2,
          marginRight: sizes.GRID_UNIT * 4,
          marginBottom: sizes.GRID_UNIT * 1,
        }}
      >
        <ScottTitle css={{ marginLeft: sizes.GRID_UNIT * 2, marginBottom: 0 }}>
          {label}
        </ScottTitle>
        {(shouldRenderEditModal || pageLinkPath) && (
          <Link
            data-testid={
              shouldRenderEditModal
                ? `labeled-section-edit-${_.lowerCase(label)}`
                : `labeled-section-link`
            }
            css={{
              marginLeft: sizes.Spacing.XSmall,
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              top: '-2px',
            }}
            href={pageLinkPath}
            onClick={shouldRenderEditModal ? () => setIsEdit(true) : undefined}
            useReactRouterLink={!shouldRenderEditModal}
          >
            {shouldRenderEditModal && (
              <Icon type={'form'} style={{ fontSize: '18px' }} />
            )}
            {pageLinkText ? (
              <Text
                size='Base'
                css={{
                  marginLeft: sizes.Spacing.XXSmall,
                  color: colors.AntdBlue,
                  textTransform: 'uppercase',
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                {pageLinkText}
              </Text>
            ) : null}
          </Link>
        )}
      </div>
      <ScottSection
        noPadding={noBodyPadding}
        css={isHorizontal ? { display: 'flex', justifyContent: 'space-around' } : {}}
      >
        {emptyMessage ? (
          <Text color='GreyDark' size='Small'>
            {emptyMessage}
          </Text>
        ) : (
          viewParts.map((View, i) => (
            <React.Fragment key={`viewpart-${i}`}>
              <View {...data} />
              {numberOfViewParts !== i + 1 && (
                <ScottHr
                  key={`viewpart-hr-${i}`}
                  isVertical={isHorizontal}
                  {...(isHorizontal ? { marginBottom: 0, marginTop: 0 } : {})}
                />
              )}
            </React.Fragment>
          ))
        )}
      </ScottSection>
      {shouldRenderEditModal && (
        <EditModal data={data} isOpen={isEdit} close={() => setIsEdit(false)} />
      )}
    </div>
  );
};

export default LabeledSection;
