import { useQuery } from '@apollo/react-hooks';
import { Button } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import React from 'react';

import * as GraphQL from '~/graphql';
import config from '~/config';
import { sizes } from '~/styles';
import Text from '~/Text';
import * as Sentry from '@sentry/react';
import { StatsigProvider } from 'statsig-react';

interface Props {
  children?: React.ReactNode;
}

const AppGate = ({ children }: Props) => {
  const { data, loading } = useQuery<GraphQL.AppGate.Query>(AppGate.query);
  if (loading) return null;

  const user = data && data.viewer;

  // Unauthenticated
  if (!user) {
    window.location.replace(`${config.apiUrl}/login?origin=${window.location.href}`);
    return null;
  }

  return (
    <StatsigProvider
      sdkKey={config.statsigKey}
      user={{
        userID: user.id,
        email: user.email,
        custom: {
          role: user.role,
        },
      }}
      options={{
        environment: {
          tier: config.nodeEnv,
        },
        localMode: config.isTest,
      }}
      waitForInitialization
    >
      <AppGateWithUser user={user}>{children}</AppGateWithUser>
    </StatsigProvider>
  );
};

const AppGateWithUser = ({
  user,
  children,
}: {
  user: GraphQL.AppGate.Query['viewer'];
  children?: React.ReactNode;
}) => {
  // Unauthorized
  if (user.role !== GraphQL.Role.Wizard) {
    return (
      <div
        css={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: sizes.Spacing.Medium,
        }}
      >
        <Text size='XXHuge' weight='Bold' css={{ marginBottom: sizes.Spacing.Large }}>
          {strings.unauthorized}
        </Text>
        <Text size='XLarge' weight='Medium' css={{ marginBottom: sizes.Spacing.Large }}>
          {strings.noAccess}
        </Text>
        <Button size='large' onClick={() => window.location.replace(`${config.apiUrl}/logout`)}>
          {strings.logOut}
        </Button>
      </div>
    );
  }

  if (config.isProduction) {
    Sentry.setUser({
      email: user.email,
      username: user.name,
      id: user.id,
    });
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const strings = {
  unauthorized: 'Unauthorized',
  noAccess: `You can't access this page with your current account.`,
  logOut: `Log out`,
};

AppGate.fragment = gql`
  fragment AppGate on User {
    id
    firstName
    lastName
    name
    email
    phone
    role
  }
`;

AppGate.query = gql`
  query AppGate {
    viewer {
      ...AppGate
    }
  }

  ${AppGate.fragment}
`;

export default AppGate;
