import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router';
import SupplyHome from './Home';
import PartnerProfile from './PartnerProfile';
import ExperienceProfile from './ExperienceProfile';

import ActivityFeed from './ActivityFeed/Page';

const Supply = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/activity`} exact component={ActivityFeed} />
      <Route path={`${path}/partner/:partnerId/:tabName?`} exact component={PartnerProfile} />
      <Route
        path={`${path}/partner/:partnerId/experience/:experienceId/:tabName?`}
        exact
        component={ExperienceProfile}
      />
      <Route path={`${path}/:tabName?`} component={SupplyHome} />
    </Switch>
  );
};

export default Supply;
