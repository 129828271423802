import { InputNumber } from 'antd';

import { DataTableFilter, GraphQLFiltersInput } from '../interface';
import { KeysOfType } from '../utilTypes';

/**
 * A <DataTable> filter that displays a numerical input field for integers.
 */
export function integerFilter<
  TFilters extends GraphQLFiltersInput,
  TFilterKey extends KeysOfType<TFilters, number> = KeysOfType<TFilters, number>
>(key: TFilterKey, title: string): DataTableFilter<TFilters, TFilterKey, number> {
  return {
    field: key,
    title,
    component: ({ value, onChange }) => (
      <InputNumber
        value={value}
        css={{ width: '100%' }}
        onChange={(value: number | string) => {
          const numericValue = typeof value === 'string' ? parseInt(value) : value;
          onChange(Number.isFinite(numericValue) ? numericValue : undefined);
        }}
      />
    ),
  };
}
