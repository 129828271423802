import { PriceStatus } from '~/graphql';
import { colors, sizes } from '~/styles';
import { BorderRadius } from '~/styles/sizes';

const styles: Record<PriceStatus, any> = {
  Paid: {
    backgroundColor: colors.Blue500,
  },
  Unpaid: {
    backgroundColor: colors.Orange,
  },
  Comped: {
    backgroundColor: colors.Red500,
  },
  Abandoned: {
    backgroundColor: colors.ExtraDarkSmoke,
  },
};

const baseStyles = {
  borderRadius: BorderRadius.XXXLarge,
  backgroundColor: colors.GreyDark,
  color: colors.White,
};

const displayStrings: Record<PriceStatus, string> = {
  Paid: 'Paid',
  Unpaid: 'Pending',
  Comped: 'Comped',
  Abandoned: 'Abanonded',
};

export default function ChargeStatus({
  status,
  className,
}: {
  status?: PriceStatus;
  className?: string;
}) {
  const statusStyle = styles[status] || {};
  return (
    <div
      css={{
        ...baseStyles,
        ...statusStyle,
        padding: sizes.Spacing.XXSmall,
        minWidth: '50px',
        alignSelf: 'center',
        justifySelf: 'center',
      }}
      className={className}
    >
      {displayStrings[status] || 'No Status'}
    </div>
  );
}
