import gql from 'graphql-tag';
import _ from 'lodash';
import React from 'react';

import { smartFormatRelativeDate } from '~/formatters';
import { memo } from '~/react';
import Strong from '~/Strong';
import { colors, font, sizes } from '~/styles';
import Text from '~/Text';
import * as GraphQL from '~/graphql';

const ConversationMessageAuthorAndTime = ({
  createdAt,
  user,
  opsUser,
  type,
}: GraphQL.ConversationMessageAuthorAndTime.Fragment) => {
  const inbound = type === GraphQL.MessageType.Inbound;
  const senderName = inbound ? user.firstName : _.get(opsUser, 'firstName');
  const time = smartFormatRelativeDate(createdAt, 'MMM D');
  const label = senderName ? (
    <>
      {senderName} <Strong css={{ display: 'inline', color: colors.Grey }}>·</Strong>{' '}
      {time}
    </>
  ) : (
    time
  );
  return (
    <div
      css={{
        paddingLeft: sizes.Spacing.XSmall,
        paddingRight: sizes.Spacing.XSmall,
        marginBottom: sizes.Spacing.XXXSmall,
        display: 'flex',
        justifyContent: inbound ? 'flex-start' : 'flex-end',
      }}
    >
      <Text size='XSmall' color='GreyDarker'>
        {label}
      </Text>
    </div>
  );
};

ConversationMessageAuthorAndTime.fragment = gql`
  fragment ConversationMessageAuthorAndTime on Message {
    type
    createdAt
    user {
      id
      firstName
    }
    opsUser {
      id
      firstName
    }
  }
`;

export default memo(ConversationMessageAuthorAndTime);
