import { Rate } from 'antd';
import React from 'react';
import { FiDollarSign } from 'react-icons/fi';

import { memo } from '~/react';
import { colors, font } from '~/styles';

interface Props {
  level?: number | null;
  readonly?: boolean;
  onChange?: (value: number) => any;
  size?: number;
}

const ExperiencesPriceLevel = ({ level, readonly, onChange, size, ...otherProps }: Props) =>
  level || !readonly ? (
    <Rate
      value={level || 0}
      count={4}
      css={{
        color: colors.AntdBlue,
        fontWeight: font.FontWeight.Bold,
        whiteSpace: 'nowrap',
        paddingTop: 1,
        '& .ant-rate-star:not(:last-child)': {
          marginRight: 0,
        },
      }}
      character={<FiDollarSign size={size} />}
      disabled={readonly}
      onChange={onChange}
      {...otherProps}
    />
  ) : (
    <div />
  );

export default memo(ExperiencesPriceLevel);
