import { Select } from 'antd';
import * as GraphQL from '~/graphql';
import PriceColumn from '~/Supply/ExperienceProfile/Pricing/PriceColumn';

const options = {
  event: 'Event',
  mystery: 'Mystery',
  person: 'Person',
  unitType: 'Unit Type',
};

interface Props {
  value: GraphQL.CostUnit;
  onChange: (value: GraphQL.CostUnit) => void;
  forPriceBand?: boolean;
  width?: number;
}

const UnitType = ({ value, onChange, forPriceBand, width = 150 }: Props) => {
  const testId = forPriceBand ? 'ctc-unit-type' : 'ctm-unit-type';
  return (
    <PriceColumn label={forPriceBand ? '' : options.unitType} dataTestId={testId}>
      <Select onChange={onChange} value={value} size='large' style={{ width }}>
        <Select.Option value={GraphQL.CostUnit.User}>{options.person}</Select.Option>
        <Select.Option value={GraphQL.CostUnit.Event}>{options.event}</Select.Option>
      </Select>
    </PriceColumn>
  );
};

export default UnitType;
