import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import * as GraphQL from '~/graphql';
import useMobile from '~/hooks/useMobile';
import usePrevious from '~/hooks/usePrevious';
import Loading from '~/Loading';
import Tabs from '~/Tabs';
import UsersTabsDetails from '~/Users/Tabs/Details';

interface Props {
  selectedRow?: { id: string };
}

const UsersDetails = ({ selectedRow, ...otherProps }: Props) => {
  const userId = _.get(selectedRow, 'id');

  const [selectedTab, setSelectedTab] = useState(UsersDetailsTab.Details);
  const isPhone = useMobile();

  const { data, loading } = useQuery<
    GraphQL.UsersDetails.Query,
    GraphQL.UsersDetails.Variables
  >(UsersDetails.query, {
    variables: { userId: userId as any },
    skip: !userId,
  });

  const lastUserId = usePrevious(userId);

  useEffect(() => {
    if (lastUserId !== userId) setSelectedTab(UsersDetailsTab.Details);
  }, [userId, lastUserId, selectedTab, setSelectedTab]);

  if (loading || !data || !data.user) return <Loading />;

  const user = data.user;

  return (
    <div
      css={{
        width: '100%',
        height: 'fit-content',
        boxSizing: 'border-box',
      }}
      {...otherProps}
    >
      <Tabs
        defaultTab={UsersDetailsTab.Details}
        tabs={[
          {
            label: UsersDetailsTab.Details,
            component: UsersTabsDetails,
            props: { data: user },
          },
        ]}
      />
    </div>
  );
};

enum UsersDetailsTab {
  Details = 'Details',
  Messages = 'Messages',
  Edit = 'Edit',
}

UsersDetails.fragment = gql`
  fragment UsersDetails on User {
    id
    name
    phone
    email
  }
`;

UsersDetails.query = gql`
  query UsersDetails($userId: ID!) {
    user(id: $userId) {
      ...UsersDetails
      ...UsersTabsDetails
    }
  }

  ${UsersDetails.fragment}
  ${UsersTabsDetails.fragment}
`;

export default UsersDetails;
