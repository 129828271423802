import React from 'react';
import _ from 'lodash';
import Link from '~/Link';
import SectionTitle from './SectionTitle';
import ScottSection from '~/ScottSection';
import ScottTitle from '~/ScottTitle';
import NumberInput from '~/Input/Number';
import PercentInput from '~/Input/Percent';
import { sizes } from '~/styles';

const strings = {
  minUnits: 'Minimum # of Units',
  percentDiscount: '% Discount',
  remove: 'Remove',
};

const Discounts = ({ title, discounts, setDiscounts, ...otherProps }) => {
  const removeDiscount = (id: number) => {
    delete discounts[id];
    setDiscounts({ ...discounts });
  };

  return Object.keys(discounts).length ? (
    <div {...otherProps}>
      <SectionTitle title={title} />
      <ScottSection>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            '> *:not(:last-child)': { marginBottom: sizes.Spacing.XSmall },
          }}
        >
          <DiscountRow>
            <ScottTitle css={{ width: 150 }} type='SubSection'>
              {strings.minUnits}
            </ScottTitle>
            <ScottTitle css={{ width: 150 }} type='SubSection'>
              {strings.percentDiscount}
            </ScottTitle>
            <div css={{ width: 'fill' }} />
          </DiscountRow>
          {Object.keys(discounts).map(key => (
            <Discount
              onRemove={removeDiscount}
              key={key}
              id={key}
              units={discounts[key].minNumUsers}
              percent={discounts[key].percentDiscount}
              discounts={discounts}
              setDiscounts={setDiscounts}
            />
          ))}
        </div>
      </ScottSection>
    </div>
  ) : null;
};

export const DiscountRow = ({ children, style = {} }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '> *:not(:last-child)': { marginRight: sizes.Spacing.Medium },
      ...style,
    }}
  >
    {children}
  </div>
);

const Discount = ({ id, units, percent, onRemove, discounts, setDiscounts }) => (
  <DiscountRow>
    <NumberInput
      data-testid={`min-num-of-units-input-${id}`}
      css={{ width: 150 }}
      value={units}
      onChange={e =>
        setDiscounts({
          ...discounts,
          [id]: {
            ...discounts[id],
            minNumUsers: e.target.value,
          },
        })
      }
    />
    <PercentInput
      data-testid={`percent-input-${id}`}
      css={{ width: 150 }}
      value={percent}
      onChange={val =>
        val <= 100 &&
        setDiscounts({
          ...discounts,
          [id]: {
            ...discounts[id],
            percentDiscount: _.round(val, 0).toFixed(0),
          },
        })
      }
    />
    <Link onClick={() => onRemove(id)} css={{ width: 'fill' }}>
      {strings.remove}
    </Link>
  </DiscountRow>
);

export default Discounts;
