import _ from 'lodash';
import React from 'react';

import { memo } from '~/react';
import MultiSelectFilter from './MultiSelect';

const FilterSupplyEntities = () => {
  const options = [
    { label: 'Partner', value: 'Partner' },
    { label: 'Experience', value: 'Xperience' },
    { label: 'Event', value: 'Event' },
  ];
  return (
    <MultiSelectFilter name={'entityTypes'} title={'Entities'} options={options} />
  );
};

export default memo(FilterSupplyEntities);
