import React from 'react';

interface Props {
  [key: string]: any;
}

const LogoMonogram = (props: Props) => (
  <svg width='1em' height='1em' viewBox='0 0 162 162' {...props}>
    <g
      transform='translate(4 4)'
      stroke='currentColor'
      strokeWidth={8}
      fill='none'
      fillRule='evenodd'
    >
      <path d='M45 110V45l32 32.039L109 45v65' />
      <circle cx={77} cy={77} r={77} />
    </g>
  </svg>
);

export default LogoMonogram;
