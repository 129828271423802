import React, { useMemo } from 'react';
import { colors, font } from '~/styles';

interface Props {
  data: any[];
  sortIndex?: (any) => string;
}

const TagsListCell = ({ data, sortIndex = input => input }: Props) => {
  if (!data) data = [];
  const sortedNames = useMemo(() => data.map(d => sortIndex(d)).sort(), [data]);
  const tagElements = sortedNames.map((name, index) => (
    <span
      key={index}
      css={{
        ...font.Size.Caption,
        display: 'inline-block',
        marginRight: '0.25em',
        padding: `0 0.25em`,
        backgroundColor: colors.GreyLighter,
        borderRadius: 3,
      }}
    >
      {name}
    </span>
  ));
  return <React.Fragment>{tagElements}</React.Fragment>;
};

export default TagsListCell;
