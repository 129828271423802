import { List, message, Button, Tag } from 'antd';
import * as GraphQL from '~/graphql';
import moment from 'moment';
import ScottTitle from '~/ScottTitle';
import { Spacing } from '~/styles/sizes';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const UpcomingBlackoutDates = (props: {
  blackoutDates: GraphQL.BlackoutDate[];
  refetch(): Promise<any>;
  showTypeTags?: boolean;
}) => {
  const [deleteBlackoutDate] = useMutation<
    GraphQL.DeleteBlackoutDate.Mutation,
    GraphQL.DeleteBlackoutDate.Variables
  >(UpcomingBlackoutDates.deleteMutation);

  return (
    <div css={{ padding: Spacing.Large }}>
      <ScottTitle>Upcoming Blackout Dates</ScottTitle>
      {/* the 57 (height from antd of each list item) * 6 (rows to be visible) + 2 to account for the borders */}
      <div css={{ height: `${57 * 6 + 2}px`, overflowY: 'auto' }}>
        <List
          bordered
          dataSource={props.blackoutDates}
          renderItem={(item: GraphQL.BlackoutDate) => (
            <List.Item
              actions={[
                <Button
                  icon='delete'
                  onClick={async () => {
                    await deleteBlackoutDate({ variables: { id: item.id } });
                    message.success(
                      `Deleted blackout date ${moment
                        .utc(item.blackedOutDate)
                        .format('MM/DD/YYYY')} for ${item.mysteryType}`,
                    );
                    await props.refetch();
                  }}
                />,
              ]}
            >
              <div
                css={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div css={{ display: 'flex' }}>
                  {props.showTypeTags && (
                    <div
                      css={{
                        width: '70px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Tag
                        color={
                          {
                            Classic: 'geekblue',
                            Meal: 'volcano',
                            Activity: 'purple',
                          }[item.mysteryType]
                        }
                      >
                        {item.mysteryType}
                      </Tag>
                    </div>
                  )}
                  {moment.utc(item.blackedOutDate).format('dddd, MMMM Do YYYY')}
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

UpcomingBlackoutDates.deleteMutation = gql`
  mutation DeleteBlackoutDate($id: ID!) {
    deleteBlackoutDate(id: $id) {
      __typename
    }
  }
`;

export default UpcomingBlackoutDates;
