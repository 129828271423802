import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Checkbox, Icon, message } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  ContractChargeHeaderRow,
  ExistingContractChargeRow,
  NewContractChargeRow,
} from '~/Costs/ContractCharge';
import ContractRow from '~/Costs/ContractRow';
import { ExistingCostRow, NewCostRow } from '~/Costs/CostRow';
import { InvoicedChargeHeaderRow, InvoicedChargeRow } from '~/Costs/InvoicedCharges';
import {
  HeaderRow,
  OFF_PLATFORM_PAYMENT_REGEX,
  STRIPE_CHARGE_ID_REGEX,
  StripeChargeRow,
} from '~/Costs/StripeCharge';
import * as GraphQL from '~/graphql';
import Row from '~/Row';
import ScottHr from '~/ScottHr';
import { borders, colors, font, sizes } from '~/styles';
import { White } from '~/styles/colors';
import { BorderRadius } from '~/styles/sizes';

const IDENTITY_FUNCTION = () => {};

const defaultCosts: GraphQL.EventCosts = {
  costToCustomerCents: 0,
  costToMysteryCents: 0,
  netGainCents: 0,
};

export type ContractCharge = {
  unitCount: number;
  premiumUnitCount: number;
  unitType: GraphQL.ContractUnitType;
  priceType: GraphQL.PriceType;
  contractId: string;
  teamEventId: string;
};
type TemporaryContractCharges = Record<number, ContractCharge>;
type TemporaryStripeIds = Record<number, string>;
type Cost = {
  amount: GraphQL.Amount;
  reason: GraphQL.PayoutReason;
  owner: GraphQL.PayoutOwner;
};
type TemporaryCosts = Record<number, Partial<Cost>>;

const formatCents = (cents: number) => {
  if (!cents) {
    return 0;
  }
  return (cents / 100).toFixed(2);
};

const createTempKey = (tempObject: Record<number, TemporaryStripeIds | TemporaryCosts>) =>
  _.isEmpty(tempObject) ? 0 : Math.max(...Object.keys(tempObject).map(Number)) + 1;

const createTempCost: (partnerId: string) => Cost = (partnerId: string) => ({
  amount: {
    unitType: GraphQL.UnitType.CurrencyCents,
    unitToken: GraphQL.UnitToken.Usd,
    unitCount: 0,
  },
  reason: {
    reasonType: GraphQL.PayoutReasonType.OpsAdjustment,
    reasonToken: GraphQL.OpsAdjustmentToken.Reschedule,
  },
  owner: {
    ownerType: GraphQL.PayoutOwnerType.Partner,
    ownerToken: partnerId,
  },
});

const createTempContractCharge = (contractId: string, teamEventId): ContractCharge => ({
  contractId,
  unitType: GraphQL.ContractUnitType.Credits,
  unitCount: 0,
  premiumUnitCount: 0,
  priceType: GraphQL.PriceType.ExtraCharge,
  teamEventId,
});

const isStripeChargeValid = (chargeId: string) => {
  return chargeId && chargeId.length > 0 && STRIPE_CHARGE_ID_REGEX.test(chargeId);
};

const areAllTempStripeChargesValid = (tempStripeIds: TemporaryStripeIds) => {
  return Object.values(tempStripeIds).every(isStripeChargeValid);
};

const payoutSorter = (
  left: GraphQL.CostToMysteryTab.Payouts,
  right: GraphQL.CostToMysteryTab.Payouts,
) => {
  if (left.systemGenerated && !right.systemGenerated) {
    return -1;
  }
  if (right.systemGenerated && !left.systemGenerated) {
    return 1;
  }
  const timeDiff = DateTime.fromISO(left.createdAt)
    .diff(DateTime.fromISO(right.createdAt))
    .toMillis();
  return timeDiff > 0 ? 1 : timeDiff < 0 ? -1 : 0;
};

const invoicedChargesFilter = price =>
  !price.stripeChargeId && price.status === GraphQL.PriceStatus.Unpaid;

type CostDisplayProps = {
  label: string;
  value: number;
  showGainLoss?: boolean;
};
const CostDisplay = ({ label, value, showGainLoss = false }: CostDisplayProps) => {
  const gainStyle = {
    borderTop: `3px solid ${colors.LightGreen}`,
    borderRadius: `${BorderRadius.Medium}px`,
  };
  const lossStyle = {
    borderTop: `3px solid ${colors.Negative}`,
    borderRadius: `${BorderRadius.Medium}px`,
  };
  const gainLossStyle = value > 0 ? gainStyle : lossStyle;
  const topStyle = showGainLoss ? gainLossStyle : {};
  return (
    <div
      css={{
        border: borders.darkDivider,
        borderRadius: `${BorderRadius.Medium}px`,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: `${sizes.Spacing.XSmall}px`,
        gap: '4px',
        ...topStyle,
      }}
    >
      <div
        css={{
          ...font.Size.Heading,
          color: colors.Grey,
        }}
      >
        {label}
      </div>
      <div
        css={{
          color: colors.Black,
          ...font.Size.Medium,
        }}
      >
        {value ? `$${(value / 100).toFixed(2)}` : '-'}
      </div>
    </div>
  );
};

type TitleRowProps = {
  children: ReactNode;
  className?: string;
};
const TitleRow = ({ children, className }: TitleRowProps) => {
  return (
    <div
      css={{
        ...font.Size.Heading,
        color: colors.Blue.Blue500,
      }}
      className={className}
    >
      {children}
    </div>
  );
};

type ColumnProps = {
  spacing?: number;
  children: ReactNode;
  className?: string;
};
const Column = ({ children, spacing, className }: ColumnProps) => {
  const spacingOverride = spacing === 0 || spacing ? spacing : sizes.Spacing.Medium;
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: `${spacingOverride}px`,
        width: '100%',
      }}
      className={className}
    >
      {children}
    </div>
  );
};

const BillingField = ({ label, children, name }) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: `${sizes.Spacing.XSmall}px`,
      }}
    >
      <label
        htmlFor={name}
        css={{
          ...font.Size.Body,
          fontWeight: font.FontWeight.Bold,
        }}
      >
        {label}
      </label>
      <span
        id={name}
        name={name}
        css={{
          ...font.Size.Small,
        }}
      >
        {children}
      </span>
    </div>
  );
};

type BillingAddButtonProps = {
  onClick?: () => void;
  children: ReactNode;
  disabled?: boolean;
};
const BillingAddButton = ({
  onClick = IDENTITY_FUNCTION,
  children,
  disabled = false,
}: BillingAddButtonProps) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      css={{
        width: '100%',
        backgroundColor: '#f7faff',
        color: colors.Blue.Blue500,
        padding: `${sizes.Spacing.Medium}px`,
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        display: 'flex',
        transition: 'all 0.3s ease-in',
        ':hover': {
          backgroundColor: colors.Blue.Blue500,
          color: colors.White,
        },
      }}
    >
      <Icon type='plus-circle' />
      <span css={{ textDecoration: 'underline' }}>{children}</span>
    </Button>
  );
};

const GroupTitleRow = ({ children }) => (
  <div
    css={{
      ...font.Size.GroupHeading,
      color: colors.Black,
    }}
  >
    {children}
  </div>
);

const EventCosts = ({ eventId, onClose }) => {
  const [temporaryContractCharges, setTemporaryContractCharges] = useState<
    TemporaryContractCharges
  >({});
  const [temporaryStripeIds, setTemporaryStripeIds] = useState<TemporaryStripeIds>({});
  const [temporaryCosts, setTemporaryCosts] = useState<TemporaryCosts>({});
  const [updatedData, setUpdatedData] = useState<GraphQL.CostToMysteryTab.TeamEvent>();
  const [saving, setSaving] = useState(false);
  const [pendingContractChargeDeletes, setPendingContractChargeDeletes] = useState<string[]>([]);
  const [pendingPayoutDeletes, setPendingPayoutDeletes] = useState<string[]>([]);
  const [pendingChargeDeletes, setPendingChargeDeletes] = useState<string[]>([]);
  const [pendingInvoiceDeletes, setPendingInvoiceDeletes] = useState<string[]>([]);
  const { data, loading } = useQuery(EventCosts.query, {
    variables: { id: eventId },
  });

  useEffect(() => {
    setUpdatedData(data ? data.teamEvent : undefined);
  }, [data, loading]);
  const [generatePrice] = useMutation<
    GraphQL.CostToMysteryPriceFromStripeCharge.Mutation,
    GraphQL.CostToMysteryPriceFromStripeCharge.Variables
  >(EventCosts.priceFromStripe, {
    refetchQueries: ['CostToMysteryTab'],
  });
  const [addContractCharge] = useMutation<
    GraphQL.CostToMysteryAddContractCharge.Mutation,
    GraphQL.CostToMysteryAddContractCharge.Variables
  >(EventCosts.addContractCharge, {
    refetchQueries: ['CostToMysteryTab'],
  });
  const [removeCharges] = useMutation<
    GraphQL.CostToMysteryDisassociatePrices.Mutation,
    GraphQL.CostToMysteryDisassociatePrices.Variables
  >(EventCosts.disassociatePrices, {
    refetchQueries: ['CostToMysteryTab'],
  });
  const [updateTeamEvent] = useMutation<
    GraphQL.CostToMysteryBillingMutation.Mutation,
    GraphQL.CostToMysteryBillingMutation.Variables
  >(EventCosts.billingMutation, {
    refetchQueries: ['CostToMysteryTab'],
  });
  const [addPayout] = useMutation<
    GraphQL.CostToMysteryAddAdjustment.Mutation,
    GraphQL.CostToMysteryAddAdjustment.Variables
  >(EventCosts.addAdjustment, {
    refetchQueries: ['CostToMysteryTab'],
  });
  const [removePayouts] = useMutation<
    GraphQL.CostToMysteryRemoveAdjustment.Mutation,
    GraphQL.CostToMysteryRemoveAdjustment.Variables
  >(EventCosts.deleteAdjustments, {
    refetchQueries: ['CostToMysteryTab'],
  });
  const [abandonPrices] = useMutation<
    GraphQL.CostToMysteryAbandonPrices.Mutation,
    GraphQL.CostToMysteryAbandonPrices.Variables
  >(EventCosts.abandonPrices, {
    refetchQueries: ['CostToMysteryTab'],
  });

  if (loading) {
    return null;
  }
  const partnerId = _.get(updatedData, 'experience.partner.id');
  const contractId = _.get(updatedData, 'contract.id');
  const teamEventId = _.get(updatedData, 'id');
  const removeTempStripeId = (key: number) => () =>
    setTemporaryStripeIds(_.omit(temporaryStripeIds, key));

  const addTempStripeId = () =>
    setTemporaryStripeIds({ ...temporaryStripeIds, [createTempKey(temporaryStripeIds)]: '' });

  const updateTempStripeId = (key: number) => (chargeId: string) => {
    setTemporaryStripeIds({ ...temporaryStripeIds, [key]: chargeId });
  };
  const removeTempCost = (key: number) => () => setTemporaryCosts(_.omit(temporaryCosts, key));
  const addTempCost = () =>
    setTemporaryCosts({
      ...temporaryCosts,
      [createTempKey(temporaryCosts)]: createTempCost(partnerId),
    });
  const updateTempCost = (key: number) => (payout: Cost) => {
    setTemporaryCosts({
      ...temporaryCosts,
      [key]: {
        ...temporaryCosts[key],
        ...payout,
      },
    });
  };
  const removeTempContractCharge = (key: number) => () =>
    setTemporaryContractCharges(_.omit(temporaryContractCharges, key));
  const addTempContractCharge = () => {
    setTemporaryContractCharges({
      ...temporaryContractCharges,
      [createTempKey(temporaryContractCharges)]: createTempContractCharge(contractId, teamEventId),
    });
  };
  const updateTempContractCharge = (key: number) => (
    unitCount: number,
    premiumUnitCount: number,
  ) => {
    setTemporaryContractCharges({
      ...temporaryContractCharges,
      [key]: {
        ...temporaryContractCharges[key],
        unitCount,
        premiumUnitCount,
      },
    });
  };
  const markContractChargeForDeletion = (id: string) => {
    setPendingContractChargeDeletes([...pendingContractChargeDeletes, id]);
  };
  const unmarkContractChargeForDeletion = (id: string) => {
    setPendingContractChargeDeletes(
      pendingContractChargeDeletes.filter(chargeId => chargeId !== id),
    );
  };
  const markPayoutForDeletion = (id: string) => {
    setPendingPayoutDeletes([...pendingPayoutDeletes, id]);
  };
  const unmarkPayoutForDeletion = (id: string) => {
    setPendingPayoutDeletes(pendingPayoutDeletes.filter(payoutId => payoutId !== id));
  };
  const markChargeForDeletion = (id: string) => {
    setPendingChargeDeletes([...pendingChargeDeletes, id]);
  };
  const unmarkChargeForDeletion = (id: string) => {
    setPendingChargeDeletes(pendingChargeDeletes.filter(chargeId => chargeId !== id));
  };
  const markInvoicedChargeForAbandon = (id: string) => {
    setPendingInvoiceDeletes([...pendingInvoiceDeletes, id]);
  };
  const unmarkPendingInvoicedChargeForAbandon = (id: string) => {
    setPendingInvoiceDeletes(pendingInvoiceDeletes.filter(chargeId => chargeId !== id));
  };

  const saveNewCharges = async (tempStripeIds: TemporaryStripeIds) => {
    let errorMessages: string[] = [];
    try {
      const validTempIds = Object.values(tempStripeIds).filter(isStripeChargeValid);

      if (validTempIds.length > 0) {
        const pricePromises = validTempIds.map(stripeChargeId => {
          return generatePrice({
            variables: {
              teamEventId: eventId,
              stripeChargeId: stripeChargeId,
              priceType: GraphQL.PriceType.ExtraCharge,
            },
          });
        });
        await Promise.all(pricePromises);

        setTemporaryStripeIds({});
      }
      if (!areAllTempStripeChargesValid(tempStripeIds)) {
        errorMessages = errorMessages.concat('Failed to save Stripe Charge');
      }
    } catch (e) {
      console.error(e);
      errorMessages = errorMessages.concat('Failed to save Stripe Charge');
    }
    return errorMessages;
  };

  const saveNewContractCharges = async (tempContractCharges: TemporaryContractCharges) => {
    let errorMessages: string[] = [];
    try {
      if (Object.keys(tempContractCharges).length > 0) {
        await addContractCharge({
          variables: {
            contractChargeInputs: Object.values(tempContractCharges).map(charge => {
              const { priceType, unitType, ...cleanedUpCharge } = charge;
              return cleanedUpCharge;
            }),
          },
        });

        setTemporaryContractCharges({});
      }
    } catch (e) {
      console.error(e);
      errorMessages = errorMessages.concat('Failed to save contract charges');
    }
    return errorMessages;
  };

  const disassociateCharges = async (chargesToRemove: string[]) => {
    let errorMessages: string[] = [];
    const pricesToRemove = prices.filter(price =>
      chargesToRemove.find(chargeId => price.stripeChargeId === chargeId),
    );
    const priceDisassocations = pricesToRemove.map(price => ({
      priceId: price.id,
      entityId: updatedData.id,
      entityType: GraphQL.PriceEntity.TeamEvent,
    }));
    try {
      await removeCharges({
        variables: {
          priceInputs: priceDisassocations,
        },
      });
      setPendingChargeDeletes([]);
    } catch (error) {
      console.error(error);
      errorMessages = errorMessages.concat('Failed to remove prices.');
    }
    return errorMessages;
  };

  const disassociateContractCharges = async (chargesToRemove: string[]) => {
    let errorMessages: string[] = [];
    const pricesToRemove = prices.filter(price =>
      chargesToRemove.find(chargeId => price.id === chargeId),
    );
    const priceDisassocations = pricesToRemove.map(price => ({
      priceId: price.id,
      entityId: updatedData.id,
      entityType: GraphQL.PriceEntity.TeamEvent,
    }));
    try {
      await removeCharges({
        variables: {
          priceInputs: priceDisassocations,
        },
      });
      setPendingContractChargeDeletes([]);
    } catch (error) {
      console.error(error);
      errorMessages = errorMessages.concat('Failed to remove contract charges.');
    }
    return errorMessages;
  };

  const saveNewAdjustments = async (tempCosts: TemporaryCosts) => {
    let errorMessages: string[] = [];
    try {
      const validTempCosts = Object.values(tempCosts).filter(cost => cost.amount.unitCount !== 0);

      if (validTempCosts.length > 0) {
        const partnerId: string | undefined = _.get(updatedData, 'experience.partner.id');
        if (!partnerId) {
          errorMessages = errorMessages.concat('Could not find partner for this event');
        } else {
          const addPayoutPromises = validTempCosts.map(cost => {
            return addPayout({
              variables: {
                teamEventId: eventId,
                ownerType: cost.owner.ownerType || GraphQL.PayoutOwnerType.Partner,
                ownerToken: cost.owner.ownerToken || partnerId,
                reasonToken: cost.reason.reasonToken,
                ...cost.amount,
              },
            });
          });
          await Promise.all(addPayoutPromises);

          setTemporaryCosts({});
        }
      }
      if (validTempCosts.length !== Object.keys(tempCosts).length) {
        errorMessages = errorMessages.concat('Failed to save extra cost');
      }
    } catch (e) {
      console.error(e);
      errorMessages = errorMessages.concat('Failed to save extra cost');
    }
    return errorMessages;
  };

  const deleteAdjustments = async (payoutIdsToDelete: string[]) => {
    let errorMessages: string[] = [];
    try {
      await removePayouts({
        variables: {
          payoutIds: payoutIdsToDelete,
        },
      });
      setPendingPayoutDeletes([]);
    } catch (e) {
      console.error(e);
      errorMessages = errorMessages.concat('Failed to delete extra costs');
    }
    return errorMessages;
  };

  const abandonCharges = async (priceIdsToAbandon: string[]) => {
    let errorMessages: string[] = [];
    try {
      await abandonPrices({
        variables: {
          priceIds: priceIdsToAbandon,
        },
      });
    } catch (e) {
      console.error(e);
      errorMessages = errorMessages.concat('Failed to abandon charges');
    }
    return errorMessages;
  };

  const saveBillingInfo = async (isFreeEvent: boolean, isManualBilling: boolean) => {
    let errorMessages: string[] = [];
    try {
      await updateTeamEvent({
        variables: {
          id: eventId,
          freeEvent: isFreeEvent,
          manualBilling: isManualBilling,
        },
      });
    } catch (e) {
      console.error(e);
      errorMessages = errorMessages.concat('Failed to update team event');
    }
    return errorMessages;
  };

  const onSubmit = async () => {
    setSaving(true);
    let errorMessages: string[] = [];
    if (Object.keys(temporaryStripeIds).length > 0) {
      const chargeSaveErrors = await saveNewCharges(temporaryStripeIds);
      errorMessages = errorMessages.concat(chargeSaveErrors);
    }
    if (Object.keys(temporaryContractCharges).length > 0) {
      const contractChargeSaveErrors = await saveNewContractCharges(temporaryContractCharges);
      errorMessages = errorMessages.concat(contractChargeSaveErrors);
    }
    if (pendingChargeDeletes.length > 0) {
      const chargeDeleteErrors = await disassociateCharges(pendingChargeDeletes);
      errorMessages = errorMessages.concat(chargeDeleteErrors);
    }
    if (pendingContractChargeDeletes.length > 0) {
      const contractChargeDeleteErrors = await disassociateContractCharges(
        pendingContractChargeDeletes,
      );
      errorMessages = errorMessages.concat(contractChargeDeleteErrors);
    }
    if (Object.keys(temporaryCosts).length > 0) {
      const adjustmentSaveErrors = await saveNewAdjustments(temporaryCosts);
      errorMessages = errorMessages.concat(adjustmentSaveErrors);
    }
    if (pendingPayoutDeletes.length > 0) {
      const adjustmentDeleteErrors = await deleteAdjustments(pendingPayoutDeletes);
      errorMessages = errorMessages.concat(adjustmentDeleteErrors);
    }
    if (pendingInvoiceDeletes.length > 0) {
      const invoiceAbandonErrors = await abandonCharges(pendingInvoiceDeletes);
      errorMessages = errorMessages.concat(invoiceAbandonErrors);
    }
    const original = _.get(data, 'teamEvent');
    if (original.freeEvent !== freeEvent || original.manualBilling !== manualBilling) {
      const saveBillingErrors = await saveBillingInfo(freeEvent, manualBilling);
      errorMessages = errorMessages.concat(saveBillingErrors);
    }
    if (errorMessages.length > 0) {
      errorMessages.forEach(error => message.error(error));
    } else {
      message.success('Event updated');
    }
    setSaving(false);
  };

  const {
    freeEvent,
    status,
    prices = [],
    payouts = [],
    costs = defaultCosts,
    manualBilling,
    contract,
  } = updatedData || {};
  const activeContractUnitType =
    (contract && contract.type) || GraphQL.ContractUnitType.Credits;
  const sortedPayouts = [...payouts].sort(payoutSorter);
  const filteredPayouts =
    status === GraphQL.TeamEventStatus.Canceled || status === GraphQL.TeamEventStatus.Expired
      ? sortedPayouts.filter(payout => !payout.estimate)
      : sortedPayouts;
  const initialPrices = prices.filter(price => price.priceType === GraphQL.PriceType.Initial);
  const initialPricesCents = initialPrices
    .map(price => price.totalCents || 0)
    .reduce((sum, cents) => sum + cents, 0);
  const finalPrices = prices.filter(price => price.priceType === GraphQL.PriceType.Finalized);
  const finalPricesCents = finalPrices
    .map(price => price.totalCents || 0)
    .reduce((sum, cents) => sum + cents, 0);
  const formattedPrices = {
    initial: formatCents(initialPricesCents),
    final: formatCents(finalPricesCents),
  };
  const hasFinalPrice = formattedPrices.final;
  const hasIntialPrice = formattedPrices.initial;
  const hasPrice = hasFinalPrice || hasIntialPrice;
  const initialLineItems = initialPrices.flatMap(price => price.lineItems || []);
  const initialUnitsUsed = _.sumBy(initialLineItems, 'contractUnits');
  const initialUpgradesUsed = _.sumBy(initialLineItems, 'contractPremiumUnits');

  const finalLineItems = finalPrices.flatMap(price => price.lineItems || []);
  const finalUnitsUsed = _.sumBy(finalLineItems, 'contractUnits');
  const finalUpgradesUsed = _.sumBy(finalLineItems, 'contractPremiumUnits');

  const pricesWithValidStripeChargeIds = prices
    .filter(price => price.stripeChargeId)
    .filter(
      price =>
        STRIPE_CHARGE_ID_REGEX.test(price.stripeChargeId) ||
        OFF_PLATFORM_PAYMENT_REGEX.test(price.stripeChargeId),
    );
  const invoicedPrices = prices.filter(invoicedChargesFilter);
  const hasInvoicedPrices = invoicedPrices.length > 0;

  const pricesWithContractUnits = prices.filter(price =>
    price.lineItems.some(li => li.contractUnits || li.contractPremiumUnits),
  );

  const totalTemporaryCosts = _.sumBy(Object.values(temporaryCosts), tempCost =>
    tempCost.amount ? tempCost.amount.unitCount : 0,
  );
  const projectedTotalCostToMystery = costs.costToMysteryCents + totalTemporaryCosts;

  const isDirty =
    JSON.stringify(updatedData) !== JSON.stringify(_.get(data, 'teamEvent')) ||
    Object.keys(temporaryStripeIds).length > 0 ||
    Object.keys(temporaryCosts).length > 0 ||
    Object.keys(temporaryContractCharges).length > 0 ||
    pendingPayoutDeletes.length > 0 ||
    pendingChargeDeletes.length > 0 ||
    pendingContractChargeDeletes.length > 0 ||
    pendingInvoiceDeletes.length > 0;

  const hasErrors = !areAllTempStripeChargesValid(temporaryStripeIds);
  return (
    <div
      css={{
        padding: sizes.Spacing.Large,
        dipslay: 'flex',
        flexDirection: 'column',
        gap: sizes.Spacing.Medium,
        overflowY: 'scroll',
      }}
    >
      {/* Re-enable once the online system has access to contract costs */}
      {/* <Column>
        <GroupTitleRow>Event costs</GroupTitleRow>
        <TitleRow>Revenue summary (experimental)</TitleRow>
        <Row
          css={{
            alignItems: 'flex-start',
            padding: sizes.Spacing.None,
            gap: sizes.Spacing.Medium,
            width: '100%',
          }}
        >
          <CostDisplay label='Cost to customer' value={costs.costToCustomerCents} />
          <CostDisplay label='Cost to Mystery' value={costs.costToMysteryCents} />
          <CostDisplay label='Net gain' value={costs.netGainCents} showGainLoss />
        </Row>
      </Column> */}
      <Column>
        <GroupTitleRow>Cost to customer</GroupTitleRow>
        <TitleRow>Billing details</TitleRow>
        <Row
          css={{
            gap: sizes.Spacing.Medium,
            width: '100%',
          }}
        >
          <BillingField label='Self Serve Payment Collected' name='payment_collected'>
            {hasFinalPrice ? `$${formattedPrices.final}` : null}
            {hasIntialPrice && !hasFinalPrice ? `$${formattedPrices.initial}` : null}
            {!hasPrice && `-`}
          </BillingField>
          <BillingField label='Contract Units Used' name='units_used'>
            {finalUnitsUsed + initialUnitsUsed || '-'}
          </BillingField>
          <BillingField label='Contract Upgrades Used' name='premium_units_used'>
            {finalUpgradesUsed + initialUpgradesUsed || '-'}
          </BillingField>
        </Row>
      </Column>
      <Column>
        <TitleRow>Options</TitleRow>
        <Row css={{ alignItems: 'center', width: '100%' }}>
          <Checkbox
            css={{ flex: 1, ...font.Size.BodyLarge, color: colors.Black }}
            onChange={() =>
              setUpdatedData({
                ...updatedData,
                freeEvent: !freeEvent,
              })
            }
            checked={freeEvent}
          >
            Free Event
          </Checkbox>
          <Checkbox
            css={{ flex: 1, ...font.Size.BodyLarge, color: colors.Black }}
            onChange={() =>
              setUpdatedData({
                ...updatedData,
                manualBilling: !manualBilling,
              })
            }
            checked={manualBilling}
          >
            Manual Billing
          </Checkbox>
        </Row>
      </Column>
      {invoicedPrices.length > 0 && (
        <React.Fragment>
          <Column css={{ gap: 0 }}>
            <TitleRow css={{ marginBottom: '20px' }}>Invoiced Charges</TitleRow>
            <InvoicedChargeHeaderRow />
          </Column>
          <Column css={{ gap: 0 }}>
            {invoicedPrices.map(price => (
              <InvoicedChargeRow
                key={price.id}
                lineItems={price.lineItems}
                price={price}
                onRemove={markInvoicedChargeForAbandon}
                onReAdd={unmarkPendingInvoicedChargeForAbandon}
                pendingDelete={pendingInvoiceDeletes.some(chargeId => chargeId === price.id)}
              />
            ))}
            {pendingInvoiceDeletes.length > 0 && (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  padding: `${sizes.Spacing.Small}px`,
                  backgroundColor: colors.GreyLighter,
                  color: colors.Red500,
                }}
              >
                We still stop attempting to collect these charges.
              </div>
            )}
          </Column>
        </React.Fragment>
      )}
      <Column css={{ gap: 0, marginTop: hasInvoicedPrices ? '20px' : 0 }}>
        <TitleRow css={{ marginBottom: '20px' }}>Contract Charges</TitleRow>
        {contract ? <ContractRow contract={contract} /> : <div>No contract</div>}
        {pricesWithContractUnits.length > 0 && <ContractChargeHeaderRow />}
      </Column>
      <Column css={{ gap: 0 }}>
        {pricesWithContractUnits.map(price => (
          <ExistingContractChargeRow
            price={price}
            lineItems={price.lineItems}
            key={price.id}
            onRemove={markContractChargeForDeletion}
            onReAdd={unmarkContractChargeForDeletion}
            pendingDelete={pendingContractChargeDeletes.some(chargeId => chargeId === price.id)}
          />
        ))}
        {Object.entries(temporaryContractCharges).map(entry => {
          const [keyString, contractCharge] = entry;
          const key = Number(keyString);
          return (
            <NewContractChargeRow
              charge={contractCharge}
              key={key}
              contractUnitType={activeContractUnitType}
              onChange={updateTempContractCharge(key)}
              onRemove={removeTempContractCharge(key)}
            />
          );
        })}
        {Object.keys(temporaryContractCharges).length > 0 && (
          <div
            css={{
              color: colors.White,
              backgroundColor: colors.Warning,
              textAlign: 'center',
              display: 'flex',
              width: '100%',
              padding: '4px',
              alignItems: 'center',
            }}
          >
            <Icon type='warning' />
            <div css={{ justifySelf: 'center', flex: 1 }}>
              Record only - this will not add to or remove from the contract
            </div>
          </div>
        )}
        {contract && (
          <BillingAddButton onClick={addTempContractCharge}>
            Create new contract charge
          </BillingAddButton>
        )}
      </Column>
      <Column spacing={sizes.Spacing.None}>
        <TitleRow css={{ marginBottom: sizes.Spacing.Medium, marginTop: sizes.Spacing.Medium }}>
          Stripe IDs
        </TitleRow>
        <HeaderRow>
          <div>Stripe ID</div>
          <div>Charge Type</div>
          <div>Status</div>
          <div>Value</div>
        </HeaderRow>
        {pricesWithValidStripeChargeIds
          .filter(price => price.stripeChargeId)
          .map(price => (
            <StripeChargeRow
              key={price.stripeChargeId}
              price={price}
              autoGenerated={price.systemGenerated}
              onRemove={markChargeForDeletion}
              onReAdd={unmarkChargeForDeletion}
              pendingDelete={pendingChargeDeletes.some(
                chargeId => chargeId === price.stripeChargeId,
              )}
            />
          ))}

        {Object.entries(temporaryStripeIds).map(entry => {
          let [keyString, value] = entry;
          const key = Number(keyString);
          return (
            <StripeChargeRow
              stripeId={value}
              key={key}
              onChange={updateTempStripeId(key)}
              onRemove={removeTempStripeId(key)}
            />
          );
        })}
        <BillingAddButton onClick={addTempStripeId}>Add additional Stripe ID</BillingAddButton>
      </Column>
      <Column>
        <GroupTitleRow>Cost to Mystery</GroupTitleRow>
        <HeaderRow css={{ justifyContent: 'space-between' }}>
          <span css={{ gridColumn: '1 / span 2' }}>Item</span>
          <span>Payee</span>
          <span css={{ textAlign: 'end' }}>Cost</span>
        </HeaderRow>
      </Column>
      <Column css={{ gap: 0 }}>
        {filteredPayouts.map(payout => (
          <ExistingCostRow
            payout={payout}
            key={payout.id}
            onRemove={markPayoutForDeletion}
            onReAdd={unmarkPayoutForDeletion}
            pendingDelete={pendingPayoutDeletes.some(payoutId => payoutId == payout.id)}
          />
        ))}
        {Object.entries(temporaryCosts).map(entry => {
          let [keyString, cost] = entry;
          const key = Number(keyString);

          return (
            <NewCostRow
              amount={cost.amount}
              reason={cost.reason}
              owner={cost.owner}
              partnerId={partnerId}
              key={key}
              onRemove={removeTempCost(key)}
              onPayoutChange={updateTempCost(key)}
            />
          );
        })}
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            ...font.Size.Body,
            fontWeight: font.FontWeight.Bold,
            width: '100%',
            marginTop: sizes.Spacing.Small,
          }}
        >
          <span>${(projectedTotalCostToMystery / 100).toFixed(2)}</span>
        </div>
        <BillingAddButton onClick={addTempCost}>Create new added cost</BillingAddButton>
      </Column>
      <div
        css={{
          position: 'sticky',
          bottom: '-30px',
          backgroundColor: 'white',
          width: '100%',
        }}
      >
        <ScottHr marginTop={0} marginBottom={0} />
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            padding: sizes.Spacing.Medium,
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            type='default'
            onClick={() => {
              onClose();
              setUpdatedData(undefined);
            }}
            css={{
              marginRight: sizes.Spacing.Small,
              color: colors.Blue.Blue500,
              border: borders.divider,
              borderColor: colors.Blue.Blue500,
              borderRadius: `${BorderRadius.Medium}px`,
            }}
          >
            Close
          </Button>
          <Button
            css={{
              borderRadius: `${BorderRadius.Medium}px`,
              color: White,
              backgroundColor: colors.Blue.Blue500,
            }}
            loading={saving}
            type='primary'
            disabled={!isDirty || hasErrors || !data || !updatedData}
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

EventCosts.billingMutation = gql`
  mutation CostToMysteryBillingMutation($id: ID!, $freeEvent: Boolean, $manualBilling: Boolean) {
    updateTeamEvent(id: $id, freeEvent: $freeEvent, manualBilling: $manualBilling) {
      id
      freeEvent
      manualBilling
    }
  }
`;

EventCosts.addAdjustment = gql`
  mutation CostToMysteryAddAdjustment(
    $teamEventId: ID!
    $ownerType: PayoutOwnerType!
    $ownerToken: String!
    $reasonToken: String!
    $unitType: UnitType!
    $unitToken: String!
    $unitCount: Int!
  ) {
    createOpsAdjustmentForTeamEvent(
      teamEventId: $teamEventId
      adjustment: {
        owner: { ownerType: $ownerType, ownerToken: $ownerToken }
        reasonToken: $reasonToken
        amount: { unitType: $unitType, unitToken: $unitToken, unitCount: $unitCount }
      }
    ) {
      id
    }
  }
`;

EventCosts.deleteAdjustments = gql`
  mutation CostToMysteryRemoveAdjustment($payoutIds: [ID!]!) {
    deleteOpsAdjustmentsForTeamEvent(payoutIds: $payoutIds) {
      id
    }
  }
`;

EventCosts.addContractCharge = gql`
  mutation CostToMysteryAddContractCharge($contractChargeInputs: [CreateContractChargeInput!]!) {
    generateContractChargesForTeamEvent(contractChargeInputs: $contractChargeInputs) {
      id
    }
  }
`;

EventCosts.disassociatePrices = gql`
  mutation CostToMysteryDisassociatePrices($priceInputs: [DisassociatePricesInput!]!) {
    disassociatePrices(priceInputs: $priceInputs) {
      id
    }
  }
`;

EventCosts.abandonPrices = gql`
  mutation CostToMysteryAbandonPrices($priceIds: [ID!]!) {
    abandonPrices(priceIds: $priceIds) {
      id
    }
  }
`;

EventCosts.priceFromStripe = gql`
  mutation CostToMysteryPriceFromStripeCharge($teamEventId: ID!, $stripeChargeId: String) {
    generatePriceFromStripeCharge(teamEventId: $teamEventId, stripeChargeId: $stripeChargeId) {
      id
    }
  }
`;

EventCosts.query = gql`
  query CostToMysteryTab($id: ID!) {
    teamEvent(id: $id) {
      id
      finalHeadCount
      expectedHeadCount
      manualBilling
      freeEvent
      status
      experience {
        id
        partner {
          id
        }
      }
      costs {
        costToCustomerCents
        costToMysteryCents
        netGainCents
      }
      prices {
        id
        paidAt
        totalCents
        stripeChargeId
        priceType
        systemGenerated
        status
        createdAt
        lineItems {
          id
          category
          contractUnits
          contractPremiumUnits
          contract {
            unitCostCents
            premiumUnitCostCents
            type
          }
        }
      }
      payouts {
        id
        systemGenerated
        estimate
        createdAt
        amount {
          unitType
          unitToken
          unitCount
        }
        createdBy {
          id
        }
        reason {
          reasonType
          reasonToken
        }
        owner {
          ownerType
          ownerToken
        }
      }
      contract {
        id
        name
        status
        type
        premiumUnitCount
        unitCount
      }
      organization {
        id
        activeContract {
          id
          type
          premiumUnitCount
          unitCount
        }
      }
    }
  }
`;

export default EventCosts;
