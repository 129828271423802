export const isValidUrl = (url?: string | null) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const isImageUrl = (url?: string | null) => {
  if (!isValidUrl(url)) {
    return false;
  }

  const validTypes = new Set([
    'apng',
    'bmp',
    'cur',
    'gif',
    'ico',
    'jfif',
    'jpeg',
    'jpg',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'tif',
    'tiff',
    'webp',
  ]);

  const fileExtension = url.split('.').pop();
  return validTypes.has(fileExtension);
};

/* eslint-disable no-control-regex */
const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

export const validateEmail = (email: string): boolean => {
  return emailRegex.test(email);
};
