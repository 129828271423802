import React from 'react';
import AddNewTemplateButton from './AddNewTemplateButton';

const TemplatesFilterBar = () => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'stretch',
      }}
    >
      <AddNewTemplateButton />
    </div>
  );
};

export default TemplatesFilterBar;
