import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, message, Modal, Select } from 'antd';
import gql from 'graphql-tag';
import React, { useCallback, useEffect, useState } from 'react';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';
import useMobile from '~/hooks/useMobile';
import LabeledField from '~/Labeled/Field';
import ScottHr from '~/ScottHr';
import SearchUsers from '~/Search/Users';
import { colors, font, sizes } from '~/styles';
import { ModalProps } from './Edit';
import * as GraphQL from '~/graphql';
import _ from 'lodash';

type Member = {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  organizationRole: GraphQL.OrganizationRole;
  isServiceAccount: boolean;
};

type MemberMap = {
  [id: string]: Member;
};

interface HandlerArgs {
  member: Member;
  includeInMap: MemberMap;
  setIncludeInMap: (members: MemberMap) => void;
  removeFromMap: MemberMap;
  setRemoveFromMap: (members: MemberMap) => void;
}

const handleAddOrRemove = ({
  member,
  includeInMap,
  setIncludeInMap,
  removeFromMap,
  setRemoveFromMap,
}: HandlerArgs) => {
  const newIncludeInMap = { ...includeInMap };
  newIncludeInMap[member.id] = member;
  setIncludeInMap(newIncludeInMap);
  const newRemoveFromMap = { ...removeFromMap };
  delete newRemoveFromMap[member.id];
  setRemoveFromMap(newRemoveFromMap);
};

const MemberModal = ({
  visible,
  onCancel,
  orgId,
  currentMembers,
}: ModalProps & { currentMembers: Member[] }) => {
  const isPhone = useMobile();
  const currentMembersById = _.keyBy(currentMembers, 'id');
  const [selected, setSelected] = useState<Member>(null);
  const [members, setMembers] = useState<MemberMap>(currentMembersById);
  const [addedMembers, setAddedMembers] = useState<MemberMap>({});
  const [removedMembers, setRemovedMembers] = useState<MemberMap>({});
  const [roles, setRoles] = useState<GraphQL.OrganizationRole[]>(null);
  const [renderList, setRenderList] = useState<Member[]>([]);

  const { data, loading: rolesLoading } = useQuery(MemberModal.getRoles);

  const [updateMembers, { loading: updateMembersLoading }] = useMutation<
    GraphQL.UpdateMembers.Mutation,
    GraphQL.UpdateMembers.Variables
  >(MemberModal.update, { refetchQueries: ['OrganizationGridQuery', 'OrganizationEditor'] });

  useEffect(() => {
    if (!data) return;
    setRoles(data.roles);
  }, [data]);

  useEffect(() => {
    // Allows rendering removed members
    const withRemovedUsers = _.orderBy(
      {
        ...currentMembersById,
        ...members,
      },
      ['lastName', 'firstName'],
      ['asc', 'asc'],
    );
    setRenderList(withRemovedUsers);
  }, [members]);

  useEffect(() => {
    setMembers(currentMembersById);
  }, [currentMembers]);

  const defaultRole = _.find(roles, role => role.name === GraphQL.OrganizationRoleName.Member);
  const originalMembersList = _.orderBy(
    currentMembersById,
    ['lastName', 'firstName'],
    ['asc', 'asc'],
  );
  const membersList = _.orderBy(members, ['lastName', 'firstName'], ['asc', 'asc']);
  const isDirty = JSON.stringify(originalMembersList) !== JSON.stringify(membersList);

  const addToMembersList = (member: Member) => {
    const newMembers = { ...members };
    newMembers[member.id] = selected;
    setMembers(newMembers);
  };

  const removeFromMembersList = (member: Member) => {
    const newMembers = { ...members };
    delete newMembers[member.id];
    setMembers(newMembers);
  };

  const handleAdd = (member: Member) => {
    addToMembersList(member);
    handleAddOrRemove({
      member,
      includeInMap: addedMembers,
      setIncludeInMap: setAddedMembers,
      removeFromMap: removedMembers,
      setRemoveFromMap: setRemovedMembers,
    });
  };

  const handleRemove = (member: Member) => {
    removeFromMembersList(member);
    handleAddOrRemove({
      member,
      includeInMap: removedMembers,
      setIncludeInMap: setRemovedMembers,
      removeFromMap: addedMembers,
      setRemoveFromMap: setAddedMembers,
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title='Add, edit, or remove members'
      destroyOnClose={true}
      width={isPhone ? '100%' : 800}
      footer={
        <Button
          type='primary'
          loading={updateMembersLoading || rolesLoading}
          disabled={!isDirty}
          onClick={async () => {
            try {
              await updateMembers({
                variables: {
                  orgId,
                  addedMembers: _.map(Object.values(addedMembers), member => _.pick(member, 'id')),
                  removedMembers: _.map(Object.values(removedMembers), member =>
                    _.pick(member, 'id'),
                  ),
                  userRoleInputs: _.map(Object.values(members), (member: Member) => ({
                    userId: member.id,
                    organizationRoleId: _.get(member, 'organizationRole.id') || defaultRole.id,
                  })),
                },
              });

              message.success('Members Updated!');
              onCancel();
            } catch (err) {
              message.error(err.message);
            }
          }}
        >
          Save
        </Button>
      }
    >
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <p css={{ ...font.Size.Body }}>Add or remove members using their name or email address.</p>
        <LabeledField label='Search Users'>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div css={{ width: '100%' }}>
              <SearchUsers
                onChange={user => {
                  if (user.orgs.filter(i => i).length > 0) {
                    message.error(
                      'Users can only be added to one org. User already exists in org: ' +
                        user.orgs[0].name,
                    );
                    return;
                  }
                  setSelected(user);
                }}
              />
            </div>
            <Button
              css={{ marginLeft: sizes.Spacing.Medium }}
              size='large'
              disabled={!selected}
              type='primary'
              onClick={() => {
                selected.organizationRole = defaultRole;
                handleAdd(selected);
                setSelected(null);
              }}
            >
              Add
            </Button>
          </div>
        </LabeledField>
        {!!renderList.length && (
          <>
            <ScottHr marginTop={sizes.Spacing.Small} />
            {renderList.map(member => {
              const wasRemoved = currentMembersById[member.id] && !members[member.id];
              return (
                <div
                  key={member.id}
                  css={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <p
                    css={{
                      transitionProperty: 'opacity, text-decoration',
                      transitionDuration: '500ms',
                      transitionTimingFunction: 'cubic-bezier(.5,-0.3,.5,1.3)',
                      opacity: wasRemoved ? 0.5 : 1,
                      textDecoration: wasRemoved ? 'line-through' : undefined,
                      flex: 2,
                    }}
                  >
                    {`${member.firstName} ${member.lastName}`}
                    {member.isServiceAccount && (
                      <span
                        css={{
                          fontSize: '10px',
                          fontWeight: font.FontWeight.Bold,
                          color: colors.AntdBlue,
                          marginLeft: 5,
                        }}
                      >
                        Service Account
                      </span>
                    )}
                  </p>
                  <p
                    css={{
                      transitionProperty: 'opacity, text-decoration',
                      transitionDuration: '500ms',
                      transitionTimingFunction: 'cubic-bezier(.5,-0.3,.5,1.3)',
                      opacity: wasRemoved ? 0.5 : 1,
                      textDecoration: wasRemoved ? 'line-through' : undefined,
                      flex: 2,
                    }}
                  >{`${member.email || member.phone || '-'}`}</p>
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'bottom',
                      flex: 2,
                    }}
                  >
                    <LabeledField label='Role'>
                      <Select
                        size='large'
                        data-testid='member-roles-selector'
                        onChange={async roleName => {
                          const role = roles.find(role => role.name === roleName);

                          const newMembers = { ...members };
                          if (newMembers.hasOwnProperty(member.id)) {
                            const existing = newMembers[member.id];
                            newMembers[member.id] = {
                              ...existing,
                              organizationRole: {
                                id: role.id,
                                name: GraphQL.OrganizationRoleName[role.name],
                              },
                            };
                            setMembers(newMembers);
                          }
                        }}
                        value={_.get(member.organizationRole, 'name', 'Member')}
                        css={{ width: sizes.GRID_UNIT * 30 }}
                      >
                        {_.map(GraphQL.OrganizationRoleName, roleName => (
                          <Select.Option
                            key={roleName}
                            data-testid={`member-roles-option-${roleName}`}
                            value={roleName}
                            title={roleName}
                          >
                            {roleName}
                          </Select.Option>
                        ))}
                      </Select>
                    </LabeledField>
                    <div
                      css={{
                        cursor: 'pointer',
                        color: wasRemoved ? colors.AntdBlue : colors.Black,
                        transition: 'color 500ms cubic-bezier(.5,-0.3,.5,1.3)',
                        flex: 1,
                        marginTop: '20px',
                        marginLeft: '14px',
                      }}
                      onClick={() => {
                        wasRemoved ? handleAdd(member) : handleRemove(member);
                      }}
                    >
                      {wasRemoved ? <FiPlusCircle size={20} /> : <FiXCircle size={20} />}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </Modal>
  );
};

MemberModal.update = gql`
  mutation UpdateMembers(
    $orgId: ID!
    $addedMembers: [ReferenceUserInput!]
    $removedMembers: [ReferenceUserInput!]
    $userRoleInputs: [UserOrganizationRole!]!
  ) {
    addOrganizationMembers(id: $orgId, addMembers: $addedMembers) {
      id
    }
    removeOrganizationMembers(id: $orgId, removeMembers: $removedMembers) {
      id
    }
    updateUsersOrganizationRoles(userRoleInputs: $userRoleInputs) {
      id
    }
  }
`;

MemberModal.getRoles = gql`
  query Roles {
    roles {
      id
      name
    }
  }
`;

export default MemberModal;
