import gql from 'graphql-tag';

import { makeQueryBasedSelect } from './QueryBasedSelect';

const SelectInterests = makeQueryBasedSelect(
  gql`
    query SelectInterests {
      interests {
        id
        name
      }
    }
  `,
  'interests',
);

export default SelectInterests;
