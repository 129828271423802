import * as GraphQL from '~/graphql';
import { TeamEventIssueCategory } from '~/graphql';

export const TEAM_EVENT_ISSUE_NAME_MAP: Record<GraphQL.TeamEventIssueCategory, string> = {
  [TeamEventIssueCategory.SupplyPartner]: 'Supply Partner',
  [TeamEventIssueCategory.Deliverables]: 'Deliverables',
  [TeamEventIssueCategory.LiveOps]: 'Live Ops',
  [TeamEventIssueCategory.OpsTeam]: 'Ops Team',
  [TeamEventIssueCategory.SupplyTeam]: 'Supply Team',
  [TeamEventIssueCategory.SalesTeam]: 'Sales Team',
  [TeamEventIssueCategory.PdeTech]: 'Pde/Tech',
  [TeamEventIssueCategory.MysteryMatched]: 'Mystery-matched',
  [TeamEventIssueCategory.Billing]: 'Billing',
  [TeamEventIssueCategory.Other]: 'Other',
};
