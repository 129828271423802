import { useVirtualizer } from '@tanstack/react-virtual';
import { useEffect } from 'react';

import { DataTableColumn, GraphQLFiltersInput } from './interface';
import { DataTableQueryResult, DataTableQueryStatus } from './querying';
import DataTableRow from './Row';

/**
 * Renders the actual content of the table, and is responsible for virtualizing
 * rows, and loading additional rows as the user scrolls.
 */
function DataTableBody<TRow, TSortKeys extends string, TFilters extends GraphQLFiltersInput>({
  columns,
  result: { status, count, rows, loadPage },
  scrollRef,
  rowHeight,
  rowsOfOverscan,
}: {
  columns: DataTableColumn<TRow, TSortKeys, TFilters>[];
  result: DataTableQueryResult<TRow, TSortKeys, TFilters>;
  scrollRef: React.RefObject<HTMLDivElement>;
  rowHeight: number;
  rowsOfOverscan: number;
}) {
  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => rowHeight,
    overscan: rowsOfOverscan,
    enableSmoothScroll: false,
  });

  // Virtual items are the list of all DOM elements that the virtualizer wants
  // us to generate (this includes all rows currently visible, as well as
  // overscanned rows above and below the scroll viewport).
  const virtualItems = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();
  const lastIndex = virtualItems.length ? virtualItems[virtualItems.length - 1].index : undefined;

  useEffect(() => {
    // If we have any unloaded rows, that means it's time to fetch more!
    if (status === DataTableQueryStatus.IDLE && !rows[lastIndex]) {
      loadPage();
    }
    // Note that we want to trigger if /either/ the last index changes, or if the
    // query has become idle (and there is still no row)
  }, [lastIndex, status]);

  return (
    <tbody>
      <tr style={{ height: virtualItems.length ? virtualItems[0].start : 0 }} />
      {virtualItems.map(virtualItem => (
        <DataTableRow
          key={virtualItem.index}
          columns={columns}
          virtualItem={virtualItem}
          data={rows[virtualItem.index]}
        />
      ))}
      <tr
        style={{
          height: virtualItems.length ? totalSize - virtualItems[virtualItems.length - 1].end : 0,
        }}
      />
    </tbody>
  );
}

export default DataTableBody;
