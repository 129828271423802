import { Interpolation } from '@emotion/core';

import * as borders from './borders';
import colors from './colors';
import * as font from './font';
import * as scrollbar from './scrollbar';
import * as shadow from './shadow';
import * as sizes from './sizes';

export { colors, shadow, scrollbar, sizes, font, borders };

/**
 * Shorthand for a valid style definition.
 */

export type CSS = Interpolation;

/**
 * Nestable mapping of style names to style definitions.
 */

export type NamedStyles<TStyles> = { [TName in keyof TStyles]: CSS };

/**
 * Coerce the given `styles` to a typed, nestable mapping of style names to
 * style definitions.
 */

export const createStyles = <TStyles>(
  styles: NamedStyles<TStyles>,
): NamedStyles<TStyles> => styles;
