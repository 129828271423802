import React from 'react';
import FullHeightContainer from 'react-full-height-container';

import ConversationSingleUser from '~/Conversation/SingleUser';
import { fullNameOrPhone } from '~/formatters';
import * as GraphQL from '~/graphql';
import Header from '~/Header';
import usePusher from '~/hooks/usePusher';
import handleNewMessage from '~/Messages/handleNewMessage';
import MessagesList from '~/Messages/List';
import { memo } from '~/react';
import SplitView from '~/SplitView';

const strings = {
  messages: 'Messages',
};

const Messages = () => {
  const pusherSubscriber = usePusher();

  pusherSubscriber.bind(GraphQL.EventType.NewMessage, handleNewMessage);

  return (
    <FullHeightContainer>
      <Header title={strings.messages} />
      <SplitView
        sessionStorageKey='messages'
        ListComponent={MessagesList}
        DetailsComponent={ConversationSingleUser}
        getMobileDetailsTitle={row => fullNameOrPhone(row)}
      />
    </FullHeightContainer>
  );
};

export default memo(Messages);
