import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useApolloClient } from 'react-apollo';
import ChatView from 'react-chatview';
import FullHeightContainer from 'react-full-height-container';

import * as GraphQL from '~/graphql';
import useScrollBottom from '~/hooks/useScrollBottom';
import { refreshUnreadMessageStatus } from '~/services/messages';
import { sizes } from '~/styles';
import ConversationMessage from './Message';
import ConversationMessageInput from './Message/Input';

interface Props {
  toPhone: string[];
  rows: GraphQL.ConversationMessage.Fragment[];
  mysteryId?: string;
  hasNextPage?: boolean;
  paginate?: () => Promise<void>;
}

const Conversation = ({
  toPhone,
  rows,
  mysteryId,
  hasNextPage,
  paginate,
  ...otherProps
}: Props) => {
  const containerRef = useRef(null);

  const client = useApolloClient();

  useScrollBottom(containerRef, [rows]);

  useEffect(() => {
    (async () => {
      if (!mysteryId) return;
      await client.mutate({
        mutation: Conversation.markReadMutation,
        variables: { mysteryId },
      });
      await refreshUnreadMessageStatus(mysteryId);
    })();
  }, []);

  return (
    <FullHeightContainer>
      <div
        css={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          flex: 'initial',
        }}
      >
        <div
          css={{
            width: '100%',
            height: '100%',
            display: 'flex',
            overflow: 'auto',
            position: 'relative',
            flexDirection: 'column',
            padding: sizes.Spacing.Medium,
          }}
          {...otherProps}
          ref={containerRef}
        >
          <ChatView
            flipped
            reversed
            scrollLoadThreshold={300}
            onInfiniteLoad={paginate}
            shouldTriggerLoad={() => hasNextPage}
          >
            {_.map(_.orderBy(rows, 'createdAt'), (row, i) => (
              <ConversationMessage key={i} data={row} />
            ))}
          </ChatView>
        </div>
        <ConversationMessageInput toPhone={toPhone!} mysteryId={mysteryId} />
      </div>
    </FullHeightContainer>
  );
};

Conversation.markReadMutation = gql`
  mutation MarkConversationRead($mysteryId: ID!) {
    markMysteryMessagesAsRead(mysteryId: $mysteryId) {
      adventure(id: $mysteryId) {
        id
        hasUnreadMessages
      }
    }
  }
`;

Conversation.userFragment = gql`
  fragment ConversationUser on User {
    id
    firstName
    lastName
    phone
  }
`;

Conversation.messageFragment = gql`
  fragment Conversation on Message {
    ...ConversationMessage
  }

  ${ConversationMessage.fragment}
`;

export default Conversation;
