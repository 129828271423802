import { InputNumber as AntInputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number/index';
import _ from 'lodash';
import React from 'react';

import { memo } from '~/lib/react';
import { Omit } from '~/types';

type Props = {
  value?: string | number;
  onChange: (event: any, value: number) => void;
  size?: 'small' | 'default' | 'large';
  name?: string;
  defaultValue?: number;
} & Omit<InputNumberProps, 'value' | 'onChange' | 'size' | 'name'>;

const InputNumber = ({
  onChange,
  value,
  size = 'large',
  name,
  defaultValue,
  ...otherProps
}: Props) => {
  value = value ? ~~value : undefined;
  const antOnChange = (value: number) =>
    onChange({ target: { name, value, validity: { valid: true } } }, value);
  return (
    <AntInputNumber
      defaultValue={defaultValue}
      onChange={antOnChange}
      value={value}
      size={size}
      name={name}
      {...otherProps}
    />
  );
};

export default memo(InputNumber);
