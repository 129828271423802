import gql from 'graphql-tag';

import Row from '~/Row';
import * as GraphQL from '~/graphql';
import { Edits } from '~/hooks/useEditableEntity';
import { sizes } from '~/styles';

import ExperienceProfileAvailabilityDetails from './Availability';
import ExperienceProfileContactDetails from './Contact';
import ExperienceProfileCoreDetails from './Core';
import ExperienceProfileInstructionsDetails from './Instructions';
import ExperienceProfilePhysicalDetails from './Physical';
import ExperienceProfilePreferenceDetails from './Preferences';
import ExperienceProfileSummaryDetails from './Summary';

export interface Props {
  experience: GraphQL.ExperienceProfileDetails.Fragment;
  onChange: (edits: Edits<GraphQL.ExperienceProfileDetails.Fragment>) => void;
}

const ExperienceProfileDetails = (props: Props) => {
  return (
    <Row marginRight={sizes.Spacing.XXXLarge}>
      <div css={{ flex: 1 }}>
        <ExperienceProfileCoreDetails {...props} />
        <ExperienceProfileContactDetails {...props} />
        <ExperienceProfilePreferenceDetails {...props} />
        {!props.experience.isVirtual && <ExperienceProfilePhysicalDetails {...props} />}
      </div>
      <div css={{ flex: 1 }}>
        <ExperienceProfileSummaryDetails {...props} />
        <ExperienceProfileAvailabilityDetails {...props} />
        <ExperienceProfileInstructionsDetails {...props} />
      </div>
    </Row>
  );
};

ExperienceProfileDetails.fragment = gql`
  fragment ExperienceProfileDetails on Xperience {
    ...ExperienceProfileAvailabilityDetails
    ...ExperienceProfileContactDetails
    ...ExperienceProfileCoreDetails
    ...ExperienceProfileInstructionsDetails
    ...ExperienceProfilePhysicalDetails
    ...ExperienceProfilePreferenceDetails
    ...ExperienceProfileSummaryDetails
  }
  ${ExperienceProfileAvailabilityDetails.fragment}
  ${ExperienceProfileContactDetails.fragment}
  ${ExperienceProfileCoreDetails.fragment}
  ${ExperienceProfileInstructionsDetails.fragment}
  ${ExperienceProfilePhysicalDetails.fragment}
  ${ExperienceProfilePreferenceDetails.fragment}
  ${ExperienceProfileSummaryDetails.fragment}
`;

export default ExperienceProfileDetails;
