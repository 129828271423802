import React from 'react';
import { Input, Tag } from 'antd';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import TextArea from '~/Input/TextArea';
import _ from 'lodash';

const MysteriesGoingToThisEventSection = (props: {
  mysteries: { id: string; shortId: string; party: { id: string }[] }[];
}) => {
  if (_.size(_.compact(props.mysteries)) === 0) return null;
  return (
    <LabeledField label='Mysteries'>
      <div css={{ display: 'flex' }}>
        {_.map(props.mysteries, mystery => (
          <Tag color='purple' key={mystery.id}>
            {mystery.shortId} ({_.size(mystery.party)})
          </Tag>
        ))}
      </div>
    </LabeledField>
  );
};

export default MysteriesGoingToThisEventSection;
