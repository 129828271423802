import gql from 'graphql-tag';

import * as GraphQL from '~/graphql';
import { Edits } from '~/hooks/useEditableEntity';
import SelectAccessibilityTypes from '~/Input/SelectAccessibilityTypes';
import SelectDietaryRestrictions from '~/Input/SelectDietaryRestrictions';
import SelectInterests from '~/Input/SelectInterests';
import Row from '~/Row';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import { GenericReferenceInput } from '~/graphql';

import Heading from '../../Heading';

export interface Props {
  experience: GraphQL.ExperienceProfilePreferenceDetails.Fragment;
  onChange: (edits: Edits<GraphQL.ExperienceProfilePreferenceDetails.Fragment>) => void;
}

const ExperienceProfilePreferenceDetails = ({ experience, onChange }: Props) => {
  return (
    <div>
      <Heading>Preferences</Heading>
      <Row>
        <LabeledField label='Guest Interests Match'>
          <SelectInterests
            value={experience.relatedInterests}
            onChange={(relatedInterests: GenericReferenceInput[]) => onChange({ relatedInterests })}
          />
        </LabeledField>
      </Row>
      <Heading>Dietary Restrictions</Heading>
      <Row>
        <LabeledField label='Friendly Towards'>
          <SelectDietaryRestrictions
            value={experience.friendlyTowardsDietaryRestrictions}
            onChange={(friendlyTowardsDietaryRestrictions: GenericReferenceInput[]) =>
              onChange({ friendlyTowardsDietaryRestrictions })
            }
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Not Friendly Towards'>
          <SelectDietaryRestrictions
            value={experience.notFriendlyTowardsDietaryRestrictions}
            onChange={(notFriendlyTowardsDietaryRestrictions: GenericReferenceInput[]) =>
              onChange({ notFriendlyTowardsDietaryRestrictions })
            }
          />
        </LabeledField>
      </Row>
      <Heading>Accessibility Types</Heading>
      <Row>
        <LabeledField label='Friendly Towards'>
          <SelectAccessibilityTypes
            value={experience.friendlyTowardsAccessibilityTypes}
            onChange={(friendlyTowardsAccessibilityTypes: GenericReferenceInput[]) =>
              onChange({ friendlyTowardsAccessibilityTypes })
            }
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Not Friendly Towards'>
          <SelectAccessibilityTypes
            value={experience.notFriendlyTowardsAccessibilityTypes}
            onChange={(notFriendlyTowardsAccessibilityTypes: GenericReferenceInput[]) =>
              onChange({ notFriendlyTowardsAccessibilityTypes })
            }
          />
        </LabeledField>
      </Row>
    </div>
  );
};

ExperienceProfilePreferenceDetails.fragment = gql`
  fragment ExperienceProfilePreferenceDetails on Xperience {
    id
    relatedInterests {
      id
      name
    }
    friendlyTowardsDietaryRestrictions {
      id
      name
    }
    notFriendlyTowardsDietaryRestrictions {
      id
      name
    }
    friendlyTowardsAccessibilityTypes {
      id
      name
    }
    notFriendlyTowardsAccessibilityTypes {
      id
      name
    }
  }
`;

export default ExperienceProfilePreferenceDetails;
