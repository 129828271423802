import React from 'react';
import LabeledSection from '~/LabeledSection';
import { Timeline, Spin } from 'antd';
import AuditLogCell from '~/AuditLogCell';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Text from '~/Text';
import _ from 'lodash';
import FullScreenSpinner from '~/FullScreenSpinner';

const ChangesSummary = ({ ...otherProps }) => {
  const { data, loading } = useQuery(ChangesSummary.query);

  return (
    <LabeledSection
      {...otherProps}
      label={`RECENT CHANGES`}
      pageLinkPath='/supply/activity'
      pageLinkText='View All'
      data={{ data, loading }}
      viewParts={[
        ({ data, loading }) => {
          if (loading)
            return (
              <div css={{ position: 'relative', width: '100%', height: 400 }}>
                <FullScreenSpinner />
              </div>
            );
          if (!data.auditLogsConnection) return <Text color='Negative'>an error has occurred</Text>;
          return (
            <Timeline css={{ '> *:last-child': { paddingBottom: 0 } }}>
              {_.map(data.auditLogsConnection.edges, 'node').map((auditLog, i) => (
                <Timeline.Item key={`audit-log-${i}`} color='gray'>
                  <AuditLogCell
                    key={auditLog.id}
                    shouldLabelEntity={auditLog.entityType !== 'Partner'}
                    {...auditLog}
                  />
                </Timeline.Item>
              ))}
            </Timeline>
          );
        },
      ]}
    />
  );
};

export default ChangesSummary;

ChangesSummary.query = gql`
  query ChangesSummary {
    auditLogsConnection(
      first: 7
      eventNames: [QueryDataChanged, EntityApproval]
      entityTypes: ["Xperience", "Partner", "Event"]
    ) @connection(key: "auditLogsConnection", filter: ["eventNames"]) {
      edges {
        node {
          id
          ...AuditLogCell
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${AuditLogCell.fragment}
`;
