import { Modal } from 'antd';
import _ from 'lodash';
import React from 'react';
import * as GraphQL from '~/graphql';

import ContractAddEdit, { EditorContract } from './ContractAddEdit';
import { ModalProps } from './Edit';

export const isNewContract = (contract): boolean => contract.id.substring(0, 3) === 'new';

export const newContract = (): EditorContract => ({
  id: _.uniqueId('new'),
  name: 'New Contract',
  unitCostCents: null,
  type: GraphQL.ContractUnitType.Recurring,
  unitCount: null,
  unitInitialCount: null,
  premiumUnitCostCents: null,
  premiumUnitCount: null,
  premiumUnitInitialCount: null,
  productType: GraphQL.MysteryProduct.TeamEvent,
  startDate: null,
  renewDate: null,
  status: GraphQL.ContractStatus.Active,
  createdAt: null,
  updatedAt: null,
  org: null,
  authorizedUsers: null,
});

const ContractModal = ({
  visible,
  onCancel,
  orgId,
  orgName,
  contract,
}: ModalProps & {
  orgName: string;
  contract?: EditorContract;
}) => {
  const editMode = contract && !isNewContract(contract);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={editMode ? `Edit plan for ${orgName}` : `Create plan for ${orgName}`}
      width={800}
      footer={null}
      destroyOnClose={true}
    >
      <ContractAddEdit orgId={orgId} contract={contract} closeModal={onCancel} />
    </Modal>
  );
};

export default ContractModal;
