import { Input as AntInput } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import cleanProps from 'clean-react-props';
import React from 'react';

import { font } from '~/styles';

const Input = React.forwardRef(
  (
    {
      size = 'large',
      defaultValue,
      allowClear,
      autoCapitalize,
      autoComplete = 'no',
      autoCorrect,
      autoFocus,
      ...otherProps
    }: InputProps,
    ref,
  ) => (
    <AntInput
      ref={ref}
      size={size}
      defaultValue={defaultValue}
      allowClear={allowClear}
      autoCapitalize={autoCapitalize}
      autoComplete={autoComplete}
      autoCorrect={autoCorrect}
      autoFocus={autoFocus}
      {...cleanProps(otherProps)}
      css={{
        borderRadius: 4,
        fontFamily: font.FONT_FAMILY,
        input: { cursor: 'pointer', ':focus': { cursor: 'text' } },
        fontSize: size === 'large' ? `16px !important` : `14 !important`,
      }}
    />
  ),
);

export default Input;
