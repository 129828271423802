import React, { useRef } from 'react';
import { useClickOutside } from 'use-events';

import { colors } from '~/styles';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  children: React.ReactNode;
  onInputChange: any;
}

const Popover = ({ isOpen, onClose, onInputChange, children }: Props) => {
  const popoverRef = useRef(null as any);

  useClickOutside(popoverRef, onClose);

  return (
    <div css={{ position: 'relative', width: 150 }} ref={popoverRef}>
      <div
        css={[
          {
            position: 'absolute',
            bottom: 20,
            right: 100,
            width: 330,
            maxHeight: 260,
            height: 260,
            boxShadow: '0px 7px 40px 2px rgba(148, 149, 150, 0.3)',
            background: colors.White,
            borderRadius: 10,
            outline: 'none',
            transition: '0.2s ease-in-out',
            zIndex: 10,
            padding: '0px 5px 5px 5px',
            boxSizing: 'border-box',
            ':after': {
              right: 28,
              width: 14,
              zIndex: -1,
              bottom: -6,
              height: 14,
              content: '""',
              borderRadius: 2,
              position: 'absolute',
              background: colors.White,
              transform: 'rotate(45deg)',
            },
          },
          !isOpen && {
            opacity: 0,
            bottom: 14,
            visibility: 'hidden',
          },
        ]}
      >
        <input
          onChange={onInputChange}
          css={{
            width: 290,
            boxSizing: 'border-box',
            margin: 'auto',
            display: 'block',
            borderWidth: '0px 0px 1px 0px',
            color: '#565867',
            paddingLeft: 25,
            height: 40,
            fontSize: 14,
            backgroundSize: '16px 16px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '0 12px',
            backgroundImage: `url(https://js.intercomcdn.com/images/search@2x.32fca88e.png)`,
            outline: 'none',
            '::placeholder': { color: '#C1C7CD' },
          }}
          placeholder='Search emoji...'
        />
        {children}
      </div>
    </div>
  );
};

export default Popover;
