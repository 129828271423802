import _ from 'lodash';
import React from 'react';
import {
  OperatingHours as IOperatingHours,
  OperatingHoursInput as IOperatingHoursInput,
} from '~/graphql';
import OperatingHoursViewer from './Viewer';
import OperatingHoursEditor from './Editor';
import moment from 'moment-timezone';

export const weekdayOrder = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const strWeekdayIndexToName = {
  '0': 'Sun',
  '1': 'Mon',
  '2': 'Tue',
  '3': 'Wed',
  '4': 'Thu',
  '5': 'Fri',
  '6': 'Sat',
};

export const weekdayNameToIndex = _.invert(strWeekdayIndexToName);

type OpenAndClose = { open: moment.Moment; close: moment.Moment } | undefined;

type OperatingHoursLayer = {
  Sun: OpenAndClose;
  Mon: OpenAndClose;
  Tues: OpenAndClose;
  Wed: OpenAndClose;
  Thu: OpenAndClose;
  Fri: OpenAndClose;
  Sat: OpenAndClose;
};

type Props = {
  isEditMode: boolean;
  operatingHours: IOperatingHoursInput[];
  setOperatingHours?: (newOperatingHours: IOperatingHoursInput[]) => void;
};

const OperatingHours = (props: Props) => {
  if (props.isEditMode && !props.setOperatingHours)
    throw new Error('if edit mode is true, there must be a setOperatingHours prop');

  return props.isEditMode ? (
    <OperatingHoursEditor
      operatingHours={convertDbOperatingHoursToLayers(props.operatingHours)}
      setOperatingHours={newLayers =>
        props.setOperatingHours(convertLayersToDbOperatingHours(newLayers))
      }
    />
  ) : (
    <OperatingHoursViewer
      operatingHours={groupAndSortDbOperatingHours(props.operatingHours)}
    />
  );
};

export default OperatingHours;

export const groupAndSortDbOperatingHours = (
  operatingHours: IOperatingHoursInput[],
): { [key: string]: IOperatingHoursInput[] } =>
  _.mapValues(_.groupBy(operatingHours, 'open.day'), hours =>
    _.sortBy(hours, 'open.time'),
  );

export const convertDbOperatingHoursToLayers = (
  operatingHours: IOperatingHoursInput[],
): OperatingHoursLayer[] => {
  const operatingHoursByDay = _.mapValues(
    groupAndSortDbOperatingHours(operatingHours),
    operatingHoursArr =>
      _.map(operatingHoursArr, hours => ({
        open: moment(hours.open.time, 'HHmm'),
        close: moment(hours.close.time, 'HHmm'),
      })),
  );

  const numOfLayers = Math.max(
    _.size(_.maxBy(_.values(operatingHoursByDay), hours => _.size(hours))),
    1,
  );

  return _.times(numOfLayers, i => {
    return weekdayOrder.reduce(
      (all, day) => ({
        ...all,
        [day]: (operatingHoursByDay[weekdayNameToIndex[day]] || [])[i],
      }),
      {} as OperatingHoursLayer,
    );
  });
};

const convertLayersToDbOperatingHours = (
  layers: OperatingHoursLayer[],
): IOperatingHoursInput[] => {
  const hours: IOperatingHoursInput[] = [];
  _.forEach(layers, layer => {
    _.forIn(layer, (openAndClose, dayOfWeek) => {
      if (openAndClose && openAndClose.open && openAndClose.close) {
        const { open, close } = openAndClose;
        const openDay = Number(weekdayNameToIndex[dayOfWeek]);
        const closeDay =
          open.format('a') === 'pm' && close.format('a') === 'am'
            ? (openDay + 1) % 7
            : openDay;
        hours.push({
          open: {
            day: openDay,
            time: open.format('HHmm'),
            hours: Number(open.hours()),
            minutes: Number(open.minutes()),
          },
          close: {
            day: closeDay,
            time: close.format('HHmm'),
            hours: Number(close.hours()),
            minutes: Number(close.minutes()),
          },
        });
      }
    });
  });
  return hours;
};
