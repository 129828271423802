import _ from 'lodash';
import { CostUnit, PricingBand } from '~/graphql';

export function getRainboxBoxes(n) {
  return _.times(n, i => (
    <div
      css={{
        width: 100,
        height: 100,
        backgroundColor: ['#550527', '#688E26', '#FAA613', '#F44708', '#A10702'][
          i % 5
        ],
        color: 'white',
        fontSize: 24,
        lineHeight: '100%',
      }}
    >
      {i}
    </div>
  ));
}

export function getPriceForBand(band?: PricingBand): number | null {
  if (!band) return null;
  const { unit, minUsers, cents } = band;
  const multipler = (unit === CostUnit.User ? minUsers : 1);
  return cents * multipler;
}
