import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import useInterval from '~/hooks/useInterval';

interface Props {
  children?: React.ReactNode;
}

const FillRemaining = React.forwardRef<{}, Props>(
  ({ children, ...otherProps }: Props, forwardRef: any) => {
    const getWindowHeight = () => ('window' in global ? window.innerHeight : 0);
    const getWindowWidth = () => ('window' in global ? window.innerWidth : 0);

    const [windowHeight, setWindowHeight] = useState(getWindowHeight());
    const [windowWidth, setWindowWidth] = useState(getWindowHeight());
    const [height, setHeight] = useState<number | undefined>(undefined as any);
    const [topOffset, setTopOffset] = useState(null as any);

    const containerRef = forwardRef || useRef<HTMLDivElement>(null as any);

    const onResize = () => setWindowHeight(getWindowHeight());

    const getContainerRectTop = () => {
      return _.get(_.invoke(containerRef.current, 'getBoundingClientRect'), 'top');
    };

    const getTopOffset = () => {
      const newTopOffset = getContainerRectTop();
      if (newTopOffset !== topOffset) setTopOffset(newTopOffset);
      return newTopOffset;
    };

    useInterval(() => {
      const newWindowWidth = getWindowWidth();
      if (windowWidth != newWindowWidth) setWindowWidth(newWindowWidth);
    }, 500);

    useInterval(getTopOffset, 500);

    // Run once on mount, set up event listeners for window resize events.
    useEffect(() => {
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }, []);

    useEffect(() => {
      const newHeight =
        windowHeight - (_.isNil(topOffset) ? getTopOffset() : topOffset);
      if (height != newHeight) setHeight(newHeight);
    }, [windowHeight, containerRef.current, topOffset]);

    return (
      <div
        ref={containerRef}
        {...otherProps}
        css={{ width: '100%', height, overflowY: 'hidden', overflowX: 'hidden' }}
      >
        {children}
      </div>
    );
  },
);

export default FillRemaining;
