import React from 'react';
import Modal from '~/Modal';
import { titleCase, capitalize } from 'humanize-plus';
import { useFormState } from 'react-use-form-state';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import Input from '~/Input';
import TextArea from '~/Input/TextArea';
import Row from '~/Row';

import { Value, Props } from '.';

const strings = {
  modalTitle: 'Edit Title and Description',
  modalTitleNoDescription: 'Edit Title',
  title: 'Title',
  description: 'Description',
};

const TitleAndDescriptionEditor = (
  props: Props & {
    isOpen: boolean;
    close(): void;
  } & { hideUserFacingLabel?: boolean },
) => {
  const { title, description } = props.value;
  const [state, { text }] = useFormState<Value>({
    title,
    description,
  });
  return (
    <Modal
      title={props.noDescription ? strings.modalTitleNoDescription : strings.modalTitle}
      visible={props.isOpen}
      onOk={() => {
        const { title, description } = state.values;
        const updatedValues = {
          title: titleCase(title),
          description: description ? capitalize(description) : description,
        };
        props.onChange(updatedValues);
        props.close();
        state.setField('title', updatedValues.title);
        state.setField('description', updatedValues.description);
      }}
      onCancel={props.close}
      showUserFacingDataTag={!props.hideUserFacingLabel}
    >
      <div>
        <Row>
          <LabeledField label={strings.title}>
            <Input data-testid='editable-title-input' {...text('title')} required />
          </LabeledField>
        </Row>
        {!props.noDescription && (
          <Row>
            <LabeledField label={strings.description}>
              <TextArea
                data-testid='editable-description-input'
                autoSize
                maxLength={800}
                {...text('description')}
                size='large'
              />
            </LabeledField>
          </Row>
        )}
      </div>
    </Modal>
  );
};

export default TitleAndDescriptionEditor;
