import React, { useState, useEffect } from 'react';
import OmniSearch from '~/Search/Omni';
import { Modal, Icon, message, Button, Popconfirm } from 'antd';
import gql from 'graphql-tag';
import ScheduleItemCell from '~/ScheduleItemCell';
import { useQuery, useMutation } from '@apollo/react-hooks';
import * as GraphQL from '~/graphql';
import FullScreenSpinner from '~/FullScreenSpinner';
import { sizes, colors } from '~/styles';
import Row from '~/Row';
import ScottTitle from '~/ScottTitle';
import Link from '~/Link';
import Text from '~/Text';
import EventDetails from './EventDetails';
import moment from 'moment-timezone';
import _ from 'lodash';
import { mergeDateWithTime } from '~/date';

type Page =
  | 'SearchExperiences'
  | 'SelectSchedule'
  | 'ScheduledEventDetails'
  | 'CustomEventDetails';

type Mode = 'Edit' | 'Create';

const strings = {
  searchPlaceholder: 'Search experiences',
  modalTitles: {
    addNewEvent: 'Add New Event',
    addNewEventTo: (experienceName: string) => `Adding Event to ${experienceName}`,
    editEventFor: (experienceName: string) => `Edit Event - ${experienceName}`,
    loading: 'Loading...',
  },
  selectRepeatingAvail: 'Select repeating availability',
  addCustomEvent: 'ADD CUSTOM EVENT',
  messages: {
    successfullyCreated: (experienceName?: string) =>
      `Event successfully created ${experienceName ? `for ${experienceName}` : ''}`,
    successfullyUpdated: (experienceName?: string) =>
      `Event successfully updated ${experienceName ? `for ${experienceName}` : ''}`,
  },
  needToGoToPartnerPage: {
    text: 'Need to add a new partner or experience? ',
    link: 'Go to partners page',
  },
  ok: 'OK',
  cancel: 'Cancel',
  deleteEvent: 'Delete Event',
  deleteEventPopConfirmProps: {
    title: 'Are you sure you want to delete this event',
    okText: 'Yes',
    cancelText: 'No',
  },
};

const EventDialog = (props: {
  isOpen: boolean;
  close(): void;
  experienceId?: string; // if wanting to bypass the search for experience
  eventId?: string; // if editing an event
  refetchQueries?: string[];
}) => {
  const { isOpen, eventId } = props;

  const mode: Mode = eventId ? 'Edit' : 'Create';

  const [eventDetailsData, setEventDetailsData] = useState(undefined);

  const [modalTitle, setModalTitle] = useState('');
  const [page, setPage] = useState('SearchExperiences');
  const [experienceId, setExperienceId] = useState(props.experienceId);
  const [schedule, setSchedule] = useState(undefined);

  const close = () => {
    props.close();
    setSchedule(undefined);
    setExperienceId(props.experienceId);
    setEventDetailsData(undefined);
  };

  const { loading: loadingExperience, data: experienceData } = useQuery<
    GraphQL.EventDialogExperienceData.Query,
    GraphQL.EventDialogExperienceData.Variables
  >(EventDialog.experienceQuery, {
    variables: { experienceId },
    skip: !experienceId || !isOpen,
    fetchPolicy: 'network-only',
  });

  const { loading: loadingEvent, data: eventData } = useQuery<
    GraphQL.EventDialogEventData.Query,
    GraphQL.EventDialogEventData.Variables
  >(EventDialog.eventQuery, {
    variables: { eventId },
    skip: !eventId || !isOpen,
  });

  const [createEvent, { loading: createEventLoading }] = useMutation<
    GraphQL.EventDialogCreateEvent.Mutation,
    GraphQL.EventDialogCreateEvent.Variables
  >(EventDialog.createMutation, { refetchQueries: props.refetchQueries });

  const [updateEvent, { loading: updateEventLoading }] = useMutation<
    GraphQL.EventDialogUpdateEvent.Mutation,
    GraphQL.EventDialogUpdateEvent.Variables
  >(EventDialog.updateMutation, { refetchQueries: props.refetchQueries });

  const [deleteEvent, { loading: deleteEventLoading }] = useMutation<
    GraphQL.EventDialogDeleteMutation.Mutation,
    GraphQL.EventDialogDeleteMutation.Variables
  >(EventDialog.deleteMutation, {
    variables: { id: props.eventId },
    refetchQueries: props.refetchQueries,
  });

  const event = eventData ? eventData.event : undefined;
  const experience = experienceData
    ? experienceData.xperience
    : event
    ? event.experience
    : undefined;

  if (event && !eventDetailsData)
    setEventDetailsData({
      ...event,
      date: moment(event.start),
      startTime: moment(event.start),
      endTime: moment(event.end),
    });

  if (event && event.schedule && (!schedule || event.schedule.id !== schedule.id))
    setSchedule(event.schedule);

  useEffect(() => {
    let newPage: Page;
    if (event) {
      if (event.schedule) {
        newPage = 'ScheduledEventDetails';
      } else {
        newPage = 'CustomEventDetails';
      }
    } else if (experience) {
      if (
        experience.availabilityType === GraphQL.XperienceAvailabilityType.Schedule
      ) {
        if (schedule === null) {
          newPage = 'CustomEventDetails';
        } else if (schedule === undefined) {
          newPage = 'SelectSchedule';
        } else {
          newPage = 'ScheduledEventDetails';
        }
      } else {
        newPage = 'CustomEventDetails';
      }
    } else {
      newPage = 'SearchExperiences';
    }
    setPage(newPage);
  }, [experience, schedule, event]);

  useEffect(() => {
    setModalTitle(
      (() => {
        if (page !== 'SearchExperiences' && (!experience || !experience.name))
          return strings.modalTitles.loading;
        switch (page) {
          case 'SearchExperiences':
            return strings.modalTitles.addNewEvent;
          case 'SelectSchedule':
            return strings.modalTitles.addNewEventTo(experience.name);
          case 'ScheduledEventDetails':
          case 'CustomEventDetails':
            return mode === 'Edit'
              ? strings.modalTitles.editEventFor(experience.name)
              : strings.modalTitles.addNewEventTo(experience.name);
        }
      })(),
    );
  }, [page, experience, loadingExperience]);

  const renderBody = () => {
    switch (page) {
      case 'SearchExperiences':
        return (
          <div>
            <OmniSearch
              data-testid='event-dialog-search-experiences'
              entities={['Xperience']}
              placeholder={strings.searchPlaceholder}
              css={{ width: '400px' }}
              onChange={({ id }) => setExperienceId(id)}
            />
          </div>
        );
      case 'SelectSchedule':
        return (
          <div>
            <Row>
              <ScottTitle>{strings.selectRepeatingAvail}</ScottTitle>
              <Link
                data-testid='add-custom-event-link'
                onClick={() => setSchedule(null)}
              >
                <Icon
                  type='plus-circle'
                  css={{ marginRight: sizes.Spacing.XXSmall }}
                />
                {strings.addCustomEvent}
              </Link>
            </Row>
            <div>
              {experience &&
                experience.schedules &&
                experience.schedules.length === 0 && (
                  <Text size='Small' color='Grey'>
                    No schedules exist for this experience
                  </Text>
                )}
              {_.map(_.get(experience, 'schedules'), (schedule, i) => (
                <div
                  key={`select-schedule-${i}`}
                  data-testid={`select-schedule-${i}`}
                  css={{
                    padding: sizes.Spacing.Large,
                    border: `1px solid ${colors.Blue}`,
                    borderRadius: sizes.GRID_UNIT * 2,
                    marginBottom: sizes.Spacing.Medium,
                    ':hover': {
                      transform: 'scale(1.03)',
                      transformOrigin: 'center center',
                      border: `1px solid ${colors.AntdBlue}`,
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => setSchedule(schedule)}
                >
                  <ScheduleItemCell {...schedule} />
                </div>
              ))}
            </div>
          </div>
        );
      case 'ScheduledEventDetails':
      case 'CustomEventDetails':
        return (
          <EventDetails
            experienceId={experienceId}
            eventId={eventId}
            schedule={schedule}
            clearSchedule={() => setSchedule(undefined)}
            data={eventDetailsData}
            setData={data => setEventDetailsData(data)}
          />
        );
    }
  };

  return (
    <Modal
      width={600}
      data-testid='event-details-modal'
      title={modalTitle}
      visible={isOpen}
      onCancel={close}
      footer={
        page === 'SearchExperiences' ? (
          <Row
            alignItems='center'
            justifyContent='flex-start'
            css={{
              margin: sizes.Spacing.Small,
            }}
          >
            <Text size='Small'>{strings.needToGoToPartnerPage.text}</Text>
            <Link href='/supply'>{strings.needToGoToPartnerPage.link}</Link>
          </Row>
        ) : (
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            {mode === 'Edit' ? (
              <Popconfirm
                {...strings.deleteEventPopConfirmProps}
                placement='top'
                onConfirm={() => deleteEvent().then(close)}
              >
                <Button
                  loading={deleteEventLoading}
                  type='danger'
                  icon='delete'
                  css={{ fontWeight: 500 }}
                >
                  {strings.deleteEvent}
                </Button>
              </Popconfirm>
            ) : (
              <div />
            ) /* so that things layout properly */}
            <div css={{ display: 'flex' }}>
              <Button
                type='default'
                css={{ width: sizes.GRID_UNIT * 15 }}
                onClick={close}
              >
                {strings.cancel}
              </Button>
              <Button
                data-testid='event-details-ok-button'
                loading={
                  createEventLoading ||
                  updateEventLoading ||
                  loadingExperience ||
                  loadingEvent
                }
                disabled={
                  !['ScheduledEventDetails', 'CustomEventDetails'].includes(page) ||
                  !eventDetailsData ||
                  !eventDetailsData.date ||
                  !eventDetailsData.capacityType ||
                  !eventDetailsData.totalCapacity ||
                  !(
                    schedule ||
                    (eventDetailsData.startTime && eventDetailsData.endTime)
                  )
                }
                type='primary'
                css={{ width: sizes.GRID_UNIT * 15 }}
                onClick={async () => {
                  try {
                    const { startTime, endTime, date, ...rest } = eventDetailsData;
                    const isNextDay = endTime < startTime;

                    const newStartTime = mergeDateWithTime(
                      date,
                      startTime || moment(schedule.startTime, 'HH:mm'),
                    );

                    const cleanedData = {
                      ...rest,
                      location: experienceData
                        ? _.pick(experienceData.xperience.location, 'id')
                        : undefined,
                      schedule: schedule ? _.pick(schedule, 'id') : undefined,
                      experience: experienceId ? { id: experienceId } : undefined,
                      start: newStartTime,
                      end: endTime
                        ? date
                            .clone()
                            .add(isNextDay ? 1 : 0, 'days')
                            .set({
                              hour: endTime.get('hour'),
                              minute: endTime.get('minute'),
                              second: 0,
                            })
                        : newStartTime.clone().add(schedule.durationMins, 'minutes'),
                    };

                    switch (mode) {
                      case 'Create':
                        await createEvent({ variables: cleanedData });
                        message.success(
                          strings.messages.successfullyCreated(experience.name),
                        );
                        break;
                      case 'Edit':
                        await updateEvent({ variables: cleanedData });
                        message.success(
                          strings.messages.successfullyUpdated(experience.name),
                        );
                        break;
                    }
                  } catch (error) {
                    console.error(error);
                    message.error(JSON.stringify(error));
                  }
                  close();
                }}
              >
                {strings.ok}
              </Button>
            </div>
          </div>
        )
      }
    >
      {loadingExperience || loadingEvent ? (
        <div css={{ padding: sizes.Spacing.XXLarge }}>
          <FullScreenSpinner />
        </div>
      ) : (
        renderBody()
      )}
    </Modal>
  );
};

export default EventDialog;

EventDialog.experienceFragment = gql`
  fragment EventDialogExperience on Xperience {
    id
    name
    availabilityType
    schedules {
      ...ScheduleItemCell
    }
    location {
      id
    }
  }
  ${ScheduleItemCell.fragment}
`;

EventDialog.experienceQuery = gql`
  query EventDialogExperienceData($experienceId: ID!) {
    xperience(id: $experienceId) {
      ...EventDialogExperience
    }
  }
  ${EventDialog.experienceFragment}
`;

EventDialog.eventFragment = gql`
  fragment EventDialogEvent on Event {
    id
    start
    end
    capacityType
    totalCapacity
    notes
    schedule {
      ...ScheduleItemCell
    }
    experience {
      ...EventDialogExperience
    }
    adventureStops {
      id
      adventure {
        id
        shortId
        party {
          id
        }
      }
    }
  }
  ${ScheduleItemCell.fragment}
  ${EventDialog.experienceFragment}
`;

EventDialog.eventQuery = gql`
  query EventDialogEventData($eventId: ID!) {
    event(id: $eventId) {
      ...EventDialogEvent
    }
  }
  ${EventDialog.eventFragment}
`;

EventDialog.createMutation = gql`
  mutation EventDialogCreateEvent(
    $experience: XperienceInput!
    $schedule: GenericReferenceInput
    $capacityType: CapacityType!
    $totalCapacity: Int!
    $location: LocationInput!
    $start: DateTime!
    $end: DateTime!
    $notes: String
    $cost: CostInput
  ) {
    createEvent(
      experience: $experience
      schedule: $schedule
      capacityType: $capacityType
      totalCapacity: $totalCapacity
      location: $location
      start: $start
      end: $end
      notes: $notes
      cost: $cost
    ) {
      ...EventDialogEvent
    }
  }
  ${EventDialog.eventFragment}
`;

EventDialog.updateMutation = gql`
  mutation EventDialogUpdateEvent(
    $id: ID!
    $schedule: GenericReferenceInput
    $capacityType: CapacityType
    $totalCapacity: Int
    $location: LocationInput
    $start: DateTime
    $end: DateTime
    $notes: String
    $cost: CostInput
  ) {
    updateEvent(
      id: $id
      schedule: $schedule
      capacityType: $capacityType
      totalCapacity: $totalCapacity
      location: $location
      start: $start
      end: $end
      notes: $notes
      cost: $cost
    ) {
      ...EventDialogEvent
    }
  }
  ${EventDialog.eventFragment}
`;

EventDialog.deleteMutation = gql`
  mutation EventDialogDeleteMutation($id: ID!) {
    deleteEvent(id: $id) {
      __typename
    }
  }
`;
