import React from 'react';
import { FiUser } from 'react-icons/fi';
import Ellipsis from '~/Ellipsis';
import { colors, font, sizes } from '~/styles';

const MobileRow = ({
  id,
  name,
  members,
  setSelectedOrganization,
}: {
  id: string;
  name: string;
  members: any[];
  setSelectedOrganization: (id: string) => void;
}) => {
  const orgName = (
    <div
      css={{
        ...font.Size.Heading,
        fontWeight: font.FontWeight.Medium,
        flex: 1,
      }}
    >
      <Ellipsis text={name} />
    </div>
  );

  return (
    <div onClick={() => setSelectedOrganization(id)} css={{ display: 'flex', alignItems: 'center' }}>
      <div
        css={{
          padding: sizes.Spacing.Medium,
          borderBottom: `1px solid ${colors.DarkSnow}`,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'space-between',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: sizes.Spacing.XXSmall,
          }}
        >
          {orgName}
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span css={{ marginRight: '1rem' }}>{members.length}</span>
            <FiUser />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileRow;
