import React from 'react';

import { memo } from '~/react';
import SearchInput from '~/Search/Input';
import { colors, sizes } from '~/styles';

interface Props {}

const MessagesListUserFilter = (props: Props) => {
  return (
    <div
      css={{
        width: '100%',
        padding: sizes.Spacing.XSmall,
        borderBottom: `1px solid ${colors.DarkSnow}`,
      }}
    >
      <SearchInput />
    </div>
  );
};

export default memo(MessagesListUserFilter);
