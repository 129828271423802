import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import Ellipsis from '~/Ellipsis';
import * as GraphQL from '~/graphql';
import { memo } from '~/react';
import { colors, font, sizes } from '~/styles';

interface Props {
  rowData: GraphQL.TeamEventGridQuery.Node;
  setSelectedTeamEventId(id: string): void;
}

const VirtualEventMobileRow = (props: Props) => {
  const { rowData, ...otherProps } = props;

  const requesterName = (
    <div
      css={{
        ...font.Size.Heading,
        fontWeight: font.FontWeight.Medium,
        flex: 1,
      }}
    >
      <Ellipsis text={_.get(rowData, 'requestedBy.name')} />
    </div>
  );

  return (
    <>
      <div onClick={() => props.setSelectedTeamEventId(rowData.id)}>
        <div {...otherProps} css={{ display: 'flex', alignItems: 'center' }}>
          <div
            css={{
              padding: sizes.Spacing.Medium,
              borderBottom: `1px solid ${colors.DarkSnow}`,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'space-between',
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: sizes.Spacing.XXSmall,
              }}
            >
              {requesterName}
              {moment(rowData.requestedFor).format('MM-DD h:mma')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(VirtualEventMobileRow);
