import gql from 'graphql-tag';
import _ from 'lodash';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { memo } from '~/react';
import * as GraphQL from '~/graphql';
import MultiSelectFilter from './MultiSelect';

const FilterOwners = (props: {
  parameterName: string;
  title?: string;
  isSingleSelect?: boolean;
}) => {
  const { data, loading } = useQuery<GraphQL.FilterOwners.Query>(FilterOwners.query);

  if (loading) return null;

  const users = data!.users;

  const options = _.sortBy(
    _.map(users, ({ id, firstName }) => ({
      label: firstName!,
      value: id,
    })),
    'label',
  );

  return (
    <MultiSelectFilter
      name={props.parameterName}
      title={props.title || strings.owners}
      options={options}
      mode={props.isSingleSelect ? 'single' : undefined}
    />
  );
};

const strings = {
  owners: 'Owners',
};

FilterOwners.fragment = gql`
  fragment FilterOwners on User {
    id
    firstName
  }
`;

FilterOwners.query = gql`
  query FilterOwners {
    users(role: Wizard) {
      ...FilterOwners
    }
  }

  ${FilterOwners.fragment}
`;

export default memo(FilterOwners);
