import { Button, Drawer, Input, message } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import LabeledField from '~/Labeled/Field';

export interface ModalProps {
  visible: boolean;
  onCancel: () => void;
  orgId: string;
}

const OrganizationCreator = ({ onClose }) => {
  const [createOrg] = useMutation(OrganizationCreator.mutation, {
    refetchQueries: ['OrganizationEditor', 'OrganizationGridQuery'],
  });
  const [name, setName] = useState('');
  const [crmUrl, setCRMUrl] = useState('');

  return (
    <Drawer
      title={'Create Organization'}
      visible={true}
      onClose={onClose}
      drawerStyle={{ overflowY: 'hidden' }}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        overflowX: 'hidden',
        justifyContent: 'space-between',
        minHeight: '92%',
      }}
      width={'40%'}
    >
      <div>
        <LabeledField label='Organization Name'>
          <Input
            css={{ height: 40, fontSize: '16px' }}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </LabeledField>
        <LabeledField label='CRM URL'>
          <Input
            css={{ height: 40, fontSize: '16px' }}
            value={crmUrl}
            onChange={e => {
              let value = e.target.value;
              if (!value.startsWith('http')) {
                value = 'http://' + value;
              }
              setCRMUrl(value);
            }}
          />
        </LabeledField>
      </div>
      <div css={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button size='large' type='danger' onClick={onClose}>
          Cancel
        </Button>
        <Button
          size='large'
          type='primary'
          onClick={async () => {
            try {
              await createOrg({ variables: { name: name.trim(), crmUrl } });
              message.success(`Organization created!`);
            } catch (ex) {
              message.error(`Organization failed to create! Contact oncall if issue persists.`);
              console.log(ex);
            }
            onClose();
          }}
        >
          Save and continue
        </Button>
      </div>
    </Drawer>
  );
};

OrganizationCreator.mutation = gql`
  mutation CreateNewOrganization($name: String!, $members: [ReferenceUserInput!], $crmUrl: String) {
    createOrganization(name: $name, members: $members, crmUrl: $crmUrl) {
      id
    }
  }
`;

export default OrganizationCreator;
