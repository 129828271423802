import React from 'react';

import Text from '~/Text';
import ScottTitle from '.';
import { sizes, colors } from '~/styles';
import UserFacingDataIconIndicator from '~/UserFacingDataIndicator/Icon';

interface Props {
  label: string;
  rightOfLabelContent?: React.ReactElement;
  children?: React.ReactNode;
  content?: React.ReactNode | string | number | null;
  errorText?: string;
  required?: boolean;
  isUserFacing?: boolean;
}

const LabeledWithScottTitle = ({
  content,
  rightOfLabelContent,
  children,
  label,
  errorText,
  required,
  isUserFacing,
  ...otherProps
}: Props) => {
  const body =
    Array.isArray(children) && children.length === 0
      ? content || '-'
      : content || children;
  return (
    <div
      css={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      {...otherProps}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: sizes.GRID_UNIT,
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ScottTitle type='Field'>{label}</ScottTitle>
          {required && (
            <span
              css={{
                color: colors.UI.Negative,
                fontSize: 12,
                marginBottom: 5,
                height: 8,
                width: 8,
                position: 'relative',
                top: -4,
                left: 2,
              }}
            >
              *
            </span>
          )}
          {isUserFacing && (
            <UserFacingDataIconIndicator
              css={{
                fontSize: 11,
                position: 'relative',
                top: -1,
                marginLeft: sizes.Spacing.XXXSmall,
              }}
            />
          )}
        </div>
        {rightOfLabelContent}
      </div>
      {body}
      {errorText && (
        <Text size='XXSmall' color='Negative'>
          {errorText}
        </Text>
      )}
    </div>
  );
};

export default LabeledWithScottTitle;
