import { useQuery } from '@apollo/react-hooks';
import { Drawer, Tabs } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { colors, sizes } from '~/styles';
import Text from '~/Text';
import EventCostsTab from './EventCosts';
import EventIssuesTab from './EventIssues';
import TeamEventEditor from './Edit';

const Tab = (props: { name: string; isActive?: boolean; icon?: React.ReactElement }) => {
  return (
    <Text
      css={{
        position: 'relative',
        bottom: 6,
        display: 'flex',
        fontWeight: props.isActive ? 500 : undefined,
        color: props.isActive ? colors.Blue.Blue500 : colors.GreyDarker,
        height: 23,
        alignItems: 'flex-end',
      }}
    >
      {props.icon ? (
        <div
          css={{
            position: 'relative',
            top: 3,
            marginRight: sizes.Spacing.XSmall,
            fontSize: 18,
          }}
        >
          {props.icon}
        </div>
      ) : (
        undefined
      )}
      {props.name}
    </Text>
  );
};

const TAB_MAP = {
  summary: { component: TeamEventEditor },
  'Event costs': {
    component: EventCostsTab,
  },
  'Event issue': {
    component: EventIssuesTab,
  },
};

const DrawerWithTabs = props => {
  const { orderId, onClose } = props;
  if (!orderId) return null;
  const [updatedData, setUpdatedData] = useState(undefined);
  const { data, loading, refetch: reloadTeamEvent } = useQuery(TeamEventEditor.query, {
    variables: { id: orderId },
  });
  const [activeKey, setActiveKey] = useState('summary');
  const TabMapComponent = TAB_MAP[activeKey].component;
  useEffect(() => {
    setUpdatedData(data ? data.teamEvent : undefined);
  }, [data]);
  return (
    <Drawer
      title='Edit Team Event'
      width={720}
      visible={!!(!loading && data)}
      onClose={onClose}
      drawerStyle={{ overflowY: 'hidden' }}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        overflowX: 'hidden',
        padding: 0,
        height: 'calc(100vh - 55px)',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          css={{
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            paddingTop: sizes.Spacing.XSmall,
            paddingLeft: sizes.Spacing.XLarge,
            paddingRight: sizes.Spacing.XLarge,
            marginLeft: -16, // antd tab padding
            marginRight: -16, // antd tab padding

            flex: 1,
          }}
        >
          <Tabs
            size='small'
            activeKey={activeKey}
            onChange={setActiveKey}
            css={{ flex: 1 }}
            tabBarStyle={{
              marginBottom: 0,
              border: 0,
            }}
            tabBarGutter={sizes.Spacing.Small}
          >
            {Object.keys(TAB_MAP).map(tabName => (
              <Tabs.TabPane
                key={tabName}
                tab={
                  <Tab
                    isActive={tabName === activeKey}
                    name={_.capitalize(tabName)}
                    icon={TAB_MAP[tabName].tabIcon}
                  />
                }
              />
            ))}
          </Tabs>
        </div>
      </div>

      <div
        css={{
          padding: sizes.Spacing.XSmall,
          display: 'flex',
          justifyContent: 'flex-end',
          borderBottom: `1px solid ${colors.GreyLighter}`,
        }}
      >
        {TAB_MAP[activeKey].filterBar}
      </div>

      <TabMapComponent
        {...props}
        updatedData={updatedData}
        setUpdatedData={setUpdatedData}
        reloadTeamEvent={reloadTeamEvent}
        data={data}
        eventId={orderId}
      />
    </Drawer>
  );
};

export default DrawerWithTabs;
