import React from 'react';

import useMobile from '~/lib/hooks/useMobile';
import { memo } from '~/lib/react';
import { borders, colors, sizes } from '~/styles';
import Text from '~/Text';

interface Props {
  title: string | React.ReactNode;
  rightContent?: React.ReactNode;
}

const Header = ({ title, rightContent }: Props) => {
  const isPhone = useMobile();
  const verticalSpacing = isPhone ? sizes.Spacing.Small : sizes.Spacing.Medium;
  const horizontalSpacing = isPhone ? sizes.Spacing.Small : sizes.Spacing.XLarge;
  return (
    <div
      css={{
        padding: `${verticalSpacing}px ${horizontalSpacing}px`,
        backgroundColor: colors.Snow,
        borderBottom: borders.divider,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Text size='XLarge' weight='Medium'>
            {title}
          </Text>
        </div>
        {!!rightContent && <div>{rightContent}</div>}
      </div>
    </div>
  );
};

export default memo(Header);
