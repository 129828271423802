import React from 'react';
import { Icon, Tooltip } from 'antd';

const UserFacingDataIconIndicator = props => (
  <Tooltip trigger='click' placement='right' title={'User-facing data'}>
    <Icon
      type='eye'
      size='small'
      css={{ color: '#eb2f96cc', fontSize: 14 }}
      {...props}
    />
  </Tooltip>
);

export default UserFacingDataIconIndicator;
