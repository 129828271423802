type Fonts = 'Avenir Next' | 'Avenir';
const DEFAULT_FONT_FAMILY = `'Avenir Next', sans-serif`
const AVENIR_FAMILY = `'Avenir', sans-serif`
const fontMap: Record<Fonts, string> = {
  'Avenir': AVENIR_FAMILY,
  'Avenir Next': DEFAULT_FONT_FAMILY
}
export const FONT_FAMILY = DEFAULT_FONT_FAMILY;


export const fontStyle = (
  fontSize: number,
  lineHeight: number,
  fontWeight: number,
  otherStyles?: any,
  fontFamily?: Fonts,
): {
  fontFamily: typeof AVENIR_FAMILY | typeof DEFAULT_FONT_FAMILY;
  fontSize: string;
  lineHeight: string;
  fontWeight: number;
} => ({
  fontFamily: fontFamily ?  fontMap[fontFamily] : DEFAULT_FONT_FAMILY,
  fontSize: `${fontSize}px !important`,
  lineHeight: `${lineHeight}px !important`,
  fontWeight,
  ...otherStyles,
});

export const Size = {
  SmallCaption: fontStyle(10, 14, 400),
  Caption: fontStyle(12, 16, 400),
  SmallBody: fontStyle(13, 16, 400),
  Body: fontStyle(14, 20, 400),
  BodyLarge: fontStyle(16, 22, 400),
  Subheading: fontStyle(12, 16, 700, { textTransform: 'uppercase' }),
  Heading: fontStyle(16, 24, 500),
  Small: fontStyle(20, 24, 400),
  Medium: fontStyle(24, 32, 400),
  Large: fontStyle(28, 32, 500),
  XLarge: fontStyle(42, 44, 500),
  GroupHeading: fontStyle(30, 36, 900, {}, 'Avenir'),
};

export const FontWeight = {
  Regular: 400,
  Medium: 500,
  Demi: 600,
  Bold: 700,
};
