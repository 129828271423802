import { Icon, Input, Select, Switch } from 'antd';
import gql from 'graphql-tag';

import * as GraphQL from '~/graphql';
import { Edits } from '~/hooks/useEditableEntity';
import InputPhone from '~/Input/Phone';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import Row from '~/Row';
import { colors, sizes } from '~/styles';
import TagsSelect from '~/Search/Tags';
import AddressInput from '~/Input/Address';
import { addSpacesToPascalCasedString } from '~/formatters';

import Heading from '../../Heading';
import SearchMysteryTemplates from '~/Search/Templates';
import LabeledSwitch from '~/Input/LabeledSwitch';

export interface Props {
  experience: GraphQL.ExperienceProfileCoreDetails.Fragment;
  onChange: (edits: Edits<GraphQL.ExperienceProfileCoreDetails.Fragment>) => void;
}

const ExperienceProfileCoreDetails = ({ experience, onChange }: Props) => {
  return (
    <div>
      <Heading>Details</Heading>
      <Row>
        <LabeledField label='Status'>
          <LabeledSwitch
            checked={experience.isActive}
            onChange={isActive => onChange({ isActive })}
            checkedLabel='Active'
            uncheckedLabel='Inactive'
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Description'>
          <Input.TextArea
            rows={6}
            value={experience.description}
            onChange={event => onChange({ description: event.target.value })}
          />
        </LabeledField>
      </Row>
      {!experience.isVirtual && (
        <Row>
          <LabeledField label='Type'>
            <Select
              mode='multiple'
              size='large'
              value={experience.xperienceTypes}
              onChange={xperienceTypes => onChange({ xperienceTypes })}
            >
              {Object.values(GraphQL.XperienceType).map(role => (
                <Select.Option key={role}>{addSpacesToPascalCasedString(role)}</Select.Option>
              ))}
            </Select>
          </LabeledField>
        </Row>
      )}
      <Row>
        <LabeledField label='Include In Template(s)'>
          <SearchMysteryTemplates
            value={experience.templates}
            onChange={result => onChange({ templates: result.map((t: any) => t.data) })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Tags'>
          <TagsSelect
            values={experience.tags.map(({ name }) => name)}
            onChange={(tags: string[]) => onChange({ tags: tags.map(name => ({ name })) })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Reveal Image URL'>
          <Input
            value={experience.photoUrl}
            onChange={event => onChange({ photoUrl: event.target.value })}
          />
          <div css={{ height: 200, overflow: 'auto' }}>
            {experience.photoUrl && <img src={experience.photoUrl} css={{ maxWidth: '100%' }} />}
            {!experience.photoUrl && (
              <div
                css={{
                  display: 'flex',
                  height: '100%',
                  backgroundColor: '#FFF4F4',
                  color: '#D32F30',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon type='exclamation-circle' />
                <span css={{ marginLeft: sizes.Spacing.XXSmall }}>Missing reveal image</span>
              </div>
            )}
          </div>
        </LabeledField>
      </Row>
      {!experience.isVirtual && (
        <Row>
          <LabeledField label='Address'>
            <AddressInput
              css={{ flex: 1 }}
              address={experience.location ? experience.location.address.full : ''}
              onChange={({ location }) => onChange({ location })}
            />
          </LabeledField>
        </Row>
      )}
      <Row>
        <LabeledField label='Phone'>
          <InputPhone
            value={experience.phone || ''}
            onChange={(event: any) => {
              onChange({ phone: event.target.value });
            }}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='URL'>
          <Input
            value={experience.url}
            onChange={event => {
              onChange({ url: event.target.value });
            }}
          />
        </LabeledField>
      </Row>
    </div>
  );
};

ExperienceProfileCoreDetails.fragment = gql`
  fragment ExperienceProfileCoreDetails on Xperience {
    id
    description
    isActive
    isVirtual
    phone
    photoUrl
    url
    xperienceTypes
    location {
      id
      address {
        full
      }
    }
    tags {
      id
      name
    }
    templates {
      id
      title
    }
  }
`;

export default ExperienceProfileCoreDetails;
