import React from 'react';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment-timezone';
import Banner from '~/Banner';
import { centsToDollars } from '~/BillingReviewModal/helpers';
import { formatCents } from '~/formatters';
import * as GraphQL from '~/graphql';
import { getPriceForBand } from '~/helpers';
import { CheckCircle, InfoCircle } from '~/icons';
import ScottHr from '~/ScottHr';
import ScottTitle from '~/ScottTitle';
import { colors, sizes } from '~/styles';
import Text from '~/Text';
import CostToCustomer from './CostToCustomer';
import { DiscountRow } from './Discounts';

const strings = {
  cancellationPolicy: 'Cancellation Policy',
  contractPricing: 'Seats Contract Pricing',
  ctmDiscount: 'Cost to Mystery Discount',
  ctmHeader: 'Cost to Mystery',
  ctcHeader: 'Cost to Customer',
  customerCharge: 'Customer Pays Mystery',
  agreedOn: 'Agreed On',
  requiresUpgrade: 'Requires Upgrade',
  bannerNoMin: 'This event has no minimum requirement',
};

const mysteryCharge = (partnerName: string) => `Mystery Pays ${partnerName}`;
const pricePer = (unit: GraphQL.CostUnit, unitName: string = 'Event') =>
  `Price / ${unit === GraphQL.CostUnit.User ? 'Person' : unitName}`;
const bannerMinRequirement = (guests: number) =>
  `This event has a minimum requirement of ${guests} guests`;

const ExperiencePricingView = (props: GraphQL.ExperiencePricingView.Fragment) => {
  const { cost, requiresUpgrade, id } = props;
  const ctcPricingBands = _.get(cost, 'ctcPricingBands') || [];
  const band = _.nth(ctcPricingBands, 0);
  let priceToCustomer = getPriceForBand(band);
  if (!priceToCustomer) {
    // backwards compability for costs without price bands
    priceToCustomer = cost.ctcCents;
  }

  const hasMinReqGuests = !!band && band.maxUsers;
  const bannerText = hasMinReqGuests ? bannerMinRequirement(band.maxUsers) : strings.bannerNoMin;
  const bannerIcon = hasMinReqGuests ? (
    <CheckCircle color={colors.Blue500} />
  ) : (
    <InfoCircle color={colors.Blue500} />
  );

  return (
    <>
      {ctcPricingBands.length > 0 ? (
        <div>
          <CostToCustomer label={strings.ctcHeader} priceBands={ctcPricingBands} id={id} />
          <Banner
            text={bannerText}
            icon={bannerIcon}
            css={{
              display: 'flex',
              margin: `${sizes.Spacing.Small}px 0`,
              padding: `${sizes.Spacing.Small}px`,
              borderRadius: `${sizes.BorderRadius.Medium}px`,
              backgroundColor: colors.Blue500_Faded,
              '.ant-alert-message': {
                marginLeft: sizes.Spacing.Small,
                color: colors.Blue500,
                fontWeight: 600,
              },
            }}
          />
          <ScottTitle type='SubSection'>{`${strings.agreedOn} ${moment(cost.agreedOn).format(
            'M/D/YYYY',
          ) || ''}`}</ScottTitle>
        </div>
      ) : (
        cost.ctmVolumeDiscounts &&
        cost.ctmVolumeDiscounts.length > 0 && (
          <CostToCustomer
            label={strings.ctmDiscount}
            discounts={props.cost.ctmVolumeDiscounts}
            id={id}
          />
        )
      )}
      <ScottHr />
      <ScottTitle type='SubSection'>{strings.contractPricing}</ScottTitle>
      <ScottTitle type='SubSection'>{strings.requiresUpgrade}</ScottTitle>
      <Text
        size='Small'
        css={{
          color: colors.GreyDarker,
          fontWeight: requiresUpgrade ? 'bold' : 'normal',
        }}
      >
        {requiresUpgrade ? 'Yes' : 'No'}
      </Text>
      <ScottHr />
      <ScottTitle type='SubSection'>{strings.ctmHeader}</ScottTitle>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <PricingDetail
          value={
            `${formatCents(cost.ctmCents)}` +
            (cost.ctmUnit === GraphQL.CostUnit.User && !!cost.ctmPerPersonCentsMinimum
              ? ` ($${centsToDollars(cost.ctmPerPersonCentsMinimum)} min.)`
              : '')
          }
          label={mysteryCharge(props.partner.name) || ''}
          unit={pricePer(cost.ctmUnit)}
        />
      </div>
      {cost.ctmTieredEventPricing && cost.ctmTieredEventPricing.length > 0 && (
        <TieredEventView
          label='Tiered Event Pricing'
          ctmTieredEventPricing={cost.ctmTieredEventPricing}
        />
      )}
      {cost.cancellationPolicy && (
        <>
          <ScottHr />
          <ScottTitle type='SubSection'>{strings.cancellationPolicy}</ScottTitle>
          <Text size='Small'>{cost.cancellationPolicy}</Text>
        </>
      )}
    </>
  );
};

const PricingDetail = ({ value, label, unit = '' }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <ScottTitle
      type='SubSection'
      css={{
        maxWidth: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: sizes.Spacing.XXSmall,
      }}
    >
      {label}
    </ScottTitle>
    <span
      css={{
        marginBottom: sizes.Spacing.XXSmall,
        color: colors.GreyDarker,
        fontSize: '18px',
        fontWeight: 600,
      }}
    >
      {value}
    </span>
    {unit && (
      <Text size='Small' weight='Demi'>
        {unit}
      </Text>
    )}
  </div>
);

export default ExperiencePricingView;

ExperiencePricingView.fragment = gql`
  fragment ExperiencePricingView on Xperience {
    id
    requiresUpgrade
    cost {
      id
      agreedOn
      ctcCents
      ctcUnit
      ctcPricingBands {
        minUsers
        maxUsers
        cents
        credits
        unit
      }
      ctmCents
      ctmUnit
      ctmVolumeDiscounts {
        minNumUsers
        percentDiscount
      }
      ctmTieredEventPricing {
        minNumUsers
        ctmCents
      }
      ctmPerPersonCentsMinimum
      cancellationPolicy
    }
    partner {
      id
      name
    }
  }
`;

const TieredEventView = ({ label, ctmTieredEventPricing }) => (
  <>
    <ScottTitle css={{ marginTop: sizes.Spacing.Medium }} type='SubSection'>
      {label}
    </ScottTitle>
    <DiscountRow style={{ marginBottom: sizes.Spacing.Small }}>
      <ScottTitle css={{ width: '30%', marginRight: sizes.Spacing.Medium }} type='Field'>
        {'Min Number Users'}
      </ScottTitle>
      <ScottTitle type='Field'>{'Cost to Mystery'}</ScottTitle>
    </DiscountRow>
    {ctmTieredEventPricing.map((row, i) => (
      <DiscountRow
        key={`not-discount-${i}`}
        style={{
          marginBottom: sizes.Spacing.XXSmall,
        }}
      >
        <Text
          weight='Demi'
          size='Small'
          style={{
            width: '30%',
            marginRight: sizes.Spacing.Medium,
          }}
        >
          {`${row.minNumUsers}`}
        </Text>
        <Text weight='Demi' size='Large'>{`${formatCents(row.ctmCents)}`}</Text>
      </DiscountRow>
    ))}
  </>
);
