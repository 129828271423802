import React from 'react';
import BlackoutDateInput from './BlackoutDateInput';
import UpcomingBlackoutDates from './UpcomingBlackoutDates';
import * as GraphQL from '~/graphql';

const BlackoutDateTab = (props: {
  mysteryType: GraphQL.MysteryType;
  blackoutDates: GraphQL.BlackoutDate[];
  refetch(): Promise<any>;
}) => {
  const blackoutDates = props.blackoutDates.filter(
    ({ mysteryType }) => mysteryType === props.mysteryType,
  );
  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <BlackoutDateInput
        blackoutDates={blackoutDates}
        mysteryType={props.mysteryType}
        refetch={props.refetch}
      />
      <UpcomingBlackoutDates
        showTypeTags={true}
        blackoutDates={blackoutDates}
        refetch={props.refetch}
      />
    </div>
  );
};

export default BlackoutDateTab;
