import React, { useState } from 'react';
import { message, Icon, Popconfirm, Popover, Tag, Button } from 'antd';
import { useMutation } from '@apollo/react-hooks';

import * as GraphQL from '~/graphql';
import { colors } from '~/styles';
import Link from '~/Link';
import TagsSelect from '~/Search/Tags';
import { deleteMutation, mergeMutation } from './operations';

interface Props {
  tag: { id: string; name: string; isUsedByCode: boolean };
  refetch: () => Promise<unknown>;
}

const TagsActionCell = ({ tag, refetch }: Props) => {
  const [deleteTag] = useMutation<GraphQL.DeleteTag.Mutation, GraphQL.DeleteTag.Variables>(
    deleteMutation,
    {
      variables: { tagId: tag.id },
      refetchQueries: ['TagsList'],
    },
  );

  const onClickDelete = async () => {
    message.loading(`Deleting tag`);
    try {
      await deleteTag();
      message.destroy();
      message.success(`Tag successfully deleted`);
      await refetch();
    } catch (e) {
      message.destroy();
      message.error(`Problem encountered: ${e}`);
    }
  };

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'flex-end',
        fontSize: 16,
      }}
    >
      <MergePopover tag={tag} refetch={refetch} />
      <Popconfirm
        placement='left'
        title={
          <>
            Are you sure you want to delete <Tag color='purple'>{tag.name}</Tag>?
          </>
        }
        okText='Delete Tag'
        okType='danger'
        cancelText='No'
        onConfirm={onClickDelete}
      >
        <Link data-testid='delete-event-link' color={colors.Negative}>
          <Icon type='delete' />
        </Link>
      </Popconfirm>
    </div>
  );
};

const MergePopover = ({ tag, refetch }: Props) => {
  const [visible, setVisible] = useState(false);
  const [merging, setMerging] = useState(false);
  const [targetTagId, setTargetTagId] = useState<string>();
  const [mergeTag] = useMutation<GraphQL.MergeTag.Mutation, GraphQL.MergeTag.Variables>(
    mergeMutation,
    {
      onCompleted: async () => {
        await refetch();
        setVisible(false);
      },
      onError: () => {
        setMerging(false);
      },
    },
  );

  const content = (
    <div>
      <div>
        Merge <Tag color='purple'>{tag.name}</Tag> into{' '}
        <TagsSelect
          single
          useIds
          value={targetTagId}
          onChange={setTargetTagId}
          css={{ width: 200 }}
        />
      </div>
      <div css={{ textAlign: 'right', marginTop: 16 }}>
        <Button
          disabled={!targetTagId || targetTagId === tag.id || merging}
          type='danger'
          onClick={() => {
            setMerging(true);
            mergeTag({ variables: { id: tag.id, into: targetTagId } });
          }}
        >
          Merge
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      trigger='click'
      placement='left'
      visible={visible}
      onVisibleChange={setVisible}
      content={content}
    >
      <Link data-testid='merge-event-link' color={colors.Negative} css={{ marginRight: 16 }}>
        <Icon type='fork' />
      </Link>
    </Popover>
  );
};
export default TagsActionCell;
