import { Spinner } from '@shopify/polaris';
import _ from 'lodash';
import React, { memo } from 'react';

interface Props {
  visible: boolean;
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
}

const LoadingOverlay = ({ visible, top, left, bottom, right }: Props) =>
  visible ? (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: _.isNil(top) ? 0 : top,
        right: _.isNil(right) ? 0 : right,
        bottom: _.isNil(bottom) ? 0 : bottom,
        left: _.isNil(left) ? 0 : left,
        background: 'rgba(255, 255, 255, 0.33)',
      }}
    >
      <Spinner size='large' color='inkLightest' />
    </div>
  ) : null;

export default memo(LoadingOverlay);
