import _ from 'lodash';
import React from 'react';

import FillRemaining from '~/FillRemaining';
import FiltersBar from '~/FiltersBar';
import FiltersBarToggle from '~/FiltersBar/Toggle';
import { formatPhone } from '~/formatters';
import Header from '~/Header';
import LabeledField from '~/Labeled/Field';
import SearchInput from '~/Search/Input';
import SplitView from '~/SplitView';
import { sizes } from '~/styles';
import AddUserButton from '~/Users/AddUserButton';
import UsersDetails from '~/Users/Details';
import UsersList from '~/Users/List';

const Users = () => {
  return (
    <FillRemaining>
      <Header
        title={strings.users}
        rightContent={
          <>
            <AddUserButton css={{ marginRight: sizes.Spacing.XSmall }} />
            <FiltersBarToggle filtersetKey='usersFiltersVisible' />
          </>
        }
      />
      <FiltersBar filtersetKey='usersFiltersVisible'>
        <LabeledField label={strings.search}>
          <SearchInput size='default' />
        </LabeledField>
      </FiltersBar>
      <SplitView
        sessionStorageKey='user'
        ListComponent={UsersList}
        DetailsComponent={UsersDetails}
        getMobileDetailsTitle={(selectedRow: any) =>
          _.get(selectedRow, 'name') || formatPhone(_.get(selectedRow, 'phone'))
        }
      />
    </FillRemaining>
  );
};

const strings = {
  users: 'Users',
  search: 'Search',
};

export default Users;
