import gql from 'graphql-tag';
import React, { useState } from 'react';
import * as GraphQL from '~/graphql';
import DataTable, { filters } from '~/DataTable';
import TagsListCell from '~/Supply/Tags/ListCell';
import { titleCaseFromPascalCase } from '~/formatters';
import Link from '~/Link';
import TemplateDialog from './TemplateDialog';

const Templates = ({
  columnWhitelist,
  variablesOverride,
  clientSideFilter,
  ...otherProps
}: {
  variablesOverride?: any;
  columnWhitelist?: string[];
  clientSideFilter?: (rowData: any) => boolean;
}) => {
  const [editingTemplateId, setEditingTemplateId] = useState('');
  return (
    <DataTable<GraphQL.MysteryTemplatesList.Node, GraphQL.MysteryTemplatesList.Variables>
      {...otherProps}
      query={Templates.query}
      queryPath={['mysteryTemplatesConnection']}
      columns={[
        {
          header: 'Photo',
          width: 200,
          cell: ({ photoUrl }) => (
            <img
              src={photoUrl}
              alt={'template cover photo'}
              css={{ height: 50, objectFit: 'cover' }}
            />
          ),
        },
        {
          header: 'Title',
          width: 250,
          filter: filters.stringFilter('title', 'Template Title'),
          cell: template => (
            <>
              <Link onClick={() => setEditingTemplateId(template.id)}>{template.title}</Link>
              <TemplateDialog
                mode='edit'
                title='Edit Template'
                onCancel={() => setEditingTemplateId('')}
                isOpen={editingTemplateId === template.id}
                currentValues={template}
              />
            </>
          ),
        },
        {
          header: 'Code',
          width: 150,
          filter: filters.stringFilter('code', 'Template Code'),
          cell: ({ code }) => code,
        },
        {
          header: 'Description',
          width: 600,
          cell: ({ description }) => description,
        },
        {
          header: 'Type',
          width: 100,
          cell: ({ type }) => type,
        },
        {
          header: 'Category Matches',
          width: 185,
          filter: filters.categoryFilter('categories'),
          cell: ({ categories }) => (
            <TagsListCell data={categories} sortIndex={category => category.name} />
          ),
        },
        {
          header: 'Connection Focus Areas',
          width: 245,
          filter: filters.enumMultiFilter(
            'connectionFocusAreas',
            'Has Connection Focus Areas',
            GraphQL.ConnectionFocusArea,
            titleCaseFromPascalCase,
          ),
          cell: ({ connectionFocusAreas }) => (
            <TagsListCell data={connectionFocusAreas} sortIndex={titleCaseFromPascalCase} />
          ),
        },
      ]}
    />
  );
};

Templates.query = gql`
  query MysteryTemplatesList(
    $first: Int
    $after: String
    $order: [MysteryTemplateOrderArgs]
    $filterInput: TemplateFilterInput
  ) {
    mysteryTemplatesConnection(
      first: $first
      after: $after
      order: $order
      filterInput: $filterInput
    ) @connection(key: "mysteryTemplatesConnection", filter: ["order", "filterInput"]) {
      edges {
        node {
          id
          type
          title
          description
          photoUrl
          code
          categories {
            id
            name
          }
          connectionFocusAreas
          rangeIdealGuests
          estimatedDurationMins
          includesBreakoutRooms
          cost {
            id
            ctcCents
            ctcUnit
            ctmCents
            ctmUnit
            ctcPricingBands {
              unit
              cents
              credits
              minUsers
              maxUsers
            }
            ctmTieredEventPricing {
              minNumUsers
              ctmCents
            }
            ctmVolumeDiscounts {
              minNumUsers
              percentDiscount
            }
            ctmPerPersonCentsMinimum
            agreedOn
            cancellationPolicy
          }
          theme {
            id
            title
          }
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export default Templates;
