import _ from 'lodash';
import React from 'react';

import Search from '~/Search';

interface Props {
  defaultValue?: any;
  onSelect: any;
  size?: 'small' | 'default' | 'large';
}

const SearchPlaces = ({
  defaultValue,
  onSelect,
  size = 'large',
  ...otherProps
}: Props) => (
  <Search
    size={size}
    defaultValue={defaultValue}
    onSelect={onSelect}
    onSearch={(input: string) =>
      new Promise<AutocompleteResult[]>(resolve => {
        autocompleteService.getPlacePredictions({ input }, predictions => {
          resolve(
            _.map(predictions, prediction => ({
              ...prediction,
              key: prediction.place_id,
              title: prediction.structured_formatting.main_text,
              description: prediction.structured_formatting.secondary_text,
            })),
          );
        });
      })
    }
    {...otherProps}
  />
);

interface AutocompleteResult {
  key: string;
  title: string;
  types: string[];
  place_id: string;
  reference: string;
  description: string;
  terms: google.maps.places.PredictionTerm[];
  matched_substrings: google.maps.places.PredictionSubstring[];
  structured_formatting: google.maps.places.AutocompleteStructuredFormatting;
}

const autocompleteService = new google.maps.places.AutocompleteService();

export default SearchPlaces;
