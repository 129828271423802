import React from 'react';
import gql from 'graphql-tag';
import * as GraphQL from '~/graphql';
import Text from '~/Text';
import moment from 'moment-timezone';
import { sizes } from '~/styles';
import EventCell from './Entities/Event';
import DefaultCell from './Entities/Default';
import ApprovalCell from './ApprovalCell';

const AuditLogCell = (
  props: GraphQL.AuditLogCell.Fragment & {
    key: string;
    shouldLabelEntity: boolean;
  },
) => {
  return (
    <div>
      {(() => {
        if (props.eventName === GraphQL.EventName.QueryDataChanged) {
          switch (props.entityType) {
            case 'Partner':
            case 'Xperience':
              return <DefaultCell {...props} shouldLabelEntity={props.shouldLabelEntity} />;
            case 'Event':
              return <EventCell {...props} />;
            case 'Contract':
              return <DefaultCell {...props} />;
            case 'Teamevent':
              return <DefaultCell {...props} />;
            default:
              throw new Error(`unknown entity type ${props.entityType}`);
          }
        } else if (props.eventName === GraphQL.EventName.EntityApproval) {
          return <ApprovalCell {...props} shouldLabelEntity={props.shouldLabelEntity} />;
        } else {
          return null;
        }
      })()}
      <Text size='XSmall' color='GreyDarker' css={{ marginTop: sizes.Spacing.XXSmall }}>
        {moment(props.occurredAt).fromNow()}
      </Text>
    </div>
  );
};

export default AuditLogCell;

AuditLogCell.fragment = gql`
  fragment AuditLogCell on AuditLog {
    id
    metadata {
      before
      after
    }
    occurredAt
    actor {
      id
      firstName
    }
    entityType
    entityId
    eventName
  }
`;
