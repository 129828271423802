import React from 'react';
import { Button, DatePicker, Icon, Typography } from 'antd';
import moment from 'moment-timezone';
import { PricingBand } from '~/graphql';
import PriceBand from '~/PriceBand';
import BandRow from '~/PriceBand/BandRow';
import ScottHr from '~/ScottHr';
import ScottSection from '~/ScottSection';
import { colors, sizes } from '~/styles';
import SectionTitle from './SectionTitle';

const columnHeaders = {
  minUsers: 'Min guests',
  maxUsers: 'Max guests',
  price: 'Dollar cost per unit',
  unitType: 'Credit cost per person',
  guests: 'Guests',
  pricing: 'Pricing',
};

const addCtcBandString = ' Add CTC band';
const agreedOnDateString = 'AGREED ON ';
const dateFormat = 'M/D/YYYY';

const PricingSection = ({ children, className }: { children: string; className?: string }) => (
  <div
    css={{
      fontFamily: 'Avenir',
      lineHeight: '127.6%',
      fontWeight: 800,
      color: colors.Silver,
      fontSize: '12px',
    }}
    className={className}
  >
    {children}
  </div>
);

interface Props {
  title: string;
  ctcBands: { [key: number]: PricingBand };
  agreedOnDate: moment.Moment;
  onAgreedDateChange: (arg: moment.Moment) => void;
  onCtcBandChange: (id: number, band: PricingBand) => void;
  onRemoveCtcBand: (id: number) => void;
  onAddCtcBand: () => void;
}

const PriceBands = ({
  title,
  ctcBands,
  agreedOnDate,
  onAgreedDateChange,
  onCtcBandChange,
  onRemoveCtcBand,
  onAddCtcBand,
}: Props) => {
  return Object.keys(ctcBands).length ? (
    <div css={{ marginTop: sizes.Spacing.Medium }}>
      <SectionTitle title={title} />
      <ScottSection noPadding css={{ overflow: 'hidden' }}>
        <div
          css={{
            backgroundColor: colors.Snow,
            padding: `${sizes.Spacing.Small}px ${sizes.Spacing.Medium}px ${sizes.Spacing.XSmall}px`,
            marginBottom: 0,
          }}
        >
          <BandRow style={{ marginBottom: sizes.Spacing.XXSmall }}>
            <PricingSection css={{ color: colors.Blue500, marginLeft: sizes.Spacing.XXXLarge }}>
              {columnHeaders.guests}
            </PricingSection>
            <PricingSection
              css={{ color: colors.Blue500, marginLeft: sizes.Spacing.XXXLarge * 2.6 }}
            >
              {columnHeaders.pricing}
            </PricingSection>
          </BandRow>
          <BandRow>
            <PricingSection css={{ marginLeft: sizes.Spacing.XXXLarge }}>
              {columnHeaders.minUsers}
            </PricingSection>
            <PricingSection css={{ marginLeft: sizes.Spacing.XLarge }}>
              {columnHeaders.maxUsers}
            </PricingSection>
            <PricingSection css={{ marginLeft: sizes.Spacing.XXLarge }}>
              {columnHeaders.price}
            </PricingSection>
            <PricingSection
              css={{
                flexGrow: 2,
                textAlign: 'right',
                paddingRight: sizes.Spacing.XLarge,
              }}
            >
              {columnHeaders.unitType}
            </PricingSection>
            <div css={{ width: 'fill' }} />
          </BandRow>
        </div>
        <ScottHr marginBottom={0} marginTop={0} />
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: `0 ${sizes.Spacing.Medium}px`,
          }}
        >
          {Object.entries(ctcBands).map(([key, ctcBand], index) => (
            <div key={key}>
              <PriceBand
                displayIndex={index}
                id={parseInt(key)}
                onRemove={onRemoveCtcBand}
                band={ctcBand}
                onBandChange={onCtcBandChange}
              />
              <ScottHr marginBottom={0} />
            </div>
          ))}
        </div>
        <div
          css={{
            display: 'flex',
            padding: `${sizes.Spacing.Small}px ${sizes.Spacing.Large}px`,
            justifyContent: 'space-between',
            backgroundColor: colors.Snow,
          }}
        >
          <Button
            type='primary'
            shape='round'
            size='default'
            onClick={onAddCtcBand}
            css={{
              backgroundColor: colors.Blue500_Faded,
              color: colors.Blue500,
              border: 'none',
            }}
          >
            <Icon type='plus-circle' css={{ marginRight: sizes.Spacing.XXXSmall }} />
            {addCtcBandString}
          </Button>
          <div>
            <Typography
              css={{
                display: 'inline-block',
                fontWeight: 600,
                fontSize: '12px',
                color: `${colors.GreyDarker}`,
                marginRight: `${sizes.Spacing.XSmall}px`,
              }}
            >
              {agreedOnDateString}
            </Typography>
            <DatePicker
              defaultValue={moment(new Date())}
              format={dateFormat}
              value={agreedOnDate}
              onChange={onAgreedDateChange}
            />
          </div>
        </div>
      </ScottSection>
    </div>
  ) : (
    <div
      css={{
        padding: `${sizes.Spacing.Small}px`,
      }}
    >
      <Button
        type='primary'
        shape='round'
        size='default'
        onClick={onAddCtcBand}
        css={{
          backgroundColor: colors.Blue500_Faded,
          color: colors.Blue500,
          border: 'none',
        }}
      >
        <Icon type='plus-circle' css={{ marginRight: sizes.Spacing.XXXSmall }} />
        {addCtcBandString}
      </Button>
    </div>
  );
};

export default PriceBands;
