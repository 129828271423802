import { ContractStatus, ContractUnitType } from '~/graphql';

export const CONTRACT_UNIT_PHRASE_LOOKUP = {
  [ContractUnitType.Cents]: 'dollars',
  [ContractUnitType.Event]: 'event credits',
  [ContractUnitType.Recurring]: 'seats',
  [ContractUnitType.Credits]: 'credits',
};

const CONTRACT_UNIT_FORMATS = {
  [ContractUnitType.Cents]: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }),
  [ContractUnitType.Credits]: new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2, //Only show two decimal places at maximum
  }),
  [ContractUnitType.Event]: new Intl.NumberFormat('en-US'), //Basic number
  [ContractUnitType.Recurring]: new Intl.NumberFormat('en-US'), //Basic number
};

export const CONTRACT_UNIT_FORMAT_LOOKUP = {
  [ContractUnitType.Cents]: (cents: number) =>
    CONTRACT_UNIT_FORMATS[ContractUnitType.Cents].format(cents / 100),
  [ContractUnitType.Credits]: (credits: number) =>
    CONTRACT_UNIT_FORMATS[ContractUnitType.Credits].format(credits / 100),
  [ContractUnitType.Event]: (event: number) =>
    CONTRACT_UNIT_FORMATS[ContractUnitType.Event].format(event),
  [ContractUnitType.Recurring]: (seats: number) =>
    CONTRACT_UNIT_FORMATS[ContractUnitType.Recurring].format(seats),
};

const CONTRACT_DISPLAY_STRINGS: Record<ContractUnitType, string> = {
  Cents: 'cent based',
  Credits: 'credit based',
  Recurring: 'seat based',
  Event: '',
  Unlimited: '',
};

type ContractInfo = {
  name?: string;
  status?: ContractStatus;
  type?: ContractUnitType;
};

export const getContractName = (contract: ContractInfo) => {
  const contractUnitType = contract.type || ContractUnitType.Credits;
  const contractStatus = contract.status === 'Active' ? 'Active' : 'Inactive';
  return (
    contract.name ||
    `${contractStatus} ${CONTRACT_DISPLAY_STRINGS[contractUnitType]} contract`
  );
};
