export const Brand = {
  LightRubine: `#F61E62`,
  Rubine: `#D8094A`,
  DarkRubine: `#A70739`,
  LightPurple: `#A389F4`,
  Purple: `#7E5BEF`,
  DarkPurple: `#592DEA`,
  LightOrange: `#FF9E7C`,
  Orange: `#F06F11`,
  DarkOrange: `#FF5216`,
  LightGreen: `#29EB7F`,
  Green: `#13CE66`,
  DarkGreen: `#0F9F4F`,
  LightYellow: `#FFD55F`,
  Yellow: `#FFC82C`,
  DarkYellow: `#F8B700`,
};

export const Blue = {
  Blue500: '#2F80ED',
  Blue500_Faded: 'rgba(47, 128, 237, 0.08)',
  LightBlue: `#85D7FF`,
  AntdBlue: `#1890ff`,
  Blue: `#1FB6FF`,
  DarkBlue: `#009EEB`,
  NavyBlue: `#1F5AA8`,
};

export const Red = {
  Red500: '#D32F2F',
  Red500_Faded: 'rgba(211, 47, 47, 0.4)',
};

export const UI = {
  Positive: Brand.Green,
  Warning: Brand.Yellow,
  Negative: `#FF4949`,
};

export const Black = {
  OffBlack: `#363636`,
  Black: '#22292f',
  GreyDarkest: '#3d4852',
  GreyDarker: '#606f7b',
  GreyDark: '#8795a1',
  GreyDarkish: '#95a2ac',
  Grey: '#b8c2cc',
  GreyLight: '#dae1e7',
  GreyLighter: '#f1f5f8',
};

export const Grey = {
  Steel: `#273444`,
  Slate: `#3C4858`,
  Silver: `#8492A6`,
  Smoke: `#E0E6ED`,
  DarkSmoke: `#D3DCE6`,
  ExtraDarkSmoke: `#C0CCDA`,
  Snow: `#F9FAFC`,
  DarkSnow: `#EFF2F7`,
  ExtraDarkSnow: `#E5E9F2`,
  Grey: '#979BAA',
};

export const White = '#ffffff';
export const LyftPink = '#ea0b8c';
export const Muted = Black.GreyDarker;
export const Highlight = '#FCF4DB';

export default {
  Brand,
  UI,
  Grey,
  White,
  Muted,
  LyftPink,
  Highlight,
  ...Brand,
  ...UI,
  ...Grey,
  ...Black,
  ...Blue,
  ...Red,
  Blue,
  Red,
};

export const getRandomColorBasedOnString = string => {
  const hashCode = str => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  };

  var c = (hashCode(string) & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
};
