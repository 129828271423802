import React, { useState } from 'react';
import Link from '~/Link';
import { useApolloClient } from '@apollo/react-hooks';
import _ from 'lodash';
import gql from 'graphql-tag';
import { colors } from '~/styles';

const EntityLink = (props: {
  entityType: string;
  entityId: string;
  experienceAtPartnerText?: boolean;
}) => {
  const [isBorked, setIsBorked] = useState(false);
  const [linkText, setLinkText] = useState(undefined);
  const [linkPath, setLinkPath] = useState(undefined);

  const client = useApolloClient();

  switch (props.entityType) {
    case 'Xperience':
      client
        .query({
          query: gql`
            query AuditLogExperienceLink($id: ID!) {
              xperience(id: $id) {
                id
                name
                partner {
                  id
                  name
                }
              }
            }
          `,
          variables: { id: props.entityId },
        })
        .then(resp => {
          const { data } = resp;
          if (data.xperience) {
            setLinkText(
              props.experienceAtPartnerText
                ? `${data.xperience.name} @ ${data.xperience.partner.name}`
                : data.xperience.name,
            );
            setLinkPath(
              `/supply/partner/${data.xperience.partner.id}/experience/${
                data.xperience.id
              }`,
            );
          } else {
            setIsBorked(true);
          }
        });
      break;
    case 'Partner':
      client
        .query({
          query: gql`
            query AuditLogPartnerLink($id: ID!) {
              partner(id: $id) {
                id
                name
              }
            }
          `,
          variables: { id: props.entityId },
        })
        .then(({ data }) => {
          if (data.partner) {
            setLinkText(data.partner.name);
            setLinkPath(`/supply/partner/${data.partner.id}`);
          } else {
            setIsBorked(true);
          }
        });
      break;
  }

  if ((!linkText || !linkPath) && !isBorked) return null;
  return isBorked ? (
    <span css={{ color: colors.Negative, fontWeight: 600 }}>
      {_.toUpper(`deleted ${props.entityType}`)}
    </span>
  ) : (
    <Link css={{ fontWeight: 400 }} href={linkPath} useReactRouterLink>
      {linkText}
    </Link>
  );
};

export default EntityLink;
