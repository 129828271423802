import React from 'react';
import Header from '~/Header';
import Breadcrumb, { Props as BreadcrumbProps } from './Breadcrumb';
import Text from '~/Text';
import { sizes } from '~/styles';

const Page = ({
  children,
  title,
  breadcrumbProps,
  rightContent,
  customContentArea,
  ...otherProps
}: {
  children: React.ReactElement;
  title?: string;
  breadcrumbProps?: BreadcrumbProps;
  rightContent?: React.ReactElement;
  customContentArea?: boolean;
}) => {
  if (title && breadcrumbProps)
    throw new Error('title and breadcrumb props must not both be specified');

  let content: React.ReactNode = React.cloneElement(children, otherProps);
  if (!customContentArea) {
    content = (
      <div css={{ overflow: 'auto', flex: 1 }}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: `${sizes.Spacing.Large}px ${sizes.Spacing.XLarge}px`,
            marginRight: 'auto',
            maxWidth: '1200px',
            minHeight: '100%',
          }}
        >
          {content}
        </div>
      </div>
    );
  }

  return (
    <div css={{ height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <Header
        title={
          title ? (
            <Text size='Large' weight='Demi'>
              {title}
            </Text>
          ) : (
            <Breadcrumb {...breadcrumbProps} />
          )
        }
        rightContent={rightContent}
      />
      {content}
    </div>
  );
};

export default Page;
