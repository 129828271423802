import React from 'react';

import { colors, font, sizes } from '~/styles';

interface Props {
  index: number;
  icon?: React.ReactElement;
  title: string;
  description: string;
  email?: string;
  suffix?: string;
  onClick: (e: any, ref: any) => void;
}

const SearchResultsResult = (props: Props) => {
  return (
    <div
      data-testid={`SearchResult-${props.index}`}
      css={{
        width: '100%',
        padding: `${sizes.Spacing.XSmall}px ${sizes.Spacing.Small}px`,
        borderBottom: `1px solid ${colors.DarkSnow}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: colors.OffBlack,
        ...font.Size.Body,
        backgroundColor: colors.White,
        cursor: 'pointer',
        ':hover': {
          backgroundColor: colors.Snow,
        },
      }}
      onClick={(e: any) => {
        props.onClick(e, props);
      }}
    >
      <div css={{ display: 'flex' }}>
        {props.icon && (
          <div
            css={{
              marginRight: sizes.Spacing.XSmall,
              fontSize: 22,
              color: colors.GreyDarker,
              position: 'relative',
              top: 5,
            }}
          >
            {props.icon}
          </div>
        )}
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <span
            css={{
              display: 'block',
              fontWeight: font.FontWeight.Medium,
              marginBottom: sizes.Spacing.XXXSmall,
            }}
          >
            {props.title}
          </span>
          {props.email && (
            <div
              css={{
                fontWeight: font.FontWeight.Medium,
                marginBottom: sizes.Spacing.XXXSmall,
              }}
            >
              {props.email}
            </div>
          )}
          <span css={{ display: 'block', ...font.Size.Caption, color: colors.GreyDarker }}>
            {props.description}
          </span>
        </div>
      </div>
      {props.suffix && (
        <span
          css={{
            display: 'block',
            marginLeft: sizes.Spacing.Medium,
            ...font.Size.Caption,
          }}
        >
          {props.suffix}
        </span>
      )}
    </div>
  );
};

export default SearchResultsResult;
