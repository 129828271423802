import React from 'react';
import { colors, sizes } from '~/styles';

type ScottTitleType = 'Section' | 'SubSection' | 'Field' | 'PricingSection';

interface Props {
  children: string;
  type?: ScottTitleType;
  color?: string;
}

const ScottTitle = ({ children, type, color, ...otherProps }: Props) => {
  const css = {
    Section: {
      fontSize: '18px',
      marginBottom: sizes.Spacing.XSmall,
    },
    SubSection: {
      fontSize: '12px',
      opacity: 0.66,
      marginBottom: sizes.GRID_UNIT * 3,
    },
    PricingSection: {
      color: colors.Silver,
      fontSize: '12px',
    },
    Field: { fontSize: '9px', opacity: 0.5 },
  };

  const capitalizedString = children.toUpperCase();

  return (
    <div
      css={{
        fontFamily: 'Avenir',
        lineHeight: '127.6%',
        fontWeight: 800,
        ...css[type || 'Section'],
      }}
      {...otherProps}
    >
      {capitalizedString}
    </div>
  );
};

export default ScottTitle;
