import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { Button, Icon, Popconfirm, Spin } from 'antd';

import { sizes } from '~/styles';
import { EditableEntity, Edits } from '~/hooks/useEditableEntity';

function EditControls<TEntity extends EditableEntity>({
  updateMutation,
  deleteMutation,
  edits,
  clearEdits,
  afterDeleteLocation,
  disableDelete,
  beforeUpdate,
  extraControls,
}: {
  updateMutation: any; // Unfortunately, gql documents lack a type.
  deleteMutation: any;
  edits: Edits<TEntity>;
  clearEdits: () => void;
  afterDeleteLocation: string;
  disableDelete?: boolean;
  beforeUpdate?: (edits: Edits<TEntity>) => Edits<TEntity>;
  extraControls?: React.ReactNode;
}) {
  const hasEdits = Object.keys(edits).length === 1;
  const [updateEntity, { loading: updating }] = useMutation(updateMutation);
  const [deleteEntity, { loading: deleting }] = useMutation(deleteMutation);

  const actionsDisabled = hasEdits || updating || deleting;

  return (
    <div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          '& > *': { marginLeft: sizes.Spacing.XSmall },
        }}
      >
        {extraControls}
        {(updating || deleting) && <Spin css={{ lineHeight: '1.0' }} />}
        <Button disabled={actionsDisabled} onClick={clearEdits}>
          Clear Changes
        </Button>
        <Button
          disabled={actionsDisabled}
          type='primary'
          onClick={async () => {
            let errors: any[];
            try {
              const result = await updateEntity({
                variables: beforeUpdate ? beforeUpdate(edits) : edits,
              });
              errors = result.errors;
            } catch (error) {
              errors = [error];
            }

            if (!errors) {
              clearEdits();
            }
          }}
        >
          Save Changes
        </Button>
        <Popconfirm
          placement='leftTop'
          title='Are you sure you want to delete this?'
          okText='Yes'
          cancelText='No'
          onConfirm={async () => {
            await deleteEntity({ variables: { id: edits.id } });
            window.location.href = afterDeleteLocation;
          }}
        >
          <Button type='danger' disabled={disableDelete} css={{ borderRadius: 50 }}>
            <Icon type='delete' />
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}

export default EditControls;
