import React, { useState } from 'react';
import { Select } from 'antd';
import gql from 'graphql-tag';
import * as GraphQL from '~/graphql';
import { useQuery } from '@apollo/react-hooks';
import CreditCardCell from './Cell';
import AddNewCreditCardDialog from './Dialog';

const CREATE_NEW = 'CREATE_NEW';
const CLEAR = 'CLEAR';

const CreditCardOnFileSelector = ({
  currentSelection,
  setCardOnFile,
}: {
  currentSelection?: { id: string };
  setCardOnFile({ id: string }): void;
}) => {
  const [isCreateDialogVisible, setIsCreateDialogVisible] = useState(false);

  const { data, loading } = useQuery<
    GraphQL.CreditCardOnFileSelector.Query,
    GraphQL.CreditCardOnFileSelector.Variables
  >(CreditCardOnFileSelector.query);

  return (
    <>
      <Select
        loading={loading}
        value={currentSelection ? currentSelection.id : undefined}
        onSelect={newId => {
          if (newId === CREATE_NEW) {
            setIsCreateDialogVisible(true);
          } else if (newId === CLEAR) {
            setCardOnFile(null);
          } else {
            setCardOnFile({ id: newId });
          }
        }}
        placeholder='Select a card'
      >
        {data &&
          data.creditCards.map(cc => (
            <Select.Option key={cc.id} title={`test${cc.last4}`}>
              <CreditCardCell {...cc} />
            </Select.Option>
          ))}
        <Select.Option key={CREATE_NEW} value={CREATE_NEW}>
          Add new card
        </Select.Option>
        <Select.Option key={CLEAR} value={CLEAR}>
          Clear selection
        </Select.Option>
      </Select>
      <AddNewCreditCardDialog
        isOpen={isCreateDialogVisible}
        close={() => setIsCreateDialogVisible(false)}
      />
    </>
  );
};

export default CreditCardOnFileSelector;

CreditCardOnFileSelector.query = gql`
  query CreditCardOnFileSelector {
    creditCards {
      ...CreditCardCell
    }
  }
  ${CreditCardCell.fragment}
`;
