import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useCallback, useRef } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import * as GraphQL from '~/graphql';
import useMobile from '~/hooks/useMobile';
import PaginatedList, { RowRenderer } from '~/PaginatedList';
import { borders, sizes } from '~/styles';
import UsersListRow from './Row';

interface Props {
  onClickRow: (user: { id: string }) => void;
  selectedRow: { id: string };
  setSelectedRow: (row: any) => void;
}

const UsersList = ({ selectedRow, setSelectedRow, onClickRow, ...otherProps }: Props) => {
  const [params] = useQueryParams({ search: StringParam });
  const variables = useRef(getDefaultUsersListVariables());

  _.assign(variables.current, {
    search: (otherProps as any).usersearch || params.search,
  });

  const isPhone = useMobile();

  const rowRenderer = useCallback<RowRenderer<GraphQL.UsersList.Node>>(
    (rows, { isVisible, parent, isScrolling, index, ...otherProps }) => {
      const data = rows[index];

      if (!selectedRow && !isPhone && index === 0) setSelectedRow(data);
      const isSelected = (selectedRow && selectedRow.id) === data.id;

      return (
        <UsersListRow
          index={index}
          data={data}
          isSelected={isSelected}
          onClickRow={onClickRow}
          {...otherProps}
        />
      );
    },
    [selectedRow, isPhone],
  );

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        borderRight: isPhone ? undefined : borders.divider,
        width: isPhone ? '100%' : undefined,
      }}
    >
      <PaginatedList<GraphQL.UsersList.Query, GraphQL.UsersList.Variables>
        {...otherProps}
        query={UsersList.query}
        dataKey='usersConnection'
        variables={variables.current}
        rowHeight={sizes.GRID_UNIT * 19}
        rowRenderer={rowRenderer}
      />
    </div>
  );
};

export const getDefaultUsersListVariables = () => ({
  first: 20,
  after: null,
  search: null,
  showServiceAccounts: true,
});

UsersList.query = gql`
  query UsersList(
    $first: Int
    $after: String
    $order: [UsersOrderArgs]
    $search: String
    $showServiceAccounts: Boolean
  ) {
    usersConnection(
      first: $first
      after: $after
      order: $order
      search: $search
      showServiceAccounts: $showServiceAccounts
    ) @connection(key: "usersConnection", filter: ["search"]) {
      edges {
        node {
          ...UsersListRow
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  ${UsersListRow.fragment}
`;

export default UsersList;
