import { Spinner } from '@shopify/polaris';
import React from 'react';

import { memo } from '~/react';

interface Props {
  size?: 'large' | 'small';
}

const Loading = ({ size = 'large', ...otherProps }: Props) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '-webkit-fill-available',
      height: '-webkit-fill-available',
    }}
    {...otherProps}
  >
    <Spinner size={size} color='inkLightest' />
  </div>
);

export default memo(Loading);
