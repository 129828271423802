import { Icon, Input, Select } from 'antd';
import { formatCents } from '~/formatters';
import * as GraphQL from '~/graphql';
import MoneyInput from '~/Input/Money';
import { borders, colors, sizes } from '~/styles';
const IDENTITY_FUNCTION = () => {};

const gridStyle = {
  display: 'grid',
  gridTemplateColumns: '14px 270px 147px 160px',
  gap: `${sizes.Spacing.XSmall}px`,
};

const reasonTokenDisplayStringMap: Record<GraphQL.OpsAdjustmentToken, string> = {
  [GraphQL.OpsAdjustmentToken.Reschedule]: 'Partner Payment - Reschedule',
  [GraphQL.OpsAdjustmentToken.Cancellation]: 'Partner Payment - Cancellation',
  [GraphQL.OpsAdjustmentToken.PriceDiscrepancy]: 'Partner Payment - Price Discrepancy',
  [GraphQL.OpsAdjustmentToken.EventCustomization]: 'Partner Payment - Event Customization',
  [GraphQL.OpsAdjustmentToken.PartnerOther]: 'Partner Payment - Other',
  [GraphQL.OpsAdjustmentToken.Other]: 'Other',
  [GraphQL.OpsAdjustmentToken.SupplyIssues]: 'Supply Issues',
};

const reasonTypeDisplayStringMap: Record<GraphQL.PayoutReasonType, string> = {
  [GraphQL.PayoutReasonType.OpsAdjustment]: 'Manual adjustment',
  [GraphQL.PayoutReasonType.Tax]: 'Tax',
  [GraphQL.PayoutReasonType.TeamEventPayout]: 'Event Payout',
  [GraphQL.PayoutReasonType.TeamEventRevenue]: 'Mystery revenue',
};

const partnerPayoutTokens = [
  GraphQL.OpsAdjustmentToken.Reschedule,
  GraphQL.OpsAdjustmentToken.Cancellation,
  GraphQL.OpsAdjustmentToken.PriceDiscrepancy,
  GraphQL.OpsAdjustmentToken.EventCustomization,
  GraphQL.OpsAdjustmentToken.PartnerOther,
];
type NewCostRowProps = {
  amount: GraphQL.Amount;
  reason: GraphQL.PayoutReason;
  owner: GraphQL.PayoutOwner;
  partnerId: string;
  onPayoutChange: (payout: Pick<GraphQL.Payout, 'amount' | 'reason' | 'owner'>) => void;
  onRemove?: (id: string) => void;
  onReAdd?: (id: string) => void;
  className?: string;
  pendingDelete?: boolean;
};
const NewCostRow = ({
  amount,
  reason,
  owner,
  className,
  partnerId,
  onPayoutChange = IDENTITY_FUNCTION,
  onRemove = IDENTITY_FUNCTION,
  pendingDelete = false,
}: NewCostRowProps) => {
  const discoveredReason: Partial<GraphQL.PayoutReason> = reason || {};
  const { reasonToken } = discoveredReason;
  const discoveredAmount = amount.unitCount;
  const isPartnerPayout = owner.ownerType === GraphQL.PayoutOwnerType.Partner;
  const iconStyle = pendingDelete
    ? { color: colors.Red.Red500, ':hover': { color: colors.Blue.Blue500 } }
    : { color: colors.Blue.Blue500, ':hover': { color: colors.Red.Red500 } };
  const handleReasonChange = (value: any) => {
    const isPartnerPayoutReason = partnerPayoutTokens.find(token => token === value);
    onPayoutChange({
      amount: { ...amount },
      reason: {
        reasonType: GraphQL.PayoutReasonType.OpsAdjustment,
        reasonToken: value,
      },
      owner: {
        ownerType: isPartnerPayoutReason
          ? GraphQL.PayoutOwnerType.Partner
          : GraphQL.PayoutOwnerType.Company,
        ownerToken: isPartnerPayoutReason ? partnerId : undefined,
      },
    });
  };
  const handlePayeeChange = (e: any) => {
    onPayoutChange({
      amount: { ...amount },
      reason: { ...reason },
      owner: {
        ownerType: GraphQL.PayoutOwnerType.Company,
        ownerToken: e.target.value,
      },
    });
  };
  const handleAmountChange = (amount: number) => {
    onPayoutChange({
      amount: {
        unitType: GraphQL.UnitType.CurrencyCents,
        unitToken: GraphQL.UnitToken.Usd,
        unitCount: amount * 100,
      },
      reason: { ...reason },
      owner: { ...owner },
    });
  };
  const handleToggle = () => {
    onRemove(undefined);
  };

  return (
    <div
      css={{
        ...gridStyle,
        width: '100%',
        alignItems: 'center',
        borderBottom: borders.darkDivider,
        padding: `${sizes.Spacing.Small}px`,
        transition: 'all 0.5s ease-in-out',
        backgroundColor: pendingDelete ? colors.Red.Red500_Faded : 'inherit',
      }}
      className={className}
    >
      <Icon type='minus-circle' css={{ ...iconStyle }} onClick={handleToggle} />

      <Select value={reasonToken} onChange={handleReasonChange}>
        {Object.keys(GraphQL.OpsAdjustmentToken).map(reasonToken => (
          <Select.Option value={reasonToken} key={reasonToken}>
            {reasonTokenDisplayStringMap[reasonToken]}
          </Select.Option>
        ))}
      </Select>
      {isPartnerPayout && <Input type='text' value='Partner' disabled />}
      {!isPartnerPayout && (
        <Input
          type='text'
          placeholder='Example: Amazon'
          onChange={handlePayeeChange}
          value={owner.ownerToken}
        />
      )}
      <MoneyInput
        value={discoveredAmount / 100}
        css={{
          alignSelf: 'end',
          textAlign: 'end',
          padding: '4px 11px',
          height: '32px',
        }}
        onChange={handleAmountChange}
      />
    </div>
  );
};

type ExistingCostRowProps = {
  payout: Pick<
    GraphQL.Payout,
    'reason' | 'amount' | 'owner' | 'systemGenerated' | 'id' | 'estimate'
  >;
  onRemove?: (id: string) => void;
  onReAdd?: (id: string) => void;
  className?: string;
  pendingDelete?: boolean;
};
const ExistingCostRow = ({
  payout,
  className,
  onRemove = IDENTITY_FUNCTION,
  onReAdd = IDENTITY_FUNCTION,
  pendingDelete = false,
}: ExistingCostRowProps) => {
  const discoveredReason = payout.reason;
  const { reasonToken, reasonType } = discoveredReason;
  const discoveredAmount = payout.amount.unitCount;
  const owner = payout.owner;
  const systemGenerated = (payout && payout.systemGenerated) || false;
  const estimate = (payout && payout.estimate) || false;
  const isTeamEventPayout = reasonType === GraphQL.PayoutReasonType.TeamEventPayout;
  const iconStyle = pendingDelete
    ? { color: colors.Red.Red500, ':hover': { color: colors.Blue.Blue500 } }
    : { color: colors.Blue.Blue500, ':hover': { color: colors.Red.Red500 } };
  const handleToggle = () => {
    if (pendingDelete) {
      return onReAdd(payout ? payout.id : undefined);
    } else {
      return onRemove(payout ? payout.id : undefined);
    }
  };
  const reasonDisplayString =
    reasonType == GraphQL.PayoutReasonType.OpsAdjustment
      ? reasonTokenDisplayStringMap[reasonToken]
      : reasonTypeDisplayStringMap[reasonType];

  const isPartnerPayout = owner.ownerType === GraphQL.PayoutOwnerType.Partner;
  return (
    <div
      css={{
        ...gridStyle,
        width: '100%',
        alignItems: 'center',
        borderBottom: borders.darkDivider,
        padding: `${sizes.Spacing.Small}px`,
        transition: 'all 0.5s ease-in-out',
        backgroundColor: pendingDelete ? colors.Red.Red500_Faded : colors.GreyLighter,
      }}
      className={className}
    >
      {systemGenerated && <Icon type='lock' css={{ color: colors.GreyLight }} />}
      {!systemGenerated && !pendingDelete && (
        <Icon type='minus-circle' css={{ ...iconStyle }} onClick={handleToggle} />
      )}
      {!systemGenerated && pendingDelete && (
        <Icon type='plus-circle' css={{ ...iconStyle }} onClick={handleToggle} />
      )}
      <div>
        <span
          css={{
            fontWeight: isTeamEventPayout ? 'bold' : 'inherit',
          }}
        >
          {reasonDisplayString}
        </span>
        {systemGenerated && !estimate && (
          <span css={{ color: colors.Blue500, paddingLeft: '20px' }}>Auto-generated</span>
        )}
        {estimate && (
          <span css={{ color: colors.Blue500, paddingLeft: '20px' }}>Estimated payout</span>
        )}
      </div>
      <div>{isPartnerPayout ? owner.ownerType : owner.ownerToken}</div>
      <div css={{ textAlign: 'end' }}>{formatCents(discoveredAmount)}</div>
    </div>
  );
};

export { NewCostRow, ExistingCostRow };
