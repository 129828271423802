import React from 'react';

import { Spin } from 'antd';

const FullScreenSpinner = props => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    }}
    {...props}
  >
    <Spin size='large' />
  </div>
);

export default FullScreenSpinner;
