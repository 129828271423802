import React from 'react';
import { centsToDollars } from '~/BillingReviewModal/helpers';
import * as GraphQL from '~/graphql';
import ScottTitle from '~/ScottTitle';
import { sizes } from '~/styles';
import Text from '~/Text';
import CostToCustomerRow from './CostToCustomerRow';

const minMaxGuestString = (unit: GraphQL.CostUnit, minGuest: number, maxGuest: number): string => {
  if (unit === GraphQL.CostUnit.Event) {
    return `Min ${minGuest}  —  Max ${maxGuest}`;
  } else {
    return `${minGuest}+`;
  }
};

const dollarCostPerUnitString = (
  price: number,
  unit: GraphQL.CostUnit,
  maxGuest: number,
): string => {
  if (unit === GraphQL.CostUnit.Event) {
    return `$${price / 100 / maxGuest} / person`;
  } else {
    return `$${price / 100} / person`;
  }
};

const creditCostPerPersonString = (credits: number, unit: GraphQL.CostUnit): string =>
  credits ? `${centsToDollars(credits)} credits / person` : '';

const CostToCustomerDataRow = ({
  priceBand,
  basePrice,
  id,
  i,
}: {
  priceBand: GraphQL.ExperiencePricingView.CtcPricingBands;
  basePrice: number;
  id: string;
  i: number;
}) => {
  const { cents: price, unit, credits, minUsers, maxUsers } = priceBand;
  let discount = ((basePrice - price) / basePrice) * 100;
  let suffix = '';
  // Make the discount bright pink and add a '!' after it
  // as a warning if the discount is actually worse for the customer
  if (discount < 0) {
    suffix = '!';
  }
  return (
    <CostToCustomerRow
      key={`costToCustomerBand-${id}-${i}`}
      css={{
        marginBottom: sizes.Spacing.XXSmall,
      }}
    >
      <Text
        weight='Regular'
        size='Small'
        style={{
          width: '30%',
          marginRight: sizes.Spacing.Medium,
        }}
      >
        {minMaxGuestString(unit, minUsers, maxUsers)}
      </Text>
      <Text
        style={{
          width: '30%',
          marginRight: sizes.Spacing.Medium,
        }}
        color={suffix === '!' ? 'LyftPink' : 'Black'}
        weight='Regular'
        size='Small'
      >
        {dollarCostPerUnitString(price, unit, maxUsers)}
      </Text>
      <Text
        style={{
          width: '30%',
        }}
        weight='Regular'
        size='Small'
      >
        {creditCostPerPersonString(credits, unit)}
      </Text>
    </CostToCustomerRow>
  );
};

interface Props {
  label: string;
  id: string;
  discounts?: GraphQL.VolumeDiscount[];
  priceBands?: GraphQL.ExperiencePricingView.CtcPricingBands[];
}

const CostToCustomer = ({ label, id, discounts = [], priceBands = [] }: Props) => {
  const basePrice = priceBands[0] ? priceBands[0].cents : 0;

  return (
    <>
      <ScottTitle type='SubSection'>{label}</ScottTitle>
      <CostToCustomerRow css={{ marginBottom: sizes.Spacing.Small }}>
        <ScottTitle css={{ width: '30%', marginRight: sizes.Spacing.Medium }} type='Field'>
          Guests
        </ScottTitle>
        <ScottTitle css={{ width: '30%', marginRight: sizes.Spacing.Medium }} type='Field'>
          Dollar Cost Per Unit
        </ScottTitle>
        <ScottTitle css={{ width: '30%' }} type='Field'>
          Credit Cost Per Person
        </ScottTitle>
      </CostToCustomerRow>
      {priceBands.map((priceBand, i) => (
        <CostToCustomerDataRow priceBand={priceBand} basePrice={basePrice} id={id} i={i} />
      ))}
      {discounts.map((discount, i) => (
        <CostToCustomerRow
          key={`costToCustomerDiscount-${id}-${i}`}
          css={{
            marginBottom: sizes.Spacing.XXSmall,
          }}
        >
          <Text
            weight='Demi'
            size='Small'
            style={{
              width: '30%',
              marginRight: sizes.Spacing.Medium,
            }}
          >
            {`${discount.minNumUsers}`}
          </Text>
          <Text weight='Demi' size='Small'>{`${discount.percentDiscount}%`}</Text>
        </CostToCustomerRow>
      ))}
    </>
  );
};

export default CostToCustomer;
