import React, { useEffect } from 'react';
import { InputNumber } from 'antd';
import _ from 'lodash';
import MoneyInput from '~/Input/Money';
import { centsToDollars } from '~/BillingReviewModal/helpers';
import { Spacing } from '~/styles/sizes';
import ScottSection from '~/ScottSection';
import ScottTitle from '~/ScottTitle';
import SectionTitle from './SectionTitle';

const FIELD_WIDTH = '48%';

const TieredEventPricing = ({ data, setData }) => {
  const updateData = (index: number) => (value: { minNumUsers: number; ctmCents: number }) => {
    const newData = _.map(data, (row, i: number) => (i === index ? value : row));
    setData(newData);
  };

  useEffect(() => {
    const last = _.last(data);
    if (_.size(data) === 0 || (!!_.get(last, 'minNumUsers') && !!_.get(last, 'ctmCents'))) {
      setData([...data, { minNumUsers: undefined, ctmCents: undefined }]);
    }
  }, [data]);

  return (
    <div css={{ marginTop: Spacing.Small }}>
      <SectionTitle title='Tiered Event Pricing' />
      <ScottSection>
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <ScottTitle type='Field' css={{ width: FIELD_WIDTH }}>
            Min Num Users
          </ScottTitle>
          <ScottTitle type='Field' css={{ width: FIELD_WIDTH }}>
            Cost to Mystery
          </ScottTitle>
        </div>
        {_.map(data, (row, i: number) => (
          <TieredEventPricingRow {...row} updateData={updateData(i)} />
        ))}
      </ScottSection>
    </div>
  );
};

export default TieredEventPricing;

const TieredEventPricingRow = ({ minNumUsers, ctmCents, updateData }) => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: Spacing.XSmall,
      }}
    >
      <InputNumber
        value={minNumUsers}
        onChange={value => updateData({ minNumUsers: value, ctmCents })}
        size='large'
        css={{ marginRight: Spacing.Small, width: FIELD_WIDTH }}
      />
      <MoneyInput
        value={centsToDollars(ctmCents)}
        onChange={value => {
          updateData({ minNumUsers, ctmCents: _.toNumber(value) * 100 });
        }}
        css={{ width: FIELD_WIDTH }}
      />
    </div>
  );
};
