import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { FetchMoreOptions, NetworkStatus } from 'apollo-client';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as immutable from 'object-path-immutable';

import {
  GraphQLConnectionResult,
  GraphQLConnectionVariables,
  GraphQLFiltersInput,
  GraphQLOrder,
} from './interface';
import { OrderDirection } from '~/graphql';

export type DataTableSortMap<TKeys extends string> = Partial<
  Record<TKeys, OrderDirection | undefined>
>;

export enum DataTableQueryStatus {
  /**
   * There are rows being fetched from the server.
   */
  LOADING = 'loading',
  /**
   * The most recent server request failed, and requires user intervention.
   */
  ERROR = 'error',
  /**
   * Waiting for user interaction to trigger a request for more rows.
   */
  IDLE = 'idle',
}

/**
 * Values returned by `useDataTableQuery`.
 */
export interface DataTableQueryResult<
  TRow,
  TSortKeys extends string,
  TFilters extends GraphQLFiltersInput
> {
  /**
   * The current state that the query is in.
   */
  status: DataTableQueryStatus;
  /**
   * The rows that have been loaded so far, if any.
   */
  rows: TRow[];
  /**
   * The active sorts, if any.
   */
  sorts: Record<string, OrderDirection | undefined>;
  /**
   * The active filters, if any.
   */
  filters: Partial<TFilters>;
  /**
   * The total number of rows that match the current filter(s).
   *
   * -1 if the value is currently unknown (e.g. we haven't received a response
   * yet from the server).
   */
  count: number;
  /**
   * The most recent error, if `state` is `ERROR`.
   *
   * Can be `undefined` when in `ERROR` state if there was a transport-level
   * error (e.g. connection interrupted, server emitted a 5xx, etc).
   */
  error?: { message: string };
  /**
   * Request that another page of rows be loaded.
   *
   * Does nothing if a request is already in progress.
   */
  loadPage: (retryAfterError?: boolean) => void;
  /**
   * Sets (or unsets) the sort order for a specific sort key.
   *
   * Returns whether the query changed.
   */
  setSort: (key: TSortKeys, direction?: OrderDirection) => boolean;
  /**
   * Sets (or unsets) a filter for the speciifc filter key.
   *
   * Returns whether the query changed.
   */
  setFilter: <TKey extends keyof TFilters>(key: TKey, value?: TFilters[TKey]) => boolean;
}

export function useDataTableQuery<
  TRow,
  TSortKeys extends string,
  TFilters extends GraphQLFiltersInput
>(
  /**
   * The GraphQL query document that should be used to fetch pages of data.
   */
  query: any, // Sadly, the gql function isn't properly typed.
  /**
   * Keys to walk within query results in order to reach the connection.
   */
  queryPath: string[],
  /**
   * The number of rows to load per request to the server.
   *
   * By default, we load 50 rows per page.
   */
  pageSize: number,
  /**
   * The order in which sort keys should be applied.
   */
  sortPrecedence: TSortKeys[],
  /**
   * Initial sort order.
   */
  defaultSort?: DataTableSortMap<TSortKeys>,
  /**
   * Initial filters.
   */
  defaultFilters?: Partial<TFilters>,
): DataTableQueryResult<TRow, TSortKeys, TFilters> {
  // We track sorts as a map for easy updating & comparsion, and transform it
  // into the `order` variable. (see `setSort` below).
  const [sorts, setSorts] = useState<DataTableSortMap<TSortKeys>>(defaultSort);
  const [variables, setVariables] = useState({
    first: pageSize,
    filterInput: defaultFilters,
    order: sortMapToOrder(sorts, sortPrecedence),
  });

  // Server Results
  const { data, error: apolloError, fetchMore: apolloFetchMore, networkStatus } = useQuery(query, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const { count, edges, pageInfo } = getConnection(data, queryPath);
  const rows = useMemo(() => edges.map(e => e.node) as TRow[], [edges]);

  // Derived State
  //
  // Frustratingly, Apollo updates its `networkStatus` out of sync with data
  // updates. So, if we want the status we return to be coherent, we have to
  // manage it ourselves rather than derive it from `networkStatus`.
  interface ExecutionState {
    status: DataTableQueryStatus;
    error?: { message: string };
  }
  const [executionState, reactSetExecutionState] = useState<ExecutionState>({
    status: DataTableQueryStatus.LOADING,
  });
  const executionStateRef = useRef(executionState); // See `loadPage` below for why.
  const setExecutionState = useMemo(
    () => (newExecutionState: ExecutionState) => {
      reactSetExecutionState(newExecutionState);
      executionStateRef.current = newExecutionState;
    },
    [],
  );

  // When `useQuery` is mounted – or if we change variables – it automatically
  // kicks off a query. Because we are tracking execution state ourselves, we
  // must derive our state from the Apollo state.
  //
  // Note that after this point, `loadPage` is in charge of setting state.
  const [firstFetchComplete, setFirstFetchComplete] = useState(false);
  useEffect(() => {
    if (firstFetchComplete) return;
    if (networkStatus === NetworkStatus.error) {
      setFirstFetchComplete(true);
      setExecutionState({
        status: DataTableQueryStatus.ERROR,
        error: apolloError || { message: `server error` },
      });
    } else if (networkStatus === NetworkStatus.ready) {
      setFirstFetchComplete(true);
      setExecutionState({ status: DataTableQueryStatus.IDLE });
    }
  }, [firstFetchComplete, networkStatus, apolloError]);

  // Behavior

  // NOTE: We are memoizing all of the functions below – and are extra careful
  // about the state that they reference – for a couple of reasons:
  //
  //   * We want calls to it to be valid even if a calling component is
  //     slightly out of sync (e.g. didn't update its own props).
  //
  //   * `useQuery`'s `data` values are often out of sync with the
  //     `networkStatus`.
  //
  //   * This code is a hot path; and new functions (with closures) can cost a
  //     decent bit of CPU.
  //
  // THEREFORE: Each of these functions is effectively memoized forever, and we
  // utilize refs to access state that can change over the lifetime of the
  // table.

  // Wraps `apolloFetchMore` with our state tracking (for internal use).
  const fetchMore = useMemo(() => {
    return async function fetchMore(options: FetchMoreOptions) {
      setExecutionState({ status: DataTableQueryStatus.LOADING });

      // Apollo throws network-level errors from `fetchMore`.
      try {
        const result = await apolloFetchMore(options);

        if (result.errors) {
          setExecutionState({
            status: DataTableQueryStatus.ERROR,
            error: {
              message: `GraphQL Error: ${(result.errors || []).map(e => e.message)}`,
            },
          });
        } else {
          setExecutionState({ status: DataTableQueryStatus.IDLE });
        }
      } catch (error) {
        setExecutionState({ status: DataTableQueryStatus.ERROR, error });
      }
    };
  }, [apolloFetchMore]);

  const pageInfoRef = useRef(pageInfo);
  pageInfoRef.current = pageInfo;

  // Load the next page of the current filter.
  const loadPage = useMemo(() => {
    return async function loadPage(
      retryAfterError?: boolean,
      variables?: GraphQLConnectionVariables,
    ) {
      const { status } = executionStateRef.current;
      const { hasNextPage, endCursor } = pageInfoRef.current;

      if (!hasNextPage) return;
      if (status === DataTableQueryStatus.LOADING) return;
      if (status === DataTableQueryStatus.ERROR && !retryAfterError) return;

      await fetchMore({
        variables: { ...variables, after: endCursor },
        updateQuery(previous, { fetchMoreResult }) {
          const previousEdges = getConnection(previous, queryPath).edges;
          const nextEdges = getConnection(fetchMoreResult, queryPath).edges;

          return immutable.set(
            fetchMoreResult,
            [...queryPath, 'edges'],
            [...previousEdges, ...nextEdges],
          );
        },
      });
    };
  }, [fetchMore, queryPath]);

  const sortsRef = useRef(sorts);
  sortsRef.current = sorts;
  const variablesRef = useRef(variables);
  variablesRef.current = variables;

  // Helper for updating the `order` field of the query.
  const setSort = useMemo(() => {
    return function setSort(key: TSortKeys, direction?: OrderDirection) {
      const prevVariables = variablesRef.current;
      const prevSorts = sortsRef.current;
      if (prevSorts[key] === direction) return;

      const newSorts = { ...prevSorts, [key]: direction } as DataTableSortMap<TSortKeys>;
      setSorts(newSorts);
      setVariables({ ...prevVariables, order: sortMapToOrder(newSorts, sortPrecedence) });

      // Updating order or filter variables causes the query to fully reset.
      setExecutionState({ status: DataTableQueryStatus.LOADING });
      setFirstFetchComplete(false);

      return true;
    };
  }, []);

  // Helper for updating specific filters.
  const setFilter = useMemo(() => {
    return function setFilter<TKey extends keyof TFilters>(key: TKey, value?: TFilters[TKey]) {
      const prevVariables = variablesRef.current;
      if (prevVariables.filterInput[key] === value) return;

      setVariables({
        ...prevVariables,
        filterInput: { ...prevVariables.filterInput, [key]: value },
      });

      // Updating order or filter variables causes the query to fully reset.
      setExecutionState({ status: DataTableQueryStatus.LOADING });
      setFirstFetchComplete(false);

      return true;
    };
  }, []);

  // Result

  return {
    ...executionState,
    // When we switch filters or sorts, Apollo keeps the old data around, which
    // is a bit confusing as a user.
    rows: firstFetchComplete ? rows : [],
    count: firstFetchComplete ? count : -1,
    sorts,
    filters: variables.filterInput,
    loadPage,
    setSort,
    setFilter,
  };
}

const DEFAULT_CONNECTION: GraphQLConnectionResult<any> = {
  count: -1,
  edges: [],
  pageInfo: { hasNextPage: true },
};

function getConnection(data: any, queryPath: string[]): GraphQLConnectionResult {
  if (!data) return DEFAULT_CONNECTION;
  const connection = _.get(data, queryPath) as GraphQLConnectionResult;
  if (!connection || !connection.pageInfo || !Array.isArray(connection.edges)) {
    console.error({ data, queryPath });
    throw new Error(`Expected ${queryPath.join('.')} to exist and be a valid connection`);
  }

  return connection;
}

function sortMapToOrder<TKeys extends string>(
  sortMap: DataTableSortMap<TKeys>,
  sortPrecedence: TKeys[],
) {
  const order = [] as GraphQLOrder<TKeys>[];
  for (const key of sortPrecedence) {
    if (!sortMap[key]) continue;
    order.push({ key, direction: sortMap[key] });
  }
  return order;
}
