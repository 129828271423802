import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useRef } from 'react';

import Conversation from '~/Conversation';
import * as GraphQL from '~/graphql';
import Loading from '~/Loading';
import { usePaginatedQuery } from '~/PaginatedList/helpers';
import { memo } from '~/react';

interface Props {
  selectedRow?: any;
}

const ConversationSingleUser = ({ selectedRow, ...otherProps }: Props) => {
  const variables = useRef(getDefaultConversationSingleUserVariables());

  if (selectedRow)
    _.assign(variables.current, { userIds: [_.get(selectedRow, 'id')] });

  const { data, rows, loading, paginate, hasNextPage } = usePaginatedQuery<
    GraphQL.ConversationSingleUser.Query,
    GraphQL.ConversationSingleUser.Variables,
    GraphQL.ConversationSingleUser.Node
  >(
    ConversationSingleUser.query,
    variables.current,
    'messagesConnection',
    _.isEmpty(variables.current.userIds),
  );

  if (loading) return <Loading />;

  if (_.isEmpty(variables.current.userIds) || !data || !data.users) return null;

  const toPhone = _.compact(_.map(data.users, 'phone'));

  return (
    <Conversation
      toPhone={toPhone}
      rows={rows}
      paginate={paginate}
      hasNextPage={hasNextPage}
      {...otherProps}
    />
  );
};

export const getDefaultConversationSingleUserVariables = (): any => ({
  first: 20,
  after: null,
  order: [
    {
      key: GraphQL.MessagesOrderKey.CreatedAt,
      direction: GraphQL.OrderDirection.Desc,
    },
  ],
});

ConversationSingleUser.query = gql`
  query ConversationSingleUser(
    $first: Int
    $after: String
    $order: [MessagesOrderArgs]!
    $userIds: [ID]
  ) {
    users(ids: $userIds) {
      ...ConversationUser
    }
    messagesConnection(
      first: $first
      after: $after
      order: $order
      userIds: $userIds
    ) @connection(key: "messagesConnection", filter: ["userIds"]) {
      edges {
        node {
          ...Conversation
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  ${Conversation.userFragment}
  ${Conversation.messageFragment}
`;

export default memo(ConversationSingleUser);
