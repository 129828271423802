import { Button } from 'antd';
import React, { useState } from 'react';
import TemplateDialog from './TemplateDialog';

const AddNewTemplateButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button type='primary' onClick={() => setIsOpen(true)}>
        Add Template
      </Button>
      <TemplateDialog
        title='Add Template'
        mode='create'
        onCancel={() => setIsOpen(false)}
        isOpen={isOpen}
      />
    </>
  );
};

export default AddNewTemplateButton;
