import libPhone from 'google-libphonenumber';
import { oxford, titleCase } from 'humanize-plus';
import { parsePhoneNumber } from 'libphonenumber-js';
import _ from 'lodash';
import moment, { Duration, Moment } from 'moment-timezone';
import prettyMs from 'pretty-ms';

import { Maybe } from './types';

/**
 * Money formatters
 */

export const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const formatCents = (cents: number) => moneyFormatter.format(cents / 100);
export const formatCredits = (cents: number) => _.round(cents / 100,2).toFixed(2) + 'cr';

/**
 * Phone formatters
 */

const phoneUtil = libPhone.PhoneNumberUtil.getInstance();
const PNF = libPhone.PhoneNumberFormat;

export const formatPhone = (phone?: string | null) => {
  if (!phone) return '';
  const parsedPhone = phoneUtil.parseAndKeepRawInput(phone!, 'US');
  return phoneUtil.format(parsedPhone, PNF.NATIONAL);
};

export const parsePhone = (phone: string) => parsePhoneNumber(phone, 'US').number as string;

/**
 * Address/location formatters
 */

interface PartialLocation {
  address: {
    full: string;
    neighborhood?: string | null;
  };
}

export const formatAddress = (location: PartialLocation) => {
  if (!location.address)
    return {
      line1: '',
      line2: '',
    };

  const fullAddress = location.address.full!;
  const splitAddress = fullAddress.split(', ');
  splitAddress.pop();

  const long = splitAddress.join(', ');

  const [street, city] = _.get(location, 'address.full', '')
    .split(', ')
    .slice(0, 2);
  const neighborhood = _.get(location, 'address.neighborhood');

  const isSeattle = city === 'Seattle';
  const short = _.compact([
    street,
    isSeattle ? neighborhood : undefined,
    isSeattle ? undefined : city,
  ]).join(', ');

  const [line1, ...rest] = fullAddress.split(',');
  const line2 = rest.join(',');

  return { long, short, street, city, neighborhood, line1, line2 };
};

/**
 * Date formatters
 */

const getToday = () => moment().startOf('day');

const isToday = (date: string | Date | Moment) =>
  moment(date)
    .startOf('day')
    .isSame(getToday());

const wasYesterday = (date: string | Date | Moment) =>
  moment(date)
    .startOf('day')
    .isSame(getToday().subtract(1, 'day'));

const isSameYear = (date: string | Date | Moment) => moment(date).year() === getToday().year();

export const formatTime = (date: string | Date | Moment) => moment(date).format('h:mma');

export const formatTimeRange = (start: string | Date | Moment, end: string | Date | Moment) => {
  const momentStart = moment(start);
  const momentEnd = moment(end);

  const sameAMPM = momentStart.format('a') === momentEnd.format('a');
  return `${formatHour(momentStart, !sameAMPM, true)}-${formatHour(momentEnd, true, true)}`;
};

export const formatHour = (time: moment.Moment, withAmPm?: boolean, withZeros?: boolean) => {
  return time.format(
    (time.format(':mm') === ':00' && !withZeros ? 'h' : 'h:mm') + (withAmPm ? 'a' : ''),
  );
};

export const smartFormatRelativeDate = (date: string | Date | Moment, fmt?: string) => {
  const dateMoment = moment(date);

  if (isToday(dateMoment)) return dateMoment.format('h:mma');
  if (wasYesterday(dateMoment)) return 'Yesterday';
  if (!isSameYear(dateMoment)) return dateMoment.format(fmt || 'MMM YYYY');

  return dateMoment.format(fmt || 'MMM D h:mma');
};

export const smartFormatDate = (date: string | Date | Moment) => {
  const dateMoment = moment(date);

  if (isToday(dateMoment)) return dateMoment.format('h:mma');
  if (isSameYear(dateMoment)) return dateMoment.format('M/D h:mma');

  return dateMoment.format('M/D/YY h:mma');
};

export const formatSlashDate = (date: string | Date | Moment) => moment(date).format('MM/DD/YYYY');

export const prettyDuration = (duration: Duration) => prettyMs(+duration);

/**
 * Name formatters
 */

export const formatUserFullName = ({
  firstName,
  lastName,
}: {
  firstName?: string;
  lastName?: string;
}) => _.compact([firstName, lastName]).join(' ');

type Party = Maybe<ReadonlyArray<{ firstName: Maybe<string>; lastName: Maybe<string> }>>;

export const formatMysteryPartyNames = (party: Party, maxNames = 4) =>
  oxford(_.compact(_.map(party, 'firstName')), maxNames);

export const itemizedMysteryPartyNames = (party: Party) => _.map(party, formatUserFullName);

export const fullNameOrPhone = (user: { name: Maybe<string>; phone: Maybe<string> }) =>
  _.get(user, 'name') || formatPhone(_.get(user, 'phone'));

export const firstNameOrPhone = (user: { firstName: Maybe<string>; phone: Maybe<string> }) =>
  _.get(user, 'firstName') || formatPhone(_.get(user, 'phone'));

export const oxfordList = oxford;

export const titleCaseEnum = (value?: string) => _.words(value).join(' ');

/**
 * General string formatters.
 */

export const selectedFilterItems = (items?: string[], max = 3) => {
  if (!items) return;
  const firstItems = items.slice(0, max);
  const numRemainingItems = _.size(items.slice(max));

  const remainderString = numRemainingItems ? ` +${numRemainingItems} more` : ``;

  return firstItems.join(', ') + remainderString;
};

export const fullName = (user: any) => {
  if (!user) return `(No Name)`;
  const { firstName, lastName } = user;
  return _.compact([firstName, lastName]).join(' ');
};

export const addSpacesToPascalCasedString = (input: string): string =>
  input.replace(/([A-Z])/g, ' $1').trim();

export const titleCaseFromPascalCase = (input: string): string =>
  titleCase(addSpacesToPascalCasedString(input));
