import { useMutation, useQuery } from '@apollo/react-hooks';
import { Icon, Spin, Switch } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import rangy from 'rangy';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import ConversationMessageInputEmojiIcon from '~/Conversation/Message/Input/EmojiIcon';
import ConversationMessageInputFileIcon from '~/Conversation/Message/Input/FileIcon';
import ConversationMessageInputSendIcon from '~/Conversation/Message/Input/SendIcon';
import * as GraphQL from '~/graphql';
import useContainerSize from '~/hooks/useContainerSize';
import useMobile from '~/hooks/useMobile';
import usePrevious from '~/hooks/usePrevious';
import { colors, createStyles, font, scrollbar, sizes } from '~/styles';
import EmojiPicker from './EmojiPicker/EmojiPicker';
import Popover from './EmojiPicker/Popover';

interface Props {
  toPhone: string | string[];
  onFilesSelected?: any;
  mysteryId?: string;
}

const placeholders = [
  'A hyphen (-) is NOT the same as an em dash (—)',
  'Type something clever',
  `Wouldn't it be cool if this part were automated? 😂`,
  `You could be the face of Live Ops`,
  'Make sure you have Grammarly enabled, Shane.',
  'Hey Mikey, try to say "hamburger"...',
  `Remember: the customer is rarely right`,
  `If you don't have anything nice to say...`,
  'Try to sound like a chatbot',
  `I know your real name isn't Casey`,
  `I know all your darkest secrets, Casey`,
  `Remember smarterchild?`,
  `What Would Jesus Do?`,
  `Don't type anything that Vince can't automate.`,
  `Somebody check if Brennan is still using a PC.`,
];

const ConversationMessageInput = ({ toPhone, mysteryId }: Props) => {
  const { data } = useQuery<
    GraphQL.ConversationMessageInputUser.Query,
    GraphQL.ConversationMessageInputUser.Variables
  >(ConversationMessageInput.userQuery, {
    variables: { phone: _.first(toPhone) as string },
    skip: _.size(toPhone) > 1,
  });

  const user = _.get(data, 'user');

  const [inputActive, setInputActive] = useState(false);
  const [forceSmsLoading, setForceSmsLoading] = useState(false);
  const [inputHasText, setInputHasText] = useState(false);
  const [emojiPickerIsOpen, setEmojiPickerIsOpen] = useState(false);
  const [emojiFilter, setEmojiFilter] = useState('');
  const [savedRange, setRange] = useState<RangyRange | null>(null as any);
  const [width, setWidth] = useState(200);
  const [loading, setLoading] = useState(false);
  const placeholder = useRef(_.sample(placeholders));

  const emojiPickerButton = useRef(null as any);
  const fileUploadButton = useRef(null as any);
  const userInput = useRef(null as any);
  const forceSmsToggle = useRef(null as any);
  const rootContainer = useRef(null as any);

  const showEmoji = !useMobile();
  const size = useContainerSize(rootContainer);
  const lastWidth = usePrevious(size.width);

  const forceSmsSize = useContainerSize(forceSmsToggle);

  const [sendMessage] = useMutation<
    GraphQL.ConversationMessageInput.Mutation,
    GraphQL.ConversationMessageInput.Variables
  >(ConversationMessageInput.mutation);

  const [sendGroupMessage] = useMutation<
    GraphQL.GroupConversationMessageInput.Mutation,
    GraphQL.GroupConversationMessageInput.Variables
  >(ConversationMessageInput.groupMutation);

  const [setForceSms] = useMutation<
    GraphQL.ConversationMessageInputForceSms.Mutation,
    GraphQL.ConversationMessageInputForceSms.Variables
  >(ConversationMessageInput.forceSmsMutation);

  const onChangeForceSms = async forceSms => {
    setForceSmsLoading(true);
    await setForceSms({ variables: { userId: user.id, forceSms } });
    setForceSmsLoading(false);
  };

  useEffect(() => {
    if (size.width && !lastWidth) setWidth((size.width as any) - forceSmsSize.width);
  }, [width, size.width]);

  useEffect(() => {
    emojiPickerButton.current = document.querySelector('#sc-emoji-picker-button');
  }, []);

  const trackSelection = useCallback(
    _.debounce(
      () => {
        setRange(getFirstRange());
      },
      250,
      { trailing: true },
    ),
    [setRange],
  );

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    trackSelection();
    if (ev.keyCode === 13 && !ev.shiftKey) return submitText(ev);
  };

  const handleKeyUp = (ev: any) => {
    if (!inputHasText)
      setInputHasText(!!_.size(ev.target.innerHTML) && ev.target.innerHTML !== '\n');
  };

  const submitText = async (ev: any) => {
    ev.preventDefault();

    let content = userInput.current.textContent;
    const toPhones = _.castArray(toPhone);

    setLoading(true);

    if (_.isEmpty(toPhones) || !content) return;

    if (_.size(toPhones) === 1) {
      await sendMessage({ variables: { content, toPhone: toPhones[0], mysteryId } });
    } else {
      await sendGroupMessage({ variables: { content, toPhones, mysteryId } });
    }

    setLoading(false);

    userInput.current.textContent = '';
  };

  const renderSendOrFileIcon = () =>
    inputHasText ? (
      <div css={styles.button as any}>
        <ConversationMessageInputSendIcon onClick={submitText} />
      </div>
    ) : (
      <div css={styles.button as any}>
        <ConversationMessageInputFileIcon onClick={_.noop /* showFilePicker */} />
        <input
          multiple
          type='file'
          name='files[]'
          ref={fileUploadButton}
          onChange={_.noop /* onFilesSelected */}
        />
      </div>
    );

  function getFirstRange() {
    var sel = rangy.getSelection();
    return sel.rangeCount ? sel.getRangeAt(0) : null;
  }

  const handleEmojiPicked = (emoji: any) => {
    setEmojiPickerIsOpen(false);
    if (savedRange) {
      const emojiNode = document.createTextNode(emoji);
      savedRange.insertNode(emojiNode);
      savedRange.setStartAfter(emojiNode);
      rangy.getSelection().setSingleRange(savedRange);
    }
  };

  const renderEmojiPopup = () => (
    <Popover
      isOpen={emojiPickerIsOpen}
      onClose={() => setEmojiPickerIsOpen(false)}
      onInputChange={(ev: any) => setEmojiFilter(ev.target.value)}
    >
      <EmojiPicker onEmojiPicked={handleEmojiPicked} filter={emojiFilter} />
    </Popover>
  );

  return (
    <form
      css={[
        styles.root,
        {
          backgroundColor: inputActive ? colors.White : colors.GreyLighter,
          boxShadow: inputActive ? '0px -5px 20px 0px rgba(150, 165, 190, 0.2)' : 'initial',
        },
      ]}
      ref={rootContainer}
    >
      {!!user && (
        <div
          ref={forceSmsToggle}
          css={{
            flex: 1,
            flexDirection: 'column',
            height: '100%',
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: `${sizes.Spacing.XXSmall}px ${sizes.Spacing.XSmall}px`,
            borderTop: `1px solid ${colors.GreyLighter}`,
            maxWidth: '80px',
          }}
        >
          <span css={{ display: 'block', ...font.Size.Caption, width: 'max-content' }}>
            Force SMS
          </span>
          <div>
            <Switch
              checked={!!user.forceSms}
              onChange={onChangeForceSms}
              loading={forceSmsLoading}
              size='small'
            />
          </div>
        </div>
      )}
      <div
        contentEditable
        tabIndex={0}
        role='button'
        ref={userInput}
        css={[
          styles.text,
          { width: `calc(${width}px - 70px)` },
          loading && {
            pointerEvents: 'none',
            filter: 'blur(1px) brightness(.95)',
          },
        ]}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
        onSelect={trackSelection}
        placeholder={placeholder.current}
        onFocus={() => setInputActive(true)}
        onBlur={() => setInputActive(false)}
      />
      {loading && (
        <Spin
          indicator={
            <Icon
              type='loading'
              css={{
                zIndex: 100,
                fontSize: 20,
                right: '50%',
                top: 'calc(50% - 10px)',
                position: 'absolute',
              }}
              spin
            />
          }
        />
      )}
      <div
        css={{
          right: 10,
          width: 70,
          height: '100%',
          display: 'flex',
          position: 'absolute',
          justifyContent: 'flex-end',
          'input[type="file"]': {
            display: 'none',
          },
        }}
      >
        <div css={styles.button} />
        <div css={styles.button}>
          {showEmoji && (
            <ConversationMessageInputEmojiIcon
              isActive={emojiPickerIsOpen}
              tooltip={renderEmojiPopup()}
              onClick={() => setEmojiPickerIsOpen(true)}
            />
          )}
        </div>
        {renderSendOrFileIcon()}
      </div>
    </form>
  );

  /*

  _showFilePicker() {
    this._fileUploadButton.click()
  }

  toggleEmojiPicker = (e) => {
    e.preventDefault();
    if (!this.state.emojiPickerIsOpen) {
      this.setState({ emojiPickerIsOpen: true });
    }
  }

  closeEmojiPicker = (e) => {
    if (this.emojiPickerButton.contains(e.target)) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({ emojiPickerIsOpen: false });
  }

  _onFilesSelected(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.props.onFilesSelected(event.target.files)
    }
  }
  */
};

const styles = createStyles({
  root: {
    margin: 0,
    bottom: 0,
    minHeight: 55,
    display: 'flex',
    position: 'relative',
    width: '100%',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    transition: 'background-color .2s ease,box-shadow .2s ease',
  },
  button: {
    width: 30,
    height: 55,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    button: { cursor: 'pointer' },
    ':first-of-type': { width: 40 },
  },
  text: {
    width: '100%',
    resize: 'none',
    border: 'none',
    outline: 'none',
    borderBottomLeftRadius: 10,
    boxSizing: 'content-box',
    padding: 18,
    fontSize: 15,
    fontWeight: font.FontWeight.Regular,
    lineHeight: 1.33,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    color: '#565867',
    WebkitFontSmoothing: 'antialiased',
    maxHeight: 200,
    overflow: 'scroll',
    bottom: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    display: 'block',
    ':empty:before': {
      display: 'block',
      content: 'attr(placeholder)',
      color: colors.GreyDarkish,
    },
    ...scrollbar.invisible,
  },
});

ConversationMessageInput.userQuery = gql`
  query ConversationMessageInputUser($phone: String!) {
    user(phone: $phone) {
      id
      forceSms
    }
  }
`;

ConversationMessageInput.forceSmsMutation = gql`
  mutation ConversationMessageInputForceSms($userId: ID!, $forceSms: Boolean!) {
    setForceSms(id: $userId, forceSms: $forceSms) {
      id
      forceSms
    }
  }
`;

ConversationMessageInput.fragment = gql`
  fragment ConversationMessageInput on Message {
    id
    content
    type
    user {
      id
      firstName
      mostRecentMessage {
        id
        createdAt
        content
      }
    }
    createdAt
    readAt
  }
`;

ConversationMessageInput.mutation = gql`
  mutation ConversationMessageInput($content: String!, $toPhone: String!, $mysteryId: ID) {
    sendMessage(content: $content, toPhone: $toPhone, adventureId: $mysteryId) {
      ...ConversationMessageInput
    }
  }

  ${ConversationMessageInput.fragment}
`;

ConversationMessageInput.groupMutation = gql`
  mutation GroupConversationMessageInput($content: String!, $toPhones: [String!]!, $mysteryId: ID) {
    sendGroupMessage(content: $content, toPhones: $toPhones, adventureId: $mysteryId) {
      ...ConversationMessageInput
    }
  }

  ${ConversationMessageInput.fragment}
`;

export default ConversationMessageInput;
