import { FiCalendar } from 'react-icons/fi';
import { HiTemplate, HiOutlineTag } from 'react-icons/hi';
import { MdLocalActivity, MdStore } from 'react-icons/md';

const iconMap = {
  Xperience: <MdLocalActivity />,
  Partner: <MdStore />,
  Event: <FiCalendar />,
  Template: <HiTemplate />,
  Tags: <HiOutlineTag />,
};

export default iconMap;
