import React from 'react';
import _ from 'lodash';
import { centsToDollars } from '~/BillingReviewModal/helpers';
import * as GraphQL from '~/graphql';
import Link from '~/Link';
import ScottSection from '~/ScottSection';
import { sizes } from '~/styles';
import { Spacing } from '~/styles/sizes';
import SectionTitle from '~/Supply/ExperienceProfile/Pricing/SectionTitle';
import UnitType from '~/UnitType';
import MysteryCharge from './MysteryCharge';
import PriceInput from './PriceInput';

interface Props {
  ctmPerPersonCentsMinimum: number;
  forceShowTieredEventPricing: boolean;
  partnerName: string;
  rawMysteryCharge: number;
  unitType: GraphQL.CostUnit;
  onForceShowTieredEventPricingChange: (value: boolean) => void;
  onCtmPerPersonCentsMinimumChange: (value: number) => void;
  onRawMysteryChargeChange: (value: number) => void;
  onUnitTypeChange: (value: GraphQL.CostUnit) => void;
}

const CostToMystery = ({
  ctmPerPersonCentsMinimum,
  forceShowTieredEventPricing,
  partnerName,
  rawMysteryCharge,
  unitType,
  onForceShowTieredEventPricingChange,
  onCtmPerPersonCentsMinimumChange,
  onRawMysteryChargeChange,
  onUnitTypeChange,
}: Props) => {
  return (
    <>
      <SectionTitle title='Cost to Mystery' />
      <ScottSection>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div css={{ marginRight: sizes.Spacing.XXXLarge }}>
            <MysteryCharge
              value={centsToDollars(rawMysteryCharge)}
              unit={unitType}
              onChange={onRawMysteryChargeChange}
              partnerName={partnerName}
            />
            {unitType === GraphQL.CostUnit.Event && !forceShowTieredEventPricing && (
              <Link
                css={{ marginTop: Spacing.Small }}
                onClick={() => onForceShowTieredEventPricingChange(true)}
              >
                Add tiered pricing
              </Link>
            )}
            {unitType === GraphQL.CostUnit.User && (
              <div>
                <PriceInput
                  inputDataTestId='per-person-minimum'
                  value={centsToDollars(ctmPerPersonCentsMinimum)}
                  onChange={val => onCtmPerPersonCentsMinimumChange(_.toNumber(val) * 100)}
                  title={''}
                  label={'Minimum Cost'}
                  isUserFacing={false}
                />
              </div>
            )}
          </div>
          <UnitType value={unitType} onChange={onUnitTypeChange} />
        </div>
      </ScottSection>
    </>
  );
};

export default CostToMystery;
