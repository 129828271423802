import '~/polyfills';

import * as Hooks from '@apollo/react-hooks';
import { AppProvider } from '@shopify/polaris';
import React, { Suspense } from 'react';
import * as Classic from 'react-apollo';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import AuthGate from '~/App/Gate';
import GlobalStyles from '~/App/GlobalStyles';
import AppNavigation from '~/App/Navigation';
import Routes from '~/App/Routes';
import { BreakpointProvider } from '~/hooks/useBreakpoint';
import Loading from '~/Loading';
import { getApolloClient } from '~/services/apollo';

const client = getApolloClient();

const App = () => (
  <Classic.ApolloProvider client={client}>
    <Hooks.ApolloProvider client={client}>
      <AppProvider>
        <Suspense fallback={<Loading />}>
          <AuthGate>
            <BreakpointProvider>
              <BrowserRouter>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <GlobalStyles />
                  <AppNavigation routes={<Routes />} />
                </QueryParamProvider>
              </BrowserRouter>
            </BreakpointProvider>
          </AuthGate>
        </Suspense>
      </AppProvider>
    </Hooks.ApolloProvider>
  </Classic.ApolloProvider>
);

export default App;
