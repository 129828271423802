import React from 'react';
import { Button, message, Table, Typography } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import { ParseResult } from 'papaparse';
import { useRef, useState } from 'react';
import { useApolloClient } from 'react-apollo';
import { CSVReader } from 'react-papaparse';
import { Organization, UserFromCsv } from '~/graphql';
import { colors } from '~/styles';
import { DateTime } from 'luxon';
import { css } from '@emotion/core';

interface OrgUser {
  firstName: string;
  lastName: string;
  email: string;
  managerEmail?: string;
  title?: string;
  department?: string;
}

const validateData = (data: OrgUser[]) => {
  for (const orgUser of data) {
    if (!orgUser.email || !orgUser.firstName || !orgUser.lastName) {
      message.error('Full name and email are required');
      return false;
    }
  }
  return true;
};

export const BulkUpload = ({ organization }: { organization: Organization }) => {
  const client = useApolloClient();
  const readerRef = useRef(null);
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [loading, setLoading] = useState(false);

  const clearState = () => {
    setUsersToAdd([]);
    setLoading(false);
  };

  const handleConfirm = async () => {
    setLoading(true);
    await client
      .mutate({
        mutation: AddOrgUsersFromCsv,
        variables: {
          orgId: organization.id,
          users: usersToAdd,
        },
        refetchQueries: ['OrganizationEditor'],
      })
      .then(() => {
        message.success('Members uploaded');
      })
      .catch(e => {
        message.error(e.message);
      });
    clearState();
  };

  return (
    <div>
      {organization.manualIntegrationLastUpdated && !organization.isHrisConnected && (
        <Typography style={{ marginBottom: '10px' }}>
          Last updated:{' '}
          {DateTime.fromISO(organization.manualIntegrationLastUpdated).toFormat(
            'yyyy-MM-dd hh:mm:ss',
          )}
        </Typography>
      )}
      {!_.isEmpty(usersToAdd) ? (
        <div>
          <Table
            bordered
            size='small'
            pagination={false}
            dataSource={usersToAdd}
            rowKey={guest => guest.email}
            columns={[
              {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
              },
              {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
              },
              {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
              },
              {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: 'Department',
                dataIndex: 'department',
                key: 'department',
              },
              {
                title: 'Manager',
                dataIndex: 'managerEmail',
                key: 'managerEmail',
              },
              {
                title: 'Start Date',
                dataIndex: 'startDate',
                key: 'startDate,',
              },
            ]}
          />
          <div style={{ display: 'flex', marginTop: '20px' }}>
            <Button
              key='back'
              disabled={loading}
              onClick={() => clearState()}
              style={{ marginRight: '5px' }}
            >
              Back
            </Button>
            ,
            <Button
              key='confirm'
              type='primary'
              loading={loading}
              onClick={() => {
                handleConfirm();
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      ) : (
        <CSVReader
          ref={readerRef}
          config={{
            skipEmptyLines: 'greedy',
            header: true,
          }}
          onDrop={async rawData => {
            if (rawData.length === 0) {
              return;
            }
            const data = _.map<ParseResult<any>[], OrgUser>(rawData, _.iteratee('data'));
            const header = _.first(rawData).meta.fields;
            if (validateData(data)) {
              const newUsersToAdd = [...usersToAdd];
              newUsersToAdd.push(data);
              setUsersToAdd(_.uniqBy(data, 'email'));
            } else {
              readerRef.current.removeFile();
            }
          }}
          onError={e => {
            message.error(e.error);
            setLoading(false);
          }}
          style={{
            dropArea: {
              borderColor: colors.AntdBlue,
            },
          }}
        >
          <span>Drop CSV file here or click to upload.</span>
        </CSVReader>
      )}
    </div>
  );
};

const AddOrgUsersFromCsv = gql`
  mutation AddOrgUsersFromCsv($orgId: ID!, $users: [UserFromCsv!]!) {
    addUsersFromCsv(users: $users, orgId: $orgId) {
      id
    }
  }
`;
