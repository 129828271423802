import { InputProps } from 'antd/lib/input/Input';
import _ from 'lodash';
import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { StringParam, useQueryParam } from 'use-query-params';

import usePrevious from '~/hooks/usePrevious';
import Input from '~/Input';
import { colors, sizes } from '~/styles';

interface Props extends InputProps {}

const SearchInput = ({ size = 'large', prefix, autoFocus, ...props }: Props) => {
  const [search, setSearch] = useQueryParam('search', StringParam);
  const onChangeSearch = _.debounce(setSearch, 250, { trailing: true });

  const lastSearch = usePrevious(search);

  return (
    <Input
      allowClear
      autoFocus={_.isBoolean(autoFocus) ? autoFocus : search !== lastSearch}
      autoComplete='off'
      autoCorrect='off'
      defaultValue={search}
      prefix={<FiSearch size={sizes.GRID_UNIT * 3} color={colors.Grey} />}
      onChange={e => onChangeSearch(e.target.value.trimLeft())}
      size={size}
      {...props}
    />
  );
};

export default SearchInput;
