import { DatePicker } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { DateParam, useQueryParam, ArrayParam } from 'use-query-params';
const { RangePicker } = DatePicker;

import { memo } from '~/lib/react';
import _ from 'lodash';

const DATE_FORMAT = 'dddd, MMM Do';

interface Props {
  /** note: this prop only affects the size of the initial field, not the popup */
  size?: 'small' | 'default' | 'large';
  queryParamName?: string;
  range?: boolean;
  defaultRange?: Date[];
}

const FilterDate = ({
  size = 'default',
  queryParamName = 'date',
  range,
  defaultRange,
  ...otherProps
}: Props) => {
  const config = range ? ArrayParam : DateParam;
  const [date, setDate] = useQueryParam(queryParamName, config as any);

  const serializedDefaultRange = defaultRange && !_.isEmpty(defaultRange) ? [defaultRange[0].toISOString(), defaultRange[1].toISOString()] : [];
  useEffect(() => {
    if (defaultRange && _.isEmpty(date)) setDate(serializedDefaultRange);
  }, []);

  if (range) {
    return (
      <RangePicker
        value={((date as any) || []).map(d => moment(new Date(d)))}
        onChange={dates => {
          if (_.size(dates)) return setDate([dates[0].startOf('day').toDate().toISOString(), dates[1].endOf('day').toDate().toISOString()]);
          return setDate(serializedDefaultRange);
        }}
        format={DATE_FORMAT}
        css={{
          input: {
            paddingBottom: `6px !important`,
            paddingLeft: `12px !important`,
          },
        }}
        {...otherProps}
      />
    );
  }

  return (
    <DatePicker
      size={size}
      value={date ? moment(date) : undefined}
      onChange={date => setDate(date.toDate())}
      format={DATE_FORMAT}
      css={{
        input: {
          paddingBottom: `6px !important`,
          paddingLeft: `12px !important`,
        },
      }}
      {...otherProps}
    />
  );
};

export default memo(FilterDate);
