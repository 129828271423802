// @ts-ignore
import EmojiConvertor from 'emoji-js';
import React from 'react';

import { font } from '~/styles';
import emojiData from './emojiData';

const emojiConvertor = new EmojiConvertor();
emojiConvertor.init_env();

interface Props {
  onEmojiPicked: any;
  filter: any;
}

const EmojiPicker = ({ onEmojiPicked, filter }: Props) => (
  <div
    css={{
      overflow: 'auto',
      width: '100%',
      maxHeight: 'calc(100% - 40px)',
      boxSizing: 'border-box',
      padding: 15,
    }}
  >
    {emojiData.map(category => {
      const filteredEmojis = category.emojis.filter(({ name }) =>
        name.includes(filter),
      );
      return (
        <div
          css={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
          key={category.name}
        >
          {filteredEmojis.length > 0 && (
            <div
              css={{
                margin: 5,
                fontSize: 13,
                minWidth: '100%',
                color: '#b8c3ca',
                letterSpacing: '1px',
                fontWeight: font.FontWeight.Regular,
              }}
            >
              {category.name}
            </div>
          )}
          {filteredEmojis.map(({ char, name }) => {
            return (
              <span
                key={char}
                onClick={() => onEmojiPicked(char)}
                css={{
                  margin: 5,
                  width: 30,
                  lineHeight: '30px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  verticalAlign: 'middle',
                  fontSize: 28,
                  transition:
                    'transform 60ms ease-out,-webkit-transform 60ms ease-out',
                  transitionDelay: '60ms',
                  ':hover': { transform: 'scale(1.4)' },
                }}
              >
                {char}
              </span>
            );
          })}
        </div>
      );
    })}
  </div>
);

export default EmojiPicker;
