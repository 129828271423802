import { Button, Input, message, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { createMask } from 'string-mask-jedi';
import config from '~/config';
import FilterOwners from '~/Filter/Owners';
import useMask from '~/hooks/useMask';
import TextArea from '~/Input/TextArea';
import LabeledField from '~/Labeled/Field';
import UserDropdown from '~/Search/Users/UserDropdown';
import { colors, font, sizes } from '~/styles';

import { newContract } from '../ContractModal';
import ContractOverview from '../ContractOveriew';
import { Contract, OrganizationUpdateData } from '../Edit';
import { pluralize } from 'humanize-plus';

const inputStyle = {
  height: 40,
  maxWidth: 320,
  fontSize: '16px',
};

const OverviewTab = ({
  activeContracts: contracts,
  setSelectedContract,
  setShowContractModal,
  updatedData,
  setUpdatedData,
}: {
  activeContracts: Contract[];
  setSelectedContract: (contract: Contract) => void;
  setShowContractModal: (show: boolean) => void;
  updatedData: OrganizationUpdateData;
  setUpdatedData: (data: OrganizationUpdateData) => void;
}) => (
  <>
    <Typography css={{ fontSize: '30px', fontWeight: 'bold', marginBottom: sizes.Spacing.Medium }}>
      Upcoming Events
    </Typography>
    {_.size(updatedData.upcomingEvents) ? (
      <a
        href={`${
          config.opsUrl
        }/team-event?teamEventStatus=Requested&teamEventStatus=Scheduled&teamEventStatus=InProgress&organizationId=${
          updatedData.id
        }`}
      >{`View ${_.size(updatedData.upcomingEvents)} events here`}</a>
    ) : (
      'No events in progress, scheduled or requested.'
    )}
    <Typography css={{ fontSize: '30px', fontWeight: 'bold', marginTop: sizes.Spacing.XLarge }}>
      General
    </Typography>
    <div
      css={{ display: 'flex', justifyContent: 'space-between', marginTop: sizes.Spacing.Medium }}
    >
      <LabeledField label='Name' css={{ flex: 1 }}>
        <Input
          css={inputStyle}
          value={updatedData.name}
          onChange={e =>
            setUpdatedData({
              ...updatedData,
              name: e.target.value,
            })
          }
        />
      </LabeledField>
      <LabeledField label='Internal Name' css={{ flex: 1 }}>
        <Input
          css={inputStyle}
          value={updatedData.internalName}
          onChange={e =>
            setUpdatedData({
              ...updatedData,
              internalName: e.target.value,
            })
          }
        />
      </LabeledField>
    </div>
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: sizes.Spacing.Medium,
      }}
    >
      <LabeledField label='CRM Url' css={{ flex: 1 }}>
        <Input
          css={inputStyle}
          value={updatedData.crmUrl}
          onChange={e =>
            setUpdatedData({
              ...updatedData,
              crmUrl: e.target.value,
            })
          }
        />
      </LabeledField>
      <LabeledField label='Industry' css={{ flex: 1 }}>
        <Input
          css={inputStyle}
          value={updatedData.industry}
          onChange={e => setUpdatedData({ ...updatedData, industry: e.target.value })}
        />
      </LabeledField>
    </div>
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: sizes.Spacing.Medium,
      }}
    >
      <LabeledField label='Domain' css={{ flex: 1 }}>
        <Input
          css={inputStyle}
          value={updatedData.domain}
          onChange={e =>
            setUpdatedData({
              ...updatedData,
              domain: e.target.value,
            })
          }
        />
      </LabeledField>
      <LabeledField label='Sign up path' css={{ flex: 1 }}>
        <SignUpPathInput
          css={inputStyle}
          value={updatedData.userSignUpPath}
          placeholder={`/Aviato_and_Sons`}
          onChange={e => setUpdatedData({ ...updatedData, userSignUpPath: e.target.value })}
        />
        {!!updatedData.userSignUpPath && (
          <CopyToClipboard
            text={`${config.teamsUrl}/sign-up${updatedData.userSignUpPath}`}
            onCopy={() => {
              message.success(`Copied`);
            }}
          >
            <div
              css={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 12,
                maxWidth: 320,
                marginTop: 5,
                border: `1px dashed ${colors.GreyDark}`,
                fontWeight: font.FontWeight.Demi,
                backgroundColor: colors.GreyLighter,
                padding: `1px 2px`,
                ...font.Size.Caption,
              }}
            >{`${config.teamsUrl}/sign-up${updatedData.userSignUpPath}`}</div>
          </CopyToClipboard>
        )}
      </LabeledField>
    </div>
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: sizes.Spacing.Medium,
      }}
    >
      <LabeledField label='Logo url' css={{ flex: 1 }}>
        <Input
          css={inputStyle}
          value={updatedData.logoUrl}
          onChange={e =>
            setUpdatedData({
              ...updatedData,
              logoUrl: e.target.value,
            })
          }
        />
      </LabeledField>
    </div>
    <div css={{ marginTop: sizes.Spacing.Medium }}>
      <LabeledField label='Description'>
        <TextArea
          value={updatedData.description}
          onChange={e => setUpdatedData({ ...updatedData, description: e.target.value })}
        />
      </LabeledField>
    </div>
    <div css={{ display: 'flex', marginTop: sizes.Spacing.Medium }}>
      <Typography>Mystery customer since:</Typography>
      <Typography css={{ fontWeight: 'bold', marginLeft: sizes.Spacing.Small }}>
        {new Date(updatedData.createdAt).toDateString()}
      </Typography>
    </div>
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: sizes.Spacing.Medium,
        marginBottom: sizes.Spacing.Large,
      }}
    >
      <LabeledField label='Sales point of contact' css={{ flex: 1 }}>
        <UserDropdown
          user={_.get(updatedData, 'salesPointOfContact.id')}
          setUser={newPOC =>
            setUpdatedData({
              ...updatedData,
              salesPointOfContact: newPOC ? { id: newPOC } : null,
            })
          }
          placeholder={'No point of contact'}
          query={FilterOwners.query}
        />
      </LabeledField>
      <LabeledField label='Ops account manager' css={{ flex: 1 }}>
        <UserDropdown
          user={_.get(updatedData, 'accountManager.id')}
          setUser={newAccountManager =>
            setUpdatedData({
              ...updatedData,
              accountManager: newAccountManager ? { id: newAccountManager } : null,
            })
          }
          placeholder={'No account manager'}
          query={FilterOwners.query}
        />
      </LabeledField>
      <LabeledField label='Customer success manager' css={{ flex: 1 }}>
        <UserDropdown
          user={_.get(updatedData, 'customerSuccessManager.id')}
          setUser={newCustomerSuccessManager =>
            setUpdatedData({
              ...updatedData,
              customerSuccessManager: newCustomerSuccessManager
                ? { id: newCustomerSuccessManager }
                : null,
            })
          }
          placeholder={'No customer success manager'}
          query={FilterOwners.query}
        />
      </LabeledField>
    </div>
  </>
);

// Create a mask so users don't have to remember the `/`
const urlPathMask = createMask(`/${new Array(256).fill('a').join('')}`, { a: /./ });

const SignUpPathInput = props => {
  const { value, onChange } = useMask(urlPathMask, props.onChange, props.value);

  return <Input {...props} css={inputStyle} value={value} onChange={onChange} />;
};

export default OverviewTab;
