import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { colors, font } from '~/styles';

const defaultColor = colors.AntdBlue;

interface Props {
  children: React.ReactNode;
  color?: string;
  underline?: boolean;
  onClick?: any;
  href?: string;
  to?: string | { pathname?: string; search?: string; hash?: string; state?: any };
  useReactRouterLink?: boolean;
  [key: string]: any;
}

const Link = ({
  children,
  underline,
  color = defaultColor,
  external,
  onClick,
  href,
  useReactRouterLink,
  disabled,
  fontWeight = font.FontWeight.Medium,
  ...otherProps
}: Props) => {
  if (underline === undefined) underline = true;
  const sharedStyles = {
    color,
    textDecoration: underline ? 'underline' : 'none',
    fontWeight: fontWeight,
  };
  const css = {
    ...sharedStyles,
    ':hover': { ...sharedStyles, filter: 'brightness(1.15)' },
    ':active': { ...sharedStyles, filter: 'brightness(0.95)' },
    ':visited': { ...sharedStyles, filter: 'brightness(0.85)' },
  };
  const disabledCss = {
    ...sharedStyles,
    color: colors.Grey,
    textDecoration: 'none',
  };

  if (useReactRouterLink)
    return (
      <ReactRouterLink to={href} css={css} {...otherProps}>
        {children}
      </ReactRouterLink>
    );

  return (
    <a
      css={!disabled ? css : disabledCss}
      onClick={ev => {
        if (!href) ev.preventDefault();
        if (onClick) onClick(ev);
      }}
      href={href}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </a>
  );
};

export default Link;
