import React from 'react';
import AnimateHeight from 'react-animate-height';

import useMobile from '~/hooks/useMobile';
import usePersistedState from '~/hooks/usePersistedState';
import { memo } from '~/react';
import { borders, sizes } from '~/styles';

interface Props {
  filtersetKey: string;
  children: React.ReactNode;
  css?: any;
  horizontalPadding?: number;
}

const FiltersBar = ({
  filtersetKey,
  horizontalPadding = sizes.Spacing.Small,
  children,
  ...props
}: Props) => {
  const [visible] = usePersistedState(filtersetKey, false);
  const isPhone = useMobile();

  const { css, ...otherProps } = props;

  return (
    <AnimateHeight
      animateOpacity
      duration={700}
      height={visible ? 'auto' : 0}
      easing='cubic-bezier(.03,.4,.1,1.01)'
    >
      <div
        css={[
          css,
          {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: isPhone ? undefined : 'center',
            flexDirection: isPhone ? 'column' : 'row',
            padding: sizes.Spacing.XSmall,
            borderBottom: borders.divider,
            paddingLeft: horizontalPadding,
            paddingRight: horizontalPadding,
            '> *:not(:last-child)': isPhone
              ? { marginBottom: sizes.Spacing.XSmall }
              : { marginRight: sizes.Spacing.Medium },
          },
        ]}
        {...otherProps}
      >
        {children}
      </div>
    </AnimateHeight>
  );
};

export default memo(FiltersBar);
