import { Button, Icon, Popover } from 'antd';
import { Global } from '@emotion/core';
import React from 'react';

import { DataTableColumn, GraphQLFiltersInput } from './interface';
import { DataTableQueryResult } from './querying';
import { OrderDirection } from '~/graphql';
import { colors, font, sizes } from '~/styles';
import Link from '~/Link';

/**
 * Displays the table's column headers, and provides controls for sorting &
 * filtering each column.
 */
function DataTableHeader<TRow, TSortKeys extends string, TFilters extends GraphQLFiltersInput>({
  columns,
  result,
  scrollRef,
}: {
  columns: DataTableColumn<TRow, TSortKeys, TFilters>[];
  result: DataTableQueryResult<TRow, TSortKeys, TFilters>;
  scrollRef: React.MutableRefObject<HTMLDivElement>;
}) {
  return (
    <thead>
      <tr css={STYLES.root}>
        {columns.map((column, index) => (
          <DataTableHeaderCell key={index} column={column} result={result} scrollRef={scrollRef} />
        ))}
      </tr>
      <Global styles={GLOBAL_STYLES} />
    </thead>
  );
}

function DataTableHeaderCell<TRow, TSortKeys extends string, TFilters extends GraphQLFiltersInput>({
  column,
  result,
  scrollRef,
}: {
  column: DataTableColumn<TRow, TSortKeys, TFilters>;
  result: DataTableQueryResult<TRow, TSortKeys, TFilters>;
  scrollRef: React.MutableRefObject<HTMLDivElement>;
}) {
  const { header, width, sortKey, filter } = column;
  const hasControls = sortKey || filter;
  const controlsActive =
    result.sorts[sortKey] !== undefined || result.filters[filter && filter.field] !== undefined;
  const content = header;

  return (
    <th css={{ minWidth: width }}>
      <div css={STYLES.content}>
        {hasControls && (
          <Popover
            css={STYLES.columnWithControls}
            content={
              <DataTableFilterPopover column={column} result={result} scrollRef={scrollRef} />
            }
            placement='bottomLeft'
            trigger='click'
            overlayClassName='datatable-popover'
            align={{
              // Shift left by antd's popover padding
              // And down by our header cell padding
              offset: [-16, sizes.Spacing.XSmall],
            }}
          >
            <span css={STYLES.cellTitle}>{content}</span>
            <span css={STYLES.cellIconWrapper}>
              <Icon
                type='control'
                style={{ color: controlsActive ? colors.AntdBlue : undefined }}
              />
            </span>
          </Popover>
        )}
        {!hasControls && content}
      </div>
    </th>
  );
}

function DataTableFilterPopover<
  TRow,
  TSortKey extends string,
  TFilters extends GraphQLFiltersInput
>({
  column: { sortKey, filter },
  result: { sorts, filters, setSort, setFilter },
  scrollRef,
}: {
  column: DataTableColumn<TRow, TSortKey, TFilters>;
  result: DataTableQueryResult<TRow, TSortKey, TFilters>;
  scrollRef: React.MutableRefObject<HTMLDivElement>;
}) {
  let sortText: string;
  if (!sorts[sortKey]) {
    sortText = 'none';
  } else if (sorts[sortKey] === OrderDirection.Asc) {
    sortText = 'ascending';
  } else if (sorts[sortKey] === OrderDirection.Desc) {
    sortText = 'descending';
  }

  function toggleSort() {
    let newDirection: OrderDirection | undefined;
    if (!sorts[sortKey]) {
      newDirection = OrderDirection.Asc;
    } else if (sorts[sortKey] === OrderDirection.Asc) {
      newDirection = OrderDirection.Desc;
    } else {
      newDirection = undefined;
    }

    if (setSort(sortKey, newDirection)) {
      scrollRef.current.scrollTo({ top: 0 });
    }
  }

  function onFilterChanged(newValue: any) {
    if (setFilter(filter.field, newValue)) {
      scrollRef.current.scrollTo({ top: 0 });
    }
  }

  function clear() {
    if (sortKey) {
      setSort(sortKey, undefined);
    }
    if (filter) {
      setFilter(filter.field, undefined);
    }
  }

  return (
    <div css={STYLES.popoverBody}>
      <div css={STYLES.popoverTitle}>{(filter && filter.title) || 'Options'}</div>
      {filter && (
        <div>{filter.component({ value: filters[filter.field], onChange: onFilterChanged })}</div>
      )}
      {sortKey && (
        <div css={STYLES.popoverSort}>
          <Icon type='ordered-list' /> Sort:{' '}
          <Link onClick={toggleSort} underline={false}>
            {sortText}
          </Link>
        </div>
      )}

      <div css={STYLES.popoverFooter}>
        <Link onClick={clear} color={colors.Silver}>
          Clear Options
        </Link>
      </div>
    </div>
  );
}

// We have to use global styles because antd only lets us supply styles
// for the root div, or to supply a class name (the route we take).
const GLOBAL_STYLES = {
  '.datatable-popover': {
    paddingTop: 0,
    '& .ant-popover-arrow': {
      display: 'none',
    },
    '& .ant-popover-inner': {
      border: '1px solid #dddddd',
    },
  },
} as const;

const STYLES = {
  root: {
    '& th': {
      position: 'sticky',
      top: 0,
      backgroundColor: '#ffffff',
      // table borders are tricky; so we fake it.
      boxShadow: `0px -1px ${colors.GreyLight} inset`,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  cellTitle: {
    whiteSpace: 'nowrap',
  },
  cellIconWrapper: {
    paddingLeft: sizes.Spacing.XXSmall,
    flex: 1,
    lineHeight: 0,
  },
  columnWithControls: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  },
  popoverTitle: {
    ...font.Size.Subheading,
    color: colors.Silver,
    marginBottom: sizes.Spacing.XSmall,
  },
  popoverBody: {
    ...font.Size.SmallBody,
    width: 200,
  },
  popoverSort: {
    userSelect: 'none',
    marginTop: sizes.Spacing.Small,
  },
  popoverFooter: {
    marginTop: sizes.Spacing.Large,
  },
} as const;

export default DataTableHeader;
