import gql from 'graphql-tag';
import _ from 'lodash';
import React from 'react';
import { useQuery } from 'react-apollo';
import { memo } from '~/react';
import MultiSelectFilter from './MultiSelect';

const FilterRegion = () => {
  const { data } = useQuery(FilterRegion.query);
  if (data) {
    return (
      <MultiSelectFilter
        title='Region'
        name='region'
        options={_.get(data, 'getServiceRegions').map(r => ({
          value: r.id,
          label: r.name,
        }))}
        defaultValues={[]}
      />
    );
  }
  return null;
};

FilterRegion.query = gql`
  query AdventureRegions {
    getServiceRegions {
      id
      name
    }
  }
`;

export default memo(FilterRegion);
