import { Input as AntInput, message, Modal, Select, Checkbox, InputNumber } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useMutation } from 'react-apollo';
import { centsToDollars } from '~/BillingReviewModal/helpers';
import * as GraphQL from '~/graphql';
import {
  Cost,
  CostUnit,
  EditTemplate,
  MysteryTemplatesList,
  MysteryType,
  NewTemplate,
  PricingBand,
  GenericReferenceInput,
} from '~/graphql';
import { getPriceForBand } from '~/helpers';
import Input from '~/Input';
import LabeledField from '~/Labeled/Field';
import LabeledSwitch from '~/Input/LabeledSwitch';
import SelectXperienceCategories from '~/Input/SelectXperienceCategories';
import SelectTemplateTheme from '~/Input/SelectTemplateTheme';
import Link from '~/Link';
import Row from '~/Row';
import SectionTitle from '~/scenes/Supply/ExperienceProfile/Pricing/SectionTitle';
import ScottSection from '~/ScottSection';
import { recursivelyRemoveTypenames } from '~/services/apollo';
import { sizes } from '~/styles';
import { Spacing } from '~/styles/sizes';
import Discounts from '~/Supply/ExperienceProfile/Pricing/Discounts';
import {
  costMissingKeys,
  discountTransforms,
  priceTransforms,
  requiredCostKeys,
  VerticalDivide,
} from '~/Supply/ExperienceProfile/Pricing/Edit';
import MysteryCharge from '~/Supply/ExperienceProfile/Pricing/MysteryCharge';
import PriceBands from '~/Supply/ExperienceProfile/Pricing/PriceBands';
import PriceInput from '~/Supply/ExperienceProfile/Pricing/PriceInput';
import TieredEventPricing from '~/Supply/ExperienceProfile/Pricing/TieredEventPricing';
import UnitType from '~/UnitType';
import { titleCaseFromPascalCase } from '~/formatters';

interface Props {
  isOpen: boolean;
  title: string;
  mode: 'create' | 'edit';
  onCancel(): void;
  currentValues?: MysteryTemplatesList.Node;
}

const strings = {
  code: 'Code',
  title: 'Title',
  customerCharge: 'Customer Pays Mystery',
  description: 'Description',
  photo: 'Photo URL',
  connectionFocusAreas: 'Connection Focus Areas',
  estimatedDurationMins: 'Duration (mins)',
  rangeIdealGuests: 'Ideal Guest Range',
  includesBreakoutRooms: 'Includes Breakout Rooms',
  categories: 'Category Matches',
  theme: 'Theme',
  type: 'Mystery Type',
  addCtcBand: ' Add CTC band',
  cancellationPolicyPlaceholder: "Add information regarding this experience's cancellation policy",
  agreedOnDate: 'Agreed on:',
  cancellationPolicy: 'Cancellation Policy',
  negotiation: 'Negotiation',
};

const pricePer = (unit: GraphQL.CostUnit, unitName: string = 'Event') =>
  `Price / ${unit === GraphQL.CostUnit.User ? 'Person' : unitName}`;

const TemplateDialog = ({ isOpen, mode, onCancel, title, currentValues }: Props) => {
  const initialState = currentValues || {
    type: undefined,
    title: undefined,
    description: undefined,
    photoUrl: undefined,
    code: undefined,
    cost: undefined,
  };
  const cost: Cost = _.get(initialState, 'cost') || {};
  const ctcPricingBands = _.get(cost, 'ctcPricingBands') || [];
  const band: PricingBand = _.nth(ctcPricingBands, 0);

  const [templateToAdd, setTemplateToAdd] = useState<NewTemplate.Variables>(initialState);
  const [selectOpen, setSelectOpen] = useState(false);
  const [unitType, setUnitType] = useState(_.get(cost, 'ctmUnit'));
  const [rawCustomerCharge, setRawCustomerCharge] = useState(
    getPriceForBand(band) || _.get(cost, 'ctcCents'),
  );
  const [rawMysteryCharge, setRawMysteryCharge] = useState(_.get(cost, 'ctmCents'));
  const [ctmPerPersonCentsMinimum, setCtmPerPersonCentsMinimum] = useState(
    cost && cost.ctmPerPersonCentsMinimum,
  );
  const [ctmDiscounts, setCtmDiscounts] = useState(
    cost && cost.ctmVolumeDiscounts ? discountTransforms.toObj(cost.ctmVolumeDiscounts) : {},
  );
  const [forceShowTieredEventPricing, setForceShowTieredEventPricing] = useState(false);
  const [ctmTieredEventPricing, setCtmTieredEventPricing] = useState(
    cost && cost.ctmTieredEventPricing ? cost.ctmTieredEventPricing : [],
  );
  const [agreedOnDate, setAgreedOnDate] = useState(cost ? moment(cost.agreedOn) : moment());
  const [cancellationPolicy, setCancellationPolicy] = useState(
    cost ? cost.cancellationPolicy : undefined,
  );
  const [ctcBands, setCtcBands] = useState<{ [key: number]: PricingBand }>(
    ctcPricingBands.length > 0 ? priceTransforms.toObj(ctcPricingBands) : {},
  );
  const setCtcBand = (id: number, newBand: GraphQL.PricingBand) => {
    setCtcBands({
      ...ctcBands,
      [id]: newBand,
    });
  };
  const removeCtcBand = (id: number) => {
    const { [id]: bandToRemove, ...bandsToKeep } = ctcBands;
    setCtcBands(bandsToKeep);
  };
  const addCtcBand = () => {
    const maxID = Math.max(...Object.keys(ctcBands).map(key => parseInt(key)));
    const nextID = maxID > 0 ? maxID + 1 : 1;
    setCtcBands({
      ...ctcBands,
      [nextID]: {
        cents: NaN,
        minUsers: NaN,
        maxUsers: undefined,
        credits: NaN,
        unit: GraphQL.CostUnit.User,
      },
    });
  };

  const [addNewTemplate, { loading: createLoading }] = useMutation<
    NewTemplate.Mutation,
    NewTemplate.Variables
  >(AddNewTemplateMutation, {
    refetchQueries: ['MysteryTemplatesList'],
  });
  const [editTemplate, { loading: editLoading }] = useMutation<
    EditTemplate.Mutation,
    EditTemplate.Variables
  >(EditTemplateMutation, {
    refetchQueries: ['MysteryTemplatesList'],
  });

  const onSelectChange = v => {
    setTemplateToAdd({ ...templateToAdd, type: v });
  };
  const makeInputProps = key => ({
    onChange: event => setTemplateToAdd({ ...templateToAdd, [key]: event.target.value }),
    value: templateToAdd[key],
  });
  const [minIdealGuests, maxIdealGuests] = templateToAdd.rangeIdealGuests || [0, 0];

  return (
    <Modal
      centered
      width={1000}
      data-testid='template-dialog-modal'
      title={title}
      visible={isOpen}
      okButtonProps={{
        disabled: !_.values(
          _.pick(templateToAdd, ['photoUrl', 'title', 'description', 'code']),
        ).every(v => !_.isEmpty(v)),
        loading: createLoading || editLoading,
        'data-testid': 'template-info-modal-ok-button',
      }}
      onCancel={onCancel}
      confirmLoading={createLoading || editLoading}
      onOk={async () => {
        const ctcPricingBands: PricingBand[] = _.filter(
          [...priceTransforms.fromObj(ctcBands)],
          band => {
            // if cents is NaN, unit can't be found, or minUser is NaN skip it
            if (
              _.isNil(_.get(band, 'unit')) ||
              _.isNaN(_.get(band, 'cents') || NaN) ||
              _.isNaN(_.get(band, 'minUsers') || NaN)
            ) {
              return false;
            }
            return true;
          },
        );
        const band = _.nth(ctcPricingBands, 0);
        if (band) {
          setRawCustomerCharge(getPriceForBand(band));
        }
        const newCost = {
          ...cost,
          ctcUnit: _.get(band, 'unit') || cost.ctcUnit || CostUnit.User,
          ctcCents: band ? getPriceForBand(band) : rawCustomerCharge,
          ctmCents: rawMysteryCharge,
          ctmUnit: unitType,
          ctcPricingBands,
          ctmVolumeDiscounts: [...discountTransforms.fromObj(ctmDiscounts)],
          ctmTieredEventPricing: _.sortBy(
            _.filter(
              ctmTieredEventPricing,
              row =>
                _.get(row, 'minNumUsers') != null &&
                _.get(row, 'ctmCents') != null &&
                _.get(row, 'minNumUsers') !== 0 &&
                _.get(row, 'ctmCents') !== 0,
            ).map(row => _.pick(row, ['minNumUsers', 'ctmCents'])),
            'minNumUsers',
          ),
          ctmPerPersonCentsMinimum,
          agreedOn: agreedOnDate.format('MM/DD/YYYY'),
          cancellationPolicy: cancellationPolicy,
        };
        const missingKeys = costMissingKeys(newCost);
        // Only skip setting costs for this template if they've set none of the
        // required keys and are not attached to a Cost already
        const skipCost = missingKeys.length === requiredCostKeys.length && _.isNil(cost.id);
        if (!skipCost && missingKeys.length > 0) {
          message.error(`Missing these fields!: ${missingKeys}`);
          return;
        }
        const template = _.omit(
          recursivelyRemoveTypenames({
            ...templateToAdd,
            cost: skipCost ? undefined : _.omit(newCost, 'id'),
          }),
          'id',
        );
        template.categories = template.categories.map(category => {
          return { id: category.id };
        });
        template.theme = template.theme ? { id: template.theme.id } : undefined;
        setTemplateToAdd(template);
        try {
          if (mode === 'create') {
            await addNewTemplate({
              variables: template,
            });
            message.success('Created new template');
            onCancel();
          } else if (mode === 'edit') {
            await editTemplate({
              variables: {
                templateId: currentValues.id,
                template: template,
              },
            });
            message.success('Updated template');
            onCancel();
          }
        } catch (error) {
          message.error(JSON.stringify(error));
        }
      }}
    >
      <Row>
        <LabeledField required label={strings.title}>
          <Input {...makeInputProps('title')} required />
        </LabeledField>
        <LabeledField required label={strings.code}>
          <Input {...makeInputProps('code')} required />
        </LabeledField>
        <LabeledField required label={strings.photo}>
          <Input {...makeInputProps('photoUrl')} required />
        </LabeledField>
        <LabeledField label={strings.type}>
          <Select
            allowClear
            filterOption
            open={selectOpen}
            onChange={onSelectChange}
            value={_.get(templateToAdd, 'type')}
            optionFilterProp='label'
            onDropdownVisibleChange={() => setSelectOpen(!selectOpen)}
            css={[{ minWidth: sizes.GRID_UNIT * 30 }]}
          >
            {_.map(Object.keys(MysteryType), (type, i) => (
              <Select.Option key={i} value={type} label={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </LabeledField>
      </Row>
      <LabeledField required label={strings.description} css={{ width: '100%' }}>
        <AntInput.TextArea {...makeInputProps('description')} rows={4} required />
      </LabeledField>
      <Row>
        <LabeledField label={strings.categories} css={{ minWidth: '100%' }}>
          <SelectXperienceCategories
            value={templateToAdd.categories || []}
            onChange={(categories: GenericReferenceInput[]) =>
              setTemplateToAdd({ ...templateToAdd, categories })
            }
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label={strings.theme} css={{ minWidth: '100%' }}>
          <SelectTemplateTheme
            value={templateToAdd.theme}
            onChange={(theme: GenericReferenceInput) =>
              setTemplateToAdd({ ...templateToAdd, theme })
            }
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label={strings.estimatedDurationMins}>
          <InputNumber
            value={templateToAdd.estimatedDurationMins}
            onChange={estimatedDurationMins =>
              setTemplateToAdd({ ...templateToAdd, estimatedDurationMins })
            }
          />
        </LabeledField>
        <LabeledField label={strings.rangeIdealGuests}>
          <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputNumber
              value={minIdealGuests}
              onChange={minIdealGuests =>
                setTemplateToAdd({
                  ...templateToAdd,
                  rangeIdealGuests: [minIdealGuests, maxIdealGuests],
                })
              }
            />
            <p css={{ margin: '0 6px' }}>-</p>
            <InputNumber
              value={maxIdealGuests}
              onChange={maxIdealGuests =>
                setTemplateToAdd({
                  ...templateToAdd,
                  rangeIdealGuests: [minIdealGuests, maxIdealGuests],
                })
              }
            />
          </div>
        </LabeledField>
        <LabeledField label={strings.includesBreakoutRooms}>
          <LabeledSwitch
            onChange={includesBreakoutRooms =>
              setTemplateToAdd({ ...templateToAdd, includesBreakoutRooms })
            }
            checkedLabel={'Yes'}
            uncheckedLabel={'No'}
            checked={templateToAdd.includesBreakoutRooms}
          />
        </LabeledField>
        <LabeledField label={strings.connectionFocusAreas}>
          {Object.values(GraphQL.ConnectionFocusArea).map(area => (
            <Checkbox
              key={area}
              css={{ marginLeft: '0 !important' }}
              checked={
                templateToAdd.connectionFocusAreas &&
                templateToAdd.connectionFocusAreas.includes(area)
              }
              onChange={event => {
                setTemplateToAdd({
                  ...templateToAdd,
                  connectionFocusAreas: event.target.checked
                    ? _.uniq([...(templateToAdd.connectionFocusAreas || []), area])
                    : _.without(templateToAdd.connectionFocusAreas, area),
                });
              }}
            >
              {titleCaseFromPascalCase(area)}
            </Checkbox>
          ))}
        </LabeledField>
      </Row>
      <SectionTitle title={strings.negotiation} />
      <ScottSection>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <MysteryCharge
              value={rawMysteryCharge ? centsToDollars(rawMysteryCharge) : null}
              unit={unitType}
              onChange={setRawMysteryCharge}
              partnerName={''}
            />
            {unitType === CostUnit.Event && !forceShowTieredEventPricing && (
              <Link
                css={{ marginTop: Spacing.Small }}
                onClick={() => setForceShowTieredEventPricing(true)}
              >
                Add tiered pricing
              </Link>
            )}
            {unitType === CostUnit.User && (
              <div>
                <PriceInput
                  inputDataTestId='per-person-minimum'
                  value={centsToDollars(ctmPerPersonCentsMinimum)}
                  onChange={val => setCtmPerPersonCentsMinimum(_.toNumber(val) * 100)}
                  title={''}
                  label={'Minimum Cost'}
                  isUserFacing={false}
                />
              </div>
            )}
          </div>
          <UnitType value={unitType} onChange={setUnitType} />
          <VerticalDivide />
          <CustomerCharge
            value={rawCustomerCharge ? centsToDollars(rawCustomerCharge) : null}
            unitType={_.get(band, 'unit') || cost.ctcUnit || CostUnit.User}
            onChange={e => setRawCustomerCharge(e)}
          />
        </div>
      </ScottSection>
      <Discounts
        title='Cost to Mystery Discounts'
        discounts={ctmDiscounts}
        setDiscounts={setCtmDiscounts}
        css={{ marginTop: sizes.Spacing.Medium }}
      />
      <PriceBands
        title='Cost to Customer Prices'
        ctcBands={ctcBands}
        agreedOnDate={agreedOnDate}
        onAgreedDateChange={setAgreedOnDate}
        onCtcBandChange={setCtcBand}
        onRemoveCtcBand={removeCtcBand}
        onAddCtcBand={addCtcBand}
      />
      {unitType === CostUnit.Event &&
        (forceShowTieredEventPricing || _.size(ctmTieredEventPricing) > 0) && (
          <TieredEventPricing data={ctmTieredEventPricing} setData={setCtmTieredEventPricing} />
        )}
    </Modal>
  );
};

const CustomerCharge = ({ unitType, value, onChange }) => (
  <PriceInput
    inputDataTestId='customer-charge-input'
    value={value}
    onChange={val => onChange(_.toNumber(val) * 100)}
    title={strings.customerCharge}
    label={pricePer(unitType)}
    isUserFacing={true}
  />
);

export const AddNewTemplateMutation = gql`
  mutation newTemplate(
    $code: String!
    $title: String!
    $description: String!
    $theme: GenericReferenceInput
    $photoUrl: String
    $orderIndex: Int
    $type: MysteryType
    $cost: CostInput
    $categories: [GenericReferenceInput]
    $rangeIdealGuests: [Int]
    $estimatedDurationMins: Int
    $includesBreakoutRooms: Boolean
    $connectionFocusAreas: [ConnectionFocusArea]
  ) {
    createMysteryTemplate(
      code: $code
      title: $title
      description: $description
      theme: $theme
      photoUrl: $photoUrl
      orderIndex: $orderIndex
      type: $type
      cost: $cost
      categories: $categories
      rangeIdealGuests: $rangeIdealGuests
      estimatedDurationMins: $estimatedDurationMins
      includesBreakoutRooms: $includesBreakoutRooms
      connectionFocusAreas: $connectionFocusAreas
    ) {
      id
    }
  }
`;

export const EditTemplateMutation = gql`
  mutation editTemplate($templateId: ID!, $template: MysteryTemplateInput!) {
    editMysteryTemplate(templateId: $templateId, template: $template) {
      id
    }
  }
`;
export default TemplateDialog;
