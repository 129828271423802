import React, { useState } from 'react';
import Input from './';
import Text from '~/Text';
import { geocodeByPlaceIdOrAddress } from '~/geo';
import { sizes } from '~/styles';
import _ from 'lodash';

const AddressInput = (props: {
  address: string;
  onChange(args: {
    address?: string;
    location?: { placeId: string; address: any; coordinates: any };
  }): void;
  setLocationValidity?(isValid: boolean): void;
}) => {
  const { address, onChange, setLocationValidity, ...otherProps } = props;
  const [nonValidatedAddress, setNonValidatedAddress] = useState(undefined);
  const [isInvalidLocation, setIsInvalidLocation] = useState(false);

  const validateAddress = async address => {
    try {
      setIsInvalidLocation(false);
      setLocationValidity && setLocationValidity(true);
      const location = await geocodeByPlaceIdOrAddress({
        address,
      });

      onChange({
        address: location.address.full,
        location: _.pick(location, ['placeId', 'address', 'coordinates']),
      });
      setNonValidatedAddress(undefined);
    } catch (error) {
      setIsInvalidLocation(true);
      setLocationValidity && setLocationValidity(false);
      onChange({ location: null });
    }
  };

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <Input
        defaultValue={address}
        value={nonValidatedAddress != null ? nonValidatedAddress : address}
        onChange={event => setNonValidatedAddress(event.currentTarget.value)}
        onBlur={() => nonValidatedAddress && validateAddress(nonValidatedAddress)}
        {...otherProps}
      />
      {isInvalidLocation && (
        <Text
          size='XSmall'
          color='Negative'
          css={{
            marginTop: sizes.Spacing.XSmall,
            marginBottom: sizes.Spacing.XXXSmall,
          }}
        >
          {'Invalid address'}
        </Text>
      )}
    </div>
  );
};

export default AddressInput;
