import _ from 'lodash';
import { useEffect, useState } from 'react';
import useForceUpdate from '~/hooks/useForceUpdate';

const useContainerSize = (ref: any) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  const forceUpdate = useForceUpdate();

  const getHeight = () => _.get(ref, 'current.clientHeight');
  const getWidth = () => _.get(ref, 'current.clientWidth');

  useEffect(() => {
    window.addEventListener('resize', forceUpdate);
    return () => window.removeEventListener('resize', forceUpdate);
  }, []);

  useEffect(() => {
    const height = getHeight();
    const width = getWidth();

    if (size.height != height || size.width != width) setSize({ height, width });
  }, [getHeight(), getWidth()]);

  return size;
};

export default useContainerSize;
