import _ from 'lodash';
import { oxford } from 'humanize-plus';

export const isPrimitive = value => !_.isObject(value);

export const isPrimitiveArray = arr =>
  _.isArray(arr) && _.every(arr, value => isPrimitive(value));

export const isArrayOfThingsWithName = arr =>
  _.isArray(arr) && _.every(arr, value => value.name);

export const isAcceptableArray = arr =>
  isPrimitiveArray(arr) || isArrayOfThingsWithName(arr);

export const transformBeforeAfterData = (data): string => {
  if (data === null || (_.isArray(data) && data.length === 0)) return '-';
  if (data === true || data === false) return JSON.stringify(data);
  if (isPrimitive(data)) return data;

  if (isPrimitiveArray(data)) return oxford(data);

  if (isArrayOfThingsWithName(data)) return oxford(data.map(({ name }) => name));

  throw new Error(
    `Unsupported data type for before/after: ${JSON.stringify(data, null, 4)}`,
  );
};
