import { Calendar, message } from 'antd';
import { sizes } from '~/styles';
import BlackoutDateCell from './BlackoutDateCell';
import moment from 'moment-timezone';
import ScottTitle from '~/ScottTitle';
import React, { useState } from 'react';
import * as GraphQL from '~/graphql';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const strings = {
  availableDates: 'Available Dates',
};

const BlackoutDateInput = (props: {
  mysteryType: GraphQL.MysteryType;
  blackoutDates: GraphQL.BlackoutDate[];
  refetch(): Promise<any>;
}) => {
  const [selectedDate, setSelectedDate] = useState<moment.Moment | undefined>(
    undefined,
  );

  const [addBlackoutDate] = useMutation<
    GraphQL.AddBlackoutDate.Mutation,
    GraphQL.AddBlackoutDate.Variables
  >(BlackoutDateInput.addMutation);

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        css={{
          margin: sizes.Spacing.Large,
          width: 400,
        }}
      >
        <ScottTitle
          css={{
            width: '100%',
            marginBottom: sizes.Spacing.XXSmall,
          }}
        >
          {strings.availableDates}
        </ScottTitle>
        <div css={{ border: '1px solid #d9d9d9', borderRadius: 4 }}>
          <Calendar
            disabledDate={current => {
              if (!current) {
                // allow empty select
                return false;
              }
              const date = moment().startOf('day');

              return (
                current.valueOf() < date.valueOf() ||
                !!props.blackoutDates.find(
                  ({ blackedOutDate }) =>
                    moment(blackedOutDate)
                      .utc()
                      .format('MMMM Do YYYY') === current.format('MMMM Do YYYY'),
                )
              );
            }}
            fullscreen={false}
            onChange={date => {
              setSelectedDate(date);
            }}
            defaultValue={selectedDate}
          />
        </div>
      </div>

      <BlackoutDateCell
        selectedDate={selectedDate}
        addBlackoutDate={async date => {
          try {
            await addBlackoutDate({
              variables: {
                blackedOutDate: date,
                reason: 'insufficient lead time',
                mysteryType: props.mysteryType,
              },
            });
            await props.refetch();
            setSelectedDate(undefined);
            message.success(
              `Successfully added ${date} to ${props.mysteryType} blackout dates`,
            );
          } catch (error) {
            message.error('Failed to add blackout date!');
          }
        }}
      />
    </div>
  );
};

BlackoutDateInput.addMutation = gql`
  mutation AddBlackoutDate(
    $blackedOutDate: DateTime!
    $mysteryType: MysteryType!
    $reason: String
  ) {
    addBlackoutDate(
      blackedOutDate: $blackedOutDate
      mysteryType: $mysteryType
      reason: $reason
    ) {
      id
      blackedOutDate
      mysteryType
      reason
    }
  }
`;

export default BlackoutDateInput;
