import gql from 'graphql-tag';
import _ from 'lodash';

export const COLOR_MAP = {
  Incomplete: '#F5222D',
  CompletedNeedsReview: '#F06F11',
  CompletedReviewed: '#52C41A',
};

const ExperienceStatus = () => {
  // Only exists for the fragment
};

export default ExperienceStatus;

ExperienceStatus.fragment = gql`
  fragment ExperienceStatus on Xperience {
    id
    status
    latestChangesHaveBeenApproved
    latestApproval {
      id
      occurredAt
      actor {
        id
        name
      }
    }
    missingRequiredFields
  }
`;
