import React from 'react';
import { Async as AsyncSelect } from 'react-select';

import { memo } from '~/react';
import { colors, createStyles, font, sizes } from '~/styles';

interface Props {
  isLoading: boolean;
  placeholder?: string;
  loadOptions: any;
  value: any;
  onChange: any;
}

const SearchMultiSelect = (props: Props) => (
  <AsyncSelect
    isMulti
    isSearchable
    isClearable={false}
    backspaceRemovesValue={false}
    classNamePrefix='react-select'
    css={SearchMultiSelect.css}
    styles={SearchMultiSelect.styles}
    {...props}
  />
);

SearchMultiSelect.css = createStyles({
  '.react-select__control': {
    outline: `0 !important`,
    borderRadius: 4,
    height: sizes.GRID_UNIT * 8,
    borderColor: '#d9d9d9',
    ':hover': {
      border: `1px solid #40a9ff`,
      ':not(.react-select__control--is-focused)': { cursor: 'pointer' },
    },
  },
  '.react-select__control--is-focused': {
    border: `1px solid #40a9ff`,
    boxShadow: `0 0 0 2px rgba(24, 144, 255, 0.2)`,
    cursor: 'text',
  },
  '.react-select__value-container': {
    padding: `0px 6px`,
  },
  '.react-select__menu': {
    zIndex: 10,
  },
});

SearchMultiSelect.styles = {
  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: '#f1f1f1',
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: colors.GreyDarkest,
    ...font.Size.Caption,
    fontWeight: font.FontWeight.Medium,
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: colors.GreyDark,
    marginRight: 3,
    marginLeft: 2,
    padding: 0,
    ':hover': {
      backgroundColor: '#e4e4e4',
    },
  }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
  menu: (styles: any) => ({
    ...styles,
    width: 'fit-content',
    minWidth: sizes.GRID_UNIT * 35,
  }),
};

export default memo(SearchMultiSelect);
