import React, { useCallback, useState } from 'react';

import useMobile from '~/hooks/useMobile';
import { memo } from '~/react';
import SideSheet from '~/SideSheet';
import { sizes } from '~/styles';

interface Props {
  sessionStorageKey: string;
  ListComponent: any;
  DetailsComponent: any;
  MobileDetailsComponent?: any;
  getMobileDetailsTitle: (selectedRow: any) => string;
}

const SplitView = ({
  sessionStorageKey,
  ListComponent,
  DetailsComponent,
  MobileDetailsComponent,
  getMobileDetailsTitle,
}: Props) => {
  const fullSessionStorageKey = `lastSplitView#${sessionStorageKey}`;
  const lastId = sessionStorage.getItem(fullSessionStorageKey);

  const [selectedRow, setSelectedRow] = useState(
    lastId ? { id: lastId } : (null as any),
  );
  const [sideSheetVisible, setSideSheetVisible] = useState(false);

  const isPhone = useMobile();

  const onClose = useCallback(() => setSideSheetVisible(false), [
    setSideSheetVisible,
  ]);

  const onClickRow = useCallback(
    (row: { id: string }) => {
      sessionStorage.setItem(fullSessionStorageKey, row.id);
      setSelectedRow(row);
      if (isPhone) setSideSheetVisible(true);
    },
    [setSelectedRow],
  );

  MobileDetailsComponent = MobileDetailsComponent || DetailsComponent;

  return isPhone ? (
    <React.Fragment>
      <ListComponent css={{ width: '100%' }} onClickRow={onClickRow} />
      <SideSheet
        onClose={onClose}
        visible={sideSheetVisible}
        title={getMobileDetailsTitle(selectedRow)}
      >
        <MobileDetailsComponent onClose={onClose} selectedRow={selectedRow} />
      </SideSheet>
    </React.Fragment>
  ) : (
    <div css={{ display: 'flex', width: '100%' }}>
      <ListComponent
        css={{ width: sizes.GRID_UNIT * 70 }}
        onClickRow={onClickRow}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
      <div css={{ width: '100%' }}>
        <DetailsComponent selectedRow={selectedRow} />
      </div>
    </div>
  );
};

export default memo(SplitView);
