import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useState } from 'react';
import * as GraphQL from '~/graphql';
import SearchMultiSelect from '~/Search/MultiSelect';

interface Props {
  title?: string;
  onChange: (data: any) => any;
  value?: GraphQL.SearchMysteryTemplates.Fragment[] | null;
}

const SearchMysteryTemplates = ({ title, value, onChange, ...otherProps }: Props) => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const handleSearchChange = async (query: string) => {
    setLoading(true);

    const data = await client.mutate<
      GraphQL.SearchMysteryTemplates.Mutation,
      GraphQL.SearchMysteryTemplates.Variables
    >({
      mutation: SearchMysteryTemplates.mutation,
      variables: { query },
    });

    setLoading(false);

    return SearchMysteryTemplates.convertIntoTags(_.get(data, 'data.searchMysteryTemplates'));
  };

  return (
    <SearchMultiSelect
      isLoading={loading}
      placeholder={strings.templates}
      loadOptions={handleSearchChange}
      value={SearchMysteryTemplates.convertIntoTags(value!)}
      onChange={onChange}
      {...otherProps}
    />
  );
};

const strings = { templates: 'Templates' };

SearchMysteryTemplates.convertIntoTags = (templates: GraphQL.SearchMysteryTemplates.Fragment[]) => {
  return _.map(templates, data => ({
    key: data.id,
    label: data.title,
    value: data.id,
    data: _.pick(data, 'id', 'title'),
  }));
};

SearchMysteryTemplates.fragment = gql`
  fragment SearchMysteryTemplates on MysteryTemplate {
    id
    title
  }
`;

SearchMysteryTemplates.mutation = gql`
  mutation SearchMysteryTemplates($query: String!) {
    searchMysteryTemplates(query: $query) {
      ...SearchMysteryTemplates
    }
  }

  ${SearchMysteryTemplates.fragment}
`;

export default SearchMysteryTemplates;
