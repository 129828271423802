import React from 'react';
import { Typography } from 'antd';
import { Organization } from '~/graphql';
import { BulkUpload } from '../BulkUpload';

export const BulkUploadTab = ({ organization }: { organization: Organization }) => {
  return (
    <>
      <Typography>The organization structure is very rigid and has a few requirements:</Typography>
      <ol>
        <li>There must only be one person at the top of the organization who has NO manager</li>
        <li>Each person, if they have a manager, must only have one manager</li>
        <li>Nobody can be their own manager</li>
        <li>
          Other free floating individuals with NO manager can exist but NOBODY ELSE can report to
          them
        </li>
      </ol>
      <Typography>Column requirements:</Typography>
      <ul>
        <li>firstName</li>
        <li>lastName</li>
        <li>
          email
          <ul>
            <li>format: Must be valid email</li>
          </ul>
        </li>
        <li>
          title
          <ul>
            <li>optional</li>
          </ul>
        </li>
        <li>
          department
          <ul>
            <li>optional</li>
          </ul>
        </li>
        <li>
          managerEmail
          <ul>
            <li>optional</li>
            <li>format: Must be valid email of someone else in the CSV</li>
          </ul>
        </li>
        <li>
          startDate
          <ul>
            <li>optional</li>
            <li>format: YYYY-MM-dd (example: 2022-12-25) </li>
          </ul>
        </li>
      </ul>
      <Typography>Example CSV: (notice the manager email is blank for the CEO)</Typography>
      <pre>
        firstName,lastName,email,title,department,managerEmail,startDate
        <br />
        John,Doe,johndoe@trymystery.com,Engineer,Engineering,leaderperson@trymystery.com,2022-12-25
        <br />
        Leader,Person,leader@trymystery.com,CEO,GnA,leaderperson@trymystery.com,,2019-01-02
      </pre>
      <BulkUpload organization={organization} />
    </>
  );
};
