import { Button, Icon, Input, Modal, Row, Select, Tag, Tooltip } from 'antd';
import _ from 'lodash';
import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { FiArrowDown, FiArrowUp, FiPlus } from 'react-icons/fi';
import Checkbox from '~/Checkbox';
import { titleCaseEnum } from '~/formatters';
import { Question, QuestionType } from '~/graphql';
import LabeledField from '~/Labeled/Field';
import Link from '~/Link';
import { colors, sizes } from '~/styles';

const ACTIVE_QUESTION_TYPES = [
  `${QuestionType.Text}`,
  `${QuestionType.Checkboxes}`,
  `${QuestionType.DropDown}`,
  `${QuestionType.Image}`,
  `${QuestionType.Scale}`,
  `${QuestionType.MultipleChoice}`,
  `${QuestionType.LimitedCharText}`,
];

const DropdownOptions = ({ question, setQuestion }) => {
  const [inputValue, setInputValue] = useState('');
  const [inputVisible, setInputVisible] = useState(false);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef({ focus: () => {} });
  const editInputRef = useRef({ focus: () => {} });

  const onRemove = removeOption =>
    setQuestion({
      ...question,
      options: [...question.options].filter(option => option !== removeOption),
    });

  const handleInputChange = e => setInputValue(e.target.value);

  const handleInputConfirm = () => {
    if (!_.size(question.options)) {
      setQuestion({
        ...question,
        options: [inputValue],
      });
    } else if (inputValue && question.options.indexOf(inputValue) === -1) {
      const options = [...question.options, inputValue];
      setQuestion({
        ...question,
        options,
      });
    }

    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = e => setEditInputValue(e.target.value);

  const handleEditInputConfirm = () => {
    const newOptions = [...question.options];
    newOptions[editInputIndex] = editInputValue;
    setQuestion({
      ...question,
      options: newOptions,
    });
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  const saveInputRef = input => {
    inputRef.current = input;
  };
  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, [saveInputRef]);

  const saveEditInputRef = input => {
    editInputRef.current = input;
  };
  useEffect(() => {
    editInputRef.current && editInputRef.current.focus();
  }, [saveEditInputRef]);

  return (
    <div css={{ display: 'flex', flexDirection: 'row', marginTop: sizes.Spacing.Small }}>
      {_.map(_.get(question, 'options', []) as string[], (tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              css={{ maxWidth: '120px' }}
              ref={saveEditInputRef}
              key={tag}
              size='small'
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }

        const isLongTag = tag.length > 20;

        const tagElem = (
          <Tag key={tag} closable onClose={() => onRemove(tag)}>
            <span
              onDoubleClick={e => {
                setEditInputIndex(index);
                setEditInputValue(tag);
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Input
          ref={saveInputRef}
          css={{ maxWidth: '120px' }}
          type='text'
          size='small'
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag
          css={{
            display: 'flex',
            background: '#fff',
            borderStyle: 'dashed',
            alignItems: 'center',
          }}
          onClick={() => {
            setInputVisible(true);
          }}
        >
          New Option <FiPlus css={{ marginLeft: sizes.Spacing.XXSmall }} />
        </Tag>
      )}
    </div>
  );
};

interface Props {
  question: Question;
  setQuestion: (q: Question) => void;
  deleteQuestion: () => void;
  reorderQuestion: (up: boolean) => void;
  index: number;
  questionCount: number;
  disabled?: boolean;
}

export const QuestionInput = forwardRef(
  (
    {
      question,
      setQuestion,
      deleteQuestion,
      reorderQuestion,
      index,
      questionCount,
      disabled,
    }: Props,
    ref: any,
  ) => {
    const [showWarning, setShowWarning] = useState(false);

    const notFirst = index > 0;
    const notLast = index < questionCount - 1;

    return (
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Modal
            title='Delete Question?'
            visible={showWarning}
            onOk={() => {
              deleteQuestion();
              setShowWarning(false);
            }}
            onCancel={() => setShowWarning(false)}
            footer={[
              <Button key='cancel' onClick={() => setShowWarning(false)}>
                Cancel
              </Button>,
              <Button
                key='delete'
                type='danger'
                onClick={() => {
                  deleteQuestion();
                  setShowWarning(false);
                }}
              >
                Delete
              </Button>,
            ]}
          >
            This question has been answered by users - do you still want to delete it?
          </Modal>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              flex: 0.02,
              fontSize: 14,
            }}
          >
            {notFirst && (
              <Link
                href='#'
                onClick={e => {
                  e.preventDefault();
                  reorderQuestion(true);
                }}
              >
                <FiArrowUp
                  css={{
                    color: colors.AntdBlue,
                  }}
                />
              </Link>
            )}
            {notLast && (
              <Link
                href='#'
                onClick={e => {
                  e.preventDefault();
                  reorderQuestion(false);
                }}
              >
                <FiArrowDown
                  css={{
                    color: colors.AntdBlue,
                  }}
                />
              </Link>
            )}
          </div>
          <LabeledField css={{ flex: 0.78 }} label='Question'>
            <Input
              disabled={disabled}
              placeholder='Question...'
              value={question.question}
              onChange={e =>
                setQuestion({
                  ...question,
                  question: e.target.value,
                })
              }
            />
          </LabeledField>
          <LabeledField css={{ flex: 0.14 }} label='Type'>
            <Select
              disabled={disabled}
              value={question.questionType}
              onChange={newType =>
                setQuestion({
                  ...question,
                  questionType: newType,
                })
              }
            >
              {Object.keys(QuestionType).map(questionType => (
                <Select.Option
                  key={`question-type-${questionType}`}
                  value={questionType}
                  disabled={!ACTIVE_QUESTION_TYPES.includes(questionType) || disabled}
                >
                  {titleCaseEnum(questionType)}
                </Select.Option>
              ))}
            </Select>
          </LabeledField>
          <Link
            href='#'
            onClick={e => {
              e.preventDefault();
              if (_.size(question.answers)) {
                setShowWarning(true);
              } else {
                deleteQuestion();
              }
            }}
            css={{
              color: colors.Negative,
              fontSize: 20,
              ':hover': {
                color: colors.Negative,
                opacity: 0.9,
              },
            }}
          >
            <Icon type='delete' />
          </Link>
        </div>
        {(question.questionType === QuestionType.DropDown ||
          question.questionType === QuestionType.MultipleChoice ||
          question.questionType === QuestionType.Checkboxes) && (
          <LabeledField css={{ marginLeft: '60px' }} label='Options'>
            <DropdownOptions question={question} setQuestion={setQuestion} />
          </LabeledField>
        )}
        {question.questionType === QuestionType.Scale && (
          <Row css={{ display: 'flex' }}>
            <LabeledField css={{ marginLeft: '60px' }} label='Scale Min'>
              <Input
                disabled={disabled}
                value={question.scaleMin}
                type='number'
                onChange={e => setQuestion({ ...question, scaleMin: Number(e.target.value) })}
              />
            </LabeledField>
            <LabeledField css={{ marginLeft: '60px' }} label='Scale Max'>
              <Input
                disabled={disabled}
                value={question.scaleMax}
                type='number'
                onChange={e => setQuestion({ ...question, scaleMax: Number(e.target.value) })}
              />
            </LabeledField>
          </Row>
        )}
        <Row css={{ display: 'flex' }}>
          <LabeledField css={{ marginLeft: '60px' }} label='Additional Options'>
            <Checkbox
              disabled={disabled}
              checked={question.required}
              onChange={e => setQuestion({ ...question, required: e.target.checked })}
            >
              Required
            </Checkbox>
            <Checkbox
              disabled={disabled}
              checked={question.requesterOnly}
              onChange={e => setQuestion({ ...question, requesterOnly: e.target.checked })}
            >
              Ask Requester Only
            </Checkbox>
          </LabeledField>
        </Row>
      </div>
    );
  },
);
