// Originally used in the Team Event editor

import { useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import * as GraphQL from '~/graphql';
import { sizes } from '~/styles';

const UserDropdown = ({
  user,
  setUser,
  placeholder,
  query,
  fullName = false,
  disabled = false,
  width = 320,
}: {
  user: string;
  setUser: (user: string | null) => void;
  placeholder: string;
  query: any;
  fullName?: boolean;
  disabled?: boolean;
  width?: number;
}) => {
  const { data, loading } = useQuery(query);

  if (loading) return null;

  const users = data!.users;

  const options = _(users)
    .map(({ id, firstName, lastName }: Partial<GraphQL.User>) => ({
      label: fullName ? `${firstName} ${lastName}` : firstName,
      value: id,
    }))
    .orderBy('label', 'asc')
    .value();

  return (
    <Select
      disabled={disabled}
      placeholder={placeholder}
      value={user}
      onChange={newUser => setUser(newUser)}
      onClear={() => setUser(null)}
      filterOption={(input, option) =>
        (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      allowClear={true}
      showSearch={true}
      css={{ width, fontSize: '12px', marginRight: sizes.Spacing.XSmall }}
      dropdownMatchSelectWidth={true}
    >
      {options.map(option => (
        <Select.Option key={`user-${option.value}`} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default UserDropdown;
