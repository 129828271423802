import { Input as AntInput } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import cleanProps from 'clean-react-props';
import React from 'react';
import Text from '~/Text';

import { font } from '~/styles';

const TextArea = React.forwardRef(
  (
    {
      size = 'large',
      defaultValue,
      allowClear,
      autoCapitalize,
      autoComplete = 'no',
      autoCorrect,
      autoFocus,
      ...otherProps
    }: InputProps,
    ref,
  ) => (
    <div>
      <AntInput.TextArea
        ref={ref}
        size={size}
        defaultValue={defaultValue}
        allowClear={allowClear}
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        autoFocus={autoFocus}
        autoSize
        {...cleanProps(otherProps)}
        css={{
          borderRadius: 4,
          fontFamily: font.FONT_FAMILY,
          input: { cursor: 'pointer', ':focus': { cursor: 'text' } },
          fontSize: size === 'large' ? `16px !important` : `14 !important`,
        }}
      />
      {otherProps.maxLength > 0 && (
        <Text size='XXSmall' color='Grey' css={{ float: 'right' }}>
          {otherProps.value ? otherProps.value.toString().length : 0}/
          {otherProps.maxLength}
        </Text>
      )}
    </div>
  ),
);

export default TextArea;
