import React from 'react';
import LabeledSection from '~/LabeledSection';
import * as GraphQL from '~/graphql';
import _ from 'lodash';
import gql from 'graphql-tag';
import { Timeline } from 'antd';
import AuditLogCell from '~/AuditLogCell';

const strings = {
  changeLog: 'Change Log',
};

const ExperienceProfileAuditLogs = (props: GraphQL.ExperienceProfileAuditLogs.Fragment) => {
  if (!props.id) return null;

  const auditLogs = _.orderBy(
    [...props.auditLogs, ..._.flatten(_.map(props.events, 'auditLogs'))],
    'occurredAt',
    'desc',
  );

  const isEmpty =
    !props.id || Object.keys(props).length === 0 || !auditLogs || auditLogs.length === 0;

  return (
    <LabeledSection
      label={strings.changeLog}
      pageLinkPath='/supply/activity'
      pageLinkText='View All'
      viewParts={[
        () => {
          return (
            <Timeline css={{ '> *:last-child': { paddingBottom: 0 } }}>
              {auditLogs.map((auditLog, i) => (
                <Timeline.Item key={`audit-log-${i}`} color='gray'>
                  <AuditLogCell
                    key={auditLog.id}
                    shouldLabelEntity={auditLog.entityType !== 'Xperience'}
                    {...auditLog}
                  />
                </Timeline.Item>
              ))}
            </Timeline>
          );
        },
      ]}
      emptyMessage={isEmpty ? 'No changes for this experience' : undefined}
      data={props}
    />
  );
};

export default ExperienceProfileAuditLogs;

ExperienceProfileAuditLogs.fragment = gql`
  fragment ExperienceProfileAuditLogs on Xperience {
    id
    auditLogs {
      ...AuditLogCell
    }
    events {
      auditLogs {
        ...AuditLogCell
      }
    }
  }
  ${AuditLogCell.fragment}
`;
