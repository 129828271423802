import { colors, font, sizes } from '~/styles';

const Heading = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      css={{
        ...font.Size.Heading,
        color: '#1960BF', // Blue 500
        fontWeight: font.FontWeight.Demi,
        marginBottom: sizes.Spacing.Small,
      }}
    >
      {children}
    </div>
  );
};

export default Heading;
