import moment, { Moment } from 'moment-timezone';

export type SomeDate = string | Date | Moment;

export const mergeDateWithTime = (dateMoment: SomeDate, timeMoment: SomeDate) => {
  dateMoment = moment(dateMoment);
  return moment(timeMoment)
    .year(dateMoment.year())
    .month(dateMoment.month())
    .date(dateMoment.date());
};

export const durationBetween = (earlier: SomeDate, later: SomeDate) =>
  moment.duration(+moment(later) - +moment(earlier));

export const getPickupTimeForStop = (stop: any) => {
  const actualDurationMin = stop.transitDurationMinutes || 0;
  const roundedDurationMin = Math.ceil(actualDurationMin / 5) * 5;
  return moment(stop.startTime).subtract(roundedDurationMin, 'minutes');
};

export const roundToNearestFiveMinutes = (date: Moment, roundUp = true) => {
  const offset = 5;
  const minutes = date.minutes();
  const roundedMinutes =
    Math[roundUp ? 'ceil' : 'floor']((minutes + 1) / offset) * offset;
  return date.clone().minutes(roundedMinutes);
};

export const momentWithoutTimezone = (date: string | Date | Moment) =>
  moment(date).subtract(Math.abs(moment(date).utcOffset()), 'minutes');

export const getDateStringFromTimestamp = (date: Moment): string =>
  moment(date).format(
    `ddd, MMM Do ${moment().year() === moment(date).year() ? '' : 'YYYY'}`,
  );
