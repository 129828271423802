import React from 'react';

import useMobileStyle from '~/hooks/useMobileStyle';
import { colors, sizes } from '~/styles';

interface Props {
  data?: any;
  isSelected?: boolean;
  children: React.ReactNode;
  onClick?: (data: any) => void;
}

const RowComponent = React.forwardRef(
  ({ data, onClick, isSelected, children, ...otherProps }: Props, ref: any) => (
    <div
      css={{
        display: 'flex',
        padding: sizes.Spacing.Small,
        borderBottom: `1px solid ${colors.DarkSnow}`,
        backgroundColor: isSelected ? colors.DarkSnow : colors.White,
        ...useMobileStyle({ backgroundColor: colors.White }),
        cursor: 'default',
        transition: 'all 0.15s ease-in-out',
        ':hover': !isSelected
          ? {
              backgroundColor: colors.Snow,
            }
          : undefined,
        ':active': {
          backgroundColor: colors.DarkSnow,
        },
      }}
      onClick={() => onClick && onClick(data)}
      ref={ref}
      {...otherProps}
    >
      {children}
    </div>
  ),
);

export default RowComponent;
