import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import Header from '~/Header';
import SearchInput from '~/Search/Input';
import * as GraphQL from '~/graphql';
import PaginatedTable from '~/PaginatedTable';
import MobileRow from './MobileRow';
import { colors, sizes } from '~/styles';
import OrganizationEditor from './Edit';
import { Button } from 'antd';
import OrganizationCreator from './Create';

const renderCustomerType = (customerType: GraphQL.CustomerType) => {
  if (customerType === GraphQL.CustomerType.Transactional) {
    return <span style={{ color: colors.Blue500 }}>{customerType}</span>;
  }
  if (customerType === GraphQL.CustomerType.EventsOnly) {
    return <span style={{ color: colors.Green }}>Events Only</span>;
  }
  if (customerType === GraphQL.CustomerType.EventsAndInsights) {
    return <span style={{ color: colors.Purple }}>🥳 Events and Insights 🥳</span>;
  }
  return customerType;
};

const getDefaultOrganizationListVariables = () => ({
  first: 40,
  after: null,
  search: null,
  order: [
    {
      key: GraphQL.OrganizationOrderKey.CreatedAt,
      direction: GraphQL.OrderDirection.Desc,
    },
  ],
});

const rowRenderer = (
  rows: GraphQL.OrganizationGridQuery.Node[],
  { index }: any,
  setSelectedOrganization,
) => <MobileRow key={index} {...rows[index]} setSelectedOrganization={setSelectedOrganization} />;

const Organization = () => {
  const [params] = useQueryParams({
    search: StringParam,
  });
  const variables = useRef(getDefaultOrganizationListVariables());
  _.assign(variables.current, {
    ...params,
    order: [
      {
        key: GraphQL.OrganizationOrderKey.InternalName,
        direction: GraphQL.OrderDirection.Asc,
      },
    ],
  });

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [createOrg, setCreateOrg] = useState(false);

  return (
    <div css={{ height: '100vh', overflow: 'hidden' }}>
      <Header
        title='Organizations'
        rightContent={
          <div css={{ display: 'flex', flexDirection: 'row', padding: '2px' }}>
            <SearchInput size='default' />
            <div css={{ padding: '10px' }} />
            <Button onClick={() => setCreateOrg(true)}>Create Organization</Button>
          </div>
        }
      />
      <PaginatedTable<GraphQL.OrganizationGridQuery.Query, GraphQL.OrganizationGridQuery.Variables>
        columns={[
          {
            key: 'internalName',
            width: sizes.GRID_UNIT * 10,
            cellRenderer: name => name,
          },
          {
            key: 'members',
            width: sizes.GRID_UNIT * 10,
            cellRenderer: members => (members ? _.size(members) : 0),
          },
          {
            key: 'contracts',
            width: sizes.GRID_UNIT * 10,
            cellRenderer: contracts =>
              _.get(
                contracts.find(
                  (contract: GraphQL.Contract) => contract.status === GraphQL.ContractStatus.Active,
                ),
                'type',
                '-',
              ),
          },
          {
            key: 'customerType',
            width: sizes.GRID_UNIT * 5,
            cellRenderer: renderCustomerType,
          },
        ]}
        variables={variables.current}
        query={Organization.query}
        listRowRenderer={(rows, props) => rowRenderer(rows, props, setSelectedOrg)}
        dataKey='organizationsConnection'
        rowHeight={sizes.GRID_UNIT * 8}
        headerHeight={sizes.GRID_UNIT * 8}
        listRowHeight={sizes.GRID_UNIT * 17}
        onRowClick={({ rowData }) => setSelectedOrg(rowData.id)}
      />
      {selectedOrg && (
        <OrganizationEditor onClose={() => setSelectedOrg(null)} orgId={selectedOrg} />
      )}
      {createOrg && <OrganizationCreator onClose={() => setCreateOrg(false)} />}
    </div>
  );
};

Organization.fragment = gql`
  fragment OrganizationGrid on Organization {
    id
    name
    internalName
    createdAt
    updatedAt
    customerType
    members: membersWithServiceAccounts {
      id
      firstName
      lastName
      organizationRole {
        id
        name
      }
    }
    contracts {
      id
      status
      type
    }
  }
`;

Organization.query = gql`
  query OrganizationGridQuery(
    $first: Int
    $after: String
    $order: [OrganizationOrderArgs]
    $search: String
  ) {
    organizationsConnection(first: $first, after: $after, order: $order, search: $search)
      @connection(key: "organizationsConnection", filter: ["search"]) {
      count
      edges {
        node {
          ...OrganizationGrid
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${Organization.fragment}
`;

export default Organization;
