import React, { useState, useEffect } from 'react';
import { Modal, message, InputNumber, TimePicker } from 'antd';
import _ from 'lodash';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import * as GraphQL from '~/graphql';
import moment from 'moment-timezone';
import Text from '~/Text';
import Row from '~/Row';
import { sizes } from '~/styles';
import Recurrance from '~/Recurrance';
import { rrulestr } from 'rrule';
import ScottTitle from '~/ScottTitle';
import ScottHr from '~/ScottHr';
import ScheduleItemCell from '~/ScheduleItemCell';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const ScheduleDialog = (props: {
  isOpen: boolean;
  close(): void;
  experienceId: string;
  existingScheduleIds: { id: string }[];
  scheduleToEdit?: GraphQL.ScheduleDialog.Fragment;
  refetchQueries: string[];
}) => {
  const {
    isOpen,
    close,
    existingScheduleIds,
    scheduleToEdit,
    refetchQueries,
  } = props;

  const [newSchedule, setNewSchedule] = useState<{
    rrule?: string;
    capacity?: number;
    startTime?: string;
    durationMins?: number;
  }>(scheduleToEdit || {});

  useEffect(() => {
    if (scheduleToEdit) setNewSchedule(scheduleToEdit);
  }, [scheduleToEdit]);

  const onClose = () => {
    close();
    setNewSchedule({});
  };

  const [updateExperience] = useMutation<
    GraphQL.ScheduleDialog.Mutation,
    GraphQL.ScheduleDialog.Variables
  >(ScheduleDialog.mutation);

  return (
    <Modal
      width='700px'
      visible={isOpen}
      title={
        !!scheduleToEdit && scheduleToEdit.id
          ? 'Editing Schedule'
          : 'Add new schedule'
      }
      okButtonProps={{
        disabled:
          newSchedule.capacity == undefined ||
          newSchedule.durationMins == undefined ||
          newSchedule.startTime == undefined ||
          newSchedule.rrule == undefined,
        'data-testid': 'add-schedule-ok-button',
      }}
      onOk={async () => {
        updateExperience({
          variables: {
            experienceId: props.experienceId,
            schedules: [
              ...existingScheduleIds,
              _.pick(newSchedule, [
                'rrule',
                'durationMins',
                'capacity',
                'startTime',
              ]), // omitting id so that this creates a new id on api (id of schedule implicitly guarantees no change)
            ] as GraphQL.ScheduleInput[],
            availabilityType: GraphQL.XperienceAvailabilityType.Schedule,
          },
          refetchQueries,
        })
          .then(() => {
            onClose();
            message.success(
              !!scheduleToEdit && scheduleToEdit.id
                ? 'Schedule edited successfully'
                : 'New schedule added',
            );
          })
          .catch(error => {
            console.error(JSON.stringify(error));
            message.error(JSON.stringify(error));
          });
      }}
      onCancel={onClose}
    >
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <ScottTitle
          data-testid='new-schedule-label'
          type='SubSection'
          css={{ opacity: 1.0 }}
        >
          New Schedule
        </ScottTitle>
        <Row justifyContent='flex-start'>
          <div css={{ width: '50%' }}>
            <Recurrance
              onChange={newRRule =>
                setNewSchedule({ ...newSchedule, rrule: newRRule })
              }
            />
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Row css={{ marginBottom: sizes.GRID_UNIT * 20 }}>
              <LabeledField label={'Start Time'}>
                <TimePicker
                  use12Hours
                  minuteStep={5}
                  css={{ width: sizes.GRID_UNIT * 28 }}
                  format='h:mm a'
                  value={
                    newSchedule.startTime
                      ? moment(newSchedule.startTime, 'HHmm')
                      : undefined
                  }
                  onChange={startTime =>
                    setNewSchedule({
                      ...newSchedule,
                      startTime: startTime.format('HHmm'),
                    })
                  }
                />
              </LabeledField>
              <LabeledField label={'Duration (mins)'}>
                <InputNumber
                  data-testid='duration-input'
                  value={newSchedule.durationMins}
                  onChange={durationMins =>
                    setNewSchedule({ ...newSchedule, durationMins })
                  }
                />
              </LabeledField>
            </Row>
            <Row>
              <LabeledField label={'Capacity'}>
                <InputNumber
                  data-testid='capacity-input'
                  value={newSchedule.capacity}
                  onChange={capacity => setNewSchedule({ ...newSchedule, capacity })}
                />
              </LabeledField>
            </Row>
          </div>
        </Row>
        {newSchedule.rrule && (
          <>
            <ScottHr marginTop={sizes.Spacing.Small} />
            <Row justifyContent='flex-start'>
              <div css={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                <ScottTitle type='SubSection' css={{ opacity: 1.0 }}>
                  Preview
                </ScottTitle>
                <div
                  css={{
                    border: `1px solid lightgray`,
                    borderRadius: '4px',
                    padding: sizes.Spacing.Small,
                    width: 'fit-content',
                  }}
                >
                  <ScheduleItemCell {...newSchedule as any} />
                </div>
              </div>
              <div css={{ display: 'flex', flexDirection: 'column' }}>
                <ScottTitle type='SubSection' css={{ opacity: 1.0 }}>
                  Events in the next month
                </ScottTitle>
                {rrulestr(newSchedule.rrule)
                  .between(
                    moment()
                      .startOf('day')
                      .toDate(),
                    moment()
                      .startOf('day')
                      .add(1, 'month')
                      .toDate(),
                  )
                  .map(date => moment(date))
                  .map(date => (
                    <Text
                      size={'Small'}
                      color='GreyDarker'
                      css={{ marginBottom: sizes.Spacing.XXSmall }}
                    >
                      {date.format('ddd, MMM Do, YYYY')}
                    </Text>
                  ))}
              </div>
            </Row>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ScheduleDialog;

ScheduleDialog.fragment = gql`
  fragment ScheduleDialog on Schedule {
    id
    rrule
    startTime
    durationMins
    capacity
  }
`;

ScheduleDialog.mutation = gql`
  mutation ScheduleDialog(
    $experienceId: ID!
    $availabilityType: XperienceAvailabilityType!
    $schedules: [ScheduleInput]!
  ) {
    updateXperience(
      id: $experienceId
      availabilityType: $availabilityType
      schedules: $schedules
    ) {
      id
      availabilityType
      schedules {
        ...ScheduleDialog
      }
    }
  }
  ${ScheduleDialog.fragment}
`;
