import gql from 'graphql-tag';
import React, { useCallback, useRef } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import * as GraphQL from '~/graphql';
import useMobile from '~/hooks/useMobile';
import PaginatedList, { RowRenderer } from '~/PaginatedList';
import { memo } from '~/react';
import { colors, sizes } from '~/styles';
import MessagesListRow from './Row';
import MessagesListUserFilter from './UserFilter';

type User = GraphQL.MessagesListRow.Fragment;

interface Props {
  onClickRow: (user: User) => void;
  selectedRow?: any;
  setSelectedRow?: any;
}

const MessagesList = ({
  selectedRow,
  setSelectedRow,
  onClickRow,
  ...otherProps
}: Props) => {
  const variables = useRef(getDefaultMessagesListVariables());
  const [search] = useQueryParam('search', StringParam);

  const isPhone = useMobile();

  const rowRenderer = useCallback<RowRenderer<User>>(
    (rows, { isVisible, parent, isScrolling, index, ...otherProps }) => {
      const data = rows[index];

      // If there isn't a selected user **and** we're the first row **and** this
      // isn't the mobile layout, select ourself!
      if (!selectedRow && !isPhone && index === 0) setSelectedRow(data);
      const isSelected = (selectedRow && selectedRow.id) === data.id;

      return (
        <MessagesListRow
          index={index}
          data={data}
          isSelected={isSelected}
          onClick={onClickRow}
          {...otherProps}
        />
      );
    },
    [selectedRow, isPhone],
  );

  Object.assign(variables.current, { search });

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid ${colors.DarkSnow}`,
      }}
    >
      <MessagesListUserFilter />
      <PaginatedList<GraphQL.MessagesList.Query, GraphQL.MessagesList.Variables>
        {...otherProps}
        query={MessagesList.query}
        dataKey='usersConnection'
        variables={variables.current}
        rowHeight={sizes.GRID_UNIT * 20}
        rowRenderer={rowRenderer}
      />
    </div>
  );
};

export const getDefaultMessagesListVariables = () => ({
  first: 20,
  after: null,
  order: [
    {
      key: GraphQL.UsersOrderKey.MostRecentMessage,
      direction: GraphQL.OrderDirection.Desc,
    },
  ],
});

MessagesList.query = gql`
  query MessagesList(
    $first: Int
    $after: String
    $search: String
    $order: [UsersOrderArgs!]!
  ) {
    usersConnection(first: $first, after: $after, search: $search, order: $order)
      @connection(key: "usersConnection", filter: ["search"]) {
      edges {
        node {
          ...MessagesListRow
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  ${MessagesListRow.fragment}
`;

export default memo(MessagesList);
