import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { Result, Tabs } from 'antd';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';

import EditableTitle from '~/EditableTitle';
import FullScreenSpinner from '~/FullScreenSpinner';
import * as GraphQL from '~/graphql';
import { Edits, useEditableEntity } from '~/hooks/useEditableEntity';
import EditControls from '~/EditControls';

import Page from '../Page';
import PartnerExperiences from './Experiences';
import PartnerAuditLogs from './AuditLogs';
import PartnerProfileDetails from './Details';

const PartnerProfile = () => {
  const { partnerId } = useParams<{ partnerId: string; tabName: string }>();

  const { data, loading, error } = useQuery<
    GraphQL.PartnerProfile.Query,
    GraphQL.PartnerProfile.Variables
  >(PartnerProfile.query, { variables: { partnerId }, fetchPolicy: 'network-only' });

  if (loading) return <FullScreenSpinner />;

  if (error) {
    return (
      <Page>
        <Result
          status={500}
          title='Error loading Partner'
          subTitle='We ran into an error when loading this partner.'
        >
          <div>Details for developers:</div>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Result>
      </Page>
    );
  }

  if (!data || !data.partner) {
    return (
      <Page>
        <Result
          status={404}
          title='Partner not found'
          subTitle="Sorry, the partner you're looking for does not exist."
        />
      </Page>
    );
  }

  return <PartnerProfileContent partner={data.partner} />;
};

const PartnerProfileContent = (props: { partner: GraphQL.PartnerProfile.Fragment }) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { tabName } = useParams<{ tabName: string }>();
  const [partner, { edits, edit, clearEdits }] = useEditableEntity(props.partner);

  const beforeUpdate = useMemo(() => {
    return function beforeUpdate(edits: Edits<GraphQL.PartnerProfile.Fragment>) {
      if (edits.owners) {
        // We only care about the ID of owners
        edits.owners = edits.owners.map(({ id }) => ({ id }));
      }
      return edits;
    };
  }, []);

  return (
    <Page breadcrumbProps={{ partner }}>
      <>
        <div css={{ display: 'flex' }}>
          <div css={{ flex: 1 }}>
            <EditableTitle
              value={{
                title: partner.name,
              }}
              onChange={({ title }) => {
                edit({ name: title });
              }}
              noDescription
            />
          </div>
          <EditControls
            updateMutation={PartnerProfile.updateMutation}
            deleteMutation={PartnerProfile.deleteMutation}
            edits={edits}
            clearEdits={clearEdits}
            afterDeleteLocation={'/supply/partners'}
            beforeUpdate={beforeUpdate}
            disableDelete={partner.experiences.length > 0}
          />
        </div>
        <Tabs
          size='large'
          activeKey={tabName || 'details'}
          css={{ flex: 1, margin: `0 -16px`, '& .ant-tabs-tabpane': { padding: `0 16px` } }}
          onChange={newActiveKey => {
            history.push(path.replace(':partnerId', partner.id).replace(':tabName', newActiveKey));
          }}
        >
          <Tabs.TabPane tab='Partner Details' key='details'>
            <PartnerProfileDetails partner={partner} onChange={edit} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Experiences' key='experiences'>
            <PartnerExperiences
              partnerId={partner.id}
              experiences={partner.experiences}
              history={history}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Change Log' key='audit'>
            <PartnerAuditLogs {...partner} />
          </Tabs.TabPane>
        </Tabs>
      </>
    </Page>
  );
};

export default PartnerProfile;

PartnerProfile.fragment = gql`
  fragment PartnerProfile on Partner {
    id
    name
    description
    experiences {
      ...PartnerExperiences
    }
    createdAt
    updatedAt
    ...PartnerProfileDetails
    ...PartnerAuditLogs
  }
  ${PartnerProfileDetails.fragment}
  ${PartnerAuditLogs.fragment}
  ${PartnerExperiences.fragment}
`;

PartnerProfile.query = gql`
  query PartnerProfile($partnerId: ID!) {
    partner(id: $partnerId) {
      ...PartnerProfile
    }
  }
  ${PartnerProfile.fragment}
`;

PartnerProfile.updateMutation = gql`
  mutation PartnerProfileUpdate(
    $id: ID!
    $description: String
    $email: String
    $location: LocationInput
    $name: String
    $notes: Markdown
    $numHosts: Int
    $permissionToRecord: Boolean
    $phone: String
    $quality: PartnerQuality
    $status: PartnerStatus
    $supportedVideoPlatforms: [PartnerVideoPlatformInput]
    $url: String
    $owners: [ReferenceUserInput]
  ) {
    updatePartner(
      id: $id
      description: $description
      email: $email
      location: $location
      name: $name
      notes: $notes
      numHosts: $numHosts
      permissionToRecord: $permissionToRecord
      phone: $phone
      quality: $quality
      status: $status
      supportedVideoPlatforms: $supportedVideoPlatforms
      url: $url
      owners: $owners
    ) {
      ...PartnerProfile
    }
  }
  ${PartnerProfile.fragment}
`;

PartnerProfile.deleteMutation = gql`
  mutation PartnerProfileDelete($id: ID!) {
    deletePartner(id: $id) {
      __typename
    }
  }
`;
