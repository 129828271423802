import { InputProps } from 'antd/lib/input/Input';
import _ from 'lodash';
import React from 'react';
import NumberFormat from 'react-number-format';
import { ExcludeKeys } from 'tsdef';

import Input from '~/Input';

type Props = {
  defaultValue?: any;
  onChange: (value: number) => void;
  value?: any;
  placeholder?: string;
  [key: string]: any;
} & ExcludeKeys<InputProps, 'onChange' | 'value' | 'defaultValue' | 'prefix' | 'type'>;

const CreditInput = (props: Props) => {
  const { onChange, defaultValue, placeholder, value, ...safeProps } = props;

  return (
    <NumberFormat
      {...safeProps as any}
      css={{ textAlign: 'right' }}
      suffix={'    credits'}
      value={value === '0.00' ? undefined : value}
      placeholder={placeholder || '0.00    credits'}
      thousandSeparator
      onBlur={() => onChange(_.toNumber(value))}
      customInput={Input}
      onValueChange={event => {
        if (event.value) onChange(_.toNumber(event.value));
      }}
    />
  );
};

export default CreditInput;
