import Button from '@atlaskit/button';
import React from 'react';

import usePersistedState from '~/hooks/usePersistedState';

interface Props {
  filtersetKey: string;
}

const FiltersBarToggle = ({ filtersetKey }: Props) => {
  const [visible, setVisible] = usePersistedState(filtersetKey, false);
  return (
    <Button
      onAuxClick={() => {}}
      onAuxClickCapture={() => {}}
      onClick={() => setVisible(!visible)}
    >
      {visible ? strings.hideFilters : strings.showFilters}
    </Button>
  );
};

const strings = {
  hideFilters: 'Hide filters',
  showFilters: 'Show filters',
};

export default FiltersBarToggle;
