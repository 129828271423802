import { Interpolation } from '@emotion/css';

import useBreakpoint from './useBreakpoint';

type CSS = Interpolation;

const useMobileStyle = (mobileCSS: CSS, desktopCSS: CSS = {}): any =>
  useBreakpoint().isPhone ? mobileCSS : desktopCSS;

export default useMobileStyle;
