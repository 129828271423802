import React from 'react';

import { sizes, colors } from '~/styles';
import Text from '~/Text';

interface Props {
  label: string | React.ReactNode;
  required?: boolean;
  children: React.ReactNode;
  requiredLabel?: string; 
}

const LabeledField = ({ label, required, requiredLabel, children, ...otherProps }: Props) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'column',
      marginBottom: sizes.Spacing.Small,
    }}
    {...otherProps}
  >
    <Text
      size='XSmall'
      weight='Medium'
      css={{
        paddingLeft: sizes.Spacing.XXXSmall,
        marginBottom: sizes.Spacing.XXXSmall,
      }}
    >
      {label}
      {required && <span css={{ color: colors.UI.Negative }}> {requiredLabel || '*'}</span>}
    </Text>
    {children}
  </div>
);

export default LabeledField;
