import { Divider, Modal, Icon } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useState } from 'react';
import { useApolloClient } from 'react-apollo';
import { FiEdit, FiTrash2 } from 'react-icons/fi';

import { formatPhone } from '~/formatters';
import * as GraphQL from '~/graphql';
import Labeled from '~/Labeled';
import { colors, sizes } from '~/styles';
import EditUserModal from '~/Users/EditUserModal';
import Link from '~/Link';

interface Props {
  data: GraphQL.UsersTabsDetails.Fragment;
}

const UsersTabsDetails = ({ data }: Props) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();

  const onDeleteUser = async () => {
    try {
      setLoading(true);
      await client.mutate({
        mutation: UsersTabsDetails.mutation,
        variables: { id: data.id },
        refetchQueries: ['UsersList'],
      });
    } finally {
      setLoading(false);
    }
  };

  const onClickDeleteUser = () => {
    Modal.confirm({
      title: `Are you sure you want to delete ${data.name}?`,
      cancelText: `Cancel`,
      okText: `Long hair don't care`,
      okType: 'danger',
      okButtonProps: {
        loading: loading,
      },
      cancelButtonProps: {
        disabled: loading,
      },
      onOk() {
        onDeleteUser();
      },
    });
  };

  return (
    <div css={{ padding: sizes.Spacing.Small }}>
      <div
        css={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          '> div': {
            justifyContent: 'flex-start',
            '> *': {
              marginRight: sizes.Spacing.XLarge,
            },
          },
          '> div:last-of-type': {
            marginBottom: 0,
          },
        }}
      >
        <div
          css={{
            position: 'absolute',
            top: sizes.Spacing.Small,
            right: 0,
            display: 'flex',
            '> *:not(:last-child)': { marginRight: sizes.Spacing.Small },
          }}
        >
          <Link
            href='#'
            onClick={e => {
              e.preventDefault();
              setEditing(true);
            }}
            css={{ fontSize: 22 }}
          >
            <Icon type='edit' />
          </Link>
          <Link
            href='#'
            onClick={e => {
              e.preventDefault();
              onClickDeleteUser();
            }}
            css={{
              color: colors.Negative,
              fontSize: 22,
              ':hover': {
                color: colors.Negative,
                opacity: 0.9,
              },
            }}
          >
            <Icon type='delete' />
          </Link>
        </div>
        <EditUserModal onClose={() => setEditing(false)} open={editing} user={data} />
        <UsersTabsDetails.Row>
          <Labeled label='First name'>{data.firstName || '—'}</Labeled>
          <Labeled label='Last name'>{data.lastName || '—'}</Labeled>
          <Labeled label='Role'>{data.role}</Labeled>
        </UsersTabsDetails.Row>
        <UsersTabsDetails.Row>
          <Labeled label='Phone'>{formatPhone(data.phone) || '—'}</Labeled>
          <Labeled label='Email'>{data.email || '—'}</Labeled>
          <Labeled label='Internal Slack ID'>{data.internalSlackId || '—'}</Labeled>
        </UsersTabsDetails.Row>
      </div>
    </div>
  );
};

UsersTabsDetails.Row = ({ children, ...otherProps }: any) => (
  <div
    css={{
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: sizes.Spacing.Medium,
    }}
    {...otherProps}
  >
    {children}
  </div>
);

UsersTabsDetails.fragment = gql`
  fragment UsersTabsDetails on User {
    id
    name
    firstName
    lastName
    phone
    email
    role
    internalSlackId
    isServiceAccount
  }
`;

UsersTabsDetails.mutation = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export default UsersTabsDetails;
