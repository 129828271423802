import gql from 'graphql-tag';
import React from 'react';
import { Spin, Tabs } from 'antd';

import FillRemaining from '~/FillRemaining';
import * as GraphQL from '~/graphql';
import { memo } from '~/react';
import { colors, sizes } from '~/styles';
import Header from '~/Header';
import { useQuery } from '@apollo/react-hooks';
import UpcomingBlackoutDates from './UpcomingBlackoutDates';
import BlackoutDateTab from './BlackoutDateTab';
import FullScreenSpinner from '~/FullScreenSpinner';

const strings = { title: 'Blackout Dates', availableDates: 'Available Dates' };

const BlackoutDates = () => {
  const { data, loading, refetch } = useQuery(BlackoutDates.query);

  if (loading || !data) return <FullScreenSpinner />;

  const { blackoutDates } = data;

  return (
    <FillRemaining>
      <div
        css={{
          display: 'flex',
          boxSizing: 'border-box',
          flexDirection: 'column',
          backgroundColor: colors.White,
        }}
      >
        <Header title={strings.title} />
        <div css={{ margin: 'auto', width: '90%', paddingTop: sizes.Spacing.Large }}>
          <Tabs defaultActiveKey='Summary' onChange={() => {}}>
            <Tabs.TabPane tab='Summary' key='Summary'>
              <UpcomingBlackoutDates
                showTypeTags={true}
                blackoutDates={data.blackoutDates}
                refetch={refetch}
              />
            </Tabs.TabPane>
            {Object.keys(GraphQL.MysteryType).map(
              (mysteryType: GraphQL.MysteryType) => (
                <Tabs.TabPane tab={mysteryType} key={mysteryType}>
                  <BlackoutDateTab
                    mysteryType={mysteryType}
                    blackoutDates={blackoutDates}
                    refetch={refetch}
                  />
                </Tabs.TabPane>
              ),
            )}
          </Tabs>
        </div>
      </div>
    </FillRemaining>
  );
};

BlackoutDates.query = gql`
  query BlackoutDates {
    blackoutDates {
      id
      blackedOutDate
      mysteryType
      reason
    }
  }
`;

export default memo(BlackoutDates);
