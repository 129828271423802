import { DatePicker, Input, Radio, Select, Tag } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Frequency, Options, RRule, rrulestr } from 'rrule';
import { sizes } from '~/styles';
import ScottTitle from '~/ScottTitle';

const DATE_FORMAT = 'll';

const strings = {
  repeatEvery: 'Repeat every',
  repeatOn: 'Repeat on',
  starts: `Starts`,
  ends: `Ends`,
  never: `Never`,
  on: `On`,
  after: `After`,
  endsOn: 'Ends on',
  frequency: interval => [
    { value: Frequency.DAILY, label: interval > 1 ? 'days' : 'day' },
    { value: Frequency.WEEKLY, label: interval > 1 ? 'weeks' : 'week' },
    { value: Frequency.MONTHLY, label: interval > 1 ? 'months' : 'month' },
    { value: Frequency.YEARLY, label: interval > 1 ? 'years' : 'year' },
  ],
  weekdays: [
    { value: RRule.SU, label: 'S' },
    { value: RRule.MO, label: 'M' },
    { value: RRule.TU, label: 'T' },
    { value: RRule.WE, label: 'W' },
    { value: RRule.TH, label: 'T' },
    { value: RRule.FR, label: 'F' },
    { value: RRule.SA, label: 'S' },
  ],
};

interface Props {
  rrule?: string;
  startDay?: Date;
  onChange: any;
}

export const Recurrance = (props: Props) => {
  const initialConfig = props.rrule
    ? rrulestr(props.rrule).origOptions
    : {
        interval: 1,
        freq: Frequency.WEEKLY,
        byweekday:
          RRule[
            moment(props.startDay || +moment())
              .format('dd')
              .toUpperCase()
          ],
        dtstart: moment().toDate(),
        tzid: 'America/Los_Angeles',
      };

  const [config, setConfig] = useState<Partial<Options>>(initialConfig);

  useEffect(() => {
    if (!_.isArray(config.byweekday) || _.size(config.byweekday) === 1) {
      setConfig({
        ...config,
        byweekday:
          RRule[
            moment(props.startDay || +moment())
              .format('dd')
              .toUpperCase()
          ],
      });
    }
  }, [props.startDay]);

  useEffect(() => {
    const currentRuleText = new RRule(config).toString();
    if (props.rrule !== currentRuleText) props.onChange(currentRuleText);
  }, [config]);

  const setInterval = e =>
    setConfig({ ...config, interval: _.toNumber(e.target.value) });
  const setFrequency = freq => setConfig({ ...config, freq });
  const setDtStart = newDtStart =>
    newDtStart && setConfig({ ...config, dtstart: newDtStart.toDate() });

  return (
    <div css={{ display: 'flex', flexDirection: 'column', width: 500 }}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: sizes.GRID_UNIT * 3,
        }}
      >
        <ScottTitle type='Field' css={{ marginRight: sizes.GRID_UNIT * 3 }}>
          {strings.starts}
        </ScottTitle>
        <DatePicker
          allowClear={false}
          value={moment(config.dtstart)}
          onChange={setDtStart}
        />
      </div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: sizes.GRID_UNIT * 3,
        }}
      >
        <ScottTitle
          type='Field'
          css={{ marginRight: sizes.GRID_UNIT, whiteSpace: 'nowrap' }}
        >
          {strings.repeatEvery}
        </ScottTitle>
        <Input
          size='small'
          onChange={setInterval}
          value={config.interval}
          defaultValue='1'
          css={{
            width: sizes.GRID_UNIT * 10,
            marginRight: sizes.GRID_UNIT * 2,
          }}
        />
        <Select
          onChange={setFrequency}
          value={config.freq}
          size='small'
          css={{ width: sizes.GRID_UNIT * 18 }}
        >
          {_.map(strings.frequency(config.interval), ({ label, value }, i) => (
            <Select.Option key={_.toString(i)} value={value} title={label}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </div>

      {config.freq === Frequency.WEEKLY && (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: sizes.GRID_UNIT * 3,
          }}
        >
          <ScottTitle type='Field' css={{ marginBottom: sizes.GRID_UNIT * 2 }}>
            {strings.repeatOn}
          </ScottTitle>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            {_.map(strings.weekdays, ({ label, value }, i) => {
              const checked =
                config.byweekday === value ||
                _.includes(config.byweekday as any, value);
              return (
                <Tag.CheckableTag
                  key={i}
                  data-testid={`repeat-on-${label}-${i}`}
                  css={checked ? '' : { backgroundColor: '#f1f3f4' }}
                  onChange={checked => {
                    if (checked) {
                      setConfig({
                        ...config,
                        byweekday: _.uniqBy(
                          [..._.castArray(config.byweekday!), value as any],
                          'weekday',
                        ),
                      });
                    } else {
                      setConfig({
                        ...config,
                        byweekday: _.reject(
                          [..._.castArray(config.byweekday!)],
                          val => val && (val as any).weekday === value.weekday,
                        ) as any,
                      });
                    }
                  }}
                  checked={checked}
                >
                  {label}
                </Tag.CheckableTag>
              );
            })}
          </div>
        </div>
      )}

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: sizes.GRID_UNIT * 3,
        }}
      >
        <ScottTitle
          type='Field'
          css={{ marginBottom: sizes.GRID_UNIT * 2, marginTop: sizes.GRID_UNIT }}
        >
          {strings.ends}
        </ScottTitle>
        <Radio.Group
          value={config.until ? 'on' : config.count ? 'after' : 'never'}
          onChange={ev => {
            const key = ev.target.value;
            switch (key) {
              case 'never':
                setConfig({ ...config, count: undefined, until: undefined });
                return;
              case 'on':
                setConfig({ ...config, count: undefined, until: new Date() });
                return;
              case 'after':
                setConfig({ ...config, until: undefined, count: 1 });
                return;
            }
          }}
        >
          <EndOption
            value='never'
            label={strings.never}
            checked={config.count === undefined && !config.until}
          />
          <EndOption
            value='on'
            label={strings.on}
            checked={!!config.until}
            optionValue={
              <EndDatePicker
                css={{ width: sizes.GRID_UNIT * 26 }}
                disabled={!config.until}
                value={moment(config.until!)}
                onChange={(date: Moment) =>
                  setConfig({ ...config, until: date.toDate() })
                }
              />
            }
          />
          <EndOption
            value='after'
            label={strings.after}
            checked={config.count !== undefined}
            optionValue={
              <Input
                size='small'
                type='number'
                suffix={
                  <span
                    css={{
                      position: 'relative',
                      top: -3,
                    }}
                  >
                    occurrences
                  </span>
                }
                value={config.count!}
                disabled={config.count === undefined}
                css={{ width: sizes.GRID_UNIT * 26, position: 'relative' }}
                onChange={ev => setConfig({ ...config, count: ~~ev.target.value })}
              />
            }
          />
        </Radio.Group>
      </div>
    </div>
  );
};

interface EndDatePickerProps extends DatePickerProps {
  value: Moment;
  onChange: any;
}

const EndDatePicker = ({ value, onChange, ...otherProps }: EndDatePickerProps) => (
  <DatePicker
    {...otherProps}
    size='small'
    allowClear={false}
    onChange={onChange}
    suffixIcon={<div />}
    format={DATE_FORMAT}
    value={moment(value)}
    placeholder={strings.endsOn}
    css={{ width: sizes.GRID_UNIT * 15 }}
  />
);

interface EndOptionProps {
  label: string;
  checked: boolean;
  optionValue?: any;
  value: string;
}

const EndOption = ({ label, checked, optionValue, value }: EndOptionProps) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: sizes.GRID_UNIT,
      '> *:first-child': { width: sizes.GRID_UNIT * 13 },
    }}
  >
    <Radio checked={checked} value={value}>
      {label}
    </Radio>
    {optionValue}
  </div>
);

export default Recurrance;
