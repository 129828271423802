import { Button, Drawer, message, Tabs } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import AuditLogCell from '~/AuditLogCell';
import * as GraphQL from '~/graphql';
import { useMutation, useQuery } from '@apollo/react-hooks';
import ArchiveContractModal from './ContractArchive';
import ContractModal, { isNewContract } from './ContractModal';
import MemberModal from './MemberModal';
import MembersTab from './Tabs/MembersTab';
import OverviewTab from './Tabs/OverviewTab';
import PlansTab from './Tabs/PlansTab';
import CustomerTypeTab from './Tabs/CustomerType/CustomerTypeTab';
import { recursivelyRemoveTypenames } from '~/services/apollo';
import { BulkUploadTab } from './Tabs/BulkUploadTab';
import { BetasTab } from './Tabs/BetasTab';
import ContractAddEdit, { EditorContract } from './ContractAddEdit';

const { TabPane } = Tabs;

export interface ModalProps {
  visible: boolean;
  onCancel: () => void;
  orgId: string;
}

// Fixes weird requiredness issues
export type Contract = Omit<EditorContract, 'auditLogs'>;
export type OrganizationUpdateData = Omit<
  GraphQL.OrganizationEditor.Query['organization'],
  'contracts'
> & {
  contracts: Contract[];
};

const OrganizationEditor = ({ orgId, onClose }) => {
  const { data, loading } = useQuery<
    GraphQL.OrganizationEditor.Query,
    GraphQL.OrganizationEditor.Variables
  >(OrganizationEditor.query, { variables: { id: orgId }, fetchPolicy: 'network-only' });

  const [saveCompany, { loading: saving }] = useMutation<
    GraphQL.OrganizationEdit.Mutation,
    GraphQL.OrganizationEdit.Variables
  >(OrganizationEditor.mutation, {
    refetchQueries: ['OrganizationGridQuery', 'OrganizationEditor'],
  });

  const [updatedData, setUpdatedData] = useState<OrganizationUpdateData>(null);
  const [showContractModal, setShowContractModal] = useState<boolean>(false);
  const [showMemberModal, setShowMemberModal] = useState<boolean>(false);
  const [selectedContract, setSelectedContract] = useState<Contract>();
  const [archive, setArchive] = useState<{ visible: boolean; contractId: string }>({
    visible: false,
    contractId: null,
  });
  const setInferMembership = infer =>
    setUpdatedData({
      ...updatedData,
      inferMembership: infer,
    });

  useEffect(() => {
    if (!data) return;
    const organization = _.get(data, 'organization');
    if (!organization) return;
    setUpdatedData({
      ...organization,
    });
  }, [data]);

  const normalizeData = (data: any, defaultValue: any = null) =>
    JSON.stringify(recursivelyRemoveTypenames(data || defaultValue));

  const hasChanged = (clientData: any, serverData: any, defaultValue: any = null) =>
    normalizeData(clientData, defaultValue) !== normalizeData(serverData, defaultValue);

  if (loading || !data || !updatedData) return null;
  const { name, members, contracts, inferMembership, isPulseBetaEnabled } = updatedData;
  const isDirty = hasChanged(updatedData, _.get(data, 'organization'));

  const contract = _.find(
    contracts,
    contract => contract.status === GraphQL.ContractStatus.Active && !isNewContract(contract),
  );

  return (
    <Drawer
      title={_.get(data, 'organization.name')} // Not `name` so it doesn't change while editing
      visible={true}
      onClose={onClose}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        justifyContent: 'space-between',
        minHeight: '92%',
      }}
      width={'80%'}
    >
      <MemberModal
        visible={showMemberModal}
        onCancel={() => setShowMemberModal(false)}
        orgId={orgId}
        currentMembers={members}
      />
      <ContractModal
        visible={showContractModal}
        onCancel={() => setShowContractModal(false)}
        orgId={orgId}
        orgName={name}
        contract={selectedContract}
        setArchive={setArchive}
      />
      <ArchiveContractModal
        contractId={archive.contractId}
        visible={archive.visible}
        onClose={() => {
          setArchive({ visible: false, contractId: null });
        }}
        closeDrawer={onClose}
      />
      <Tabs defaultActiveKey='1'>
        <TabPane tab='Overview' key='1'>
          <OverviewTab
            activeContracts={contracts}
            setShowContractModal={setShowContractModal}
            setSelectedContract={setSelectedContract}
            updatedData={updatedData}
            setUpdatedData={setUpdatedData}
          />
        </TabPane>
        <TabPane tab='Plans' key='2'>
          <PlansTab
            contracts={contracts}
            setSelectedContract={setSelectedContract}
            setShowContractModal={setShowContractModal}
          />
        </TabPane>
        <TabPane tab={`Members (${_.size(members)})`} key='3'>
          <MembersTab
            members={members}
            inferMembership={inferMembership}
            setShowMemberModal={setShowMemberModal}
            setInferMembership={setInferMembership}
          />
        </TabPane>
        <TabPane tab={`Org Chart Upload`} key='4'>
          <BulkUploadTab organization={data.organization as GraphQL.Organization} />
        </TabPane>
        <TabPane tab={`Customer Type`} key='5'>
          <CustomerTypeTab updatedData={updatedData} setUpdatedData={setUpdatedData} />
        </TabPane>
        <TabPane tab={`Betas`} key='6'>
          <BetasTab updatedData={updatedData} setUpdatedData={setUpdatedData} />
        </TabPane>
      </Tabs>
      <div>
        <Button
          type='primary'
          loading={saving}
          disabled={!isDirty}
          onClick={async () => {
            try {
              // Always call this update in order to refresh all org data.
              await saveCompany({
                variables: {
                  ...recursivelyRemoveTypenames(_.omit(updatedData, 'members')),
                },
              });
              message.success('Organization updated!');
            } catch (err) {
              message.error(err.message);
            }
          }}
        >
          Save
        </Button>
      </div>
    </Drawer>
  );
};

OrganizationEditor.query = gql`
  query OrganizationEditor($id: ID!) {
    organization(id: $id) {
      id
      name
      internalName
      domain
      industry
      description
      engagementGraphApproved
      createdAt
      crmUrl
      isHrisConnected
      manualIntegrationLastUpdated
      customerType
      userSignUpPath
      logoUrl
      inferMembership
      accountManager {
        id
      }
      salesPointOfContact {
        id
      }
      customerSuccessManager {
        id
      }
      members: membersWithServiceAccounts {
        id
        firstName
        lastName
        email
        phone
        isServiceAccount
        organizationRole {
          id
          name
        }
      }
      upcomingEvents {
        id
        shortId
      }
      contracts {
        id
        name
        status
        startDate
        renewDate
        unitCostCents
        unitCount
        unitInitialCount
        type
        pendingUnitCount
        pendingPremiumUnitCount
        premiumUnitCostCents
        premiumUnitCount
        premiumUnitInitialCount
        notes
        productType
        createdAt
        updatedAt
        auditLogs {
          ...AuditLogCell
        }
        authorizedUsers {
          ...AuthorizedUser
        }
      }
      isPulseBetaEnabled
      isMeetupsBetaEnabled
    }
  }
  ${AuditLogCell.fragment}
  ${ContractAddEdit.fragments.contract}
`;

OrganizationEditor.mutation = gql`
  mutation OrganizationEdit(
    $id: ID!
    $name: String!
    $internalName: String
    $domain: String
    $crmUrl: String
    $accountManager: ReferenceUserInput
    $salesPointOfContact: ReferenceUserInput
    $customerSuccessManager: ReferenceUserInput
    $industry: String
    $description: String
    $engagementGraphApproved: Boolean
    $customerType: CustomerType
    $logoUrl: String
    $userSignUpPath: String
    $inferMembership: Boolean
    $isPulseBetaEnabled: Boolean
    $isMeetupsBetaEnabled: Boolean
  ) {
    updateOrganization(
      id: $id
      name: $name
      internalName: $internalName
      domain: $domain
      crmUrl: $crmUrl
      accountManager: $accountManager
      salesPointOfContact: $salesPointOfContact
      customerSuccessManager: $customerSuccessManager
      industry: $industry
      description: $description
      engagementGraphApproved: $engagementGraphApproved
      customerType: $customerType
      logoUrl: $logoUrl
      userSignUpPath: $userSignUpPath
      inferMembership: $inferMembership
      isPulseBetaEnabled: $isPulseBetaEnabled
      isMeetupsBetaEnabled: $isMeetupsBetaEnabled
    ) {
      id
    }
  }
`;

export default OrganizationEditor;
