import { Radio } from 'antd';

import { DataTableFilter, GraphQLFiltersInput } from '../interface';
import { KeysOfType } from '../utilTypes';

/**
 * A <DataTable> filter that displays as <Yes> / <No>
 */
export function booleanFilter<
  TFilters extends GraphQLFiltersInput,
  TFilterKey extends KeysOfType<TFilters, boolean> = KeysOfType<TFilters, boolean>
>(
  key: TFilterKey,
  title: string,
  labels = {
    true: 'Yes',
    false: 'No',
  },
): DataTableFilter<TFilters, TFilterKey, boolean> {
  return {
    field: key,
    title,
    component: ({ value, onChange }) => (
      <Radio.Group
        value={typeof value === 'boolean' && String(value)}
        onChange={({ target }) => onChange(target.value === 'true')}
      >
        <Radio value='true'>{labels.true}</Radio>
        <Radio value='false'>{labels.false}</Radio>
      </Radio.Group>
    ),
  };
}
