import { sizes } from '~/styles';

const BandRow = ({ children, style = {} }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      '> *:not(:last-child)': { marginRight: sizes.Spacing.Medium },
      ...style,
    }}
  >
    {children}
  </div>
);

export default BandRow;
