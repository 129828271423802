import React from 'react';

import { font } from '~/styles';

interface Props {
  label: string;
  children?: React.ReactNode;
  content?: React.ReactNode | string | number | null;
}

const Labeled = ({ content, children, label, ...otherProps }: Props) => (
  <div css={{ display: 'flex', flexDirection: 'column' }} {...otherProps}>
    <span
      css={{
        display: 'block',
        ...font.Size.SmallCaption,
        fontWeight: font.FontWeight.Demi,
        textTransform: 'uppercase',
      }}
    >
      {label}
    </span>
    {content || children}
  </div>
);

export default Labeled;
