import React from 'react';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import CapacitySection from './CapacitySection';
import NotesSection from './NotesSection';
import SelectDateSection from './SelectDateSection';
import ScottHr from '~/ScottHr';
import * as GraphQL from '~/graphql';
import ScheduleItemCell from '~/ScheduleItemCell';
import { sizes } from '~/styles';
import Row from '~/Row';
import Link from '~/Link';
import moment from 'moment-timezone';
import MysteriesGoingToThisEventSection from './MysteriesGoingToThisEventSection';
import _ from 'lodash';

type Data = GraphQL.EventDialogEvent.Fragment;

const EventDetails = ({
  experienceId,
  eventId,
  schedule,
  clearSchedule,
  data,
  setData,
}: {
  experienceId: string;
  eventId?: string;
  schedule?: GraphQL.ScheduleItemCell.Fragment;
  clearSchedule(): void;
  data: Data;
  setData(newData: Data): void;
}) => {
  const mysteriesGoingToThisEvent =
    data && data.adventureStops ? _.map(data.adventureStops, 'adventure') : [];

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      {schedule && (
        <>
          <Row>
            <LabeledField label={'Selected Schedule'}>
              <div css={{ marginTop: sizes.Spacing.XSmall }}>
                <ScheduleItemCell {...schedule} />
              </div>
            </LabeledField>
            {!eventId && (
              <Link
                data-testid='change-schedule-link'
                onClick={() => clearSchedule()}
              >
                Change
              </Link>
            )}
          </Row>
          <ScottHr />
        </>
      )}
      <SelectDateSection
        experienceId={experienceId}
        schedule={schedule}
        start={moment(data ? data.start : undefined)}
        end={moment(data ? data.end : undefined)}
        {...data}
        setDate={date => setData({ ...data, date })}
        setStartTime={startTime => setData({ ...data, startTime })}
        setEndTime={endTime => setData({ ...data, endTime })}
      />
      <ScottHr />
      <CapacitySection
        {...data}
        setCapacityType={capacityType => setData({ ...data, capacityType })}
        setTotalCapacity={totalCapacity => setData({ ...data, totalCapacity })}
        capacityFromSchedule={schedule ? schedule.capacity : undefined}
      />
      <ScottHr />
      <NotesSection {...data} setNotes={notes => setData({ ...data, notes })} />
      {_.size(mysteriesGoingToThisEvent) > 0 && (
        <>
          <ScottHr />
          <MysteriesGoingToThisEventSection mysteries={mysteriesGoingToThisEvent} />
        </>
      )}
    </div>
  );
};

export default EventDetails;
