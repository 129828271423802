import { CheckedIcon, UnCheckedIcon, NegativeCheckedIcon } from './CheckboxIcons';

interface Props {
  checked: boolean;
  isNegative?: boolean;
}

const Icon = ({ checked, isNegative }: Props) => {
  const SVG = !checked
    ? UnCheckedIcon
    : isNegative
    ? NegativeCheckedIcon
    : CheckedIcon;

  return (
    <div
      css={{
        width: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SVG />
    </div>
  );
};

export default Icon;
