import React, { useState } from 'react';
import { Radio, message, Icon, Tooltip, Popconfirm } from 'antd';
import Modal from '~/Modal';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import * as GraphQL from '~/graphql';
import Text from '~/Text';
import OperatingHours from '~/OperatingHours';
import ScottHr from '~/ScottHr';
import _ from 'lodash';
import { sizes, colors } from '~/styles';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { addSpacesToPascalCasedString } from '~/formatters';
import ScheduleDialog from '~/ScheduleDialog';
import ScheduleItemCell from '~/ScheduleItemCell';
import Link from '~/Link';
import { pluralize } from 'humanize-plus';

const strings = {
  modalTitle: 'Edit Availability',
  availabilityType: 'Availability Type',
  when: 'When? (Times must be input in PT)',
};

const AvailabilityEditor = (props: {
  data: GraphQL.Availability.Fragment;
  isOpen?: boolean;
  close?(): void;
}) => {
  const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState(false);
  const [selectedScheduleToEdit, setSelectedScheduleToEdit] = useState(undefined);

  const [availabilityType, setAvailabilityType] = useState(props.data.availabilityType);
  const [operatingHours, setOperatingHours] = useState<GraphQL.OperatingHoursInput[]>(
    props.data.operatingHours,
  );

  const [updateExperience, { loading }] = useMutation<
    GraphQL.AvailabilityEditor.Mutation,
    GraphQL.AvailabilityEditor.Variables
  >(AvailabilityEditor.mutation, { refetchQueries: ['ExperienceProfile'] });

  const { schedules } = props.data;

  const update = ({
    updatedSchedules,
    updatedOperatingHours,
  }: {
    updatedSchedules?: GraphQL.ScheduleItemCell.Fragment[];
    updatedOperatingHours?: any[];
  }) =>
    updateExperience({
      variables: {
        experienceId: props.data.id,
        availabilityType,
        ...(availabilityType === GraphQL.XperienceAvailabilityType.Schedule
          ? {
              schedules: _.map(updatedSchedules, schedule =>
                _.pick(schedule, ['id', 'rrule', 'durationMins', 'capacity', 'startTime']),
              ),
            }
          : {
              operatingHours: _.map(updatedOperatingHours, oh => _.pick(oh, ['open', 'close'])),
            }),
      },
    })
      .then(() => {
        console.log('success');
        message.success('Updated availability');
      })
      .catch(error => {
        console.error(JSON.stringify(error));
      });

  return (
    <Modal
      title={strings.modalTitle}
      visible={props.isOpen}
      footer={availabilityType === GraphQL.XperienceAvailabilityType.Schedule ? null : undefined}
      okButtonProps={{
        disabled: false,
        loading,
        'data-testid': 'edit-availability-ok-button',
      }}
      onOk={async () => {
        if (availabilityType === GraphQL.XperienceAvailabilityType.OperatingHours)
          await update({
            updatedOperatingHours: operatingHours,
            updatedSchedules: schedules,
          });

        props.close();
      }}
      onCancel={props.close}
      width='900px'
    >
      <LabeledField label={strings.availabilityType}>
        <div data-testid='availability-type-radios'>
          <Radio.Group value={availabilityType} onChange={e => setAvailabilityType(e.target.value)}>
            {Object.keys(GraphQL.XperienceAvailabilityType).map(availType => (
              <Radio key={availType} value={availType}>
                {addSpacesToPascalCasedString(availType)}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </LabeledField>
      <ScottHr />
      <div>
        {(() => {
          switch (availabilityType) {
            case GraphQL.XperienceAvailabilityType.OperatingHours:
              return (
                <div>
                  <Text data-testid='when-label' size='Large' weight='Demi'>
                    {strings.when}
                  </Text>
                  <OperatingHours
                    isEditMode={true}
                    operatingHours={operatingHours}
                    setOperatingHours={newValue => setOperatingHours(newValue)}
                  />
                </div>
              );
            case GraphQL.XperienceAvailabilityType.Schedule:
              return (
                <>
                  <div css={{ display: 'flex', flexDirection: 'column' }}>
                    <Text size='Large' weight='Demi' css={{ marginBottom: sizes.Spacing.Medium }}>
                      Existing Schedules
                    </Text>
                    {_.size(schedules) === 0 ? (
                      <Text size='Small'>No schedules added</Text>
                    ) : (
                      _.map(schedules, (schedule, i) => {
                        const numberOfFutureEvents = _.size(schedule.futureEvents);
                        const linkCss = numberOfFutureEvents ? { css: { opacity: 0.5 } } : {};
                        const iconCss = {
                          fontSize: 18,
                          marginLeft: sizes.Spacing.XSmall,
                          marginRight: sizes.Spacing.XSmall,
                        };

                        const showErrorMessage = (action: 'edit' | 'delete') =>
                          message.error(
                            `Cannot ${action} this schedule as there ${pluralize(
                              numberOfFutureEvents,
                              'is',
                              'are',
                            )} ${numberOfFutureEvents} ${pluralize(
                              numberOfFutureEvents,
                              'event',
                              'events',
                            )} depending on it`,
                          );
                        return (
                          <div
                            key={`select-schedule-${i}`}
                            data-testid={`select-schedule-${i}`}
                            css={{
                              marginBottom: sizes.Spacing.Medium,
                              padding: sizes.Spacing.Medium,
                              border: `1px solid ${colors.ExtraDarkSnow}`,
                              borderRadius: sizes.GRID_UNIT * 2,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <ScheduleItemCell {...schedule} />
                            <div css={{ display: 'flex', alignItems: 'center' }}>
                              <Tooltip title='Make a copy' mouseEnterDelay={0.75}>
                                <Link
                                  onClick={() => {
                                    setSelectedScheduleToEdit(_.omit(schedule, 'id'));
                                    setIsScheduleDialogOpen(true);
                                  }}
                                >
                                  <Icon type='copy' css={{ ...iconCss, color: colors.AntdBlue }} />
                                </Link>
                              </Tooltip>
                              <Tooltip title='Edit' mouseEnterDelay={0.75}>
                                <Link
                                  {...linkCss}
                                  onClick={() => {
                                    if (numberOfFutureEvents) {
                                      showErrorMessage('edit');
                                    } else {
                                      setSelectedScheduleToEdit(schedule);
                                      setIsScheduleDialogOpen(true);
                                    }
                                  }}
                                >
                                  <Icon type='form' css={{ ...iconCss, color: colors.AntdBlue }} />
                                </Link>
                              </Tooltip>
                              <Popconfirm
                                disabled={numberOfFutureEvents > 0}
                                title='Are you sure you want to delete this schedule?'
                                okText='Yes'
                                cancelText='No'
                                onConfirm={() => {
                                  update({
                                    updatedSchedules: _.filter(schedules, (schedule, j) => i !== j),
                                  });
                                }}
                              >
                                <Tooltip title='Delete' mouseEnterDelay={0.75}>
                                  <Link
                                    {...linkCss}
                                    onClick={
                                      numberOfFutureEvents
                                        ? () => showErrorMessage('delete')
                                        : undefined
                                    }
                                  >
                                    <Icon
                                      type='delete'
                                      css={{ ...iconCss, color: colors.Negative }}
                                    />
                                  </Link>
                                </Tooltip>
                              </Popconfirm>
                            </div>
                          </div>
                        );
                      })
                    )}
                    <Link
                      onClick={() => {
                        setSelectedScheduleToEdit({});
                        setIsScheduleDialogOpen(true);
                      }}
                      css={{
                        marginTop: sizes.Spacing.Medium,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Icon type='plus-circle' css={{ marginRight: sizes.Spacing.Small }} />
                      Add new schedule
                    </Link>
                  </div>
                  <ScheduleDialog
                    isOpen={isScheduleDialogOpen}
                    close={() => setIsScheduleDialogOpen(false)}
                    experienceId={props.data.id}
                    existingScheduleIds={_.map(
                      _.filter(
                        props.data.schedules,
                        schedule =>
                          !selectedScheduleToEdit || schedule.id !== selectedScheduleToEdit.id,
                      ),
                      schedule => _.pick(schedule, 'id'),
                    )}
                    scheduleToEdit={selectedScheduleToEdit}
                    refetchQueries={['ExperienceProfile']}
                  />
                </>
              );
            default:
              throw new Error(`unknown availabilityType: ${availabilityType}`);
          }
        })()}
      </div>
    </Modal>
  );
};

export default AvailabilityEditor;

AvailabilityEditor.mutation = gql`
  mutation AvailabilityEditor(
    $experienceId: ID!
    $availabilityType: XperienceAvailabilityType!
    $schedules: [ScheduleInput]
    $operatingHours: [OperatingHoursInput]
  ) {
    updateXperience(
      id: $experienceId
      availabilityType: $availabilityType
      schedules: $schedules
      operatingHours: $operatingHours
    ) {
      id
      availabilityType
      schedules {
        id
        rrule
        startTime
        durationMins
        capacity
      }
      operatingHours {
        id
        open {
          day
          hours
          minutes
          time
        }
        close {
          day
          hours
          minutes
          time
        }
      }
    }
  }
`;
