import useTimeout from '@rooks/use-timeout';
import _ from 'lodash';
import React, { useRef } from 'react';

import useMobile from '~/hooks/useMobile';
import usePersistedState from '~/hooks/usePersistedState';

interface Props {
  onPress: any;
  onLongPress: any;
  duration?: number;
  children: React.ReactNode;
  onTouchStart?: any;
  onTouchEnd?: any;
  onTouchMove?: any;
}

const LongPress = ({
  onPress,
  onLongPress,
  onTouchStart,
  onTouchEnd,
  onTouchMove,
  duration = 500,
  children,
}: Props) => {
  const shouldShortPress = useRef(true);
  const moved = useRef(false);

  const isPhone = useMobile();

  const longPressed = () => {
    shouldShortPress.current = false;
    if (onLongPress && moved.current === false) onLongPress();
  };

  const { start, clear } = useTimeout(longPressed, duration);

  const _onTouchStart = (event: any) => {
    shouldShortPress.current = true;
    moved.current = false;
    start();
    if (_.isFunction(onTouchStart)) onTouchStart(event);
  };

  const _onTouchEnd = (event: any) => {
    clear();
    if (onPress && shouldShortPress.current && !moved.current) onPress();
    if (_.isFunction(onTouchEnd)) onTouchEnd(event);
  };

  const _onTouchMove = (event: any) => {
    moved.current = true;
    if (_.isFunction(onTouchMove)) onTouchMove(event);
  };

  return (
    <>
      {React.Children.map(children, (child: any) => {
        const newProps = !isPhone
          ? { onClick: onPress }
          : LongPress.useTouchAvailability()
          ? {
              onContextMenu: (event: any) => event.preventDefault(),
              onTouchStart: _onTouchStart,
              onTouchEnd: _onTouchEnd,
              onTouchMove: _onTouchMove,
              style: {
                ..._.get(child, 'props.style', {}),
                WebkitUserSelect: 'none',
                WebkitTouchCallout: 'none',
              },
            }
          : {
              onMouseDown: _onTouchStart,
              onMouseUp: _onTouchEnd,
              onMouseMove: _onTouchMove,
            };
        return React.cloneElement(child, { ...child.props, ...newProps });
      })}
    </>
  );
};

LongPress.useTouchAvailability = () => {
  const [isTouchAvailable, setIsTouchAvailable] = usePersistedState(
    'touchAvailable',
    null as any,
  );

  if (_.isNil(isTouchAvailable)) {
    try {
      document.createEvent('TouchEvent');
      setIsTouchAvailable(true);
    } catch (e) {
      setIsTouchAvailable(false);
    }
  }

  return isTouchAvailable;
};

export default LongPress;
