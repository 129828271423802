import _ from 'lodash';
import { Checkbox, Input } from 'antd';
import gql from 'graphql-tag';

import * as GraphQL from '~/graphql';
import { Edits } from '~/hooks/useEditableEntity';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import Row from '~/Row';
import InputNumberWithUnit from '~/Input/NumberWithUnit';

import Heading from '../../Heading';
import { titleCaseFromPascalCase } from '~/formatters';

export interface Props {
  experience: GraphQL.ExperienceProfileInstructionsDetails.Fragment;
  onChange: (edits: Edits<GraphQL.ExperienceProfileInstructionsDetails.Fragment>) => void;
}

const ExperienceProfileInstructionsDetails = ({ experience, onChange }: Props) => {
  return (
    <div>
      <Heading>Details</Heading>
      <Row>
        <LabeledField label='Lead Time Needed'>
          <InputNumberWithUnit
            value={experience.leadTimeNeededDays}
            onChange={leadTimeNeededDays => onChange({ leadTimeNeededDays })}
            unit='days before event'
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Experience Duration Options'>
          <Input
            value={experience.durationOptions}
            onChange={event => onChange({ durationOptions: event.target.value })}
            placeholder='Add options in minutes, separated by commas'
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Experience Structure'>
          <Input.TextArea
            rows={6}
            value={experience.experienceStructure}
            onChange={event => onChange({ experienceStructure: event.target.value })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Experience Notes'>
          <Input.TextArea
            rows={6}
            value={experience.supplyKitInstructions}
            onChange={event => onChange({ supplyKitInstructions: event.target.value })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Day Before Instructions'>
          <Input.TextArea
            rows={6}
            value={experience.dayBeforeInstructions}
            onChange={event => onChange({ dayBeforeInstructions: event.target.value })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Hybrid Group Instructions'>
          <Input.TextArea
            rows={6}
            value={experience.hybridGroupInstructions}
            onChange={event => onChange({ hybridGroupInstructions: event.target.value })}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Post Event Information'>
          <Input.TextArea
            rows={6}
            value={experience.postEventInformation}
            onChange={event => onChange({ postEventInformation: event.target.value })}
          />
        </LabeledField>
      </Row>
    </div>
  );
};

ExperienceProfileInstructionsDetails.fragment = gql`
  fragment ExperienceProfileInstructionsDetails on Xperience {
    id
    dayBeforeInstructions
    durationOptions
    experienceStructure
    hybridGroupInstructions
    leadTimeNeededDays
    supplyKitInstructions
    postEventInformation
  }
`;

export default ExperienceProfileInstructionsDetails;
