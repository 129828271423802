import { Drawer } from '@blueprintjs/core';
import _ from 'lodash';
import React from 'react';

import AppNavigation from '~/App/Navigation';
import useMobile from '~/hooks/useMobile';
import FillRemaining from '../FillRemaining';

interface Props {
  children: React.ReactNode;
  visible?: boolean;
  onClose: any;
  title?: string;
  width?: any;
}

const SideSheet = ({ title, visible, onClose, width, children }: Props) => {
  const isPhone = useMobile();
  return (
    <Drawer
      lazy
      title={title}
      onClose={onClose}
      size={width || (isPhone ? '100%' : '50%')}
      isOpen={_.isBoolean(visible) ? visible : true}
      style={
        isPhone ? { position: 'absolute', top: AppNavigation.HEADER_HEIGHT } : {}
      }
    >
      <FillRemaining css={{ overflowY: 'auto' }}>{children}</FillRemaining>
    </Drawer>
  );
};

export default SideSheet;
