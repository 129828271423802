import _ from 'lodash';
import React from 'react';

import PriceLevel from '~/Experiences/PriceLevel';
import { formatTimeRange, formatCents, smartFormatDate } from '~/formatters';
import Link from '~/Link';
import { ColumnProps } from '~/PaginatedTable/types';
import { sizes } from '~/styles';
import ActionCell from './ActionCell';
import { getDateStringFromTimestamp } from '~/date';
import Text from '~/Text';
import moment from 'moment-timezone';

const placeholder = '-';

export const DAYS_TO_BE_CONSIDERED_AT_RISK = 7;

const isAtRisk = timestamp =>
  moment(timestamp).isBefore(moment().add(DAYS_TO_BE_CONSIDERED_AT_RISK, 'days'));

export default [
  {
    key: 'partner',
    width: sizes.GRID_UNIT * 25,
    cellRenderer: (_, rowData) => (
      <Link
        href={`/supply/partner/${rowData.experience.partner.id}`}
        useReactRouterLink
      >
        {rowData.experience.partner.name}
      </Link>
    ),
  },
  {
    key: 'experience',
    width: sizes.GRID_UNIT * 30,
    cellRenderer: cellData => (
      <Link
        href={`/supply/partner/${cellData.partner.id}/experience/${cellData.id}`}
        useReactRouterLink
      >
        {cellData.name}
      </Link>
    ),
  },
  {
    key: 'date',
    width: sizes.GRID_UNIT * 24,
    cellRenderer: (_, rowData) => (
      <Text
        size='Small'
        color={moment(rowData.start).isBefore(moment()) ? 'Grey' : undefined}
      >
        {getDateStringFromTimestamp(rowData.start)}
      </Text>
    ),
  },
  {
    key: 'time',
    width: sizes.GRID_UNIT * 24,
    cellRenderer: (_, rowData) => (
      <Text
        size='Small'
        color={moment(rowData.start).isBefore(moment()) ? 'Grey' : undefined}
      >
        {formatTimeRange(rowData.start, rowData.end)}
      </Text>
    ),
  },
  {
    key: 'capacityType',
    label: 'Capacity Type',
    width: sizes.GRID_UNIT * 22,
    cellRenderer: cellData => cellData,
  },
  {
    key: 'capacity',
    label: 'Capacity',
    width: sizes.GRID_UNIT * 20,
    cellRenderer: (_cellData, rowData) =>
      `${rowData.totalCapacity - rowData.remainingCapacity}/${
        rowData.remainingCapacity
      }`,
  },
  {
    key: 'atRisk',
    label: 'At risk',
    width: sizes.GRID_UNIT * 10,
    cellRenderer: (_cellData, rowData) => (
      <Text
        color={isAtRisk(rowData.start) ? 'Negative' : undefined}
        weight={isAtRisk(rowData.start) ? 'Demi' : undefined}
        size='Small'
        css={
          moment(rowData.start).isBefore(moment()) && rowData.remainingCapacity > 0
            ? {
                textDecoration: 'line-through',
                opacity: 0.5,
              }
            : undefined
        }
      >
        {rowData.remainingCapacity}
      </Text>
    ),
  },
  {
    key: 'neighborhood',
    label: 'Neighborhood',
    width: sizes.GRID_UNIT * 24,
    cellRenderer: (_, rowData) =>
      rowData.location
        ? rowData.location.address.neighborhood || placeholder
        : placeholder,
  },
  {
    key: 'cost',
    label: 'Cost',
    width: sizes.GRID_UNIT * 12,
    cellRenderer: cellData =>
      cellData ? formatCents(cellData.ctcCents) : placeholder,
  },
  {
    key: 'priceLevel',
    label: 'Price',
    width: sizes.GRID_UNIT * 12,
    cellRenderer: (_, rowData) =>
      // TODO: pull price level from google search initially
      rowData.experience.priceLevel ? (
        <PriceLevel level={rowData.experience.priceLevel} size={14} readonly />
      ) : (
        placeholder
      ),
  },
  {
    key: 'xperienceType',
    label: 'Type',
    width: sizes.GRID_UNIT * 20,
    cellRenderer: (_, rowData) =>
      rowData.experience.xperienceTypes.join(', ') || placeholder,
  },
  {
    key: 'cardOnFile',
    label: 'COF',
    width: sizes.GRID_UNIT * 10,
    cellRenderer: (_, rowData) => (rowData.experience.cardOnFile ? 'Yes' : 'No'),
  },
  {
    key: 'cuisineFriendly',
    label: 'Cuisine Friendly',
    width: sizes.GRID_UNIT * 20,
    cellRenderer: (_cellData, rowData) =>
      _.map(rowData.experience.friendlyTowardsDietaryRestrictions, 'name').join(
        ', ',
      ) || placeholder,
  },
  {
    key: 'actions',
    noHeader: true,
    width: sizes.GRID_UNIT * 10,
    cellRenderer: (_cellData, rowData) => <ActionCell eventId={rowData.id} />,
  },
] as ColumnProps[];
