import React from 'react';

import { memo } from '~/lib/react';
import { sizes } from '~/styles';

interface Props {
  children?: React.ReactNode;
  justifyContent?: 'flex-start' | 'flex-end' | 'space-between';
  alignItems?: 'flex-start' | 'flex-end' | 'center';
  marginBottom?: number;
  marginRight?: number;
}

/**
 * This component is a flexbox helper that sets the wrapper to flex aligned
 * center, allowing you to specify how to justify your content. It adds a small bit
 * of margin to the bottom, and to the right of each child that isn't the last one.
 */
const Row = ({
  children,
  justifyContent = 'space-between',
  alignItems = 'flex-start',
  marginBottom = sizes.Spacing.Small,
  marginRight = sizes.Spacing.XSmall,
  ...props
}: Props) => (
  <div
    css={{
      display: 'flex',
      justifyContent,
      alignItems,
      marginBottom,
      '> *:not(:last-child)': { marginRight },
    }}
    {...props}
  >
    {children}
  </div>
);

export default memo(Row);
