import React from 'react';
import * as GraphQL from '~/graphql';
import gql from 'graphql-tag';
import ExperiencePricingView from './View';
import ExperiencePricingEditor from './Edit';
import LabeledSection from '~/LabeledSection';

const strings = {
  pricing: 'Pricing',
  emptyMessage: 'No pricing info added',
};

const ExperienceProfilePricing = (props: GraphQL.ExperienceProfilePricing.Fragment) => {
  const isEmpty = Object.keys(props).length === 0 || !props.cost;
  return (
    <LabeledSection
      label={strings.pricing}
      viewParts={[ExperiencePricingView]}
      EditModal={ExperiencePricingEditor}
      emptyMessage={isEmpty ? strings.emptyMessage : undefined}
      data={props}
    />
  );
};

export default ExperienceProfilePricing;

ExperienceProfilePricing.fragment = gql`
  fragment ExperienceProfilePricing on Xperience {
    ...ExperiencePricingView
  }
  ${ExperiencePricingView.fragment}
`;
