import { Checkbox, DatePicker, Modal, Select, TimePicker } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useState } from 'react';
import LoadingOverlay from '~/Loading/Overlay';
import Row from '~/Row';
import ScottHr from '~/ScottHr';
import ScottTitle from '~/ScottTitle';
import { mergeDateWithTime } from '~/date';
import { TestEventType } from '~/graphql';
import { sizes } from '~/styles';

const ROW_MARGIN_BOTTOM = sizes.Spacing.Medium;

const CreateTeamEventModal = ({ visible, createTeamEvent, onClose, setSelectedTeamEventId }) => {
  const [requestedFor, setRequestedFor] = useState(moment());
  const [saving, setSaving] = useState(false);
  const [includeVirtualEvent, setIncludeVirtualEvent] = useState(false);
  const [requireInstructor, setRequireInstructor] = useState(false);
  const [testEventType, setTestEventType] = useState<TestEventType>(undefined);

  return (
    <Modal
      title='Create Team Event'
      visible={visible}
      okButtonProps={{
        disabled: !includeVirtualEvent,
      }}
      onOk={() => {
        setSaving(true);

        const variables = {
          requestedFor,
          includeVirtualEvent,
          requireInstructor,
          testEventType,
        };

        createTeamEvent({
          variables,
        }).then(({ data }) => {
          setSelectedTeamEventId(_.get(data, 'createTeamEvent.id'));
          setSaving(false);
          onClose();
        });
      }}
      onCancel={() => {
        onClose();
      }}
    >
      <LoadingOverlay visible={saving} />
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <ScottTitle type='SubSection'>Requested For</ScottTitle>
        <Row css={{ marginBottom: ROW_MARGIN_BOTTOM }}>
          <DatePicker
            allowClear={false}
            placeholder='Date'
            format={'M/D/YYYY'}
            value={requestedFor}
            onChange={newDate => setRequestedFor(mergeDateWithTime(newDate, requestedFor))}
          />
          <TimePicker
            allowClear={false}
            placeholder='Time'
            minuteStep={5}
            format={'h:mm a'}
            value={requestedFor}
            onChange={newTime => setRequestedFor(mergeDateWithTime(requestedFor, newTime))}
          />
        </Row>
        <ScottTitle type='SubSection'>Team Event Options</ScottTitle>
        <Row css={{ marginBottom: ROW_MARGIN_BOTTOM }}>
          <div css={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: ROW_MARGIN_BOTTOM,
              }}
            >
              <Checkbox
                css={{ flex: 0.5 }}
                onChange={() => setIncludeVirtualEvent(!includeVirtualEvent)}
                defaultChecked={false}
              >
                Include Virtual Event?
              </Checkbox>
              <Checkbox
                css={{ flex: 0.5 }}
                disabled={!includeVirtualEvent}
                onChange={() => setRequireInstructor(!requireInstructor)}
                defaultChecked={false}
              >
                Meeting Requires Instructor?
              </Checkbox>
              <Checkbox
                css={{ flex: 0.5 }}
                onChange={() =>
                  !testEventType
                    ? setTestEventType(TestEventType.NoPayments)
                    : setTestEventType(undefined)
                }
                defaultChecked={false}
                value={!!testEventType}
              >
                Test Event?
              </Checkbox>
            </div>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div>Test event type</div>
              {testEventType && (
                <Select
                  onChange={e => setTestEventType(e.toString() as TestEventType)}
                  css={{ width: '100%' }}
                  value={testEventType}
                >
                  {Object.values(TestEventType).map(testType => (
                    <option value={testType} key={testType}>
                      {testType}
                    </option>
                  ))}
                </Select>
              )}
            </div>
            <ScottHr />
          </div>
        </Row>
      </div>
    </Modal>
  );
};

export default CreateTeamEventModal;
