import React from 'react';
import { Select, InputNumber } from 'antd';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import ScottTitle from '~/ScottTitle';
import Row from '~/Row';
import Text from '~/Text';
import _ from 'lodash';
import { pluralize } from 'humanize-plus';

import * as GraphQL from '~/graphql';
import { sizes } from '~/styles';

const CapacitySection = (props: {
  capacityType?: GraphQL.CapacityType;
  totalCapacity?: number;
  setCapacityType(newCapacityType: GraphQL.CapacityType): void;
  setTotalCapacity(newAmount: number): void;
  capacityFromSchedule?: number;
}) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <ScottTitle type='SubSection'>Capacity</ScottTitle>
      <Row justifyContent='flex-start'>
        <LabeledField label='Type'>
          <Select
            size='large'
            data-testid='event-details-capacity-type-selector'
            onChange={props.setCapacityType}
            value={props.capacityType}
            css={{ width: sizes.GRID_UNIT * 30 }}
          >
            {_.map(GraphQL.CapacityType, type => (
              <Select.Option
                key={type}
                data-testid={`event-details-capacity-type-option-${type}`}
                value={type}
                title={type}
              >
                {type}
              </Select.Option>
            ))}
          </Select>
        </LabeledField>
        <Row
          alignItems='flex-start'
          css={{ marginLeft: sizes.Spacing.Medium, width: '100%' }}
        >
          <LabeledField
            label='Amount'
            errorText={
              props.capacityFromSchedule &&
              props.totalCapacity > props.capacityFromSchedule
                ? `Warning: This is ${props.totalCapacity -
                    props.capacityFromSchedule} ${pluralize(
                    props.totalCapacity - props.capacityFromSchedule,
                    'unit',
                    'units',
                  )} larger than the capacity from the schedule.`
                : undefined
            }
          >
            <InputNumber
              size='large'
              data-testid='event-details-capacity-amount-input'
              placeholder='0'
              value={props.totalCapacity}
              onChange={totalCapacity => props.setTotalCapacity(totalCapacity)}
            />
          </LabeledField>
          {props.capacityFromSchedule != null && (
            <Text
              size='XSmall'
              color='GreyDarker'
              css={{ paddingTop: sizes.Spacing.Medium }}
            >{`${props.capacityFromSchedule} total capacity`}</Text>
          )}
        </Row>
      </Row>
    </div>
  );
};

export default CapacitySection;
