import { Tooltip } from 'antd';
import React from 'react';

import { colors, sizes } from '~/styles';

interface Props {
  component: React.FC<any>;
  onClick: any;
  active: boolean;
  label?: string;
}

const NavIcon = ({ component: Component, label, onClick, active }: Props) => (
  <Tooltip title={label} mouseEnterDelay={0.3} placement='right'>
    <div
      css={{
        width: 44,
        height: 44,
        display: 'flex',
        marginBottom: sizes.Spacing.Medium,
        cursor: 'pointer',
        borderRadius: '50%',
        alignItems: 'center',
        boxSizing: 'border-box',
        justifyContent: 'center',
        transition: '.2s ease-in-out all',
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        ':hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
        ':active': { backgroundColor: 'rgba(255, 255, 255, 0.07)' },
      }}
      onClick={onClick}
    >
      <Component size={24} color={active ? colors.White : colors.Grey} />
    </div>
  </Tooltip>
);

export default NavIcon;
