import gql from 'graphql-tag';
import _ from 'lodash';
import React from 'react';

import * as GraphQL from '~/graphql';
import useMobile from '~/hooks/useMobile';
import { sizes } from '~/styles';
import ConversationMessageAuthorAndTime from './AuthorAndTime';
import ConversationMessageWithNewlines from './WithNewlines';

interface Props {
  data: GraphQL.ConversationMessage.Fragment;
}

const ConversationMessage = ({ data }: Props) => {
  const isPhone = useMobile();
  const isInboundMessage = data.type === GraphQL.MessageType.Inbound;

  return (
    <div
      css={{
        alignSelf: isInboundMessage ? 'flex-start' : 'flex-end',
        width: 'fit-content',
        // If the message is longer than a single line (roughly), ensure that
        // the message bubble is wide enough that it doesn't get displayed
        // awkwardly.
        minWidth: _.size(data.content) > 40 ? sizes.GRID_UNIT * 60 : undefined,
        maxWidth: isPhone ? '80%' : '60%',
        ':not(:last-child)': {
          marginBottom: sizes.Spacing.XSmall,
        },
      }}
    >
      <ConversationMessageAuthorAndTime {...data} />
      <ConversationMessageWithNewlines {...data} />
    </div>
  );
};

ConversationMessage.fragment = gql`
  fragment ConversationMessage on Message {
    id
    type
    readAt
    adventure {
      id
      hasUnreadMessages
    }
    user {
      id
      phone
    }
    opsUser {
      id
      firstName
    }
    ...ConversationMessageWithNewlines
    ...ConversationMessageAuthorAndTime
  }

  ${ConversationMessageWithNewlines.fragment}
  ${ConversationMessageAuthorAndTime.fragment}
`;

export default ConversationMessage;
