import React from 'react';
import LabeledSection from '~/LabeledSection';

import EventsTable from '../Events';
import { EventOrderKey, OrderDirection, CapacityType } from '~/graphql';
import moment from 'moment-timezone';
import { DAYS_TO_BE_CONSIDERED_AT_RISK } from '../Events/columns';

const AtRiskSummary = () => {
  return (
    <LabeledSection
      label={`AT RISK`}
      noBodyPadding
      pageLinkText='View All'
      pageLinkPath={`/supply/events?dateFilterType=Custom&startDate=${encodeURIComponent(
        moment()
          .startOf('day')
          .toISOString()
          .split('T')
          .shift(),
      )}&endDate=${encodeURIComponent(
        moment()
          .startOf('day')
          .add(DAYS_TO_BE_CONSIDERED_AT_RISK, 'days')
          .toISOString()
          .split('T')
          .shift(),
      )}&capacityTypes=${encodeURIComponent(CapacityType.PrePurchased)}`}
      viewParts={[
        () => {
          return (
            <div css={{ maxHeight: 250, overflow: 'hidden' }}>
              <EventsTable
                variablesOverride={{
                  filterInput: {
                    capacityType: CapacityType.PrePurchased,
                    startDate: moment().startOf('day'),
                  },
                  order: [
                    { key: EventOrderKey.Start, direction: OrderDirection.Asc },
                  ],
                }}
                columnWhitelist={['partner', 'date', 'time', 'capacity', 'atRisk']}
                clientSideFilter={({ remainingCapacity }) => remainingCapacity > 0}
              />
            </div>
          );
        },
      ]}
    />
  );
};

export default AtRiskSummary;
