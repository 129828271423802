import gql from 'graphql-tag';
import _ from 'lodash';
import React from 'react';
import * as GraphQL from '~/graphql';
import Search from '~/Search';
import { getApolloClient } from '~/services/apollo';

const OrganizationSearch = ({ onSelect, defaultValue, orgId }) => (
  <Search
    onSelect={({ key, title }) => onSelect({ id: key, name: title })}
    onSearch={async (query: string) => {
      const data = await getApolloClient().query<
        GraphQL.OrganizationSearch.Query,
        GraphQL.OrganizationSearch.Variables
      >({ query: OrganizationSearch.query, variables: { query } });
      return _(data.data!.organizations)
        .filter(org => org.id !== orgId)
        .map(org => ({
          key: org.id,
          title: org.internalName,
        }))
        .value();
    }}
    defaultValue={defaultValue}
    onClear={() => onSelect(null)}
  />
);

OrganizationSearch.query = gql`
  query OrganizationSearch($query: String!) {
    organizations(internalName: $query) {
      id
      name
      internalName
    }
  }
`;

export default OrganizationSearch;
