import { Typography } from 'antd';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { formatCents } from '~/formatters';
import { Contract, ContractUnitType } from '~/graphql';
import { CONTRACT_UNIT_PHRASE_LOOKUP, CONTRACT_UNIT_FORMAT_LOOKUP, getContractName } from '~/helpers/contract';

import Column from './Tabs/Column';
import { EditorContract } from './ContractAddEdit';

const ContractOverview = ({
  contract,
  setSelectedContract,
  setShowContractModal,
}: {
  contract?: EditorContract;
  setSelectedContract(contract: EditorContract): void;
  setShowContractModal(show: boolean): void;
}) => {
  if (!contract) {
    return null;
  }

  const unitsFormatter = CONTRACT_UNIT_FORMAT_LOOKUP[contract.type] || (() => 'N/A');
  const contractUnitsRemaining = unitsFormatter(contract.unitCount);
  const contractUnitsPending = unitsFormatter(contract.pendingUnitCount);
  const contractName = getContractName(contract);

  return (
    <div>
      <Typography css={{ fontWeight: 'bold', fontSize: '20px' }}>{contractName}</Typography>
      <div
        css={{ display: 'flex' }}
        onClick={() => {
          setSelectedContract(contract);
          setShowContractModal(true);
        }}
      >
        <Column>
          <Typography css={{ fontWeight: 'bold' }}>Plan start date</Typography>
          <Typography css={{ fontSize: '20px' }}>
            {DateTime.fromISO(contract.startDate).toLocaleString(DateTime.DATE_MED)}
          </Typography>
        </Column>
        <Column>
          <Typography css={{ fontWeight: 'bold' }}>Plan renew date</Typography>
          <Typography css={{ fontSize: '20px' }}>
            {DateTime.fromISO(contract.renewDate).toLocaleString(DateTime.DATE_MED)}
          </Typography>
        </Column>
        <Column>
          <Typography css={{ fontWeight: 'bold' }}>
            {_.startCase(CONTRACT_UNIT_PHRASE_LOOKUP[contract.type])}
          </Typography>
          <Typography
            css={{ fontSize: '20px' }}
          >{`${contractUnitsRemaining} remaining`}</Typography>
          <Typography css={{ fontSize: '10px' }}>
            {contract.unitCostCents ? `$${(contract.unitCostCents / 100).toFixed(2)} / each` : '-'}
          </Typography>
        </Column>
        <Column css={{ marginRight: '0px' }}>
          <Typography css={{ fontWeight: 'bold' }}>Supply & upgrade credits</Typography>
          <Typography css={{ fontSize: '20px' }}>
            {contract.premiumUnitCount ? `${contract.premiumUnitCount} remaining` : '-'}
          </Typography>
          <Typography css={{ fontSize: '10px' }}>
            {contract.premiumUnitCostCents
              ? `$${(contract.premiumUnitCostCents / 100).toFixed(2)} / each`
              : '-'}
          </Typography>
        </Column>
        <Column css={{ marginRight: '0px' }}>
          <Typography css={{ fontWeight: 'bold' }}>Pending units</Typography>
          <Typography css={{ fontSize: '16px' }}>{`${contractUnitsPending} ${
            CONTRACT_UNIT_PHRASE_LOOKUP[contract.type]
          }`}</Typography>
          <Typography css={{ fontSize: '16px' }}>{`${
            contract.pendingPremiumUnitCount
          } supply credits`}</Typography>
        </Column>
      </div>
    </div>
  );
};

export default ContractOverview;
