import { Global } from '@emotion/core';
import React from 'react';

import { memo } from '~/react';
import { colors, font, sizes } from '~/styles';

/**
 * Add any styles that need to be applied globally to this file.
 */

const GlobalStyles = () => (
  <Global
    styles={{
      html: {
        boxSizing: 'border-box',
      },
      '*, *:before, *:after': {
        boxSizing: 'inherit',
      },
      body: {
        overscrollBehaviorY: 'none',
        fontFamily: `${font.FONT_FAMILY} !important`,
      },
      '*': {
        WebkitOverflowScrolling: 'touch',
      },
      '[class*="📦fnt-fam"]': {
        fontFamily: `${font.FONT_FAMILY} !important`,
      },
      '.Polaris-OptionList-Checkbox__Input:focus + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox__Input:active + .Polaris-OptionList-Checkbox__Backdrop': {
        boxShadow: 'none !important',
      },
      '.side-sheet': {
        backgroundColor: colors.White,
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
      '.ui.search .prompt': {
        borderRadius: 4,
      },
      '.ant-input-number-focused input': {
        boxShadow: 'none',
        outline: 'none !important',
      },
      '.ant-input-number-handler-wrap': {
        display: 'none',
      },
      'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      'span.ant-input-suffix': {
        transform: 'initial !important',
        position: 'absolute',
        top: 'calc(50% - 7px) !important',
      },
      '.ant-input-affix-wrapper-lg > span.ant-input-suffix': {
        right: '8px !important',
      },
      'span.ant-input-prefix': {
        transform: 'initial !important',
        position: 'absolute',
        top: 'calc(50% - 8px) !important',
      },
      '.ant-input-affix-wrapper-lg > span.int-input-prefix': {
        left: '12px !important',
      },
      '.ant-input-affix-wrapper > span.ant-input-prefix': {
        left: '10px !important',
      },
      '.ant-input-affix-wrapper .ant-input-lg:not(:first-child)': {
        paddingLeft: 34,
      },
      '.ant-input-lg': {
        ...font.Size.Body,
      },
      'li.ant-timeline-item:last-child .ant-timeline-item-tail': {
        display: 'none',
      },
      '.ant-time-picker-input': {
        ...font.Size.Body,
      },
      'input:not([type=checkbox]):not([type=radio])': {
        WebkitAppearance: 'none',
      },
      '.css-1ii3p2c:not([disabled]):not([data-disabled]):active': {
        backgroundColor: 'rgba(67, 90, 111, 0.06)',
        boxShadow: 'none',
      },
      '.css-1ii3p2c:not([disabled]):not([data-disabled]):focus': {
        backgroundColor: 'none',
        boxShadow: 'none',
      },
      '.css-xeqldi::before, [data-css-xeqldi]::before, .css-xeqldi[data-simulate-before], [data-css-xeqldi][data-simulate-before]': {
        backgroundColor: `rgba(0, 0, 0, 0.5)`,
      },
      '.Polaris-ActionList--active .Polaris-ActionList__Text': {
        fontWeight: font.FontWeight.Medium,
      },
      '.Polaris-Subheading': {
        fontWeight: font.FontWeight.Demi,
        color: colors.OffBlack,
      },
      '.Polaris-ActionList__Item': {
        ...font.Size.Small,
        padding: sizes.Spacing.Small,
        paddingLeft: sizes.Spacing.Large,
        ':focus': {
          boxShadow: 'none !important',
        },
        ':hover': {
          backgroundImage:
            'linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)) !important',
        },
        ':focus:not(:hover)': {
          backgroundImage: 'unset !important',
        },
      },
      '.ReactVirtualized__Table__headerRow, .ReactVirtualized__Table__row': {
        paddingLeft: sizes.Spacing.Large,
        paddingRight: `${sizes.Spacing.Large}px !important`,
      },
      '.ReactVirtualized__Grid.ReactVirtualized__Table__Grid, .ReactVirtualized__Grid.ReactVirtualized__List': {
        outline: 'none',
        minWidth: '100%',
      },
      '.bp3-drawer-header': {
        padding: sizes.Spacing.XSmall,
        paddingLeft: sizes.Spacing.Small,
      },
      '.bp3-heading': {
        ...font.Size.BodyLarge,
        fontWeight: font.FontWeight.Medium,
      },
    }}
  />
);

export default memo(GlobalStyles);
