import React from 'react';
import { Tag, Icon } from 'antd';
import { sizes } from '~/styles';

const UserFacingDataIndicator = () => (
  <Tag color='magenta' css={{ marginLeft: sizes.Spacing.Medium }}>
    <Icon type='eye' css={{ marginRight: sizes.Spacing.XXSmall }} />
    user-facing
  </Tag>
);

export default UserFacingDataIndicator;
