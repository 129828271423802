import gql from 'graphql-tag';
import _ from 'lodash';
import React from 'react';

import { memo } from '~/react';
import * as GraphQL from '~/graphql';

const ConversationMessageAttachments = ({
  attachments,
}: GraphQL.ConversationMessageAttachments.Fragment) =>
  _.size(attachments) ? (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      {_.map(attachments, (attachment, i) => (
        <div key={i} css={{ width: '100%', height: 'auto' }}>
          <img src={attachment} css={{ width: '100%' }} />
        </div>
      ))}
    </div>
  ) : null;

ConversationMessageAttachments.fragment = gql`
  fragment ConversationMessageAttachments on Message {
    attachments
  }
`;

export default memo(ConversationMessageAttachments);
