import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import SearchMultiSelect from '~/Search/MultiSelect';
import * as GraphQL from '~/graphql';

interface Props {
  name?: string;
  firstNamesOnly?: boolean;
  onChange: (data: any) => any;
  value?: GraphQL.SearchUsersMultiSelect.Fragment[] | null;
  role?: GraphQL.Role;
}

const SearchUsersMultiSelect = ({
  name,
  value,
  onChange,
  role,
  firstNamesOnly,
  ...otherProps
}: Props) => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const handleSearchChange = async (query: string) => {
    setLoading(true);

    const data = await client.mutate<
      GraphQL.SearchUsersMultiSelect.Mutation,
      GraphQL.SearchUsersMultiSelect.Variables
    >({
      mutation: SearchUsersMultiSelect.mutation,
      variables: { role, query },
    });

    setLoading(false);

    return SearchUsersMultiSelect.convertIntoTags(
      _.get(data, 'data.searchUsers'),
      firstNamesOnly,
    );
  };

  const onSelect = (selectedOptions: any) =>
    onChange({
      target: {
        options: _(selectedOptions)
          .map('data')
          .uniqBy('id')
          .map(value => ({ selected: true, value }))
          .value(),
        validity: { valid: true },
        name,
      },
    });

  return (
    <SearchMultiSelect
      isLoading={loading}
      placeholder={strings.users}
      loadOptions={handleSearchChange}
      value={SearchUsersMultiSelect.convertIntoTags(value!)}
      onChange={onSelect}
      {...otherProps}
    />
  );
};

const strings = { users: 'Users' };

SearchUsersMultiSelect.convertIntoTags = (
  users: GraphQL.SearchUsersMultiSelect.Fragment[],
  firstNamesOnly = false,
) => {
  return _.map(users, data => ({
    label: firstNamesOnly ? data.firstName : data.name,
    value: data.id,
    data: _.pick(data, 'id', 'firstName', 'name'),
  }));
};

SearchUsersMultiSelect.fragment = gql`
  fragment SearchUsersMultiSelect on User {
    id
    firstName
    name
  }
`;

SearchUsersMultiSelect.mutation = gql`
  mutation SearchUsersMultiSelect($query: String!, $role: Role) {
    searchUsers(query: $query, role: $role) {
      ...SearchUsersMultiSelect
    }
  }

  ${SearchUsersMultiSelect.fragment}
`;

export default SearchUsersMultiSelect;
