import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';

/**
 * Hoist non-React static properties from `WrappedComponent`
 * to `OutputComponent`.
 */

const hoistStatics = <
  TWrappedComponent extends React.ComponentType<any>,
  TOutputComponent extends React.ComponentType<any>
>(
  OutputComponent: TOutputComponent,
  WrappedComponent: TWrappedComponent,
) => hoistNonReactStatics(OutputComponent, WrappedComponent, customStatics);

const customStatics = ['getInitialProps'].reduce(
  (obj, key) => ({ ...obj, [key]: true }),
  {},
);

/**
 * Return a memoized `Component` with its non-React static properties hoisted.
 */

export const memo = <TComponent extends React.FunctionComponent<any>>(
  Component: TComponent,
  propsAreEqual?: (
    prevProps: Readonly<React.PropsWithChildren<React.ComponentProps<TComponent>>>,
    nextProps: Readonly<React.PropsWithChildren<React.ComponentProps<TComponent>>>,
  ) => boolean,
) =>
  hoistStatics(
    React.memo<React.ComponentProps<TComponent>>(Component, propsAreEqual),
    Component,
  );
