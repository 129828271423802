import { useMutation } from '@apollo/react-hooks';
import { Button, Input, Modal, message } from 'antd';
import gql from 'graphql-tag';
import React, { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import * as GraphQL from '~/graphql';
import LabeledField from '~/Labeled/Field';

interface Props {
  partnerId: string;
}

const AddExperienceButton = ({ partnerId }: Props) => {
  const history = useHistory();
  const [isModalVisible, setModalVisible] = useState(false);
  const [name, setName] = useState('');
  const [createExperience, { loading }] = useMutation<
    GraphQL.AddExperienceButton.Mutation,
    GraphQL.AddExperienceButton.Variables
  >(AddExperienceButton.mutation);

  const nameRef = useRef(name);
  nameRef.current = name;
  const onOK = useMemo(() => {
    return async function onOK() {
      try {
        const response = await createExperience({
          variables: { name: nameRef.current, partner: { id: partnerId } },
        });

        if (response.errors) {
          message.error(`Failed to create Xperience: ${JSON.stringify(response.errors)}`, 10);
          console.error(response.errors);
        } else {
          const experience = response.data.createXperience;
          history.push(`/supply/partner/${partnerId}/experience/${experience.id}`);
        }
      } catch (error) {
        message.error(`Failed to create Xperience: ${JSON.stringify(error)}`, 10);
        console.error(error);
      }
    };
  }, [setModalVisible]);

  const onCancel = useMemo(() => {
    return function onCancel() {
      setModalVisible(false);
    };
  }, [setModalVisible]);

  return (
    <>
      <Button onClick={() => setModalVisible(true)}>Add Experience</Button>
      <Modal
        visible={isModalVisible}
        onOk={onOK}
        onCancel={onCancel}
        title='Add an experience'
        okText='Add New Experience'
        okButtonProps={{ loading }}
      >
        <LabeledField label='Experience Name'>
          <Input value={name} onChange={event => setName(event.target.value)} />
        </LabeledField>
      </Modal>
    </>
  );
};

AddExperienceButton.mutation = gql`
  mutation AddExperienceButton($name: String!, $partner: GenericReferenceInput!) {
    createXperience(name: $name, partner: $partner) {
      id
    }
  }
`;

export default AddExperienceButton;
