import React from 'react';
import { Divider, Radio, Typography } from 'antd';
import { colors, font, sizes } from '~/styles';
import { OrganizationUpdateData } from '../../Edit';
import * as GraphQL from '~/graphql';
import InsightsSection from './InsightsSection';
import LabeledSwitch from '~/Input/LabeledSwitch';

const TypeSelection = ({
  updatedData,
  setUpdatedData,
}: {
  updatedData: OrganizationUpdateData;
  setUpdatedData: (data: OrganizationUpdateData) => void;
}) => {
  const typeOptions = [
    { label: 'Transactional', value: GraphQL.CustomerType.Transactional },
    { label: 'Events only', value: GraphQL.CustomerType.EventsOnly },
    { label: 'Events + Insights', value: GraphQL.CustomerType.EventsAndInsights },
  ];
  const options = typeOptions.map(({ label, value }) => {
    const isChecked = () => value === updatedData.customerType;
    return (
      <Radio value={value} css={{ fontWeight: isChecked() ? 'bold' : 'normal' }}>
        {label}
      </Radio>
    );
  });
  return (
    <>
      <Typography css={{ fontSize: '30px', fontWeight: 'bold' }}>Type selection</Typography>
      <Typography css={{ color: colors.GreyDark }}>Select one of the types below</Typography>
      <Radio.Group
        value={updatedData.customerType}
        onChange={e => setUpdatedData({ ...updatedData, customerType: e.target.value })}
        css={{ marginTop: sizes.Spacing.Small }}
      >
        {options}
      </Radio.Group>
    </>
  );
};

export const TypeSettingSwitch = ({ checked, onChange, label }) => {
  const checkedLabel = isChecked => (
    <Typography css={{ marginLeft: sizes.Spacing.XXSmall }}>
      {label} {isChecked ? ' Enabled' : ' Disabled'}
    </Typography>
  );
  return (
    <LabeledSwitch
      checked={checked}
      onChange={onChange}
      checkedLabel={checkedLabel(true)}
      uncheckedLabel={checkedLabel(false)}
    />
  );
};

export const TypeSettingHeader = ({ title, subtitle }) => (
  <>
    <Typography
      css={{ fontSize: '20px', fontWeight: font.FontWeight.Demi, color: colors.GreyDark }}
    >
      {title}
    </Typography>
    <Typography css={{ color: colors.GreyDark, marginTop: sizes.Spacing.XXSmall }}>
      {subtitle}
    </Typography>
  </>
);

const CustomerTypeTab = ({
  updatedData,
  setUpdatedData,
}: {
  updatedData: OrganizationUpdateData;
  setUpdatedData: (data: OrganizationUpdateData) => void;
}) => {
  const showTypeSettings = () =>
    updatedData.customerType === GraphQL.CustomerType.EventsAndInsights;
  return (
    <div css={{ marginBottom: sizes.Spacing.XLarge }}>
      <TypeSelection updatedData={updatedData} setUpdatedData={setUpdatedData} />
      {showTypeSettings() && (
        <div css={{ marginTop: sizes.Spacing.Large }}>
          <Typography css={{ fontSize: '30px', fontWeight: 'bold' }}>Type settings</Typography>
          <InsightsSection updatedData={updatedData} setUpdatedData={setUpdatedData} />
          <Divider type='horizontal' />
        </div>
      )}
    </div>
  );
};

export default CustomerTypeTab;
