import React from 'react';
import gql from 'graphql-tag';
import * as GraphQL from '~/graphql';
import { sizes } from '~/styles';

const CreditCardCell = ({
  last4,
  expMonth,
  expYear,
}: GraphQL.CreditCardCell.Fragment) => (
  <div css={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <div>●●●● ●●●● ●●●● {last4}</div>
    <div css={{ marginLeft: sizes.Spacing.Small }}>
      {expMonth} / {expYear}
    </div>
  </div>
);

export default CreditCardCell;

CreditCardCell.fragment = gql`
  fragment CreditCardCell on CreditCard {
    id
    last4
    expMonth
    expYear
  }
`;
