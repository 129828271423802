import gql from 'graphql-tag';

import { makeQueryBasedSelect } from './QueryBasedSelect';

const SelectDietaryRestrictions = makeQueryBasedSelect(
  gql`
    query SelectDietaryRestrictions {
      dietaryRestrictions {
        id
        name
      }
    }
  `,
  'dietaryRestrictions',
);

export default SelectDietaryRestrictions;
