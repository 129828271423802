import React from 'react';
import { titleCaseEnum } from '~/formatters';
import { memo } from '~/react';
import MultiSelectFilter from './MultiSelect';

interface optionsInterface {
  [key: string]: string;
}

const FilterTeamEventStatus = ({
  options,
  defaultValues = [],
  title = 'Team Event Status',
  name = 'teamEventStatus',
}: {
  options: optionsInterface;
  defaultValues?: string[];
  title?: string;
  name?: string;
}) => {
  const optionsWithLabel = Object.keys(options).map(o => ({
    value: o,
    label: titleCaseEnum(o),
  }));
  return (
    <MultiSelectFilter
      title={title}
      name={name}
      options={optionsWithLabel}
      defaultValues={defaultValues}
    />
  );
};

export default memo(FilterTeamEventStatus);
