import { Input } from 'antd';

import { DataTableFilter, GraphQLFiltersInput } from '../interface';
import { KeysOfType } from '../utilTypes';

/**
 * A <DataTable> filter that displays a string input field.
 */
export function stringFilter<
  TFilters extends GraphQLFiltersInput,
  TFilterKey extends KeysOfType<TFilters, string> = KeysOfType<TFilters, string>
>(key: TFilterKey, title: string): DataTableFilter<TFilters, TFilterKey, string> {
  return {
    field: key,
    title,
    component: ({ value, onChange }) => (
      <Input
        value={value}
        css={{ width: '100%' }}
        onChange={(e: any) => onChange(e.target.value)}
      />
    ),
  };
}
