import React from 'react';
import { sizes, colors } from '~/styles';

const ScottSection = ({
  children,
  noPadding,
  ...otherProps
}: {
  children?: React.ReactNode;
  noPadding?: boolean;
}) => {
  return (
    <div
      css={{
        borderRadius: sizes.GRID_UNIT * 2,
        border: `1px solid ${colors.Smoke}`,
        padding: !noPadding ? sizes.GRID_UNIT * 4 : undefined,
      }}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default ScottSection;
