import React from 'react';
import Text from '~/Text';
import { sizes } from '~/styles';

const SectionTitle = ({ title }) => (
  <Text
    size='Small'
    weight='Demi'
    color='Black'
    css={{
      marginLeft: sizes.Spacing.XSmall,
      marginBottom: sizes.Spacing.XXSmall,
    }}
  >
    {title}
  </Text>
);

export default SectionTitle;
