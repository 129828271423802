import CreditInput from '~/Input/Credit';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import PriceColumn from './PriceColumn';

const CreditInputColumn = ({ value, onChange, title, label, inputDataTestId, isUserFacing }) => (
  <PriceColumn label={title}>
    <LabeledField label={label} isUserFacing={isUserFacing}>
      <CreditInput value={value} onChange={onChange} data-testid={inputDataTestId} />
    </LabeledField>
  </PriceColumn>
);

export default CreditInputColumn;
