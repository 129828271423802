import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { InfiniteLoader, List } from 'react-virtualized';
import * as GraphQL from '~/graphql';
import { usePaginatedQuery } from '~/PaginatedList/helpers';
import AuditLogCell from '~/AuditLogCell';
import _ from 'lodash';
import useContainerSize from '~/hooks/useContainerSize';
import { sizes, colors } from '~/styles';
import FillRemaining from '~/FillRemaining';
import FullScreenSpinner from '~/FullScreenSpinner';
import { useQueryParams, StringParam, ArrayParam } from 'use-query-params';
import { Result } from 'antd';
import { memo } from '~/react';

const CIRCLE_SIZE = 10;

const ActivityFeed = (props: { onlyLastXChanges?: number }) => {
  const [params] = useQueryParams({
    actorId: StringParam,
    entityTypes: ArrayParam,
  });

  const variables = useRef<GraphQL.ActivityFeed.Variables>(
    getDefaultActivityFeedVariables(props.onlyLastXChanges),
  );

  _.assign(variables.current, {
    actorId: params.actorId,
    entityTypes: params.entityTypes,
  });

  const { rows, count, paginate, hasNextPage, loading } = usePaginatedQuery<
    GraphQL.ActivityFeed.Query,
    GraphQL.ActivityFeed.Variables
  >(ActivityFeed.query, variables.current, 'auditLogsConnection');

  const containerRef = useRef(null as any);

  const size = useContainerSize(containerRef);

  return (
    <FillRemaining ref={containerRef}>
      <InfiniteLoader
        threshold={15}
        minimumBatchSize={10}
        rowCount={props.onlyLastXChanges || count}
        loadMoreRows={
          (() => {
            if (loading) return Promise.resolve();
            return paginate();
          }) as any
        }
        isRowLoaded={({ index }) => !hasNextPage || index < _.size(rows)}
      >
        {({ onRowsRendered, registerChild }) => {
          return loading ? (
            <FullScreenSpinner />
          ) : _.size(rows) > 0 ? (
            <List
              css={{
                paddingLeft: sizes.Spacing.Medium,
                paddingTop: sizes.Spacing.Medium,
              }}
              width={size.width - 2 * sizes.Spacing.Medium /*padding*/}
              height={size.height}
              ref={registerChild}
              rowHeight={50}
              overscanRowCount={10}
              rowCount={count}
              onRowsRendered={onRowsRendered}
              rowRenderer={({ key, index, style }) =>
                rows[index] ? (
                  <div
                    key={`${key}-${rows[index].id}`}
                    css={{
                      marginBottom: sizes.Spacing.Small,
                      display: 'flex',
                    }}
                    style={style}
                  >
                    <div
                      css={{
                        border: `2px solid ${colors.Grey}`,
                        borderRadius: '50%',
                        width: CIRCLE_SIZE,
                        height: CIRCLE_SIZE,
                        marginRight: sizes.Spacing.XSmall,
                        position: 'relative',
                        top: 3,
                      }}
                    >
                      {index !== _.size(rows) - 1 && (
                        <div
                          css={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: CIRCLE_SIZE - 2 /*border width*/,
                            margin: 'auto',
                            width: 2,
                            height: 40,
                            backgroundColor: colors.GreyLight,
                          }}
                        />
                      )}
                    </div>

                    {rows[index] && (
                      <AuditLogCell
                        {...rows[index]}
                        shouldLabelEntity={true}
                        key={key}
                        css={{
                          paddingTop: sizes.Spacing.Small,
                          overflow: 'visible',
                        }}
                      />
                    )}
                  </div>
                ) : null
              }
              noContentRenderer={() => <span>Empty row!</span>}
            />
          ) : (
            <Result status={404} subTitle='No logs found' />
          );
        }}
      </InfiniteLoader>
    </FillRemaining>
  );
};

export default memo(ActivityFeed);

export const getDefaultActivityFeedVariables = (first = 20) => ({
  first,
  after: null,
  search: null,
  eventNames: [GraphQL.EventName.QueryDataChanged, GraphQL.EventName.EntityApproval],
});

ActivityFeed.query = gql`
  query ActivityFeed(
    $first: Int
    $after: String
    $eventNames: [EventName]
    $entityTypes: [String]
    $actorId: String
  ) {
    auditLogsConnection(
      first: $first
      after: $after
      eventNames: $eventNames
      entityTypes: $entityTypes
      actorId: $actorId
    ) @connection(key: "auditLogsConnection", filter: ["eventNames", "entityTypes", "actorId"]) {
      edges {
        node {
          id
          ...AuditLogCell
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${AuditLogCell.fragment}
`;
