import ScottTitle from '~/ScottTitle';

interface Props {
  label: string;
  children: React.ReactNode;
  dataTestId?: string;
}

const PriceColumn = ({ label, children, dataTestId }: Props) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'column',
    }}
    data-testid={dataTestId}
  >
    <ScottTitle type='SubSection'>{label}</ScottTitle>
    {children}
  </div>
);

export default PriceColumn;
