import _ from 'lodash';
import React, { useState } from 'react';

import Ellipsis from '~/Ellipsis';
import ExperiencesPriceLevel from '~/Experiences/PriceLevel';
import * as GraphQL from '~/graphql';
import Link from '~/Link';
import LongPress from '~/LongPress';
import { memo } from '~/react';
import { colors, font, sizes } from '~/styles';

interface Props {
  rowData: any;
}

const ExperiencesMobileRow = (props: Props) => {
  const { rowData, ...otherProps } = props;
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [editorVisible, setEditorVisible] = useState(false);

  const { address, shortAddress } = getAddress(rowData);

  const experienceName = (
    <div
      css={{
        ...font.Size.Heading,
        fontWeight: font.FontWeight.Medium,
        flex: 1,
      }}
    >
      <Ellipsis text={rowData.name} />
    </div>
  );

  const addressLink = (
    <div
      css={{
        ...font.Size.Body,
        flex: 1,
      }}
    >
      <Link
        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
        target='_blank'
      >
        <Ellipsis text={shortAddress} component='span' />
      </Link>
    </div>
  );

  return (
    <>
      <LongPress onLongPress={() => setEditorVisible(true)} onPress={() => setDetailsVisible(true)}>
        <div {...otherProps} css={{ display: 'flex', alignItems: 'center' }}>
          <div
            css={{
              padding: sizes.Spacing.Medium,
              borderBottom: `1px solid ${colors.DarkSnow}`,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'space-between',
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: sizes.Spacing.XXSmall,
              }}
            >
              {experienceName}
            </div>

            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {addressLink}
              <ExperiencesPriceLevel level={rowData['priceLevel']} size={14} readonly />
            </div>
          </div>
        </div>
      </LongPress>
    </>
  );
};

const getAddress = (rowData: any) => {
  const location = _.get(rowData, 'location');
  const fullAddress = location.address.full!;
  const splitAddress = fullAddress.split(', ');
  splitAddress.pop();

  const address = splitAddress.join(', ');

  const [streetAddress, city] = _.get(location, 'address.full', '')
    .split(', ')
    .slice(0, 2);
  const neighborhood = _.get(location, 'address.neighborhood');

  const isSeattle = city === 'Seattle';
  const shortAddress = _.compact([
    streetAddress,
    isSeattle ? neighborhood : undefined,
    isSeattle ? undefined : city,
  ]).join(', ');

  return { address, shortAddress };
};

export default memo(ExperiencesMobileRow);
