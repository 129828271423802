import React, { useState } from 'react';
import moment from 'moment-timezone';
import { addSpacesToPascalCasedString } from '~/formatters';
import Text from '~/Text';
import { oxford } from 'humanize-plus';

import * as GraphQL from '~/graphql';
import { sizes } from '~/styles';
import { Popover, Table, Modal, Tooltip } from 'antd';
import _ from 'lodash';
import Link from '~/Link';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import { isPrimitive, isAcceptableArray, transformBeforeAfterData } from './Entities/helpers';
import EntityLink from './EntityLink';

// TODO: refactor this to share code with DefaultCell
const ApprovalCell = ({
  occurredAt,
  actor,
  metadata,
  entityType,
  entityId,
  shouldLabelEntity,
}: GraphQL.AuditLogCell.Fragment & { shouldLabelEntity?: boolean }) => {
  const lowerCaseEntity = _.lowerCase(entityType);
  const uiFriendlyEntity = lowerCaseEntity === 'xperience' ? 'experience' : lowerCaseEntity;

  const actorName = actor.firstName;
  const beforeMetadata = _.get(metadata, `before.${lowerCaseEntity}`);
  const afterMetadata = _.get(metadata, `after.${lowerCaseEntity}`);

  if (!beforeMetadata) {
    return (
      <Text size='Small'>
        {`Initial approval of ${uiFriendlyEntity} `}
        <EntityLink entityType={entityType} entityId={entityId} />
        {` by ${actorName}`}
      </Text>
    );
  }

  const thingsThatWereUpdated = Object.keys(beforeMetadata || afterMetadata || {}).map(
    propertyName => cleanupPropertyName(propertyName),
  );

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const data = _.map(beforeMetadata, (beforeMeta, propertyName) => ({
    propertyName: cleanupPropertyName(propertyName),
    before: beforeMeta,
    after: afterMetadata[propertyName],
  }));

  const showHover = _.every(
    data,
    ({ before, after }) =>
      (isPrimitive(before) || isAcceptableArray(before)) &&
      (isPrimitive(after) || isAcceptableArray(after)) &&
      before !== after,
  );

  const numberOfUpdatedThings = _.size(thingsThatWereUpdated);

  const body = (
    <Text size='Small'>
      {actorName} approved changes to {thingsThatWereUpdated.length === 1 ? 'the ' : ''}
      {showHover
        ? thingsThatWereUpdated.map((updatedThing, i) => {
            const thisThingsData = _.find(
              data,
              ({ propertyName }) => propertyName === updatedThing,
            );
            return (
              <>
                <Popover
                  placement={'right'}
                  content={
                    <div css={{ display: 'flex', flexDirection: 'column' }}>
                      <LabeledField label='before'>
                        <Text size='Small'>{transformBeforeAfterData(thisThingsData.before)}</Text>
                      </LabeledField>
                      <LabeledField label='after' css={{ marginTop: sizes.Spacing.Small }}>
                        <Text size='Small'>{transformBeforeAfterData(thisThingsData.after)}</Text>
                      </LabeledField>
                    </div>
                  }
                >
                  <Link css={{ fontWeight: 400 }} onClick={() => setIsDetailsModalOpen(true)}>
                    {updatedThing}
                  </Link>
                </Popover>
                {numberOfUpdatedThings >= 3 && i <= numberOfUpdatedThings - 2 && ', '}
                {numberOfUpdatedThings >= 2 && i + 2 === numberOfUpdatedThings && ' and '}
              </>
            );
          })
        : oxford(thingsThatWereUpdated)}
      {shouldLabelEntity && ` for ${uiFriendlyEntity} `}
      {shouldLabelEntity && <EntityLink entityType={entityType} entityId={entityId} />}
    </Text>
  );

  return (
    <>
      {body}
      <Modal
        destroyOnClose
        title={`Approval by ${actorName} on ${moment(occurredAt).format('llll')}`}
        visible={isDetailsModalOpen}
        onCancel={() => setIsDetailsModalOpen(false)}
        footer={null}
      >
        <Table
          bordered
          footer={null}
          pagination={false}
          size='small'
          dataSource={data}
          columns={[
            {
              title: 'Property',
              dataIndex: 'propertyName',
              key: 'propertyName',
            },
            {
              title: 'Previously Approved Values',
              dataIndex: 'before',
              key: 'before',
              render: dataRenderer,
            },
            {
              title: 'Approved Values',
              dataIndex: 'after',
              key: 'after',
              render: dataRenderer,
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default ApprovalCell;

const cleanupPropertyName = (eventName: string): string => {
  return addSpacesToPascalCasedString(eventName)
    .split(' ')
    .map(word => (word.toLowerCase() === 'xperience' ? 'experience' : word))
    .join(' ')
    .toLowerCase();
};
const dataRenderer = data => {
  try {
    return <Text size='Small'>{transformBeforeAfterData(data)} </Text>;
  } catch (error) {
    return (
      <Tooltip placement='right' mouseEnterDelay={0.7} title={JSON.stringify(data, null, 2)}>
        <Text color='Grey' size='Small'>
          RAW JSON DATA
        </Text>
      </Tooltip>
    );
  }
};
