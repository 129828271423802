import gql from 'graphql-tag';
import React from 'react';

import Text from '~/Text';
import { formatPhone } from '~/formatters';
import * as GraphQL from '~/graphql';
import RowComponent from '~/RowComponent';
import { sizes, colors } from '~/styles';

interface Props {
  index: number;
  data: GraphQL.UsersListRow.Fragment;
  isSelected: boolean;
  onClickRow: (user: { id: string }) => void;
}

const UsersListRow = ({ data, onClickRow, isSelected, ...otherProps }: Props) => {
  const email = data.email ? data.email : null;
  const phone = data.phone ? formatPhone(data.phone) : null;
  const label = data.name ? data.name : phone;
  return (
    <RowComponent
      data={data}
      isSelected={isSelected}
      css={{
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      onClick={onClickRow}
      {...otherProps}
    >
      <Text
        weight='Demi'
        css={{
          marginBottom: sizes.Spacing.XSmall,
          color: isSelected ? colors.AntdBlue : colors.Black,
        }}
      >
        {label}
      </Text>
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        {phone && phone !== label && (
          <Text
            size='Small'
            color='GreyDark'
            css={{ marginBottom: sizes.Spacing.XXXSmall }}
          >
            {phone}
          </Text>
        )}
        {email && (
          <Text size='Small' color='GreyDark'>
            {email}
          </Text>
        )}
      </div>
    </RowComponent>
  );
};

UsersListRow.fragment = gql`
  fragment UsersListRow on User {
    id
    name
    phone
    email
  }
`;

export default UsersListRow;
