export enum Size {
  XXXSmall = 'XXXSmall',
  XXSmall = 'XXSmall',
  XSmall = 'XSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
  XXLarge = 'XXLarge',
  XXXLarge = 'XXXLarge',
}

// A transform that we apply to some items (tags, buttons, etc.) to make them
// scale better for mobile viewing.
export const MOBILE_TRANSFORM = 'scale(0.85)';

export const GRID_UNIT = 5;
export const SUB_GRID_UNIT = GRID_UNIT / 2;
export const TWELVE = SUB_GRID_UNIT * 3;

export const BorderRadius = {
  Small: 2,
  Medium: 4,
  Large: 8,
  XXXLarge: 100,
};

export const Spacing = {
  None: 0,
  XXXSmall: SUB_GRID_UNIT,
  XXSmall: GRID_UNIT,
  XSmall: GRID_UNIT * 2,
  Small: GRID_UNIT * 3,
  Medium: GRID_UNIT * 4,
  Large: GRID_UNIT * 6,
  XLarge: GRID_UNIT * 8,
  XXLarge: GRID_UNIT * 12,
  XXXLarge: GRID_UNIT * 16,
};
