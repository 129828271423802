import gql from 'graphql-tag';
import _ from 'lodash';
import React from 'react';
import Linkify from 'react-linkify';

import * as GraphQL from '~/graphql';
import useMobile from '~/hooks/useMobile';
import { memo } from '~/react';
import { colors, font, sizes } from '~/styles';
import ConversationMessageAttachments from './Attachments';

const ConversationMessageWithNewlines = ({
  type,
  content,
  attachments,
}: GraphQL.ConversationMessageWithNewlines.Fragment) => {
  const isPhone = useMobile();

  const inbound = type === GraphQL.MessageType.Inbound;
  const color = inbound ? colors.OffBlack : colors.White;

  const parts = _.compact(content.split('\n'));
  return (
    <div
      css={{
        color,
        borderRadius: 8,
        backgroundColor: inbound ? colors.DarkSnow : colors.DarkBlue,
        padding: sizes.Spacing.XSmall,
        paddingLeft: sizes.Spacing.Small,
        paddingRight: sizes.Spacing.Small,
        ...(isPhone ? font.Size.Body : font.Size.BodyLarge),
        a: { color, textDecoration: 'underline' },
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {_.map(parts, (part, i) => (
        <Linkify key={i} properties={{ target: '_blank' }}>
          {part}
        </Linkify>
      ))}
      <ConversationMessageAttachments attachments={attachments} />
    </div>
  );
};

ConversationMessageWithNewlines.fragment = gql`
  fragment ConversationMessageWithNewlines on Message {
    id
    type
    content
    ...ConversationMessageAttachments
  }

  ${ConversationMessageAttachments.fragment}
`;

export default memo(ConversationMessageWithNewlines);
