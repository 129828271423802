import { Alert } from 'antd';

interface Props {
  text: string;
  type?: 'success' | 'info' | 'warning' | 'error';
  className?: string;
  icon?: React.ReactNode;
}

const Banner = ({ text, type = 'info', className, icon }: Props) => (
  <Alert message={text} type={type} className={className} icon={icon} banner />
);

export default Banner;
