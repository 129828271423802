import gql from 'graphql-tag';
import moment from 'moment-timezone';

import * as GraphQL from '~/graphql';
import DataTable, { filters } from '~/DataTable';
import Link from '~/Link';
import { colors, sizes } from '~/styles';
import { Icon } from 'antd';

const PartnersTable = (props: object) => {
  return (
    <DataTable<GraphQL.PartnersTable.Node, GraphQL.PartnersTable.Variables>
      {...props}
      query={query}
      queryPath={['partnersConnection']}
      defaultSort={{
        Name: GraphQL.OrderDirection.Asc,
        Tier: GraphQL.OrderDirection.Asc,
      }}
      columns={[
        {
          header: 'Partner',
          width: 350,
          sortKey: GraphQL.PartnerOrderKey.Name,
          cell: ({ id, name }) => (
            <Link href={`/supply/partner/${id}`} useReactRouterLink>
              {name}
            </Link>
          ),
        },
        {
          header: 'Status',
          width: 150,
          sortKey: GraphQL.PartnerOrderKey.Status,
          filter: filters.enumMultiFilter('statuses', 'Statuses', GraphQL.PartnerStatus),
          cell: ({ status }) => <PartnerStatus status={status} />,
        },
        {
          header: 'Quality',
          width: 200,
          sortKey: GraphQL.PartnerOrderKey.Quality,
          filter: filters.enumMultiFilter('qualities', 'Qualities', GraphQL.PartnerQuality),
          cell: ({ quality }) => <PartnerQuality quality={quality} />,
        },
        {
          header: 'Last Updated',
          width: 150,
          sortKey: GraphQL.PartnerOrderKey.UpdatedAt,
          cell: ({ updatedAt }) => moment(updatedAt).fromNow(),
        },
      ]}
    />
  );
};

const statusColors: Record<GraphQL.PartnerStatus, string> = {
  Lead: '#2F80ED', // Blue 500
  Qualified: '#9C27B0', // Magenta 500
  Vetting: '#ED6C02', // Orange 500
  Active: '#2E7D32', // Green 500
  Deactivated: '#D32F2F', // Red 500
  Rejected: '#D32F2F', // Red 500
};

const PartnerStatus = ({ status }: { status: GraphQL.PartnerStatus }) => {
  return (
    <span
      css={{
        display: 'inline-block',
        backgroundColor: statusColors[status],
        color: '#ffffff',
        borderRadius: 20,
        padding: `0.25em 0.625em`,
        margin: `-0.25em 0`,
      }}
    >
      {status}
    </span>
  );
};

const qualityStars: Record<GraphQL.PartnerQuality, number> = {
  Unknown: 0,
  Standard: 1,
  Preferred: 2,
  Elite: 3,
};
const maxQualityStars = 3;

const PartnerQuality = ({ quality }: { quality: GraphQL.PartnerQuality }) => {
  if (quality === GraphQL.PartnerQuality.Unknown) {
    return <div css={{ fontStyle: 'italic', color: colors.Silver }}>None</div>;
  }

  let stars = [] as React.ReactNode[];
  for (let i = 0; i < maxQualityStars; i++) {
    stars.push(
      <Icon
        key={i}
        type='star'
        css={{
          color: i < qualityStars[quality] ? colors.AntdBlue : colors.Grey,
          marginLeft: sizes.Spacing.XXXSmall,
        }}
      />,
    );
  }

  return (
    <div css={{ display: 'flex' }}>
      <div css={{ flex: 1 }}>{quality}</div>
      <div css={{ display: 'flex', alignItems: 'center', marginLeft: sizes.Spacing.Small }}>
        {stars}
      </div>
    </div>
  );
};

const query = gql`
  query PartnersTable(
    $first: Int
    $after: String
    $order: [PartnerOrderArgs]
    $filterInput: PartnerFilterInput
  ) {
    partnersConnection(first: $first, after: $after, order: $order, filterInput: $filterInput)
      @connection(key: "partnersConnection", filter: ["order", "filterInput"]) {
      edges {
        node {
          id
          name
          status
          quality
          updatedAt
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export default PartnersTable;
