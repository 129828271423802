import React from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';
import Text from '~/Text';
import UserFacingDataIndicator from '~/UserFacingDataIndicator';

const CustomModal = (
  props: ModalProps & {
    children: React.ReactNode;
    enterShouldSubmit?: boolean;
    showUserFacingDataTag?: boolean;
  },
) => {
  const { children, enterShouldSubmit = true, ...modalProps } = props;
  const title = (
    <div css={{ display: 'flex', alignItems: 'center' }}>
      <Text size='Large' weight='Demi'>
        {modalProps.title}
      </Text>
      {props.showUserFacingDataTag && <UserFacingDataIndicator />}
    </div>
  );

  const body = enterShouldSubmit ? (
    <form
      onSubmit={e => {
        e.preventDefault();
        // if the ok button is disabled, don't let enter click it
        if (!(modalProps.okButtonProps && modalProps.okButtonProps.disabled))
          return modalProps.onOk(undefined);
      }}
    >
      {children}
      <input
        type='submit'
        value='submit'
        css={{ visibility: 'hidden', height: 0 }}
      />
    </form>
  ) : (
    children
  );

  return (
    <Modal {...modalProps} title={title}>
      {body}
    </Modal>
  );
};

export default CustomModal;
