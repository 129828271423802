import gql from 'graphql-tag';
import _ from 'lodash';

import Conversation from '~/Conversation';
import ConversationMessage from '~/Conversation/Message';
import ConversationSingleUser, {
  getDefaultConversationSingleUserVariables,
} from '~/Conversation/SingleUser';
import * as GraphQL from '~/graphql';
import MessagesList, { getDefaultMessagesListVariables } from '~/Messages/List';
import { getApolloClient } from '~/services/apollo';

const handleNewMessage = async ({ messageId }: any) => {
  const client = getApolloClient();

  const queryResult = await client.query<
    GraphQL.HandleNewMessage.Query,
    GraphQL.HandleNewMessage.Variables
  >({
    query: handleNewMessage.query,
    variables: { id: messageId },
  });

  const message = queryResult.data.message;

  if (!message) return;

  writeToConversation(message);
  writeToUsersList(message);

  return message;
};

const writeToUsersList = (message: GraphQL.HandleNewMessage.Fragment) => {
  const client = getApolloClient();

  try {
    const queryAndVariables = {
      query: MessagesList.query,
      variables: {
        ...getDefaultMessagesListVariables(),
      },
    };

    const cachedResult = client.readQuery<
      GraphQL.UsersList.Query,
      GraphQL.UsersList.Variables
    >(queryAndVariables);

    if (
      !cachedResult ||
      !cachedResult.usersConnection ||
      !cachedResult.usersConnection.edges
    )
      return;

    client.writeQuery<GraphQL.UsersList.Query, GraphQL.UsersList.Variables>({
      ...queryAndVariables,
      data: {
        ...cachedResult,
        usersConnection: {
          ...cachedResult.usersConnection,
          edges: _.uniqBy(
            [
              { node: message.user, __typename: 'UserEdge' },
              ...cachedResult.usersConnection.edges,
            ],
            'node.id',
          ) as any,
        },
      },
    });
  } catch (e) {}
};

const writeToConversation = (message: GraphQL.HandleNewMessage.Fragment) => {
  const client = getApolloClient();

  // This fails if we don't already have paginated messages for the user in the
  // cache. Just swallow the error.
  try {
    const queryAndVariables = {
      query: ConversationSingleUser.query,
      variables: {
        ...getDefaultConversationSingleUserVariables(),
        userIds: [message.user.id],
      },
    };

    const cachedResult = client.readQuery<
      GraphQL.ConversationSingleUser.Query,
      GraphQL.ConversationSingleUser.Variables
    >(queryAndVariables);

    if (
      !cachedResult ||
      !cachedResult.messagesConnection ||
      !cachedResult.messagesConnection.edges
    )
      return;

    client.writeQuery<
      GraphQL.ConversationSingleUser.Query,
      GraphQL.ConversationSingleUser.Variables
    >({
      ...queryAndVariables,
      data: {
        ...cachedResult,
        messagesConnection: {
          ...cachedResult.messagesConnection,
          edges: [
            { node: message, __typename: 'MessageEdge' },
            ...cachedResult.messagesConnection.edges,
          ],
        },
      },
    });
  } catch (e) {}
};

handleNewMessage.fragment = gql`
  fragment handleNewMessage on Message {
    ...ConversationMessage
    user {
      ...ConversationUser
    }
  }

  ${ConversationMessage.fragment}
  ${Conversation.userFragment}
`;

handleNewMessage.query = gql`
  query handleNewMessage($id: ID!) {
    message(id: $id) {
      ...handleNewMessage
    }
  }

  ${handleNewMessage.fragment}
`;

export default _.memoize(handleNewMessage);
