import { Button, message, Modal } from 'antd';
import gql from 'graphql-tag';
import React from 'react';
import * as GraphQL from '~/graphql';
import { sizes } from '~/styles';

import { useMutation } from '@apollo/react-hooks';

const ArchiveContractModal = ({
  contractId,
  visible,
  onClose,
}: {
  contractId: string;
  visible: boolean;
  onClose: () => void;
}) => {
  const [archive, { loading }] = useMutation<
    GraphQL.ContractArchive.Mutation,
    GraphQL.ContractArchive.Variables
  >(ArchiveContractModal.mutation, {
    variables: { contractId },
    refetchQueries: ['OrganizationEditor'],
  });

  return (
    <Modal footer={null} visible={visible} title='Confirm Archive' onCancel={onClose}>
      <h1
        css={{
          marginBottom: sizes.Spacing.Large,
          fontSize: '3rem',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Are you sure you'd like to archive this contract?
      </h1>
      <div css={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <Button type='ghost' onClick={onClose}>
          Cancel
        </Button>
        <Button
          loading={loading}
          type='danger'
          onClick={async () => {
            await archive();
            message.success('Contract archived!');
            onClose();
          }}
        >
          Archive
        </Button>
      </div>
    </Modal>
  );
};

ArchiveContractModal.mutation = gql`
  mutation ContractArchive($contractId: ID!) {
    archiveContract(id: $contractId) {
      id
    }
  }
`;

export default ArchiveContractModal;
