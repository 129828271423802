import gql from 'graphql-tag';

import { makeQueryBasedSelect } from './QueryBasedSelect';

const SelectTemplateTheme = makeQueryBasedSelect(
  gql`
    query SelectTemplateTheme {
      mysteryThemes {
        id
        name: title
        orderIndex
        logoUrl
      }
    }
  `,
  'mysteryThemes',
);

export default SelectTemplateTheme;
