import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Input, message, Select } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import * as GraphQL from '~/graphql';
import { TeamEventIssueCategory } from '~/graphql';
import { TEAM_EVENT_ISSUE_NAME_MAP } from '~/helpers/eventIssues';
import Row from '~/Row';
import ScottHr from '~/ScottHr';
import { borders, colors, font, sizes } from '~/styles';

const STYLES = {
  root: {
    width: '100%',
    '.ant-select-selection': {
      padding: sizes.Spacing.XXSmall,
    },
    '.ant-select-selection__choice': {
      display: 'flex',
      alignItems: 'center',
      height: 'auto',
      width: 'auto',
      padding: `${sizes.Spacing.Small}px ${sizes.Spacing.Medium}px`,
      borderRadius: sizes.BorderRadius.XXXLarge,
      color: colors.White,
      backgroundColor: colors.Red.Red500,
    },
    '.ant-select-selection__choice__content': {
      ...font.Size.BodyLarge,
      marginRight: sizes.Spacing.Small,
    },
    '.ant-select-selection__choice__remove': {
      color: colors.White,
      marginRight: sizes.Spacing.XSmall,
    },
  },
};

type TitleRowProps = {
  children: ReactNode;
  className?: string;
};

type ColumnProps = {
  spacing?: number;
  children: ReactNode;
  className?: string;
};

const TitleRow = ({ children, className }: TitleRowProps) => {
  return (
    <div
      css={{
        ...font.Size.Heading,
        color: colors.Blue.Blue500,
      }}
      className={className}
    >
      {children}
    </div>
  );
};

const Column = ({ children, spacing, className }: ColumnProps) => {
  const spacingOverride = spacing === 0 || spacing ? spacing : sizes.Spacing.Medium;
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: `${spacingOverride}px`,
        width: '100%',
      }}
      className={className}
    >
      {children}
    </div>
  );
};

const GroupTitleRow = ({ children }) => (
  <div
    css={{
      ...font.Size.GroupHeading,
      color: colors.Black,
    }}
  >
    {children}
  </div>
);

interface Props {
  eventId: string;
  onClose: () => void;
}

const EventIssues = (props: Props) => {
  const { onClose, eventId } = props;

  const [saving, setSaving] = useState(false);
  const [updatedData, setUpdatedData] = useState<GraphQL.IssueOnEventTab.TeamEventIssues>({
    id: '',
    note: '',
    categories: [],
  });
  const { data, loading } = useQuery(EventIssues.query, {
    variables: { id: eventId },
  });

  useEffect(() => {
    const hasExistingEventIssue = data && data.teamEvent.teamEventIssues.length;
    if (hasExistingEventIssue) setUpdatedData(data.teamEvent.teamEventIssues[0]);
  }, [data]);

  const [addIssue] = useMutation<
    GraphQL.TeamEventIssueCreateIssue.Mutation,
    GraphQL.TeamEventIssueCreateIssue.Variables
  >(EventIssues.createIssue, {
    refetchQueries: ['IssueOnEventTab'],
  });
  const [updateIssue] = useMutation<
    GraphQL.TeamEventIssueUpdateIssue.Mutation,
    GraphQL.TeamEventIssueUpdateIssue.Variables
  >(EventIssues.updateIssue, {
    refetchQueries: ['IssueOnEventTab'],
  });

  if (loading) {
    return null;
  }

  const isDirty =
    JSON.stringify(updatedData) !== JSON.stringify(_.get(data, 'teamEvent').teamEventIssues[0]);

  const onSubmit = async () => {
    setSaving(true);
    const { id, categories, note } = updatedData;
    let errorMessages = [];

    if (data && data.teamEvent.teamEventIssues.length) {
      try {
        updateIssue({
          variables: {
            categories,
            id,
            note,
          },
        });
      } catch (e) {
        errorMessages = [...errorMessages, 'Failed to update event issue'];
      }
    } else {
      try {
        addIssue({
          variables: {
            categories,
            teamEventId: eventId,
            note,
          },
        });
      } catch (e) {
        errorMessages = [...errorMessages, 'Failed to save event issue'];
      }
    }
    if (errorMessages.length > 0) {
      errorMessages.forEach(error => message.error(error));
    } else {
      message.success('Event issue saved');
    }
    setSaving(false);
  };

  return (
    <div
      css={{
        padding: sizes.Spacing.Large,
        flex: 1,
        flexDirection: 'column',
        gap: sizes.Spacing.Medium,
        overflowY: 'scroll',
      }}
    >
      <Column>
        <GroupTitleRow>Event issues</GroupTitleRow>
        <TitleRow>Select the issues that happened in this event</TitleRow>
        <Row
          css={{
            alignItems: 'flex-start',
            padding: sizes.Spacing.None,
            gap: sizes.Spacing.Medium,
            width: '100%',
          }}
        >
          <Select
            css={STYLES.root}
            mode='multiple'
            value={updatedData.categories}
            onChange={(newValue: TeamEventIssueCategory[]) => {
              setUpdatedData({
                ...updatedData,
                categories: newValue,
              });
            }}
            showArrow
          >
            {Object.entries(TeamEventIssueCategory).map(([key, value]) => (
              <Select.Option key={key} label={value}>
                {TEAM_EVENT_ISSUE_NAME_MAP[key]}
              </Select.Option>
            ))}
          </Select>
        </Row>
        <TitleRow>Add any notes to supplement the issues that happened in this event</TitleRow>
        <Row
          css={{
            alignItems: 'flex-start',
            padding: sizes.Spacing.None,
            gap: sizes.Spacing.Medium,
            width: '100%',
          }}
        >
          <Input.TextArea
            rows={8}
            value={updatedData.note}
            onChange={e =>
              setUpdatedData({
                ...updatedData,
                note: e.target.value,
              })
            }
          />
        </Row>
      </Column>
      <div
        css={{
          position: 'sticky',
          bottom: '-30px',
          backgroundColor: 'white',
          width: '100%',
        }}
      >
        <ScottHr marginTop={0} marginBottom={0} />
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            padding: sizes.Spacing.Medium,
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            type='default'
            onClick={() => {
              onClose();
              setUpdatedData({ id: '', note: '', categories: [] });
            }}
            css={{
              marginRight: sizes.Spacing.Small,
              color: colors.Blue.Blue500,
              border: borders.divider,
              borderColor: colors.Blue.Blue500,
              borderRadius: `${sizes.BorderRadius.Medium}px`,
            }}
          >
            Close
          </Button>
          <Button
            css={{
              borderRadius: `${sizes.BorderRadius.Medium}px`,
              color: colors.White,
              backgroundColor: colors.Blue.Blue500,
            }}
            loading={saving}
            type='primary'
            disabled={!isDirty || !updatedData}
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

EventIssues.createIssue = gql`
  mutation TeamEventIssueCreateIssue(
    $categories: [TeamEventIssueCategory!]!
    $note: String
    $teamEventId: ID!
  ) {
    createTeamEventIssue(categories: $categories, note: $note, teamEventId: $teamEventId) {
      id
      categories
      note
    }
  }
`;

EventIssues.updateIssue = gql`
  mutation TeamEventIssueUpdateIssue(
    $categories: [TeamEventIssueCategory!]!
    $note: String
    $id: ID!
  ) {
    updateTeamEventIssue(categories: $categories, note: $note, id: $id) {
      id
      categories
      note
    }
  }
`;

EventIssues.query = gql`
  query IssueOnEventTab($id: ID!) {
    teamEvent(id: $id) {
      id
      teamEventIssues {
        id
        categories
        note
      }
    }
  }
`;

export default EventIssues;
