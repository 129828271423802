import _ from 'lodash';
import React from 'react';
import { sizes } from '~/styles';
import { OperatingHoursInput } from '~/graphql';
import Text from '~/Text';
import moment from 'moment-timezone';
import { weekdayOrder, weekdayNameToIndex } from '.';
import { formatHour } from '~/formatters';

type Props = {
  operatingHours: { [day: string]: OperatingHoursInput[] };
};

const OperatingHoursViewer = (props: Props) => {
  return (
    <div>
      {_.map(weekdayOrder, weekdayName => (
        <HoursForDayRow
          key={weekdayName}
          weekdayName={weekdayName}
          operatingHours={props.operatingHours[weekdayNameToIndex[weekdayName]]}
        />
      ))}
    </div>
  );
};

export default OperatingHoursViewer;

const HoursForDayRow = (props: {
  weekdayName: string;
  operatingHours?: OperatingHoursInput[];
}) => {
  let hoursText = props.operatingHours
    ? _.map(props.operatingHours, hours => {
        const open = moment(hours.open.time, 'HHmm');
        const close = moment(hours.close.time, 'HHmm');
        const sameAMPM = open.format('a') === close.format('a');
        return `${formatHour(open, !sameAMPM)}-${formatHour(close, true)}`;
      }).join(', ')
    : 'closed';

  if (props.operatingHours && props.operatingHours.length > 1)
    hoursText = '(' + hoursText + ')';

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: sizes.Spacing.XXXSmall,
      }}
    >
      <div css={{ width: 50 }}>
        <Text weight='Demi' size='XSmall'>
          {props.weekdayName}
        </Text>
      </div>
      <Text size='XSmall'>{hoursText}</Text>
    </div>
  );
};
