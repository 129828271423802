import gql from 'graphql-tag';

import { makeQueryBasedSelect } from './QueryBasedSelect';

const SelectXperienceCategories = makeQueryBasedSelect(
  gql`
    query SelectXperienceCategories {
      xperienceCategories {
        id
        name
      }
    }
  `,
  'xperienceCategories',
);

export default SelectXperienceCategories;
