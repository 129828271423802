import { Icon, Input } from 'antd';
import { isEmpty } from 'lodash';
import { formatCents } from '~/formatters';
import { PriceStatus } from '~/graphql';
import { borders, colors, font, sizes } from '~/styles';
import { BorderRadius } from '~/styles/sizes';
import ChargeStatus from './ChargeStatus';

export const STRIPE_CHARGE_ID_REGEX = /^(ch_|py_)[a-zA-Z0-9]+$/;
export const OFF_PLATFORM_PAYMENT_REGEX = /^ach_[a-zA-Z0-9\-]+$/;
const CHARGE_REGEX = /^ch_[a-zA-Z0-9]+$/;
const PAYMENT_REGEX = /^py_[a-zA-Z0-9]+$/;
const BASE_STRIPE_URL = 'https://dashboard.stripe.com/payments';

const grid = {
  display: 'grid',
  gridTemplateColumns: '2.5fr 1fr 1fr 1fr',
  gap: `${sizes.Spacing.XSmall}px`,
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
};

export const HeaderRow = ({ children, className = '' }) => (
  <div
    css={{
      ...grid,
      color: colors.White,
      padding: `${sizes.Spacing.XSmall}px ${sizes.Spacing.Small}px`,
      ...font.Size.Body,
      fontWeight: font.FontWeight.Bold,
      backgroundColor: colors.Blue500,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    }}
    className={className}
  >
    {children}
  </div>
);

type StripeIdRowProps = {
  price?: {
    stripeChargeId: string;
    systemGenerated?: boolean;
    status?: PriceStatus;
    totalCents?: number;
  };
  autoGenerated?: boolean;
  onChange?: (stripeChargeId: string) => void;
  onRemove?: (stripeChargeId: string) => void;
  onReAdd?: (stripeChargeId: string) => void;
  stripeId?: string;
  pendingDelete?: boolean;
};
export const StripeChargeRow = ({
  price,
  autoGenerated = false,
  stripeId,
  pendingDelete = false,
  onChange,
  onRemove,
  onReAdd,
}: StripeIdRowProps) => {
  const hasValidStripeId = STRIPE_CHARGE_ID_REGEX.test(stripeId);
  const generated = (price && price.systemGenerated) || autoGenerated || false;
  const iconStyle = {
    color: colors.Blue.Blue500,
    ...font.Size.Body,
    alignSelf: 'flex-start',
    paddingTop: sizes.Spacing.XSmall,
  };
  const handleChange = e => {
    onChange(e.target.value);
  };
  const handleToggle = () => {
    if (!price) {
      return;
    }
    if (pendingDelete) {
      return onReAdd(price.stripeChargeId);
    } else {
      return onRemove(price.stripeChargeId);
    }
  };
  const error = !isEmpty(stripeId) && !STRIPE_CHARGE_ID_REGEX.test(stripeId);
  const isCharge = price ? CHARGE_REGEX.test(price.stripeChargeId) : CHARGE_REGEX.test(stripeId);
  const isPayment = price ? PAYMENT_REGEX.test(price.stripeChargeId) : PAYMENT_REGEX.test(stripeId);
  const chargeTypeText = isCharge ? 'Charge' : isPayment ? 'Payment' : 'Unknown';
  return (
    <div
      css={{
        width: '100%',
        padding: `${sizes.Spacing.XSmall}px ${sizes.Spacing.Small}px`,
        borderBottom: borders.darkDivider,
        margin: sizes.Spacing.None,
        ...(grid as any),
        transition: 'all 0.5s ease-in-out',
        backgroundColor: pendingDelete ? colors.Red.Red500_Faded : colors.GreyLighter,
      }}
    >
      <div
        spacing={sizes.Spacing.None}
        css={{
          display: 'grid',
          gridTemplateColumns: '17px auto',
          gap: `${sizes.Spacing.XSmall}px`,
          alignSelf: 'stretch',
          padding: sizes.Spacing.None,
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {generated && <Icon type='lock' css={{ color: colors.GreyLight }} title='Auto-generated' />}
        {!generated && !pendingDelete && (
          <Icon type='minus-circle' css={{ ...iconStyle }} onClick={handleToggle} />
        )}
        {!generated && pendingDelete && (
          <Icon type='plus-circle' css={{ ...iconStyle }} onClick={handleToggle} />
        )}
        {price && (
          <div>
            {hasValidStripeId && (
              <a
                href={`${BASE_STRIPE_URL}/${price.stripeChargeId}`}
                css={{
                  width: '250px',
                }}
              >
                {price.stripeChargeId}
              </a>
            )}
            {!hasValidStripeId && <div>{price.stripeChargeId}</div>}
          </div>
        )}
        {!price && (
          <Input
            value={price ? price.stripeChargeId : stripeId}
            disabled={!!price}
            css={{
              width: '250px',
              ':disabled': {
                backgroundColor: colors.GreyLighter,
                color: colors.GreyDarkest,
              },
              color: colors.Black,
              border: borders.darkDivider,
              borderRadius: `${BorderRadius.Medium}px`,
              ...font.Size.Caption,
            }}
            onChange={handleChange}
            placeholder='ch_1EuIRCDASa8QCnJaiNFsFub'
            pattern={String(STRIPE_CHARGE_ID_REGEX).replaceAll('/', '')}
          />
        )}
        {error && (
          <div css={{ color: colors.Red.Red500, gridColumnStart: '2', gridRowStart: '2' }}>
            Not a valid Stripe ID
          </div>
        )}
      </div>
      <div>{chargeTypeText}</div>
      <ChargeStatus status={price ? price.status : undefined} />
      <div>{price && price.totalCents ? formatCents(price.totalCents) : '-'}</div>
    </div>
  );
};
