import { Button, Select } from 'antd';
import gql from 'graphql-tag';
import { useState } from 'react';

import { addSpacesToPascalCasedString } from '~/formatters';
import * as GraphQL from '~/graphql';
import { Edits } from '~/hooks/useEditableEntity';
import Row from '~/Row';
import SearchUsers from '~/Search/Users';
import LabeledField from '~/ScottTitle/LabeledWithScottTitle';
import EditUserModal from '~/Users/EditUserModal';

import Heading from '../../Heading';

export interface Props {
  experience: GraphQL.ExperienceProfileContactDetails.Fragment;
  onChange: (edits: Edits<GraphQL.ExperienceProfileContactDetails.Fragment>) => void;
}

const ExperienceProfileContactDetails = ({ experience, onChange }: Props) => {
  const [addingUser, setAddingUser] = useState(false);

  return (
    <div>
      <Heading>
        <Row css={{ alignItems: 'flex-end' }}>
          <div css={{ flex: 1 }}>Point of Contact</div>
          <Button onClick={() => setAddingUser(true)}>Add House Elf</Button>
          <EditUserModal
            open={addingUser}
            defaultRole={GraphQL.Role.HouseElf}
            onClose={() => setAddingUser(false)}
          />
        </Row>
      </Heading>
      <Row>
        <LabeledField label='User'>
          <SearchUsers
            value={experience.owner ? experience.owner.name : ''}
            onChange={(owner: GraphQL.SearchUsers.Fragment) =>
              onChange({ owner: { id: owner.id } })
            }
            role={GraphQL.Role.HouseElf}
            placeholder='Search users for house elf'
            autoFocus={false}
          />
        </LabeledField>
      </Row>
      <Row>
        <LabeledField label='Roles'>
          <Select
            mode='multiple'
            size='large'
            value={experience.ownerRoles}
            onChange={ownerRoles => onChange({ ownerRoles })}
          >
            {Object.values(GraphQL.XperienceOwnerRole).map(role => (
              <Select.Option key={role}>{addSpacesToPascalCasedString(role)}</Select.Option>
            ))}
          </Select>
        </LabeledField>
      </Row>
    </div>
  );
};

ExperienceProfileContactDetails.fragment = gql`
  fragment ExperienceProfileContactDetails on Xperience {
    id
    owner {
      ...SearchUsers
    }
    ownerRoles
  }
  ${SearchUsers.fragment}
`;

export default ExperienceProfileContactDetails;
